/* Boxcar | Boxcar HTML Template

Template Name: Boxcar
Version: 1.0.0
License: copyright commercial
/************ TABLE OF CONTENTS ***************
1. Fonts
2. Reset
3. Global Settings
4. Main Header / TwO
5. Sticky Header 
6. Mobile Menu
7. Section Title
8. Main Slider
9. Banner Section
10. Project Section
11. Features Section / TwO / Three / Four / Five
12. About Section / Two
13. Services Section / Two
14. Call To Action / Two
15. FAQ's Sectiom
16.  Marquee Section
17. Fun Fact Section
18. Testimonial Section / Two
19. team Section 
20, Contact Section / Two
21. Why Choose Us / Two
22. News Section / Two
23. Video Section
24. Map Section
25. Clients Section
26. Main Footer
**********************************************/

@import url("animate.css");
@import url("owl.css");
@import url("jquery.fancybox.min.css");
@import url("linear.css");
@import url("fontawesome.css");
@import url("flaticon.css");
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&amp;display=swap');

:root {
  --theme-color-light: #ffffff;
  --theme-color-dark: #050B20;
  --theme-color-gray: #F6F6F6;
  --theme-color1: #405FF2;
  --theme-color2: #00B67A;
  --theme-color3: #faf7f2;
  --bg-theme-color1: var(--theme-color1);
  --bg-theme-color2: var(--theme-color2);
  --bg-theme-color3: var(--theme-color3);
  --text-color: #050B20;
  --text-color2: #616670;
  --headings-color: #050B20;
  --link-color: var(--headings-color);
  --link-hover-color: var(--headings-color);
  --title-font: "DM Sans", sans-serif;
  --text-font: "DM Sans", sans-serif;
  --title-font2: "Bebas Neue", sans-serif;
  --body-font-size: 16px;
  --body-line-height: 28px;
  --body-font-weight: 400;
  --line-height-heading-h1: 1.2em;
  --line-height-heading: 54px;
  --line-height-heading-small: 1.35em;
  --h1-font-size: 68px;
  --h2-font-size: 40px;
  --h3-font-size: 30px;
  --h4-font-size: 24px;
  --h5-font-size: 22px;
  --h6-font-size: 20px;
  --h1-font-weight: 400;
  --h2-font-weight: 400;
  --h3-font-weight: 400;
  --h4-font-weight: 400;
  --h5-font-weight: 400;
  --h6-font-weight: 400;
  --sec-title-subtitle-font-size: 14px;
  --sec-title-subtitle-color: var(--headings-color);
  --sec-title-subtitle-font-family: var(--text-font);
  --sec-title-subtitle-font-weight: 700;
  --sec-title-subtitle-line-height: 20px;
  --sec-title-color: var(--headings-color);
  --sec-title-font-size: var(--h2-font-size);
  --sec-title-font-family: var(--title-font);
  --sec-title-font-weight: var(--h2-font-weight);
  --theme-light-background: #F2F3F5;
  --theme-light-background-text-color: var(--headings-color);
  --theme-black: #131313;
  --container-width: 1430px;
  --small-container-width: 1000px;
  --large-container-width: 1730px;
  --container-pt: 120px;
  --container-pb: 120px;
}

/*
 * typography.scss
 * -----------------------------------------------
*/
::-moz-selection {
  background: var(--theme-color-dark);
  color: #fff;
  text-shadow: none;
}

::selection {
  background: var(--theme-color-dark);
  color: #fff;
  text-shadow: none;
}

:active,
:focus {
  outline: none !important;
}

::-webkit-input-placeholder {
  color: #7c858c;
}

::-moz-input-placeholder {
  color: #7c858c;
}

::-ms-input-placeholder {
  color: #7c858c;
}

body {
  background-color: #fff;
  background-attachment: fixed;
  -ms-word-wrap: break-word;
  word-wrap: break-word;
  counter-reset: my-sec-counter;
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  color: var(--text-color);
  font-size: var(--body-font-size);
  font-family: var(--text-font);
  font-weight: var(--body-font-weight);
  line-height: var(--body-line-height);
}

p,
.text {
  color: var(--text-color);
  font-size: var(--body-font-size);
  font-family: var(--text-font);
  font-weight: var(--body-font-weight);
  line-height: var(--body-line-height);
}

/* -------- Headings ---------- */
h1,
h2,
h3,
h4,
h5,
h6 {
  color: var(--headings-color);
  font-family: var(--title-font);
  position: relative;
  line-height: var(--line-height-heading-);
}

h1 small,
h1 .small,
h2 small,
h2 .small,
h3 small,
h3 .small,
h4 small,
h4 .small,
h5 small,
h5 .small,
h6 small,
h6 .small {
  font-weight: normal;
  line-height: 1;
  color: var(--headings-color);
}

h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a {
  color: inherit;
  font-weight: inherit;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-bottom: 1rem;
}

h1 {
  font-size: var(--h1-font-size);
  font-weight: var(--h1-font-weight);
  line-height: var(--line-height-heading-h1);
}

h2 {
  font-size: var(--h2-font-size);
  font-weight: var(--h2-font-weight);
  line-height: var(--line-height-heading);
}

h3 {
  font-size: var(--h3-font-size);
  font-weight: var(--h3-font-weight);
  line-height: var(--line-height-heading);
}

h4 {
  font-size: var(--h4-font-size);
  font-weight: var(--h4-font-weight);
  line-height: var(--line-height-heading-small);
  letter-spacing: 0.15px;
}

h5 {
  font-size: var(--h5-font-size);
  font-weight: var(--h5-font-weight);
  line-height: var(--line-height-heading-small);
}

h6 {
  font-size: var(--h6-font-size);
  font-weight: var(--h6-font-weight);
  line-height: var(--line-height-heading-small);
}

/* -------- Body Text ---------- */
table p {
  margin-bottom: 0;
}

p {
  margin-bottom: 20px;
}

p a:not(.button):not(.btn):hover,
p a:not(.button):not(.btn):focus {
  text-decoration: underline;
}

/* -------- other ---------- */
a {
  color: var(--link-color);
  text-decoration: none;
  font-weight: var(--body-font-weight);
  cursor: pointer;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

a:hover,
a:focus {
  color: inherit;
  text-decoration: none;
  outline: none;
}

a b,
a strong {
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

a img {
  border: none;
}

pre,
ul,
ol,
dl,
dd,
blockquote,
address,
table,
fieldset {
  margin-bottom: 10px;
}

ol,
ul {
  list-style-position: inside;
  margin: 0;
  padding: 0;
}

b,
strong {
  color: #333;
  font-weight: var(--body-font-weight-bold);
}

iframe {
  border: none !important;
}

/*
 * container.scss
 * -----------------------------------------------
*/
.container .container {
  width: 100%;
}

.container .container,
.container .container-fluid,
.container-fluid .container,
.container-fluid .container-fluid {
  padding-left: 0;
  padding-right: 0;
}

section>.container,
section>.container-fluid {
  padding-top: var(--container-pt);
  padding-bottom: var(--container-pt);
}

@media (min-width: 1400px) {

  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    max-width: var(--container-width);
  }
}

/*=== Default Form ===*/
.form-control,
.input-text {
  height: calc(2.25rem + 27px);
  padding: 14px 30px;
  outline: 0;
  background-color: #f4f5f8;
  border: 1px solid #f4f5f8;
  color: #686a6f;
  font-size: 0.9rem;
  width: 100%;
}

.form-control::-webkit-input-placeholder,
.input-text::-webkit-input-placeholder {
  color: #686a6f;
  opacity: 1;
}

.form-control::-moz-placeholder,
.input-text::-moz-placeholder {
  color: #686a6f;
  opacity: 1;
}

.form-control:-ms-input-placeholder,
.input-text:-ms-input-placeholder {
  color: #686a6f;
  opacity: 1;
}

.form-control::-ms-input-placeholder,
.input-text::-ms-input-placeholder {
  color: #686a6f;
  opacity: 1;
}

.form-control::placeholder,
.input-text::placeholder {
  color: #686a6f;
  opacity: 1;
}

.form-control:-ms-input-placeholder,
.input-text:-ms-input-placeholder {
  color: #686a6f;
}

.form-control::-ms-input-placeholder,
.input-text::-ms-input-placeholder {
  color: #686a6f;
}

textarea.form-control {
  height: auto;
  padding-top: 15px;
  padding-bottom: 15px;
}

/* -------- Dark Theme Styling ---------- */
.dark-layout {
  background-color: var(--theme-color-dark) !important;
  --sec-title-color: var(--theme-color-light);
}

.dark-layout .sticky-header .main-menu .navigation>li>a,
.dark-layout h1,
.dark-layout h2,
.dark-layout h3,
.dark-layout h4,
.dark-layout h5,
.dark-layout h6 {
  color: var(--theme-color-light);
}

.dark-layout .preloader {
  background-color: var(--theme-color-dark);
}

.dark-layout .preloader:after {
  background-image: url(../images/logo.svg);
}

.dark-layout .sticky-header {
  background-color: var(--theme-color-dark);
}

.dark-layout .hidden-bar .upper-box {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.dark-layout .hidden-bar .social-links {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.dark-layout .hidden-bar .social-links li {
  border-right: 1px solid rgba(0, 0, 0, 0.1);
}

/*** 

====================================================================
Reset
====================================================================

***/
* {
  margin: 0px;
  padding: 0px;
  border: none;
  outline: none;
  font-size: 100%;
}

/*** 

====================================================================
Global Settings
====================================================================

***/
textarea {
  overflow: hidden;
  resize: none;
}

button {
  outline: none !important;
  cursor: pointer;
}

img {
  display: inline-block;
  max-width: 100%;
  height: auto;
  border-radius: 16px;
}

ul,
li {
  list-style: none;
  padding: 0px;
  margin: 0px;
}

figure.image {
  margin-bottom: 0;
}

.title a {
  color: inherit;
}

.color1 {
  color: var(--theme-color1);
}

.boxcar-wrapper {
  position: relative;
  margin: 0 auto;
  width: 100%;
  min-width: 300px;
  overflow: hidden;
  z-index: 99;
  background-color: #ffffff;
}

.large-container {
  position: static;
  max-width: var(--large-container-width);
  padding: 0px 15px;
  margin: 0 auto;
  width: 100%;
}

.boxcar-container {
  position: static;
  max-width: var(--container-width);
  padding: 0px 15px;
  margin: 0 auto;
  width: 100%;
}

.small-container {
  position: static;
  max-width: var(--small-container-width);
  padding: 0px 15px;
  margin: 0 auto;
  width: 100%;
}

.bg {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.bg-pattern-1 {
  background-image: url(../images/icons/pattern-1.html);
}

.bg-white {
  background-color: #fff;
}

/*=======================
    Preloader
=======================*/
.preloader {
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  z-index: 999999;
  background-color: #ffffff;
}

.preloader:after {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 150px;
  margin-left: -75px;
  margin-top: -30px;
  height: 50px;
  background-position: center center;
  background-repeat: no-repeat;
  -webkit-animation: pulse 1s infinite linear;
  animation: pulse 1s infinite linear;
  background-image: url(../images/favicon.png);
  content: "";
}

.preloader:before {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  width: 100%;
  text-align: center;
  margin: 0 auto;
  margin-top: 20px;
  color: var(--theme-color-dark);
  font-weight: 600;
  font-size: 14px;
  font-family: var(--title-font);
  letter-spacing: 2px;
  text-transform: uppercase;
  content: "Loading";
  -webkit-transition: none;
  transition: none;
}

/*=======================
Scroll To Top style
=======================*/
.scroll-to-top {
  position: fixed;
  right: 20px;
  bottom: 20px;
  width: 40px;
  font-size: 16px;
  line-height: 40px;
  color: #ffffff;
  text-align: center;
  cursor: pointer;
  background-color: var(--theme-color1);
  z-index: 100;
  display: none;
  border-radius: 50%;
  margin: 0 auto;
  -webkit-box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}

.scroll-to-top:hover {
  background: var(--theme-color3);
  color: #ffffff;
}

/*======================
    Tabs Box
======================*/
.tabs-box {
  position: relative;
}

.tabs-box .tab {
  display: none;
}

.tabs-box .tab.active-tab {
  display: block;
}

/*=======================
    List Styles
=======================*/
.list-style-one {
  position: relative;
}

.list-style-one li {
  position: relative;
  font-size: 16px;
  line-height: 30px;
  color: var(--theme-color-dark);
  margin-right: 50px;
  font-weight: 500;
  margin-bottom: 11px;
  padding-left: 22px;
}

.list-style-one li .icon {
  position: absolute;
  top: 0;
  left: 0;
  color: var(--theme-color1);
}

/*=======================
    List Styles
=======================*/
.list-style-two {
  margin-top: 28px;
}

.list-style-two li {
  font-size: 16px;
  color: var(--text-color);
  position: relative;
  padding-left: 25px;
  margin-bottom: 8px;
}

.list-style-two li .icon {
  position: absolute;
  top: 0;
  left: 0;
  color: var(--theme-color1);
}

/*===========================
    Social Icons Styles
============================*/
.social-icon-one {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.social-icon-one li {
  position: relative;
  margin-left: 20px;
}

.social-icon-one li:first-child {
  margin-left: 0;
}

.social-icon-one li a {
  position: relative;
  display: block;
  font-size: 12px;
  line-height: 30px;
  color: var(--theme-color-dark);
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}

.social-icon-one li a:hover {
  color: var(--theme-color1);
}

/*======================
    Play Buttons
======================*/
.play-btn {
  position: relative;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.play-btn .icon {
  position: relative;
  height: 60px;
  width: 60px;
  line-height: 60px;
  text-align: center;
  border-radius: 50%;
  font-size: 20px;
  color: var(--theme-color1);
  background-color: var(--theme-color-light);
  border: 1px solid var(--theme-color1);
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}

.play-btn .title {
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  padding: 10px 25px;
  text-transform: capitalize;
  padding-left: 30px;
  margin-left: -10px;
  color: var(--theme-color2);
  background: var(--theme-color-gray);
  border-radius: 0px 22px 22px 0px;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}

.play-btn:hover .icon {
  background-color: var(--bg-theme-color1);
  color: var(--theme-color-light);
}

.play-btn:hover .title {
  background-color: var(--bg-theme-color2);
  color: var(--theme-color-light);
}

/*======================
    Media Play Button 
======================*/
.play-now {
  position: relative;
  display: block;
  z-index: 9;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}

.play-now .icon {
  position: relative;
  display: inline-block;
  height: 85px;
  width: 85px;
  text-align: center;
  line-height: 85px;
  background-color: #ffffff;
  color: var(--bg-theme-color2);
  z-index: 1;
  font-size: 18px;
  display: block;
  border-radius: 50%;
  -webkit-box-shadow: 0 0px 10px 0 rgba(255, 255, 255, 0.3);
  box-shadow: 0 0px 10px 0 rgba(255, 255, 255, 0.3);
  -webkit-transform-origin: center;
  transform-origin: center;
}

.play-now .ripple:before,
.play-now .ripple:after {
  position: absolute;
  top: 50%;
  left: 50%;
  height: 70px;
  width: 70px;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  border-radius: 50%;
  -webkit-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.6);
  box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.6);
  -webkit-animation: ripple 3s infinite;
  animation: ripple 3s infinite;
  content: "";
}

.play-now .ripple.light {
  -webkit-box-shadow: 0 0 0 0 rgb(255, 255, 255);
  box-shadow: 0 0 0 0 rgb(255, 255, 255);
}

.play-now .ripple:before {
  -webkit-animation-delay: 0.9s;
  animation-delay: 0.9s;
}

.play-now .ripple::after {
  -webkit-animation-delay: 0.6s;
  animation-delay: 0.6s;
}

@-webkit-keyframes ripple {
  70% {
    -webkit-box-shadow: 0 0 0 50px rgba(233, 35, 47, 0);
    box-shadow: 0 0 0 50px rgba(233, 35, 47, 0);
  }

  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(233, 35, 47, 0);
    box-shadow: 0 0 0 0 rgba(233, 35, 47, 0);
  }
}

@keyframes ripple {
  70% {
    -webkit-box-shadow: 0 0 0 50px rgba(233, 35, 47, 0);
    box-shadow: 0 0 0 50px rgba(233, 35, 47, 0);
  }

  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(233, 35, 47, 0);
    box-shadow: 0 0 0 0 rgba(233, 35, 47, 0);
  }
}

/*======================
    Carousel Navs
======================*/
.default-navs .owl-nav {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.default-navs .owl-next,
.default-navs .owl-prev {
  display: block;
  width: 60px;
  height: 60px;
  font-size: 20px;
  font-weight: 700;
  line-height: 58px;
  color: var(--theme-color-dark);
  background: #fff;
  border: 1px solid #E3E3E3;
  text-align: center;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  border-radius: 50%;
  margin-left: 20px;
}

.default-navs .owl-next:hover,
.default-navs .owl-prev:hover {
  border-color: var(--theme-color1);
}

.default-navs .owl-prev {
  margin-left: 0;
}

.disable-navs .owl-nav {
  display: none;
}

.disable-dots .owl-dots {
  display: none;
}

/*==========================
	Default Tabs
===========================*/
.default-tabs {
  position: relative;
  overflow: hidden;
  margin-bottom: 30px;
}

.default-tabs .tab-buttons {
  position: relative;
  margin-bottom: 30px;
}

.default-tabs .tab-btn {
  position: relative;
  float: left;
  font-weight: 600;
  font-size: 18px;
  padding: 15px 35px;
  color: var(--theme-color1);
  line-height: 20px;
  border-radius: 5px;
  background-color: #ffffff;
  cursor: pointer;
  margin-right: 20px;
  -webkit-box-shadow: 0 10px 30px rgba(0, 0, 0, 0.05);
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.05);
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}

.default-tabs .tab-btn:last-child {
  margin-right: 0;
}

.default-tabs .tab-btn.active-btn {
  background: var(--gradient-1);
  color: #ffffff;
}

.default-tabs .tabs-content {
  position: relative;
  width: 100%;
}

/*================================
    Progress Bar
=================================*/
.skills {
  position: relative;
  margin-bottom: 50px;
}

.skills .skill-item {
  position: relative;
  margin-bottom: 25px;
}

.skills .skill-item:last-child {
  margin-bottom: 0px;
}

.skills .skill-item .skill-header {
  position: relative;
  margin-bottom: 0px;
}

.skills .skill-item .skill-header .skill-title {
  font-weight: 600;
  color: var(--theme-color-dark);
  letter-spacing: 0;
  margin-bottom: 10px;
}

.skills .skill-item .skill-bar {
  position: relative;
  width: 100%;
  height: 14px;
  border-radius: 10px;
}

.skills .skill-item .skill-bar .bar-inner {
  position: relative;
  width: 100%;
  height: 14px;
  background: #f2f3f6;
  border-radius: 10px;
}

.skills .skill-item .skill-bar .bar-inner .bar {
  position: absolute;
  left: 0px;
  top: 0px;
  height: 14px;
  width: 0px;
  -webkit-transition: all 3000ms ease;
  transition: all 3000ms ease;
  border-radius: 10px;
  background: var(--bg-theme-color1);
}

.skills .skill-item .skill-bar .bar-inner .skill-percentage {
  position: absolute;
  right: 0;
  bottom: 100%;
  font-weight: 400;
  color: #6f7174;
  line-height: 25px;
  margin-bottom: 10px;
}

.overlay-anim {
  position: relative;
}

.overlay-anim:before {
  background: rgba(255, 255, 255, 0.3);
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 0;
  opacity: 1;
  z-index: 9;
  pointer-events: none;
}

.overlay-anim:hover:before {
  height: 100%;
  opacity: 0;
  -webkit-transition: all 400ms linear;
  transition: all 400ms linear;
}

.bounce-y {
  -webkit-animation: bounce-y 5s infinite linear;
  animation: bounce-y 5s infinite linear;
}

@-webkit-keyframes bounce-y {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  50% {
    -webkit-transform: translateY(-30px);
    transform: translateY(-30px);
  }

  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

@keyframes bounce-y {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  50% {
    -webkit-transform: translateY(-30px);
    transform: translateY(-30px);
  }

  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

.slick-prev,
.slick-next {
  width: 60px;
  height: 40px;
  border: 1px solid #050B20;
  border-radius: 30px;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.slick-prev::before,
.slick-next::before {
  content: "\f105";
  color: #050B20;
  font-size: 14px;
  font-family: "Font Awesome 6 Pro";
  opacity: 1;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.slick-prev:hover,
.slick-next:hover {
  background-color: #050B20;
}

.slick-prev:hover:before,
.slick-next:hover:before {
  color: #fff;
}

.slick-prev {
  left: -150px;
}

.slick-prev:before {
  content: "\f104";
}

.slick-next {
  right: -150px;
}

.boxcar-title-three {
  margin-bottom: 51px;
}

.boxcar-title-three .breadcrumb {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 12px;
}

.boxcar-title-three .breadcrumb li {
  font-size: 15px;
  margin-right: 21px;
  position: relative;
}

.boxcar-title-three .breadcrumb li::before {
  content: "/";
  position: absolute;
  top: 0;
  right: -14px;
}

.boxcar-title-three .breadcrumb li:last-child {
  margin-right: 0;
}

.boxcar-title-three .breadcrumb li:last-child::before {
  display: none;
}

.boxcar-title-three .breadcrumb li a {
  color: var(--theme-color1);
}

.boxcar-title-three h2 {
  margin-bottom: 36px;
  font-weight: 700;
}

.boxcar-title-three .text {
  font-size: 40px;
  font-weight: 700;
  line-height: 55px;
}

.footer-style-one.v1 {
  z-index: -1;
}

.text-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-bottom: 25px;
}

.text-box .text {
  font-size: 15px;
  color: var(--theme-color-dark);
  font-weight: 400;
}

.text-box .form_boxes.v3 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-right: 14px;
  height: 20px;
}

.text-box .form_boxes.v3 small {
  color: #818181;
}

.text-box .form_boxes.v3 .drop-menu .select {
  padding: 0 0 0 10px;
  font-size: 15px;
  color: var(--theme-color-dark);
}

.text-box .form_boxes.v3 .drop-menu .select i {
  right: -14px;
  top: 1px;
}

.text-box .form_boxes.v3 .drop-menu .dropdown {
  width: 161px;
  left: auto;
  right: 0;
  -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.body {
  background-color: var(--theme-color-dark);
}

.boxcar-wrapper.v2 {
  background-color: unset;
}

.drop-menu.height-auto {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}

.drop-menu.height-auto .select {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  line-height: unset;
}

.theme-btn {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center;
  white-space: nowrap;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  font-family: var(--title-font);
}

.theme-btn .btn-title {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.theme-btn:hover {
  background: #0146A6 !important;
  border-color: transparent !important;
}

/*Btn Style One*/
.btn-style-one {
  position: relative;
  font-size: 16px;
  line-height: 25px;
  padding: 14px 32px 18px;
  overflow: hidden;
  color: var(--theme-color1);
  border: 1px solid var(--theme-color1);
  background: #f8f5f0;
  text-transform: uppercase;
  font-family: var(--text-font);
}

.btn-style-one i {
  position: relative;
  display: block;
  margin-left: 10px;
}

.btn-style-one:hover {
  color: var(--theme-color-light);
}

.theme-btn.small {
  padding: 10px 30px;
  line-height: 20px;
  font-size: 10px;
}

.theme-btn.large {
  padding: 20px 60px;
  line-height: 25px;
}

.image {
  overflow: hidden;
  border-radius: 15px 15px 0 0;
}

.image img {
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.inner-box:hover .image img {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.boxcar-pricing-section .content-column .inner-column .read-more:hover,
.blog-blockt-two .inner-box .read-more:hover,
.boxcar-pricing-section-two .content-column .inner-column .read-more:hover,
.header-style-v3 .btn .header-btn-two:hover,
.boxcar-pricing-section-three .content-column .inner-column .read-more:hover,
.hheader-style-v4 .btn .header-btn-two:hover,
.boxcar-brand-section-three .content-column .inner-column .boxcar-title .brand-btn:hover,
.boxcar-pricing-section-four .content-column .inner-column .read-more:hover,
.boxcar-inspiration-section-two .right-box-two .uper-box .btn-box .read-more:hover,
.header-style-v1 .header-inner .right-box .header-btn-eight:hover,
.why-choose-us-section .content-column-two .boxcar-title .read-more:hover,
.header-style-v7 .btn .header-btn-two:hover,
.boxcar-pricing-section-five .content-column .inner-column .read-more:hover,
.boxcar-pricing-section-six .content-column .inner-column .read-more:hover,
.cars-section-fifteen .inner-column .btn-box .shoping-btn:hover,
.theme-btn-web:hover,
.dealer-ship-section-two .side-bar .message:hover,
.vehicles-section-three .right-box .auther-info-box .inner-column .auther-info .btn-box .button:hover,
.inventory-section .side-bar-column .inner-column .contact-box .content-box .btn-box .side-btn:hover,
.inventory-section .side-bar-column .inner-column .contact-box .content-box .btn-box .side-btn.two:hover,
.inventory-section .side-bar-column .inner-column .contact-box-two .btn-box .side-btn:hover {
  background: #0146A6 !important;
  border-color: transparent;
  color: #fff;
}

.inventory-section .side-bar-column .inner-column .contact-box .content-box .btn-box .side-btn:hover {
  background: #4E6CFB !important;
  border-color: transparent;
  color: #fff;
}


.car-block-two .inner-box .image-box .icon-box:hover {
  background-color: var(--theme-color1);
}

.car-block-two .inner-box .image-box .icon-box:hover svg path {
  fill: #fff;
}

.blog-block .inner-box .content-box .title:hover {
  color: var(--theme-color1);
}

.brand-boxcar-banner-section .content-column .inner-column .btn:hover {
  background: #fff;
  color: #000;
  border-color: transparent;
}



.car-block-six .inner-box .image-box .image {
  border-radius: 0;
}

.boxcar-banner-section-six .content-column .inner-column .btn-box .read-more svg path {
  fill: #000;
}

.dealership-block .inner-box .content-box .read-more svg path {
  fill: var(--theme-color1);
}

.boxcar-banner-section-six .content-column .inner-column .btn-box .read-more:hover svg path,
.dealership-block .inner-box .content-box .read-more:hover svg path {
  fill: #fff;
}

.brand-boxcar-banner-section .content-column .inner-column .btn:hover svg path {
  fill: #111;
}

.testimonial-block-four .inner-box:hover .image img {
  -webkit-transform: unset;
  transform: unset;
}

.team-block-three .inner-box .image {
  border-radius: 15px;
}

.team-block-three .inner-box:hover .image img {
  border-radius: 15px;
}

.blog-block .inner-box .image {
  border-radius: 15px;
}

.news-letter-section .form-column .inner-column .subscribe-form .form-group button:hover,
.boxcar-banner-section-nine .banner-content .form-submit button:hover,
.hheader-style-v4.v10 .btn .header-btn-two:hover {
  color: #fff;
}

.boxcar-banner-section-nine .banner-content .content-box .btn-box .read-more:hover svg path {
  fill: #000;
}

.blog-section-five .right-box-two .content-box.two .review:hover,
.cars-section-fifteen .content-sec .content-column .inner-column .content-box.two .review:hover,
.dealer-ship-section-two .inner-column .brand-box .content-box .brand-btn:hover,
.dealer-ship-section-two .inner-column .content-box.two .review:hover,
.inventory-section .inspection-column .inner-column .content-box.two .review:hover {
  background-color: var(--theme-color1);
  color: #fff;
  border-color: transparent;
}

.blog-section-five .right-box-two .content-box.two .review:hover svg path,
.cars-section-fifteen .content-sec .content-column .inner-column .content-box.two .review:hover svg path,
.dealer-ship-section-two .inner-column .brand-box .content-box .brand-btn:hover svg path,
.dealer-ship-section-two .inner-column .content-box.two .review:hover svg path,
.inventory-section .inspection-column .inner-column .content-box.two .review:hover svg path {
  fill: #fff;
}

.inventory-section .side-bar-column .inner-column .contact-box .content-box .btn-box .side-btn.two:hover svg path {
  fill: #fff;
}

.title>a:hover,
.text>a:hover {
  color: var(--theme-color1);
}

.inventory-section .inspection-column .inner-column .description-sec .des-list li img {
  margin-right: 10px;
}

.inventory-section .side-bar-column.v3 .contact-box-two .btn-box .side-btn.two:hover,
.inventory-section .side-bar-column .inner-column .contact-box-two .btn-box .side-btn.two:hover {
  background-color: transparent !important;
  color: #000;
}

.hheader-style-v4.five .btn .header-btn-two:hover {
  color: #fff;
  border-color: transparent;
}

/*** 

====================================================================
  Search Popup
====================================================================

***/
.search-popup {
  position: fixed;
  left: 0;
  top: 0;
  height: 100vh;
  width: 100%;
  z-index: 999999;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  overflow: hidden;
  -webkit-transform: scale(0.95);
  transform: scale(0.95);
}

.search-popup .search-back-drop {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background-color: var(--theme-color-dark);
  opacity: 0.95;
}

.search-popup .close-search {
  position: absolute;
  top: 30px;
  right: 30px;
  font-size: 26px;
  color: var(--theme-color-light);
  z-index: 3;
  border-radius: 50%;
  background-color: transparent;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.search-popup .search-inner {
  position: relative;
  display: block;
  top: 40%;
  height: auto;
  z-index: 1;
  width: calc(100% - 60px);
  max-width: 800px;
  margin: auto;
  opacity: 0;
  -webkit-transform: translateY(-50px);
  transform: translateY(-50px);
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}

.search-popup .form-group {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.search-popup .form-group input[type=search],
.search-popup .form-group input[type=text] {
  position: relative;
  display: block;
  line-height: 20px;
  font-size: 16px;
  width: 100%;
  height: 50px;
  border: 1px solid #e1e6dc;
  padding: 15px 20px;
  color: #707070;
  background: #ffffff;
  border-radius: 5px;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.search-popup .form-group input[type=search]:focus,
.search-popup .form-group input[type=text]:focus {
  border-color: var(--border-theme-color2);
}

.search-popup .form-group button {
  position: absolute;
  right: 5px;
  top: 5px;
  height: 40px;
  width: 40px;
  display: block;
  font-size: 18px;
  color: var(--theme-color-dark);
  line-height: 40px;
  border-radius: 5px;
  font-weight: normal;
  background: #ffffff;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.search-popup .form-group button:hover {
  color: var(--theme-color-dark);
}

.search-popup textarea::-webkit-input-placeholder,
.search-popup input::-webkit-input-placeholder {
  color: inherit;
}

.search-popup textarea::-moz-placeholder,
.search-popup input::-moz-placeholder {
  color: inherit;
}

.search-popup textarea:-ms-input-placeholder,
.search-popup input:-ms-input-placeholder {
  color: inherit;
}

.search-popup textarea::-ms-input-placeholder,
.search-popup input::-ms-input-placeholder {
  color: inherit;
}

.search-popup textarea::placeholder,
.search-popup input::placeholder {
  color: inherit;
}

.moblie-search-active .search-popup {
  opacity: 1;
  visibility: visible;
  -webkit-transform: scale(1);
  transform: scale(1);
  border-radius: 0%;
}

.moblie-search-active .search-popup .search-inner {
  opacity: 1;
  -webkit-transform: translateY(0);
  transform: translateY(0);
  -webkit-transition-delay: 500ms;
  transition-delay: 500ms;
}

/*** 

====================================================================
Main Header
====================================================================

***/
.header-span {
  position: relative;
  height: 110px;
  display: block;
  width: 100%;
}

.boxcar-header {
  position: relative;
  width: 100%;
  z-index: 999999;
}

.header-top {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.header-top .header-top-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 24px 0 10px;
}

.header-top .top-left {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.header-top .top-left .info-list {
  color: #ffffff;
  font-size: 14px;
}

.header-top .top-left .info-list span {
  color: #b5cf2b;
  font-size: 14px;
  font-weight: 600;
}

.header-top .top-center {
  margin-left: auto;
  margin-right: auto;
}

.header-top .top-right {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.header-top .top-right button {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: #ffffff;
  font-size: 14px;
  background: transparent;
}

.header-top .top-right button span,
.header-top .top-right button img {
  margin-right: 10px;
  display: inline-block;
}

.header-top .top-right button span {
  font-size: 20px;
}

.header-top .top-right button.login-btn {
  margin-left: 72px;
}

.header-top .top-right button.login-btn i {
  font-size: 18px;
  margin-right: 7px;
}

.boxcar-header .header-inner {
  position: relative;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}

.boxcar-header .header-inner .logo-inner {
  position: relative;
}

.boxcar-header {
  width: 100%;
  z-index: 999;
}

.boxcar-header .logo {
  position: relative;
  display: block;
}

.boxcar-header .logo img {
  max-width: 100%;
  height: auto;
}

.boxcar-header .c-box {
  position: relative;
  left: 0px;
  top: 0px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}

.boxcar-header .c-box .nav-out-bar {
  position: relative;
  margin-left: 230px;
  margin-right: auto;
}

.main-menu {
  position: relative;
}

@media (max-width: 991.98px) {
  .main-menu {
    display: none;
  }
}

.main-menu .navbar-header {
  display: none;
}

.main-menu .navbar-collapse {
  padding: 0px;
}

.main-menu .navigation {
  position: relative;
  margin: 0px;
}

.main-menu .navigation>li {
  position: relative;
  float: left;
  margin: 0 18px;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}

.main-menu .navigation>li:last-child {
  margin-right: 0;
}

.main-menu .navigation>li>a {
  position: relative;
  display: block;
  text-align: center;
  opacity: 1;
  color: #ffffff;
  font-size: 15px;
  line-height: 30px;
  padding: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}

.boxcar-header .right-box {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 100%;
}

.boxcar-header .right-box .header-btn {
  display: inline-block;
  color: #ffffff;
  text-transform: capitalize;
  font-weight: 600;
  font-size: 15px;
  padding: 0 5px 0 38px;
  height: 60px;
  line-height: 60px;
  border: 1px solid #fff;
  border-radius: 50px;
}

.boxcar-header .right-box .header-btn span {
  margin-left: 10px;
  position: relative;
  top: -2px;
  display: inline-block;
  width: 50px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  border-radius: 50%;
  background-color: var(--theme-color1);
}

.boxcar-header .right-box .header-btn span i {
  color: #000;
  font-size: 22px;
  font-weight: 900;
  top: 4px;
  position: relative;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.boxcar-header .right-box .header-btn:hover {
  background-color: var(--theme-color1);
  border-color: transparent;
}

.boxcar-header .ui-btn-outer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 6px 0;
  border-left: 1px solid rgba(255, 255, 255, 0.5);
  padding-left: 17px;
  position: relative;
  right: 117px;
}

.boxcar-header .ui-btn {
  position: relative;
  display: block;
  height: 30px;
  width: 30px;
  line-height: 30px;
  text-align: center;
  background: none;
  font-size: 26px;
  color: #ffffff;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}

.boxcar-header .ui-btn:hover {
  color: var(--theme-color1);
}

.boxcar-header .info-btn {
  position: relative;
  font-size: 16px;
  padding-left: 44px;
  color: #ffffff;
  text-align: left;
  font-weight: 500;
  white-space: nowrap;
  line-height: 20px;
}

.boxcar-header .info-btn small {
  display: block;
  font-size: 10px;
  font-weight: 500;
  text-transform: uppercase;
  color: var(--theme-color1);
}

.boxcar-header .info-btn .icon {
  position: absolute;
  left: 0;
  top: 50%;
  margin-top: -12px;
  line-height: 40px;
  height: 40px;
  font-size: 24px;
  line-height: 1em;
  margin-right: 20px;
  padding-right: 20px;
}

.boxcar-header .info-btn:hover {
  color: var(--theme-color-light);
}

/*** 

====================================================================
    header-style-v1
====================================================================

***/
.header-style-v1 {
  position: absolute;
}

.header-style-v1.style-two {
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  padding-bottom: 10px;
  padding-top: 10px;
}

.header-style-v1.style-two .header-inner {
  padding: 0;
}

.header-style-v1 .btn {
  padding-right: 0;
}

.header-style-v1 .btn .header-btn-two {
  height: 46px;
  line-height: 46px;
  font-weight: 500;
  color: var(--theme-color-dark);
  font-size: 15px;
  padding:0 16px;
  border-radius: 50px;
  background-color: var(--theme-color-light);
  text-transform: capitalize;
  display: inline-block;
}

.header-style-v1 .btn .header-btn-two:hover {
  color: #fff;
  background-color: var(--theme-color1);
}

.header-style-v1 .header-inner {
  padding: 28px 0 0;
}

.header-style-v1.home-8 .header-inner {
  padding-top: 60px;
}

@media (max-width: 1440px) {
  .header-style-v1.home-8 .header-inner {
    padding-top: 30px;
  }
}

.header-style-v1 .header-inner .inner-container {
  max-width: 1829px;
  margin: 0 auto;
  padding: 0 15px;
}

.header-style-v1 .header-inner .c-box .logo-inner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.header-style-v1 .header-inner .c-box .logo-inner .logo {
  margin-right: 62px;
  /* Medium Large devices */
}

@media (max-width: 1399px) {
  .header-style-v1 .header-inner .c-box .logo-inner .logo {
    margin-right: 30px;
  }
}

.header-style-v1 .header-inner .c-box .logo-inner .logo img {
  max-width: 110px;
}

.header-style-v1 .header-inner .c-box .logo-inner .search-btn a {
  color: var(--theme-color-light);
  font-size: 15px;
  display: flex;
}

.header-style-v1 .header-inner .c-box .logo-inner .search-btn a .icon {
  margin-right: 13px;
  margin-top: -2px;
}

.header-style-v1 .header-inner .c-box .nav-out-bar {
  margin-left: auto;
  margin-right: 31px;
  /* Medium Large devices */
}

@media (max-width: 1399px) {
  .header-style-v1 .header-inner .c-box .nav-out-bar {
    margin-left: 20px;
  }
}

@media (max-width: 1399.98px) {
  .header-style-v1 .header-inner .c-box .nav-out-bar .nav {
    display: unset;
  }
}

@media (max-width: 1199.98px) {
  .header-style-v1 .header-inner .c-box .nav-out-bar .nav {
    display: none;
  }
}

.header-style-v1 .header-inner .c-box .nav-out-bar .nav .navigation .current-dropdown i {
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  font-size: 12px;
  margin-left: 8px;
}

.header-style-v1 .header-inner .c-box .nav-out-bar .nav .navigation .current-dropdown:hover>.dropdown {
  opacity: 1;
  visibility: visible;
  margin-top: 0;
}

.header-style-v1 .header-inner .c-box .nav-out-bar .nav .navigation .current-dropdown:hover i {
  -webkit-transform: rotateX(-180deg);
  transform: rotateX(-180deg);
}

.header-style-v1 .header-inner .c-box .nav-out-bar .nav .navigation .current-dropdown .dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #fff;
  padding: 2px 0 5px;
  margin-top: 30px;
  margin-bottom: 0;
  min-width: 243px;
  z-index: 99;
  visibility: hidden;
  opacity: 0;
  -webkit-box-shadow: 0px 6px 15px 0px rgba(64, 79, 104, 0.05);
  box-shadow: 0px 6px 15px 0px rgba(64, 79, 104, 0.05);
  border-radius: 16px;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  padding: 25px 20px;
}

.header-style-v1 .header-inner .mega-menu {
  -webkit-box-shadow: 0px 6px 15px 0px rgba(64, 79, 104, 0.05);
  box-shadow: 0px 6px 15px 0px rgba(64, 79, 104, 0.05);
}

.header-style-v1 .header-inner .c-box .nav-out-bar .nav .navigation .current-dropdown .dropdown li {
  padding: 0;
  margin-bottom: 10px;
}

.header-style-v1 .header-inner .c-box .nav-out-bar .nav .navigation .current-dropdown .dropdown li:last-child {
  margin-bottom: 0;
}

.header-style-v1 .header-inner .c-box .nav-out-bar .nav .navigation .current-dropdown .dropdown li a {
  display: block;
  color: #050B20;
  font-size: 15px;
  -webkit-transition: all 220ms linear 0ms;
  transition: all 220ms linear 0ms;
  text-transform: capitalize;
}

.header-style-v1 .header-inner .c-box .nav-out-bar .nav .navigation .current-dropdown .dropdown li a:hover {
  color: var(--theme-color1);
}

.header-style-v1 .header-inner .c-box .nav-out-bar .nav .navigation .current-dropdown .dropdown li:last-child a {
  border-bottom: 0;
}

.header-style-v1 .header-inner .right-box .header-btn-eight {
  height: 46px;
  line-height: 46px;
  font-weight: 500;
  color: var(--theme-color-light);
  font-size: 15px;
  padding: 0 26px;
  border-radius: 16px;
  border: 1px solid var(--theme-color-light);
  text-transform: capitalize;
  display: inline-block;
}

.header-style-v1 .header-inner .right-box .contact-info {
  position: relative;
  padding-left: 50px;
}

.header-style-v1 .header-inner .right-box .contact-info .image {
  position: absolute;
  top: 12px;
  left: 0;
}

.header-style-v1 .header-inner .right-box .contact-info span {
  font-size: 12px;
  color: var(--theme-color-light);
}

.header-style-v1 .header-inner .right-box .contact-info .title {
  color: var(--theme-color-light);
  margin-top: -4px;
  margin-bottom: 0;
}

.header-style-v1 .header-inner .right-box .social-list-one {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-left: 71px;
  position: relative;
}

.header-style-v1 .header-inner .right-box .social-list-one::before {
  content: "";
  position: absolute;
  top: -10px;
  left: -31px;
  width: 2px;
  height: 50px;
  background-color: #525c5c;
}

.header-style-v1 .header-inner .right-box .social-list-one li {
  margin-right: 48px;
}

.header-style-v1 .header-inner .right-box .social-list-one li:last-child {
  margin-right: 0;
}

.header-style-v1 .header-inner .right-box .social-list-one li a {
  font-size: 24px;
  color: var(--theme-color-light);
}

.header-style-v1 .header-inner .sticky-header {
  background-color: var(--theme-color-v2);
}

.header-style-v1 .header-inner .sticky-header .mobile-navigation {
  display: none;
}

.header-style-v1 .header-inner .sticky-header .main-menu .navigation>li {
  padding: 0;
}

.header-style-v1 .header-inner .sticky-header.fixed-header {
  height: 70px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background: #000;
}

.header-style-v1 .header-inner .sticky-header .main-menu {
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.header-style-v1 .header-inner .sticky-header .main-menu .navigation>li.current>a,
.header-style-v1 .header-inner .sticky-header .main-menu .navigation>li:hover>a {
  color: #fff;
}

.header-style-v1 .header-inner .sticky-header .inner-container {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

@media (max-width: 767.98px) {
  .header-style-v1 .header-inner .contact-info {
    display: none;
  }
}

@media (max-width: 575.98px) {
  .header-style-v1 .header-inner .contact-info {
    display: unset;
    width: 232px;
    margin-left: -170px;
  }
}

.header-style-v1 .header-inner .right-box {
  position: relative;
  margin-left: 0;
}

.header-inner .right-box .box-account {
  display: flex;
  align-items: center;
  gap: 9px;
  margin-right: 18px;
  color: var(--theme-color-light);
  font-size: 15px;
}

.boxcar-header.five .header-inner .right-box .box-account {
  color: var(--theme-color-dark);
}

.header-inner .right-box .box-account .icon {
  margin-top: -4px;
}

@media (max-width: 767.98px) {
  .header-style-v1 .header-inner .right-box:before {
    display: none;
  }
}

.header-style-v1 .header-inner .right-box>a {
  font-size: 15px;
  color: var(--theme-color-light);
  margin-right: 18px;
}

.header-style-v1 .header-inner .right-box>a i {
  color: var(--theme-color-light);
  font-size: 16px;
  margin-right: 10px;
}



.header-style-v1.inner-header {
  position: static;
  background-color: var(--theme-color-dark);
}

.header-style-v1.inner-header .header-btn-two {
  background-color: var(--theme-color-dark);
  border: 1px solid var(--theme-color-light);
  color: var(--theme-color-light);
}

/*** 

====================================================================
    header-style-v3
====================================================================

***/
.header-style-v3 {
  position: absolute;
}

.header-style-v3 .btn {
  padding-right: 0;
}

.header-style-v3 .btn .header-btn-two {
  height: 46px;
  line-height: 46px;
  font-weight: 500;
  color: var(--theme-color-light);
  font-size: 15px;
  padding: 0 26px;
  border-radius: 16px;
  border: 1px solid var(--theme-color-light);
  text-transform: capitalize;
  display: inline-block;
}

.header-style-v3 .header-inner {
  padding: 13px 0 0;
}

.header-style-v3 .header-inner .inner-container {
  max-width: 1829px;
  margin: 0 auto;
  padding: 0 15px;
}

.header-style-v3 .header-inner .c-box {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.header-style-v3 .header-inner .c-box .logo-inner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: baseline;
  -ms-flex-align: baseline;
  align-items: baseline;
}

.header-style-v3 .header-inner .c-box .logo-inner .menu {
  font-size: 15px;
  color: var(--theme-color-light);
  text-transform: capitalize;
  margin-right: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-right: 20px;
}

.header-style-v3 .header-inner .c-box .logo-inner .menu .menu-btn {
  width: 22px;
  margin-right: 10px;
}

.header-style-v3 .header-inner .c-box .logo-inner .menu .menu-btn>span {
  display: block;
  height: 1px;
  background: #fff;
  width: 100%;
  margin-bottom: 5px;
}

.header-style-v3 .header-inner .c-box .logo-inner .menu .menu-btn>span:last-child {
  margin-bottom: 0;
}

.header-style-v3 .header-inner .c-box .logo-inner .menu i {
  margin-right: 13px;
}

.header-style-v3 .header-inner .c-box .logo-inner .logo {
  margin-right: 62px;
}

.header-style-v3 .header-inner .c-box .logo-inner .logo img {
  max-width: 110px;
}

.header-style-v3 .header-inner .c-box .nav-out-bar {
  margin-left: auto;
  margin-right: 31px;
  display: none;
}

@media (max-width: 1399.98px) {
  .header-style-v3 .header-inner .c-box .nav-out-bar {
    margin-left: 157px;
  }
}

@media (max-width: 1399.98px) {
  .header-style-v3 .header-inner .c-box .nav-out-bar .nav {
    display: unset;
  }
}

@media (max-width: 1199.98px) {
  .header-style-v3 .header-inner .c-box .nav-out-bar .nav {
    display: none;
  }
}

.header-style-v3 .header-inner .c-box .nav-out-bar .nav .navigation li a {
  color: var(--theme-color-dark);
  font-weight: 500;
}

.header-style-v3 .header-inner .c-box .nav-out-bar .nav .navigation li a:hover {
  color: var(--theme-color1);
}

.header-style-v3 .header-inner .c-box .nav-out-bar .nav .navigation .current-dropdown i {
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  font-size: 12px;
  margin-left: 5px;
}

.header-style-v3 .header-inner .c-box .nav-out-bar .nav .navigation .current-dropdown:hover .dropdown {
  opacity: 1;
  visibility: visible;
  margin-top: 17px;
}

.header-style-v3 .header-inner .c-box .nav-out-bar .nav .navigation .current-dropdown:hover i {
  -webkit-transform: rotateX(-180deg);
  transform: rotateX(-180deg);
}

.header-style-v3 .header-inner .c-box .nav-out-bar .nav .navigation .current-dropdown .dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  background-color: var(--theme-color1);
  padding: 2px 0 5px;
  margin-top: 30px;
  margin-bottom: 0;
  min-width: 220px;
  z-index: 99;
  visibility: hidden;
  opacity: 0;
  -webkit-box-shadow: 0 15px 30px -10px rgba(0, 0, 0, 0.09);
  box-shadow: 0 15px 30px -10px rgba(0, 0, 0, 0.09);
  border-radius: 7px;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.header-style-v3 .header-inner .c-box .nav-out-bar .nav .navigation .current-dropdown .dropdown li {
  padding: 0 18px;
  white-space: nowrap;
}

.header-style-v3 .header-inner .c-box .nav-out-bar .nav .navigation .current-dropdown .dropdown li a {
  padding: 9px 0 5px;
  display: block;
  color: #fff;
  font-size: 14px;
  border-bottom: 1px solid rgba(251, 251, 251, 0.5);
  -webkit-transition: all 220ms linear 0ms;
  transition: all 220ms linear 0ms;
}

.header-style-v3 .header-inner .c-box .nav-out-bar .nav .navigation .current-dropdown .dropdown li:last-child a {
  border-bottom: 0;
}

.header-style-v3 .header-inner .main-menu .navigation>li>a {
  color: var(--theme-color-dark);
  font-size: 16px;
  font-family: var(--text-font);
}

.header-style-v3 .header-inner .main-menu .navigation>li>a:hover {
  color: var(--theme-color1);
}

.header-style-v3 .header-inner .right-box .search-btn {
  margin-right: 31px;
}

.header-style-v3 .header-inner .right-box .search-btn a {
  color: var(--theme-color-light);
  font-size: 15px;
}

.header-style-v3 .header-inner .right-box .search-btn a i {
  margin-right: 13px;
}

.header-style-v3 .header-inner .right-box .contact-info {
  position: relative;
  padding-left: 50px;
}

.header-style-v3 .header-inner .right-box .contact-info .image {
  position: absolute;
  top: 12px;
  left: 0;
}

.header-style-v3 .header-inner .right-box .contact-info span {
  font-size: 12px;
  color: var(--theme-color-light);
}

.header-style-v3 .header-inner .right-box .contact-info .title {
  color: var(--theme-color-light);
  margin-top: -4px;
  margin-bottom: 0;
}

.header-style-v3 .header-inner .right-box .social-list-one {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-left: 71px;
  position: relative;
}

.header-style-v3 .header-inner .right-box .social-list-one::before {
  content: "";
  position: absolute;
  top: -10px;
  left: -31px;
  width: 2px;
  height: 50px;
  background-color: #525c5c;
}

.header-style-v3 .header-inner .right-box .social-list-one li {
  margin-right: 48px;
}

.header-style-v3 .header-inner .right-box .social-list-one li:last-child {
  margin-right: 0;
}

.header-style-v3 .header-inner .right-box .social-list-one li a {
  font-size: 24px;
  color: var(--theme-color-light);
}

.header-style-v3 .header-inner .main-menu .navigation>li {
  padding: 0;
  margin: 0 18px;
}

.header-style-v3 .header-inner .main-menu .navigation>li:last-child {
  margin-right: 0;
}

.header-style-v3 .header-inner .c-box .nav-out-bar .nav .navigation li a {
  color: var(--theme-color-light);
  font-size: 15px;
}

.header-style-v3 .header-inner .c-box .nav-out-bar .nav .navigation li a:hover {
  color: var(--theme-color1);
}

.header-style-v3 .header-inner .main-menu .navigation>li>ul>li>a:hover {
  color: var(--theme-color-v2);
}

.header-style-v3 .header-inner .sticky-header {
  background-color: var(--theme-color-v2);
}

.header-style-v3 .header-inner .sticky-header .mobile-navigation {
  display: none;
}

.header-style-v3 .header-inner .sticky-header .main-menu .navigation>li.current>a,
.header-style-v3 .header-inner .sticky-header .main-menu .navigation>li:hover>a {
  color: var(--theme-color-v2);
}

.header-style-v3 .header-inner .sticky-header .main-menu .navigation>li {
  padding: 0;
}

.header-style-v3 .header-inner .sticky-header.fixed-header {
  height: 70px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background: #000;
}

.header-style-v3 .header-inner .sticky-header .main-menu {
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.header-style-v3 .header-inner .sticky-header .main-menu .navigation>li.current>a,
.header-style-v3 .header-inner .sticky-header .main-menu .navigation>li:hover>a {
  color: #fff;
}

.header-style-v3 .header-inner .sticky-header .inner-container {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

@media (max-width: 767.98px) {
  .header-style-v3 .header-inner .contact-info {
    display: none;
  }
}

@media (max-width: 575.98px) {
  .header-style-v3 .header-inner .contact-info {
    display: unset;
    width: 232px;
    margin-left: -170px;
  }
}

.header-style-v3 .header-inner .right-box {
  position: relative;
}

@media (max-width: 767.98px) {
  .header-style-v3 .header-inner .right-box:before {
    display: none;
  }
}

.header-style-v3 .header-inner .right-box span {
  font-size: 15px;
  color: var(--theme-color-light);
  margin-right: 18px;
}

.header-style-v3 .header-inner .right-box span i {
  color: var(--theme-color-light);
  font-size: 16px;
  margin-right: 10px;
}

/*** 

====================================================================
    hheader-style-v4
====================================================================

***/
.hheader-style-v4 {
  position: absolute;
}

.hheader-style-v4.style-two {
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  padding-bottom: 10px;
  padding-top: 10px;
}

.hheader-style-v4.style-two .header-inner {
  padding: 0;
}

.hheader-style-v4 .btn {
  padding-right: 0;
}

.hheader-style-v4 .btn .header-btn-two {
  height: 46px;
  line-height: 46px;
  font-weight: 500;
  color: var(--theme-color-light);
  font-size: 15px;
  padding: 0 26px;
  border-radius: 16px;
  border: 1px solid var(--theme-color-light);
  text-transform: capitalize;
  display: inline-block;
}

.hheader-style-v4 .header-inner {
  padding: 28px 0 0;
}

.hheader-style-v4 .header-inner .inner-container {
  max-width: 1829px;
  margin: 0 auto;
  padding: 0 15px;
}

.hheader-style-v4 .header-inner .c-box .logo-inner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.hheader-style-v4 .header-inner .c-box .logo-inner .logo {
  margin-right: 62px;
}

.hheader-style-v4 .header-inner .c-box .logo-inner .logo img {
  max-width: 110px;
}

.box-phone-cus {
  display: flex;
  align-items: center;
  gap: 9px;
}

.box-phone-cus .icon {
  margin-top: -4px;
}

.hheader-style-v4 .header-inner .c-box .logo-inner .search-btn .search {
  color: var(--theme-color-light);
  font-size: 15px;
}

.hheader-style-v4 .header-inner .c-box .logo-inner .search-btn .search i {
  margin-right: 13px;
}

.hheader-style-v4 .header-inner .c-box .nav-out-bar {
  margin-left: auto;
  margin-right: 60px;
}

.hheader-style-v4.style-1 .header-inner .c-box .nav-out-bar {
  margin-right: auto;
}

@media (max-width: 1399.98px) {
  .hheader-style-v4 .header-inner .c-box .nav-out-bar {
    margin-left: 157px;
  }
}

@media (max-width: 1399.98px) {
  .hheader-style-v4 .header-inner .c-box .nav-out-bar .nav {
    display: unset;
  }
}

@media (max-width: 1199.98px) {
  .hheader-style-v4 .header-inner .c-box .nav-out-bar .nav {
    display: none;
  }
}

.hheader-style-v4 .header-inner .c-box .nav-out-bar .nav .navigation .current-dropdown i {
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  font-size: 12px;
  margin-left: 8px;
}

.hheader-style-v4 .header-inner .c-box .nav-out-bar .nav .navigation .current-dropdown:hover>.dropdown {
  opacity: 1;
  visibility: visible;
  margin-top: 17px;
}

.hheader-style-v4 .header-inner .c-box .nav-out-bar .nav .navigation .current-dropdown:hover i {
  -webkit-transform: rotateX(-180deg);
  transform: rotateX(-180deg);
}

.hheader-style-v4 .header-inner .c-box .nav-out-bar .nav .navigation .current-dropdown .dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #fff;
  padding: 2px 0 5px;
  margin-top: 30px;
  margin-bottom: 0;
  min-width: 243px;
  z-index: 999;
  visibility: hidden;
  opacity: 0;
  -webkit-box-shadow: 0px 6px 15px 0px rgba(64, 79, 104, 0.05);
  box-shadow: 0px 6px 15px 0px rgba(64, 79, 104, 0.05);
  border-radius: 16px;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  padding: 25px 20px;
}

.hheader-style-v4 .header-inner .c-box .nav-out-bar .nav .navigation .current-dropdown .dropdown li {
  padding: 0;
  margin-bottom: 10px;
}

.hheader-style-v4 .header-inner .c-box .nav-out-bar .nav .navigation .current-dropdown .dropdown li:last-child {
  margin-bottom: 0;
}

.hheader-style-v4 .header-inner .c-box .nav-out-bar .nav .navigation .current-dropdown .dropdown li a {
  display: block;
  color: #050B20;
  font-size: 15px;
  -webkit-transition: all 220ms linear 0ms;
  transition: all 220ms linear 0ms;
  text-transform: capitalize;
}

.hheader-style-v4 .header-inner .c-box .nav-out-bar .nav .navigation .current-dropdown .dropdown li a:hover {
  color: var(--theme-color1);
}

.hheader-style-v4 .header-inner .c-box .nav-out-bar .nav .navigation .current-dropdown .dropdown li:last-child a {
  border-bottom: 0;
}

.hheader-style-v4 .header-inner .right-box .contact-info {
  position: relative;
  padding-left: 50px;
}

.hheader-style-v4 .header-inner .right-box .contact-info .image {
  position: absolute;
  top: 12px;
  left: 0;
}

.hheader-style-v4 .header-inner .right-box .contact-info span {
  font-size: 12px;
  color: var(--theme-color-light);
}

.hheader-style-v4 .header-inner .right-box .contact-info .title {
  color: var(--theme-color-light);
  margin-top: -4px;
  margin-bottom: 0;
}

.hheader-style-v4 .header-inner .right-box .social-list-one {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-left: 71px;
  position: relative;
}

.hheader-style-v4 .header-inner .right-box .social-list-one::before {
  content: "";
  position: absolute;
  top: -10px;
  left: -31px;
  width: 2px;
  height: 50px;
  background-color: #525c5c;
}

.hheader-style-v4 .header-inner .right-box .social-list-one li {
  margin-right: 48px;
}

.hheader-style-v4 .header-inner .right-box .social-list-one li:last-child {
  margin-right: 0;
}

.hheader-style-v4 .header-inner .right-box .social-list-one li a {
  font-size: 24px;
  color: var(--theme-color-light);
}

.hheader-style-v4 .header-inner .sticky-header {
  background-color: var(--theme-color-v2);
}

.hheader-style-v4 .header-inner .sticky-header .mobile-navigation {
  display: none;
}

.hheader-style-v4 .header-inner .sticky-header .main-menu .navigation>li {
  padding: 0;
}

.hheader-style-v4 .header-inner .sticky-header.fixed-header {
  height: 70px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background: #000;
}

.hheader-style-v4 .header-inner .sticky-header .main-menu {
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.hheader-style-v4 .header-inner .sticky-header .main-menu .navigation>li.current>a,
.hheader-style-v4 .header-inner .sticky-header .main-menu .navigation>li:hover>a {
  color: #fff;
}

.hheader-style-v4 .header-inner .sticky-header .inner-container {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

@media (max-width: 767.98px) {
  .hheader-style-v4 .header-inner .contact-info {
    display: none;
  }
}

@media (max-width: 575.98px) {
  .hheader-style-v4 .header-inner .contact-info {
    display: unset;
    width: 232px;
    margin-left: -170px;
  }
}

.hheader-style-v4 .header-inner .right-box {
  position: relative;
}

@media (max-width: 767.98px) {
  .hheader-style-v4 .header-inner .right-box:before {
    display: none;
  }
}

.hheader-style-v4 .header-inner .right-box .user {
  font-size: 15px;
  color: var(--theme-color-light);
  margin-right: 18px;
}

.hheader-style-v4 .header-inner .right-box .user i {
  color: var(--theme-color-light);
  font-size: 16px;
  margin-right: 10px;
}

.hheader-style-v4 .header-inner .right-box .search {
  color: var(--theme-color-light);
  font-size: 15px;
  line-height: 26px;
  margin-right: 31px;
  display: flex;
  gap: 10px;
  font-weight: 500;
}

.hheader-style-v4 .header-inner .right-box .search .icon {
  margin-top: -1px;
}

.hheader-style-v4 .header-inner .right-box .search i {
  margin-right: 13px;
}

.hheader-style-v4.v6 .header-inner {
  padding-top: 13px;
}

.hheader-style-v4.v6 .nav-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.hheader-style-v4.v6 .c-box {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.hheader-style-v4.v6 .c-box .logo-inner .logo {
  margin-right: 12px;
}

.hheader-style-v4.v6 .c-box .nav-out-bar {
  margin-left: 0;
}

.hheader-style-v4.v6 .main-menu .navigation>li>a {
  color: var(--theme-color-dark);
}

.hheader-style-v4.v10 {
  position: static;
}

.hheader-style-v4.v10 .header-inner {
  padding: 20px 0;
}

.hheader-style-v4.v10 .nav-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.hheader-style-v4.v10 .c-box {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.hheader-style-v4.v10 .c-box .logo-inner .logo {
  margin-right: 12px;
}

.hheader-style-v4.v10 .c-box .nav-out-bar {
  margin-left: 30px;
}

.hheader-style-v4.v10 .main-menu .navigation>li>a {
  color: var(--theme-color-dark);
}

.hheader-style-v4.v10 .btn .header-btn-two {
  border-color: var(--theme-color-dark);
  color: var(--theme-color-dark);
}

.hheader-style-v4.v10 .right-box .user {
  color: var(--theme-color-dark);
}

.hheader-style-v4.v10 .right-box .user i {
  color: var(--theme-color-dark);
}

.hheader-style-v4.v10 .right-box .search {
  color: var(--theme-color-dark);
  display: flex;
  align-items: center;
}

/*** 

====================================================================
    header-style-five
====================================================================

***/
.hheader-style-v4.five {
  position: static;
  background: #fff;
  padding: 15px 0;
}

.hheader-style-v4.five .header-inner {
  padding: 0;
}

.hheader-style-v4.five .btn .header-btn-two {
  color: var(--theme-color-dark);
  border-color: var(--theme-color-dark);
}

.hheader-style-v4.five .c-box .logo-inner .search-btn .search {
  color: var(--theme-color-dark);
}

.hheader-style-v4.five .main-menu .navigation>li>a {
  color: var(--theme-color-dark);
}

.hheader-style-v4.five .right-box .search {
  color: var(--theme-color-dark);
}

.hheader-style-v4.five .right-box .user {
  color: var(--theme-color-dark);
}

.hheader-style-v4.five .right-box .user i {
  color: var(--theme-color-dark);
}

/*** 

====================================================================
    header-style-v7
====================================================================

***/
.header-style-v7 .btn {
  padding-right: 0;
}

.header-style-v7 .btn .header-btn-two {
  height: 46px;
  line-height: 46px;
  font-weight: 500;
  color: var(--theme-color-dark);
  font-size: 15px;
  padding: 0 26px;
  border-radius: 120px;
  border: 1px solid #E1E1E1;
  text-transform: capitalize;
  display: inline-block;
}

.header-style-v7 .header-inner {
  padding: 13px 0 13px;
  border-bottom: 1px solid #e1e1e1;
  margin-bottom: 28px;
}

.header-style-v7 .header-inner .inner-container {
  max-width: 1829px;
  margin: 0 auto;
  padding: 0 15px;
}

.header-style-v7 .header-inner .c-box {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.header-style-v7 .header-inner .c-box .logo-inner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.header-style-v7 .header-inner .c-box .logo-inner .menu {
  font-size: 15px;
  color: var(--theme-color-dark);
  text-transform: capitalize;
  margin-right: 31px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.header-style-v7 .header-inner .c-box .logo-inner .menu .menu-btn {
  width: 22px;
  margin-right: 10px;
}

.header-style-v7 .header-inner .c-box .logo-inner .menu .menu-btn>span {
  display: block;
  height: 1px;
  background: var(--theme-color-dark);
  width: 100%;
  margin-bottom: 5px;
}

.header-style-v7 .header-inner .c-box .logo-inner .menu .menu-btn>span:last-child {
  margin-bottom: 0;
}

.header-style-v7 .header-inner .c-box .logo-inner .menu i {
  margin-right: 13px;
}

.header-style-v7 .header-inner .c-box .logo-inner .search {
  color: var(--theme-color-dark);
  font-size: 15px;
  margin-right: 31px;
  display: flex;
  gap: 7px;
}

.header-style-v7 .header-inner .c-box .logo-inner .search .icon {
  margin-top: -2px;
}

.header-style-v7 .header-inner .c-box .logo-inner .search i {
  margin-right: 13px;
}

.header-style-v7 .header-inner .c-box .logo {
  right: -160px;
}

.header-style-v7 .header-inner .c-box .nav-out-bar {
  margin-left: auto;
  margin-right: 31px;
  display: none;
}

@media (max-width: 1399.98px) {
  .header-style-v7 .header-inner .c-box .nav-out-bar {
    margin-left: 157px;
  }
}

@media (max-width: 1399.98px) {
  .header-style-v7 .header-inner .c-box .nav-out-bar .nav {
    display: unset;
  }
}

@media (max-width: 1199.98px) {
  .header-style-v7 .header-inner .c-box .nav-out-bar .nav {
    display: none;
  }
}

.header-style-v7 .header-inner .c-box .nav-out-bar .nav .navigation li a {
  color: var(--theme-color-dark);
  font-weight: 500;
}

.header-style-v7 .header-inner .c-box .nav-out-bar .nav .navigation li a:hover {
  color: var(--theme-color1);
}

.header-style-v7 .header-inner .c-box .nav-out-bar .nav .navigation .current-dropdown i {
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  font-size: 12px;
  margin-left: 5px;
}

.header-style-v7 .header-inner .c-box .nav-out-bar .nav .navigation .current-dropdown:hover .dropdown {
  opacity: 1;
  visibility: visible;
  margin-top: 17px;
}

.header-style-v7 .header-inner .c-box .nav-out-bar .nav .navigation .current-dropdown:hover i {
  -webkit-transform: rotateX(-180deg);
  transform: rotateX(-180deg);
}

.header-style-v7 .header-inner .c-box .nav-out-bar .nav .navigation .current-dropdown .dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #fff;
  padding: 2px 0 5px;
  margin-top: 30px;
  margin-bottom: 0;
  min-width: 220px;
  z-index: 99;
  visibility: hidden;
  opacity: 0;
  -webkit-box-shadow: 0 15px 30px -10px rgba(0, 0, 0, 0.09);
  box-shadow: 0 15px 30px -10px rgba(0, 0, 0, 0.09);
  border-radius: 7px;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.header-style-v7 .header-inner .c-box .nav-out-bar .nav .navigation .current-dropdown .dropdown li {
  padding: 0 18px;
  white-space: nowrap;
}

.header-style-v7 .header-inner .c-box .nav-out-bar .nav .navigation .current-dropdown .dropdown li a {
  padding: 9px 0 5px;
  display: block;
  color: #fff;
  font-size: 14px;
  border-bottom: 1px solid rgba(251, 251, 251, 0.5);
  -webkit-transition: all 220ms linear 0ms;
  transition: all 220ms linear 0ms;
}

.header-style-v7 .header-inner .c-box .nav-out-bar .nav .navigation .current-dropdown .dropdown li:last-child a {
  border-bottom: 0;
}

.header-style-v7 .header-inner .main-menu .navigation>li>a {
  color: var(--theme-color-dark);
  font-size: 16px;
  font-family: var(--text-font);
}

.header-style-v7 .header-inner .main-menu .navigation>li>a:hover {
  color: var(--theme-color1);
}

.header-style-v7 .header-inner .right-box .search-btn {
  margin-right: 31px;
}

.header-style-v7 .header-inner .right-box .search-btn a {
  color: var(--theme-color-dark);
  font-size: 15px;
  display: flex;
  gap: 10px;
}

.header-style-v7 .header-inner .right-box .search-btn a i {
  margin-right: 13px;
}

.header-style-v7 .header-inner .right-box .contact-info {
  position: relative;
  padding-left: 50px;
}

.header-style-v7 .header-inner .right-box .contact-info .image {
  position: absolute;
  top: 12px;
  left: 0;
}

.header-style-v7 .header-inner .right-box .contact-info span {
  font-size: 12px;
  color: var(--theme-color-light);
}

.header-style-v7 .header-inner .right-box .contact-info .title {
  color: var(--theme-color-light);
  margin-top: -4px;
  margin-bottom: 0;
}

.header-style-v7 .header-inner .right-box .social-list-one {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-left: 71px;
  position: relative;
}

.header-style-v7 .header-inner .right-box .social-list-one::before {
  content: "";
  position: absolute;
  top: -10px;
  left: -31px;
  width: 2px;
  height: 50px;
  background-color: #525c5c;
}

.header-style-v7 .header-inner .right-box .social-list-one li {
  margin-right: 48px;
}

.header-style-v7 .header-inner .right-box .social-list-one li:last-child {
  margin-right: 0;
}

.header-style-v7 .header-inner .right-box .social-list-one li a {
  font-size: 24px;
  color: var(--theme-color-light);
}

.header-style-v7 .header-inner .main-menu .navigation>li {
  padding: 0;
  margin: 0 18px;
}

.header-style-v7 .header-inner .main-menu .navigation>li:last-child {
  margin-right: 0;
}

.header-style-v7 .header-inner .c-box .nav-out-bar .nav .navigation li a {
  color: var(--theme-color-light);
  font-size: 15px;
}

.header-style-v7 .header-inner .c-box .nav-out-bar .nav .navigation li a:hover {
  color: var(--theme-color1);
}

.header-style-v7 .header-inner .main-menu .navigation>li>ul>li>a:hover {
  color: var(--theme-color-v2);
}

.header-style-v7 .header-inner .sticky-header {
  background-color: var(--theme-color-v2);
}

.header-style-v7 .header-inner .sticky-header .mobile-navigation {
  display: none;
}

.header-style-v7 .header-inner .sticky-header .main-menu .navigation>li.current>a,
.header-style-v7 .header-inner .sticky-header .main-menu .navigation>li:hover>a {
  color: var(--theme-color-v2);
}

.header-style-v7 .header-inner .sticky-header .main-menu .navigation>li {
  padding: 0;
}

.header-style-v7 .header-inner .sticky-header.fixed-header {
  height: 70px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background: #000;
}

.header-style-v7 .header-inner .sticky-header .main-menu {
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.header-style-v7 .header-inner .sticky-header .main-menu .navigation>li.current>a,
.header-style-v7 .header-inner .sticky-header .main-menu .navigation>li:hover>a {
  color: #fff;
}

.header-style-v7 .header-inner .sticky-header .inner-container {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

@media (max-width: 767.98px) {
  .header-style-v7 .header-inner .contact-info {
    display: none;
  }
}

@media (max-width: 575.98px) {
  .header-style-v7 .header-inner .contact-info {
    display: unset;
    width: 232px;
    margin-left: -170px;
  }
}

.header-style-v7 .header-inner .right-box {
  position: relative;
}

@media (max-width: 767.98px) {
  .header-style-v7 .header-inner .right-box:before {
    display: none;
  }
}

.header-style-v7 .header-inner .right-box .icon {
  /* font-size: 15px;
  color: var(--theme-color-light);
  margin-right: 18px; */
  margin-top: -2px;
}

.header-style-v7 .header-inner .right-box span i {
  color: var(--theme-color-light);
  font-size: 16px;
  margin-right: 10px;
}

/*** 

====================================================================
    header-style-v9
====================================================================

***/
.header-style-v9 {
  position: absolute;
}

.header-style-v9 .btn {
  padding-right: 0;
}

.header-style-v9 .btn .header-btn-two {
  height: 46px;
  line-height: 46px;
  font-weight: 500;
  color: var(--theme-color-light);
  font-size: 15px;
  padding: 0 26px;
  border-radius: 120px;
  border: 1px solid #E1E1E1;
  text-transform: capitalize;
  display: inline-block;
}

.header-style-v9 .header-inner {
  padding: 13px 0 13px;
}

.header-style-v9 .header-inner .inner-container {
  max-width: 1829px;
  margin: 0 auto;
  padding: 0 15px;
}

.header-style-v9 .header-inner .c-box {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.header-style-v9 .header-inner .c-box .logo-inner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.header-style-v9 .header-inner .c-box .logo-inner .menu {
  font-size: 15px;
  color: var(--theme-color-dark);
  text-transform: capitalize;
  margin-right: 31px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.header-style-v9 .header-inner .c-box .logo-inner .menu .menu-btn {
  width: 22px;
  margin-right: 10px;
}

.header-style-v9 .header-inner .c-box .logo-inner .menu .menu-btn>span {
  display: block;
  height: 1px;
  background: var(--theme-color-dark);
  width: 100%;
  margin-bottom: 5px;
}

.header-style-v9 .header-inner .c-box .logo-inner .menu .menu-btn>span:last-child {
  margin-bottom: 0;
}

.header-style-v9 .header-inner .c-box .logo-inner .menu i {
  margin-right: 13px;
}

.header-style-v9 .header-inner .c-box .logo-inner .search {
  color: var(--theme-color-dark);
  font-size: 15px;
  margin-right: 31px;
}

.header-style-v9 .header-inner .c-box .logo-inner .search i {
  margin-right: 13px;
}

.header-style-v9 .header-inner .c-box .logo {
  left: 95px;
}

.header-style-v9 .header-inner .c-box .nav-out-bar {
  margin: 0;
}

@media (max-width: 1399.98px) {
  .header-style-v9 .header-inner .c-box .nav-out-bar .nav {
    display: unset;
  }
}

@media (max-width: 1199.98px) {
  .header-style-v9 .header-inner .c-box .nav-out-bar .nav {
    display: none;
  }
}

.header-style-v9 .header-inner .c-box .nav-out-bar .nav .navigation>li>a {
  color: #fff;
}

.header-style-v9 .header-inner .c-box .nav-out-bar .nav .navigation>li>a:hover {
  color: #fff !important;
}

.header-style-v9 .header-inner .c-box .nav-out-bar .nav .navigation .current-dropdown i {
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  font-size: 12px;
  margin-left: 5px;
}

.header-style-v9 .header-inner .c-box .nav-out-bar .nav .navigation .current-dropdown:hover>.dropdown {
  opacity: 1;
  visibility: visible;
  margin-top: 17px;
}

.header-style-v9 .header-inner .c-box .nav-out-bar .nav .navigation .current-dropdown:hover i {
  -webkit-transform: rotateX(-180deg);
  transform: rotateX(-180deg);
}

.header-style-v9 .header-inner .c-box .nav-out-bar .nav .navigation .current-dropdown .dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #fff;
  padding: 15px 10px;
  margin-top: 30px;
  margin-bottom: 0;
  min-width: 220px;
  z-index: 99;
  visibility: hidden;
  opacity: 0;
  -webkit-box-shadow: 0 15px 30px -10px rgba(0, 0, 0, 0.09);
  box-shadow: 0 15px 30px -10px rgba(0, 0, 0, 0.09);
  border-radius: 7px;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.header-style-v9 .header-inner .c-box .nav-out-bar .nav .navigation .current-dropdown .dropdown li {
  padding: 0 18px;
  white-space: nowrap;
}

.header-style-v9 .header-inner .c-box .nav-out-bar .nav .navigation .current-dropdown .dropdown li a {
  padding: 9px 0 5px;
  display: block;
  color: #000;
  font-size: 14px;
  border-bottom: 1px solid rgba(251, 251, 251, 0.5);
  -webkit-transition: all 220ms linear 0ms;
  transition: all 220ms linear 0ms;
}

.header-style-v9 .header-inner .c-box .nav-out-bar .nav .navigation .current-dropdown .dropdown li a:hover {
  color: var(--theme-color1);
}

.header-style-v9 .header-inner .c-box .nav-out-bar .nav .navigation .current-dropdown .dropdown li:last-child a {
  border-bottom: 0;
}

.header-style-v9 .header-inner .main-menu .navigation>li>a {
  color: var(--theme-color-dark);
  font-size: 16px;
  font-family: var(--text-font);
}

.header-style-v9 .header-inner .main-menu .navigation>li>a:hover {
  color: var(--theme-color1);
}

.header-style-v9 .header-inner .right-box>a {
  font-size: 15px;
  color: var(--theme-color-light);
  margin-right: 18px;
}

.header-style-v9 .header-inner .right-box>a i {
  color: var(--theme-color-light);
  font-size: 16px;
  margin-right: 10px;
}

.header-style-v9 .header-inner .right-box .search-btn {
  margin-right: 31px;
}

.header-style-v9 .header-inner .right-box .search-btn a {
  color: var(--theme-color-light);
  font-size: 15px;
}

.header-style-v9 .header-inner .right-box .search-btn a i {
  margin-right: 13px;
}

.header-style-v9 .header-inner .right-box .contact-info {
  position: relative;
  padding-left: 50px;
}

.header-style-v9 .header-inner .right-box .contact-info .image {
  position: absolute;
  top: 12px;
  left: 0;
}

.header-style-v9 .header-inner .right-box .contact-info span {
  font-size: 12px;
  color: var(--theme-color-light);
}

.header-style-v9 .header-inner .right-box .contact-info .title {
  color: var(--theme-color-light);
  margin-top: -4px;
  margin-bottom: 0;
}

.header-style-v9 .header-inner .right-box .social-list-one {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-left: 71px;
  position: relative;
}

.header-style-v9 .header-inner .right-box .social-list-one::before {
  content: "";
  position: absolute;
  top: -10px;
  left: -31px;
  width: 2px;
  height: 50px;
  background-color: #525c5c;
}

.header-style-v9 .header-inner .right-box .social-list-one li {
  margin-right: 48px;
}

.header-style-v9 .header-inner .right-box .social-list-one li:last-child {
  margin-right: 0;
}

.header-style-v9 .header-inner .right-box .social-list-one li a {
  font-size: 24px;
  color: var(--theme-color-light);
}

.header-style-v9 .header-inner .main-menu .navigation>li {
  padding: 0;
  margin: 0 18px;
}

.header-style-v9 .header-inner .main-menu .navigation>li:last-child {
  margin-right: 0;
}

.header-style-v9 .header-inner .c-box .nav-out-bar .nav .navigation li a:hover {
  color: var(--theme-color1);
}

.header-style-v9 .header-inner .main-menu .navigation>li>ul>li>a:hover {
  color: var(--theme-color-v2);
}

.header-style-v9 .header-inner .sticky-header {
  background-color: var(--theme-color-v2);
}

.header-style-v9 .header-inner .sticky-header .mobile-navigation {
  display: none;
}

.header-style-v9 .header-inner .sticky-header .main-menu .navigation>li.current>a,
.header-style-v9 .header-inner .sticky-header .main-menu .navigation>li:hover>a {
  color: var(--theme-color-v2);
}

.header-style-v9 .header-inner .sticky-header .main-menu .navigation>li {
  padding: 0;
}

.header-style-v9 .header-inner .sticky-header.fixed-header {
  height: 70px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background: #000;
}

.header-style-v9 .header-inner .sticky-header .main-menu {
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.header-style-v9 .header-inner .sticky-header .main-menu .navigation>li.current>a,
.header-style-v9 .header-inner .sticky-header .main-menu .navigation>li:hover>a {
  color: #fff;
}

.header-style-v9 .header-inner .sticky-header .inner-container {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}




@media (max-width: 767.98px) {
  .header-style-v9 .header-inner .contact-info {
    display: none;
  }
}

@media (max-width: 575.98px) {
  .header-style-v9 .header-inner .contact-info {
    display: unset;
    width: 232px;
    margin-left: -170px;
  }
}

.header-style-v9 .header-inner .right-box {
  position: relative;
  width: 50%;
}

.header-style-v7 .header-inner .right-box {
  width: 50%;

}

@media (max-width: 767.98px) {
  .header-style-v9 .header-inner .right-box:before {
    display: none;
  }
}

@media (max-width: 1440px) {
  /* .header-style-v9 .header-inner .right-box {
    width: auto;
  }

  .header-style-v9 .header-inner .c-box .logo {
    left: -33px;
  } */
}


.header-style-v9 .header-inner .right-box span {
  font-size: 15px;
  color: var(--theme-color-light);
  margin-right: 18px;
}

.header-style-v9 .header-inner .right-box span i {
  color: var(--theme-color-light);
  font-size: 16px;
  margin-right: 10px;
}

/*** 

====================================================================
    header-style-ten
====================================================================

***/
.header-style-ten {
  background-color: var(--theme-color-dark);
}

.header-style-ten.style-two {
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  padding-bottom: 10px;
  padding-top: 10px;
}

.header-style-ten.style-two .header-inner {
  padding: 0;
}

.header-style-ten .btn {
  padding-right: 0;
}

.header-style-ten .btn .header-btn-two {
  height: 46px;
  line-height: 46px;
  font-weight: 500;
  color: var(--theme-color-dark);
  font-size: 15px;
  padding: 0 26px;
  border-radius: 50px;
  background-color: var(--theme-color-light);
  text-transform: capitalize;
  display: inline-block;
}

.header-style-ten .btn .header-btn-two:hover {
  color: #fff;
  background-color: var(--theme-color1);
}

.header-style-ten .header-inner {
  padding: 28px 0;
}

.header-style-ten .header-inner .inner-container {
  max-width: 1829px;
  margin: 0 auto;
  padding: 0 15px;
}

.header-style-ten .header-inner .c-box .logo-inner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.header-style-ten .header-inner .c-box .logo-inner .logo {
  margin-right: 132px;
}

.header-style-ten .header-inner .c-box .logo-inner .logo img {
  max-width: 110px;
}

.header-style-ten .header-inner .c-box .logo-inner .search-btn a {
  color: var(--theme-color-light);
  font-size: 15px;
}

.header-style-ten .header-inner .c-box .logo-inner .search-btn a i {
  margin-right: 13px;
}

.header-style-ten .header-inner .c-box .btn-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.header-style-ten .header-inner .c-box .btn-box .menu-btn .button {
  color: var(--theme-color-light);
  margin-right: 34px;
}

.header-style-ten .header-inner .c-box .nav-out-bar {
  margin-left: auto;
  margin-right: 31px;
}

@media (max-width: 1399.98px) {
  .header-style-ten .header-inner .c-box .nav-out-bar {
    margin-left: 157px;
  }
}

@media (max-width: 1399.98px) {
  .header-style-ten .header-inner .c-box .nav-out-bar .nav {
    display: unset;
  }
}

@media (max-width: 1199.98px) {
  .header-style-ten .header-inner .c-box .nav-out-bar .nav {
    display: none;
  }
}

.header-style-ten .header-inner .c-box .nav-out-bar .nav .navigation .current-dropdown i {
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  font-size: 12px;
  margin-left: 8px;
}

.header-style-ten .header-inner .c-box .nav-out-bar .nav .navigation .current-dropdown:hover>.dropdown {
  opacity: 1;
  visibility: visible;
  margin-top: 17px;
}

.header-style-ten .header-inner .c-box .nav-out-bar .nav .navigation .current-dropdown:hover i {
  -webkit-transform: rotateX(-180deg);
  transform: rotateX(-180deg);
}

.header-style-ten .header-inner .c-box .nav-out-bar .nav .navigation .current-dropdown .dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #fff;
  padding: 2px 0 5px;
  margin-top: 30px;
  margin-bottom: 0;
  min-width: 220px;
  z-index: 99;
  visibility: hidden;
  opacity: 0;
  -webkit-box-shadow: 0px 6px 15px 0px rgba(64, 79, 104, 0.05);
  box-shadow: 0px 6px 15px 0px rgba(64, 79, 104, 0.05);
  border-radius: 16px;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  padding: 25px 20px;
}

.header-style-ten .header-inner .c-box .nav-out-bar .nav .navigation .current-dropdown .dropdown li {
  padding: 0;
  margin-bottom: 10px;
}

.header-style-ten .header-inner .c-box .nav-out-bar .nav .navigation .current-dropdown .dropdown li:last-child {
  margin-bottom: 0;
}

.header-style-ten .header-inner .c-box .nav-out-bar .nav .navigation .current-dropdown .dropdown li a {
  display: block;
  color: #050B20;
  font-size: 15px;
  -webkit-transition: all 220ms linear 0ms;
  transition: all 220ms linear 0ms;
  text-transform: capitalize;
}

.header-style-ten .header-inner .c-box .nav-out-bar .nav .navigation .current-dropdown .dropdown li a:hover {
  color: var(--theme-color1);
}

.header-style-ten .header-inner .c-box .nav-out-bar .nav .navigation .current-dropdown .dropdown li:last-child a {
  border-bottom: 0;
}

.header-style-ten .header-inner .right-box .header-btn-eight {
  height: 46px;
  line-height: 46px;
  font-weight: 500;
  color: var(--theme-color-light);
  font-size: 15px;
  padding: 0 26px;
  border-radius: 16px;
  border: 1px solid var(--theme-color-light);
  text-transform: capitalize;
  display: inline-block;
}

.header-style-ten .header-inner .right-box .contact-info {
  position: relative;
  padding-left: 50px;
}

.header-style-ten .header-inner .right-box .contact-info .image {
  position: absolute;
  top: 12px;
  left: 0;
}

.header-style-ten .header-inner .right-box .contact-info span {
  font-size: 12px;
  color: var(--theme-color-light);
}

.header-style-ten .header-inner .right-box .contact-info .title {
  color: var(--theme-color-light);
  margin-top: -4px;
  margin-bottom: 0;
}

.header-style-ten .header-inner .right-box .social-list-one {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-left: 71px;
  position: relative;
}

.header-style-ten .header-inner .right-box .social-list-one::before {
  content: "";
  position: absolute;
  top: -10px;
  left: -31px;
  width: 2px;
  height: 50px;
  background-color: #525c5c;
}

.header-style-ten .header-inner .right-box .social-list-one li {
  margin-right: 48px;
}

.header-style-ten .header-inner .right-box .social-list-one li:last-child {
  margin-right: 0;
}

.header-style-ten .header-inner .right-box .social-list-one li a {
  font-size: 24px;
  color: var(--theme-color-light);
}

.header-style-ten .header-inner .sticky-header {
  background-color: var(--theme-color-v2);
}

.header-style-ten .header-inner .sticky-header .mobile-navigation {
  display: none;
}

.header-style-ten .header-inner .sticky-header .main-menu .navigation>li {
  padding: 0;
}

.header-style-ten .header-inner .sticky-header.fixed-header {
  height: 70px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background: #000;
}

.header-style-ten .header-inner .sticky-header .main-menu {
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.header-style-ten .header-inner .sticky-header .main-menu .navigation>li.current>a,
.header-style-ten .header-inner .sticky-header .main-menu .navigation>li:hover>a {
  color: #fff;
}

.header-style-ten .header-inner .sticky-header .inner-container {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

@media (max-width: 767.98px) {
  .header-style-ten .header-inner .contact-info {
    display: none;
  }
}

@media (max-width: 575.98px) {
  .header-style-ten .header-inner .contact-info {
    display: unset;
    width: 232px;
    margin-left: -170px;
  }
}

.header-style-ten .header-inner .right-box {
  position: relative;
}

@media (max-width: 767.98px) {
  .header-style-ten .header-inner .right-box:before {
    display: none;
  }
}

.header-style-ten .header-inner .right-box>a {
  font-size: 15px;
  color: var(--theme-color-light);
  margin-right: 18px;
}

.header-style-ten .header-inner .right-box>a i {
  color: var(--theme-color-light);
  font-size: 16px;
  margin-right: 10px;
}

/*** 

====================================================================
Sticky Header
====================================================================

***/
.sticky-header {
  position: fixed;
  visibility: hidden;
  opacity: 0;
  left: 0px;
  top: 0px;
  width: 100%;
  padding: 0px 0px;
  z-index: 99999;
  background: #000;
  -webkit-box-shadow: 0 0 20px rgba(0, 0, 0, 0.05);
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.05);
}

.sticky-header .inner-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.sticky-header.fixed-header {
  opacity: 1;
  z-index: 9999;
  visibility: visible;
}

.sticky-header .logo {
  padding: 10px 0;
}

.sticky-header .logo img {
  max-height: 40px;
}

.sticky-header .nav-out-bar {
  position: relative;
  background: none;
  margin-left: auto;
}

.sticky-header .main-menu .navigation>li {
  margin: 0;
  margin-left: 60px;
  padding: 20px 0;
}

.sticky-header .main-menu .navigation>li>a {
  color: #fff;
}

.sticky-header .main-menu .navigation>li.current>a,
.sticky-header .main-menu .navigation>li:hover>a {
  color: var(--theme-color1);
}

.sticky-header .right-box,
.sticky-header .navbar-header {
  display: none;
}

/* Small devices */
@media (max-width: 767px) {
  .search-btn {
    display: none;
  }
}

.dropdown:before {
  content: "";
  position: absolute;
  bottom: calc(100% - 6px);
  left: 15px;
  border-top: 10px solid #fff;
  border-right: 10px solid transparent;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.mega-menu {
  position: absolute;
  top: 100%;
  left: -100px;
  width: 800px;
  background-color: #fff;
  border-radius: 16px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  padding: 40px 52px 29px 46px;
  opacity: 0;
  gap: 83px;
  visibility: hidden;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  /* margin-top: 20px; */
  z-index: 9999;
}

.mega-menu:before {
  content: "";
  position: absolute;
  bottom: calc(100% - 6px);
  left: 120px;
  border-top: 10px solid #fff;
  border-right: 10px solid transparent;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.header-default .mega-menu {
  top: 140%;
}

.header-default .header-inner .c-box .nav-out-bar .nav .navigation .current-dropdown .dropdown {
  top: 140%;
}



.mega-menu .mega-column h3 {
  color: #202124;
  font-size: 16px;
  line-height: 28px;
  font-weight: 500;
  margin-bottom: 15px;
}

.mega-menu .mega-column ul li {
  margin-bottom: 12px;
}

.mega-menu .mega-column ul li:last-child {
  margin-bottom: 0;
}

.mega-menu .mega-column ul li a {
  display: inline-block;
  color: #050B20;
  font-size: 15px;
  text-align: left;
}

.mega-menu .mega-column ul li a:hover {
  color: #405FF2;
}

.main-menu .navigation>li:hover>.mega-menu {
  opacity: 1;
  visibility: visible;
  margin-top: 0;
}

.header-style-v1.style-two {
  padding: 0;
}

.header-style-v1.style-two .main-menu .navigation>li {
  padding: 30px 0;
}

.main-menu .navigation>li>span {
  position: relative;
  display: block;
  text-align: center;
  opacity: 1;
  color: #ffffff;
  font-size: 15px;
  line-height: 30px;
  padding: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}

.mm-navbar_sticky {
  margin-right: 30px;
}

.mm-panels>.mm-panel .mm-listview {
  padding-right: 30px;
}

.hheader-style-v4.five .main-menu .navigation>li>span,
.hheader-style-v4.v6 .main-menu .navigation>li>span,
.hheader-style-v4.v10 .main-menu .navigation>li>span {
  color: #000;
}

.hheader-style-v4.v10 .right-box .user:hover {
  color: var(--theme-color1);
}

.header-style-v1 .header-inner .c-box .nav-out-bar .nav .navigation .current-dropdown.right-one .dropdown {
  left: auto;
  right: 0;
}

.header-style-v1 .header-inner .c-box .nav-out-bar .nav .navigation .current-dropdown.right-one .dropdown:before {
  left: auto;
  right: 30px;
}

.header-style-v1 .header-inner .c-box .nav-out-bar .nav .navigation .current-dropdown.right-one .dropdown.deep {
  left: 100%;
  top: 10px;
  margin-left: 10px;
}

.header-style-v1 .header-inner .c-box .nav-out-bar .nav .navigation .current-dropdown.right-one .dropdown.deep:before {
  display: none;
}

.header-style-v1 .header-inner .c-box .nav-out-bar .nav .navigation .current-dropdown.right-one .dropdown li a {
  position: relative;
}

.header-style-v1 .header-inner .c-box .nav-out-bar .nav .navigation .current-dropdown.right-one .dropdown li a i {
  position: absolute;
  top: 50%;
  right: 10px;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

/*** 

====================================================================
      Mobile Menu
====================================================================

***/
.mm-panels {
  max-width: 470px;
  --mm-color-background: #fff;
  --mm-color-text-dimmed: #979797;
  --mm-color-border: rgba(255, 255, 255, 0.1);
  --mm-listitem-size: 50px;
  /* Extra small devices */
}

@media (max-width: 416px) {
  .mm-panels {
    width: 100%;
  }
}

.mm-menu a,
.mm-menu a:active,
.mm-menu a:hover,
.mm-menu a:link,
.mm-menu a:visited {
  color: #979797;
}

.mm-menu li {
  padding: 0 20px;
  display: block;
}

.mm-menu .mm-listitem:after {
  display: none;
}

.mm-menu li.current>a {
  color: #ffffff;
  background-color: #E9F2FF;
  border-radius: 16px;
  color: #405FF2;
}

.mm-btn:after,
.mm-btn:before {
  border-color: #ffffff;
  border-width: 1.5px;
  height: 6px;
  width: 6px;
}

.mm-panels .mm-counter {
  position: absolute;
  right: 45px;
  top: 50%;
  text-indent: 0;
  display: block;
  margin-top: -10px;
  background-color: rgba(255, 167, 55, 0.8);
  height: 20px;
  width: 20px;
  text-align: center;
  border-radius: 50%;
  color: #fff;
  line-height: 22px;
  font-size: 12px;
  font-weight: 600;
  padding: 0;
}

#toggle-user-sidebar {
  margin-left: 20px;
}

#toggle-user-sidebar .thumb {
  display: block;
  height: 30px;
  width: 30px;
  border: 2px solid rgba(255, 255, 255, 0.15);
  border-radius: 50%;
}

.mm-menu_fullscreen.mm-menu_position-bottom {
  top: 0;
  background: transparent;
}

.mm-navbar_sticky {
  border-bottom: 1px solid #E1E1E1;
  padding: 0;
  padding-bottom: 20px;
  margin-bottom: 30px;
  padding-top: 20px;
  margin-right: 30px;
}

.mm-listview {
  padding-top: 20px;
}

.mm-menu li {
  margin-bottom: 10px;
  display: flex;
  align-items: center;
}

.mobile-navigation .flaticon-menu-1:before {
  -webkit-transition: all 100ms ease;
  transition: all 100ms ease;
}

.mm-wrapper_opened .mobile-navigation .flaticon-menu-1:before {
  content: "\f175";
  font-size: 18px;
  line-height: 30px;
  display: block;
}

.mm-add-listing {
  margin-top: 87px;
  border: transparent;
  display: none;
}

.mm-add-listing .theme-btn {
  border-radius: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 100%;
  color: #ffffff !important;
  height: 60px;
  border-radius: 10px;
}

.mm-add-listing .mm-listitem__text {
  padding: 0;
}

.mm-add-listing .contact-info {
  position: relative;
  padding: 18px 0;
  display: block;
}

.mm-add-listing .phone-num {
  position: relative;
  display: block;
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  margin-bottom: 13px;
  color: #7E7E7E;
}

.mm-add-listing .phone-num span {
  display: block;
}

.mm-add-listing .phone-num a {
  color: #ffffff;
}

.mm-add-listing .address {
  position: relative;
  display: block;
  font-size: 14px;
  line-height: 30px;
  color: #7E7E7E;
}

.mm-add-listing .email {
  font-size: 14px;
  line-height: 30px;
  color: #7E7E7E !important;
}

.mm-add-listing .social-links {
  position: relative;
  display: block;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.mm-add-listing .social-links a {
  font-size: 14px;
  line-height: 16px;
  text-transform: capitalize;
  color: #7E7E7E;
  margin-right: 37px;
}

.mm-panel:after {
  height: 10px;
}

.mobile-navigation a {
  color: #fff;
}

/* Large devices */
@media (max-width: 1199px) {
  .header-style-v1 .header-inner .right-box {
    margin-left: auto;
  }
}

.mm-navbar__title>span {
  color: #050B20;
  font-size: 20px;
  font-weight: 500;
}

.mm-navbar__title {
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

.mm-listitem__text {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  color: #050B20;
  font-size: 18px;
  font-weight: 500;
}

.mm-menu a,
.mm-menu a:active,
.mm-menu a:hover,
.mm-menu a:link,
.mm-menu a:visited {
  color: #050B20;
}

.mm-panels>.mm-panel li>a i {
  display: none;
}

.mm-panels>.mm-panel .mm-btn::after,
.mm-panels>.mm-panel .mm-btn::before {
  border-color: #050B20;
}

.mm-panels>.mm-panel .mm-listview {
  padding-top: 0;
}

.mm-panels>.mm-panel {
  padding: 0px 30px 30px;
}

.mm-menu li {
  padding: 0;
}

.mm-navbar__title {
  padding: 0;
}

.mm-menu_fullscreen {
  max-width: 470px;
  width: 470px;
  min-width: 470px;
  /* Extra small devices */
}

@media (max-width: 416px) {
  .mm-menu_fullscreen {
    width: 100%;
    min-width: 100%;
    max-width: 100%;
  }
}

/* Large devices */
@media (max-width: 1199px) {
  header {
    padding: 20px 0 !important;
  }


}

.mm-panels>.mm-panel.mega-menu {
  position: static;
  opacity: 1;
  visibility: visible;
  height: 100vh;
  width: auto;
}

.mm-panels>.mm-panel.mega-menu .mega-column {
  width: 100%;
}

.mm-panels>.mm-panel.mega-menu .mega-column ul {
  padding: 0 20px;
  margin-bottom: 20px;
}

.mm-panels>.mm-panel.mega-menu .mega-column ul li {
  margin-bottom: 20px;
}

.mobile-navigation {
  width: 24px;
  margin-left: 20px;
  cursor: pointer;
  top: 0;
  display: none;
  /* Large devices */
}

@media (max-width: 1199px) {
  .mobile-navigation {
    display: inline-block;
  }
}

.mobile-navigation a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  width: 100%;
  font-size: 26px;
}

.mm-wrapper__blocker {
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  background: rgba(0, 0, 0, 0.5);
}

/*** 

====================================================================
Section Title
====================================================================

***/
.boxcar-title {
  position: relative;
  margin-bottom: 41px;
}

.boxcar-title.white h2 {
  color: #fff;
}

.boxcar-title.white p {
  color: #fff;
}

.boxcar-title h2 {
  font-family: var(--title-font);
  font-size: 40px;
  font-weight: 700;
  color: var(--theme-color-dark);
  text-transform: capitalize;
  line-height: 45px;
}

@media (max-width: 991px) {
  .boxcar-title h2 {
    font-size: 30px;
    line-height: 35px;
  }

  .compare-products .box-empty {
    display: none;
  }
}


.boxcar-title .text {
  margin-top: 23px;
}

.boxcar-title .btn-title {
  position: absolute;
  top: 21px;
  right: 0;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px;
  font-family: var(--title-font);
  color: var(--color-primary, var(--theme-color-dark));
}

.boxcar-title .btn-title svg {
  margin-left: 9px;
  position: relative;
  top: -4px;
}

.boxcar-title.light .text,
.boxcar-title.light h2,
.boxcar-title.light h1 {
  color: #fff;
}

.boxcar-title.light .sub-title {
  color: var(--theme-color-light);
  letter-spacing: 1.5px;
}

/*** 

====================================================================
  banner section
====================================================================

***/
.boxcar-banner-section-v1 {
  background-image: url(../images/banner/banner-page1.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  position: relative;
  /* Small devices */

}



@media (max-width: 767px) {

  .boxcar-banner-section-v1 {
    height: unset;
    padding: 50px 0;
  }
}

.boxcar-banner-section-v1 .banner-content {
  width: 924px;
  margin: 0 auto;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  /* Medium devices */
  /* Small devices */
}

@media (max-width: 991px) {
  .boxcar-banner-section-v1 .banner-content {
    width: calc(100% - 30px);
  }
}

@media (max-width: 767px) {
  .boxcar-banner-section-v1 .banner-content {
    -webkit-transform: unset;
    transform: unset;
    position: static;
    padding: 0;
  }
}

.boxcar-banner-section-v1 .banner-content>span {
  display: block;
  color: #fff;
  font-size: 16px;
  line-height: 28px;
  margin-bottom: 30px;
  /* Medium devices */
}

@media (max-width: 991px) {
  .boxcar-banner-section-v1 .banner-content>span {
    margin-bottom: 12px;
  }
}

.boxcar-banner-section-v1 .banner-content h2 {
  color: #fff;
  font-size: 70px;
  text-transform: capitalize;
  font-weight: 700;
  margin-bottom: 48px;
  /* Small devices */
}

@media (max-width: 767px) {
  .boxcar-banner-section-v1 .banner-content h2 {
    font-size: 46px;
    line-height: 58px;
  }
}

.boxcar-banner-section-v1 .banner-content .form-tabs-list {
  margin-bottom: 22px;
}

.boxcar-banner-section-v1 .banner-content .form-tabs-list li {
  display: inline-block;
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  text-transform: capitalize;
  margin-right: 26px;
  cursor: pointer;
  position: relative;
  padding-bottom: 3px;
}

.boxcar-banner-section-v1 .banner-content .form-tabs-list li:before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #fff;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.boxcar-banner-section-v1 .banner-content .form-tabs-list li.current:before {
  opacity: 1;
  visibility: visible;
}

.boxcar-banner-section-v1 .banner-content .form-tabs-list li:last-child {
  margin-right: 0;
}

.boxcar-banner-section-v1 .banner-content .form-tab-pane {
  margin-bottom: 60px;
  display: none;
}

.boxcar-banner-section-v1 .banner-content .form-tab-pane.current {
  display: block;
}

.boxcar-banner-section-v1 .banner-content .form-tab-pane form {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background: #fff;
  border-radius: 50px;
  padding: 0 10px;
  height: 76px;
  /* Small devices */
}

@media (max-width: 767px) {
  .boxcar-banner-section-v1 .banner-content .form-tab-pane form {
    display: block;
    height: unset;
    border-radius: 0;
    padding: 0;
  }
}

.boxcar-banner-section-v1 .banner-content .form-tab-pane form .form_boxes {
  width: 25%;
  height: 76px;
  /* Small devices */
}

@media (max-width: 767px) {
  .boxcar-banner-section-v1 .banner-content .form-tab-pane form .form_boxes {
    width: 100%;
  }
}

.boxcar-banner-section-v1 .banner-content .form-submit {
  margin-left: auto;
  /* Medium devices */
  /* Small devices */
}

@media (max-width: 991px) {
  .boxcar-banner-section-v1 .banner-content .form-submit {
    width: 25%;
    position: relative;
    right: 25px;
  }
}

@media (max-width: 767px) {
  .boxcar-banner-section-v1 .banner-content .form-submit {
    width: 100%;
    right: 0;
  }
}

.boxcar-banner-section-v1 .banner-content .form-submit button {
  color: #fff;
  font-size: 15px;
  font-weight: 500;
  height: 56px;
  line-height: 56px;
  padding: 0 30px;
  border-radius: 30px;
  background-color: #405FF2;
  /* Small devices */
}

.boxcar-banner-section-v1 .banner-content .form-submit button i {
  position: relative;
  margin-right: 7px;
  top: 2px;
}

@media (max-width: 767px) {
  .boxcar-banner-section-v1 .banner-content .form-submit button {
    width: 100%;
    border-radius: 0;
  }
}

.boxcar-banner-section-v1 .banner-content .form-tab-content>span {
  display: block;
  color: #fff;
  font-size: 15px;
  font-weight: 500;
  margin-bottom: 31px;
}

.boxcar-banner-section-v1 .banner-content .form-tab-content .model-links li {
  display: inline-block;
  margin-right: 6px;
  /* Medium devices */
}

@media (max-width: 991px) {
  .boxcar-banner-section-v1 .banner-content .form-tab-content .model-links li {
    margin-bottom: 12px;
  }
}

.boxcar-banner-section-v1 .banner-content .form-tab-content .model-links li:last-child {
  margin-right: 0;
}

.boxcar-banner-section-v1 .banner-content .form-tab-content .model-links li a {
  background: rgba(255, 255, 255, 0.2);
  display: inline-block;
  color: #fff;
  font-size: 15px;
  font-weight: 500;
  height: 44px;
  line-height: 44px;
  padding: 0 30px;
  border-radius: 30px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.boxcar-banner-section-v1 .banner-content .form-tab-content .model-links li a:hover {
  background-color: #FFFFFF4F;
}

.boxcar-banner-section-v1 .banner-content .form-tab-content .model-links li a i {
  font-size: 24px;
  line-height: 24px;
  margin-right: 10px;
  margin-top: 2px;
}

.boxcar-banner-section-v1.banner-style-three {
  background-image: url(../images/banner/banner-hp3.jpg);
}

.boxcar-banner-section-v1.banner-style-three:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #050B20;
  opacity: 0.4;
}

.banner-style-three .banner-content-three .form-tab-content {
  position: relative;
  z-index: 5;

}

.boxcar-banner-section-v1.banner-style-three .banner-content-three {
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  z-index: 99;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.boxcar-banner-section-v1.banner-style-three .banner-content-three .banner-content {
  position: static;
  -webkit-transform: unset;
  transform: unset;
  text-align: left;
  margin: unset;
}

.boxcar-banner-section-v1.banner-style-three .form-tab-pane form {
  border-radius: 15px;
}

.boxcar-banner-section-v1.banner-style-three .drop-menu .dropdown {
  border-radius: 15px;
}

.drop-menu {
  position: relative;
  font-size: 15px;
  color: #050B20;
  height: 100%;
  text-align: left;
  height: 76px;
}

.drop-menu .select {
  cursor: pointer;
  display: block;
  color: #050B20;
  font-size: 15px;
  text-transform: capitalize;
  line-height: 76px;
  padding: 0 30px;
}

.drop-menu .select i {
  font-size: 14px;
  margin-top: 0;
  cursor: pointer;
  float: right;
  position: relative;
  right: 10px;
}

.drop-menu.active {
  border-radius: 5px 5px 0 0;
}

.drop-menu.active:hover,
.drop-menu.active:focus {
  border-radius: 5px 5px 0 0;
}

.drop-menu.active .select {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  outline: none;
}

.drop-menu.active .select i {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

.drop-menu:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
  outline: none;
}

.drop-menu .dropdown {
  position: absolute;
  width: 100%;
  left: 0;
  margin-top: 1px;
  overflow: hidden;
  display: none;
  max-height: 144px;
  overflow-y: auto;
  z-index: 9999;
  background: #fff;
  padding: 0;
  list-style: none;
  padding: 10px 15px;
  top: 110%;
  border-radius: 10px;
  border: 1px solid #e1e1e1;
}

.drop-menu .dropdown li {
  padding: 10px 0;
  cursor: pointer;
  color: #242526;
  border-bottom: 1px solid #e0dfe5;
}

.drop-menu.active .select>span,
.drop-menu.active .select i,
.drop-menu .dropdown li:hover {
  color: #7686e6;
}

.drop-menu .dropdown li:last-child {
  border-bottom: 0;
}

/*** 

====================================================================
  banner section two
====================================================================

***/
.boxcar-banner-section-two {
  position: relative;
}

.boxcar-banner-section-two .banner-slider {
  position: relative;
}

.boxcar-banner-section-two .banner-slider .banner-slide {
  position: relative;
  z-index: 9;
}

.boxcar-banner-section-two .banner-slider .banner-slide:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #050B20;
  opacity: 0.4;
}

.boxcar-banner-section-two .banner-slider .banner-slide .right-box {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  left: 0;
  width: 100%;
  z-index: 99;
}

.boxcar-banner-section-two .banner-slider .banner-slide .right-box .content-box .sub-title {
  font-size: 40px;
  font-weight: 700;
  color: var(--theme-color-light);
  margin-bottom: 21px;
  display: inline-block;
}

.boxcar-banner-section-two .banner-slider .banner-slide .right-box .content-box .sub-title small {
  position: relative;
  top: -2px;
  font-size: 20px;
  font-weight: 500;
  line-height: 30px;
  margin-left: 12px;
}

.boxcar-banner-section-two .banner-slider .banner-slide .right-box .content-box h1 {
  font-size: 70px;
  color: var(--theme-color-light);
  font-weight: 700;
  line-height: normal;
}

.boxcar-banner-section-two .banner-slider .banner-slide .right-box .content-box ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 27px;
}

.boxcar-banner-section-two .banner-slider .banner-slide .right-box .content-box ul li {
  position: relative;
  color: var(--theme-color-light);
  padding-left: 28px;
  margin-right: 30px;
}

.boxcar-banner-section-two .banner-slider .banner-slide .right-box .content-box ul li:last-child {
  margin-right: 0;
}

.boxcar-banner-section-two .banner-slider .banner-slide .right-box .content-box ul li i {
  position: absolute;
  top: 3px;
  left: 0;
}

.boxcar-banner-section-two .banner-slider .banner-slide .right-box .content-box .banner-btn {
  padding: 17px 26px 15px;
  background-color: var(--theme-color-light);
  font-family: var(--title-font);
  border: 1px solid var(--theme-color-light);
  font-size: 15px;
  font-weight: 500;
  display: inline-block;
  border-radius: 12px;
}

.boxcar-banner-section-two .banner-slider .banner-slide .right-box .content-box .banner-btn svg {
  position: relative;
  top: -1px;
  fill: var(--theme-color-dark);
  width: 14px;
  height: 14px;
  margin-left: 11px;
}

.boxcar-banner-section-two .banner-thumbs {
  position: absolute;
  bottom: 40px;
  left: 0;
  width: 100%;
  z-index: 99;
}

.boxcar-banner-section-two .banner-thumbs .banner-slider-thumbs .banner-slide-thumb {
  display: inline-block;
  float: none;
  margin-right: 30px;
  font-size: 16px;
  font-weight: 500;
  padding-bottom: 10px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  color: #fff;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  cursor: pointer;
}

.boxcar-banner-section-two .banner-thumbs .banner-slider-thumbs .banner-slide-thumb.slick-current {
  border-color: #fff;
}

/*** 

====================================================================
  banner section four
====================================================================

***/
.boxcar-banner-section-four {
  position: relative;
  /* height: 100vh; */
}

@media (max-width: 1550px) {
  .boxcar-banner-section-four {
    height: 100vh;

  }

}

.boxcar-banner-section-four .banner-slider-v4 {
  position: relative;
  height: 100%;
}

.boxcar-banner-section-four .banner-slider-v4 .slick-track,
.boxcar-banner-section-four .banner-slider-v4 .slick-list {
  height: 100%;
}

.boxcar-banner-section-four .banner-slider-v4 .slick-track .banner-slide,
.boxcar-banner-section-four .banner-slider-v4 .slick-list .banner-slide {
  height: 100%;
}

.boxcar-banner-section-four .banner-slider-v4 .slick-track .banner-slide>img,
.boxcar-banner-section-four .banner-slider-v4 .slick-list .banner-slide>img {
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.boxcar-banner-section-four .banner-slider-v4 .slick-dots {
  bottom: 70px;
  left: 14vw;
  -webkit-transform: unset;
  transform: unset;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin-left: 65px;
}

.boxcar-banner-section-four .banner-slider-v4 .slick-dots li {
  width: auto;
  height: auto;
}

.boxcar-banner-section-four .banner-slider-v4 .slick-dots li button {
  width: 20px;
  height: 4px;
  background: #fff;
  border-radius: 30px;
  padding: 0;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.boxcar-banner-section-four .banner-slider-v4 .slick-dots li.slick-active button {
  width: 35px;
}

.boxcar-banner-section-four .banner-slider-v4 .slick-prev,
.boxcar-banner-section-four .banner-slider-v4 .slick-next {
  bottom: 60px;
  top: auto;
  -webkit-transform: unset;
  transform: unset;
  z-index: 99;
  left: 13vw;
  right: auto;
  border-color: #fff;
}

.boxcar-banner-section-four .banner-slider-v4 .slick-prev:before,
.boxcar-banner-section-four .banner-slider-v4 .slick-next:before {
  color: #fff;
  font-size: 20px;
}

.boxcar-banner-section-four .banner-slider-v4 .slick-next {
  margin-left: 210px;
}

.boxcar-banner-section-four .banner-slider-v4 .banner-slide {
  position: relative;
  z-index: 9;
}

.boxcar-banner-section-four .banner-slider-v4 .banner-slide:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #050B20;
  opacity: 0.4;
}

.boxcar-banner-section-four .banner-slider-v4 .banner-slide .right-box {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  left: 0;
  width: 100%;
  z-index: 99;
}

.boxcar-banner-section-four .banner-slider-v4 .banner-slide .right-box .content-box .sub-title {
  font-size: 40px;
  font-weight: 700;
  color: var(--theme-color-light);
  margin-bottom: 21px;
  display: inline-block;
}

.boxcar-banner-section-four .banner-slider-v4 .banner-slide .right-box .content-box .sub-title small {
  position: relative;
  top: -2px;
  font-size: 20px;
  font-weight: 500;
  line-height: 30px;
  margin-left: 12px;
}

.boxcar-banner-section-four .banner-slider-v4 .banner-slide .right-box .content-box h1 {
  font-size: 70px;
  color: var(--theme-color-light);
  font-weight: 700;
  line-height: normal;
}

.boxcar-banner-section-four .banner-slider-v4 .banner-slide .right-box .content-box ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 27px;
}

.boxcar-banner-section-four .banner-slider-v4 .banner-slide .right-box .content-box ul li {
  position: relative;
  color: var(--theme-color-light);
  padding-left: 28px;
  margin-right: 30px;
}

.boxcar-banner-section-four .banner-slider-v4 .banner-slide .right-box .content-box ul li:last-child {
  margin-right: 0;
}

.boxcar-banner-section-four .banner-slider-v4 .banner-slide .right-box .content-box ul li img {
  position: absolute;
  top: 3px;
  left: 0;
}

.boxcar-banner-section-four .banner-slider-v4 .banner-slide .right-box .content-box .banner-btn {
  padding: 17px 26px 15px;
  background-color: var(--theme-color-light);
  font-family: var(--title-font);
  border: 1px solid var(--theme-color-light);
  font-size: 15px;
  font-weight: 500;
  display: inline-block;
  border-radius: 12px;
}

.boxcar-banner-section-four .banner-slider-v4 .banner-slide .right-box .content-box .banner-btn svg {
  position: relative;
  top: -1px;
  fill: var(--theme-color-dark);
  width: 14px;
  height: 14px;
  margin-left: 11px;
}

.boxcar-banner-section-four .banner-slider-v4 .banner-slide .right-box .list-box {
  position: absolute;
  bottom: -274px;
  right: 60px;
  border-radius: 16px;
  width: 360px;
  padding: 60px;
  background: rgba(255, 255, 255, 0.1);
  -webkit-backdrop-filter: blur(7.5px);
  backdrop-filter: blur(7.5px);
}

.boxcar-banner-section-four .banner-slider-v4 .banner-slide .right-box .list-box .items {
  margin-bottom: 54px;
}

.boxcar-banner-section-four .banner-slider-v4 .banner-slide .right-box .list-box .items:last-child {
  margin-bottom: 0;
}

.boxcar-banner-section-four .banner-slider-v4 .banner-slide .right-box .list-box .items i {
  display: block;
  color: #fff;
  font-size: 30px;
}

.boxcar-banner-section-four .banner-slider-v4 .banner-slide .right-box .list-box .items span {
  color: var(--theme-color-light);
  font-size: 15px;
  display: inline-block;
  margin: 12px 0 -1px;
}

.boxcar-banner-section-four .banner-slider-v4 .banner-slide .right-box .list-box .items .title {
  font-size: 20px;
  font-weight: 500;
  color: var(--theme-color-light);
  margin-bottom: 0;
}

.boxcar-banner-section-four .banner-slider-v4 .banner-slide .right-box .list-box .btn-box .btn {
  font-family: var(--title-font);
  font-weight: 500;
  font-size: 15px;
  background-color: var(--theme-color-light);
  padding: 20px 73px 19px;
  display: inline-block;
}

.boxcar-banner-section-four .banner-slider-v4 .banner-slide .right-box .list-box .btn-box .btn svg {
  position: relative;
  top: -2px;
  margin-left: 9px;
}

/*** 

====================================================================
  banner section five
====================================================================

***/
.boxcar-banner-section-five {
  position: relative;
  padding-bottom: 0;
  padding-top: 100px;
  height: unset;
  position: relative;
  z-index: 1;
  margin-bottom: -180px;
}

.boxcar-banner-section-five:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: calc(100% - 220px);
  background: #EEF1FB;
  z-index: -1;
}

.boxcar-banner-section-five .image-column {
  margin-top: -120px;
}

.boxcar-banner-section-five .banner-content {
  width: 924px;
  margin: 0 auto;
  text-align: center;
  margin: auto;
  border-radius: 50px;
}

.boxcar-banner-section-five .banner-content>span {
  display: block;
  color: var(--theme-color-dark);
  font-size: 16px;
  line-height: 28px;
  margin-bottom: 30px;
}

.boxcar-banner-section-five .banner-content h2 {
  color: var(--theme-color-dark);
  font-size: 70px;
  text-transform: capitalize;
  font-weight: 700;
  margin-bottom: 48px;
}

.boxcar-banner-section-five .banner-content .form-tabs-list {
  margin-bottom: 22px;
}

.boxcar-banner-section-five .banner-content .form-tabs-list li {
  display: inline-block;
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  text-transform: capitalize;
  margin-right: 26px;
  cursor: pointer;
  position: relative;
  padding-bottom: 3px;
}

.boxcar-banner-section-five .banner-content .form-tabs-list li:before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #fff;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.boxcar-banner-section-five .banner-content .form-tabs-list li.current:before {
  opacity: 1;
  visibility: visible;
}

.boxcar-banner-section-five .banner-content .form-tabs-list li:last-child {
  margin-right: 0;
}

.boxcar-banner-section-five .banner-content .form-tab-pane {
  margin-bottom: 60px;
  display: none;
}

.boxcar-banner-section-five .banner-content .form-tab-pane.current {
  display: block;
}

.boxcar-banner-section-five .banner-content .form-tab-pane form {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background: #fff;
  border-radius: 50px;
  padding: 0 10px;
  height: 76px;
}

.boxcar-banner-section-five .banner-content .form-tab-pane form .form_boxes {
  width: 23%;
  height: 76px;
  position: relative;
}

.boxcar-banner-section-five .banner-content .form-tab-pane form .form_boxes:nth-child(4):before {
  display: none;
}

.boxcar-banner-section-five .banner-content .form-tab-pane form .form_boxes:before {
  content: "";
  position: absolute;
  top: 50%;
  right: 0;
  width: 1px;
  height: 36px;
  background: #e1e1e1;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.boxcar-banner-section-five .banner-content .form-submit {
  margin-left: auto;
}

.boxcar-banner-section-five .banner-content .form-submit button {
  color: #fff;
  font-size: 20px;
  font-weight: 500;
  width: 56px;
  height: 56px;
  line-height: 60px;
  text-align: center;
  border-radius: 50%;
  background-color: #405FF2;
  padding-top: 3px;
}

.boxcar-banner-section-five .banner-content .form-tab-content>span {
  display: block;
  color: #fff;
  font-size: 15px;
  font-weight: 500;
  margin-bottom: 31px;
}

.boxcar-banner-section-five .banner-content .form-tab-content .model-links li {
  display: inline-block;
  margin-right: 6px;
}

.boxcar-banner-section-five .banner-content .form-tab-content .model-links li:last-child {
  margin-right: 0;
}

.boxcar-banner-section-five .banner-content .form-tab-content .model-links li a {
  background: rgba(255, 255, 255, 0.2);
  display: inline-block;
  color: #fff;
  font-size: 15px;
  font-weight: 500;
  height: 44px;
  line-height: 44px;
  padding: 0 30px;
  border-radius: 30px;
}

.boxcar-banner-section-five .banner-content .form-tab-content .model-links li a img {
  margin-right: 5px;
}

.boxcar-banner-section-five.banner-style-three {
  background-image: url(../images/banner/banner-hp3.jpg);
}

.boxcar-banner-section-five.banner-style-three:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #050B20;
  opacity: 0.4;
}

.boxcar-banner-section-five.banner-style-three .banner-content-three {
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  z-index: 99;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.boxcar-banner-section-five.banner-style-three .banner-content-three .banner-content {
  position: static;
  -webkit-transform: unset;
  transform: unset;
  text-align: left;
  margin: unset;
}

.boxcar-banner-section-five.banner-style-three .form-tab-pane form {
  border-radius: 15px;
}

.boxcar-banner-section-five.banner-style-three .drop-menu .dropdown {
  border-radius: 15px;
}

/*** 

====================================================================
  banner section six
====================================================================

***/
.boxcar-banner-section-six {
  position: relative;
}

.boxcar-banner-section-six::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 946px;
  height: 820px;
  background-image: url(../images/resource/banner-six.png);
}

.boxcar-banner-section-six .content-column .inner-column {
  padding: 252px 0 254px;
}

.boxcar-banner-section-six .content-column .inner-column span {
  color: var(--theme-color-dark);
  margin-bottom: 23px;
  display: inline-block;
}

.boxcar-banner-section-six .content-column .inner-column h1 {
  font-size: 70px;
  font-weight: 700;
  margin-bottom: 41px;
}

.boxcar-banner-section-six .content-column .inner-column .btn-box {
  margin-bottom: 63px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.boxcar-banner-section-six .content-column .inner-column .btn-box .read-more {
  font-weight: 500;
  font-size: 15px;
  font-family: var(--title-font);
  line-height: 26px;
  text-transform: capitalize;
  padding: 14px 26px 12px;
  border-radius: 12px;
  border: 1px solid var(--theme-color1);
  display: inline-block;
  color: var(--theme-color-dark);
  margin-right: 30px;
}

.boxcar-banner-section-six .content-column .inner-column .btn-box .read-more:last-child {
  margin-right: 0;
}

.boxcar-banner-section-six .content-column .inner-column .btn-box .read-more svg {
  position: relative;
  top: -4px;
  color: var(--theme-color-dark);
  width: 14px;
  height: 14px;
  margin-left: 10px;
}

.boxcar-banner-section-six .content-column .inner-column .btn-box .read-more:hover {
  background-color: var(--theme-color1);
  color: var(--theme-color-light);
}

.boxcar-banner-section-six .content-column .inner-column .right-box .boxcar-title {
  margin-bottom: 23px;
}

.boxcar-banner-section-six .content-column .inner-column .right-box .boxcar-title h6 {
  font-size: 16px;
  color: var(--theme-color-dark);
  font-weight: 500;
}

.boxcar-banner-section-six .content-column .inner-column .right-box .service-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.boxcar-banner-section-six .content-column .inner-column .right-box .service-list li {
  margin-right: 10px;
}

.boxcar-banner-section-six .content-column .inner-column .right-box .service-list li:last-child {
  margin-right: 0;
}

.boxcar-banner-section-six .content-column .inner-column .right-box .service-list li a {
  font-weight: 500;
  color: var(--theme-color-dark);
  padding: 7px 30px 7px;
  border-radius: 50px;
  border: 1px solid var(--Border, #E1E1E1);
  display: inline-block;
}

.boxcar-banner-section-six .content-column .inner-column .right-box .service-list li a i {
  color: #000;
  font-size: 24px;
  margin-right: 11px;
  position: relative;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  top: 5px;
}

.boxcar-banner-section-six .content-column .inner-column .right-box .service-list li a:hover {
  background: var(--theme-color1);
  color: #fff;
  border-color: transparent;
}

.boxcar-banner-section-six .content-column .inner-column .right-box .service-list li a:hover i {
  color: #fff;
}

/*** 

====================================================================
  banner section seven
====================================================================

***/
.boxcar-banner-section-seven {
  position: relative;
}

.boxcar-banner-section-seven.v10 .banner-slider-v7 .slick-prev {
  left: 5vw;
}

.boxcar-banner-section-seven.v10 .banner-slider-v7 .slick-next {
  right: 5vw;
}

.boxcar-banner-section-seven.v10 .large-container {
  max-width: 1829px;
  padding: 0 15px;
}

.boxcar-banner-section-seven.v10 .form-tab-content {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 99;
}

.boxcar-banner-section-seven.v10 .form-submit {
  margin-left: auto;
}

.boxcar-banner-section-seven.v10 .form-submit button {
  color: #fff;
  font-size: 15px;
  font-weight: 500;
  height: 56px;
  line-height: 56px;
  padding: 0 30px;
  border-radius: 16px;
  border: 1px solid #fff;
  background: transparent;
}

.boxcar-banner-section-seven.v10 .form-submit button i {
  position: relative;
  margin-right: 7px;
  top: 2px;
}

.boxcar-banner-section-seven.v10 form {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background: #050B20;
  border-radius: 16px 16px 0px 0px;
  padding: 10px 20px;
}

.boxcar-banner-section-seven.v10 form .form_boxes {
  width: 20%;
  height: 76px;
  position: relative;
}

.boxcar-banner-section-seven.v10 form .form_boxes:before {
  content: "";
  position: absolute;
  top: 50%;
  right: 0;
  width: 1px;
  height: 36px;
  background: #fff;
  opacity: 0.2;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.boxcar-banner-section-seven.v10 form .form_boxes:nth-child(4):before {
  display: none;
}

.boxcar-banner-section-seven.v10 form .drop-menu .select {
  color: #fff;
}

.boxcar-banner-section-seven .banner-slider-v7 {
  position: relative;
}

.boxcar-banner-section-seven .banner-slider-v7 .slick-prev,
.boxcar-banner-section-seven .banner-slider-v7 .slick-next {
  width: 60px;
  height: 40px;
  border-radius: 30px;
  background: rgba(255, 255, 255, 0.2);
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  z-index: 99;
  border: 0;
}

.boxcar-banner-section-seven .banner-slider-v7 .slick-prev:before,
.boxcar-banner-section-seven .banner-slider-v7 .slick-next:before {
  color: #fff;
}

.boxcar-banner-section-seven .banner-slider-v7 .slick-prev {
  left: 8vw;
}

.boxcar-banner-section-seven .banner-slider-v7 .slick-next {
  right: 8vw;
}

.boxcar-banner-section-seven .banner-slider-v7 img {
  width: 100%;
  border-radius: 16px;
}

.boxcar-banner-section-seven .banner-slider-v7 .banner-slide {
  position: relative;
  z-index: 9;
}

.boxcar-banner-section-seven .banner-slider-v7 .banner-slide:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #050B20;
  opacity: 0.3;
  border-radius: 16px;
}

.boxcar-banner-section-seven .banner-slider-v7 .banner-slide .right-box {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  left: 0;
  width: 100%;
  z-index: 99;
}

.boxcar-banner-section-seven .banner-slider-v7 .banner-slide .right-box .content-box {
  text-align: center;
}

.boxcar-banner-section-seven .banner-slider-v7 .banner-slide .right-box .content-box .sub-title {
  color: var(--theme-color-light);
  margin-bottom: 19px;
  display: inline-block;
}

.boxcar-banner-section-seven .banner-slider-v7 .banner-slide .right-box .content-box h1 {
  font-size: 70px;
  color: var(--theme-color-light);
  font-weight: 700;
  line-height: normal;
}

/*** 

====================================================================
  banner section homepage 8
====================================================================

***/
.boxcar-banner-section-v8 {
  background-image: url(../images/background/banner-v8.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  position: relative;
  border-radius: 32px;
  margin: 30px 30px 0px;
}

@media (max-width: 1440px) {
  .boxcar-banner-section-v8 {
    margin: 0;
    border-radius: 0;
  }
}

.boxcar-banner-section-v8 .boxcar-container {
  height: 100%;
}

.boxcar-banner-section-v8 .banner-content-v8 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.boxcar-banner-section-v8 .banner-content-v8 h2 {
  color: #fff;
  font-size: 52px;
  font-weight: 500;
  margin-bottom: 50px;
}

.boxcar-banner-section-v8 .banner-content-v8 .banner-v8-form {
  max-width: 448px;
  background: #fff;
  border-radius: 16px;
  padding: 30px;
}

.boxcar-banner-section-v8 .banner-content-v8 .banner-v8-form .form-tabs-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  border: 1px solid #050B20;
  border-radius: 16px;
  height: 53px;
  line-height: 53px;
  margin-bottom: 20px;
}

.boxcar-banner-section-v8 .banner-content-v8 .banner-v8-form .form-tabs-list li {
  width: 50%;
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
}

.boxcar-banner-section-v8 .banner-content-v8 .banner-v8-form .form-tabs-list li.current {
  border-radius: 16px 0px 0px 16px;
  background-color: #050B20;
  color: #fff;
}

.boxcar-banner-section-v8 .banner-content-v8 .banner-v8-form .form-tabs-list li:last-child.current {
  border-radius: 0 16px 16px 0;
}

.boxcar-banner-section-v8 .banner-content-v8 .banner-v8-form .form-tab-pane {
  display: none;
}

.boxcar-banner-section-v8 .banner-content-v8 .banner-v8-form .form-tab-pane.current {
  display: block;
}

.boxcar-banner-section-v8 .banner-content-v8 .banner-v8-form .form-tab-pane form .form_boxes {
  width: 100%;
  height: 76px;
  margin-bottom: 20px;
}

.boxcar-banner-section-v8 .banner-content-v8 .banner-v8-form .drop-menu {
  border: 1px solid #e1e1e1;
  border-radius: 16px;
  padding: 7px 15px;
}

.boxcar-banner-section-v8 .banner-content-v8 .banner-v8-form .drop-menu .dropdown {
  border-radius: 16px;
  padding: 15px;
  z-index: 9;
  margin: 0;
  -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.boxcar-banner-section-v8 .banner-content-v8 .banner-v8-form .drop-menu>label {
  display: block;
  color: #818181;
  font-size: 13px;
  font-weight: 400;
}

.boxcar-banner-section-v8 .banner-content-v8 .banner-v8-form .drop-menu .select {
  padding: 0;
  height: unset;
  line-height: unset;
}

.boxcar-banner-section-v8 .banner-content-v8 .banner-v8-form .drop-menu .select i {
  right: 0;
}

.boxcar-banner-section-v8 .banner-content-v8 .banner-v8-form .form-submit {
  margin-left: auto;
}

.boxcar-banner-section-v8 .banner-content-v8 .banner-v8-form .form-submit button {
  color: #fff;
  font-size: 15px;
  font-weight: 500;
  height: 56px;
  line-height: 56px;
  padding: 0 30px;
  width: 100%;
  border-radius: 30px;
  background-color: #405FF2;
}

.boxcar-banner-section-v8 .banner-content-v8 .banner-v8-form .form-submit button i {
  position: relative;
  margin-right: 7px;
  top: 2px;
}

/*** 

====================================================================
  banner section
====================================================================

***/
.boxcar-banner-section-nine {
  background-image: url(../images/banner/banner-page9.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  position: relative;
}

.boxcar-banner-section-nine:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #050B20;
  opacity: 0.4;
}

.boxcar-banner-section-nine .drop-menu.active .select>span,
.boxcar-banner-section-nine .drop-menu.active .select i,
.boxcar-banner-section-nine .drop-menu .dropdown li:hover {
  color: #fff;
}

.boxcar-banner-section-nine .banner-content {
  width: 924px;
  margin: 0 auto;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.boxcar-banner-section-nine .banner-content .content-box {
  margin-top: 166px;
  position: relative;
}

.boxcar-banner-section-nine .banner-content .content-box span {
  display: block;
  color: #fff;
  font-size: 16px;
  line-height: 28px;
  margin-bottom: 30px;
}

.boxcar-banner-section-nine .banner-content .content-box h2 {
  color: #fff;
  font-size: 70px;
  text-transform: capitalize;
  font-weight: 700;
  margin-bottom: 55px;
}

.boxcar-banner-section-nine .banner-content .content-box .btn-box {
  margin-bottom: 63px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.boxcar-banner-section-nine .banner-content .content-box .btn-box .read-more {
  font-weight: 500;
  font-size: 15px;
  font-family: var(--title-font);
  line-height: 26px;
  text-transform: capitalize;
  padding: 14px 26px 12px;
  border-radius: 50px;
  border: 1px solid var(--theme-color-light);
  display: inline-block;
  color: var(--theme-color-light);
  margin-right: 30px;
}

.boxcar-banner-section-nine .banner-content .content-box .btn-box .read-more:last-child {
  margin-right: 0;
}

.boxcar-banner-section-nine .banner-content .content-box .btn-box .read-more svg {
  position: relative;
  top: -4px;
  color: var(--theme-color-dark);
  width: 14px;
  height: 14px;
  margin-left: 10px;
}

.boxcar-banner-section-nine .banner-content .content-box .btn-box .read-more:hover {
  background-color: var(--theme-color-light);
  color: var(--theme-color-dark);
}

.boxcar-banner-section-nine .banner-content .content-box .btn-box .read-more.active {
  background-color: var(--theme-color-light);
  color: var(--theme-color-dark);
}

.boxcar-banner-section-nine .banner-content .content-box .btn-box .read-more.active svg {
  color: var(--theme-color-dark) !important;
}

.boxcar-banner-section-nine .banner-content .form-tabs-list {
  margin-bottom: 22px;
}

.boxcar-banner-section-nine .banner-content .form-tabs-list li {
  display: inline-block;
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  text-transform: capitalize;
  margin-right: 26px;
  cursor: pointer;
  position: relative;
  padding-bottom: 3px;
}

.boxcar-banner-section-nine .banner-content .form-tabs-list li:before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #fff;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.boxcar-banner-section-nine .banner-content .form-tabs-list li.current:before {
  opacity: 1;
  visibility: visible;
}

.boxcar-banner-section-nine .banner-content .form-tabs-list li:last-child {
  margin-right: 0;
}

.boxcar-banner-section-nine .banner-content .form-tab-pane {
  display: none;
}

.boxcar-banner-section-nine .banner-content .form-tab-pane.current {
  display: block;
}

.boxcar-banner-section-nine .banner-content .form-tab-pane form {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 50px;
  padding: 0 10px;
  height: 76px;
  border: 1px solid var(--White, #FFF);
  background: rgba(255, 255, 255, 0.05);
  -webkit-backdrop-filter: blur(7.5px);
  backdrop-filter: blur(7.5px);
}

.boxcar-banner-section-nine .banner-content .form-tab-pane form .form_boxes {
  width: 19%;
  height: 76px;
}

.boxcar-banner-section-nine .banner-content .form-submit {
  margin-left: auto;
}

.boxcar-banner-section-nine .banner-content .form-submit button {
  color: var(--theme-color-dark);
  font-size: 15px;
  font-weight: 500;
  height: 56px;
  line-height: 56px;
  padding: 0 30px;
  border-radius: 30px;
  background-color: var(--theme-color-light);
}

.boxcar-banner-section-nine .banner-content .form-submit button i {
  position: relative;
  margin-right: 7px;
  top: 2px;
}

.boxcar-banner-section-nine .banner-content .form-tab-content>span {
  display: block;
  color: #fff;
  font-size: 15px;
  font-weight: 500;
  margin-bottom: 31px;
}

.boxcar-banner-section-nine .banner-content .form-tab-content .model-links li {
  display: inline-block;
  margin-right: 6px;
}

.boxcar-banner-section-nine .banner-content .form-tab-content .model-links li:last-child {
  margin-right: 0;
}

.boxcar-banner-section-nine .banner-content .form-tab-content .model-links li a {
  background: rgba(255, 255, 255, 0.2);
  display: inline-block;
  color: #fff;
  font-size: 15px;
  font-weight: 500;
  height: 44px;
  line-height: 44px;
  padding: 0 30px;
  border-radius: 30px;
}

.boxcar-banner-section-nine .banner-content .form-tab-content .model-links li a img {
  margin-right: 5px;
}

.boxcar-banner-section-nine .drop-menu .select {
  color: var(--theme-color-light);
}

.boxcar-banner-section-nine.banner-style-three {
  background-image: url(../images/banner/banner-hp3.jpg);
}

.boxcar-banner-section-nine.banner-style-three:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #050B20;
  opacity: 0.4;
}

.boxcar-banner-section-nine.banner-style-three .banner-content-three {
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  z-index: 99;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.boxcar-banner-section-nine.banner-style-three .banner-content-three .banner-content {
  position: static;
  -webkit-transform: unset;
  transform: unset;
  text-align: left;
  margin: unset;
}

.boxcar-banner-section-nine.banner-style-three .form-tab-pane form {
  border-radius: 15px;
}

.boxcar-banner-section-nine.banner-style-three .drop-menu .dropdown {
  border-radius: 15px;
}

.banner-slider-v4 {
  position: relative;
}

/*** 

====================================================================
	googel-maps-Section
====================================================================

***/
.googel-map-section {
  position: relative;
}

.googel-map-section .right-box .goole-iframe iframe {
  width: 100%;
  height: 473px;
}

/*** 

====================================================================
    brand section
====================================================================

***/
.boxcar-brand-section {
  position: relative;
  padding: 120px 0;
  /* Medium devices */
}

.bg-1 {
  background-color: rgba(249, 251, 252, 1);

}

.bg-2 {
  background-color: rgba(223, 223, 223, 1);
}

.section-radius-top {
  border-top-left-radius: 80px;
  border-top-right-radius: 80px;
  margin-top: -80px;
  overflow: hidden;
}

.section-radius-bottom {
  margin-bottom: -80px;
  border-bottom-left-radius: 80px;
  border-bottom-right-radius: 80px;
  position: relative;
  z-index: 1;
  overflow: hidden;
}

@media (max-width: 991px) {
  .boxcar-brand-section {
    padding: 90px 0;
  }

  .section-radius-top {
    border-top-left-radius: 40px;
    border-top-right-radius: 40px;
  }

  .section-radius-bottom {
    border-bottom-left-radius: 40px;
    border-bottom-right-radius: 40px;
  }
}

.cars-block .inner-box {
  text-align: center;
  padding: 0 40px 0 39px;
  border-radius: 16px;
  border: 1px solid var(--Border, #E1E1E1);
  height: 180px;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 100%;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  /* Medium devices */
}

.cars-block.style-1 .inner-box {
  background-color: #fff;
}

@media (max-width: 991px) {
  .cars-block .inner-box {
    margin-bottom: 24px;
  }
}

.cars-block .inner-box:hover {
  border-color: var(--theme-color1);
}

.cars-block .inner-box .image-box {
  margin-bottom: 0;
  height: 100px;
}

.cars-block .inner-box .content-box .title {
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 27px;
}

.boxcar-brand-section.v7::before {
  content: "";
  position: absolute;
  top: 0;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  width: 1162px;
  height: 500px;
  border-radius: 16px;
  background: var(--bg-1, #F9FBFC);
}

/*** 

====================================================================
    brand section two
====================================================================

***/
.boxcar-brand-section-two {
  position: relative;
  padding: 120px 0;
}

.cars-block-two .inner-box {
  text-align: center;
  padding: 45px 40px 20px 39px;
  border-radius: 16px;
  border: 1px solid var(--Border, #E1E1E1);
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.cars-block-two .inner-box:hover {
  border-color: var(--theme-color-dark);
  background-color: rgba(249, 251, 252, 1);
}

.cars-block-two .inner-box:hover .image-box {
  background-color: var(--theme-color-dark);
}

.cars-block-two:not(.style-svg) .inner-box:hover .image-box svg path {
  fill: var(--theme-color-light);
}

.cars-block-two.style-svg .inner-box:hover .image-box svg path {
  stroke: var(--theme-color-light);
}

.cars-block-two .inner-box .image-box {
  width: 70px;
  height: 70px;
  line-height: 70px;
  text-align: center;
  margin: 0 auto 0;
  background-color: #F9FBFC;
  border-radius: 50%;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  margin-bottom: 12px;
}

.cars-block-two .inner-box .image-box svg path {}

.cars-block-two .inner-box .content-box .title {
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 27px;
}

/*** 

====================================================================
    brand banner section
====================================================================

***/
.brand-boxcar-banner-section {
  position: relative;
  padding: 211px 0 167px;
  background-image: url(../images/background/child.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  z-index: 9;
}

.brand-boxcar-banner-section:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #050B20;
  opacity: 0.3;
  z-index: -1;
}

.brand-boxcar-banner-section .content-column .inner-column .title {
  font-size: 70px;
  color: var(--theme-color-light);
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
  margin-bottom: 28px;
}

.brand-boxcar-banner-section .content-column .inner-column .btn {
  padding: 20px 87px 18px;
  line-height: 26px;
  color: var(--theme-color-light);
  border-radius: 12px;
  border: 1px solid var(--White, #FFF);
}

.brand-boxcar-banner-section .content-column .inner-column .btn svg {
  fill: var(--theme-color-light);
  margin-left: 10px;
  position: relative;
  top: -3px;
}

/*** 

====================================================================
    brand section-three
====================================================================

***/
.boxcar-brand-section-three {
  position: relative;
}

.boxcar-brand-section-three .right-box {
  padding: 80px 152px 87px;
  border-radius: 16px;
  background: var(--bg-1, #F9FBFC);
}

.boxcar-brand-section-three .content-column .inner-column {
  max-width: 470px;
  margin-top: 140px;
}

.boxcar-brand-section-three .content-column .inner-column .boxcar-title {
  margin-bottom: 0;
}

.boxcar-brand-section-three .content-column .inner-column .boxcar-title h2 {
  margin-bottom: 13px;
}

.boxcar-brand-section-three .content-column .inner-column .boxcar-title .text {
  margin-top: 13px;
  margin-bottom: 50px;
}

.boxcar-brand-section-three .content-column .inner-column .boxcar-title .brand-btn {
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  font-family: var(--title=font);
  color: var(--theme-color-light);
  background-color: var(--theme-color1);
  display: inline-block;
  padding: 13px 26px 11px;
  border-radius: 12px;
  border: 1px solid var(--theme-color1);
  background: var(--theme-color1);
}

.boxcar-brand-section-three .content-column .inner-column .boxcar-title .brand-btn svg {
  position: relative;
  top: -2px;
  margin-left: 11px;
}

.boxcar-brand-section-three .image-column .inner-column .image-box {
  text-align: right;
}

/*** 

====================================================================
    boxcar-brand-section-four
====================================================================

***/
.boxcar-brand-section-four {
  position: relative;
  padding: 120px 0;
}

@media (max-width: 768px) {
  .boxcar-brand-section-four {
    padding-top: 180px;
  }

}

.boxcar-brand-section-four .right-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  /* -ms-flex-wrap: wrap;
  flex-wrap: wrap; */
  overflow-x: auto;
}

.brand-block-four {
  margin-right: 15px;
}

.brand-block-four:last-child {
  margin-right: 0;
}

.brand-block-four .inner-box {
  border-radius: 16px;
  border: 1px solid var(--Border, #E1E1E1);
  background: var(--White, #FFF);
  text-align: center;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  width: 140px;
  padding: 30px 0;
}

.brand-block-four .inner-box:hover {
  background: var(--bg-1, #F9FBFC);
}

.brand-block-four .inner-box .icon-box {
  min-height: 40px;
  max-height: 40px;
}

.brand-block-four .inner-box .icon-box i {
  color: #000;
  font-size: 40px;
}

.brand-block-four .inner-box .title {
  margin-top: 15px;
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 0;
}

/*** 

====================================================================
    brand section five
====================================================================

***/
.boxcar-brand-section-five {
  position: relative;
  padding: 120px 0;
}

.boxcar-brand-section-five .right-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.cars-block-five {
  margin-right: 66px;
}

.cars-block-five:last-child {
  margin-right: 0;
}

.cars-block-five .inner-box {
  text-align: center;
}

.cars-block-five .inner-box .image-box {
  margin-bottom: 0;
  height: 70px;
}

.cars-block-five .inner-box .content-box .title {
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 27px;
  text-transform: capitalize;
}

/*** 

====================================================================
    brand section six
====================================================================

***/
.boxcar-brand-section-six {
  position: relative;
  padding: 120px 0;
}

.boxcar-brand-section-six .right-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  overflow: auto;
}

.boxcar-brand-section-six .right-box::-webkit-scrollbar {
  width: 1px;
}

.boxcar-brand-section-six .right-box::-webkit-scrollbar-thumb {
  background: transparent;
}

@media (max-width: 1400px) {
  .boxcar-brand-section-six .right-box {
    margin-right: -15px;
    padding-right: 15px;
  }
}

.cars-block-six {
  margin-right: 20px;
}

.cars-block-six:last-child {
  margin-right: 0;
}

.cars-block-six .inner-box {
  text-align: center;
  padding: 45px 0 20px;
  border-radius: 16px;
  border: 1px solid var(--Border, #E1E1E1);
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  width: 138px;
}

.cars-block-six.home-8 .inner-box {
  padding: 0;
  height: 127px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.cars-block-six.home-8:not(:last-child) {
  margin-right: 16px;
}

.cars-block-six.home-8 .inner-box .title {
  margin-bottom: 0;
}

.cars-block-six .inner-box:hover {
  background: var(--bg-1, #F9FBFC);
}

.cars-block-six .inner-box .image-box {
  margin-bottom: 12px;
}

.cars-block-six .inner-box .image-box svg {
  fill: var(--theme-color-dark);
}

.cars-block-six.home-8 .inner-box .image-box svg {
  fill: none;
}


.cars-block-six .inner-box .content-box .title {
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 27px;
}

/*** 

====================================================================
    why choose section
====================================================================

***/
.why-choose-us-section {
  position: relative;
  padding: 120px 0 64px;
}

.why-choose-us-section .content-column .boxcar-title h2 {
  line-height: 45px;
}

.why-choose-us-section .content-column-two .boxcar-title .text {
  margin-bottom: 56px;
}

.why-choose-us-section .content-column-two .boxcar-title .read-more {
  padding: 14px 25px 12px;
  border-radius: 12px;
  border: 1px solid var(--color-secondary, var(--theme-color1));
  background: var(--color-secondary, var(--theme-color1));
  display: inline-block;
  font-family: var(--title-font);
  color: var(--theme-color-light);
}

.why-choose-us-section .content-column-two .boxcar-title .read-more svg {
  position: relative;
  top: -2px;
  margin-left: 7px;
}

.choose-us-block {
  margin-bottom: 56px;
}

.choose-us-block .inner-box .icon-box {
  margin-bottom: 24px;
}

.choose-us-block .inner-box .content-box .title {
  font-weight: 500;
  margin-bottom: 17px;
}

.choose-us-block .inner-box .content-box .text {
  padding-right: 65px;
  font-size: 15px;
  line-height: 26px;
}

.why-choose-us-section.v2 .boxcar-container {
  max-width: 990px;
}

/*** 

====================================================================
    why choose section tow
====================================================================
}
***/
.why-choose-us-section-two {
  position: relative;
  padding: 120px 0 64px;
  border-radius: 16px;
  background: var(--bg-1, #F9FBFC);
}

.why-choose-us-section-two .boxcar-container {
  max-width: 1800px;
}

.why-choose-us-section-two .right-box {
  max-width: 1430px;
  margin: 0 auto;
}

/*** 

====================================================================
    why choose section three
====================================================================

***/
.why-choose-us-section-three {
  position: relative;
  padding: 120px 0;
}

.why-choose-us-section-three .content-column .boxcar-title h2 {
  line-height: 45px;
}

.why-choose-us-section-three .right-box {
  border-radius: 16px;
  background: var(--bg-1, #F9FBFC);
  padding: 120px 150px 64px;
}

/*** 

====================================================================
    why choose section
====================================================================

***/
.why-choose-us-section-four {
  position: relative;
  padding: 120px 0 64px;
}

.why-choose-us-section-four::before {
  content: "";
  position: absolute;
  top: 0;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  width: 1162px;
  height: 620px;
  border-radius: 16px;
  background: var(--bg-1, #F9FBFC);
}

.why-choose-us-section-four .content-column .boxcar-title h2 {
  line-height: 45px;
}

.choose-us-block-four {
  margin-bottom: 56px;
}

.choose-us-block-four .inner-box {
  padding: 50px 41px 43px;
  border-radius: 16px;
  background: var(--theme-color-light);
  -webkit-box-shadow: 0px 10px 40px 0px rgba(0, 0, 0, 0.05);
  box-shadow: 0px 10px 40px 0px rgba(0, 0, 0, 0.05);
}

.choose-us-block-four .inner-box .icon-box {
  margin-bottom: 24px;
}

.choose-us-block-four .inner-box .content-box .title {
  font-weight: 500;
  margin-bottom: 17px;
}

.choose-us-block-four .inner-box .content-box .text {
  font-size: 15px;
  line-height: 26px;
}

/*** 

====================================================================
    why choose section five
====================================================================

***/
.why-choose-us-section-five {
  position: relative;
  padding: 120px 0 64px;
  background-color: var(--theme-color1);
}

.why-choose-us-section-five .content-column .boxcar-title h2 {
  line-height: 45px;
}

.why-choose-us-section-five .content-column-two .boxcar-title .text {
  margin-bottom: 56px;
}

.why-choose-us-section-five .content-column-two .boxcar-title .read-more {
  padding: 14px 25px 12px;
  border-radius: 12px;
  border: 1px solid var(--color-secondary, var(--theme-color1));
  background: var(--color-secondary, var(--theme-color1));
  display: inline-block;
  font-family: var(--title-font);
  color: var(--theme-color-light);
}

.why-choose-us-section-five .content-column-two .boxcar-title .read-more svg {
  position: relative;
  top: -2px;
  margin-left: 7px;
}

.choose-us-block-five {
  margin-bottom: 56px;
}

.choose-us-block-five .inner-box .icon-box {
  margin-bottom: 24px;
}

.choose-us-block-five .inner-box .content-box .title {
  font-weight: 500;
  margin-bottom: 17px;
  color: var(--theme-color-light);
}

.choose-us-block-five .inner-box .content-box .text {
  padding-right: 65px;
  font-size: 15px;
  line-height: 26px;
  color: var(--theme-color-light);
}

/* Small devices */
@media (max-width: 767px) {
  .blog-blockt-two {
    margin-bottom: 25px;
  }

  .blog-blockt-two:last-child {
    margin-bottom: 0;
  }
}

/*** 

====================================================================
    testimonial section
====================================================================

***/
.boxcar-testimonial-section {
  position: relative;
  padding: 120px 0;
  background: #F9FBFC;
}

.boxcar-testimonial-section .image-column .inner-column .image-box .image {
  /* Medium devices */
}

@media (max-width: 991px) {
  .boxcar-testimonial-section .image-column .inner-column .image-box .image {
    margin-bottom: 30px;
  }

  .boxcar-testimonial-section .image-column .inner-column .image-box .image a {
    display: block;
  }
}

.boxcar-testimonial-section .image-column .inner-column .image-box .image img {
  border-radius: 10px;
}

.boxcar-testimonial-section .content-column .inner-column {
  margin-top: 109px;
  margin-left: 120px;
  /* Large devices */
}

@media (max-width: 1199px) {
  .boxcar-testimonial-section .content-column .inner-column {
    margin-top: 0;
    margin-left: 0;
  }
}

.boxcar-testimonial-section .content-column .inner-column .rating {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 10px;
}

.boxcar-testimonial-section .content-column .inner-column .rating li {
  color: #E1C03F;
  font-size: 14px;
  margin-right: 9px;
}

.boxcar-testimonial-section .content-column .inner-column .rating span {
  width: 37px;
  height: 26px;
  line-height: 26px;
  background-color: #E1C03F;
  color: var(--theme-color-light);
  font-size: 14px;
  font-weight: 500;
  text-align: center;
  border-radius: 50px;
}

.boxcar-testimonial-section .content-column .inner-column .title {
  font-size: 18px;
  font-weight: 500;
  line-height: 32px;
  margin-bottom: -3px;
}

.boxcar-testimonial-section .content-column .inner-column span {
  font-size: 14px;
  line-height: 24px;
}

.boxcar-testimonial-section .content-column .inner-column .text {
  font-size: 26px;
  font-weight: 500;
  line-height: 42px;
  margin-top: 39px;
  margin-right: 80px;
}

/*** 

====================================================================
    testimonial section two
====================================================================

***/
.testimonila-section-two {
  position: relative;
  padding: 120px 0;
}

@media (max-width: 991px) {
  .testimonila-section-two {
    padding: 90px 0px;
  }
}

.testimonila-section-two .content-column .inner-column .boxcar-title {
  margin-bottom: 43px;
}

.testimonila-section-two .content-column .inner-column .content-box span {
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 13px;
  display: inline-block;
}

.testimonila-section-two .content-column .inner-column .content-box .rating {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 12px;
}

.testimonila-section-two .content-column .inner-column .content-box .rating li {
  width: 37.5px;
  height: 37.5px;
  text-align: center;
  line-height: 37.5px;
  font-size: 25px;
  background-color: var(--theme-color2);
  color: var(--theme-color-light);
  margin-right: 3px;
}

.testimonila-section-two .content-column .inner-column .content-box .rating li:last-child {
  margin-right: 0;
}

.testimonila-section-two .content-column .inner-column .content-box small {
  font-size: 15px;
  margin-bottom: 18px;
  display: inline-block;
}

.testimonila-section-two .content-column .inner-column .content-box sub {
  display: block;
  font-size: 24px;
  font-weight: 500;
  text-transform: capitalize;
}

.testimonila-section-two .content-column .inner-column .content-box sub i {
  color: var(--theme-color2);
  margin-right: 2px;
}

.testimonila-section-two .slider-column .inner-column .rating-area {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 20px;
}

.testimonila-section-two .slider-column .inner-column .rating-area span {
  color: #6C6C83;
  margin-left: 20px;
}

.testimonila-section-two .slider-column .inner-column .rating-area span i {
  margin-right: 7px;
}

.testimonila-section-two .slider-column .inner-column .rating-area .rating {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.testimonila-section-two .slider-column .inner-column .rating-area .rating li {
  color: var(--theme-color-light);
  width: 18.75px;
  height: 18.75px;
  background-color: #00B67A;
  line-height: 18.75px;
  font-size: 12px;
  margin-left: 1px;
  text-align: center;
}

.testimonila-section-two .slider-column .inner-column .auther-info .name {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 0;
}

.testimonila-section-two .slider-column .inner-column .auther-info .designation {
  font-size: 14px;
}

.testimonila-section-two .slider-column .inner-column .auther-info .text {
  margin-top: 46px;
  font-size: 24px;
  line-height: 42px;
  font-weight: 500;
}

.testimonila-section-two .testi-two-slider {
  margin-bottom: 30px;
}

.testimonila-section-two .testi-two-thumb-slide {
  margin-right: 0;
  position: relative;
  cursor: pointer;
}

.testimonila-section-two .testi-two-thumb-slide:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 1px solid #405FF2;
  border-radius: 50%;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.testimonila-section-two .testi-two-thumb-slide.slick-current:before {
  opacity: 1;
  visibility: visible;
}

.testimonila-section-two .testi-two-thumb-slide.slick-current>img {
  opacity: 1;
}

.testimonila-section-two .testi-two-thumb-slide>img {
  border-radius: 50%;
  padding: 10px;
  opacity: 0.5;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

/*** 

====================================================================
    testimonial section three
====================================================================

***/
.boxcar-testimonial-section-three {
  position: relative;
}

.boxcar-testimonial-section-three .right-box {
  background-color: var(--theme-color1);
  border-radius: 16px;
  padding: 110px 150px;
}

.boxcar-testimonial-section-three .content-column .inner-column {
  margin-top: 47px;
}

.boxcar-testimonial-section-three .content-column .inner-column .boxcar-title {
  margin-bottom: 0px;
}

.boxcar-testimonial-section-three .content-column .inner-column .boxcar-title h2 {
  margin-bottom: 13px;
}

.boxcar-testimonial-section-three .content-column .inner-column .boxcar-title .text {
  margin-top: 13px;
  padding-right: 130px;
}

.testimonial-block-three .inner-box {
  background-color: var(--theme-color-light);
  text-align: center;
  padding: 34px 0 40px;
  border-radius: 16px;
}

.testimonial-block-three .inner-box .content-box span {
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 13px;
  display: inline-block;
}

.testimonial-block-three .inner-box .content-box .rating {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 12px;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.testimonial-block-three .inner-box .content-box .rating li {
  width: 37.5px;
  height: 37.5px;
  text-align: center;
  line-height: 37.5px;
  font-size: 25px;
  background-color: var(--theme-color2);
  color: var(--theme-color-light);
  margin-right: 3px;
}

.testimonial-block-three .inner-box .content-box .rating li:last-child {
  margin-right: 0;
}

.testimonial-block-three .inner-box .content-box small {
  font-size: 15px;
  margin-bottom: 10px;
  display: inline-block;
}

.testimonial-block-three .inner-box.v2 {
  padding-bottom: 24px;
}

.testimonial-block-three .inner-box.v3 {
  padding-bottom: 23px;
}

/*** 

====================================================================
    testimonial section four
====================================================================

***/
.boxcar-testimonial-section-four {
  position: relative;
  padding: 120px 0;
}

.boxcar-testimonial-section-four .boxcar-title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: baseline;
  -ms-flex-align: baseline;
  align-items: baseline;
}

.boxcar-testimonial-section-four .right-box {
  border-radius: 16px;
  background: var(--bg-1, #F9FBFC);
  padding: 120px 150px 150px;
}

.boxcar-testimonial-section-four .stories-slider .slick-prev {
  left: 15px;
  top: auto;
  bottom: -50px;
  width: 60px;
  height: 40px;
  border: 1px solid #050B20;
  border-radius: 30px;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.boxcar-testimonial-section-four .stories-slider .slick-prev::before {
  content: "\f105";
  color: #050B20;
  font-size: 14px;
  font-family: "Font Awesome 6 Pro";
  opacity: 1;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.boxcar-testimonial-section-four .stories-slider .slick-next {
  right: auto;
  left: 95px;
  top: auto;
  bottom: -50px;
}

.testimonial-block-four {
  padding: 0 15px;
  padding-bottom: 60px;
}

.testimonial-block-four .inner-box {
  position: relative;
  border-radius: 16px;
  background: var(--theme-color-light);
  padding: 31px 41px 26px;
}

.testimonial-block-four .inner-box .icon {
  position: absolute;
  top: 30px;
  right: 49px;
}

.testimonial-block-four .inner-box .title {
  font-weight: 500;
  line-height: 30px;
  margin-bottom: 34px;
}

.testimonial-block-four .inner-box .text {
  font-size: 15px;
  line-height: 30px;
  margin-bottom: 42px;
}

.testimonial-block-four .inner-box .auther-info {
  position: relative;
  padding: 9px 0 10px 76px;
}

.testimonial-block-four .inner-box .auther-info .image {
  position: absolute;
  top: 0;
  left: 0;
}

.testimonial-block-four .inner-box .auther-info .image img {
  border-radius: 50%;
}

.testimonial-block-four .inner-box .auther-info .name {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 0;
  line-height: 17px;
}

.testimonial-block-four .inner-box .auther-info span {
  font-size: 14px;
}

.boxcar-testimonial-section-four.v7 .right-box {
  border-radius: 0;
  background-color: unset;
  padding: 0;
}

.boxcar-testimonial-section-four.v7 .testimonial-block-four .inner-box {
  background: #EBF7FE;
  -webkit-box-shadow: unset;
  box-shadow: unset;
}

.boxcar-testimonial-section-four.v8 .slick-next,
.boxcar-testimonial-section-four.v8 .slick-prev {
  display: none !important;
}

.boxcar-testimonial-section-four.v8 .right-box {
  border-radius: 0;
  background-color: var(--theme-color-dark);
  border-radius: 16px;
}

.boxcar-testimonial-section-four.v8 .testimonial-block-four {
  padding-bottom: 0;
}

.boxcar-testimonial-section-four.v8 .testimonial-block-four .inner-box {
  background: var(--theme-color-light);
  -webkit-box-shadow: unset;
  box-shadow: unset;
}

/*** 

====================================================================
    pricing section
====================================================================

***/
.boxcar-pricing-section {
  position: relative;
  padding: 120px 0;
}

.boxcar-pricing-section .image-column {
  margin-bottom: 20px;
}

.boxcar-pricing-section .image-column .inner-column {
  height: 100%;
}

.boxcar-pricing-section .image-column .inner-column .image-box {
  position: relative;
  height: 100%;
}

.boxcar-pricing-section .image-column .inner-column .image-box .image {
  height: 100%;
}

.boxcar-pricing-section .image-column .inner-column .image-box .image a {
  height: 100%;
  display: block;
}

.boxcar-pricing-section .image-column .inner-column .image-box .image img {
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  border-radius: 16px 0 0 16px;
  width: 100%;
  /* Medium devices */
}

@media (max-width: 991px) {
  .boxcar-pricing-section .image-column .inner-column .image-box .image img {
    border-radius: 0;
  }
}

.boxcar-pricing-section .image-column .inner-column .image-box .play-now {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 90px;
  height: 90px;
  background-color: var(--theme-color-light);
  color: var(--theme-color-dark);
  border-radius: 50%;
  text-align: center;
  line-height: 90px;
  font-size: 20px;
}

.boxcar-pricing-section .content-column .inner-column {
  background: var(--bg-2, #EEF1FB);
  padding: 105px 130px 119px;
  height: 100%;
  /* Medium Large devices */
  /* Small devices */
}

@media (max-width: 1399px) {
  .boxcar-pricing-section .content-column .inner-column {
    padding: 70px;
  }
}

@media (max-width: 767px) {
  .boxcar-pricing-section .content-column .inner-column {
    padding: 40px;
  }
}

.boxcar-pricing-section .content-column .inner-column .boxcar-title {
  margin-bottom: 43px;
}

.boxcar-pricing-section .content-column .inner-column .boxcar-title h2 {
  line-height: 55px;
  margin-bottom: 4px;
}

.boxcar-pricing-section .content-column .inner-column .boxcar-title .text {
  margin-top: 1px;
}

.boxcar-pricing-section .content-column .inner-column .list-style-one {
  margin-bottom: 50px;
}

.boxcar-pricing-section .content-column .inner-column .list-style-one li {
  position: relative;
  padding-left: 34px;
  margin-right: 0;
}

.boxcar-pricing-section .content-column .inner-column .list-style-one li i {
  position: absolute;
  top: 0;
  left: 0;
  width: 25px;
  height: 25px;
  line-height: 25px;
  text-align: center;
  background-color: var(--theme-color-light);
  border-radius: 60%;
  font-size: 8px;
}

.boxcar-pricing-section .content-column .inner-column .read-more {
  font-weight: 500;
  font-size: 15px;
  font-family: var(--title-font);
  line-height: 26px;
  text-transform: capitalize;
  padding: 13px 23px 10px;
  border-radius: 12px;
  border: 1px solid var(--color-secondary, var(--theme-color1));
  background: var(--color-secondary, var(--theme-color1));
  display: inline-block;
  color: var(--theme-color-light);
}

.boxcar-pricing-section .content-column .inner-column .read-more svg {
  position: relative;
  top: -4px;
  color: var(--theme-color-light);
  width: 14px;
  height: 14px;
  margin-left: 10px;
}

/*** 

====================================================================
    pricing section two
====================================================================

***/
.boxcar-pricing-section-two {
  position: relative;
  padding: 120px 0;
}

.boxcar-pricing-section-two .image-column .inner-column .image-box {
  position: relative;
}

.boxcar-pricing-section-two .image-column .inner-column .image-box .image img {
  border-radius: 10px;
  width: 100%;
  height: 633px;
  -o-object-fit: cover;
  object-fit: cover;
}

.boxcar-pricing-section-two .content-column .inner-column {
  padding: 150px 0px 119px;
  height: 100%;
}

.boxcar-pricing-section-two .content-column .inner-column .boxcar-title {
  margin-bottom: 40px;
}

.boxcar-pricing-section-two .content-column .inner-column .boxcar-title h2 {
  line-height: 55px;
  margin-bottom: 27px;
}

.boxcar-pricing-section-two .content-column .inner-column .boxcar-title .text {
  margin-top: 0px;
}

.boxcar-pricing-section-two .content-column .inner-column .read-more {
  font-weight: 500;
  font-size: 15px;
  font-family: var(--title-font);
  line-height: 26px;
  text-transform: capitalize;
  padding: 15px 27px 14px;
  border-radius: 12px;
  background: var(--color-secondary, var(--theme-color-dark));
  display: inline-block;
  color: var(--theme-color-light);
}

.boxcar-pricing-section-two .content-column .inner-column .read-more svg {
  position: relative;
  top: -4px;
  color: var(--theme-color-light);
  width: 14px;
  height: 14px;
  margin-left: 10px;
}

.boxcar-pricing-section-two.v2 .content-column .inner-column .read-more {
  background-color: var(--theme-color1);
}

/*** 

====================================================================
    pricing section-three
====================================================================

***/
.boxcar-pricing-section-three {
  position: relative;
  padding: 120px 0;
  background: var(--bg-1, #F9FBFC);
}

.boxcar-pricing-section-three .row {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.boxcar-pricing-section-three .image-column .inner-column .image-box {
  position: relative;
}

.boxcar-pricing-section-three .image-column .inner-column .image-box .image img {
  border-radius: 16px;
  width: 100%;
}

.boxcar-pricing-section-three .image-column .inner-column .image-box .play-now {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 90px;
  height: 90px;
  background-color: var(--theme-color-light);
  color: var(--theme-color-dark);
  border-radius: 50%;
  text-align: center;
  line-height: 90px;
  font-size: 20px;
}

.boxcar-pricing-section-three .content-column .inner-column {
  padding: 0 0px 0 117px;
  height: 100%;
}

.boxcar-pricing-section-three .content-column .inner-column .boxcar-title {
  margin-bottom: 25px;
}

.boxcar-pricing-section-three .content-column .inner-column .boxcar-title h2 {
  line-height: 55px;
  margin-bottom: 5px;
}

.boxcar-pricing-section-three .content-column .inner-column .boxcar-title .text {
  margin-top: 1px;
}

.boxcar-pricing-section-three .content-column .inner-column .read-more {
  font-weight: 500;
  font-size: 15px;
  font-family: var(--title-font);
  line-height: 26px;
  text-transform: capitalize;
  padding: 13px 23px 10px;
  border-radius: 12px;
  border: 1px solid var(--color-secondary, var(--theme-color1));
  background: var(--color-secondary, var(--theme-color1));
  display: inline-block;
  color: var(--theme-color-light);
}

.boxcar-pricing-section-three .content-column .inner-column .read-more svg {
  position: relative;
  top: -4px;
  color: var(--theme-color-light);
  width: 14px;
  height: 14px;
  margin-left: 10px;
}

/*** 

====================================================================
    pricing section four
====================================================================

***/
.boxcar-pricing-section-four {
  position: relative;
  padding: 120px 0;
}

.boxcar-pricing-section-four.style-bg::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 38%;
  background-color: rgba(249, 251, 252, 1);
  border-top-right-radius: 16px;
  border-bottom-right-radius: 16px;
}

@media (max-width: 991px) {
  .boxcar-pricing-section-four.style-bg::before {
    content: none;
  }

  /* .boxcar-pricing-section-four {
    padding-top: 0px;
    padding-bottom: 0px;
  } */
}


.boxcar-pricing-section-four .image-column .inner-column .image-box {
  position: relative;
}

.boxcar-pricing-section-four .image-column .inner-column .image-box .image img {
  border-radius: 16px;
  width: 100%;
}

.boxcar-pricing-section-four .image-column .inner-column .image-box .play-now {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 90px;
  height: 90px;
  background-color: var(--theme-color-light);
  color: var(--theme-color-dark);
  border-radius: 50%;
  text-align: center;
  line-height: 90px;
  font-size: 20px;
}

.boxcar-pricing-section-four .content-column .inner-column {
  padding: 68px 0 80px 92px;
  height: 100%;
}

.boxcar-pricing-section-four .content-column .inner-column .boxcar-title {
  margin-bottom: 43px;
}

.boxcar-pricing-section-four .content-column .inner-column .boxcar-title h2 {
  line-height: 55px;
  margin-bottom: 4px;
}

.boxcar-pricing-section-four .content-column .inner-column .boxcar-title .text {
  margin-top: 1px;
}

.boxcar-pricing-section-four .content-column .inner-column .list-style-one {
  margin-bottom: 50px;
}

.boxcar-pricing-section-four .content-column .inner-column .list-style-one li {
  position: relative;
  padding-left: 34px;
  margin-right: 0;
}

.boxcar-pricing-section-four .content-column .inner-column .list-style-one li i {
  position: absolute;
  top: 0;
  left: 0;
  width: 25px;
  height: 25px;
  line-height: 25px;
  text-align: center;
  background-color: #EEF1FB;
  border-radius: 60%;
  font-size: 8px;
}

.boxcar-pricing-section-four .content-column .inner-column .read-more {
  font-weight: 500;
  font-size: 15px;
  font-family: var(--title-font);
  line-height: 26px;
  text-transform: capitalize;
  padding: 13px 23px 10px;
  border-radius: 12px;
  border: 1px solid var(--color-secondary, var(--theme-color1));
  background: var(--color-secondary, var(--theme-color1));
  display: inline-block;
  color: var(--theme-color-light);
}

.boxcar-pricing-section-four .content-column .inner-column .read-more svg {
  position: relative;
  top: -4px;
  color: var(--theme-color-light);
  width: 14px;
  height: 14px;
  margin-left: 10px;
}

.boxcar-pricing-section-four.v7 .content-column .inner-column {
  padding-left: 124px;
  padding-top: 32px;
}

.boxcar-pricing-section-four.v7 .image-column .image-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: end;
}

.boxcar-pricing-section-four.v7 .image-column .image-box .image-2 {
  margin-bottom: 0;
}

.boxcar-pricing-section-four.v7 .image-column .image-box .image-2 img {
  border-radius: 16px;
}

.boxcar-pricing-section-four.v7 .image-column .image-box .image {
  margin-right: 35px;
}

.boxcar-pricing-section-four.v7 .image-column .image-box .image img {
  border-radius: 16px;
}

.boxcar-pricing-section-four.v9 .image-column .inner-column {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: end;
}

.boxcar-pricing-section-four.v9 .image-column .inner-column .image-box .image-2 {
  margin-top: 73px;
  margin-left: 61px;
  margin-bottom: 0;
}

.boxcar-pricing-section-four.v9 .image-column .inner-column .image-box .image-2 img {
  border-radius: 16px;
}

.boxcar-pricing-section-four.v9 .image-column .inner-column .image-box .image-3 {
  margin-left: 66px;
  margin-bottom: 0;
}

.boxcar-pricing-section-four.v9 .image-column .inner-column .image-box .image-3 img {
  border-radius: 16px;
}

.boxcar-pricing-section-four.v9 .content-column .inner-column {
  padding: 56px 0 94px 123px;
}

/*** 

====================================================================
    pricing section-five
====================================================================

***/
.boxcar-pricing-section-five {
  position: relative;
  background: var(--bg-1, #F9FBFC);
}

.boxcar-pricing-section-five .image-column .inner-column .image-box {
  position: relative;
}

.boxcar-pricing-section-five .image-column .inner-column .image-box .image {
  border-radius: 0;
}

.boxcar-pricing-section-five .image-column .inner-column .image-box .image img {
  width: 100%;
  height: 600px;
  -o-object-fit: cover;
  object-fit: cover;
}

.boxcar-pricing-section-five .content-column .inner-column {
  padding: 151px 190px 0 154px;
  height: 100%;
  background-color: var(--theme-color-dark);
}

.boxcar-pricing-section-five .content-column .inner-column .boxcar-title {
  margin-bottom: 25px;
}

.boxcar-pricing-section-five .content-column .inner-column .boxcar-title h2 {
  line-height: 55px;
  margin-bottom: 27px;
}

.boxcar-pricing-section-five .content-column .inner-column .boxcar-title .text {
  margin-bottom: 40px;
}

.boxcar-pricing-section-five .content-column .inner-column .read-more {
  font-weight: 500;
  font-size: 15px;
  font-family: var(--title-font);
  line-height: 26px;
  text-transform: capitalize;
  padding: 13px 23px 10px;
  border-radius: 12px;
  border: 1px solid var(--color-secondary, var(--theme-color1));
  background: var(--color-secondary, var(--theme-color1));
  display: inline-block;
  color: var(--theme-color-light);
}

.boxcar-pricing-section-five .content-column .inner-column .read-more svg {
  position: relative;
  top: -4px;
  color: var(--theme-color-light);
  width: 14px;
  height: 14px;
  margin-left: 10px;
}

/*** 

====================================================================
    pricing section six
====================================================================

***/
.boxcar-pricing-section-six {
  position: relative;
  padding: 120px 0;
}

.boxcar-pricing-section-six .image-column .inner-column .image-box {
  position: relative;
}

.boxcar-pricing-section-six .image-column .inner-column .image-box .image img {
  border-radius: 10px;
  width: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  height: 500px;
}

.boxcar-pricing-section-six .content-column .inner-column {
  padding: 69px 0px 80px 124px;
  height: 100%;
}

.boxcar-pricing-section-six .content-column .inner-column .boxcar-title {
  margin-bottom: 20px;
}

.boxcar-pricing-section-six .content-column .inner-column .boxcar-title h2 {
  line-height: 55px;
  margin-bottom: 8px;
}

.boxcar-pricing-section-six .content-column .inner-column .boxcar-title .text {
  margin-top: 0px;
}

.boxcar-pricing-section-six .content-column .inner-column .btn-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 60px;
}

.boxcar-pricing-section-six .content-column .inner-column .btn-box .btn-two {
  font-size: 15px;
  font-style: normal;
  display: inline-block;
  font-weight: 500;
  line-height: 28px;
  color: var(--theme-color-dark);
  border-radius: 60px;
  padding: 7px 29px 7px;
  border: 1px solid var(--theme-color-dark);
  margin-right: 20px;
}

.boxcar-pricing-section-six .content-column .inner-column .btn-box .btn-two:last-child {
  margin-right: 0;
}

.boxcar-pricing-section-six .content-column .inner-column .btn-box .btn-two svg {
  fill: var(--theme-color-dark);
  width: 26px;
  height: 26px;
  margin-right: 8px;
}

.boxcar-pricing-section-six .content-column .inner-column .read-more {
  font-weight: 500;
  font-size: 15px;
  font-family: var(--title-font);
  line-height: 26px;
  text-transform: capitalize;
  padding: 15px 27px 14px;
  border-radius: 12px;
  background: var(--color-secondary, var(--theme-color-dark));
  display: inline-block;
  color: var(--theme-color-light);
}

.boxcar-pricing-section-six .content-column .inner-column .read-more svg {
  position: relative;
  top: -4px;
  color: var(--theme-color-light);
  width: 14px;
  height: 14px;
  margin-left: 10px;
}

/*** 

====================================================================
    pricing section seven
====================================================================

***/
.boxcar-pricing-section-seven {
  position: relative;
  padding: 120px 0;
  background-color: var(--theme-color-light);
  position: relative;
  border-radius: 80px;
}

.pricing-block-seven .inner-box {
  border-radius: 16px;
  border: 1px solid #E1E1E1;
  background: var(--theme-color-light);
  padding: 28px 29px 29px;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.pricing-block-seven .inner-box:hover {
  background-color: var(--theme-color1);
}

.pricing-block-seven .inner-box:hover .title {
  color: var(--theme-color-light);
}

.pricing-block-seven .inner-box:hover .plan {
  color: var(--theme-color-light);
}

.pricing-block-seven .inner-box:hover .text {
  color: var(--theme-color-light);
}

.pricing-block-seven .inner-box:hover .pricing-list li {
  color: var(--theme-color-light);
}

.pricing-block-seven .inner-box:hover .pricing-list li i {
  background-color: var(--theme-color-light);
}

.pricing-block-seven .inner-box .title {
  font-size: 38px;
  font-weight: 700;
  margin-bottom: 0;
}

.pricing-block-seven .inner-box .title span {
  font-size: 15px;
  font-weight: 400;
}

.pricing-block-seven .inner-box .plan {
  font-size: 20px;
  font-weight: 500;
}

.pricing-block-seven .inner-box .text {
  font-size: 14px;
  line-height: 28px;
  margin-top: 4px;
  margin-bottom: 29px;
}

.pricing-block-seven .inner-box .pricing-list {
  margin-bottom: 28px;
}

.pricing-block-seven .inner-box .pricing-list li {
  position: relative;
  font-size: 15px;
  color: var(--theme-color-dark);
  padding-left: 35px;
  margin-bottom: 12px;
}

.pricing-block-seven .inner-box .pricing-list li:last-child {
  margin-bottom: 0;
}

.pricing-block-seven .inner-box .pricing-list li i {
  position: absolute;
  top: 1px;
  left: 0;
  font-size: 7px;
  width: 25px;
  height: 25px;
  line-height: 25px;
  text-align: center;
  border-radius: 50%;
  background-color: #e9f2ff;
  color: var(--theme-color1);
}

.pricing-block-seven .inner-box .pricing-btn {
  font-size: 15px;
  font-weight: 500;
  color: var(--theme-color1);
  border-radius: 8px;
  border: 1px solid #405FF2;
  background: var(--theme-color-light);
  width: 100%;
  height: 54px;
  text-align: center;
  line-height: 54px;
  display: inline-block;
}

.pricing-block-seven .inner-box .pricing-btn svg {
  position: relative;
  top: -2px;
  margin-left: 9px;
}

/*** 

====================================================================
    shop section
====================================================================

***/
.cars-section {
  position: relative;
  padding: 120px 0;
}

.cars-section .boxcar-title .btn-title {
  position: absolute;
  top: 21px;
  right: 0;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px;
  font-family: var(--title-font);
  color: var(--color-primary, var(--theme-color-dark));
}

.cars-section .nav-tabs {
  margin-bottom: 34px;
  /* Small devices */
}

@media (max-width: 767px) {
  .cars-section .nav-tabs li {
    width: 100%;
    margin-bottom: 15px;
  }

  .cars-section .nav-tabs li button {
    margin-right: 0;
    text-align: left;
  }
}

.cars-section .nav-tabs .nav-link {
  font-size: 16px;
  font-weight: 500;
  color: var(--theme-color-dark);
  border-top: 0;
  border-left: 0;
  border-right: 0;
  padding: 0 0 7px 0;
  margin-right: 32px;
  position: relative;
}

.cars-section .nav-tabs .nav-link:hover {
  border-color: transparent;
}

.cars-section .nav-tabs .nav-link.active::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: var(--theme-color1);
}

.shop-cars {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  /* Small devices */
}

@media (max-width: 767px) {
  .shop-cars {
    display: block;
  }
}

.shop-cars .cars-list {
  margin-right: 114px;
  /* Large devices */
  /* Medium devices */
}

@media (max-width: 1199px) {
  .shop-cars .cars-list {
    margin-right: 40px;
  }
}

@media (max-width: 991px) {
  .shop-cars .cars-list {
    margin-right: 20px;
  }
}

.shop-cars .cars-list:last-child {
  margin-right: 0;
}

.shop-cars .cars-list li a {
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 45px;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.shop-cars .cars-list li a:hover {
  color: var(--theme-color1);
  text-decoration: underline;
}

/*** 

====================================================================
    shop section two
====================================================================

***/
.cars-section-two {
  position: relative;
  padding: 120px 0 160px;
  background: var(--color-primary, var(--theme-color-dark));
}

.cars-section-two .boxcar-title .btn-title {
  position: absolute;
  top: 21px;
  right: 0;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px;
  font-family: var(--title-font);
  color: var(--color-primary, var(--theme-color-light));
}

.cars-section-two .nav-tabs {
  margin-bottom: 50px;
  border-bottom: 1px solid #363c4c;
}

.cars-section-two .nav-tabs .nav-link {
  font-size: 16px;
  font-weight: 500;
  color: var(--theme-color-light);
  border-top: 0;
  border-left: 0;
  border-right: 0;
  padding: 0 0 7px 0;
  margin-right: 32px;
  background-color: unset;
  position: relative;
}

.cars-section-two .nav-tabs .nav-link:hover {
  border-color: transparent;
}

.cars-section-two .nav-tabs .nav-link.active::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: var(--theme-color1);
}

.car-block-two {
  margin: 0 15px;
}

.car-block-two .inner-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  border-radius: 15px;
  overflow: hidden;
}

.car-block-two .inner-box .image-box {
  position: relative;
  /* display: inline-block; */
}

.car-block-two .inner-box .image-box .image {
  height: 100%;
  border-radius: 0;

  /* overflow: hidden; */
}

.car-block-two .inner-box .image-box .image img {
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

@media (max-width: 767px) {
  .car-block-two .inner-box .image-box .image img {
    width: 100%;
  }
}

.car-block-two .inner-box .image-box span {
  position: absolute;
  top: 20px;
  left: 20px;
  font-size: 14px;
  background-color: var(--theme-color1);
  font-weight: 500;
  color: var(--theme-color-light);
  padding: 6px 16px 6px;
  border-radius: 50px;
}

.car-block-two .inner-box .image-box .icon-box {
  position: absolute;
  top: 20px;
  right: 20px;
  width: 36px;
  height: 36px;
  background-color: var(--theme-color-light);
  border-radius: 50%;
  line-height: 35px;
  text-align: center;
}

.car-block-two .inner-box .content-box {
  position: relative;
  background-color: #161d30;
  padding: 25px 30px 22px;
  flex-grow: 1;
}

.car-block-two .inner-box .content-box .title {
  font-size: 18px;
  font-weight: 500;
  color: var(--theme-color-light);
  line-height: 24px;
  margin-bottom: 0;
}

.car-block-two .inner-box .content-box .text {
  color: var(--theme-color-light);
  font-size: 14px;
  margin-bottom: 37px;
}

.car-block-two .inner-box .content-box ul {
  margin-bottom: 34px;
}

.car-block-two .inner-box .content-box ul li {
  position: relative;
  color: var(--theme-color-light);
  font-size: 14px;
  /* padding-left: 30px; */
  line-height: 17px;
  margin-bottom: 21px;
  display: flex;
  align-items: center;
  gap: 10px
}

.car-block-two .inner-box .content-box ul li i {
  font-size: 20px;
}

.car-block-two .inner-box .content-box ul li:last-child {
  margin-bottom: 0;
}

.car-block-two .inner-box .content-box ul li img {
  position: absolute;
  top: 0;
  left: 0;
}

.car-block-two .inner-box .content-box .btn-box span {
  color: var(--theme-color-light);
  font-size: 14px;
  display: block;
  margin-bottom: -2px;
}

.car-block-two .inner-box .content-box .btn-box small {
  font-size: 20px;
  font-weight: 700;
  color: var(--theme-color-light);
  line-height: 30px;
}

.car-block-two .inner-box .content-box .btn-box .details {
  position: absolute;
  bottom: 23px;
  right: 20px;
  font-size: 15px;
  font-weight: 500;
  color: var(--theme-color-light);
  font-family: var(--title-font);
}

.car-block-two .inner-box .content-box .btn-box .details svg {
  position: relative;
  top: -2px;
  margin-left: 10px;
}

/*** 

====================================================================
    shop section three
====================================================================

***/
.cars-section-three {
  position: relative;
  padding: 120px 0 160px;
}

.cars-section-three .boxcar-title {
  margin-bottom: 32px;
}

.cars-section-three .boxcar-title .btn-title {
  position: absolute;
  top: 21px;
  right: 0;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px;
  font-family: var(--title-font);
  color: var(--color-primary, var(--theme-color-dark));
}

.cars-section-three .nav-tabs {
  margin-bottom: 34px;
  flex-wrap: nowrap;
}

.cars-section-three .nav-tabs .nav-link {
  font-size: 16px;
  font-weight: 500;
  color: var(--theme-color-dark);
  border-top: 0;
  border-left: 0;
  border-right: 0;
  padding: 0 0 7px 0;
  margin-right: 32px;
  position: relative;
}

.cars-section-three .nav-tabs .nav-link:hover {
  border-color: transparent;
}

.cars-section-three .nav-tabs .nav-link.active::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: var(--theme-color1);
}

.car-block-three {
  margin: 0 14px;
}

.car-block-three .inner-box .image-box {
  position: relative;
  display: inline-block;
  width: 100%;
}

.car-block-three .inner-box .image-box .image {
  width: 100%;
  border-radius: 15px 15px 0px 0px;
}

.car-block-three .inner-box .image-box .image img {
  width: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.car-block-three .inner-box .image-box span {
  position: absolute;
  top: 20px;
  left: 20px;
  font-size: 14px;
  background-color: var(--theme-color1);
  font-weight: 500;
  color: var(--theme-color-light);
  padding: 6px 16px 6px;
  border-radius: 50px;
}

.car-block-three .inner-box .image-box .icon-box {
  position: absolute;
  top: 20px;
  right: 20px;
  width: 36px;
  height: 36px;
  background-color: var(--theme-color-light);
  border-radius: 50%;
  line-height: 35px;
  text-align: center;
}

.car-block-three .inner-box .image-box.two span {
  background-color: #3D923A;
}

.car-block-three .inner-box .content-box {
  position: relative;
  border-radius: 0 0 16px 16px;
  border: 1px solid var(--Border, #E1E1E1);
  border-top: 0;
  padding: 15px 27px 11px;
  margin-top: -9px;
}

.box-car .inner-box:hover {
  box-shadow: 0px 6px 24px 0px rgba(0, 0, 0, 0.05);

}

.box-car.car-block-nine .inner-box:hover {
  box-shadow: unset;

}

.box-car .content-box .title a {
  position: relative;
}

.box-car .content-box .title a::before {
  content: "";
  width: 0;
  height: 1px;
  bottom: 0px;
  position: absolute;
  left: auto;
  right: 0;
  z-index: 1;
  -webkit-transition: width 0.6s cubic-bezier(0.25, 0.8, 0.25, 1) 0s;
  -o-transition: width 0.6s cubic-bezier(0.25, 0.8, 0.25, 1) 0s;
  transition: width 0.6s cubic-bezier(0.25, 0.8, 0.25, 1) 0s;
  background: var(--theme-color-dark);

}

.box-car .inner-box:hover .content-box .title a::before {
  width: 100%;
  left: 0;
  right: auto;
}

.box-car .content-box .title a:hover:before {
  background: var(--theme-color1);
}

.car-block-three .inner-box .content-box .title {
  font-size: 18px;
  font-weight: 500;
  color: var(--theme-color-dark);
  line-height: 24px;
  margin-bottom: 0;
}

.car-block-three .inner-box .content-box .text {
  color: var(--theme-color-dark);
  font-size: 14px;
  margin-bottom: 20px;
  padding-bottom: 13px;
  border-bottom: 1px solid #e1e1e1;
  white-space: nowrap;
}

.car-block-three .inner-box .content-box ul {
  margin-bottom: 11px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  border-bottom: 1px solid #e1e1e1;
}

.car-block-three .inner-box .content-box ul li {
  position: relative;
  color: var(--theme-color-dark);
  font-size: 14px;
  line-height: 24px;
  margin-bottom: 16px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  margin-right: 32px;
}

.car-block-three .inner-box .content-box ul li:last-child {
  margin-right: 0;
}

.car-block-three .inner-box .content-box ul li img {
  width: 18px;
  margin-bottom: 7px;
}

.car-block-three .inner-box .content-box .btn-box span {
  color: var(--theme-color-dark);
  font-size: 14px;
  display: block;
  margin-bottom: -2px;
}

.car-block-three .inner-box .content-box .btn-box small {
  font-size: 20px;
  font-weight: 700;
  color: var(--theme-color-dark);
  line-height: 30px;
}

.car-block-three .inner-box .content-box .btn-box .details {
  position: absolute;
  bottom: 11px;
  right: 30px;
  font-size: 15px;
  font-weight: 500;
  color: var(--theme-color1);
  font-family: var(--title-font);
}

.car-block-three .inner-box .content-box .btn-box .details svg {
  position: relative;
  top: -2px;
  margin-left: 10px;
}

/*** 

====================================================================
    shop section four
====================================================================

***/
.cars-section-four {
  position: relative;
  padding: 120px 0 90px;
}

.cars-section-four .inner-container {
  max-width: 1829px;
  margin: 0 auto;
  padding: 0 15px;
}

.cars-section-four .boxcar-title {
  margin-bottom: 32px;
}

.cars-section-four .boxcar-title .breadcrumb {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 12px;
}

.cars-section-four .boxcar-title .breadcrumb li {
  font-size: 15px;
  margin-right: 21px;
}

.cars-section-four .boxcar-title .breadcrumb li:last-child {
  margin-right: 0;
}

.cars-section-four .boxcar-title .breadcrumb li a {
  color: var(--theme-color1);
}

.cars-section-four .boxcar-title h2 {
  margin-bottom: 41px;
}

.cars-section-four .boxcar-title .btn-title {
  position: absolute;
  top: 21px;
  right: 0;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px;
  font-family: var(--title-font);
  color: var(--color-primary, var(--theme-color-dark));
}

.cars-section-four .lower-sec .text {
  font-size: 15px;
}

.cars-section-four .nav-tabs {
  margin-bottom: 34px;
  flex-wrap: nowrap;
}

.cars-section-four .nav-tabs .nav-link {
  font-size: 16px;
  font-weight: 500;
  color: var(--theme-color-dark);
  border-top: 0;
  border-left: 0;
  border-right: 0;
  padding: 0 0 7px 0;
  margin-right: 32px;
  position: relative;
  background-color: transparent;
}

.cars-section-four .nav-tabs .nav-link:hover {
  border-color: transparent;
}

.cars-section-four .nav-tabs .nav-link.active::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: var(--theme-color1);
}

.cars-section-four .pagination-sec {
  margin-top: 30px;
  text-align: center;
}

.cars-section-four .pagination-sec .pagination {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.cars-section-four .pagination-sec .pagination li:first-child a,
.cars-section-four .pagination-sec .pagination li:last-child a {
  border: 1px solid #e1e1e1;
  width: 60px;
  font-size: 30px;
  line-height: 35px;
}

.cars-section-four .pagination-sec .pagination li a {
  font-size: 15px;
  margin: 0 5px;
  width: 40px;
  height: 40px;
  border-radius: 50px;
  text-align: center;
  font-weight: 500;
  padding: 0;
  line-height: 40px;
  border: 0;
  color: var(--theme-color-dark);
}

.cars-section-four .pagination-sec .pagination li a:hover {
  background-color: #f9fbfc;
}

.cars-section-four .pagination-sec .text {
  font-size: 14px;
  margin-top: 19px;
}

.cars-section-four .form-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-bottom: 57px;
}

.cars-section-four .form-box .form_boxes .drop-menu {
  border: 1px solid #e1e1e1;
  border-radius: 120px;
  height: unset;
  margin-right: 10px;
}

.cars-section-four .form-box .form_boxes .drop-menu:active {
  border-color: var(--theme-color-dark);
}

.cars-section-four .form-box .form_boxes .drop-menu .dropdown {
  background-color: var(--theme-color-light);
  -webkit-box-shadow: 30px 30px 60px 0px rgba(0, 0, 1, 0.05);
  box-shadow: 30px 30px 60px 0px rgba(0, 0, 1, 0.05);
}

.cars-section-four .form-box .form_boxes .drop-menu .select {
  padding: 0 35px 0 25px;
  height: 48px;
  line-height: 48px;
}

.cars-section-four .form-box .form_boxes .drop-menu .select span {
  font-size: 15px;
}

.cars-section-four .form-box .form_boxes .drop-menu .select span i {
  right: -11px;
}

.car-block-four {
  margin-bottom: 30px;
}

.car-block-four .inner-box .image-box {
  position: relative;
  /* display: inline-block; */
  width: 100%;
}

.car-block-four .inner-box .image-box .image {
  border-radius: 15px 15px 0px 0px;
  width: 100%;
}

.car-block-four .inner-box .image-box .image img {
  width: 100%;
}

.car-block-four .inner-box .image-box span {
  position: absolute;
  top: 20px;
  left: 20px;
  font-size: 14px;
  background-color: var(--theme-color1);
  font-weight: 500;
  color: var(--theme-color-light);
  padding: 6px 16px 6px;
  border-radius: 50px;
}

.car-block-four .inner-box .image-box .icon-box {
  position: absolute;
  top: 20px;
  right: 20px;
  width: 36px;
  height: 36px;
  background-color: var(--theme-color-light);
  border-radius: 50%;
  line-height: 35px;
  text-align: center;
}

.car-block-four .inner-box .image-box.two span {
  background-color: #3D923A;
}

.car-block-four .inner-box .content-box {
  position: relative;
  border-radius: 0 0 16px 16px;
  border: 1px solid var(--Border, #E1E1E1);
  border-top: 0;
  padding: 15px 27px 11px;
}

.car-block-four.style-bg .inner-box .content-box {
  background-color: #fff;
}

.car-block-four .inner-box .content-box .title {
  font-size: 18px;
  font-weight: 500;
  color: var(--theme-color-dark);
  line-height: 24px;
  margin-bottom: 0;
}

.car-block-four .inner-box .content-box .text {
  color: var(--theme-color-dark);
  font-size: 14px;
  margin-bottom: 20px;
  padding-bottom: 13px;
  border-bottom: 1px solid #e1e1e1;
}

.car-block-four .inner-box .content-box ul {
  margin-bottom: 11px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  border-bottom: 1px solid #e1e1e1;
}

.car-block-four .inner-box .content-box ul li {
  position: relative;
  color: var(--theme-color-dark);
  font-size: 14px;
  line-height: 17px;
  margin-bottom: 16px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  margin-right: 42px;
}

.car-block-four .inner-box .content-box ul li:last-child {
  margin-right: 0;
}

.car-block-four .inner-box .content-box ul li img {
  width: 18px;
  margin-bottom: 7px;
}

.car-block-four .inner-box .content-box .btn-box span {
  color: var(--theme-color-dark);
  font-size: 14px;
  display: block;
  margin-bottom: -2px;
}

.car-block-four .inner-box .content-box .btn-box small {
  font-size: 20px;
  font-weight: 700;
  color: var(--theme-color-dark);
  line-height: 30px;
}

.car-block-four .inner-box .content-box .btn-box .details {
  position: absolute;
  bottom: 11px;
  right: 30px;
  font-size: 15px;
  font-weight: 500;
  color: var(--theme-color1);
  font-family: var(--title-font);
}

.car-block-four .inner-box .content-box .btn-box .details svg {
  position: relative;
  top: -2px;
  margin-left: 10px;
}

/*** 

====================================================================
    shop section five
====================================================================

***/
.cars-section-five {
  position: relative;
  padding: 120px 0 160px;
  background-color: var(--theme-color-dark);
}

.cars-section-five .boxcar-title {
  margin-bottom: 32px;
}

.cars-section-five .boxcar-title .btn-title {
  position: absolute;
  top: 21px;
  right: 0;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px;
  font-family: var(--title-font);
  color: var(--color-primary, var(--theme-color-dark));
}

.cars-section-five .slick-prev,
.cars-section-five .slick-next {
  border-color: var(--theme-color-light);
}

.cars-section-five .slick-prev::before,
.cars-section-five .slick-next::before {
  color: var(--theme-color-light);
}

.cars-section-five .nav-tabs {
  margin-bottom: 34px;
  border-bottom: 1px solid #2d3345;
}

.cars-section-five .nav-tabs .nav-link {
  font-size: 16px;
  font-weight: 500;
  color: var(--theme-color-light);
  border-top: 0;
  background-color: unset;
  border-left: 0;
  border-right: 0;
  padding: 0 0 7px 0;
  margin-right: 32px;
  position: relative;
}

.cars-section-five .nav-tabs .nav-link:hover {
  border-color: transparent;
}

.cars-section-five .nav-tabs .nav-link.active::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: var(--theme-color1);
}

.car-block-five {
  margin: 0 15px;
}

.car-block-five .inner-box .image-box {
  position: relative;
  display: inline-block;
}

.car-block-five .inner-box .image-box .image img {
  border-radius: 15px 15px 0 0;
}

.car-block-five .inner-box .image-box span {
  position: absolute;
  top: 20px;
  left: 20px;
  font-size: 14px;
  background-color: var(--theme-color1);
  font-weight: 500;
  color: var(--theme-color-light);
  padding: 6px 16px 6px;
  border-radius: 50px;
}

.car-block-five .inner-box .image-box .icon-box {
  position: absolute;
  top: 20px;
  right: 20px;
  width: 36px;
  height: 36px;
  background-color: var(--theme-color-light);
  border-radius: 50%;
  line-height: 35px;
  text-align: center;
}

.car-block-five .inner-box .image-box.two span {
  background-color: #3D923A;
}

.car-block-five .inner-box .content-box {
  position: relative;
  border-radius: 0 0 16px 16px;
  background-color: #161c30;
  border-top: 0;
  padding: 15px 27px 11px;
  margin-top: -9px;
}

.car-block-five .inner-box .content-box .title {
  font-size: 18px;
  font-weight: 500;
  color: var(--theme-color-light);
  line-height: 24px;
  margin-bottom: 0;
}

.car-block-five .inner-box .content-box .text {
  color: var(--theme-color-light);
  font-size: 14px;
  margin-bottom: 20px;
  padding-bottom: 13px;
  border-bottom: 1px solid #2d3345;
}

.car-block-five .inner-box .content-box ul {
  margin-bottom: 11px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  border-bottom: 1px solid #2d3345;
}

.car-block-five .inner-box .content-box ul li {
  position: relative;
  color: var(--theme-color-light);
  font-size: 14px;
  line-height: 17px;
  margin-bottom: 16px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  margin-right: 42px;
}

.car-block-five .inner-box .content-box ul li:last-child {
  margin-right: 0;
}

.car-block-five .inner-box .content-box ul li i {
  width: 18px;
  margin-bottom: 7px;
}

.car-block-five .inner-box .content-box .btn-box span {
  color: var(--theme-color-light);
  font-size: 14px;
  display: block;
  margin-bottom: -2px;
}

.car-block-five .inner-box .content-box .btn-box small {
  font-size: 20px;
  font-weight: 700;
  color: var(--theme-color-light);
  line-height: 30px;
}

.car-block-five .inner-box .content-box .btn-box .details {
  position: absolute;
  bottom: 11px;
  right: 30px;
  font-size: 15px;
  font-weight: 500;
  color: var(--theme-color-light);
  font-family: var(--title-font);
}

.car-block-five .inner-box .content-box .btn-box .details svg {
  position: relative;
  top: -2px;
  margin-left: 10px;
  fill: var(--theme-color-light);
}

/*** 

====================================================================
    shop section six
====================================================================

***/
.cars-section-six {
  position: relative;
  padding: 120px 0 160px;
}

.cars-section-six .slick-list {
  /* overflow: unset; */
}

.cars-section-six .boxcar-title .btn-title {
  position: absolute;
  top: 21px;
  right: 0;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px;
  font-family: var(--title-font);
  color: var(--color-primary, var(--theme-color-dark));
}

.cars-section-six .nav-tabs {
  margin-bottom: 50px;
  border-bottom: 1px solid #e1e1e1;
}

.cars-section-six .nav-tabs .nav-link {
  font-size: 16px;
  font-weight: 500;
  color: var(--theme-color-dark);
  border-top: 0;
  border-left: 0;
  border-right: 0;
  padding: 0 0 7px 0;
  margin-right: 32px;
  background-color: unset;
  position: relative;
}

.cars-section-six .nav-tabs .nav-link:hover {
  border-color: transparent;
}

.cars-section-six .nav-tabs .nav-link.active::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: var(--theme-color1);
}

.car-block-six {
  margin: 0 15px;
}

.car-block-six .inner-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  border-radius: 15px;
  overflow: hidden;
}

.car-block-six .inner-box .image-box {
  position: relative;
  width: 50%;
}

.car-block-six .inner-box .image-box .image {
  height: 100%;
}

.car-block-six .inner-box .image-box .image img {
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  width: 100%;
}

.car-block-six .inner-box .image-box .icon-box {
  position: absolute;
  top: 20px;
  left: 20px;
  width: 36px;
  height: 36px;
  background-color: var(--theme-color-light);
  border-radius: 50%;
  line-height: 35px;
  text-align: center;
}

.car-block-six .inner-box .content-box {
  width: 50%;
  position: relative;
  background-color: #161d30;
  padding: 35px 31px 35px;
}

.car-block-six .inner-box .content-box .title {
  font-size: 18px;
  font-weight: 500;
  color: var(--theme-color-light);
  line-height: 24px;
  margin-bottom: 0;
}

.car-block-six .inner-box .content-box .text {
  color: var(--theme-color-light);
  font-size: 14px;
  margin-bottom: 24px;
}

.car-block-six .inner-box .content-box ul {
  margin-bottom: 21px;
}

.car-block-six .inner-box .content-box ul li {
  position: relative;
  color: var(--theme-color-light);
  font-size: 14px;
  line-height: 24px;
  margin-bottom: 21px;
  display: flex;
  align-items: center;
  gap: 10px;
}

.car-block-six .inner-box .content-box ul li i {
  font-size: 18px;
}

.car-block-six .inner-box .content-box ul li:last-child {
  margin-bottom: 0;
}

.car-block-six .inner-box .content-box ul li img {
  position: absolute;
  top: 0;
  left: 0;
}

.car-block-six .inner-box .content-box .btn-box span {
  color: var(--theme-color-light);
  font-size: 14px;
  display: block;
  margin-bottom: -2px;
}

.car-block-six .inner-box .content-box .btn-box small {
  font-size: 20px;
  font-weight: 700;
  color: var(--theme-color-light);
  line-height: 30px;
}

.car-block-six .inner-box .content-box .btn-box .details {
  position: absolute;
  bottom: 36px;
  right: 20px;
  font-size: 15px;
  font-weight: 500;
  color: var(--theme-color-light);
  font-family: var(--title-font);
}

.car-block-six .inner-box .content-box .btn-box .details svg {
  position: relative;
  top: -2px;
  margin-left: 10px;
}

.car-block-six .inner-box .content-box.v2 {
  background-color: #735043;
}

.car-block-six .inner-box .content-box.v3 {
  background: var(--color-secondary, var(--theme-color1));
}

.car-block-six .inner-box .content-box.v4 {
  background-color: #373948;
}

.car-slider,
.cars-section-five .cars-section-seven .car-slider-three {
  padding-bottom: 30px;
}

.car-slider .slick-prev,
.car-slider .slick-next,
.cars-section-five .cars-section-seven .car-slider-three .slick-prev,
.cars-section-five .cars-section-seven .car-slider-three .slick-next {
  top: auto;
  bottom: -50px;
  -webkit-transform: unset;
  transform: unset;
  border-color: #fff;
}

.cus-slider .slick-prev,
.cus-slider .slick-next {
  top: auto;
  bottom: -50px;
  -webkit-transform: unset;
  transform: unset;
}

.car-slider .slick-prev:before,
.car-slider .slick-next:before,
.cars-section-five .cars-section-seven .car-slider-three .slick-prev:before,
.cars-section-five .cars-section-seven .car-slider-three .slick-next:before {
  color: #fff;
}

.car-slider .slick-prev:hover,
.car-slider .slick-next:hover,
.cars-section-five .cars-section-seven .car-slider-three .slick-prev:hover,
.cars-section-five .cars-section-seven .car-slider-three .slick-next:hover {
  background-color: #fff;
}




.car-slider .slick-prev:hover:before,
.car-slider .slick-next:hover:before,
.cars-section-five .cars-section-seven .car-slider-three .slick-prev:hover:before,
.cars-section-five .cars-section-seven .car-slider-three .slick-next:hover:before {
  color: #000;
}

.car-slider .slick-prev,
.cars-section-five .cars-section-seven .car-slider-three .slick-prev,
.cus-slider .slick-prev {
  left: 15px;
}

.car-slider .slick-next,
.cars-section-five .cars-section-seven .car-slider-three .slick-next,
.cus-slider .slick-next {
  right: auto;
  left: 95px;
}

.car-slider-three {
  padding-bottom: 30px;
}

.slider-layout-1 {
  width: calc(100vw - ((100vw - 1400px) / 2));
  margin-right: unset !important;
  max-width: 100%;
  margin-left: auto;
}

.car-slider-three .slick-prev,
.car-slider-three .slick-next {
  top: auto;
  bottom: -50px;
  -webkit-transform: unset;
  transform: unset;
}

.car-slider-three .slick-prev {
  left: 15px;
}

.car-slider-three .slick-next {
  right: auto;
  left: 95px;
}



.car-slider.style-1 .slick-prev,
.car-slider.style-1 .slick-next {
  border-color: #050B20;
  color: #050B20;
}

.car-slider.style-1 .slick-prev::before,
.car-slider.style-1 .slick-next::before {
  color: #050B20;
}

.car-slider.style-1 .slick-prev:hover,
.car-slider.style-1 .slick-next:hover {
  background-color: #050B20;
}

.car-slider.style-1 .slick-prev:hover::before,
.car-slider.style-1 .slick-next:hover::before {
  color: #fff;
  opacity: 1;
}


.car-slider .slick-list,
.car-slider-three .slick-list {
  padding-bottom: 30px;
  margin-bottom: -30px;

}

/*** 

====================================================================
    shop section seven
====================================================================

***/
.cars-section-seven {
  position: relative;
  padding: 120px 0 160px;
}

.cars-section-seven .car-slider-three .slick-next,
.cars-section-seven .car-slider-three .slick-prev {
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  margin-left: -35px;
}

.cars-section-seven .car-slider-three .slick-next {
  margin-left: 40px;
}

.cars-section-seven .boxcar-title .btn-title {
  position: absolute;
  top: 21px;
  right: 0;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px;
  font-family: var(--title-font);
  color: var(--color-primary, var(--theme-color-dark));
}

.cars-section-seven .nav-tabs {
  margin-bottom: 34px;
  border-bottom: 1px solid #2d3345;
}

.cars-section-seven .nav-tabs .nav-link {
  font-size: 16px;
  font-weight: 500;
  color: var(--theme-color-light);
  border-top: 0;
  background-color: unset;
  border-left: 0;
  border-right: 0;
  padding: 0 0 7px 0;
  margin-right: 32px;
  position: relative;
}

.cars-section-seven .nav-tabs .nav-link:hover {
  border-color: transparent;
}

.cars-section-seven .nav-tabs .nav-link.active::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: var(--theme-color1);
}

.car-block-seven {
  margin: 0 15px;
}

.car-block-seven .inner-box .image-box {
  position: relative;
  display: inline-block;
}

.car-block-seven .inner-box .image-box .image img {
  border-radius: 15px 15px 0 0;
}

.car-block-seven .inner-box .image-box span {
  position: absolute;
  top: 20px;
  left: 20px;
  font-size: 14px;
  background-color: var(--theme-color1);
  font-weight: 500;
  color: var(--theme-color-light);
  padding: 6px 16px 6px;
  border-radius: 50px;
}

.car-block-seven .inner-box .image-box .icon-box {
  position: absolute;
  top: 20px;
  right: 20px;
  width: 36px;
  height: 36px;
  background-color: var(--theme-color-light);
  border-radius: 50%;
  line-height: 35px;
  text-align: center;
}

.car-block-seven .inner-box .image-box.two span {
  background-color: #3D923A;
}

.car-block-seven .inner-box .content-box {
  position: relative;
  border-radius: 0 0 16px 16px;
  background-color: #161c30;
  border-top: 0;
  padding: 15px 27px 11px;
  margin-top: -9px;
}

.car-block-seven .inner-box .content-box .title {
  font-size: 18px;
  font-weight: 500;
  color: var(--theme-color-light);
  line-height: 24px;
  margin-bottom: 0;
}

.car-block-seven .inner-box .content-box .text {
  color: var(--theme-color-light);
  font-size: 14px;
  margin-bottom: 20px;
  padding-bottom: 13px;
  border-bottom: 1px solid #2d3345;
}

.car-block-seven .inner-box .content-box ul {
  margin-bottom: 11px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  border-bottom: 1px solid #2d3345;
}

.car-block-seven .inner-box .content-box ul li {
  position: relative;
  color: var(--theme-color-light);
  font-size: 14px;
  line-height: 17px;
  margin-bottom: 16px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  margin-right: 42px;
}

.car-block-seven .inner-box .content-box ul li:last-child {
  margin-right: 0;
}

.car-block-seven .inner-box .content-box ul li img {
  width: 18px;
  margin-bottom: 7px;
}

.car-block-seven .inner-box .content-box .btn-box span {
  color: var(--theme-color-light);
  font-size: 14px;
  display: block;
  margin-bottom: -2px;
}

.car-block-seven .inner-box .content-box .btn-box small {
  font-size: 20px;
  font-weight: 700;
  color: var(--theme-color-light);
  line-height: 30px;
}

.car-block-seven .inner-box .content-box .btn-box .details {
  position: absolute;
  bottom: 11px;
  right: 30px;
  font-size: 15px;
  font-weight: 500;
  color: var(--theme-color-light);
  font-family: var(--title-font);
}

.car-block-seven .inner-box .content-box .btn-box .details svg {
  position: relative;
  top: -2px;
  margin-left: 10px;
  fill: var(--theme-color-light);
}

/*** 

====================================================================
    shop section eight
====================================================================

***/
.cars-section-eight {
  position: relative;
  padding: 120px 0 160px;
}

.cars-section-eight .car-slider-three .slick-next,
.cars-section-eight .car-slider-three .slick-prev {
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  margin-left: -35px;
}

.cars-section-eight .car-slider-three .slick-next {
  margin-left: 40px;
}

.cars-section-eight .boxcar-title .btn-title {
  position: absolute;
  top: 21px;
  right: 0;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px;
  font-family: var(--title-font);
  color: var(--color-primary, var(--theme-color-dark));
}

.cars-section-eight .nav-tabs {
  margin-bottom: 50px;
  border-bottom: 1px solid #e1e1e1;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.cars-section-eight .nav-tabs .nav-link {
  font-size: 16px;
  font-weight: 500;
  color: var(--theme-color-dark);
  border-top: 0;
  background-color: unset;
  border-left: 0;
  border-right: 0;
  padding: 0 0 7px 0;
  margin-right: 32px;
  position: relative;
}

.cars-section-eight .nav-tabs .nav-link:hover {
  border-color: transparent;
}

.cars-section-eight .nav-tabs .nav-link.active::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: var(--theme-color1);
}

/*** 

====================================================================
    shop section nine
====================================================================

***/
.cars-section-nine {
  position: relative;
  padding: 120px 0 160px;
}

.cars-section-nine .boxcar-title {
  margin-bottom: 32px;
}

.cars-section-nine .boxcar-title .btn-title {
  position: absolute;
  top: 21px;
  right: 0;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px;
  font-family: var(--title-font);
  color: var(--color-primary, var(--theme-color-dark));
}

.cars-section-nine .nav-tabs {
  margin-bottom: 34px;
}

.cars-section-nine .nav-tabs .nav-link {
  font-size: 16px;
  font-weight: 500;
  color: var(--theme-color-dark);
  border-top: 0;
  border-left: 0;
  border-right: 0;
  padding: 0 0 7px 0;
  margin-right: 32px;
  position: relative;
}

.cars-section-nine .nav-tabs .nav-link:hover {
  border-color: transparent;
}

.cars-section-nine .nav-tabs .nav-link.active::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: var(--theme-color1);
}

.car-block-nine {
  margin: 0 15px;
}

.car-block-nine .inner-box .image-box {
  position: relative;
  display: inline-block;
  width: 100%;
}

.car-block-nine .inner-box .image-box .image {
  border-radius: 15px;
  overflow: hidden;
}

.car-block-nine .inner-box .image-box .image img {
  border-radius: 16px;
}

.car-block-nine .inner-box .image-box span {
  position: absolute;
  top: 20px;
  left: 20px;
  font-size: 14px;
  background-color: var(--theme-color1);
  font-weight: 500;
  color: var(--theme-color-light);
  padding: 6px 16px 6px;
  border-radius: 50px;
}

.car-block-nine .inner-box .image-box .icon-box {
  position: absolute;
  top: 20px;
  right: 20px;
  width: 36px;
  height: 36px;
  background-color: var(--theme-color-light);
  border-radius: 50%;
  line-height: 35px;
  text-align: center;
}

.car-block-nine .inner-box .image-box.two span {
  background-color: #3D923A;
}

.car-block-nine .inner-box .content-box {
  position: relative;
  border-top: 0;
  padding: 15px 0 0;
  margin-top: -9px;
}

.car-block-nine .inner-box .content-box .title {
  font-size: 18px;
  font-weight: 500;
  color: var(--theme-color-dark);
  line-height: 24px;
  margin-bottom: 0;
}

.car-block-nine .inner-box .content-box .text {
  color: var(--theme-color-dark);
  font-size: 14px;
  margin-bottom: 0;
  padding-bottom: 9px;
}

.car-block-nine .inner-box .content-box ul {
  margin-bottom: 11px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  border-bottom: 1px solid #e1e1e1;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding-bottom: 5px;
}

.car-block-nine .inner-box .content-box ul li {
  position: relative;
  color: var(--theme-color-dark);
  font-size: 14px;
  line-height: 17px;
  margin-bottom: 16px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  width: 50%;
  margin-right: 0;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 8px;
}

.car-block-nine .inner-box .content-box ul li i {
  font-size: 16px;
}

.car-block-nine .inner-box .content-box ul li:last-child {
  margin-right: 0;
}

.car-block-nine .inner-box .content-box ul li img {
  width: 18px;
  margin-right: 10px;
  position: relative;
  top: 5px;
}

.car-block-nine .inner-box .content-box .btn-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: end;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.car-block-nine .inner-box .content-box .btn-box small {
  font-size: 20px;
  font-weight: 700;
  color: var(--theme-color-dark);
  line-height: 30px;
}

.car-block-nine .inner-box .content-box .btn-box .details {
  font-size: 15px;
  font-weight: 500;
  color: var(--theme-color1);
  font-family: var(--title-font);
}

.car-block-nine .inner-box .content-box .btn-box .details svg {
  position: relative;
  top: -2px;
  margin-left: 10px;
}

/*** 

====================================================================
    shop section ten
====================================================================

***/
.cars-section-ten {
  position: relative;
  padding: 120px 0 160px;
}

.cars-section-ten .right-box {
  padding: 120px 150px 160px;
  border-radius: 16px;
  background: #F9FBFC;
}

.cars-section-ten .boxcar-title {
  margin-bottom: 32px;
}

.cars-section-ten .boxcar-title .btn-title {
  position: absolute;
  top: 21px;
  right: 0;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px;
  font-family: var(--title-font);
  color: var(--color-primary, var(--theme-color-dark));
}

.cars-section-ten .nav-tabs {
  margin-bottom: 34px;
  flex-wrap: nowrap;
}

.cars-section-ten .nav-tabs .nav-link {
  font-size: 16px;
  font-weight: 500;
  color: var(--theme-color-dark);
  border-top: 0;
  border-left: 0;
  border-right: 0;
  padding: 0 0 7px 0;
  margin-right: 32px;
  position: relative;
  background: transparent;
}

.cars-section-ten .nav-tabs .nav-link:hover {
  border-color: transparent;
}

.cars-section-ten .nav-tabs .nav-link.active::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: var(--theme-color1);
}

.car-block-ten {
  margin: 0 15px;
}

.car-block-ten .inner-box {
  background-color: var(--theme-color-light);
  border-radius: 15px;
}

.car-block-ten .inner-box .image-box {
  position: relative;
  /* display: inline-block; */
}

.car-block-ten .inner-box .image-box .image img {
  border-radius: 15px 15px 0 0;
}

.car-block-ten .inner-box .image-box span {
  position: absolute;
  top: 20px;
  left: 20px;
  font-size: 14px;
  background-color: var(--theme-color1);
  font-weight: 500;
  color: var(--theme-color-light);
  padding: 1px 16px 1px 16px;
  border-radius: 50px;
}

.car-block-ten .inner-box .image-box .icon-box {
  position: absolute;
  top: 20px;
  right: 20px;
  width: 36px;
  height: 36px;
  background-color: var(--theme-color-light);
  border-radius: 50%;
  line-height: 35px;
  text-align: center;
}

.car-block-ten .inner-box .image-box.two span {
  background-color: #3D923A;
}

.car-block-ten .inner-box .content-box {
  position: relative;
  border-radius: 0 0 16px 16px;
  border: 1px solid var(--Border, #E1E1E1);
  border-top: 0;
  padding: 15px 27px 16px;
}

.car-block-ten .inner-box .content-box .title {
  font-size: 18px;
  font-weight: 500;
  color: var(--theme-color-dark);
  line-height: 24px;
  margin-bottom: 5px;
}

.car-block-ten .inner-box .content-box ul {
  margin-bottom: 2px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.car-block-ten .inner-box .content-box ul li {
  position: relative;
  color: var(--theme-color-dark);
  font-size: 14px;
  line-height: 17px;
  margin-bottom: 16px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  padding: 0 10px;
}

.car-block-ten .inner-box .content-box ul li:first-child {
  padding-left: 0;
}

.car-block-ten .inner-box .content-box ul li:first-child::before {
  display: none;
}

.car-block-ten .inner-box .content-box ul li::before {
  content: "";
  position: absolute;
  top: 6px;
  left: 0;
  width: 4px;
  height: 4px;
  border-radius: 10px;
  background-color: #ABAAAA;
}

.car-block-ten .inner-box .content-box .btn-box small {
  font-size: 20px;
  font-weight: 700;
  color: var(--theme-color-dark);
  line-height: 30px;
  display: inline-block;
  margin-bottom: 6px;
}

.car-block-ten .inner-box .content-box .btn-box .details {
  display: block;
  font-size: 15px;
  font-weight: 500;
  color: var(--theme-color1);
  font-family: var(--title-font);
}

.car-block-ten .inner-box .content-box .btn-box .details svg {
  position: relative;
  top: -2px;
  margin-left: 10px;
}

/*** 

====================================================================
    shop section eleven
====================================================================

***/
.cars-section-eleven {
  position: relative;
  padding: 120px 0 90px;
}

.cars-section-eleven .boxcar-title {
  margin-bottom: 32px;
}

.cars-section-eleven .boxcar-title .btn-title {
  position: absolute;
  top: 21px;
  right: 0;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px;
  font-family: var(--title-font);
  color: var(--color-primary, var(--theme-color-dark));
}

.cars-section-eleven .nav-tabs {
  margin-bottom: 34px;
}

.cars-section-eleven .nav-tabs .nav-link {
  font-size: 16px;
  font-weight: 500;
  color: var(--theme-color-dark);
  border-top: 0;
  border-left: 0;
  border-right: 0;
  padding: 0 0 7px 0;
  margin-right: 32px;
  position: relative;
}

.cars-section-eleven .nav-tabs .nav-link:hover {
  border-color: transparent;
}

.cars-section-eleven .nav-tabs .nav-link.active::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: var(--theme-color1);
}

.car-block-eleven {
  margin-bottom: 30px;
}

.car-block-eleven .inner-box .image-box {
  position: relative;
  /* display: inline-block; */
}

.car-block-eleven .inner-box .image-box .image img {
  border-radius: 16px 16px 0 0;
}

.car-block-eleven .inner-box .image-box span {
  position: absolute;
  top: 20px;
  left: 20px;
  font-size: 14px;
  background-color: var(--theme-color1);
  font-weight: 500;
  color: var(--theme-color-light);
  padding: 6px 16px 6px;
  border-radius: 50px;
}

.car-block-eleven .inner-box .image-box .icon-box {
  position: absolute;
  top: 20px;
  right: 20px;
  width: 36px;
  height: 36px;
  background-color: var(--theme-color-light);
  border-radius: 50%;
  line-height: 35px;
  text-align: center;
}

.car-block-eleven .inner-box .image-box.two span {
  background-color: #3D923A;
}

.car-block-eleven .inner-box .content-box {
  position: relative;
  border-radius: 0 0 16px 16px;
  border: 1px solid var(--Border, #E1E1E1);
  border-top: 0;
  padding: 15px 27px 11px;
}

.car-block-eleven .inner-box .content-box .title {
  font-size: 18px;
  font-weight: 500;
  color: var(--theme-color-dark);
  line-height: 24px;
  margin-bottom: 0;
}

.car-block-eleven .inner-box .content-box .text {
  color: var(--theme-color-dark);
  font-size: 14px;
  margin-bottom: 0;
  padding-bottom: 9px;
}

.car-block-eleven .inner-box .content-box ul {
  margin-bottom: 11px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  border-bottom: 1px solid #e1e1e1;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding-bottom: 5px;
}

.car-block-eleven .inner-box .content-box ul li {
  position: relative;
  color: var(--theme-color-dark);
  font-size: 14px;
  line-height: 17px;
  margin-bottom: 16px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  width: 50%;
  margin-right: 0;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 8px;
}

.car-block-eleven .inner-box .content-box ul li i {
  font-size: 16px;
}

.car-block-eleven .inner-box .content-box ul li:last-child {
  margin-right: 0;
}

.car-block-eleven .inner-box .content-box ul li img {
  width: 18px;
  margin-right: 10px;
  position: relative;
  top: 5px;
}

.car-block-eleven .inner-box .content-box .btn-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: end;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.car-block-eleven .inner-box .content-box .btn-box small {
  font-size: 20px;
  font-weight: 700;
  color: var(--theme-color-dark);
  line-height: 30px;
}

.car-block-eleven .inner-box .content-box .btn-box .details {
  font-size: 15px;
  font-weight: 500;
  color: var(--theme-color1);
  font-family: var(--title-font);
}

.car-block-eleven .inner-box .content-box .btn-box .details svg {
  position: relative;
  top: -2px;
  margin-left: 10px;
}

/*** 

====================================================================
    shop section twelve
====================================================================

***/
.cars-section-twelve {
  position: relative;
  padding: 120px 0 160px;
}

.cars-section-twelve .boxcar-title .btn-title {
  position: absolute;
  top: 21px;
  right: 0;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px;
  font-family: var(--title-font);
  color: var(--color-primary, var(--theme-color-dark));
}

.cars-section-twelve .nav-tabs {
  margin-bottom: 34px;
  border-bottom: 1px solid #2d3345;
}

.cars-section-twelve .nav-tabs .nav-link {
  font-size: 16px;
  font-weight: 500;
  color: var(--theme-color-light);
  border-top: 0;
  background-color: unset;
  border-left: 0;
  border-right: 0;
  padding: 0 0 7px 0;
  margin-right: 32px;
  position: relative;
}

.cars-section-twelve .nav-tabs .nav-link:hover {
  border-color: transparent;
}

.cars-section-twelve .nav-tabs .nav-link.active::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: var(--theme-color1);
}

.car-block-twelve {
  margin: 0 15px;
}

.car-block-twelve .inner-box .image-box {
  position: relative;
  display: inline-block;
}

.car-block-twelve .inner-box .image-box .image img {
  border-radius: 15px 15px 0 0;
}

.car-block-twelve .inner-box .image-box span {
  position: absolute;
  top: 20px;
  left: 20px;
  font-size: 14px;
  background-color: var(--theme-color1);
  font-weight: 500;
  color: var(--theme-color-light);
  padding: 6px 16px 6px;
  border-radius: 50px;
}

.car-block-twelve .inner-box .image-box .icon-box {
  position: absolute;
  top: 20px;
  right: 20px;
  width: 36px;
  height: 36px;
  background-color: var(--theme-color-light);
  border-radius: 50%;
  line-height: 35px;
  text-align: center;
}

.car-block-twelve .inner-box .image-box.two span {
  background-color: #3D923A;
}

.car-block-twelve .inner-box .content-box {
  position: relative;
  border-radius: 0 0 16px 16px;
  border: 1px solid var(--Border, #E1E1E1);
  border-top: 0;
  padding: 15px 27px 11px;
  margin-top: -9px;
}

.car-block-twelve .inner-box .content-box .title {
  font-size: 18px;
  font-weight: 500;
  color: var(--theme-color-dark);
  line-height: 24px;
  margin-bottom: 0;
}

.car-block-twelve .inner-box .content-box .text {
  color: var(--theme-color-dark);
  font-size: 14px;
  margin-bottom: 20px;
  padding-bottom: 13px;
  border-bottom: 1px solid #e1e1e1;
}

.car-block-twelve .inner-box .content-box ul {
  margin-bottom: 11px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  border-bottom: 1px solid #e1e1e1;
}

.car-block-twelve .inner-box .content-box ul li {
  position: relative;
  color: var(--theme-color-dark);
  font-size: 14px;
  line-height: 17px;
  margin-bottom: 16px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  margin-right: 42px;
}

.car-block-twelve .inner-box .content-box ul li:last-child {
  margin-right: 0;
}

.car-block-twelve .inner-box .content-box ul li img {
  width: 18px;
  margin-bottom: 7px;
}

.car-block-twelve .inner-box .content-box .btn-box span {
  color: var(--theme-color-dark);
  font-size: 14px;
  display: block;
  margin-bottom: -2px;
}

.car-block-twelve .inner-box .content-box .btn-box small {
  font-size: 20px;
  font-weight: 700;
  color: var(--theme-color-dark);
  line-height: 30px;
}

.car-block-twelve .inner-box .content-box .btn-box .details {
  position: absolute;
  bottom: 11px;
  right: 30px;
  font-size: 15px;
  font-weight: 500;
  color: var(--theme-color1);
  font-family: var(--title-font);
}

.car-block-twelve .inner-box .content-box .btn-box .details svg {
  position: relative;
  top: -2px;
  margin-left: 10px;
  fill: var(--theme-color1);
}

/*** 

====================================================================
    shop section thirteen
====================================================================

***/
.cars-section-thirteen {
  position: relative;
  padding: 60px 0 100px;
  border-radius: 80px;
}



.cars-section-thirteen .boxcar-title-three {
  margin-bottom: 51px;
}

.cars-section-thirteen .boxcar-title-three .breadcrumb {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 12px;
}

.cars-section-thirteen .boxcar-title-three .breadcrumb li {
  font-size: 15px;
  margin-right: 21px;
}

.cars-section-thirteen .boxcar-title-three .breadcrumb li:last-child {
  margin-right: 0;
}

.cars-section-thirteen .boxcar-title-three .breadcrumb li a {
  color: var(--theme-color1);
}

.cars-section-thirteen .boxcar-title-three .service-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.cars-section-thirteen .boxcar-title-three .service-list li {
  margin-right: 10px;
}

.cars-section-thirteen .boxcar-title-three .service-list li:last-child {
  margin-right: 0;
}

.cars-section-thirteen .boxcar-title-three .service-list li a {
  font-size: 15px;
  border: 1px solid #e1e1e1;
  border-radius: 50px;
  padding: 13px 25px 11px;
  display: inline-block;
}

.cars-section-thirteen .boxcar-title-three .service-list li a:hover {
  background-color: #e9f2ff;
  border-color: #e9f2ff;
}

.cars-section-thirteen .boxcar-title-three h2 {
  margin-bottom: 21px;
}

.cars-section-thirteen .pagination-sec {
  margin-top: 60px;
  text-align: center;
}

.cars-section-thirteen .pagination-sec .pagination {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  flex-wrap: wrap;
}

.cars-section-thirteen .pagination-sec .pagination li:first-child a,
.cars-section-thirteen .pagination-sec .pagination li:last-child a {
  border: 1px solid #e1e1e1;
  width: 60px;
  font-size: 30px;
  line-height: 35px;
}

.cars-section-thirteen .pagination-sec .pagination li a {
  font-size: 15px;
  margin: 0 5px;
  width: 40px;
  height: 40px;
  border-radius: 50px;
  text-align: center;
  font-weight: 500;
  padding: 0;
  line-height: 40px;
  border: 0;
  color: var(--theme-color-dark);
}

.cars-section-thirteen .pagination-sec .pagination li a:hover {
  background-color: #f9fbfc;
}

.cars-section-thirteen .pagination-sec .text {
  font-size: 14px;
  margin-top: 19px;
}

.service-block-thirteen {
  margin-bottom: 30px;
}

.service-block-thirteen .inner-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.service-block-thirteen .inner-box .image-box .image {
  border-radius: 16px 0 0 16px;
  overflow: hidden;
}

@media (max-width: 991px) {
  .service-block-thirteen .inner-box .image-box .image {
    border-radius: 16px;
  }
}

.service-block-thirteen .inner-box .right-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  border: 1px solid #E1E1E1;
  border-left: 0;
  border-radius: 0 16px 16px 0;
  padding: 42px 30px 10px;
}

.service-block-thirteen .inner-box .right-box .content-box .title {
  font-size: 26px;
  font-weight: 500;
  margin-bottom: 0;
}

.service-block-thirteen .inner-box .right-box .content-box .text {
  margin-bottom: 34px;
  font-size: 15px;
}

.service-block-thirteen .inner-box .right-box .content-box .inspection-sec {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 47px;
  gap: 30px;
  flex-wrap: wrap;
}

@media (max-width: 991px) {
  .service-block-thirteen .inner-box .right-box .content-box .inspection-sec {
    margin-bottom: 30px;
    gap: 20px
  }
}

.service-block-thirteen .inner-box .right-box .content-box .inspection-sec .inspection-box {
  position: relative;
  /* margin-right: 30px; */
  display: flex;
  gap: 12px;
}

.service-block-thirteen .inner-box .right-box .content-box .inspection-sec .inspection-box:last-child {
  margin-right: 0;
}

.service-block-thirteen .inner-box .right-box .content-box .inspection-sec .inspection-box img {
  position: absolute;
  top: 8px;
  left: 0;
}

.service-block-thirteen .inner-box .right-box .content-box .inspection-sec .inspection-box span {
  display: block;
  color: #818181;
  font-size: 15px;
  margin-bottom: -4px;
}

.service-block-thirteen .inner-box .right-box .content-box .inspection-sec .inspection-box small {
  color: var(--theme-color-dark);
  font-size: 15px;
  font-weight: 500;
}

.service-block-thirteen .inner-box .right-box .content-box .ul-cotent {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.service-block-thirteen .inner-box .right-box .content-box .ul-cotent li {
  /* margin-right: 10px; */
}

.service-block-thirteen .inner-box .right-box .content-box .ul-cotent li:last-child {
  margin-right: 0;
}

.service-block-thirteen .inner-box .right-box .content-box .ul-cotent li a {
  font-size: 15px;
  border: 1px solid #E1E1E1;
  border-radius: 50px;
  padding: 6px 20px;
  display: inline-flex;
}

.service-block-thirteen .inner-box .right-box .content-box-two {
  position: relative;
  text-align: right;
  margin-top: 15px;
  margin-left: 51px;
}

.service-block-thirteen .inner-box .right-box .content-box-two::before {
  content: "";
  position: absolute;
  top: -6px;
  left: -31px;
  width: 1px;
  height: 220px;
  background-color: #e1e1e1;
}

.service-block-thirteen .inner-box .right-box .content-box-two .icon-box {
  margin-bottom: 47px;
  display: inline-flex;
  align-items: center;
}

@media (max-width: 991px) {
  .service-block-thirteen .inner-box .right-box .content-box-two .icon-box {
    margin-bottom: 30px;
  }
}

.service-block-thirteen .inner-box .right-box .content-box-two .icon-box span {
  font-size: 15px;
  margin-right: 9px;
  display: inline-block;
  margin-bottom: 0;
}

.service-block-thirteen .inner-box .right-box .content-box-two .title {
  font-size: 26px;
  font-weight: 600;
  margin-bottom: 2px;
}

.service-block-thirteen .inner-box .right-box .content-box-two span {
  font-size: 15px;
  display: inline-block;
  margin-bottom: 20px;
}

.service-block-thirteen .inner-box .right-box .content-box-two .button {
  display: block;
  color: var(--theme-color1);
  font-weight: 500;
  border-radius: 12px;
  border: 1px solid var(--theme-color1);
  padding: 13px 25px 11px;
}

.service-block-thirteen .inner-box .right-box .content-box-two .button:hover {
  background-color: var(--theme-color1);
  color: var(--theme-color-light);
}

.service-block-thirteen .inner-box .right-box .content-box-two .button:hover svg {
  fill: var(--theme-color-light);
}

.service-block-thirteen .inner-box .right-box .content-box-two .button svg {
  position: relative;
  top: -2px;
  margin-left: 11px;
}

/*** 

====================================================================
    shop section fourten
====================================================================

***/
.cars-section-fourteen {
  background-color: var(--theme-color-light);
  position: relative;
  padding: 52px 0 120px;
  border-radius: 80px;
}

.cars-section-fourteen .side-bar {
  border-radius: 16px;
  border: 1px solid var(--Border, #E1E1E1);
  background: var(--White, #FFF);
  padding: 15px 0 0;
}

.categories-box {
  padding: 0 19px 7px;
  border-bottom: 1px solid #e1e1e1;
  /* Hide the browser's default checkbox */
  /* Create a custom checkbox */
  /* When the checkbox is checked, add a blue background */
  /* Create the checkmark/indicator (hidden when not checked) */
  /* Show the checkmark when checked */
  /* Style the checkmark/indicator */
}

.categories-box .title {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 10px;
}

.categories-box .contain {
  display: block;
  position: relative;
  padding-left: 30px;
  margin-bottom: 8px;
  cursor: pointer;
  font-size: 15px;
  color: var(--theme-color-dark);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.categories-box .contain input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.categories-box .checkmark {
  position: absolute;
  top: 4px;
  left: 0;
  height: 20px;
  width: 20px;
  border: 1px solid rgb(225, 225, 225);
  border-radius: 5px;
}

.categories-box .contain input:checked~.checkmark {
  background-color: var(--theme-color-dark);
}

.categories-box .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.categories-box .contain input:checked~.checkmark:after {
  display: block;
}

.categories-box .contain .checkmark:after {
  left: 6px;
  top: 3px;
  width: 7px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.price-box {
  padding: 15px 19px 0;
}

.price-box .title {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 10px;
}

.price-box form {
  width: 100%;
  margin: 0;
}

.price-box form .form-column {
  padding-right: 20px;
}

.price-box form .form-column.v2 {
  padding-right: 0;
  padding-left: 20px;
}

.price-box form .form_boxes {
  border-radius: 12px;
  border: 1px solid rgb(225, 225, 225);
  background: var(--color-white, #FFF);
  padding: 7px 15px;
  margin-bottom: 30px;
}

.price-box form .form_boxes textarea {
  width: 100%;
  height: 200px;
}

.price-box form .form_boxes textarea::-webkit-input-placeholder {
  color: var(--theme-color-dark);
}

.price-box form .form_boxes textarea::-moz-placeholder {
  color: var(--theme-color-dark);
}

.price-box form .form_boxes textarea:-ms-input-placeholder {
  color: var(--theme-color-dark);
}

.price-box form .form_boxes textarea::-ms-input-placeholder {
  color: var(--theme-color-dark);
}

.price-box form .form_boxes textarea::placeholder {
  color: var(--theme-color-dark);
}

.price-box form .form_boxes label {
  display: block;
  color: #818181;
  font-size: 15px;
  font-weight: 400;
}

.price-box form .form_boxes .drop-menu {
  height: unset;
}

.price-box form .form_boxes .drop-menu .select {
  padding: 0;
  line-height: unset;
}

.price-box form .form_boxes .drop-menu .dropdown {
  padding: 5px 15px;
  margin-top: 10px;
  border-radius: 12px;
  left: -15px;
  width: calc(100% + 30px);
  -webkit-box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.08);
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.08);
}

.car-block-fourteen {
  margin-bottom: 30px;
}

.car-block-fourteen .inner-box {
  border-radius: 16px;
  border: 1px solid var(--Border, #E1E1E1);
  background: var(--theme-color-light);
  padding: 17px 30px 30px;
  text-align: center;
}

.car-block-fourteen .inner-box .image-box {
  margin-bottom: 30px;
}

.car-block-fourteen .inner-box .content-box .rating {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 4px;
}

.car-block-fourteen .inner-box .content-box .rating li {
  font-size: 12px;
  color: var(--theme-color1);
  margin-right: 4px;
}

.car-block-fourteen .inner-box .content-box .rating li:last-child {
  margin-right: 0;
}

.car-block-fourteen .inner-box .content-box .text {
  font-weight: 500;
  color: var(--theme-color-dark);
  margin-bottom: 7px;
  text-align: start;

}

.car-block-fourteen .inner-box .content-box .title {
  font-weight: 700;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 24px;
}

.car-block-fourteen .inner-box .content-box .title del {
  font-size: 14px;
  padding-right: 10px;
}

.car-block-fourteen .inner-box .content-box .shoping-btn {
  font-size: 15px;
  font-weight: 500;
  color: var(--theme-color1);
  border-radius: 8px;
  border: 1px solid var(--color-secondary, #405FF2);
  background: var(--theme-color-light);
  height: 54px;
  line-height: 54px;
  width: 100%;
  display: inline-block;
  text-align: center;
}

.car-block-fourteen .inner-box .content-box .shoping-btn i {
  margin-right: 11px;
}

.car-block-fourteen .inner-box .content-box .shoping-btn:hover {
  background-color: var(--theme-color1);
  color: var(--theme-color-light);
}

/*** 

====================================================================
    shop section fifteen
====================================================================

***/
.cars-section-fifteen {
  background-color: var(--theme-color-light);
  position: relative;
  padding: 52px 0 0;
  border-radius: 80px;
}

.cars-section-fifteen .cars-section-fourteen.v1 {
  margin-top: 0;
  padding: 120px 0 160px;
}

.cars-section-fifteen .cars-section-fourteen.v1 .car-block-fourteen {
  margin: 0 15px;
}

.cars-section-fifteen .right-box.one {
  margin-bottom: 104px;
}

.cars-section-fifteen .nav-tabs {
  margin-bottom: 34px;
  border: 0;
  display: unset;
  margin-bottom: 0;
}

.cars-section-fifteen .nav-tabs .nav-link {
  font-size: 18px;
  font-weight: 500;
  color: var(--theme-color-dark);
  border-top: 0;
  border-left: 0;
  border-right: 0;
  padding: 0 0 7px 0;
  margin-right: 32px;
  position: relative;
  margin-bottom: 19px;
}

.cars-section-fifteen .nav-tabs .nav-link:hover {
  border-color: transparent;
}

.cars-section-fifteen .nav-tabs .nav-link.active {
  color: var(--theme-color1);
}

.cars-section-fifteen .nav-tabs .nav-link.active::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: var(--theme-color1);
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.cars-section-fifteen .inner-column h2 {
  font-weight: 700;
  margin-bottom: 12px;
}

.cars-section-fifteen .inner-column .price {
  font-size: 26px;
  font-weight: 500;
  color: var(--theme-color-dark);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 19px;
}

.cars-section-fifteen .inner-column .price del {
  font-size: 15px;
  margin-left: 10px;
}

.cars-section-fifteen .inner-column small {
  font-size: 15px;
  color: var(--theme-color-dark);
  margin-bottom: 17px;
  display: inline-block;
}

.cars-section-fifteen .inner-column small i {
  font-size: 10px;
  color: var(--theme-color1);
  width: 18px;
  height: 18px;
  background-color: rgba(64, 95, 242, 0.15);
  line-height: 18px;
  text-align: center;
  border-radius: 50px;
  position: relative;
  top: -1px;
  margin-right: 7px;
}

.cars-section-fifteen .inner-column .text {
  font-size: 15px;
  color: var(--theme-color-dark);
  margin-bottom: 21px;
}

.cars-section-fifteen .inner-column .btn-box {
  margin-bottom: 22px;
}

.cars-section-fifteen .inner-column .btn-box .shoping-btn {
  font-size: 15px;
  font-weight: 500;
  color: var(--theme-color-light);
  border-radius: 8px;
  border: 1px solid var(--color-secondary, #405FF2);
  background: var(--theme-color1);
  height: 54px;
  line-height: 54px;
  padding: 0 50px;
  display: inline-block;
  text-align: center;
}

.cars-section-fifteen .inner-column .btn-box .shoping-btn i {
  margin-right: 11px;
}

.cars-section-fifteen .inner-column .list li {
  font-size: 15px;
  color: var(--theme-color-dark);
  margin-bottom: -2px;
}

.cars-section-fifteen .inner-column .list li span {
  width: 111px;
  display: inline-block;
}

.cars-section-fifteen .content-sec {
  padding: 20px 0px;
}

.cars-section-fifteen .content-sec .content-column .inner-column .description-sec .title {
  font-size: 26px;
  font-weight: 500;
  margin-bottom: 23px;
}

.cars-section-fifteen .content-sec .content-column .inner-column .description-sec .text {
  font-size: 15px;
  color: var(--theme-color-dark);
}

.cars-section-fifteen .content-sec .content-column .inner-column .description-sec .text.two {
  margin-bottom: 24px;
}

.cars-section-fifteen .content-sec .content-column .inner-column .description-sec .des-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-top: 38px;
}

.cars-section-fifteen .content-sec .content-column .inner-column .description-sec .des-list li {
  margin-right: 18px;
}

.cars-section-fifteen .content-sec .content-column .inner-column .description-sec .des-list li:last-child {
  margin-right: 0;
}

.cars-section-fifteen .content-sec .content-column .inner-column .description-sec .des-list li span {
  display: inline-block;
  font-size: 15px;
  font-weight: 500;
  color: var(--theme-color-dark);
  padding: 14px 26px 12px;
  border-radius: 12px;
  background: var(--bg-2, #EEF1FB);
}

.cars-section-fifteen .content-sec .content-column .inner-column .description-sec .des-list li span img {
  margin-right: 10px;
  position: relative;
  top: -1px;
}

.cars-section-fifteen .content-sec .content-column .inner-column .description-sec .des-list .two span {
  background: var(--bg-3, #E9F2FF);
}

.cars-section-fifteen .content-sec .content-column .inner-column .description-sec .des-list .three span {
  background: var(--bg-4, #FFE9F3);
}

.cars-section-fifteen .content-sec .content-column .inner-column .reviews .title {
  margin-bottom: 23px;
  font-weight: 500;
}

.cars-section-fifteen .content-sec .content-column .inner-column .reviews .auther-name {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 16px;
}

.cars-section-fifteen .content-sec .content-column .inner-column .reviews .auther-name span {
  font-size: 14px;
  font-weight: 500;
  width: 40px;
  height: 40px;
  line-height: 40px;
  background-color: var(--theme-color-dark);
  border-radius: 50%;
  text-align: center;
  color: var(--theme-color-light);
  display: inline-block;
  margin-right: 12px;
}

.cars-section-fifteen .content-sec .content-column .inner-column .reviews .auther-name .name {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 0;
  margin-right: 17px;
}

.cars-section-fifteen .content-sec .content-column .inner-column .reviews .auther-name small {
  font-size: 14px;
  color: var(--theme-color-dark);
}

.cars-section-fifteen .content-sec .content-column .inner-column .reviews .rating-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.cars-section-fifteen .content-sec .content-column .inner-column .reviews .rating-list .list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.cars-section-fifteen .content-sec .content-column .inner-column .reviews .rating-list .list li {
  font-size: 10px;
  margin-right: 4px;
  color: var(--theme-color1);
}

.cars-section-fifteen .content-sec .content-column .inner-column .reviews .rating-list .list li:last-child {
  margin-right: 0;
}

.cars-section-fifteen .content-sec .content-column .inner-column .reviews .rating-list span {
  font-size: 15px;
  color: var(--theme-color-dark);
  font-weight: 500;
  margin-left: 12px;
}

.cars-section-fifteen .content-sec .content-column .inner-column .reviews .text {
  font-size: 15px;
  color: var(--theme-color-dark);
  max-width: 849px;
}

.cars-section-fifteen .content-sec .content-column .inner-column .image-box {
  margin-top: 23px;
  margin-bottom: 20px;
}

.cars-section-fifteen .content-sec .content-column .inner-column .image-box img {
  border-radius: 12px;
  margin-right: 16px;
}

.cars-section-fifteen .content-sec .content-column .inner-column .image-box img:last-child {
  margin-right: 0;
}

.cars-section-fifteen .content-sec .content-column .inner-column .btn-box .like-btn {
  font-size: 15px;
  color: #818181;
  margin-right: 28px;
}

.cars-section-fifteen .content-sec .content-column .inner-column .btn-box .like-btn i {
  margin-right: 10px;
}

.cars-section-fifteen .content-sec .content-column .inner-column .btn-box .like-btn:hover {
  color: var(--theme-color1);
}

.cars-section-fifteen .content-sec .content-column .inner-column .content-box.two {
  margin-top: 29px;
  border-bottom: 1px solid #E1E1E1;
  padding-bottom: 60px;
}

.cars-section-fifteen .content-sec .content-column .inner-column .content-box.two .text {
  margin-bottom: 27px;
}

.cars-section-fifteen .content-sec .content-column .inner-column .content-box.two .review {
  font-size: 15px;
  color: var(--theme-color1);
  font-weight: 500;
  height: 54px;
  line-height: 54px;
  padding: 0 38px;
  border-radius: 12px;
  border: 1px solid var(--color-secondary, #405FF2);
  background: var(--White, #FFF);
  display: inline-block;
  margin-top: 30px;
}

.cars-section-fifteen .content-sec .content-column .inner-column .content-box.two .review svg {
  margin-left: 10px;
  position: relative;
  top: -1px;
}

.cars-section-fifteen .content-sec .content-column .inner-column .Reply-sec {
  margin-top: 52px;
  margin-bottom: 20px;
}

.cars-section-fifteen .content-sec .content-column .inner-column .Reply-sec .text {
  margin-bottom: 30px;
}

.cars-section-fifteen .content-sec .content-column .inner-column .Reply-sec .right-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.cars-section-fifteen .content-sec .content-column .inner-column .Reply-sec .right-box .rating-list {
  width: 330px;
}

.cars-section-fifteen .content-sec .content-column .inner-column .Reply-sec .right-box .rating-list .list-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-bottom: 20px;
}

.cars-section-fifteen .content-sec .content-column .inner-column .Reply-sec .right-box .rating-list .list-box span {
  font-size: 15px;
  color: var(--theme-color-dark);
}

.cars-section-fifteen .content-sec .content-column .inner-column .Reply-sec .right-box .rating-list .list-box .list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.cars-section-fifteen .content-sec .content-column .inner-column .Reply-sec .right-box .rating-list .list-box .list li {
  font-size: 12px;
  color: var(--theme-color1);
  margin-right: 4px;
}

.cars-section-fifteen .content-sec .content-column .inner-column .Reply-sec .right-box .rating-list .list-box .list li:last-child {
  margin-right: 0;
}

.cars-section-fifteen .content-sec .content-column .inner-column .Reply-sec .right-box .rating-list.two {
  margin-right: 144px;
}

.cars-section-fifteen .content-sec .content-column .inner-column form {
  width: 100%;
  margin: 0;
  margin-left: -12px;
}

.cars-section-fifteen .content-sec .content-column .inner-column form .form_boxes {
  border-radius: 12px;
  border: 1px solid rgb(225, 225, 225);
  background: var(--color-white, #FFF);
  padding: 7px 15px;
  margin-bottom: 30px;
}

.cars-section-fifteen .content-sec .content-column .inner-column form .form_boxes textarea {
  width: 100%;
  height: 200px;
}

.cars-section-fifteen .content-sec .content-column .inner-column form .form_boxes textarea::-webkit-input-placeholder {
  color: var(--theme-color-dark);
}

.cars-section-fifteen .content-sec .content-column .inner-column form .form_boxes textarea::-moz-placeholder {
  color: var(--theme-color-dark);
}

.cars-section-fifteen .content-sec .content-column .inner-column form .form_boxes textarea:-ms-input-placeholder {
  color: var(--theme-color-dark);
}

.cars-section-fifteen .content-sec .content-column .inner-column form .form_boxes textarea::-ms-input-placeholder {
  color: var(--theme-color-dark);
}

.cars-section-fifteen .content-sec .content-column .inner-column form .form_boxes textarea::placeholder {
  color: var(--theme-color-dark);
}

.cars-section-fifteen .content-sec .content-column .inner-column form .form_boxes label {
  display: block;
  color: #818181;
  font-size: 15px;
  font-weight: 400;
}

.cars-section-fifteen .content-sec .content-column .inner-column form .form_boxes .drop-menu {
  height: unset;
}

.cars-section-fifteen .content-sec .content-column .inner-column form .form_boxes .drop-menu .select {
  padding: 0;
  line-height: unset;
}

.cars-section-fifteen .content-sec .content-column .inner-column form .form_boxes .drop-menu .dropdown {
  padding: 5px 15px;
  margin-top: 10px;
  border-radius: 12px;
  left: -15px;
  width: calc(100% + 30px);
  -webkit-box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.08);
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.08);
}

.cars-section-fifteen .content-sec .content-column .inner-column form .form_boxes.v2 {
  padding-top: 14px;
}

.cars-section-fifteen .content-sec .content-column .inner-column form .theme-btn {
  background: #405FF2;
  color: #fff;
  height: 54px;
  line-height: 54px;
  border-radius: 12px;
  padding: 0 26px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.cars-section-fifteen .content-sec .content-column .inner-column form .theme-btn svg {
  margin-left: 10px;
}

/*** 

====================================================================
    boxcar-customers-section
====================================================================

***/
.boxcar-customers-section .car-slider-three {
  padding-bottom: 0;
}

.boxcar-customers-section .car-slider-three .slick-prev,
.boxcar-customers-section .car-slider-three .slick-next {
  bottom: auto;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 40px;
  height: 30px;
}

.boxcar-customers-section .car-slider-three .slick-prev {
  left: -50px;
}

.boxcar-customers-section .car-slider-three .slick-next {
  right: -50px;
  left: auto;
}

.cars-section-four.v1 {
  padding: 52px 0 120px;
  background-color: var(--theme-color-light);
}

.cars-section-four.v1 .text-box.v1 {
  margin-bottom: 11px;
}

.cars-section-four.v1 .boxcar-title-three {
  margin-bottom: 0;
  position: relative;
  z-index: 12;
}

.cars-section-four.v1 .boxcar-title-three h2 {
  margin-bottom: 21px;
}

.cars-section-four.v1.v2 .boxcar-title-three {
  margin-bottom: 51px;
}

.cars-section-ten.v8 {
  padding: 120px 0;
}

.cars-section-four.v1.v5 {
  padding: 0;
}

.cars-section-four.v1.v5 .text-box.v2 .form_boxes.v3 {
  margin-right: 57px;
}

.cars-section-four.v1.v5 .map-column #map {
  position: fixed;
  height: 100%;
  right: 0;
  bottom: 0;
}

.cars-section-four.v1.v5 .map-column .inner-column {
  position: relative;
  margin-right: -52px;
}

.cars-section-four.v1.v5 .map-column .inner-column .goole-iframe iframe {
  width: 100%;
  height: 1133px;
  border-radius: 0 80px 0 0;
}

.cars-section-four.v1.v5 .map-column .inner-column .car-block-ten {
  position: absolute;
  top: 178px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}

.cars-section-four.v1.v5 .map-column .inner-column .car-block-ten .inner-box {
  width: 329px;
  margin: auto;
  border-radius: 16px;
}

.cars-section-four.v1.v5 .map-column .inner-column .car-block-ten .inner-box .content-box {
  padding-bottom: 15px;
}

.cars-section-four.v1.v5 .right-box {
  padding: 30px 20px 20px 40px;
}

.cars-section-four.v1.v5 .right-box .car-block-ten {
  margin: 0 0 30px;
}

.cars-section-four.v1.v5 .right-box .car-block-ten .inner-box .image-box {
  width: 100%;
}

.cars-section-four.v1.v5 .right-box .car-block-ten .inner-box .image-box .image img {
  width: 100%;
}

.cars-section-four.v1.v5 .pagination-sec.v1 {
  margin-top: 60px;
}

.cars-section-four.v1.v5 .map-column.two .inner-column .goole-iframe iframe {
  height: 1250px;
}

.car-block-forteen {
  margin-bottom: 30px;
}

.car-block-forteen:last-child {
  margin-bottom: 0;
}

.car-block-forteen .inner-box {
  background-color: var(--theme-color-light);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.car-block-forteen .inner-box .image-box {
  position: relative;
  display: inline-block;
}

.car-block-forteen .inner-box .image-box .image {
  border-radius: 15px 0 0 15px;

}

@media (max-width: 1399px) {
  .car-block-forteen .inner-box .image-box .image {
    border-radius: 15px 15px 0 0;

  }
}

.car-block-forteen .inner-box .image-box .image img {
  height: 288px;
}

.car-block-forteen .inner-box .image-box span {
  position: absolute;
  top: 20px;
  left: 20px;
  font-size: 14px;
  background-color: var(--theme-color1);
  font-weight: 500;
  color: var(--theme-color-light);
  padding: 1px 16px 1px 16px;
  border-radius: 50px;
}

.car-block-forteen .inner-box .image-box .icon-box {
  position: absolute;
  top: 20px;
  right: 20px;
  width: 36px;
  height: 36px;
  background-color: var(--theme-color-light);
  border-radius: 50%;
  line-height: 35px;
  text-align: center;
}

.car-block-forteen .inner-box .image-box.two span {
  background-color: #3D923A;
}

.car-block-forteen .inner-box .content-box {
  position: relative;
  border-radius: 0 15px 15px 0;
  border: 1px solid var(--Border, #E1E1E1);
  border-left: 0;
  padding: 23px 30px 13px;
  height: 100%;
  width: calc(100% - 330px);
}

.car-block-forteen .inner-box .content-box .title {
  font-size: 18px;
  font-weight: 500;
  color: var(--theme-color-dark);
  line-height: 24px;
  margin-bottom: 5px;
}

.car-block-forteen .inner-box .content-box .text {
  margin-bottom: 14px;
  font-size: 14px;
}

.car-block-forteen .inner-box .content-box ul li {
  position: relative;
  /* padding-left: 28px; */
  padding-bottom: 11px;
  display: flex;
  gap: 10px;
}

.car-block-forteen .inner-box .content-box ul li img {
  position: absolute;
  top: 4px;
  left: 0;
}

.car-block-forteen .inner-box .content-box .btn-box span {
  font-size: 14px;
  display: block;
  margin-bottom: -2px;
}

.car-block-forteen .inner-box .content-box .btn-box small {
  font-size: 20px;
  font-weight: 700;
  color: var(--theme-color-dark);
  line-height: 30px;
  display: inline-block;
  margin-bottom: 6px;
}

.car-block-forteen .inner-box .content-box .btn-box .details {
  position: absolute;
  bottom: 19px;
  right: 35px;
  display: block;
  font-size: 15px;
  font-weight: 500;
  color: var(--theme-color-dark);
  font-family: var(--title-font);
}

.car-block-forteen .inner-box .content-box .btn-box .details svg {
  position: relative;
  top: -2px;
  margin-left: 10px;
}

.ui-widget.ui-widget-content {
  background-color: #E1E1E1;
  height: 3px;
  margin-bottom: 30px;
  border: 0;
}

.ui-slider-horizontal .ui-slider-handle {
  width: 29px;
  height: 29px;
  border: 0;
  border-radius: 0;
  cursor: pointer;
  position: relative;
}

.ui-slider-horizontal .ui-slider-handle:before {
  content: "";
  position: absolute;
  top: -13px;
  left: 0;
  width: 29px;
  height: 29px;
  background-image: url(../images/icons/next.html);
  background-repeat: no-repeat;
}

.ui-slider-horizontal .ui-slider-range {
  background-color: #050B20;
}

.cars-section-fifteen .inner-column {
  position: relative;
}

.cars-section-fifteen .btn-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.shop-single-thumbs-slider {
  position: absolute;
  top: 0;
  left: 0;
  width: 100px;
}

.shop-single-thumbs-slider .shop-single-thumb {
  width: 100%;
  height: 90px;
  text-align: center;
  margin-bottom: 10px;
  line-height: 90px;
}

.shop-single-thumbs-slider .shop-single-thumb .thumb-img {
  border: 1px solid #e1e1e1;
  height: 90px;
  width: 90px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 16px;
  cursor: pointer;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.shop-single-thumbs-slider .shop-single-thumb.slick-active.slick-current .thumb-img {
  border-color: #050B20;
}

.shop-single-product-slider {
  width: 643px;
  border: 1px solid #E1E1E1;
  border-radius: 16px;
  margin: auto;
  margin-bottom: 20px;
  /* padding: 70px 0px; */
}

.compare-products {
  overflow: auto;
}


@media (max-width: 1024px) {
  .shop-single-product-slider {
    padding: 0;
  }
}

.shop-single-product-slider .shop-single-product-slide {
  text-align: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.number {
  width: 140px;
  border: 1px solid #E1E1E1;
  border-radius: 16px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-right: 20px;
}

.number .minus,
.number .plus {
  cursor: pointer;
}

.number input {
  width: 80px;
  text-align: center;
  outline: none;
}

/* ===================== PAYMENT OPTIONS =================== */
.payment-options {
  padding: 30px;
  border: 1px solid #E1E1E1;
  border-radius: 16px;
  margin-top: 30px;
  margin-bottom: 30px;
}

.payment-options .shipp input[type=radio] {
  display: none;
}

.payment-options .shipp input[type=radio]+label span {
  display: inline-block;
  width: 20px;
  height: 20px;
  position: relative;
  margin-right: 10px;
  border: 1px solid #e1e1e1;
  border-radius: 50%;
  top: 4px;
}

.payment-options .shipp input[type=radio]:checked+label span {
  border: 3px solid #050B20;
}

.payment-options .shipp>label small {
  display: inline-block;
  color: #222222;
  font-size: 15px;
  font-weight: 500;
}

.payment-options ul li {
  margin-bottom: 15px;
}

.payment-options ul li:last-child {
  margin-bottom: 0;
}

.payment-options ul li .shipp+p {
  font-size: 13px;
  color: #050B20;
  line-height: 24px;
  margin-top: 15px;
}

/* .payment-options ul li .shipp+p:before {
  content: "";
  position: absolute;
  top: -5px;
  left: 30px;
  border-top: 10px solid #807aff;
  border-right: 10px solid transparent;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
} */

.payment-options .shipp+img {
  margin-left: 60px;
  margin-top: 16px;
}

.form-submit .theme-btn {
  background: #405FF2;
  color: #fff;
  height: 54px;
  line-height: 54px;
  border-radius: 12px;
  padding: 0 26px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

/* Shop Order Page Style */
.order_complete_message {
  position: relative;
  margin-bottom: 70px;
}

.order_complete_message .icon {
  border-radius: 50%;
  font-size: 30px;
  height: 80px;
  line-height: 80px;
  margin: 0 auto 25px;
  width: 80px;
  background-color: #E9F2FF;
}

.order_complete_message .icon span {
  color: #405FF2;
}

.order_complete_message .title {
  font-size: 30px;
  font-weight: 500;
  line-height: 45px;
  margin-bottom: 10px;
}

.order_complete_message .para {
  color: #041e42;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 26px;
}

.order_complete_message .shop_order_box {
  position: relative;
}

.order_complete_message .shop_order_box .order_details {
  background-color: #fff;
  border: 1px solid #eaeaea;
  border-radius: 6px;
  padding: 40px 50px 20px;
}

.order_complete_message .shop_order_box .order_details .title {
  font-size: 20px;
  line-height: 29px;
  letter-spacing: 0em;
}

.order_complete_message .shop_order_box .order_details .od_content .product_name_qnt,
.order_complete_message .shop_order_box .order_details .order_sidebar_widget .product_name_qnt {
  color: #041e42;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 22px;
}

.order_complete_message .shop_order_box .order_details .order_details .od_content .product_name_qnt span,
.order_complete_message .shop_order_box .order_details .order_sidebar_widget .product_name_qnt span {
  color: #041e42;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 22px;
}

.order_complete_message .shop_order_box .order_details .od_content .subtitle p {
  color: #041e42;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 35px;
}

.order_complete_message .shop_order_box .order_details .od_content .subtitle span {
  color: #041e42;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 35px;
}

.order_complete_message .shop_order_box .order_details .od_content .subtitle .free_shipping {
  font-size: 15px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
}

.order_complete_message .shop_order_box .order_details .od_content .subtitle .fwn_bd_color {
  color: #041e42;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 35px;
}

.order_complete_message .order_list_raw {
  background-color: #fff;
  border: 1px dashed #626974;
  border-radius: 6px;
  margin-bottom: 30px;
  padding: 35px 40px 35px;
}

.order_complete_message .order_list_raw ul {
  margin-bottom: 0;
}

.order_complete_message .order_list_raw ul li {
  margin-right: 80px;
  text-align: left;
}

.order_complete_message .order_list_raw ul li:last-child {
  margin-right: 0;
}

.order_complete_message .order_list_raw ul li h5 {
  font-style: normal;
  line-height: 28px;
  margin-bottom: 0;
}

.order_complete_message .order_list_raw ul li p {
  color: #041e42;
  font-style: normal;
  font-size: 16px;
  line-height: 28px;
  margin-bottom: 5px;
}

.order_complete_message .order_sidebar_widget .product_name_qnt {
  margin-bottom: 15px;
}

.order_complete_message .list_last_content .para {
  color: #000000;
  font-family: Jost;
  font-size: 15px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0em;
}

.order_list_raw {
  background: #E9F2FF;
  border: 2px dashed #405FF2;
  padding: 36px 100px;
  border-radius: 8px;
}

.order_list_raw ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.order_list_raw li {
  text-align: left;
  width: 24%;
}

.order_list_raw li p {
  color: #050B20;
  font-size: 15px;
  line-height: 28px;
  margin-bottom: 0;
}

.order_list_raw li h5 {
  color: #050B20;
  font-size: 15px;
  line-height: 28px;
  font-weight: 500;
  margin-bottom: 0;
}

.order_details {
  border: 1px solid #E1E1E1;
  padding: 50px;
  border-radius: 16px;
  margin-top: 50px;
  margin-bottom: 80px;
}

.order_details>h4 {
  color: #050B20;
  font-size: 20px;
  line-height: 30px;
  font-weight: 500;
  text-transform: capitalize;
}

.order_details .od_content ul li {
  border-bottom: 1px solid #E9E9E9;
  padding: 12px 0;
}

.order_details .od_content ul li.no-border {
  border: 0;
}

.order_details .od_content ul li.no-border p {
  margin-bottom: 10px;
}

.order_details .od_content ul li.no-border p:last-child {
  margin-bottom: 0;
}

.order_details .od_content ul li:last-child {
  border-bottom: 0;
  padding-bottom: 0;
}

.order_details .od_content ul li p {
  font-size: 15px;
  font-weight: 500;
  color: #050B20;
  margin-bottom: 0;
}

.order_details .od_content ul li p a {
  display: inline-block;
  color: #405FF2;
  font-size: 14px;
  font-weight: 400;
}

.order_details .od_content ul li span {
  color: #050B20;
  font-size: 15px;
  font-weight: 500;
}

.cart-page {
  padding: 70px 0 100px;
}

.cart-table {
  padding-right: 40px;
}

.cart-table table {
  width: 100%;
}

.cart-table table thead {
  background: #E9F2FF;
}

.cart-table table thead th {
  padding: 20px 20px;
  font-size: 16px;
  font-weight: 500;
  color: #405FF2;
}

.cart-table table thead th:first-child {
  border-radius: 16px 0 0 16px;
}

.cart-table table thead th:last-child {
  border-radius: 0 16px 16px 0;
}

.cart-table table .number {
  height: 54px;
}

.cart-table table tr td {
  padding: 20px;
  border-bottom: 1px solid #ECEDF2;
}

.cart-table table tr .remove-cart-item {
  display: inline-block;
  border: 1px solid #E1E1E1;
  border-radius: 16px;
  width: 40px;
  height: 40px;
  background-color: #F9FBFC;
  text-align: center;
  line-height: 40px;
}

.cart-table .shop-cart-product {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.cart-table .shop-cart-product .shop-product-cart-img {
  width: 120px;
  height: 105px;
  border-radius: 16px;
  overflow: hidden;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-right: 20px;
}

.cart-table .shop-cart-product .shop-product-cart-img>img {}

.cart-table .shop-cart-product .shop-product-cart-info h3 {
  max-width: 170px;
  color: #050B20;
  font-size: 16px;
  line-height: 26px;
  font-weight: 500;
  margin-bottom: 0;
}

.cart-table-bottom {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding-top: 10px;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.cart-table-bottom .coupan-form {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.cart-table-bottom .coupan-form input {
  border: 1px solid #E1E1E1;
  border-radius: 16px;
  height: 55px;
  padding: 0 20px;
  color: #050B20;
  width: 270px;
  margin-right: 20px;
  font-size: 15px;
}

.theme-btn-web {
  background: #405FF2;
  color: #fff;
  height: 54px;
  line-height: 54px;
  border-radius: 12px;
  padding: 0 26px;
  display: inline-block;
}

.theme-btn-web:hover {
  color: #fff;
}

.theme-btn-web img {
  margin-left: 7px;
}

.cart-totals {
  border: 1px solid #E1E1E1;
  padding: 25px;
  border-radius: 16px;
}

.cart-totals h3 {
  color: #050B20;
  font-size: 20px;
  line-height: 30px;
  font-weight: 500;
  text-transform: capitalize;
  margin-bottom: 30px;
}

.cart-totals .theme-btn-web {
  text-align: center;
  width: 100%;
  display: block;
  margin-top: 30px;
}

.cart-totals table {
  width: 100%;
}

.cart-totals table th {
  color: #050B20;
  font-size: 15px;
  border-bottom: 1px solid #E1E1E1;
  padding: 10px 0;
  font-weight: 400;
}

.cart-totals table td {
  color: #050B20;
  font-size: 15px;
  border-bottom: 1px solid #E1E1E1;
  padding: 10px 0;
  text-align: right;
  font-weight: 500;
}

.cart-totals table tr:first-child th,
.cart-totals table tr:first-child td {
  padding-top: 0;
}

.cart-totals table tr:last-child th,
.cart-totals table tr:last-child td {
  padding-bottom: 0;
  border-bottom: 0;
}

.compare-section {
  padding: 80px 0 190px;
}

.compare-section .compare-products table {
  width: 100%;
}

.compare-section .compare-products table thead th {
  width: 25%;
  padding: 15px 10px;
}

.compare-section .compare-products table tbody th {
  color: #050B20;
  font-size: 15px;
  border-bottom: 1px solid #E1E1E1;
  padding: 10px 0;
  font-weight: 400;
}

.compare-section .compare-products table tbody th img {
  margin-right: 5px;
  position: relative;
  top: -1px;
}

.compare-section .compare-products table tbody td {
  color: #050B20;
  font-size: 15px;
  padding: 10px 0;
  font-weight: 500;
  border-bottom: 1px solid #E1E1E1;
}

.compare-section .compare-products table tbody tr:last-child th,
.compare-section .compare-products table tbody tr:last-child td {
  border-bottom: 0;
  padding-bottom: 0;
}

.compare-section .compare-product-img {
  position: relative;
}

.compare-section .compare-product-img>img {
  width: 100%;
  border-radius: 16px;
}

.compare-section .compare-product-img .remove-pro {
  display: inline-block;
  position: absolute;
  top: 20px;
  right: 20px;
  border-radius: 16px;
  width: 40px;
  height: 40px;
  background-color: #fff;
  text-align: center;
  line-height: 40px;
}

.cluster-visible {
  text-align: center;
  font-size: 16px !important;
  color: #FFFFFF !important;
  font-weight: 500 !important;
  border-radius: 50%;
  width: 40px !important;
  height: 40px !important;
  line-height: 40px !important;
  background-color: #1967D2;
  border: 7px solid rgba(25, 103, 210, 0.2);
  -webkit-box-shadow: 0 7px 30px rgba(33, 33, 33, 0.3);
  box-shadow: 0 7px 30px rgba(33, 33, 33, 0.3);
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  background-clip: content-box;
}

.map-marker-container {
  position: absolute;
  -webkit-transform: translate3d(-50%, -100%, 0);
  transform: translate3d(-50%, -100%, 0);
}

.marker-container {
  position: relative;
  top: -5px;
  width: 120px;
  height: 40px;
  z-index: 1;
  border-radius: 50%;
  cursor: pointer;
  -webkit-perspective: 1000;
}

.face {
  position: absolute;
  background: #fff;
  padding: 15px 20px;
  border-radius: 120px;
  -webkit-box-shadow: 0px 10px 40px 0px rgba(0, 0, 0, 0.05);
  box-shadow: 0px 10px 40px 0px rgba(0, 0, 0, 0.05);
  text-align: center;
  border-radius: 50%;
  z-index: 100;
  background: #fff;
  font-size: 15px;
  color: #050B20;
  font-weight: 500;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  background-clip: content-box;
}

.face>div {
  background-image: url(images/resource/company-logo/3-1.html);
  position: absolute;
  height: 100%;
  width: 100%;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.map-marker-container:hover .marker-container .marker-card,
.clicked .marker-container .marker-card,
.map-marker-container.clicked .marker-card {
  -webkit-transform: rotateY(360deg);
  transform: rotateY(360deg);
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

#singleListingMap .marker-container {
  cursor: default;
}

.map-marker-container[data-marker_id="0"] .marker-arrow:before {
  border-color: #4cbfd8 transparent transparent;
}

.map-marker-container[data-marker_id="1"] .marker-arrow:before {
  border-color: #ff8480 transparent transparent;
}

.map-marker-container[data-marker_id="2"] .marker-arrow:before {
  border-color: #8c689d transparent transparent;
}

.map-marker-container[data-marker_id="3"] .marker-arrow:before {
  border-color: #35c2a5 transparent transparent;
}

.map-marker-container[data-marker_id="4"] .marker-arrow:before {
  border-color: #ff8480 transparent transparent;
}

.map-marker-container[data-marker_id="5"] .marker-arrow:before {
  border-color: #ffb874 transparent transparent;
}

.map-marker-container[data-marker_id="6"] .marker-arrow:before {
  border-color: #ff007a transparent transparent;
}

.map-marker-container[data-marker_id="0"] .face {
  background-color: #4cbfd8;
}

.map-marker-container[data-marker_id="1"] .face {
  background-color: #ff8480;
}

.map-marker-container[data-marker_id="2"] .face {
  background-color: #8c689d;
}

.map-marker-container[data-marker_id="3"] .face {
  background-color: #35c2a5;
}

.map-marker-container[data-marker_id="4"] .face {
  background-color: #ff8480;
}

.map-marker-container[data-marker_id="5"] .face {
  background-color: #ffb874;
}

.map-marker-container[data-marker_id="6"] .face {
  background-color: #ff007a;
}

.marker-card {
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  position: absolute;
  z-index: 1;
  background: #fff;
  width: 120px;
  height: 40px;
  border-radius: 120px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 15px;
  font-weight: 500;
}

.map-listing-item .image-box img {
  width: 100%;
  border-radius: 16px 16px 0 0;
}

.map-listing-item .content {
  background: #fff;
  padding: 20px;
  border-radius: 0 0 16px 16px;
}

.map-listing-item .content h3 {
  margin: 0;
  color: #050B20;
  font-size: 18px;
  font-weight: 500;
  line-height: 32px;
}

.map-listing-item .content .job-info {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.map-listing-item .content .job-info li {
  color: #050B20;
  font-size: 14px;
  padding: 0 10px;
}

.map-listing-item .content .job-info li:first-child {
  padding-left: 0;
}

.map-listing-item .content .job-info li:last-child {
  padding-right: 0;
}

/*** 

====================================================================
    galler-section section
====================================================================

***/
.car-galler-section {
  position: relative;
  padding: 120px 0;
}

.car-galler-section .boxcar-title .btn-title {
  position: absolute;
  top: 21px;
  right: 0;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px;
  font-family: var(--title-font);
  color: var(--color-primary, var(--theme-color-dark));
}

.galler-block {
  margin-bottom: 30px;
}

.galler-block .inner-box {
  position: relative;
}

.galler-block .inner-box:hover .image-box .image {
  border-radius: 16px;
}

.galler-block .inner-box:hover .image-box .image img {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.galler-block .inner-box .image-box .image {
  border-radius: 16px;
  overflow: hidden;
}

.galler-block .inner-box .image-box .image img {
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  width: 100%;
}

.galler-block .inner-box .content-box {
  position: absolute;
  bottom: 30px;
  left: 30px;
  background-color: var(--theme-color-light);
  padding: 7px 30px 9px;
  border-radius: 50px;
}

.galler-block .inner-box .content-box .title {
  font-size: 15px;
  font-weight: 500;
}

.galler-block .inner-box .content-box .title span {
  margin-left: 7px;
  display: inline-block;
}

/*** 

====================================================================
    customer section
====================================================================

***/
.boxcar-customers-section {
  position: relative;
  padding: 120px 0 100px;
  background-color: #F9FBFC;
}

.boxcar-customers-section .boxcar-title {
  margin-top: 11px;
}

.boxcar-customers-section .boxcar-title .text {
  font-size: 15px;
  position: absolute;
  top: -8px;
  right: 0;
}

.customer-block {
  margin: 20px 15px;
}

.customer-block .inner-box {
  border-radius: 16px;
  background-color: var(--theme-color-light);
  -webkit-box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.08);
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.08);
  padding: 25px 30px 30px;
}

.customer-block .inner-box .rating-area {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 7px;
}

.customer-block .inner-box .rating-area span {
  color: #6C6C83;
  margin-left: 20px;
}

.customer-block .inner-box .rating-area span i {
  margin-right: 7px;
}

.customer-block .inner-box .rating-area .rating {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.customer-block .inner-box .rating-area .rating li {
  color: var(--theme-color-light);
  width: 18.75px;
  height: 18.75px;
  background-color: #00B67A;
  line-height: 18.75px;
  font-size: 12px;
  margin-left: 1px;
  text-align: center;
}

.customer-block .inner-box .title {
  font-weight: 500;
  margin-bottom: 3px;
  font-size: 16px;
  line-height: 28px;
}

.customer-block .inner-box .text {
  font-size: 14px;
  margin-bottom: 8px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

/*** 

====================================================================
    customer section two
====================================================================

***/
.boxcar-customers-section-two {
  position: relative;
  padding: 120px 0 50px;
}

.boxcar-customers-section-two .boxcar-title {
  margin-top: 11px;
}

.boxcar-customers-section-two .boxcar-title .text {
  font-size: 15px;
  position: absolute;
  top: -8px;
  right: 0;
}

.customer-block-two {
  margin: 20px 15px;
}

.customer-block-two .inner-box {
  border-radius: 16px;
  background: var(--bg-1, #F9FBFC);
  padding: 25px 30px 30px;
}

.customer-block-two .inner-box .rating-area {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 7px;
}

.customer-block-two .inner-box .rating-area span {
  color: #6C6C83;
  margin-left: 20px;
}

.customer-block-two .inner-box .rating-area span i {
  margin-right: 7px;
}

.customer-block-two .inner-box .rating-area .rating {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.customer-block-two .inner-box .rating-area .rating li {
  color: var(--theme-color-light);
  width: 18.75px;
  height: 18.75px;
  background-color: #00B67A;
  line-height: 18.75px;
  font-size: 12px;
  margin-left: 1px;
  text-align: center;
}

.customer-block-two .inner-box .title {
  font-weight: 500;
  margin-bottom: 3px;
  font-size: 16px;
  line-height: 28px;
}

.customer-block-two .inner-box .text {
  font-size: 14px;
  margin-bottom: 8px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

/*** 

====================================================================
    Fun Fact Section
====================================================================

***/
.boxcar-fun-fact-section {
  position: relative;
}

.boxcar-fun-fact-section .fact-counter {
  border-bottom: 1px solid #e1e1e1;
  padding: 55px 156px 29px;
}

.counter-block {
  position: relative;
  margin-bottom: 30px;
}

.counter-block .inner {
  text-align: center;
  position: relative;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}

.counter-block .inner:hover .icon {
  background-color: #ffffff;
  color: var(--bg-theme-color1);
  border-radius: 50%;
}

.counter-block .content {
  position: relative;
}

@media (max-width: 575.98px) {
  .counter-block .content {
    padding-left: 0;
    text-align: center;
  }
}

.counter-block .icon {
  position: absolute;
  left: 35px;
  top: 5px;
  color: var(--theme-color1);
  font-size: 48px;
  line-height: 1em;
  border-radius: 5px;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}

@media (max-width: 575.98px) {
  .counter-block .icon {
    position: relative;
    left: 0;
    top: 0;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    margin-bottom: 10px;
  }
}

.counter-block .widget-counter {
  font-size: 38px;
  line-height: 58px;
  font-weight: 600;
  font-family: var(--title-font);
  color: var(--theme-color-dark);
  letter-spacing: -1.92px;
  margin-bottom: 1px;
}

.counter-block .widget-counter .count-text {
  font-size: 38px;
  line-height: 58px;
  font-weight: 600;
  font-family: var(--title-font);
  color: var(--theme-color-dark);
}

.counter-block .counter-title {
  font-size: 15px;
  line-height: 28px;
  font-weight: 400;
  margin-bottom: 0;
  text-transform: uppercase;
}

/*** 

====================================================================
    Fun Fact Section
====================================================================

***/
.boxcar-fun-fact-section-two {
  position: relative;
}

.boxcar-fun-fact-section-two .fact-counter {
  border-radius: 16px;
  background: #EEF1FB;
  padding: 104px 158px 79px;
}

.counter-block-two {
  position: relative;
  margin-bottom: 30px;
}

.counter-block-two .inner {
  text-align: center;
  position: relative;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}

.counter-block-two .inner:hover .icon {
  background-color: #ffffff;
  color: var(--bg-theme-color1);
  border-radius: 50%;
}

.counter-block-two .content {
  position: relative;
}

@media (max-width: 575.98px) {
  .counter-block-two .content {
    padding-left: 0;
    text-align: center;
  }
}

.counter-block-two .widget-counter {
  font-size: 38px;
  line-height: 58px;
  font-weight: 600;
  font-family: var(--title-font);
  color: var(--theme-color1);
  letter-spacing: -1.92px;
  margin-bottom: 1px;
}

.counter-block-two .widget-counter .count-text {
  font-size: 38px;
  line-height: 58px;
  font-weight: 600;
  font-family: var(--title-font);
  color: var(--theme-color1);
}

.counter-block-two .counter-title {
  font-size: 15px;
  line-height: 28px;
  font-weight: 400;
  margin-bottom: 0;
  color: var(--theme-color1);
  text-transform: uppercase;
}

/*** 

====================================================================
    Fun Fact Section
====================================================================

***/
.boxcar-fun-fact-section-three {
  position: relative;
  border-bottom: 1px solid #e1e1e1;
  padding: 55px 156px 29px;
}

/*** 

====================================================================
    news-letter-section
====================================================================

***/
.news-letter-section {
  position: relative;
  padding: 120px 0;
}

.news-letter-section .image-column .inner-column {
  background: #3551D7;
  padding: 53px 66px 52px 25px;
}

.news-letter-section .form-column .inner-column {
  background: var(--theme-color1);
  padding: 81px 0 81px 255px;
}

.news-letter-section .form-column .inner-column .boxcar-title {
  margin-bottom: 31px;
}

.news-letter-section .form-column .inner-column .boxcar-title h2 {
  margin-bottom: 2px;
}

.news-letter-section .form-column .inner-column .boxcar-title .text {
  margin-top: 9px;
}

.news-letter-section .form-column .inner-column .subscribe-form .form-group {
  position: relative;
  display: inline-block;
}

.news-letter-section .form-column .inner-column .subscribe-form .form-group input {
  width: 567px;
  height: 80px;
  border-radius: 12px;
  background: #3551D7;
  padding: 0 36px;
  font-size: 15px;
}

.news-letter-section .form-column .inner-column .subscribe-form .form-group input::-webkit-input-placeholder {
  color: var(--theme-color-light);
}

.news-letter-section .form-column .inner-column .subscribe-form .form-group input::-moz-placeholder {
  color: var(--theme-color-light);
}

.news-letter-section .form-column .inner-column .subscribe-form .form-group input:-ms-input-placeholder {
  color: var(--theme-color-light);
}

.news-letter-section .form-column .inner-column .subscribe-form .form-group input::-ms-input-placeholder {
  color: var(--theme-color-light);
}

.news-letter-section .form-column .inner-column .subscribe-form .form-group input::placeholder {
  color: var(--theme-color-light);
}

.news-letter-section .form-column .inner-column .subscribe-form .form-group button {
  position: absolute;
  top: 8px;
  right: 10px;
  font-size: 15px;
  color: var(--theme-color1);
  background-color: var(--theme-color-light);
  padding: 19px 43px 17px;
  border-radius: 16px;
  text-transform: capitalize;
}

/*** 

====================================================================
    service section
====================================================================

***/
.boxcar-service-section {
  position: relative;
  padding: 120px 0;
}

.boxcar-service-section .right-box {
  position: relative;
  border-radius: 16px;
  background: #F5F7FE;
  padding: 98px 151px 110px;
}

.boxcar-service-section .content-column .inner-column .title {
  font-weight: 700;
  line-height: 55px;
  text-transform: capitalize;
  margin-bottom: 27px;
}

.boxcar-service-section .content-column .inner-column .text {
  margin-bottom: 40px;
  font-size: 15px;
}

.boxcar-service-section .content-column .inner-column .store {
  position: relative;
  background-color: #161d30;
  display: inline-block;
  padding: 6px 40px 11px 81px;
  border-radius: 16px;
}

.boxcar-service-section .content-column .inner-column .store img {
  position: absolute;
  top: 15px;
  left: 20px;
}

.boxcar-service-section .content-column .inner-column .store span {
  color: var(--theme-color-light);
  font-size: 13px;
  display: block;
}

.boxcar-service-section .content-column .inner-column .store .title {
  position: relative;
  font-size: 15px;
  color: var(--theme-color-light);
  font-weight: 500;
  margin-top: -4px;
  margin-bottom: 0;
  line-height: 20px;
}

.boxcar-service-section .content-column .inner-column .store .title::before {
  content: "";
  position: absolute;
  top: -15px;
  left: -21px;
  width: 1px;
  height: 31px;
  background-color: #505463;
}

.boxcar-service-section .content-column .inner-column .store.two {
  padding-right: 60px;
  margin-left: 25px;
}

.boxcar-service-section .image-column .inner-column .image-box {
  position: absolute;
  bottom: 0;
  right: 0;
}

.boxcar-service-section.v7 .right-box {
  background-color: unset;
  padding: 0;
}

.boxcar-service-section.v7 .right-box .content-column .inner-column {
  margin-top: 147px;
  margin-right: 115px;
}

.boxcar-service-section.v7 .right-box .image-column .image-box {
  position: static;
}

.boxcar-service-section.v7 .right-box .image-column .image-box img {
  border-radius: 16px;
}

/*** 

====================================================================
    service section two
====================================================================

***/
.boxcar-service-section-two {
  position: relative;
  padding: 120px 0;
}

.boxcar-service-section-two .right-box {
  position: relative;
  border-radius: 16px;
  background-color: var(--theme-color1);
  padding: 98px 151px 110px;
}

.boxcar-service-section-two .content-column .inner-column .title {
  font-weight: 700;
  line-height: 55px;
  text-transform: capitalize;
  margin-bottom: 27px;
  color: var(--theme-color-light);
}

.boxcar-service-section-two .content-column .inner-column .text {
  margin-bottom: 40px;
  font-size: 15px;
  color: var(--theme-color-light);
}

.boxcar-service-section-two .content-column .inner-column .store {
  position: relative;
  background-color: var(--theme-color-light);
  display: inline-block;
  padding: 6px 40px 11px 81px;
  border-radius: 16px;
}

.boxcar-service-section-two .content-column .inner-column .store img {
  position: absolute;
  top: 15px;
  left: 20px;
}

.boxcar-service-section-two .content-column .inner-column .store span {
  color: var(--theme-color1);
  font-size: 13px;
  display: block;
}

.boxcar-service-section-two .content-column .inner-column .store .title {
  position: relative;
  font-size: 15px;
  color: var(--theme-color1);
  font-weight: 500;
  margin-top: -4px;
  margin-bottom: 0;
  line-height: 20px;
}

.boxcar-service-section-two .content-column .inner-column .store .title::before {
  content: "";
  position: absolute;
  top: -15px;
  left: -21px;
  width: 1px;
  height: 31px;
  background-color: var(--theme-color1);
}

.boxcar-service-section-two .content-column .inner-column .store.two {
  padding-right: 60px;
  margin-left: 25px;
}

.boxcar-service-section-two .image-column .inner-column .image-box {
  position: absolute;
  bottom: 0;
  right: 0;
}

/*** 

====================================================================
    service section three
====================================================================

***/
.services-section-three {
  position: relative;
  padding: 120px 0 160px;
}

.services-section-three .slick-prev,
.services-section-three .slick-next {
  left: 15px;
  top: auto;
  bottom: -30px;
  -webkit-transform: unset;
  transform: unset;
}

.services-section-three .slick-prev:hover,
.services-section-three .slick-next:hover {
  background-color: var(--theme-color-dark);
}

.services-section-three .slick-next {
  right: auto;
  left: 95px;
}

.service-block-three {
  margin: 0 15px 50px;
}

.service-block-three .inner-box {
  border-radius: 16px;
  background: var(--theme-color-light);
  -webkit-box-shadow: 0px 10px 40px 0px rgba(0, 0, 0, 0.05);
  box-shadow: 0px 10px 40px 0px rgba(0, 0, 0, 0.05);
}

.service-block-three .inner-box .image-box .image img {
  border-radius: 16px 16px 0 0;
}

.service-block-three .inner-box .content-box {
  padding: 24px 30px 21px;
}

.service-block-three .inner-box .content-box .title {
  font-weight: 500;
  margin-bottom: 5px;
}

.service-block-three .inner-box .content-box .text {
  font-size: 15px;
  margin-bottom: 15px;
}

.service-block-three .inner-box .content-box .ser-btn {
  font-size: 15px;
  font-weight: 500;
  display: inline-block;
}

.service-block-three .inner-box .content-box .ser-btn svg {
  margin-left: 9px;
  position: relative;
  top: -1px;
}

/*** 

====================================================================
    service inner-section
====================================================================

***/
.services-inner-section {
  background-color: var(--theme-color-light);
  position: relative;
  padding: 52px 0 120px;
  border-radius: 80px;
}

.services-inner-section .boxcar-pricing-section-two.v2 .content-column .inner-column {
  max-width: 600px;
}

.services-inner-section .why-choose-us-section.v3 {
  padding: 0;
}

.services-inner-section .why-choose-us-section.v3 .right-box {
  padding: 120px 150px 64px;
  background-color: #F9FBFC;
  border-radius: 16px;
  margin: 0 -150px;
}

.services-inner-section .calculater-sec .content-column .inner-column {
  width: 100%;
  height: 100%;
  border-radius: 16px;
  border: 1px solid #E1E1E1;
  background: #F9FBFC;
  padding: 51px 47px 52px;
}

.services-inner-section .calculater-sec .content-column .inner-column .boxcar-title {
  padding-left: 13px;
}

.services-inner-section .calculater-sec .content-column .inner-column .boxcar-title p {
  font-size: 15px;
  max-width: 685px;
  color: var(--theme-color-dark);
}

.services-inner-section .calculater-sec .content-column .inner-column .text {
  line-height: 24px;
  color: var(--theme-color-dark);
  font-size: 14px;
  padding: 23px 13px 0;
}

.services-inner-section .calculater-sec .content-column form {
  width: 100%;
  margin: 0;
}

.services-inner-section .calculater-sec .content-column form .form_boxes {
  border-radius: 12px;
  border: 1px solid var(--Border, #E1E1E1);
  background: var(--color-white, #FFF);
  border-radius: 12px;
  padding: 7px 15px;
  margin-bottom: 30px;
}

.services-inner-section .calculater-sec .content-column form .form_boxes label {
  display: block;
  color: #818181;
  font-size: 13px;
  font-weight: 400;
}

.services-inner-section .calculater-sec .content-column form .form_boxes .drop-menu {
  height: unset;
}

.services-inner-section .calculater-sec .content-column form .form_boxes .drop-menu .select {
  padding: 0;
  line-height: unset;
}

.services-inner-section .calculater-sec .content-column form .form_boxes .drop-menu .dropdown {
  padding: 5px 15px;
  margin-top: 10px;
  border-radius: 12px;
  left: -15px;
  width: calc(100% + 30px);
  background-color: var(--theme-color-light);
  -webkit-box-shadow: 30px 30px 60px 0px rgba(0, 0, 1, 0.05);
  box-shadow: 30px 30px 60px 0px rgba(0, 0, 1, 0.05);
}

.services-inner-section .calculater-sec .content-column form .form_boxes.v2 {
  height: 220px;
}

.services-inner-section .calculater-sec .content-column form .theme-btn {
  background: #405FF2;
  color: #fff;
  height: 54px;
  line-height: 54px;
  border-radius: 12px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 0 26px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.services-inner-section .calculater-sec .content-column form .theme-btn svg {
  margin-left: 10px;
}

.services-inner-section .calculater-sec .content-column form .theme-btn.v2 {
  width: 100%;
}

.services-inner-section .calculater-sec .timing-block {
  padding-left: 64px;
}

.services-inner-section .calculater-sec .timing-block .inner-box {
  border-radius: 16px;
  border: 1px solid #E1E1E1;
  background: var(--theme-color-light);
  padding: 23px 29px 20px;
}

.services-inner-section .calculater-sec .timing-block .inner-box .title {
  font-weight: 500;
  margin-bottom: 17px;
}

.services-inner-section .calculater-sec .timing-block .inner-box .timing-list li {
  font-size: 15px;
  font-weight: 500;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-bottom: 13px;
}

.services-inner-section .calculater-sec .timing-block .inner-box .timing-list li:last-child {
  margin-bottom: 0;
}

.services-inner-section .calculater-sec .timing-block .inner-box .timing-list li span {
  font-weight: 400;
}

/*** 

====================================================================
    map-section
====================================================================

***/
.map-section {
  position: relative;
}

.map-section .goole-iframe iframe {
  width: 100%;
  height: 698px;
}

.map-section .map-box {
  position: absolute;
  top: 97px;
  background-color: var(--theme-color-light);
  -webkit-box-shadow: 0px 10px 40px 0px rgba(0, 0, 0, 0.05);
  box-shadow: 0px 10px 40px 0px rgba(0, 0, 0, 0.05);
  border-radius: 16px;
  width: 448px;
  padding: 38px 50px 30px;
}

.map-section .map-box .title {
  font-weight: 700;
  margin-bottom: 9px;
}

.map-section .map-box .text {
  font-size: 15px;
  margin-bottom: 30px;
}

.map-section .map-box .btn-two {
  font-size: 15px;
  font-style: normal;
  display: inline-block;
  font-weight: 500;
  line-height: 28px;
  color: var(--theme-color-dark);
  border-radius: 60px;
  padding: 7px 29px 7px;
  border: 1px solid var(--theme-color-dark);
  margin-right: 20px;
}

.map-section .map-box .btn-two:last-child {
  margin-right: 0;
}

.map-section .map-box .btn-two svg {
  fill: var(--theme-color-dark);
  width: 26px;
  height: 26px;
  margin-right: 8px;
}

.map-section .map-box .shaduel-list {
  margin-top: 29px;
}

.map-section .map-box .shaduel-list li {
  font-size: 15px;
  line-height: 35px;
}

/*** 

====================================================================
    vehicles section
====================================================================

***/
.vehicles-section {
  position: relative;
  padding: 52px 0 60px;
  background-color: var(--theme-color-dark);
}

.vehicles-section .nav-tabs {
  border: 0;
  margin-bottom: 79px;
}

.vehicles-section .nav {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.vehicles-section .nav-tabs .nav-link.active {
  background-color: unset;
  border: 1px solid rgba(255, 255, 255, 0.3);
  padding: 7px 20px 5px;
  border-radius: 50px;
  color: var(--theme-color-light);
  font-weight: 500;
}

.vehicles-section .nav-tabs .nav-link {
  border: 0;
  margin-right: 38px;
  color: var(--theme-color-light);
}

.vehicles-section .nav-tabs .nav-link:last-child {
  margin-right: 0;
}

.vehicles-section .right-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: 0 15px;
}

.vehicles-section .right-box .vehicle-block {
  padding: 0 40px;
}

.vehicles-section .right-box .vehicle-block:last-child {
  margin-right: 0;
}

.vehicles-section .right-box .vehicle-block .inner-box .image-box {
  margin-bottom: 23px;
}

.vehicles-section .right-box .vehicle-block .inner-box .content-box {
  text-align: center;
}

.vehicles-section .right-box .vehicle-block .inner-box .content-box .title {
  color: #fff;
  font-weight: 500;
  margin-bottom: 0;
}

/*** 

====================================================================
    vehicles section
====================================================================

***/
.vehicles-section-two {
  position: relative;
  padding: 120px 0;
}

.vehicles-section-two .boxcar-title {
  margin-bottom: 29px;
}

.vehicles-section-two .nav-tabs {
  border: 0;
  margin-bottom: 79px;
}

.vehicles-section-two .nav {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.vehicles-section-two .nav-tabs .nav-link.active {
  background-color: unset;
  border: 1px solid rgba(255, 255, 255, 0.3);
  padding: 7px 20px 5px;
  border-radius: 50px;
  color: var(--theme-color-light);
  font-weight: 500;
}

.vehicles-section-two .nav-tabs .nav-link {
  border: 0;
  margin-right: 38px;
  color: var(--theme-color-light);
}

.vehicles-section-two .nav-tabs .nav-link:last-child {
  margin-right: 0;
}

.vehicles-section-two .Vehicle-block .inner-box .image-box {
  margin-bottom: 0;
}

.vehicles-section-two .Vehicle-block .inner-box .image-box .image img {
  width: 100%;
}

.vehicles-section-two .Vehicle-block .inner-box .content-box {
  text-align: center;
  position: relative;
  top: -10px;
}

.vehicles-section-two .Vehicle-block .inner-box .content-box .title {
  color: var(--theme-color-dark);
  font-weight: 500;
  margin-bottom: 0;
}

/*** 

====================================================================
    vehicles section three
====================================================================

***/
.vehicles-section-three {
  background-color: var(--theme-color-light);
  position: relative;
  padding: 52px 0 120px;
  border-radius: 80px;
}

.vehicles-section-three .cars-section-four.team {
  padding: 52px 0 0;
}

.vehicles-section-three .cars-section-four.team .boxcar-title {
  margin-bottom: 12px;
}

.vehicles-section-three .cars-section-four.team .boxcar-title h2 {
  margin-bottom: 23px;
}

.vehicles-section-three .boxcar-title {
  margin-bottom: 51px;
  max-width: 445px;
}

.vehicles-section-three .boxcar-title .breadcrumb {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 12px;
}

.vehicles-section-three .boxcar-title .breadcrumb li {
  font-size: 15px;
  margin-right: 21px;
  position: relative;
}

.vehicles-section-three .boxcar-title .breadcrumb li::before {
  content: "/";
  position: absolute;
  top: 0;
  right: -14px;
}

.vehicles-section-three .boxcar-title .breadcrumb li:last-child {
  margin-right: 0;
}

.vehicles-section-three .boxcar-title .breadcrumb li:last-child::before {
  display: none;
}

.vehicles-section-three .boxcar-title .breadcrumb li a {
  color: var(--theme-color1);
}

.vehicles-section-three .boxcar-title h2 {
  margin-bottom: 36px;
}

.vehicles-section-three .boxcar-title .text {
  font-size: 40px;
  font-weight: 700;
  line-height: 55px;
}

.vehicles-section-three .right-box .auther-info-box {
  border-radius: 16px;
  border: 1px solid var(--Border, #E1E1E1);
  background: var(--bg-1, #F9FBFC);
  margin: 0 -150px;
  padding: 25px 70px 0;
}

.vehicles-section-three .right-box .auther-info-box .inner-column {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.vehicles-section-three .right-box .auther-info-box .inner-column .auther-info {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.vehicles-section-three .right-box .auther-info-box .inner-column .auther-info .content-box {
  max-width: 470px;
}

.vehicles-section-three .right-box .auther-info-box .inner-column .auther-info .content-box .title {
  font-size: 30px;
  font-weight: 500;
  margin-bottom: 0;
  line-height: 44px;
}

.vehicles-section-three .right-box .auther-info-box .inner-column .auther-info .content-box span {
  font-size: 15px;
  line-height: 26px;
  margin-bottom: 15px;
  display: block;
}

.vehicles-section-three .right-box .auther-info-box .inner-column .auther-info .content-box .text {
  margin-bottom: 18px;
}

.vehicles-section-three .right-box .auther-info-box .inner-column .auther-info .content-box .contact-info {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.vehicles-section-three .right-box .auther-info-box .inner-column .auther-info .content-box .contact-info li {
  position: relative;
  padding-left: 36px;
  margin-right: 30px;
}

.vehicles-section-three .right-box .auther-info-box .inner-column .auther-info .content-box .contact-info li:last-child {
  margin-right: 0;
}

.vehicles-section-three .right-box .auther-info-box .inner-column .auther-info .content-box .contact-info li a {
  line-height: 28px;
  font-size: 15px;
  font-family: var(--text-font);
}

.vehicles-section-three .right-box .auther-info-box .inner-column .auther-info .content-box .contact-info li a .icon {
  position: absolute;
  top: 0;
  left: 0;
  font-size: 26px;
}

.vehicles-section-three .right-box .auther-info-box .inner-column .auther-info .btn-box {
  margin-left: 325px;
}

.vehicles-section-three .right-box .auther-info-box .inner-column .auther-info .btn-box .button {
  font-weight: 500;
  font-size: 15px;
  font-family: var(--title-font);
  line-height: 26px;
  text-transform: capitalize;
  padding: 13px 62px 12px;
  border-radius: 12px;
  border: 1px solid var(--color-secondary, var(--theme-color1));
  background: var(--color-secondary, var(--theme-color1));
  display: inline-block;
  color: var(--theme-color-light);
}

.vehicles-section-three .right-box .auther-info-box .inner-column .auther-info .btn-box .button svg {
  position: relative;
  top: -4px;
  color: var(--theme-color-light);
  width: 14px;
  height: 14px;
  margin-left: 10px;
}

/*** 

====================================================================
    team section
====================================================================

***/
.boxcar-team-section {
  position: relative;
  padding: 120px 0;
}

.boxcar-team-section .pagination-sec {
  text-align: center;
}

.boxcar-team-section .pagination-sec .pagination {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.boxcar-team-section .pagination-sec .pagination li:first-child a,
.boxcar-team-section .pagination-sec .pagination li:last-child a {
  border: 1px solid #e1e1e1;
  width: 60px;
  font-size: 30px;
  line-height: 35px;
}

.boxcar-team-section .pagination-sec .pagination li a {
  font-size: 15px;
  margin: 0 5px;
  width: 40px;
  height: 40px;
  border-radius: 50px;
  text-align: center;
  font-weight: 500;
  padding: 0;
  line-height: 40px;
  border: 0;
  color: var(--theme-color-dark);
}

.boxcar-team-section .pagination-sec .pagination li a:hover {
  background-color: #f9fbfc;
}

.boxcar-team-section .pagination-sec .text {
  font-size: 14px;
  margin-top: 19px;
}

.boxcar-team-section .boxcar-title {
  margin-bottom: 51px;
}

.boxcar-team-section .boxcar-title .breadcrumb {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 12px;
}

.boxcar-team-section .boxcar-title .breadcrumb li {
  font-size: 15px;
  margin-right: 21px;
  position: relative;
}

.boxcar-team-section .boxcar-title .breadcrumb li::before {
  content: "/";
  position: absolute;
  top: 0;
  right: -14px;
}

.boxcar-team-section .boxcar-title .breadcrumb li:last-child {
  margin-right: 0;
}

.boxcar-team-section .boxcar-title .breadcrumb li:last-child::before {
  display: none;
}

.boxcar-team-section .boxcar-title .breadcrumb li a {
  color: var(--theme-color1);
}

.boxcar-team-section .boxcar-title .service-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.boxcar-team-section .boxcar-title .service-list li {
  margin-right: 10px;
}

.boxcar-team-section .boxcar-title .service-list li:last-child {
  margin-right: 0;
}

.boxcar-team-section .boxcar-title .service-list li a {
  font-size: 15px;
  border: 1px solid #e1e1e1;
  border-radius: 50px;
  padding: 13px 25px 11px;
  display: inline-block;
}

.boxcar-team-section .boxcar-title .service-list li a:hover {
  background-color: #e9f2ff;
  border-color: #e9f2ff;
}

.boxcar-team-section .boxcar-title h2 {
  margin-bottom: 21px;
}

.boxcar-team-section.v1 {
  position: relative;
  background-color: var(--theme-color-light);
  padding: 52px 0 120px;
  border-radius: 80px;
}

.team-block {
  margin-bottom: 60px;
}

.team-block .inner-box:hover .image-box .image {
  border-radius: 16px;
}

.team-block .inner-box:hover .image-box .image img {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.team-block .inner-box:hover .image-box .contact-info {
  opacity: 1;
  visibility: visible;
  bottom: 30px;
}

.team-block .inner-box .image-box {
  position: relative;
}

.team-block .inner-box .image-box .image {
  border-radius: 16px;
  overflow: hidden;
}

.team-block .inner-box .image-box .image img {
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  width: 100%;
}

.team-block .inner-box .image-box .contact-info {
  position: absolute;
  bottom: 0px;
  left: 30px;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.team-block .inner-box .image-box .contact-info span {
  margin-bottom: 10px;
}

.team-block .inner-box .image-box .contact-info span,
.team-block .inner-box .image-box .contact-info small {
  font-size: 14px;
  display: block;
  line-height: 35px;
  background-color: var(--theme-color-light);
  height: 35px;
  padding: 0 20px;
  border-radius: 50px;
}

.team-block .inner-box .content-box {
  padding-top: 22px;
}

.team-block .inner-box .content-box .title {
  font-weight: 500;
  line-height: 30px;
  font-size: 20px;
  margin-bottom: 3px;
}

.team-block .inner-box .content-box span {
  font-size: 14px;
}

/*** 

====================================================================
    team section two
====================================================================

***/
.boxcar-team-section-two {
  position: relative;
  padding: 120px 0;
}

.team-block-two .inner-box:hover .image-box .image {
  border-radius: 16px;
}

.team-block-two .inner-box:hover .image-box .image img {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.team-block-two .inner-box:hover .image-box .contact-info {
  opacity: 1;
  visibility: visible;
  bottom: 30px;
}

.team-block-two .inner-box .image-box {
  position: relative;
}

.team-block-two .inner-box .image-box .image {
  border-radius: 16px;
  overflow: hidden;
}

.team-block-two .inner-box .image-box .image img {
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  width: 100%;
}

.team-block-two .inner-box .image-box .contact-info {
  position: absolute;
  bottom: 0px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  opacity: 0;
  visibility: hidden;
  text-align: center;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.team-block-two .inner-box .image-box .contact-info span {
  margin-bottom: 10px;
}

.team-block-two .inner-box .image-box .contact-info span,
.team-block-two .inner-box .image-box .contact-info small {
  font-size: 14px;
  display: block;
  line-height: 35px;
  background-color: var(--theme-color-light);
  height: 35px;
  padding: 0 20px;
  border-radius: 50px;
}

.team-block-two .inner-box .content-box {
  position: absolute;
  top: 22px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  text-align: center;
}

.team-block-two .inner-box .content-box .title {
  font-weight: 500;
  line-height: 30px;
  font-size: 20px;
  margin-bottom: 3px;
}

.team-block-two .inner-box .content-box span {
  font-size: 14px;
  white-space: pre;
}

/*** 

====================================================================
    team section three
====================================================================

***/
.boxcar-team-section-three {
  padding: 120px 0 90px;
}

.boxcar-team-section-three .right-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.team-block-three {
  margin-right: 25px;
  margin-bottom: 30px;
}

.team-block-three:last-child {
  margin-right: 0;
}

.team-block-three .inner-box .image-box {
  position: relative;
}

.team-block-three .inner-box .image-box .content-box {
  position: absolute;
  top: 31px;
  left: 31px;
}

.team-block-three .inner-box .image-box .content-box span {
  font-size: 15px;
  color: var(--theme-color-light);
  margin-bottom: 2px;
  display: inline-block;
}

.team-block-three .inner-box .image-box .content-box .title {
  margin-bottom: 0;
  font-size: 18px;
  color: var(--theme-color-light);
  font-weight: 500;
}

/*** 

====================================================================
    team section four
====================================================================

***/
.boxcar-team-section-four {
  position: relative;
  padding: 120px 0;
}

.boxcar-team-section-four .right-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.team-block-four {
  margin-right: 25px;
}

.team-block-four:last-child {
  margin-right: 0;
}

.team-block-four .inner-box:hover .image-box .image {
  border-radius: 16px;
}

.team-block-four .inner-box:hover .image-box .image img {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.team-block-four .inner-box:hover .image-box .contact-info {
  opacity: 1;
  visibility: visible;
  bottom: 30px;
}

.team-block-four .inner-box .image-box {
  position: relative;
}

.team-block-four .inner-box .image-box .image {
  border-radius: 16px;
  overflow: hidden;
}

.team-block-four .inner-box .image-box .image img {
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  width: 100%;
}

.team-block-four .inner-box .image-box .contact-info {
  position: absolute;
  bottom: 0px;
  left: 30px;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.team-block-four .inner-box .image-box .contact-info span {
  margin-bottom: 10px;
}

.team-block-four .inner-box .image-box .contact-info span,
.team-block-four .inner-box .image-box .contact-info small {
  font-size: 14px;
  display: block;
  line-height: 35px;
  background-color: var(--theme-color-light);
  height: 35px;
  padding: 0 20px;
  border-radius: 50px;
}

.team-block-four .inner-box .content-box {
  padding-top: 22px;
}

.team-block-four .inner-box .content-box .title {
  font-weight: 500;
  line-height: 30px;
  font-size: 20px;
  margin-bottom: 3px;
}

.team-block-four .inner-box .content-box span {
  font-size: 14px;
}

.boxcar-title .btn-title {
  /* Small devices */
}

@media (max-width: 767px) {
  .boxcar-title .btn-title {
    display: none;
  }
}

.widget-accordion {
  position: relative;
}

.widget-accordion .accordion.block:active {
  background-color: unset;
}

.widget-accordion .block {
  position: relative;
  margin-bottom: 20px;
}

.widget-accordion .block:last-child {
  margin-bottom: 0;
}

.widget-accordion .block .acc-btn {
  position: relative;
  font-size: 18px;
  line-height: 28px;
  font-weight: 600;
  color: var(--theme-color-dark);
  cursor: pointer;
  border: 1px solid #202020;
  letter-spacing: -0.8px;
  padding: 39px 40px 0;
  padding-right: 70px;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

@media (max-width: 1199.98px) {
  .widget-accordion .block .acc-btn {
    padding-left: 18px;
  }
}

.widget-accordion .block .acc-btn .icon {
  position: absolute;
  right: 0px;
  top: 0px;
  height: 105px;
  width: 58px;
  font-size: 16px;
  line-height: 105px;
  text-align: center;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.widget-accordion .block .acc-btn:active {
  color: var(--theme-color-dark);
}

.widget-accordion .block .acc-btn.active {
  border: 0;
  padding-bottom: 15px;
}

.widget-accordion .block .acc-btn.active .icon {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

.widget-accordion .block .acc-content {
  position: relative;
  display: none;
  padding: 0 0 40px;
}

.widget-accordion .block .acc-content .content {
  padding: 0px 40px 0;
}

.widget-accordion .block .acc-content .content .text {
  margin-bottom: 0;
  color: var(--text-color);
  text-transform: lowercase;
}

.widget-accordion .block .acc-content.current {
  display: block;
}

.widget-accordion.style-two .block .acc-btn {
  background-color: var(--theme-color-light);
  padding: 20px 20px;
  padding-right: 60px;
  line-height: 30px;
}

.widget-accordion.style-two .block .acc-btn .icon {
  right: 20px;
  top: 20px;
  height: 30px;
  width: 30px;
  font-size: 14px;
  line-height: 30px;
  text-align: center;
  border-radius: 50%;
  color: var(--theme-color-light);
  background-color: var(--theme-color1);
}

.widget-accordion.style-two .block .acc-btn:active {
  color: var(--theme-color-dark);
}

.widget-accordion.style-two .block .acc-content .content {
  padding: 22px 0px 0;
}

.widget-accordion.style-three .block {
  background-color: var(--theme-color-light);
  border-radius: 10px;
  margin-bottom: 30px;
}

.widget-accordion.style-three .block:last-child {
  margin-bottom: 0;
}

.widget-accordion.style-three .block .acc-btn {
  background-color: var(--theme-color-light);
  padding: 20px 30px;
  font-size: 20px;
  line-height: 30px;
}

.widget-accordion.style-three .block .acc-btn .icon {
  right: 20px;
  top: 20px;
  height: 30px;
  width: 30px;
  font-size: 14px;
  line-height: 30px;
  text-align: center;
  border-radius: 50%;
  color: var(--theme-color-light);
  background-color: var(--theme-color1);
}

.widget-accordion.style-three .block .acc-btn:active {
  color: var(--theme-color-dark);
}

.widget-accordion.style-three .block .acc-content .content {
  padding: 20px 30px 20px;
}

.widget-accordion.style-four .block {
  background-color: var(--theme-color-light);
  border-bottom: 1px solid var(--theme-color-gray);
  margin-bottom: 0px;
}

.widget-accordion.style-four .block .acc-btn {
  background-color: transparent;
  padding: 20px 0px;
}

.widget-accordion.style-four .block .acc-btn .icon {
  right: 0px;
  top: 20px;
  height: 30px;
  line-height: 30px;
  width: auto;
  color: var(--theme-color1);
  background-color: transparent;
}

.widget-accordion.style-four .block .acc-btn.active {
  color: var(--theme-color2);
  border-radius: 10px 10px 0 0;
}

.widget-accordion.style-four .block .acc-btn.active .icon {
  background-color: var(--theme-color-light);
  color: var(--theme-color1);
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}

.widget-accordion.style-four .block .acc-btn.active .icon:before {
  content: "\f101";
}

.widget-accordion.style-four .block .acc-content .content {
  padding: 0px 0px 20px;
  background-color: var(--theme-color-light);
}

/*** 

====================================================================
    faq-inner-section
====================================================================

***/
.faq-inner-section {
  position: relative;
  padding: 120px 0;
  background-color: var(--theme-color-light);
  position: relative;
  border-radius: 80px;
}

.faq-inner-section .faqs-section {
  padding-top: 120px;
}

.faq-inner-section .faqs-section .inner-container {
  max-width: 954px;
  margin: 0 auto;
}

.faq-inner-section .faqs-section .accordion.block.active-block {
  background-color: #F9FBFC;
}

.faq-inner-section .faqs-section .widget-accordion .block .acc-btn {
  font-size: 20px;
  font-family: var(--title-font);
  font-weight: 500;
  text-transform: capitalize;
  color: var(--theme-color-dark);
  border: 0;
}

.faq-inner-section .faqs-section .widget-accordion .block .acc-content .content .text {
  font-size: 15px;
  line-height: 28px;
}

.faq-inner-section .faqs-section .widget-accordion .block .acc-btn {
  background-color: unset;
}

.faq-inner-section .faqs-section .widget-accordion .block.active-block .acc-btn {
  color: var(--theme-color-dark);
}

/*** 

====================================================================
    tabs-section
====================================================================

***/
.tabs-section {
  background-color: var(--theme-color-light);
  position: relative;
  padding: 52px 0 120px;
  border-radius: 80px;
}

.tabs-section .nav-tabs .nav-link {
  background-color: unset;
  border: 0;
  padding: 0;
  font-size: 15px;
  font-weight: 500;
  line-height: 45px;
  color: var(--theme-color-dark);
}

.tabs-section .nav-tabs .nav-link:active {
  color: var(--theme-color1);
}

.tabs-section .nav-link {
  padding-left: 19px !important;
  border-radius: 0;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.tabs-section .nav-link.active {
  border-left: 1px solid var(--theme-color1);
}

.tabs-section .nav {
  position: relative;
  display: unset;
}

.tabs-section .nav::before {
  content: "";
  position: absolute;
  top: 13px;
  left: 0;
  width: 1px;
  height: 211px;
  background-color: #E1E1E1;
}

.tabs-section .nav-tabs .nav-item.show .nav-link,
.tabs-section .nav-tabs .nav-link.active {
  color: var(--theme-color1);
}

.tabs-section .right-box .content-box {
  margin-bottom: 47px;
}

.tabs-section .right-box .content-box:last-child {
  margin-bottom: 0;
}

.tabs-section .right-box .content-box .title {
  font-weight: 500;
  margin-bottom: 17px;
}

.tabs-section .right-box .content-box .text {
  font-size: 15px;
}

.tabs-section .right-box .content-box .text.v2 {
  margin-bottom: 26px;
}

/*** 

====================================================================
    blog section
====================================================================

***/
.boxcar-title {
  margin-bottom: 50px;
}

.boxcar-title .breadcrumb {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 12px;
}

.boxcar-title .breadcrumb li {
  font-size: 15px;
  margin-right: 21px;
}

.boxcar-title .breadcrumb li:last-child {
  margin-right: 0;
}

.boxcar-title .breadcrumb li a {
  color: var(--theme-color1);
}

.blog-section {
  position: relative;
  padding: 120px 0 90px;
}

.blog-section .boxcar-title {
  margin-bottom: 50px;
}

.blog-section .boxcar-title .breadcrumb {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 12px;
}

.blog-section .boxcar-title .breadcrumb li {
  font-size: 15px;
  margin-right: 21px;
}

.blog-section .boxcar-title .breadcrumb li:last-child {
  margin-right: 0;
}

.blog-section .boxcar-title .breadcrumb li a {
  color: var(--theme-color1);
}

.blog-section .pagination-sec {
  margin-top: 30px;
  text-align: center;
}

.blog-section .pagination-sec .pagination {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.blog-section .pagination-sec .pagination li:first-child a,
.blog-section .pagination-sec .pagination li:last-child a {
  border: 1px solid #e1e1e1;
  width: 60px;
  font-size: 30px;
  line-height: 35px;
}

.blog-section .pagination-sec .pagination li a {
  font-size: 15px;
  margin: 0 5px;
  width: 40px;
  height: 40px;
  border-radius: 50px;
  text-align: center;
  font-weight: 500;
  padding: 0;
  line-height: 40px;
  border: 0;
  color: var(--theme-color-dark);
}

.blog-section .pagination-sec .pagination li a:hover {
  background-color: #f9fbfc;
}

.blog-section .pagination-sec .text {
  font-size: 14px;
  margin-top: 19px;
}

.blog-section .nav-tabs {
  margin-bottom: 34px;
}

.blog-section .nav-tabs .nav-link {
  font-size: 16px;
  font-weight: 500;
  color: var(--theme-color-dark);
  border-top: 0;
  border-left: 0;
  border-right: 0;
  padding: 0 0 7px 0;
  margin-right: 32px;
}

.blog-section .nav-tabs .nav-link.active {
  border-bottom: 1px solid var(--theme-color1);
}

.blog-block {
  margin-bottom: 30px;
}

.blog-block .inner-box .image-box {
  position: relative;
}

.blog-block .inner-box .image-box .image img {
  border-radius: 10px;
}

.blog-block .inner-box .image-box .date {
  position: absolute;
  top: 20px;
  left: 20px;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  text-transform: capitalize;
  width: 66px;
  height: 30px;
  line-height: 10px;
  padding: 10px 15px;
  border-radius: 50px;
  background-color: var(--theme-color-light);
  color: var(--theme-color-dark);
}

.blog-block .inner-box .content-box {
  padding: 22px 0 0;
}

.blog-block .inner-box .content-box .post-info {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 2px;
}

.blog-block .inner-box .content-box .post-info li {
  position: relative;
  font-size: 15px;
  margin-right: 25px;
}

.blog-block .inner-box .content-box .post-info li:last-child {
  margin-right: 0;
}

.blog-block .inner-box .content-box .post-info li:last-child::before {
  display: none;
}

.blog-block .inner-box .content-box .post-info li::before {
  content: "";
  position: absolute;
  top: 11px;
  right: -16px;
  width: 5px;
  height: 5px;
  background-color: #e1e1e1;
  border-radius: 50%;
}

.blog-block .inner-box .content-box .title {
  font-weight: 500;
  line-height: 30px;
  margin-bottom: 0;
}

/*** 

====================================================================
    blog section two
====================================================================

***/
.blog-section-two {
  position: relative;
  padding: 120px 0;
}



.blog-blockt-two .inner-box {
  position: relative;
  border-radius: 16px;
  background: var(--bg-3, #E9F2FF);
  padding: 78px 81px 89px;
  /* Large devices */
}

@media (max-width: 1199px) {
  .blog-blockt-two .inner-box {
    padding: 50px 40px;
  }
}

.blog-blockt-two .inner-box .title {
  font-weight: 700;
  line-height: 45px;
}

.blog-blockt-two .inner-box .text {
  margin-bottom: 20px;
  max-width: 455px;
}

.blog-blockt-two .inner-box .read-more {
  padding: 14px 25px 12px;
  border-radius: 12px;
  background: var(--color-secondary, var(--theme-color1));
  display: inline-block;
  font-family: var(--title-font);
  color: var(--theme-color-light);
}

.blog-blockt-two .inner-box .read-more svg {
  position: relative;
  top: -2px;
  margin-left: 7px;
}

.blog-blockt-two .inner-box .hover-img {
  position: absolute;
  bottom: 60px;
  right: 60px;
  /* Large devices */
}

@media (max-width: 1199px) {
  .blog-blockt-two .inner-box .hover-img {
    bottom: 30px;
    right: 30px;
  }
}

.blog-blockt-two .inner-box.two {
  background: var(--bg-4, #FFE9F3);
}

.blog-blockt-two .inner-box.two .read-more {
  background-color: var(--theme-color-dark);
}

/*** 

====================================================================
    blog section three
====================================================================

***/
.blog-section-three {
  position: relative;
  padding: 120px 0;
}

.blog-blockt-three .inner-box {
  position: relative;
}

.blog-blockt-three .inner-box .hover-img {
  position: relative;
}

.blog-blockt-three .inner-box .hover-img .image {
  position: relative;
}

.blog-blockt-three .inner-box .hover-img .image:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: -webkit-gradient(linear, left top, left bottom, from(#050B20), to(rgba(5, 11, 32, 0)));
  background: linear-gradient(180deg, #050B20 0%, rgba(5, 11, 32, 0) 100%);
  opacity: 0.5;
}

.blog-blockt-three .inner-box .hover-img .image {
  border-radius: 16px;
  overflow: hidden;
}

.blog-blockt-three .inner-box .hover-img .image img {
  width: 100%;
}

.blog-blockt-three .inner-box .hover-img .content-box {
  position: absolute;
  top: 77px;
  left: 80px;
}

.blog-blockt-three .inner-box .hover-img .content-box .title {
  font-weight: 700;
  line-height: 45px;
  color: var(--theme-color-light);
}

.blog-blockt-three .inner-box .hover-img .content-box .text {
  margin-bottom: 20px;
  max-width: 455px;
  color: var(--theme-color-light);
}

.blog-blockt-three .inner-box .hover-img .content-box .read-more {
  padding: 14px 25px 12px;
  border-radius: 12px;
  background: var(--theme-color-light);
  display: inline-block;
  font-family: var(--title-font);
  color: var(--theme-color-dark);
}

.blog-blockt-three .inner-box .hover-img .content-box .read-more svg {
  position: relative;
  top: -2px;
  margin-left: 7px;
}

/*** 

====================================================================
    blog section four
====================================================================

***/
.blog-section-four {
  position: relative;
  padding: 52px 0 120px;
  border-radius: 80px;
  background-color: var(--theme-color-light);
}

.blog-section-four .boxcar-title {
  margin-bottom: 50px;
}

.blog-section-four .boxcar-title .breadcrumb {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 12px;
}

.blog-section-four .boxcar-title .breadcrumb li {
  font-size: 15px;
  margin-right: 21px;
}

.blog-section-four .boxcar-title .breadcrumb li:last-child {
  margin-right: 0;
}

.blog-section-four .boxcar-title .breadcrumb li a {
  color: var(--theme-color1);
}

.blog-section-four .pagination-sec {
  margin-top: 30px;
  text-align: center;
}

.blog-section-four .pagination-sec .pagination {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.blog-section-four .pagination-sec .pagination li:first-child a,
.blog-section-four .pagination-sec .pagination li:last-child a {
  border: 1px solid #e1e1e1;
  width: 60px;
  font-size: 30px;
  line-height: 35px;
}

.blog-section-four .pagination-sec .pagination li a {
  font-size: 15px;
  margin: 0 5px;
  width: 40px;
  height: 40px;
  border-radius: 50px;
  text-align: center;
  font-weight: 500;
  padding: 0;
  line-height: 40px;
  border: 0;
  color: var(--theme-color-dark);
}

.blog-section-four .pagination-sec .pagination li a:hover {
  background-color: #f9fbfc;
}

.blog-section-four .pagination-sec .text {
  font-size: 14px;
  margin-top: 19px;
}

.blog-section-four .sider-bar .categories-sec {
  border-radius: 16px;
  border: 1px solid #E1E1E1;
  padding: 25px 30px 16px;
  margin-bottom: 30px;
}

.blog-section-four .sider-bar .categories-sec .widget-title {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 12px;
}

.blog-section-four .sider-bar .categories-sec .categories-list li a {
  font-size: 13px;
  line-height: 35px;
  color: var(--theme-color-dark);
}

.blog-section-four .sider-bar .categories-sec .categories-list li a:hover {
  color: var(--theme-color1);
}

.blog-section-four .sider-bar .recent-post-sec {
  border-radius: 16px;
  border: 1px solid #E1E1E1;
  padding: 24px 30px 28px 30px;
  margin-bottom: 30px;
}

.blog-section-four .sider-bar .recent-post-sec .widget-title {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 22px;
}

.blog-section-four .sider-bar .recent-post-sec .pos-block {
  margin-bottom: 20px;
}

.blog-section-four .sider-bar .recent-post-sec .pos-block:last-child {
  margin-bottom: 0;
}

.blog-section-four .sider-bar .recent-post-sec .pos-block .inner-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.blog-section-four .sider-bar .recent-post-sec .pos-block .inner-box img {
  border-radius: 5px;
}

.blog-section-four .sider-bar .recent-post-sec .pos-block .inner-box .content-box {
  margin-left: 15px;
}

.blog-section-four .sider-bar .recent-post-sec .pos-block .inner-box .content-box .title {
  font-size: 15px;
  line-height: 22px;
  font-weight: 500;
  margin-bottom: 0;
}

.blog-section-four .sider-bar .recent-post-sec .pos-block .inner-box .content-box span {
  font-size: 14px;
  color: var(--theme-color-dark);
  display: inline-block;
}

.blog-section-four .sider-bar .tag-sec {
  border-radius: 16px;
  border: 1px solid #E1E1E1;
  padding: 24px 30px 16px 30px;
}

.blog-section-four .sider-bar .tag-sec .widget-title {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 24px;
}

.blog-section-four .sider-bar .tag-sec .tag-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.blog-section-four .sider-bar .tag-sec .tag-list li {
  margin-right: 10px;
  margin-bottom: 10px;
}

.blog-section-four .sider-bar .tag-sec .tag-list li:last-child {
  margin-right: 0;
}

.blog-section-four .sider-bar .tag-sec .tag-list li a {
  font-size: 13px;
  color: var(--theme-color-dark);
  font-weight: 500;
  border-radius: 50px;
  background: #E9F2FF;
  display: inline-block;
  padding: 3px 18px;
}

.blog-section-four .sider-bar .tag-sec .tag-list li a:hover {
  background-color: var(--theme-color1);
  color: var(--theme-color-light);
}

.blog-section-four .inner-column-two {
  margin-right: 120px;
}

.blog-block-four {
  margin-bottom: 60px;
}

.blog-block-four:last-child {
  margin-bottom: 30px;
}

.blog-block-four .inner-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.blog-block-four .inner-box .image-box {
  position: relative;
  max-width: 400px;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 400px;
  flex: 0 0 400px;
}

.blog-block-four .inner-box .image-box .image img {
  border-radius: 10px;
}

.blog-block-four .inner-box .image-box .date {
  position: absolute;
  top: 20px;
  left: 20px;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  text-transform: capitalize;
  width: 66px;
  height: 30px;
  line-height: 10px;
  padding: 10px 15px;
  border-radius: 50px;
  background-color: var(--theme-color-light);
  color: var(--theme-color-dark);
}

.blog-block-four .inner-box .content-box {
  margin-left: 75px;
}

.blog-block-four .inner-box .content-box .post-info {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 2px;
}

.blog-block-four .inner-box .content-box .post-info li {
  position: relative;
  font-size: 15px;
  margin-right: 25px;
}

.blog-block-four .inner-box .content-box .post-info li:last-child {
  margin-right: 0;
}

.blog-block-four .inner-box .content-box .post-info li:last-child::before {
  display: none;
}

.blog-block-four .inner-box .content-box .post-info li::before {
  content: "";
  position: absolute;
  top: 11px;
  right: -16px;
  width: 5px;
  height: 5px;
  background-color: #e1e1e1;
  border-radius: 50%;
}

.blog-block-four .inner-box .content-box .title {
  font-size: 30px;
  font-weight: 500;
  line-height: 45px;
  margin-bottom: 11px;
}

.blog-block-four .inner-box .content-box .text {
  font-size: 15px;
  line-height: 26px;
  margin-bottom: 14px;
}

.blog-block-four .inner-box .content-box .btn-two {
  font-size: 15px;
  font-weight: 500;
  display: inline-block;
}

.blog-block-four .inner-box .content-box .btn-two svg {
  margin-left: 9px;
  position: relative;
  top: -2px;
}

.blog-section.v1 {
  padding-top: 52px;
  border-radius: 80px;
  background-color: var(--theme-color-light);
}

.blog-section-four.v1 .blog-block-four .inner-box {
  display: block;
}

.blog-section-four.v1 .blog-block-four .inner-box .image-box {
  max-width: unset;
}

.blog-section-four.v1 .blog-block-four .inner-box .content-box {
  margin-left: 0;
  padding-top: 22px;
}

/*** 

====================================================================
    blog section five
====================================================================

***/
.blog-section-five {
  padding-top: 52px;
  border-radius: 80px;
  background-color: var(--theme-color-light);
}

.blog-section-five .boxcar-title {
  margin-bottom: 30px;
}

.blog-section-five .boxcar-title h2 {
  margin-bottom: 52px;
}

.blog-section-five .post-info {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 2px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.blog-section-five .post-info li {
  position: relative;
  font-size: 15px;
  margin-right: 25px;
}

.blog-section-five .post-info li img {
  border-radius: 50px;
  margin-right: 10px;
}

.blog-section-five .post-info li:last-child {
  margin-right: 0;
}

.blog-section-five .post-info li:last-child::before {
  display: none;
}

.blog-section-five .post-info li:first-child {
  margin-right: 0;
}

.blog-section-five .post-info li:first-child::before {
  display: none;
}

.blog-section-five .post-info li::before {
  content: "";
  position: absolute;
  top: 11px;
  right: -16px;
  width: 5px;
  height: 5px;
  background-color: #e1e1e1;
  border-radius: 50%;
}

.blog-section-five .right-box .content-box .outer-image {
  margin-bottom: 44px;
}

.blog-section-five .right-box .content-box .outer-image img {
  border-radius: 16px;
}

.blog-section-five .right-box .content-box .title {
  font-size: 26px;
  font-weight: 500;
  margin-bottom: 21px;
}

.blog-section-five .right-box-two {
  max-width: 924px;
  margin: 0 auto;
  position: relative;
}

.blog-section-five .right-box-two .text {
  font-size: 15px;
  line-height: 28px;
}

.blog-section-five .right-box-two .text.two {
  margin-top: 28px;
  margin-bottom: 60px;
}

.blog-section-five .right-box-two .auther-box {
  position: relative;
  border-radius: 16px;
  background: #E9F2FF;
  padding: 41px 57px 29px;
}

.blog-section-five .right-box-two .auther-box::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 16px;
  height: 100%;
  background-color: var(--theme-color1);
  border-radius: 16px 0 0 16px;
}

.blog-section-five .right-box-two .auther-box .text {
  color: var(--theme-color-dark);
  font-size: 15px;
  font-style: italic;
  font-weight: 500;
  margin-bottom: 15px;
}

.blog-section-five .right-box-two .auther-box .name {
  font-size: 17px;
  font-weight: 500;
  color: var(--theme-color-dark);
}

.blog-section-five .right-box-two .list-sec {
  margin-top: 52px;
  margin-bottom: 61px;
}

.blog-section-five .right-box-two .list-sec .inner-column {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.blog-section-five .right-box-two .list-sec .inner-column .title {
  font-size: 26px;
  font-weight: 500;
  margin-bottom: 21px;
}

.blog-section-five .right-box-two .list-sec .inner-column .list {
  margin-right: 96px;
}

.blog-section-five .right-box-two .list-sec .inner-column .list:last-child {
  margin-right: 0;
}

.blog-section-five .right-box-two .list-sec .inner-column .list li {
  position: relative;
  font-size: 15px;
  line-height: 46px;
  color: var(--color-primary, #050B20);
  font-family: var(--text-font);
  padding-left: 29px;
}

.blog-section-five .right-box-two .list-sec .inner-column .list li i {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  left: 0;
  width: 18px;
  height: 18px;
  line-height: 18px;
  text-align: center;
  border-radius: 50%;
  color: var(--theme-color1);
  font-size: 8px;
  background-color: rgb(233, 242, 255);
}

.blog-section-five .right-box-two .image-sec .image-box .inner-image img {
  border-radius: 16px;
}

.blog-section-five .right-box-two .list-sec-two {
  margin-top: 22px;
  margin-bottom: 60px;
}

.blog-section-five .right-box-two .list-sec-two .title {
  font-size: 26px;
  font-weight: 500;
  margin-bottom: 21px;
}

.blog-section-five .right-box-two .list-sec-two .list-two li {
  position: relative;
  font-size: 15px;
  line-height: 46px;
  color: var(--color-primary, #050B20);
  font-family: var(--text-font);
  padding-left: 19px;
}

.blog-section-five .right-box-two .list-sec-two .list-two li:nth-child(2)::before {
  display: none;
}

.blog-section-five .right-box-two .list-sec-two .list-two li::before {
  content: "";
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  left: 0;
  width: 5px;
  height: 5px;
  background-color: var(--theme-color-dark);
}

.blog-section-five .right-box-two .social-section {
  border-top: 1px solid #E1E1E1;
  padding: 59px 0 60px;
  border-bottom: 1px solid #E1E1E1;
}

.blog-section-five .right-box-two .social-section .inner-column {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.blog-section-five .right-box-two .social-section .inner-column .social-icons {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.blog-section-five .right-box-two .social-section .inner-column .social-icons li {
  font-size: 15px;
}

.blog-section-five .right-box-two .social-section .inner-column .social-icons li a {
  font-size: 14px;
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  border-radius: 50px;
  display: inline-block;
}

.blog-section-five .right-box-two .social-section .inner-column .social-icons li a:hover {
  background-color: #F9FBFC;
}

.blog-section-five .right-box-two .social-section .inner-column .quality-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.blog-section-five .right-box-two .social-section .inner-column .quality-list li {
  margin-right: 10px;
}

.blog-section-five .right-box-two .social-section .inner-column .quality-list li:last-child {
  margin-right: 0;
}

.blog-section-five .right-box-two .social-section .inner-column .quality-list li a {
  padding: 3px 17px;
  border-radius: 50px;
  background-color: #E9F2FF;
  font-size: 13px;
  display: inline-block;
  font-weight: 500;
}

.blog-section-five .right-box-two .social-section .inner-column .quality-list li a:hover {
  background-color: var(--theme-color1);
  color: var(--theme-color-light);
}

.blog-section-five .right-box-two .auther-box-two {
  position: relative;
  margin-top: 60px;
  border-bottom: 1px solid #E1E1E1;
  padding-bottom: 60px;
}

.blog-section-five .right-box-two .auther-box-two img {
  position: absolute;
  top: 0;
  left: 0;
}

.blog-section-five .right-box-two .auther-box-two .content-box {
  padding-left: 100px;
  padding-top: 4px;
}

.blog-section-five .right-box-two .auther-box-two .content-box .name {
  font-size: 18px;
  font-weight: 500;
  color: var(--theme-color-dark);
  margin-bottom: -1px;
}

.blog-section-five .right-box-two .auther-box-two .content-box .designation {
  font-size: 14px;
  color: var(--theme-color-dark);
}

.blog-section-five .right-box-two .auther-box-two .content-box .text {
  font-size: 15px;
  line-height: 28px;
  margin-top: 15px;
}

.blog-section-five .right-box-two .ruls-sec {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding-top: 45px;
  border-bottom: 1px solid #E1E1E1;
  padding-bottom: 44px;
}

.blog-section-five .right-box-two .ruls-sec .content-box .title {
  font-size: 17px;
  color: var(--theme-color-dark);
  font-weight: 500;
  margin-bottom: 5px;
}

.blog-section-five .right-box-two .ruls-sec .content-box .title i {
  margin-right: 10px;
}

.blog-section-five .right-box-two .ruls-sec .content-box .text {
  font-size: 14px;
  color: var(--theme-color-dark);
  line-height: 28px;
}

.blog-section-five .right-box-two .ruls-sec .content-box.v2 {
  text-align: end;
}

.blog-section-five .right-box-two .ruls-sec .content-box.v2 .title i {
  margin-left: 10px;
  margin-right: 0;
}

.blog-section-five .right-box-two .reviews {
  margin-top: 52px;
}

.blog-section-five .right-box-two .reviews .title {
  margin-bottom: 23px;
  font-weight: 500;
}

.blog-section-five .right-box-two .reviews .auther-name {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 16px;
}

.blog-section-five .right-box-two .reviews .auther-name span {
  font-size: 14px;
  font-weight: 500;
  width: 40px;
  height: 40px;
  line-height: 40px;
  background-color: var(--theme-color-dark);
  border-radius: 50%;
  text-align: center;
  color: var(--theme-color-light);
  display: inline-block;
  margin-right: 12px;
}

.blog-section-five .right-box-two .reviews .auther-name .name {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 0;
  margin-right: 17px;
}

.blog-section-five .right-box-two .reviews .auther-name small {
  font-size: 14px;
  color: var(--theme-color-dark);
}

.blog-section-five .right-box-two .reviews .rating-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.blog-section-five .right-box-two .reviews .rating-list .list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.blog-section-five .right-box-two .reviews .rating-list .list li {
  font-size: 10px;
  margin-right: 4px;
  color: var(--theme-color1);
}

.blog-section-five .right-box-two .reviews .rating-list .list li:last-child {
  margin-right: 0;
}

.blog-section-five .right-box-two .reviews .rating-list span {
  font-size: 15px;
  color: var(--theme-color-dark);
  font-weight: 500;
  margin-left: 12px;
}

.blog-section-five .right-box-two .reviews .text {
  font-size: 15px;
  color: var(--theme-color-dark);
  max-width: 849px;
}

.blog-section-five .right-box-two .image-box {
  margin-top: 23px;
  margin-bottom: 20px;
}

.blog-section-five .right-box-two .image-box img {
  border-radius: 12px;
  margin-right: 16px;
}

.blog-section-five .right-box-two .image-box img:last-child {
  margin-right: 0;
}

.blog-section-five .right-box-two .btn-box .like-btn {
  font-size: 15px;
  color: #818181;
  margin-right: 28px;
}

.blog-section-five .right-box-two .btn-box .like-btn i {
  margin-right: 10px;
}

.blog-section-five .right-box-two .btn-box .like-btn:hover {
  color: var(--theme-color1);
}

.blog-section-five .right-box-two .content-box.two {
  margin-top: 29px;
  border-bottom: 1px solid #E1E1E1;
  padding-bottom: 60px;
}

.blog-section-five .right-box-two .content-box.two .text {
  margin-bottom: 27px;
}

.blog-section-five .right-box-two .content-box.two .review {
  font-size: 15px;
  color: var(--theme-color1);
  font-weight: 500;
  height: 54px;
  line-height: 54px;
  padding: 0 38px;
  border-radius: 12px;
  border: 1px solid var(--color-secondary, #405FF2);
  background: var(--White, #FFF);
  display: inline-block;
  margin-top: 30px;
}

.blog-section-five .right-box-two .content-box.two .review svg {
  margin-left: 10px;
  position: relative;
  top: -1px;
}

.blog-section-five .right-box-two .Reply-sec {
  margin-top: 52px;
  margin-bottom: 20px;
}

.blog-section-five .right-box-two .Reply-sec .text {
  margin-bottom: 30px;
}

.blog-section-five .right-box-two .Reply-sec .right-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.blog-section-five .right-box-two .Reply-sec .right-box .rating-list {
  width: 330px;
}

.blog-section-five .right-box-two .Reply-sec .right-box .rating-list .list-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-bottom: 20px;
}

.blog-section-five .right-box-two .Reply-sec .right-box .rating-list .list-box span {
  font-size: 15px;
  color: var(--theme-color-dark);
}

.blog-section-five .right-box-two .Reply-sec .right-box .rating-list .list-box .list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.blog-section-five .right-box-two .Reply-sec .right-box .rating-list .list-box .list li {
  font-size: 12px;
  color: var(--theme-color1);
  margin-right: 4px;
}

.blog-section-five .right-box-two .Reply-sec .right-box .rating-list .list-box .list li:last-child {
  margin-right: 0;
}

.blog-section-five .right-box-two .Reply-sec .right-box .rating-list.two {
  margin-right: 144px;
}

.blog-section-five .right-box-two form {
  width: 100%;
  margin: 0;
  margin-left: -12px;
}

.blog-section-five .right-box-two form .form_boxes {
  border-radius: 12px;
  border: 1px solid rgb(225, 225, 225);
  background: var(--color-white, #FFF);
  padding: 7px 15px;
  margin-bottom: 30px;
}

.blog-section-five .right-box-two form .form_boxes textarea {
  width: 100%;
  height: 200px;
}

.blog-section-five .right-box-two form .form_boxes textarea::-webkit-input-placeholder {
  color: var(--theme-color-dark);
}

.blog-section-five .right-box-two form .form_boxes textarea::-moz-placeholder {
  color: var(--theme-color-dark);
}

.blog-section-five .right-box-two form .form_boxes textarea:-ms-input-placeholder {
  color: var(--theme-color-dark);
}

.blog-section-five .right-box-two form .form_boxes textarea::-ms-input-placeholder {
  color: var(--theme-color-dark);
}

.blog-section-five .right-box-two form .form_boxes textarea::placeholder {
  color: var(--theme-color-dark);
}

.blog-section-five .right-box-two form .form_boxes label {
  display: block;
  color: #818181;
  font-size: 15px;
  font-weight: 400;
}

.blog-section-five .right-box-two form .form_boxes .drop-menu {
  height: unset;
}

.blog-section-five .right-box-two form .form_boxes .drop-menu .select {
  padding: 0;
  line-height: unset;
}

.blog-section-five .right-box-two form .form_boxes .drop-menu .dropdown {
  padding: 5px 15px;
  margin-top: 10px;
  border-radius: 12px;
  left: -15px;
  width: calc(100% + 30px);
  -webkit-box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.08);
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.08);
}

.blog-section-five .right-box-two form .form_boxes.v2 {
  padding-top: 14px;
}

.blog-section-five .right-box-two form .theme-btn {
  background: #405FF2;
  color: #fff;
  height: 54px;
  line-height: 54px;
  border-radius: 12px;
  padding: 0 26px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.blog-section-five .right-box-two form .theme-btn svg {
  margin-left: 10px;
}

.widget-accordion {
  position: relative;
}

.widget-accordion .block:active {
  background-color: #151515;
}

.widget-accordion .block {
  position: relative;
  margin-bottom: 20px;
}

.widget-accordion .block:last-child {
  margin-bottom: 0;
}

.widget-accordion .block .acc-btn {
  position: relative;
  font-size: 18px;
  line-height: 28px;
  font-weight: 600;
  color: var(--theme-color-dark);
  cursor: pointer;
  border: 1px solid #202020;
  letter-spacing: -0.8px;
  padding: 39px 40px 0;
  padding-right: 70px;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

@media (max-width: 1199.98px) {
  .widget-accordion .block .acc-btn {
    padding-left: 18px;
  }
}

.widget-accordion .block .acc-btn .icon {
  position: absolute;
  right: 0px;
  top: 0px;
  height: 107px;
  width: 58px;
  font-size: 16px;
  line-height: 107px;
  text-align: center;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.widget-accordion .block .acc-btn:active {
  color: var(--theme-color-dark);
}

.widget-accordion .block .acc-btn.active {
  border: 0;
  padding-bottom: 15px;
}

.widget-accordion .block .acc-btn.active .icon {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

.widget-accordion .block .acc-content {
  position: relative;
  display: none;
  padding: 0 0 40px;
}

.widget-accordion .block .acc-content .content {
  padding: 0px 40px 0;
}

.widget-accordion .block .acc-content .content .text {
  margin-bottom: 0;
  color: var(--text-color);
  text-transform: lowercase;
}

.widget-accordion .block .acc-content.current {
  display: block;
}

.widget-accordion.style-two .block .acc-btn {
  background-color: var(--theme-color-light);
  padding: 20px 20px;
  padding-right: 60px;
  line-height: 30px;
}

.widget-accordion.style-two .block .acc-btn .icon {
  right: 20px;
  top: 20px;
  height: 30px;
  width: 30px;
  font-size: 14px;
  line-height: 30px;
  text-align: center;
  border-radius: 50%;
  color: var(--theme-color-light);
  background-color: var(--theme-color1);
}

.widget-accordion.style-two .block .acc-btn:active {
  color: var(--theme-color-dark);
}

.widget-accordion.style-two .block .acc-content .content {
  padding: 22px 0px 0;
}

.widget-accordion.style-three .block {
  background-color: var(--theme-color-light);
  border-radius: 10px;
  margin-bottom: 30px;
}

.widget-accordion.style-three .block:last-child {
  margin-bottom: 0;
}

.widget-accordion.style-three .block .acc-btn {
  background-color: var(--theme-color-light);
  padding: 20px 30px;
  font-size: 20px;
  line-height: 30px;
}

.widget-accordion.style-three .block .acc-btn .icon {
  right: 20px;
  top: 20px;
  height: 30px;
  width: 30px;
  font-size: 14px;
  line-height: 30px;
  text-align: center;
  border-radius: 50%;
  color: var(--theme-color-light);
  background-color: var(--theme-color1);
}

.widget-accordion.style-three .block .acc-btn:active {
  color: var(--theme-color-dark);
}

.widget-accordion.style-three .block .acc-content .content {
  padding: 20px 30px 20px;
}

.widget-accordion.style-four .block {
  background-color: var(--theme-color-light);
  border-bottom: 1px solid var(--theme-color-gray);
  margin-bottom: 0px;
}

.widget-accordion.style-four .block .acc-btn {
  background-color: transparent;
  padding: 20px 0px;
}

.widget-accordion.style-four .block .acc-btn .icon {
  right: 0px;
  top: 20px;
  height: 30px;
  line-height: 30px;
  width: auto;
  color: var(--theme-color1);
  background-color: transparent;
}

.widget-accordion.style-four .block .acc-btn.active {
  color: var(--theme-color2);
  border-radius: 10px 10px 0 0;
}

.widget-accordion.style-four .block .acc-btn.active .icon {
  background-color: var(--theme-color-light);
  color: var(--theme-color1);
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}

.widget-accordion.style-four .block .acc-btn.active .icon:before {
  content: "\f101";
}

.widget-accordion.style-four .block .acc-content .content {
  padding: 0px 0px 20px;
  background-color: var(--theme-color-light);
}

/*** 

====================================================================
    about-inner
====================================================================

***/
.about-inner-one {
  background-color: var(--theme-color-light);
  position: relative;
  padding: 52px 0 120px;
  border-radius: 80px;
}

.about-inner-one .upper-box {
  margin-bottom: 110px;
}

.about-inner-one .upper-box .boxcar-title {
  margin-bottom: 51px;
  max-width: 445px;
}

.about-inner-one .upper-box .boxcar-title h2 {
  margin-bottom: 36px;
}

.about-inner-one .upper-box .boxcar-title .text {
  font-size: 40px;
  font-weight: 700;
  line-height: 55px;
}

.about-inner-one .upper-box .content-box {
  margin-top: 128px;
}

.about-inner-one .upper-box .content-box .text {
  margin-bottom: 24px;
  font-size: 15px;
  line-height: 26px;
  color: var(--theme-color-dark);
}

.about-inner-one .upper-box .content-box .text:last-child {
  margin-bottom: 0;
}

.about-inner-one .galler-section .exp-block .inner-box .exp-box {
  background-color: var(--theme-color1);
  padding: 31px 32px 116px;
  border-radius: 16px;
}

.about-inner-one .galler-section .exp-block .inner-box .exp-box .title {
  font-size: 52px;
  font-weight: 500;
  color: var(--theme-color-light);
  margin-bottom: 9px;
}

.about-inner-one .galler-section .exp-block .inner-box .exp-box .text {
  font-size: 30px;
  color: var(--theme-color-light);
  font-weight: 700;
  line-height: 45px;
}

.about-inner-one .galler-section .exp-block .inner-box .image-box .image {
  overflow: hidden;
  border-radius: 16px;
}

.about-inner-one .galler-section .exp-block .inner-box {
  display: flex;
  flex-direction: column;
  gap: 27px;
}

@media (max-width: 768px) {
  .about-inner-one .galler-section .exp-block .inner-box {
    flex-direction: row;
    margin-bottom: 30px;

  }

  .about-inner-one .galler-section .exp-block .inner-box .exp-box,
  .about-inner-one .galler-section .exp-block .inner-box .image-box {
    flex-grow: 1;
  }

  .about-inner-one .galler-section .exp-block .inner-box .image-box img,
  .about-inner-one .galler-section .image-block.style-center img,
  .about-inner-one .galler-section .image-box.two img,
  .about-inner-one .galler-section .box-double-img .image-block img {
    width: 100%;
  }

  .about-inner-one .galler-section .box-double-img .image-box,
  .about-inner-one .galler-section .box-double-img .image,
  .about-inner-one .galler-section .box-double-img .image-block img {
    height: 100%;
  }

  .about-inner-one .galler-section .image-block.style-center {
    margin-bottom: 30px;
  }
}

.about-inner-one .galler-section .image-block .image-box .image img {
  border-radius: 16px;
}

.about-inner-one .galler-section .image-block .image-box.two .image {
  margin-bottom: 30px;
}

.about-inner-one .faqs-section {
  position: relative;
  padding: 120px 0 0;
}

.about-inner-one .faqs-section .inner-container {
  max-width: 954px;
  margin: 0 auto;
}

.about-inner-one .faqs-section .accordion.block.active-block {
  background-color: #F9FBFC;
}

.about-inner-one .faqs-section .widget-accordion .block .acc-btn {
  font-size: 20px;
  font-family: var(--title-font);
  font-weight: 500;
  text-transform: capitalize;
  color: var(--theme-color-dark);
  border: 0;
}

.about-inner-one .faqs-section .widget-accordion .block .acc-content .content .text {
  font-size: 15px;
  line-height: 28px;
}

.about-inner-one .faqs-section .widget-accordion .block .acc-btn {
  background-color: unset;
}

.about-inner-one .faqs-section .widget-accordion .block.active-block .acc-btn {
  color: var(--theme-color-dark);
}

.breadcrumb {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 12px;
}

.breadcrumb li {
  font-size: 15px;
  margin-right: 21px;
  position: relative;
}

.breadcrumb li::before {
  content: "/";
  position: absolute;
  top: 0;
  right: -14px;
}

.breadcrumb li:last-child {
  margin-right: 0;
}

.breadcrumb li:last-child::before {
  display: none;
}

.breadcrumb li a {
  color: var(--theme-color1);
}

/*** 

====================================================================
    error-section
====================================================================

***/
.error-section {
  padding: 120px 0;
  background-color: var(--theme-color-light);
  position: relative;
  border-radius: 80px;
}

.error-section .right-box {
  text-align: center;
}

.error-section .right-box .content-box {
  position: relative;
  margin-top: -71px;
  z-index: 99;
}

.error-section .right-box .content-box h2 {
  font-size: 40px;
  font-weight: 700;
}

.error-section .right-box .content-box .text {
  font-size: 15px;
  line-height: 28px;
  max-width: 450px;
  margin: 0 auto 30px;
}

.error-section .right-box .content-box .error-btn {
  font-size: 15px;
  font-family: var(--title-font);
  font-weight: 500;
  color: var(--theme-color1);
  height: 54px;
  line-height: 54px;
  text-align: center;
  border-radius: 12px;
  border: 1px solid var(--theme-color1);
  display: inline-block;
  background: var(--theme-color-light);
  padding: 0 26px;
}

.error-section .right-box .content-box .error-btn svg {
  position: relative;
  margin-left: 9px;
  top: -1px;
}

/*** 

====================================================================
    dashboard section
====================================================================

***/
.dashboard-widget {
  position: relative;
}

.dashboard-widget .nav-tabs {
  border-bottom: 1px solid #e1e1e1;
  margin: 0 15px 30px;
}

.dashboard-widget .nav-tabs .nav-link {
  font-size: 16px;
  font-weight: 500;
  color: var(--theme-color-dark);
  border-top: 0;
  background-color: unset;
  border-left: 0;
  border-right: 0;
  padding: 0 0 7px 0;
  margin-right: 32px;
}

.dashboard-widget .nav-tabs .nav-link.active {
  border-bottom: 1px solid var(--theme-color1);
}

.dashboard-widget .right-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.dashboard-widget .side-bar {
  position: relative;
  width: 300px;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 300px;
  flex: 0 0 300px;
  padding: 0 30px;
}

.dashboard-widget .side-bar .nav-list li a {
  color: var(--theme-color-light);
  font-weight: 500;
  font-size: 15px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  border-radius: 16px;
  padding: 16px 91px 14px 19px;
}

.dashboard-widget .side-bar .nav-list li a img {
  margin-right: 16px;
}

.dashboard-widget .side-bar .nav-list li a:hover {
  background: rgba(255, 255, 255, 0.1);
}

.dashboard-widget .content-column {
  width: 100%;
}

.dashboard-widget .content-column .inner-column {
  background-color: var(--theme-color-light);
  padding: 58px 60px 60px;
  margin-right: 30px;
  border-radius: 16px;
}

.dashboard-widget .content-column .inner-column .list-title {
  margin-bottom: 58px;
}

.dashboard-widget .content-column .inner-column .list-title .title {
  font-size: 32px;
  font-weight: 700;
  color: #222;
  margin-bottom: 0;
  line-height: 46px;
}

.dashboard-widget .content-column .inner-column .list-title .text {
  font-size: 15px;
}

.dashboard-widget .content-column .inner-column .form-box {
  border-radius: 16px;
  border: 1px solid var(--Border, #E1E1E1);
  background: var(--theme-color-light);
  padding: 21px 14px 30px;
  margin-bottom: 30px;
}

.dashboard-widget .content-column .inner-column .form-box form {
  width: 100%;
  margin: 0;
}

.dashboard-widget .content-column .inner-column .form-box form .form-column {
  padding-right: 10px;
}

.dashboard-widget .content-column .inner-column .form-box form .form_boxes.v2 {
  padding-top: 14px;
  margin-bottom: 30px;
}

.dashboard-widget .content-column .inner-column .form-box form .inner-box {
  margin-bottom: 21px;
}

.dashboard-widget .content-column .inner-column .form-box form .form_boxes {
  border-radius: 12px;
  border: 1px solid rgb(225, 225, 225);
  background: var(--color-white, #FFF);
  padding: 7px 15px;
  margin-bottom: 30px;
}

.dashboard-widget .content-column .inner-column .form-box form .form_boxes textarea {
  width: 100%;
  height: 150px;
}

.dashboard-widget .content-column .inner-column .form-box form .form_boxes textarea::-webkit-input-placeholder {
  color: var(--theme-color-dark);
}

.dashboard-widget .content-column .inner-column .form-box form .form_boxes textarea::-moz-placeholder {
  color: var(--theme-color-dark);
}

.dashboard-widget .content-column .inner-column .form-box form .form_boxes textarea:-ms-input-placeholder {
  color: var(--theme-color-dark);
}

.dashboard-widget .content-column .inner-column .form-box form .form_boxes textarea::-ms-input-placeholder {
  color: var(--theme-color-dark);
}

.dashboard-widget .content-column .inner-column .form-box form .form_boxes textarea::placeholder {
  color: var(--theme-color-dark);
}

.dashboard-widget .content-column .inner-column .form-box form .form_boxes label {
  display: block;
  color: #818181;
  font-size: 15px;
  font-weight: 400;
}

.dashboard-widget .content-column .inner-column .form-box form .form_boxes .drop-menu {
  height: unset;
}

.dashboard-widget .content-column .inner-column .form-box form .form_boxes .drop-menu .select {
  padding: 0;
  line-height: unset;
  text-transform: lowercase;
}

.dashboard-widget .content-column .inner-column .form-box form .form_boxes .drop-menu .select i {
  right: 0;
  top: -13px;
}

.dashboard-widget .content-column .inner-column .form-box form .form_boxes .drop-menu .dropdown {
  padding: 5px 15px;
  margin-top: 10px;
  border-radius: 12px;
  left: -15px;
  width: calc(100% + 30px);
  -webkit-box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.08);
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.08);
}

.dashboard-widget .content-column .inner-column .form-box form .text {
  margin-top: 12px;
}

.dashboard-widget .content-column .inner-column .form-box form .form-submit {
  text-align: right;
}

.dashboard-widget .content-column .inner-column .form-box form .form-submit .theme-btn {
  color: var(--theme-color-light);
  border-radius: 12px;
  border: 1px solid var(--theme-color1);
  background: var(--theme-color1);
  font-size: 15px;
  font-weight: 500;
  padding: 0 26px;
  height: 54px;
  line-height: 54px;
  display: inline-block;
}

.dashboard-widget .content-column .inner-column .form-box form .form-submit .theme-btn svg {
  position: relative;
  top: -1px;
  margin-left: 9px;
}

.dashboard-widget .content-column .inner-column .cheak-box-sec {
  border-radius: 16px;
  border: 1px solid var(--Border, #E1E1E1);
  background: var(--theme-color-light);
  padding: 21px 29px 30px;
  margin-bottom: 30px;
}

.dashboard-widget .content-column .inner-column .cheak-box-sec .nav-tabs.v2 {
  margin: 0 0 30px;
}

.dashboard-widget .content-column .inner-column .cheak-box-sec .right-box-two {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.dashboard-widget .content-column .inner-column .cheak-box-sec .right-box-two .cheak-box {
  margin-right: 57px;
  /* Hide the browser's default checkbox */
  /* Create a custom checkbox */
  /* When the checkbox is checked, add a blue background */
  /* Create the checkmark/indicator (hidden when not checked) */
  /* Show the checkmark when checked */
  /* Style the checkmark/indicator */
}

.dashboard-widget .content-column .inner-column .cheak-box-sec .right-box-two .cheak-box:last-child {
  margin-right: 0;
}

.dashboard-widget .content-column .inner-column .cheak-box-sec .right-box-two .cheak-box .contain {
  display: block;
  position: relative;
  padding-left: 30px;
  margin-bottom: 8px;
  cursor: pointer;
  font-size: 15px;
  color: var(--theme-color-dark);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.dashboard-widget .content-column .inner-column .cheak-box-sec .right-box-two .cheak-box .contain input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.dashboard-widget .content-column .inner-column .cheak-box-sec .right-box-two .cheak-box .checkmark {
  position: absolute;
  top: 4px;
  left: 0;
  height: 20px;
  width: 20px;
  border: 1px solid rgb(225, 225, 225);
  border-radius: 5px;
}

.dashboard-widget .content-column .inner-column .cheak-box-sec .right-box-two .cheak-box .contain input:checked~.checkmark {
  background-color: var(--theme-color-dark);
}

.dashboard-widget .content-column .inner-column .cheak-box-sec .right-box-two .cheak-box .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.dashboard-widget .content-column .inner-column .cheak-box-sec .right-box-two .cheak-box .contain input:checked~.checkmark:after {
  display: block;
}

.dashboard-widget .content-column .inner-column .cheak-box-sec .right-box-two .cheak-box .contain .checkmark:after {
  left: 6px;
  top: 3px;
  width: 7px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.dashboard-widget .content-column .inner-column .cheak-box-sec .btn-box {
  text-align: right;
  margin-top: 17px;
}

.dashboard-widget .content-column .inner-column .cheak-box-sec .btn-box .form-btn {
  color: var(--theme-color-light);
  border-radius: 12px;
  border: 1px solid var(--theme-color1);
  background: var(--theme-color1);
  font-size: 15px;
  font-weight: 500;
  padding: 0 26px;
  height: 54px;
  line-height: 54px;
  display: inline-block;
}

.dashboard-widget .content-column .inner-column .cheak-box-sec .btn-box .form-btn svg {
  position: relative;
  top: -1px;
  margin-left: 9px;
}

.dashboard-widget .content-column .inner-column .gallery-sec {
  border-radius: 16px;
  border: 1px solid var(--Border, #E1E1E1);
  background: var(--theme-color-light);
  padding: 21px 29px 30px;
  margin-bottom: 30px;
}

.dashboard-widget .content-column .inner-column .gallery-sec .title {
  font-weight: 500;
  margin-bottom: 13px;
}

.dashboard-widget .content-column .inner-column .gallery-sec .nav-tabs.v2 {
  margin: 0 0 30px;
}

.dashboard-widget .content-column .inner-column .gallery-sec .right-box-three {
  border-bottom: 1px solid #E1E1E1;
  padding-bottom: 21px;
}

.dashboard-widget .content-column .inner-column .gallery-sec .right-box-three .gallery-box .text {
  font-size: 15px;
  color: var(--theme-color-dark);
  margin-top: 24px;
}

.dashboard-widget .content-column .inner-column .gallery-sec .right-box-three .gallery-box .inner-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.dashboard-widget .content-column .inner-column .gallery-sec .right-box-three .gallery-box .inner-box .image-box {
  position: relative;
  display: inline-block;
  margin-right: 30px;
}

.dashboard-widget .content-column .inner-column .gallery-sec .right-box-three .gallery-box .inner-box .image-box:hover .content-box {
  opacity: 1;
  visibility: visible;
}

.dashboard-widget .content-column .inner-column .gallery-sec .right-box-three .gallery-box .inner-box .image-box .content-box {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}

.dashboard-widget .content-column .inner-column .gallery-sec .right-box-three .gallery-box .inner-box .image-box .content-box .social-icon {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.dashboard-widget .content-column .inner-column .gallery-sec .right-box-three .gallery-box .inner-box .image-box .content-box .social-icon li {
  margin-right: 12px;
}

.dashboard-widget .content-column .inner-column .gallery-sec .right-box-three .gallery-box .inner-box .image-box .content-box .social-icon li:last-child {
  margin-right: 0;
}

.dashboard-widget .content-column .inner-column .gallery-sec .right-box-three .gallery-box .inner-box .image-box .content-box .social-icon li a {
  border-radius: 16px;
  border: 1px solid var(--theme-color-light);
  background: var(--theme-color-light);
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  display: inline-block;
}

.dashboard-widget .content-column .inner-column .gallery-sec .right-box-three .gallery-box .inner-box .uplode-box {
  position: relative;
  border-radius: 8px;
  border: 1px dashed var(--color-secondary, #405FF2);
  background: var(--bg-3, #E9F2FF);
  width: 190px;
  height: 167px;
  text-align: center;
}

.dashboard-widget .content-column .inner-column .gallery-sec .right-box-three .gallery-box .inner-box .uplode-box .content-box {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.dashboard-widget .content-column .inner-column .gallery-sec .right-box-three .gallery-box .inner-box .uplode-box .content-box span {
  display: block;
  font-size: 15px;
  color: var(--theme-color-dark);
  font-weight: 500;
  margin-top: 10px;
}

.dashboard-widget .content-column .inner-column .gallery-sec .attachment-sec {
  margin-top: 23px;
  border-bottom: 1px solid #E1E1E1;
  padding-bottom: 21px;
}

.dashboard-widget .content-column .inner-column .gallery-sec .attachment-sec .text {
  font-size: 15px;
  color: var(--theme-color-dark);
  margin-top: 24px;
}

.dashboard-widget .content-column .inner-column .gallery-sec .attachment-sec .right-box-four {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.dashboard-widget .content-column .inner-column .gallery-sec .attachment-sec .right-box-four .report-box {
  border-radius: 12px;
  background: var(--bg-1, #F9FBFC);
  text-align: center;
  display: inline-block;
  padding: 40px 54px 48px;
  margin-right: 30px;
}

.dashboard-widget .content-column .inner-column .gallery-sec .attachment-sec .right-box-four .report-box span {
  font-size: 15px;
  font-weight: 500;
  color: var(--theme-color-dark);
  margin-bottom: 11px;
  display: inline-block;
}

.dashboard-widget .content-column .inner-column .gallery-sec .attachment-sec .right-box-four .report-box .social-icon {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.dashboard-widget .content-column .inner-column .gallery-sec .attachment-sec .right-box-four .report-box .social-icon li {
  margin-right: 12px;
}

.dashboard-widget .content-column .inner-column .gallery-sec .attachment-sec .right-box-four .report-box .social-icon li:last-child {
  margin-right: 0;
}

.dashboard-widget .content-column .inner-column .gallery-sec .attachment-sec .right-box-four .report-box .social-icon li a {
  border-radius: 16px;
  border: 1px solid var(--theme-color-light);
  background: var(--theme-color-light);
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  display: inline-block;
}

.dashboard-widget .content-column .inner-column .gallery-sec .attachment-sec .right-box-four .uplode-box {
  position: relative;
  border-radius: 8px;
  border: 1px dashed var(--color-primary, #050B20);
  background: var(--bg-1, #F9FBFC);
  width: 190px;
  height: 167px;
  text-align: center;
}

.dashboard-widget .content-column .inner-column .gallery-sec .attachment-sec .right-box-four .uplode-box .content-box {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.dashboard-widget .content-column .inner-column .gallery-sec .attachment-sec .right-box-four .uplode-box .content-box span {
  display: block;
  font-size: 15px;
  color: var(--theme-color-dark);
  font-weight: 500;
  margin-top: 10px;
}

.dashboard-widget .content-column .inner-column .gallery-sec .form-sec {
  margin-top: 23px;
}

.dashboard-widget .content-column .inner-column .gallery-sec .form-sec form {
  width: 100%;
  margin: 0;
}

.dashboard-widget .content-column .inner-column .gallery-sec .form-sec form .form-column {
  margin: 0 -14px;
}

.dashboard-widget .content-column .inner-column .gallery-sec .form-sec form .form_boxes.v2 {
  padding-top: 14px;
  margin-bottom: 30px;
}

.dashboard-widget .content-column .inner-column .gallery-sec .form-sec form .inner-box {
  margin-bottom: 21px;
  margin-right: -25px;
}

.dashboard-widget .content-column .inner-column .gallery-sec .form-sec form .form_boxes {
  border-radius: 12px;
  border: 1px solid rgb(225, 225, 225);
  background: var(--color-white, #FFF);
  padding: 7px 15px;
  margin-bottom: 12px;
}

.dashboard-widget .content-column .inner-column .gallery-sec .form-sec form .form_boxes textarea {
  width: 100%;
  height: 150px;
}

.dashboard-widget .content-column .inner-column .gallery-sec .form-sec form .form_boxes textarea::-webkit-input-placeholder {
  color: var(--theme-color-dark);
}

.dashboard-widget .content-column .inner-column .gallery-sec .form-sec form .form_boxes textarea::-moz-placeholder {
  color: var(--theme-color-dark);
}

.dashboard-widget .content-column .inner-column .gallery-sec .form-sec form .form_boxes textarea:-ms-input-placeholder {
  color: var(--theme-color-dark);
}

.dashboard-widget .content-column .inner-column .gallery-sec .form-sec form .form_boxes textarea::-ms-input-placeholder {
  color: var(--theme-color-dark);
}

.dashboard-widget .content-column .inner-column .gallery-sec .form-sec form .form_boxes textarea::placeholder {
  color: var(--theme-color-dark);
}

.dashboard-widget .content-column .inner-column .gallery-sec .form-sec form .form_boxes label {
  display: block;
  color: #818181;
  font-size: 15px;
  font-weight: 400;
}

.dashboard-widget .content-column .inner-column .gallery-sec .form-sec form .form_boxes .drop-menu {
  height: unset;
}

.dashboard-widget .content-column .inner-column .gallery-sec .form-sec form .form_boxes .drop-menu .select {
  padding: 0;
  line-height: unset;
  text-transform: lowercase;
}

.dashboard-widget .content-column .inner-column .gallery-sec .form-sec form .form_boxes .drop-menu .select i {
  right: 0;
  top: -13px;
}

.dashboard-widget .content-column .inner-column .gallery-sec .form-sec form .form_boxes .drop-menu .dropdown {
  padding: 5px 15px;
  margin-top: 10px;
  border-radius: 12px;
  left: -15px;
  width: calc(100% + 30px);
  -webkit-box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.08);
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.08);
}

.dashboard-widget .content-column .inner-column .gallery-sec .form-sec form .text {
  margin-top: 12px;
}

.dashboard-widget .content-column .inner-column .gallery-sec .form-sec form .form-submit {
  text-align: right;
  padding: 0;
}

.dashboard-widget .content-column .inner-column .gallery-sec .form-sec form .form-submit .theme-btn {
  color: var(--theme-color-light);
  border-radius: 12px;
  border: 1px solid var(--theme-color1);
  background: var(--theme-color1);
  font-size: 15px;
  font-weight: 500;
  padding: 0 26px;
  height: 54px;
  line-height: 54px;
  display: inline-block;
}

.dashboard-widget .content-column .inner-column .gallery-sec .form-sec form .form-submit .theme-btn svg {
  position: relative;
  top: -1px;
  margin-left: 9px;
}

.dashboard-widget .content-column .inner-column .map-sec {
  border-radius: 16px;
  border: 1px solid var(--Border, #E1E1E1);
  background: var(--theme-color-light);
  padding: 21px 14px 30px;
  margin-bottom: 30px;
}

.dashboard-widget .content-column .inner-column .map-sec .map-box {
  margin-bottom: 21px;
}

.dashboard-widget .content-column .inner-column .map-sec .map-box iframe {
  width: 100%;
  height: 450px;
  border-radius: 30px;
}

.dashboard-widget .content-column .inner-column .map-sec .form-sec-two {
  margin-top: 23px;
}

.dashboard-widget .content-column .inner-column .map-sec .form-sec-two form {
  width: 100%;
  margin: 0;
}

.dashboard-widget .content-column .inner-column .map-sec .form-sec-two form .form-column {
  margin: 0 -14px;
}

.dashboard-widget .content-column .inner-column .map-sec .form-sec-two form .form_boxes.v2 {
  padding-top: 14px;
  margin-bottom: 30px;
}

.dashboard-widget .content-column .inner-column .map-sec .form-sec-two form .inner-box {
  margin-bottom: 21px;
  margin-right: -25px;
}

.dashboard-widget .content-column .inner-column .map-sec .form-sec-two form .form_boxes {
  border-radius: 12px;
  border: 1px solid rgb(225, 225, 225);
  background: var(--color-white, #FFF);
  padding: 7px 15px;
  margin-bottom: 30px;
}

.dashboard-widget .content-column .inner-column .map-sec .form-sec-two form .form_boxes textarea {
  width: 100%;
  height: 150px;
}

.dashboard-widget .content-column .inner-column .map-sec .form-sec-two form .form_boxes textarea::-webkit-input-placeholder {
  color: var(--theme-color-dark);
}

.dashboard-widget .content-column .inner-column .map-sec .form-sec-two form .form_boxes textarea::-moz-placeholder {
  color: var(--theme-color-dark);
}

.dashboard-widget .content-column .inner-column .map-sec .form-sec-two form .form_boxes textarea:-ms-input-placeholder {
  color: var(--theme-color-dark);
}

.dashboard-widget .content-column .inner-column .map-sec .form-sec-two form .form_boxes textarea::-ms-input-placeholder {
  color: var(--theme-color-dark);
}

.dashboard-widget .content-column .inner-column .map-sec .form-sec-two form .form_boxes textarea::placeholder {
  color: var(--theme-color-dark);
}

.dashboard-widget .content-column .inner-column .map-sec .form-sec-two form .form_boxes label {
  display: block;
  color: #818181;
  font-size: 15px;
  font-weight: 400;
}

.dashboard-widget .content-column .inner-column .map-sec .form-sec-two form .form_boxes .drop-menu {
  height: unset;
}

.dashboard-widget .content-column .inner-column .map-sec .form-sec-two form .form_boxes .drop-menu .select {
  padding: 0;
  line-height: unset;
  text-transform: lowercase;
}

.dashboard-widget .content-column .inner-column .map-sec .form-sec-two form .form_boxes .drop-menu .select i {
  right: 0;
  top: -13px;
}

.dashboard-widget .content-column .inner-column .map-sec .form-sec-two form .form_boxes .drop-menu .dropdown {
  padding: 5px 15px;
  margin-top: 10px;
  border-radius: 12px;
  left: -15px;
  width: calc(100% + 30px);
  -webkit-box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.08);
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.08);
}

.dashboard-widget .content-column .inner-column .map-sec .form-sec-two form .text {
  margin-top: 12px;
}

.dashboard-widget .content-column .inner-column .map-sec .form-sec-two form .form-submit {
  text-align: right;
  padding: 0;
}

.dashboard-widget .content-column .inner-column .map-sec .form-sec-two form .form-submit .theme-btn {
  color: var(--theme-color-light);
  border-radius: 12px;
  border: 1px solid var(--theme-color1);
  background: var(--theme-color1);
  font-size: 15px;
  font-weight: 500;
  padding: 0 26px;
  height: 54px;
  line-height: 54px;
  display: inline-block;
}

.dashboard-widget .content-column .inner-column .map-sec .form-sec-two form .form-submit .theme-btn svg {
  position: relative;
  top: -1px;
  margin-left: 9px;
}

/*** 

====================================================================
    dashboard section two
====================================================================

***/
.dashboard-widget-two .side-bar {
  position: relative;
  width: 300px;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 300px;
  flex: 0 0 300px;
  padding: 0 30px;
}

.dashboard-widget-two .side-bar .nav-list li a {
  color: var(--theme-color-light);
  font-weight: 500;
  font-size: 15px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  border-radius: 16px;
  padding: 16px 91px 14px 19px;
}

.dashboard-widget-two .side-bar .nav-list li a img {
  margin-right: 16px;
}

.dashboard-widget-two .side-bar .nav-list li a:hover {
  background: rgba(255, 255, 255, 0.1);
}

.dashboard-widget-two .right-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.dashboard-widget-two .right-box-two {
  border-radius: 16px;
  background: var(--theme-color-light);
  padding: 54px 60px 60px;
  margin-right: 30px;
}

.dashboard-widget-two .right-box-two .title-box {
  margin-bottom: 58px;
}

.dashboard-widget-two .right-box-two .title-box .title {
  font-size: 32px;
  font-weight: 700;
  color: #222;
  margin-bottom: -4px;
}

.dashboard-widget-two .right-box-two .title-box span {
  font-size: 15px;
  color: #222;
  display: inline-block;
}

.dashboard-widget-two .right-box-two .cars-section-four {
  border-radius: 16px;
  border: 1px solid var(--Border, #E1E1E1);
  padding: 30px 30px 28px;
}

.footer-style-one.v2 .footer-bottom {
  border-top: 0;
  max-width: 1603px;
  margin-left: auto;
  padding: 29px 90px 29px 60px;
}

/*** 

====================================================================
    dashboard section three
====================================================================

***/
.dashboard-widget-two .side-bar {
  position: relative;
  width: 300px;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 300px;
  flex: 0 0 300px;
  padding: 0 30px;
}

.dashboard-widget-two .side-bar .nav-list li a {
  color: var(--theme-color-light);
  font-weight: 500;
  font-size: 15px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  border-radius: 16px;
  padding: 16px 91px 14px 19px;
}

.dashboard-widget-two .side-bar .nav-list li a img {
  margin-right: 16px;
}

.dashboard-widget-two .side-bar .nav-list li a:hover {
  background: rgba(255, 255, 255, 0.1);
}

.dashboard-widget-two .content-column {
  width: 100%;
}

.dashboard-widget-two .content-column .inner-column {
  background-color: var(--theme-color-light);
  padding: 58px 60px 60px;
  margin-right: 30px;
  border-radius: 16px;
}

.dashboard-widget-two .content-column .inner-column .list-title {
  margin-bottom: 58px;
}

.dashboard-widget-two .content-column .inner-column .list-title .title {
  font-size: 32px;
  font-weight: 700;
  color: #222;
  margin-bottom: 0;
  line-height: 46px;
}

.dashboard-widget-two .content-column .inner-column .list-title .text {
  font-size: 15px;
}

.dashboard-widget-two .content-column .inner-column .gallery-sec {
  border-radius: 16px;
  border: 1px solid var(--Border, #E1E1E1);
  background: var(--theme-color-light);
  padding: 21px 29px 30px;
  margin-bottom: 30px;
}

.dashboard-widget-two .content-column .inner-column .gallery-sec .title {
  font-weight: 500;
  margin-bottom: 13px;
}

.dashboard-widget-two .content-column .inner-column .gallery-sec .nav-tabs.v2 {
  margin: 0 0 30px;
}

.dashboard-widget-two .content-column .inner-column .gallery-sec .right-box-three {
  border-bottom: 1px solid #E1E1E1;
  padding-bottom: 21px;
}

.dashboard-widget-two .content-column .inner-column .gallery-sec .right-box-three .gallery-box .text {
  font-size: 15px;
  color: var(--theme-color-dark);
  margin-top: 24px;
}

.dashboard-widget-two .content-column .inner-column .gallery-sec .right-box-three .gallery-box .inner-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.dashboard-widget-two .content-column .inner-column .gallery-sec .right-box-three .gallery-box .inner-box .image-box {
  position: relative;
  display: inline-block;
  margin-right: 30px;
}

.dashboard-widget-two .content-column .inner-column .gallery-sec .right-box-three .gallery-box .inner-box .image-box:hover .content-box {
  opacity: 1;
  visibility: visible;
}

.dashboard-widget-two .content-column .inner-column .gallery-sec .right-box-three .gallery-box .inner-box .image-box .content-box {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}

.dashboard-widget-two .content-column .inner-column .gallery-sec .right-box-three .gallery-box .inner-box .image-box .content-box .social-icon {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.dashboard-widget-two .content-column .inner-column .gallery-sec .right-box-three .gallery-box .inner-box .image-box .content-box .social-icon li {
  margin-right: 12px;
}

.dashboard-widget-two .content-column .inner-column .gallery-sec .right-box-three .gallery-box .inner-box .image-box .content-box .social-icon li:last-child {
  margin-right: 0;
}

.dashboard-widget-two .content-column .inner-column .gallery-sec .right-box-three .gallery-box .inner-box .image-box .content-box .social-icon li a {
  border-radius: 16px;
  border: 1px solid var(--theme-color-light);
  background: var(--theme-color-light);
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  display: inline-block;
}

.dashboard-widget-two .content-column .inner-column .gallery-sec .right-box-three .gallery-box .inner-box .uplode-box {
  position: relative;
  border-radius: 8px;
  border: 1px dashed var(--color-secondary, #405FF2);
  background: var(--bg-3, #E9F2FF);
  width: 190px;
  height: 167px;
  text-align: center;
}

.dashboard-widget-two .content-column .inner-column .gallery-sec .right-box-three .gallery-box .inner-box .uplode-box .content-box {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.dashboard-widget-two .content-column .inner-column .gallery-sec .right-box-three .gallery-box .inner-box .uplode-box .content-box span {
  display: block;
  font-size: 15px;
  color: var(--theme-color-dark);
  font-weight: 500;
  margin-top: 10px;
}

.dashboard-widget-two .content-column .inner-column .gallery-sec .attachment-sec {
  margin-top: 23px;
  border-bottom: 1px solid #E1E1E1;
  padding-bottom: 21px;
}

.dashboard-widget-two .content-column .inner-column .gallery-sec .attachment-sec .text {
  font-size: 15px;
  color: var(--theme-color-dark);
  margin-top: 24px;
}

.dashboard-widget-two .content-column .inner-column .gallery-sec .attachment-sec .right-box-four {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.dashboard-widget-two .content-column .inner-column .gallery-sec .attachment-sec .right-box-four .report-box {
  border-radius: 12px;
  background: var(--bg-1, #F9FBFC);
  text-align: center;
  display: inline-block;
  padding: 40px 54px 48px;
  margin-right: 30px;
}

.dashboard-widget-two .content-column .inner-column .gallery-sec .attachment-sec .right-box-four .report-box span {
  font-size: 15px;
  font-weight: 500;
  color: var(--theme-color-dark);
  margin-bottom: 11px;
  display: inline-block;
}

.dashboard-widget-two .content-column .inner-column .gallery-sec .attachment-sec .right-box-four .report-box .social-icon {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.dashboard-widget-two .content-column .inner-column .gallery-sec .attachment-sec .right-box-four .report-box .social-icon li {
  margin-right: 12px;
}

.dashboard-widget-two .content-column .inner-column .gallery-sec .attachment-sec .right-box-four .report-box .social-icon li:last-child {
  margin-right: 0;
}

.dashboard-widget-two .content-column .inner-column .gallery-sec .attachment-sec .right-box-four .report-box .social-icon li a {
  border-radius: 16px;
  border: 1px solid var(--theme-color-light);
  background: var(--theme-color-light);
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  display: inline-block;
}

.dashboard-widget-two .content-column .inner-column .gallery-sec .attachment-sec .right-box-four .uplode-box {
  position: relative;
  border-radius: 8px;
  border: 1px dashed var(--color-primary, #050B20);
  background: var(--bg-1, #F9FBFC);
  width: 190px;
  height: 167px;
  text-align: center;
}

.dashboard-widget-two .content-column .inner-column .gallery-sec .attachment-sec .right-box-four .uplode-box .content-box {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.dashboard-widget-two .content-column .inner-column .gallery-sec .attachment-sec .right-box-four .uplode-box .content-box span {
  display: block;
  font-size: 15px;
  color: var(--theme-color-dark);
  font-weight: 500;
  margin-top: 10px;
}

.dashboard-widget-two .content-column .inner-column .gallery-sec .form-sec {
  margin: 23px -14px 0;
}

.dashboard-widget-two .content-column .inner-column .gallery-sec .form-sec form {
  width: 100%;
  margin: 0;
}

.dashboard-widget-two .content-column .inner-column .gallery-sec .form-sec form .form_boxes.v2 {
  padding-top: 14px;
  margin-bottom: 30px;
}

.dashboard-widget-two .content-column .inner-column .gallery-sec .form-sec form .inner-box {
  margin-bottom: 21px;
  margin-right: -25px;
}

.dashboard-widget-two .content-column .inner-column .gallery-sec .form-sec form .form_boxes {
  border-radius: 12px;
  border: 1px solid rgb(225, 225, 225);
  background: var(--color-white, #FFF);
  padding: 7px 15px;
  margin-bottom: 30px;
}

.dashboard-widget-two .content-column .inner-column .gallery-sec .form-sec form .form_boxes textarea {
  width: 100%;
  height: 150px;
}

.dashboard-widget-two .content-column .inner-column .gallery-sec .form-sec form .form_boxes textarea::-webkit-input-placeholder {
  color: var(--theme-color-dark);
}

.dashboard-widget-two .content-column .inner-column .gallery-sec .form-sec form .form_boxes textarea::-moz-placeholder {
  color: var(--theme-color-dark);
}

.dashboard-widget-two .content-column .inner-column .gallery-sec .form-sec form .form_boxes textarea:-ms-input-placeholder {
  color: var(--theme-color-dark);
}

.dashboard-widget-two .content-column .inner-column .gallery-sec .form-sec form .form_boxes textarea::-ms-input-placeholder {
  color: var(--theme-color-dark);
}

.dashboard-widget-two .content-column .inner-column .gallery-sec .form-sec form .form_boxes textarea::placeholder {
  color: var(--theme-color-dark);
}

.dashboard-widget-two .content-column .inner-column .gallery-sec .form-sec form .form_boxes label {
  display: block;
  color: #818181;
  font-size: 15px;
  font-weight: 400;
}

.dashboard-widget-two .content-column .inner-column .gallery-sec .form-sec form .form_boxes .drop-menu {
  height: unset;
}

.dashboard-widget-two .content-column .inner-column .gallery-sec .form-sec form .form_boxes .drop-menu .select {
  padding: 0;
  line-height: unset;
  text-transform: lowercase;
}

.dashboard-widget-two .content-column .inner-column .gallery-sec .form-sec form .form_boxes .drop-menu .select i {
  right: 0;
  top: -13px;
}

.dashboard-widget-two .content-column .inner-column .gallery-sec .form-sec form .form_boxes .drop-menu .dropdown {
  padding: 5px 15px;
  margin-top: 10px;
  border-radius: 12px;
  left: -15px;
  width: calc(100% + 30px);
  -webkit-box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.08);
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.08);
}

.dashboard-widget-two .content-column .inner-column .gallery-sec .form-sec form .text {
  margin-top: 12px;
}

.dashboard-widget-two .content-column .inner-column .gallery-sec .form-sec form .form-submit {
  text-align: right;
  padding: 0;
}

.dashboard-widget-two .content-column .inner-column .gallery-sec .form-sec form .form-submit .theme-btn {
  color: var(--theme-color-light);
  border-radius: 12px;
  border: 1px solid var(--theme-color1);
  background: var(--theme-color1);
  font-size: 15px;
  font-weight: 500;
  padding: 0 26px;
  height: 54px;
  line-height: 54px;
  display: inline-block;
}

.dashboard-widget-two .content-column .inner-column .gallery-sec .form-sec form .form-submit .theme-btn svg {
  position: relative;
  top: -1px;
  margin-left: 9px;
}

.dashboard-widget-two .content-column .inner-column .gallery-sec .right-box-three.v2 {
  border-bottom: 0;
  padding-bottom: 0;
}

.dashboard-widget-two .content-column .inner-column .gallery-sec .map-sec-two {
  margin: 0 -14px;
}

.dashboard-widget-two .content-column .inner-column .gallery-sec .map-sec-two .map-box {
  margin-bottom: 21px;
}

.dashboard-widget-two .content-column .inner-column .gallery-sec .map-sec-two .map-box iframe {
  width: 100%;
  height: 450px;
  border-radius: 30px;
}

.dashboard-widget-two .content-column .inner-column .gallery-sec .map-sec-two .form-sec-two {
  margin-top: 21px;
}

.dashboard-widget-two .content-column .inner-column .gallery-sec .map-sec-two .form-sec-two form {
  width: 100%;
  margin: 0;
}

.dashboard-widget-two .content-column .inner-column .gallery-sec .map-sec-two .form-sec-two form .form-column {
  margin: 0 -14px;
}

.dashboard-widget-two .content-column .inner-column .gallery-sec .map-sec-two .form-sec-two form .form_boxes.v2 {
  padding-top: 14px;
  margin-bottom: 30px;
}

.dashboard-widget-two .content-column .inner-column .gallery-sec .map-sec-two .form-sec-two form .inner-box {
  margin-bottom: 21px;
  margin-right: -25px;
}

.dashboard-widget-two .content-column .inner-column .gallery-sec .map-sec-two .form-sec-two form .form_boxes {
  border-radius: 12px;
  border: 1px solid rgb(225, 225, 225);
  background: var(--color-white, #FFF);
  padding: 7px 15px;
  margin-bottom: 30px;
}

.dashboard-widget-two .content-column .inner-column .gallery-sec .map-sec-two .form-sec-two form .form_boxes textarea {
  width: 100%;
  height: 150px;
}

.dashboard-widget-two .content-column .inner-column .gallery-sec .map-sec-two .form-sec-two form .form_boxes textarea::-webkit-input-placeholder {
  color: var(--theme-color-dark);
}

.dashboard-widget-two .content-column .inner-column .gallery-sec .map-sec-two .form-sec-two form .form_boxes textarea::-moz-placeholder {
  color: var(--theme-color-dark);
}

.dashboard-widget-two .content-column .inner-column .gallery-sec .map-sec-two .form-sec-two form .form_boxes textarea:-ms-input-placeholder {
  color: var(--theme-color-dark);
}

.dashboard-widget-two .content-column .inner-column .gallery-sec .map-sec-two .form-sec-two form .form_boxes textarea::-ms-input-placeholder {
  color: var(--theme-color-dark);
}

.dashboard-widget-two .content-column .inner-column .gallery-sec .map-sec-two .form-sec-two form .form_boxes textarea::placeholder {
  color: var(--theme-color-dark);
}

.dashboard-widget-two .content-column .inner-column .gallery-sec .map-sec-two .form-sec-two form .form_boxes label {
  display: block;
  color: #818181;
  font-size: 15px;
  font-weight: 400;
}

.dashboard-widget-two .content-column .inner-column .gallery-sec .map-sec-two .form-sec-two form .form_boxes .drop-menu {
  height: unset;
}

.dashboard-widget-two .content-column .inner-column .gallery-sec .map-sec-two .form-sec-two form .form_boxes .drop-menu .select {
  padding: 0;
  line-height: unset;
  text-transform: lowercase;
}

.dashboard-widget-two .content-column .inner-column .gallery-sec .map-sec-two .form-sec-two form .form_boxes .drop-menu .select i {
  right: 0;
  top: -13px;
}

.dashboard-widget-two .content-column .inner-column .gallery-sec .map-sec-two .form-sec-two form .form_boxes .drop-menu .dropdown {
  padding: 5px 15px;
  margin-top: 10px;
  border-radius: 12px;
  left: -15px;
  width: calc(100% + 30px);
  -webkit-box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.08);
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.08);
}

.dashboard-widget-two .content-column .inner-column .gallery-sec .map-sec-two .form-sec-two form .text {
  margin-top: 12px;
}

.dashboard-widget-two .content-column .inner-column .gallery-sec .map-sec-two .form-sec-two form .form-submit {
  text-align: right;
  padding: 0;
}

.dashboard-widget-two .content-column .inner-column .gallery-sec .map-sec-two .form-sec-two form .form-submit .theme-btn {
  color: var(--theme-color-light);
  border-radius: 12px;
  border: 1px solid var(--theme-color1);
  background: var(--theme-color1);
  font-size: 15px;
  font-weight: 500;
  padding: 0 26px;
  height: 54px;
  line-height: 54px;
  display: inline-block;
  margin-right: 15px;
}

.dashboard-widget-two .content-column .inner-column .gallery-sec .map-sec-two .form-sec-two form .form-submit .theme-btn svg {
  position: relative;
  top: -1px;
  margin-left: 9px;
}

.my-listing-table .cart-table table tr td:first-child {
  padding-left: 0;
}

.my-listing-table .shop-product-cart-info {
  max-width: 300px;
}

.my-listing-table .shop-product-cart-info h3 {
  max-width: 100% !important;
}

.my-listing-table .shop-product-cart-info p {
  font-size: 14px;
  color: #050B20;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.my-listing-table .shop-product-cart-info .price {
  margin-top: 10px;
}

.my-listing-table .shop-product-cart-info .price span {
  display: inline-block;
  color: #050B20;
  font-size: 20px;
  font-weight: 700;
}

.my-listing-table .shop-product-cart-info .price del {
  color: #050B20;
  font-size: 14px;
}

.search-table-head {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-bottom: 30px;
}

.search-table-head form {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  width: 100%;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.search-table-head form .text-box {
  margin-left: auto;
  margin-bottom: 0;
}

.search-table-head .search-table-field {
  max-width: 250px;
  position: relative;
}

.search-table-head .search-table-field img {
  position: absolute;
  top: 50%;
  left: 0;
  color: #050B20;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.search-table-head .search-table-field input {
  color: #050B20;
  font-size: 15px;
  padding-left: 25px;
}

.search-table {
  border: 1px solid #E1E1E1;
  border-radius: 16px;
  padding: 30px;
}

.search-table table {
  width: 100%;
}

.search-table table thead th {
  background: #E9F2FF;
  padding: 30px 20px;
  font-size: 16px;
  font-weight: 500;
  color: #405FF2;
}

.search-table table thead th:first-child {
  border-radius: 16px 0 0 16px;
}

.search-table table thead th:last-child {
  border-radius: 0 16px 16px 0;
}

.search-table table tbody tr:last-child td {
  border-bottom: 0;
  padding-bottom: 0;
}

.search-table table tbody td {
  color: #050B20;
  padding: 30px 20px;
  font-size: 15px;
  border-bottom: 1px solid #E1E1E1;
}

.search-table table tbody td span {
  display: inline-block;
  position: relative;
  padding: 0 12px;
}

.search-table table tbody td span:first-child {
  padding-left: 0;
}

.search-table table tbody td span:last-child {
  padding-right: 0;
}

.search-table table tbody td span:last-child:before {
  display: none;
}

.search-table table tbody td span:before {
  content: "";
  position: absolute;
  top: 50%;
  right: -4px;
  width: 4px;
  height: 4px;
  background-color: #ABAAAA;
  border-radius: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.remove-cart-item {
  display: inline-block;
  width: 40px;
  height: 40px;
  background-color: #F9FBFC;
  border-radius: 50%;
  text-align: center;
  line-height: 40px;
  border: 1px solid #E1E1E1;
}

/*** 

====================================================================
Chat Widget
====================================================================

***/
.chat-widget .widget-content {
  padding: 0;
}

.chat-widget .card-header {
  padding: 40px 40px 0;
  background: none;
  border-radius: 0;
  border: 0;
}

.chat-widget .search-box-one .form-group input[type=text],
.chat-widget .search-box-one .form-group input[type=search] {
  width: 100%;
}

.chat {
  margin-top: auto;
  margin-bottom: auto;
}

.card {
  height: 770px;
  background: #FFFFFF;
  border: 1px solid #ECEDF2;
  -webkit-box-shadow: 0px 6px 15px rgba(64, 79, 104, 0.05);
  box-shadow: 0px 6px 15px rgba(64, 79, 104, 0.05);
  border-radius: 8px;
}

.card ::-webkit-scrollbar {
  width: 4px;
}

.card ::-webkit-scrollbar-track {
  background: transparent;
}

.card ::-webkit-scrollbar-thumb {
  background-color: #F0F5F7;
  border-radius: 10px;
}

.contacts_body {
  padding: 15px 0px;
  overflow-y: auto;
  white-space: nowrap;
}

.msg_card_body {
  position: relative;
  overflow-y: auto;
  padding: 40px 40px 10px;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.msg_card_body .d-flex {
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.msg_card_body .justify-content-end {
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
}

.chat_history {
  position: absolute;
  left: 0;
  top: 0px;
  text-align: center;
  width: 100%;
  font-size: 16px;
  line-height: 20px;
  color: #5c6770;
  background: #fff;
  padding: 30px 0;
}

.msg_card_body .mb-3 {
  margin-bottom: 30px !important;
}

.card-footer {
  padding: 30px 40px 40px;
  border-radius: 0 !important;
  background: none;
  border-top: 1px solid #ECEDF2;
}

.card-footer .form-group {
  position: relative;
}

.card-footer .form-group button {
  position: absolute;
  right: 10px;
  top: 0;
  border-radius: 12px;
  background: #405FF2;
  color: #fff;
  border: 0;
  font-size: 15px;
  font-weight: 500;
  text-transform: capitalize;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.card-footer .form-group button svg {
  margin-left: 10px;
}

.type_msg {
  background-color: transparent !important;
  border: 0 !important;
  color: white !important;
  height: 50px !important;
  overflow-y: auto;
  font-size: 14px;
  color: #696969 !important;
  line-height: 20px;
  padding: 15px 30px;
}

.type_msg:focus {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  outline: 0px !important;
}

.attach_btn {
  border-radius: 15px 0 0 15px !important;
  background-color: rgba(0, 0, 0, 0.3) !important;
  border: 0 !important;
  color: white !important;
  cursor: pointer;
}

.send_btn {
  border-radius: 0 15px 15px 0 !important;
  background-color: rgba(0, 0, 0, 0.3) !important;
  border: 0 !important;
  color: white !important;
  cursor: pointer;
}

.contacts {
  list-style: none;
  padding: 0;
}

.contacts li {
  width: 100% !important;
}

.contacts li:last-child {
  margin-bottom: 0;
}

.contacts li a {
  position: relative;
  display: block;
  padding: 16px 40px;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}

.contacts li.active a,
.contacts li:hover a {
  background: #f9fafc;
}

.user_img {
  height: 50px;
  width: 50px;
  border: 1px solid #dddddd;
  -webkit-box-shadow: 0 1px 4px rgba(0, 0, 0, 0.09);
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.09);
}

.user_img_msg {
  height: 50px;
  width: 50px;
  border: 1px solid #dddddd;
  -webkit-box-shadow: 0 1px 4px rgba(0, 0, 0, 0.09);
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.09);
}

.img_cont {
  position: relative;
  height: 50px;
  width: 50px;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 50px;
  flex: 0 0 50px;
}

.img_cont_msg {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 15px;
}

.img_cont_msg img {
  height: 50px;
  width: 50px;
}

.img_cont_msg .name {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: #202124;
  margin-left: 10px;
}

.img_cont_msg .msg_time {
  font-size: 14px;
  line-height: 19px;
  text-align: right;
  color: #696969;
  margin-left: 10px;
}

.reply .img_cont_msg {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse;
}

.reply .img_cont_msg .msg_time,
.reply .img_cont_msg .name {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse;
  margin-right: 10px;
}

.online_icon {
  position: absolute;
  right: 0;
  top: 0;
  height: 13px;
  width: 13px;
  background-color: #00d664;
  border-radius: 50%;
  border: 2px solid #fafafa;
}

.offline {
  background-color: #ff0000 !important;
}

.user_info {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-left: 15px;
}

.user_info span {
  font-size: 16px;
  line-height: 22px;
  color: #202124;
  font-weight: 500;
  margin-bottom: 3px;
}

.user_info p {
  font-size: 14px !important;
  color: #5c6770 !important;
  line-height: 1.4em !important;
  margin-bottom: 0 !important;
}

.video_cam {
  margin-left: 50px;
  margin-top: 5px;
}

.video_cam span {
  color: white;
  font-size: 20px;
  cursor: pointer;
  margin-right: 20px;
}

.bd-highlight .info {
  position: absolute;
  right: 40px;
  top: 20px;
  font-size: 16px;
  line-height: 22px;
  color: #696969;
  font-weight: 400;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
}

.bd-highlight .info .count {
  position: relative;
  display: block;
  width: 16.02px;
  height: 16px;
  background: #1967D2;
  border-radius: 50%;
  color: #ffffff;
  line-height: 16px;
  text-align: center;
  font-size: 12px;
  margin-top: 2px;
}

.msg_cotainer {
  position: relative;
  display: block;
  width: auto;
  background: #F0F5F7;
  border-radius: 8px;
  padding: 23px 30px 20px;
  font-size: 14px;
  line-height: 24px;
  color: #696969;
  max-width: 60%;
  margin-bottom: 5px;
}

.reply .msg_cotainer {
  text-align: right;
}

.msg_cotainer_send {
  margin-top: 24px;
  border-radius: 5px;
  background-color: #bdc1c9;
  color: #ffffff;
  font-size: 14px;
  line-height: 24px;
  padding: 8px 18px;
  position: relative;
}

.msg_time_send {
  position: absolute;
  right: 0;
  top: -20px;
  font-size: 14px;
  line-height: 1em;
  color: #5c6770;
}

.chat-widget .msg_head {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  border-bottom: 1px solid #ECEDF2;
  -webkit-box-shadow: 0px 6px 15px rgba(64, 79, 104, 0.05);
  box-shadow: 0px 6px 15px rgba(64, 79, 104, 0.05);
  padding: 20px 40px;
}

.chat-widget .msg_head .btn-box {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.chat-widget .dlt-chat {
  font-size: 14px;
  line-height: 19px;
  text-decoration-line: underline;
  color: #405FF2;
  background: transparent;
}

#action_menu_btn {
  position: absolute;
  right: 10px;
  top: 10px;
  color: white;
  cursor: pointer;
  font-size: 20px;
}

.action_menu {
  z-index: 1;
  position: absolute;
  padding: 15px 0;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border-radius: 15px;
  top: 30px;
  right: 15px;
  display: none;
}

.action_menu ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.action_menu ul li {
  width: 100%;
  padding: 10px 15px;
  margin-bottom: 5px;
}

.action_menu ul li i {
  padding-right: 10px;
}

.action_menu ul li:hover {
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.2);
}

.toggle-contact {
  font-size: 20px;
  margin-left: 20px;
  display: none;
}

.active-chat-contacts .toggle-contact .fa-bars:before {
  content: "\f00d";
}

.ls-widget .widget-title {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  background: transparent;
  padding: 20px 30px 30px;
  min-height: 60px;
}

.ls-widget .widget-title .chosen-outer {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.widget-title .chosen-single {
  position: relative;
  height: 45px;
  padding: 10px 20px;
  font-size: 14px;
  color: #696969;
  line-height: 25px;
  background: #F0F5F7;
}

.widget-title .chosen-container {
  margin-left: 20px;
}

.widget-title .chosen-container-single .chosen-single div:after {
  color: #696969;
  content: "\f17c";
  font-family: "Flaticon";
  font-size: 10px;
}

.search-box-one {
  position: relative;
}

.search-box-one .form-group {
  position: relative;
  margin-bottom: 0;
}

.search-box-one .form-group input[type=text],
.search-box-one .form-group input[type=search] {
  position: relative;
  display: block;
  max-width: 100%;
  width: 330px;
  height: 45px;
  line-height: 25px;
  padding: 5px 20px;
  padding-left: 54px;
  font-size: 15px;
  color: #696969;
  background: #F0F5F7;
  border-radius: 8px;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.search-box-one .form-group input[type=text]:focus,
.search-box-one .form-group input[type=search]:focus {
  border-color: #1967D2;
}

.search-box-one .form-group .icon {
  position: absolute;
  left: 0;
  top: -3px;
  color: #696969;
  height: 45px;
  width: 54px;
  z-index: 1;
  text-align: center;
  line-height: 45px;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.ls-widget .widget-content {
  position: relative;
  padding: 0px 30px 10px;
}

.ls-widget .widget-content p {
  position: relative;
  font-size: 14px;
  line-height: 26px;
  color: #5c6770;
  font-weight: 400;
  margin-bottom: 26px;
}

/* Notification list */
.notification-list {
  position: relative;
}

.notification-list li {
  position: relative;
  padding: 5px 0;
  padding-left: 47px;
  font-size: 15px;
  line-height: 25px;
  color: #696969;
  font-weight: 400;
  min-height: 35px;
  margin-bottom: 25px;
}

.notification-list li strong {
  font-weight: 500;
  color: #202124;
}

.notification-list li .icon {
  position: absolute;
  left: 0;
  top: 0;
  height: 35px;
  width: 35px;
  background: rgba(25, 103, 210, 0.15);
  color: #1967D2;
  text-align: center;
  line-height: 35px;
  border-radius: 50%;
  font-size: 16px;
}

.notification-list li .colored {
  color: #1967D2;
}

.notification-list li.success .icon {
  background: rgba(52, 168, 83, 0.15);
  color: #34a853;
}

.notification-list li.success .colored {
  color: #34a853;
}

.uii-item {
  border-radius: 16px;
  height: 170px;
  position: relative;
  border: 1px solid #E1E1E1;
  padding: 0 30px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  /* Large devices */
}

@media (max-width: 1199px) {
  .uii-item {
    margin-bottom: 30px;
  }
}

.uii-item .ui-icon {
  position: absolute;
  top: 50%;
  right: 30px;
  width: 70px;
  height: 70px;
  background-color: #E9F2FF;
  border-radius: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.uii-item .ui-icon.v2 {
  background-color: #FFE9F3;
}

.uii-item .ui-icon.v3 {
  background-color: #EEF1FB;
}

.uii-item .ui-icon.v4 {
  background-color: #F9FBFC;
}

.uii-item>span {
  color: #050B20;
  font-size: 15px;
  margin-bottom: -5px;
}

.uii-item h3 {
  margin: 0;
  color: #050B20;
  font-size: 30px;
  font-weight: 700;
}

.graph-content {
  margin-top: 70px;
  /* Medium devices */
}

@media (max-width: 991px) {
  .graph-content {
    margin-top: 0;
  }
}

.widget-graph {
  border: 1px solid #E1E1E1;
  border-radius: 16px;
  padding: 30px;
  height: 100%;
}

.graph-head {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding-right: 30px;
}

.graph-head>h3 {
  color: #050B20;
  font-size: 18px;
  font-weight: 500;
}

.graph-head .text-box .form_boxes {
  margin-right: 30px;
}

.graph-head .text-box .form_boxes:last-child {
  margin-right: 0;
}

.ls-widget {
  position: relative;
  background: #ffffff;
  border-radius: 16px;
  border: 1px solid #E1E1E1;
  /* Large devices */
}

@media (max-width: 1199px) {
  .ls-widget {
    margin-top: 40px;
  }
}

.ls-widget .widget-title {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  background: transparent;
  padding: 20px 30px 30px;
  min-height: 60px;
}

.ls-widget .widget-title h4 {
  position: relative;
  font-size: 18px;
  line-height: 30px;
  color: #1b2032;
  font-weight: 500;
  margin-right: 30px;
  margin-bottom: 0;
  padding: 0;
}

.ls-widget .widget-title h4 .icon {
  position: absolute;
  left: 0;
  top: 0px;
  line-height: 30px;
  color: #9fa9b8;
}

.dash-btn {
  display: block;
  border: 1px solid #405FF2;
  border-radius: 12px;
  width: 100%;
  text-align: center;
  font-size: 15px;
  font-weight: 500;
  text-transform: capitalize;
  height: 54px;
  line-height: 54px;
  color: #405FF2;
}

.dash-btn svg {
  margin-left: 10px;
}

.dash-btn svg path {
  fill: #405FF2;
}

.dash-btn:hover {
  background-color: #405FF2;
  color: #fff;
  border-color: transparent;
}

.dash-btn:hover svg path {
  fill: #fff;
}

.dash-btn-box {
  padding: 0 30px;
  margin-bottom: 30px;
}

/* Medium devices */
@media (max-width: 991px) {
  .dashboard-widget .content-column .inner-column {
    border-radius: 0;
  }
}

/* Extra small devices */
@media (max-width: 575px) {
  .header-style-ten .header-inner {
    padding: 0;
  }

  .search-table table thead th {
    width: 100%;
  }

  .search-table tr {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    text-align: center;
  }

  .search-table table thead th:first-child {
    border-radius: 0;
  }

  .search-table-head form {
    display: block;
  }

  .search-table table tbody td {
    width: 100%;
  }
}

/*** 

====================================================================
    contact-us section
====================================================================

***/
.contact-us-section {
  position: relative;
  padding: 52px 0 120px;
  border-radius: 80px;
  background-color: var(--theme-color-light);
}

.contact-us-section .map-sec {
  margin: 0 -152px;
}

.contact-us-section .map-sec .goole-iframe iframe {
  width: 100%;
  height: 600px;
  border-radius: 10px;
}

.contact-us-section .calculater-sec {
  padding-top: 60px;
}

.contact-us-section .calculater-sec .boxcar-container {
  max-width: 100%;
}

.contact-us-section .calculater-sec .content-column .inner-column {
  width: 100%;
  height: 100%;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  padding-right: 38px;
}

.contact-us-section .calculater-sec .content-column .inner-column .boxcar-title {
  margin-bottom: 21px;
  padding-left: 13px;
}

.contact-us-section .calculater-sec .content-column form {
  width: 100%;
  margin: 0;
}

.contact-us-section .calculater-sec .content-column form .form_boxes {
  border-radius: 12px;
  border: 1px solid var(--Border, #E1E1E1);
  background: var(--color-white, #FFF);
  border-radius: 12px;
  padding: 7px 15px;
  margin-bottom: 30px;
}

.contact-us-section .calculater-sec .content-column form .form_boxes label {
  display: block;
  color: #818181;
  font-size: 13px;
  font-weight: 400;
}

.contact-us-section .calculater-sec .content-column form .form_boxes .drop-menu {
  height: unset;
}

.contact-us-section .calculater-sec .content-column form .form_boxes .drop-menu .select {
  padding: 0;
  line-height: unset;
}

.contact-us-section .calculater-sec .content-column form .form_boxes .drop-menu .dropdown {
  padding: 5px 15px;
  margin-top: 10px;
  border-radius: 12px;
  left: -15px;
  width: calc(100% + 30px);
  background-color: var(--theme-color-light);
  -webkit-box-shadow: 30px 30px 60px 0px rgba(0, 0, 1, 0.05);
  box-shadow: 30px 30px 60px 0px rgba(0, 0, 1, 0.05);
}

.contact-us-section .calculater-sec .content-column form .form_boxes.v2 {
  height: 220px;
}

.contact-us-section .calculater-sec .content-column form .theme-btn {
  background: #405FF2;
  color: #fff;
  height: 54px;
  line-height: 54px;
  border-radius: 12px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 0 26px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.contact-us-section .calculater-sec .content-column form .theme-btn svg {
  margin-left: 10px;
}

.contact-us-section .calculater-sec .content-column form .theme-btn.v2 {
  width: 100%;
}

.contact-us-section .calculater-sec .contact-column .inner-column {
  border-radius: 16px;
  border: 1px solid var(--Border, #E1E1E1);
  background: var(--White, #FFF);
  padding: 32px 41px 47px;
  margin-left: 38px;
}

.contact-us-section .calculater-sec .contact-column .inner-column .boxcar-title {
  margin-bottom: 38px;
}

.contact-us-section .calculater-sec .contact-column .inner-column .boxcar-title .title {
  font-weight: 500;
}

.contact-us-section .calculater-sec .contact-column .inner-column .boxcar-title .text {
  line-height: 26px;
  margin-top: 0;
}

.contact-us-section .calculater-sec .contact-column .inner-column .content-box {
  padding-left: 36px;
  margin-bottom: 24px;
}

.contact-us-section .calculater-sec .contact-column .inner-column .content-box .title {
  position: relative;
  font-size: 15px;
  font-weight: 500;
  margin-bottom: 2px;
}

.contact-us-section .calculater-sec .contact-column .inner-column .content-box .title .icon {
  position: absolute;
  top: 0px;
  left: -36px;
  font-size: 26px;
}

.contact-us-section .calculater-sec .contact-column .inner-column .social-icons {
  margin-top: 26px;
}

.contact-us-section .calculater-sec .contact-column .inner-column .social-icons .title {
  font-size: 15px;
  font-weight: 500;
  margin-bottom: 15px;
}

.contact-us-section .calculater-sec .contact-column .inner-column .social-icons .social-links {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-left: -15px;
}

.contact-us-section .calculater-sec .contact-column .inner-column .social-icons .social-links li a {
  border-radius: 50px;
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  display: inline-block;
}

.contact-us-section .calculater-sec .contact-column .inner-column .social-icons .social-links li a:hover {
  background: var(--bg-1, #F9FBFC);
}

.contact-us-section .ofice-section {
  position: relative;
  padding: 120px 0 0;
}

.contact-us-section .ofice-section .ofice-block .inner-box .title {
  font-size: 18px;
  font-weight: 500;
  line-height: 32px;
  margin-bottom: 13px;
}

.contact-us-section .ofice-section .ofice-block .inner-box .text {
  line-height: 26px;
  margin-bottom: 6px;
}

.contact-us-section .ofice-section .ofice-block .inner-box .ofice-btn {
  font-size: 15px;
  font-family: var(--title-font);
  font-weight: 500;
  display: inline-block;
  color: var(--theme-color-dark);
}

.contact-us-section .ofice-section .ofice-block .inner-box .ofice-btn svg {
  margin-left: 9px;
  position: relative;
  top: -2px;
}

.contact-us-section .ofice-section .ofice-block .inner-box .contact-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-top: 22px;
}

.contact-us-section .ofice-section .ofice-block .inner-box .contact-list li {
  position: relative;
  font-size: 15px;
  color: var(--theme-color-dark);
  padding-left: 36px;
  margin-right: 30px;
}

.contact-us-section .ofice-section .ofice-block .inner-box .contact-list li:last-child {
  margin-right: 0;
}

.contact-us-section .ofice-section .ofice-block .inner-box .contact-list li .icon {
  position: absolute;
  top: 0;
  left: 0;
  font-size: 26px;
}

.contact-block .inner-box {
  border-radius: 0px 16px 16px 0px;
  background: #E9F2FF;
  max-width: 450px;
  margin-left: auto;
  padding: 128px 52px 127px;
}

.contact-block .inner-box .content-box {
  margin-bottom: 43px;
}

.contact-block .inner-box .content-box:last-child {
  margin-bottom: 0;
}

.contact-block .inner-box .content-box .title {
  font-weight: 500;
  line-height: 30px;
  margin-bottom: 3px;
}

.contact-block .inner-box .content-box span {
  font-size: 18px;
  font-weight: 500;
  color: var(--theme-color1);
  line-height: 32px;
  display: block;
}

.contact-us-section.v2 .right-box {
  border-radius: 16px;
  border: 1px solid var(--Border, #E1E1E1);
  background: var(--theme-color-light);
}

.contact-us-section.v2 .right-box .content-column .inner-column {
  padding: 51px 38px 51px 60px;
}

/*** 

====================================================================
    inventory section
====================================================================

***/
.inventory-section {
  background-color: var(--theme-color-light);
  position: relative;
  padding: 52px 0 120px;
  border-radius: 80px;
}

.inventory-section .overview-sec.v2 {
  margin-top: 42px;
}

.inventory-section .boxcar-title-three {
  position: relative;
  margin-bottom: 30px;
}

.inventory-section .boxcar-title-three h2 {
  margin-bottom: 0;
}

.inventory-section .boxcar-title-three .text {
  font-size: 15px;
  color: var(--theme-color-dark);
  line-height: 27px;
  margin-bottom: 20px;
  font-weight: 400;
}

.inventory-section .boxcar-title-three .spectes-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
}

/* .inventory-section .boxcar-title-three .spectes-list li {
  margin-right: 10px;
} */

.inventory-section .boxcar-title-three .spectes-list li:last-child {
  margin-right: 0;
}

.inventory-section .boxcar-title-three .spectes-list li span {
  font-size: 15px;
  color: var(--theme-color1);
  display: inline-block;
  border-radius: 120px;
  background: var(--bg-3, #E9F2FF);
  padding: 7px 20px 5px;
}

.inventory-section .boxcar-title-three .spectes-list li span img {
  margin-right: 10px;
  position: relative;
  top: -2px;
}

.inventory-section .boxcar-title-three .spectes-list.v2 li span {
  background-color: unset;
  color: var(--theme-color-dark);
  border: 1px solid var(--Border, #E1E1E1);
}

.inventory-section .boxcar-title-three .content-box {
  position: absolute;
  bottom: 0;
  right: 0;
  text-align: right;
}

.inventory-section .boxcar-title-three .content-box .btn-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 12px;
}

.inventory-section .boxcar-title-three .content-box .btn-box .share-btn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-right: 18px;
}

.inventory-section .boxcar-title-three .content-box .btn-box .share-btn:last-child {
  margin-right: 0;
}

.inventory-section .boxcar-title-three .content-box .btn-box .share-btn span {
  font-size: 15px;
  color: var(--theme-color-dark);
  display: inline-block;
  margin-right: 10px;
}

.inventory-section .boxcar-title-three .content-box .btn-box .share-btn .share {
  fill: var(--White, #FFF);
  border: 1px solid #E1E1E1;
  border-radius: 50%;
  width: 36px;
  height: 36px;
  line-height: 32px;
  text-align: center;
  -webkit-filter: drop-shadow(0px 10px 40px rgba(0, 0, 0, 0.05));
  filter: drop-shadow(0px 10px 40px rgba(0, 0, 0, 0.05));
}

.inventory-section .boxcar-title-three .content-box .btn-box.v2 {
  margin-bottom: 0;
}

.inventory-section .boxcar-title-three .content-box .title {
  font-weight: 700;
  margin-bottom: 4px;
}

.inventory-section .boxcar-title-three .content-box span {
  font-size: 15px;
  font-weight: 500;
  color: var(--theme-color-dark);
}

.inventory-section .boxcar-title-three .content-box span i {
  margin-right: 10px;
}

.inventory-section .gallery-sec .slick-next,
.inventory-section .gallery-sec .slick-prev {
  border: 0;
  background-color: rgba(143, 143, 153, 0.9);
}

.inventory-section .gallery-sec .slick-prev::before,
.inventory-section .gallery-sec .slick-next::before {
  color: var(--theme-color-light);
}

.inventory-section .gallery-sec .slick-next {
  right: 10px;
}

.inventory-section .gallery-sec .slick-prev {
  left: 10px;
  z-index: 9;
}

.inventory-section .gallery-sec .image-column .inner-column .image-box {
  position: relative;
}

.inventory-section .gallery-sec .image-column .inner-column .image-box .image {
  border-radius: 16px 0px 0px 16px;
  overflow: hidden;
}

.inventory-section .gallery-sec .image-column .inner-column .image-box .image img {
  width: 100%;
}

.inventory-section .gallery-sec .image-column .inner-column .image-box .content-box {
  position: absolute;
  bottom: 10px;
  left: 10px;
  width: 100%;
}

.inventory-section .gallery-sec .image-column .inner-column .image-box .content-box .video-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.inventory-section .gallery-sec .image-column .inner-column .image-box .content-box .video-list li {
  margin-right: 10px;
}

.inventory-section .gallery-sec .image-column .inner-column .image-box .content-box .video-list li:last-child {
  margin-right: 0;
  margin-left: auto;
  left: auto;
  right: 20px;
  position: relative;
}

.inventory-section .gallery-sec .image-column .inner-column .image-box .content-box .video-list li a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 15px;
  color: var(--theme-color-dark);
  background-color: var(--theme-color-light);
  padding: 7px 20px 5px;
  border-radius: 12px;
}

.inventory-section .gallery-sec .image-column .inner-column .image-box .content-box .video-list li a img {
  margin-right: 10px;
}

.inventory-section .gallery-sec .image-column-two .inner-column {
  /* margin-bottom: 34px; */
  /* height: 100%; */
}

.inventory-section .gallery-sec .image-column-two .inner-column .image-box {
  position: relative;
  display: inline-block;
}

.inventory-section .gallery-sec .image-column-two .inner-column .image-box:hover .content-box {
  opacity: 1;
  visibility: visible;
}

.inventory-section .gallery-sec .image-column-two .inner-column .image-box .content-box {
  position: absolute;
  bottom: 10px;
  right: 10px;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  width: 100%;
}

.inventory-section .gallery-sec .image-column-two .inner-column .image-box .content-box .video-list {
  width: 100%;
}

.inventory-section .gallery-sec .image-column-two .inner-column .image-box .content-box .video-list li a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 15px;
  color: var(--theme-color-dark);
  background-color: var(--theme-color-light);
  padding: 7px 20px 5px;
  border-radius: 12px;
}

.inventory-section .gallery-sec .image-column-two .inner-column .image-box .content-box .video-list li a img {
  margin-right: 10px;
}

.inventory-section .gallery-sec .image-column-two .inner-column .image-box .content-box .video-list li:last-child {
  margin-left: auto;
  position: absolute;
  bottom: 10px;
  right: 10px;
}

.inventory-section .gallery-sec-two {
  margin-bottom: 100px;
  padding: 0 60px;
}

@media (max-width: 1440px) {
  .inventory-section .gallery-sec-two {
    padding: 0 30px;

  }

}

@media (max-width: 991px) {
  .inventory-section .gallery-sec-two {
    padding: 0 15px;
    margin-bottom: 60px;
  }

}

.gallery-sec-two .wrap-slider-gallery {
  border-radius: 16px;
  overflow: hidden;
}

.inventory-section .gallery-sec-two .slick-next {
  right: 10px;
}

.inventory-section .gallery-sec-two .slick-prev {
  left: 10px;
  z-index: 99;
}

.inventory-section .gallery-sec-two .slick-next,
.inventory-section .gallery-sec-two .slick-prev {
  background-color: #8f8c95;
  border: 0;
}

.inventory-section .gallery-sec-two .slick-prev::before,
.inventory-section .gallery-sec-two .slick-next::before {
  color: var(--theme-color-light);
}

.inventory-section .gallery-sec-two .image-column {
  margin: 0 15px;
}

.inventory-section .gallery-sec-two .image-column .inner-column .image-box {
  position: relative;
}

.inventory-section .gallery-sec-two .image-column .inner-column .image-box .image {
  border-radius: 16px;
  overflow: hidden;
}

.inventory-section .gallery-sec-two .image-column .inner-column .image-box .image img {
  width: 100%;
  height: 550px;
  -o-object-fit: cover;
  object-fit: cover;
}

@media (max-width: 500px) {
  .inventory-section .gallery-sec-two .image-column .inner-column .image-box .image img {
    height: 400px;
  }

  .cars-section-eight .nav-tabs {
    justify-content: flex-start;
    row-gap: 10px;
  }

}

.inventory-section .gallery-sec-two .image-column .inner-column .image-box .content-box {
  position: absolute;
  bottom: 10px;
  left: 10px;
  width: 100%;
}

.inventory-section .gallery-sec-two .image-column .inner-column .image-box .content-box .video-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.inventory-section .gallery-sec-two .image-column .inner-column .image-box .content-box .video-list li {
  margin-right: 10px;
}

.inventory-section .gallery-sec-two .image-column .inner-column .image-box .content-box .video-list li:last-child {
  margin-right: 0;
  margin-left: auto;
  left: auto;
  right: 20px;
  position: relative;
}

.inventory-section .gallery-sec-two .image-column .inner-column .image-box .content-box .video-list li a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 15px;
  color: var(--theme-color-dark);
  background-color: var(--theme-color-light);
  padding: 7px 20px 5px;
  border-radius: 12px;
}

.inventory-section .gallery-sec-two .image-column .inner-column .image-box .content-box .video-list li a img {
  margin-right: 10px;
}

.inventory-section .inspection-column {
  margin-top: 9px;
}

.inventory-section .inspection-column .inner-column .title {
  font-weight: 500;
  font-size: 26px;
  margin-bottom: 27px;
}

.inventory-section .inspection-column .inner-column .overview-sec {
  border-bottom: 1px solid #E1E1E1;
  padding-bottom: 26px;
}

.inventory-section .inspection-column .inner-column .overview-sec .content-column .inner-column .list li {
  font-size: 15px;
  color: var(--theme-color-dark);
  font-weight: 500;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 20px;
}

.inventory-section .inspection-column .inner-column .overview-sec .content-column .inner-column .list li span {
  font-weight: 400;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 198px;
  display: inline-block;
}

.inventory-section .inspection-column .inner-column .overview-sec .content-column .inner-column .list li span img {
  margin-right: 15px;
  position: relative;
  top: -2px;
}

.inventory-section .inspection-column .inner-column .overview-sec-two {
  margin-top: 52px;
  border-bottom: 1px solid #E1E1E1;
  padding-bottom: 50px;
}

.inventory-section .inspection-column .inner-column .overview-sec-two .list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 30px;
  flex-wrap: wrap;
}

.inventory-section .inspection-column .inner-column .overview-sec-two .list li {
  width: 160px;
  height: 150px;
  border-radius: 16px;
  border: 1px solid var(--Border, #E1E1E1);
  background: var(--theme-color-light);
  text-align: center;
  padding: 32px 0;
  /* margin-right: 30px; */
}

.boxcar-testimonial-section.home1 .boxcar-title {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}

.boxcar-testimonial-section.home1 .boxcar-title h2 {
  margin-bottom: 0;
}

.boxcar-testimonial-section.home1 .boxcar-title .text {
  margin-top: 0;

}

@media (max-width: 600px) {
  .compare-section .compare-products table tr {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    /* -ms-flex-wrap: wrap;
    flex-wrap: wrap; */
  }

  .compare-section .compare-products table tr th,
  .compare-section .compare-products table tr td {
    width: 100%;
    flex-shrink: 0;
  }
}

@media (max-width: 500px) {
  .inventory-section .inspection-column .inner-column .overview-sec-two .list li {
    width: 100%;
  }

}

.inventory-section .inspection-column .inner-column .overview-sec-two .list li:last-child {
  margin-right: 0;
}

.inventory-section .inspection-column .inner-column .overview-sec-two .list li img {
  margin-bottom: 8px;
}

.inventory-section .inspection-column .inner-column .overview-sec-two .list li span {
  display: block;
  color: #818181;
  margin-bottom: -2px;
}

.inventory-section .inspection-column .inner-column .overview-sec-two .list li small {
  font-size: 16px;
  font-weight: 500;
  color: var(--theme-color-dark);
}

.inventory-section .inspection-column .inner-column .description-sec {
  margin-top: 52px;
  border-bottom: 1px solid #E1E1E1;
  padding-bottom: 50px;
}

.inventory-section .inspection-column .inner-column .description-sec .title {
  font-size: 26px;
  font-weight: 500;
  margin-bottom: 23px;
}

.inventory-section .inspection-column .inner-column .description-sec .text {
  font-size: 15px;
  color: var(--theme-color-dark);
}

.inventory-section .inspection-column .inner-column .description-sec .text.two {
  margin-bottom: 24px;
}

.inventory-section .inspection-column .inner-column .description-sec .des-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-top: 38px;
}

.inventory-section .inspection-column .inner-column .description-sec .des-list li {
  margin-right: 18px;
}

.inventory-section .inspection-column .inner-column .description-sec .des-list li:last-child {
  margin-right: 0;
}

.inventory-section .inspection-column .inner-column .description-sec .des-list li .item {
  display: inline-block;
  font-size: 15px;
  font-weight: 500;
  color: var(--theme-color-dark);
  padding: 14px 26px 12px;
  border-radius: 12px;
  background: var(--bg-2, #EEF1FB);
}

.inventory-section .inspection-column .inner-column .description-sec .des-list li .item img {
  margin-right: 10px;
  position: relative;
  top: -1px;
}

.inventory-section .inspection-column .inner-column .description-sec .des-list .two .item {
  background: var(--bg-3, #E9F2FF);
}

.inventory-section .inspection-column .inner-column .description-sec .des-list .three .item {
  background: var(--bg-4, #FFE9F3);
}

.inventory-section .inspection-column .inner-column .features-sec {
  margin-top: 52px;
  border-bottom: 1px solid #E1E1E1;
  padding-bottom: 44px;
}

.inventory-section .inspection-column .inner-column .features-sec .list-column .inner-column .title {
  font-size: 18px;
  font-weight: 500;
}

.inventory-section .inspection-column .inner-column .features-sec .list-column .inner-column .feature-list li {
  font-size: 15px;
  color: var(--theme-color-dark);
  margin-bottom: 13px;
}

.inventory-section .inspection-column .inner-column .features-sec .list-column .inner-column .feature-list li:last-child {
  margin-bottom: 0;
}

.inventory-section .inspection-column .inner-column .features-sec .list-column .inner-column .feature-list li i {
  color: var(--theme-color1);
  background-color: #eef0fc;
  width: 21px;
  height: 21px;
  line-height: 21px;
  text-align: center;
  border-radius: 50%;
  font-size: 12px;
  margin-right: 10px;
}

.inventory-section .inspection-column .inner-column .features-sec.v2 {
  padding-bottom: 36px;
}

.inventory-section .inspection-column .inner-column .features-sec.v2 .list-column {
  margin-bottom: 30px;
}

.inventory-section .inspection-column .inner-column .faqs-section {
  margin-top: 52px;
}

.inventory-section .inspection-column .inner-column .faqs-section .title {
  font-weight: 500;
  font-size: 26px;
  margin-bottom: 8px;
}

.inventory-section .inspection-column .inner-column .faqs-section .widget-accordion {
  border-bottom: 1px solid #E1E1E1;
  padding-bottom: 30px;
}

.inventory-section .inspection-column .inner-column .faqs-section .accordion.block {
  border-bottom: 1px solid #E1E1E1;
}

.inventory-section .inspection-column .inner-column .faqs-section .accordion.block.v2 {
  border-bottom: 0;
}

.inventory-section .inspection-column .inner-column .faqs-section .widget-accordion .block:active {
  background-color: unset;
}

.inventory-section .inspection-column .inner-column .faqs-section .accordion.block.active-block {
  background-color: transparent;
}

.inventory-section .inspection-column .inner-column .faqs-section .widget-accordion .block {
  margin-bottom: 0;
  padding-bottom: 7px;
}

.inventory-section .inspection-column .inner-column .faqs-section .widget-accordion .block .acc-btn {
  font-size: 18px;
  font-family: var(--title-font);
  font-weight: 500;
  text-transform: capitalize;
  color: var(--theme-color-dark);
  border: 0;
  padding: 23px 0 15px;
}

.inventory-section .inspection-column .inner-column .faqs-section .widget-accordion .block .acc-content .content {
  padding: 0;
}

.inventory-section .inspection-column .inner-column .faqs-section .widget-accordion .block .acc-btn {
  background-color: unset;
}

.inventory-section .inspection-column .inner-column .faqs-section .widget-accordion .block.active-block .acc-btn {
  color: var(--theme-color-dark);
  background-color: transparent;
}

.inventory-section .inspection-column .inner-column .faqs-section .widget-accordion .block .acc-btn .icon {
  height: 0;
  line-height: 0;
  top: 37px;
}

.inventory-section .inspection-column .inner-column .faqs-section .widget-accordion .block.active-block .acc-btn .icon {
  height: 0;
  line-height: 0;
  top: 37px;
}

.inventory-section .inspection-column .inner-column .faqs-section .widget-accordion .block.active-block .acc-btn .icon:before {
  content: "\f106";
}

.inventory-section .inspection-column .inner-column .faqs-section .widget-accordion .block .acc-content {
  padding: 0;
}

.inventory-section .inspection-column .inner-column .faqs-section .list-column .inner-column {
  margin-bottom: 13px;
}

.inventory-section .inspection-column .inner-column .faqs-section .list-column .inner-column .spects-list li {
  font-size: 15px;
  color: var(--theme-color-dark);
  font-weight: 500;
  padding-bottom: 12px;
}

.inventory-section .inspection-column .inner-column .faqs-section .list-column .inner-column .spects-list li:last-child {
  padding-bottom: 0;
}

.inventory-section .inspection-column .inner-column .faqs-section .list-column .inner-column .spects-list li span {
  font-weight: 500;
  width: 207px;
  display: inline-block;
}

.inventory-section .inspection-column .inner-column .location-box {
  margin-top: 52px;
  border-bottom: 1px solid #E1E1E1;
  padding-bottom: 51px;
}

.inventory-section .inspection-column .inner-column .location-box .title {
  font-size: 26px;
  font-weight: 500;
  margin-bottom: 22px;
}

.inventory-section .inspection-column .inner-column .location-box .text {
  font-size: 15px;
  color: var(--theme-color-dark);
  max-width: 431px;
  margin-bottom: 14px;
}

.inventory-section .inspection-column .inner-column .location-box .brand-btn {
  font-size: 15px;
  font-weight: 500;
  color: var(--theme-color1);
  display: inline-block;
  margin-bottom: 22px;
}

.inventory-section .inspection-column .inner-column .location-box .brand-btn svg {
  margin-left: 9px;
  position: relative;
  top: -1px;
}

.inventory-section .inspection-column .inner-column .location-box iframe {
  width: 100%;
  height: 450px;
  border-radius: 20px;
}

.inventory-section .inspection-column .inner-column .contact-box-three {
  margin-top: 60px;
  border-bottom: 1px solid #E1E1E1;
  padding-bottom: 60px;
  position: relative;
}

.inventory-section .inspection-column .inner-column .contact-box-three .icon-box {
  position: absolute;
  top: 0;
  left: 0;
  width: 120px;
  height: 120px;
  line-height: 115px;
  text-align: center;
  border: 1px solid #e5e5e5;
  border-radius: 50%;
  margin-bottom: 14px;
}

.inventory-section .inspection-column .inner-column .contact-box-three .content-box .inner-box {
  padding-left: 140px;
}

.inventory-section .inspection-column .inner-column .contact-box-three .content-box .inner-box .title {
  font-weight: 500;
  margin-bottom: 5px;
}

.inventory-section .inspection-column .inner-column .contact-box-three .content-box .inner-box .text {
  font-size: 15px;
  color: var(--theme-color-dark);
  margin-bottom: 10px;
}

.inventory-section .inspection-column .inner-column .contact-box-three .content-box .inner-box .contact-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 30px;
}

.inventory-section .inspection-column .inner-column .contact-box-three .content-box .inner-box .contact-list li {
  margin-right: 16px;
}

.inventory-section .inspection-column .inner-column .contact-box-three .content-box .inner-box .contact-list li:last-child {
  margin-right: 0;
}

.inventory-section .inspection-column .inner-column .contact-box-three .content-box .inner-box .contact-list li a {
  font-size: 15px;
  color: var(--theme-color-dark);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.inventory-section .inspection-column .inner-column .contact-box-three .content-box .inner-box .contact-list li a .image-box {
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  border-radius: 50px;
  background-color: #E9F2FF;
  margin-right: 10px;
}

.inventory-section .inspection-column .inner-column .contact-box-three .content-box .btn-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.inventory-section .inspection-column .inner-column .contact-box-three .content-box .btn-box .side-btn {
  background-color: var(--theme-color1);
  color: var(--theme-color-light);
  font-size: 15px;
  font-weight: 500;
  height: 54px;
  line-height: 54px;
  text-align: center;
  display: inline-block;
  width: 100%;
  border-radius: 12px;
  margin-right: 20px;
}

.inventory-section .inspection-column .inner-column .contact-box-three .content-box .btn-box .side-btn svg {
  position: relative;
  top: -1px;
  margin-left: 9px;
}

.inventory-section .inspection-column .inner-column .contact-box-three .content-box .btn-box .side-btn.two {
  color: #60C961;
  border: 1px solid #60C961;
  background: var(--White, #FFF);
  margin-right: 0;
}

.inventory-section .inspection-column .inner-column .contact-box-three .content-box .btn-box .side-btn-three {
  color: var(--theme-color-dark);
  font-size: 15px;
  font-weight: 500;
  display: inline-block;
  width: 100%;
  text-align: center;
}

.inventory-section .inspection-column .inner-column .contact-box-three .content-box .btn-box .side-btn-three svg {
  position: relative;
  top: -1px;
  margin-left: 9px;
}

.inventory-section .inspection-column .inner-column .form-box {
  margin-top: 52px;
}

.inventory-section .inspection-column .inner-column .form-box .form-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-top: 22px;
}

.inventory-section .inspection-column .inner-column .form-box .form-list li {
  font-weight: 500;
  color: var(--theme-color-dark);
  font-size: 16px;
  margin-right: 93px;
}

.inventory-section .inspection-column .inner-column .form-box .form-list li:last-child {
  margin-right: 0;
}

.inventory-section .inspection-column .inner-column .form-box .form-list li span {
  display: inline-block;
  width: 165px;
  font-weight: 400;
  font-size: 15px;
}

.inventory-section .inspection-column .inner-column .form-box .theme-btn {
  padding: 0 60px;
}

.inventory-section .inspection-column .inner-column .review-sec {
  margin-top: 52px;
}

.inventory-section .inspection-column .inner-column .review-sec .title {
  font-size: 26px;
  font-weight: 500;
  margin-bottom: 30px;
}

.inventory-section .inspection-column .inner-column .review-sec .review-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.inventory-section .inspection-column .inner-column .review-sec .review-box .rating-box {
  position: relative;
  width: 200px;
  height: 200px;
  border: 10px solid #E9F2FF;
  border-radius: 50%;
  margin-right: 39px;
}

.inventory-section .inspection-column .inner-column .review-sec .review-box .rating-box .content-box {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 100%;
  text-align: center;
}

.inventory-section .inspection-column .inner-column .review-sec .review-box .rating-box .content-box span {
  color: var(--theme-color1);
  font-size: 15px;
  font-weight: 500;
  display: inline-block;
}

.inventory-section .inspection-column .inner-column .review-sec .review-box .rating-box .content-box .title {
  font-size: 40px;
  font-weight: 700;
  color: var(--theme-color1);
  margin-bottom: -1px;
  margin-top: -3px;
}

.inventory-section .inspection-column .inner-column .review-sec .review-box .rating-box .content-box small {
  font-size: 15px;
  font-weight: 500;
  color: var(--theme-color1);
}

.inventory-section .inspection-column .inner-column .review-sec .review-box .review-list {
  width: 303px;
  margin-top: 6px;
}

.inventory-section .inspection-column .inner-column .review-sec .review-box .review-list li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  border-bottom: 1px solid #E1E1E1;
  padding-bottom: 5px;
  margin-bottom: 16px;
}

.inventory-section .inspection-column .inner-column .review-sec .review-box .review-list li:last-child {
  padding-bottom: 0;
  border: 0;
  margin-bottom: 0;
}

.inventory-section .inspection-column .inner-column .review-sec .review-box .review-list li .review-title span {
  display: block;
  color: var(--theme-color-dark);
  font-weight: 500;
  margin-bottom: -5px;
}

.inventory-section .inspection-column .inner-column .review-sec .review-box .review-list li .review-title small {
  font-size: 15px;
  color: var(--theme-color-dark);
}

.inventory-section .inspection-column .inner-column .review-sec .review-box .review-list li sub {
  font-weight: 500;
  color: var(--theme-color-dark);
}

.inventory-section .inspection-column .inner-column .review-sec .review-box .review-list li sub i {
  color: var(--theme-color1);
  margin-right: 9px;
}

.inventory-section .inspection-column .inner-column .review-sec .review-box .review-list.two {
  margin-right: 54px;
}

.inventory-section .inspection-column .inner-column .review-sec.v2 .review-box {
  display: unset;
}

.inventory-section .inspection-column .inner-column .review-sec.v2 .review-box .rating-box {
  margin-right: 0;
  margin-bottom: 30px;
}

.inventory-section .inspection-column .inner-column .review-sec.v2 .content-box-three {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.inventory-section .inspection-column .inner-column .reviews {
  margin-top: 38px;
}

.inventory-section .inspection-column .inner-column .reviews .title {
  margin-bottom: 23px;
}

.inventory-section .inspection-column .inner-column .reviews .auther-name {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 16px;
}

.inventory-section .inspection-column .inner-column .reviews .auther-name span {
  font-size: 14px;
  font-weight: 500;
  width: 40px;
  height: 40px;
  line-height: 40px;
  background-color: var(--theme-color-dark);
  border-radius: 50%;
  text-align: center;
  color: var(--theme-color-light);
  display: inline-block;
  margin-right: 12px;
}

.inventory-section .inspection-column .inner-column .reviews .auther-name .name {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 0;
  margin-right: 17px;
}

.inventory-section .inspection-column .inner-column .reviews .auther-name small {
  font-size: 14px;
  color: var(--theme-color-dark);
}

.inventory-section .inspection-column .inner-column .reviews .rating-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.inventory-section .inspection-column .inner-column .reviews .rating-list .list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.inventory-section .inspection-column .inner-column .reviews .rating-list .list li {
  font-size: 10px;
  margin-right: 4px;
  color: var(--theme-color1);
}

.inventory-section .inspection-column .inner-column .reviews .rating-list .list li:last-child {
  margin-right: 0;
}

.inventory-section .inspection-column .inner-column .reviews .rating-list span {
  font-size: 15px;
  color: var(--theme-color-dark);
  font-weight: 500;
  margin-left: 12px;
}

.inventory-section .inspection-column .inner-column .reviews .text {
  font-size: 15px;
  color: var(--theme-color-dark);
  max-width: 849px;
}

.inventory-section .inspection-column .inner-column {
  margin-top: 23px;

}

.inventory-section .inspection-column .inner-column .image-box img {
  border-radius: 12px;
  margin-right: 16px;
}

.inventory-section .inspection-column .inner-column .image-box img:last-child {
  margin-right: 0;
}

.inventory-section .inspection-column .inner-column .btn-box .like-btn {
  font-size: 15px;
  color: #818181;
  margin-right: 28px;
}

.inventory-section .inspection-column .inner-column .btn-box .like-btn i {
  margin-right: 10px;
}

.inventory-section .inspection-column .inner-column .btn-box .like-btn:hover {
  color: var(--theme-color1);
}

.inventory-section .inspection-column .inner-column .content-box.two {
  margin-top: 29px;
  border-bottom: 1px solid #E1E1E1;
  padding-bottom: 60px;
}

.inventory-section .inspection-column .inner-column .content-box.two .text {
  margin-bottom: 27px;
}

.inventory-section .inspection-column .inner-column .content-box.two .review {
  font-size: 15px;
  color: var(--theme-color1);
  font-weight: 500;
  height: 54px;
  line-height: 54px;
  padding: 0 38px;
  border-radius: 12px;
  border: 1px solid var(--color-secondary, #405FF2);
  background: var(--White, #FFF);
  display: inline-block;
  margin-top: 30px;
}

.inventory-section .inspection-column .inner-column .content-box.two .review svg {
  margin-left: 10px;
  position: relative;
  top: -1px;
}

.inventory-section .inspection-column .inner-column .Reply-sec {
  margin-top: 52px;
  margin-bottom: 20px;
}

.inventory-section .inspection-column .inner-column .Reply-sec .text {
  margin-bottom: 30px;
}

.inventory-section .inspection-column .inner-column .Reply-sec .right-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.inventory-section .inspection-column .inner-column .Reply-sec .right-box .rating-list {
  width: 330px;
}

.inventory-section .inspection-column .inner-column .Reply-sec .right-box .rating-list .list-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-bottom: 20px;
}

.inventory-section .inspection-column .inner-column .Reply-sec .right-box .rating-list .list-box span {
  font-size: 15px;
  color: var(--theme-color-dark);
}

.inventory-section .inspection-column .inner-column .Reply-sec .right-box .rating-list .list-box .list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.inventory-section .inspection-column .inner-column .Reply-sec .right-box .rating-list .list-box .list li {
  font-size: 12px;
  color: var(--theme-color1);
  margin-right: 4px;
}

.inventory-section .inspection-column .inner-column .Reply-sec .right-box .rating-list .list-box .list li:last-child {
  margin-right: 0;
}

.inventory-section .inspection-column .inner-column .Reply-sec .right-box .rating-list.two {
  margin-right: 144px;
}

.inventory-section .inspection-column .inner-column form {
  width: 100%;
  margin: 0;
  margin-left: -12px;
}

.inventory-section .inspection-column .inner-column form .form_boxes {
  border-radius: 12px;
  border: 1px solid rgb(225, 225, 225);
  background: var(--color-white, #FFF);
  padding: 7px 15px;
  margin-bottom: 30px;
}

.inventory-section .inspection-column .inner-column form .form_boxes textarea {
  width: 100%;
  height: 200px;
}

.inventory-section .inspection-column .inner-column form .form_boxes textarea::-webkit-input-placeholder {
  color: var(--theme-color-dark);
}

.inventory-section .inspection-column .inner-column form .form_boxes textarea::-moz-placeholder {
  color: var(--theme-color-dark);
}

.inventory-section .inspection-column .inner-column form .form_boxes textarea:-ms-input-placeholder {
  color: var(--theme-color-dark);
}

.inventory-section .inspection-column .inner-column form .form_boxes textarea::-ms-input-placeholder {
  color: var(--theme-color-dark);
}

.inventory-section .inspection-column .inner-column form .form_boxes textarea::placeholder {
  color: var(--theme-color-dark);
}

.inventory-section .inspection-column .inner-column form .form_boxes label {
  display: block;
  color: #818181;
  font-size: 15px;
  font-weight: 400;
}

.inventory-section .inspection-column .inner-column form .form_boxes .drop-menu {
  height: unset;
}

.inventory-section .inspection-column .inner-column form .form_boxes .drop-menu .select {
  padding: 0;
  line-height: unset;
}

.inventory-section .inspection-column .inner-column form .form_boxes .drop-menu .dropdown {
  padding: 5px 15px;
  margin-top: 10px;
  border-radius: 12px;
  left: -15px;
  width: calc(100% + 30px);
  -webkit-box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.08);
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.08);
}

.inventory-section .inspection-column .inner-column form .form_boxes.v2 {
  padding-top: 14px;
}

.inventory-section .inspection-column .inner-column form .theme-btn {
  background: #405FF2;
  color: #fff;
  height: 54px;
  line-height: 54px;
  border-radius: 12px;
  padding: 0 26px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.inventory-section .inspection-column .inner-column form .theme-btn svg {
  margin-left: 10px;
}

.inventory-section .inspection-column.v2 {
  margin-top: -23px;
}

.inventory-section .side-bar-column {
  margin-top: 16px;
}

.inventory-section .side-bar-column .inner-column .contact-box {
  border-radius: 16px;
  border: 1px solid var(--Border, #E1E1E1);
  padding: 30px 30px 26px;
  box-shadow: 0px 10px 40px 0px rgba(0, 0, 0, 0.05);
  background-color: var(--theme-color-light);
}

.inventory-section .side-bar-column .inner-column .contact-box .icon-box {
  width: 80px;
  height: 80px;
  line-height: 75px;
  text-align: center;
  border: 1px solid #e5e5e5;
  border-radius: 50%;
  margin-bottom: 14px;
}

.inventory-section .side-bar-column .inner-column .contact-box .content-box .title {
  font-weight: 500;
  margin-bottom: 5px;
}

.inventory-section .side-bar-column .inner-column .contact-box .content-box .text {
  font-size: 15px;
  color: var(--theme-color-dark);
  margin-bottom: 10px;
}

.inventory-section .side-bar-column .inner-column .contact-box .content-box .contact-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 30px;
}

.inventory-section .side-bar-column .inner-column .contact-box .content-box .contact-list li {
  margin-right: 16px;
}

.inventory-section .side-bar-column .inner-column .contact-box .content-box .contact-list li:last-child {
  margin-right: 0;
}

.inventory-section .side-bar-column .inner-column .contact-box .content-box .contact-list li a {
  font-size: 15px;
  color: var(--theme-color-dark);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.inventory-section .side-bar-column .inner-column .contact-box .content-box .contact-list li a .image-box {
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  border-radius: 50px;
  background-color: #E9F2FF;
  margin-right: 10px;
}

.inventory-section .side-bar-column .inner-column .contact-box .content-box .btn-box .side-btn {
  background-color: var(--theme-color1);
  color: var(--theme-color-light);
  font-size: 15px;
  font-weight: 500;
  height: 54px;
  line-height: 54px;
  text-align: center;
  display: inline-block;
  width: 100%;
  border-radius: 12px;
  margin-bottom: 20px;
}

.inventory-section .side-bar-column .inner-column .contact-box .content-box .btn-box .side-btn svg {
  position: relative;
  top: -1px;
  margin-left: 9px;
}

.inventory-section .side-bar-column .inner-column .contact-box .content-box .btn-box .side-btn.two {
  color: #60C961;
  border: 1px solid #60C961;
  background: var(--White, #FFF);
  margin-bottom: 15px;
}

.inventory-section .side-bar-column .inner-column .contact-box .content-box .btn-box .side-btn.two:hover {
  background: #60C961 !important;
}

.inventory-section .side-bar-column .inner-column .contact-box .content-box .btn-box .side-btn-three {
  color: var(--theme-color-dark);
  font-size: 15px;
  font-weight: 500;
  display: inline-block;
  width: 100%;
  text-align: center;
}

.inventory-section .side-bar-column .inner-column .contact-box .content-box .btn-box .side-btn-three svg {
  position: relative;
  top: -1px;
  margin-left: 9px;
}

.inventory-section .side-bar-column .inner-column .contact-box-two {
  border-radius: 16px;
  border: 1px solid var(--Border, #E1E1E1);
  padding: 33px 30px 30px;
  margin-bottom: 30px;
}

.inventory-section .side-bar-column .inner-column .contact-box-two span {
  color: var(--theme-color-dark);
}

.inventory-section .side-bar-column .inner-column .contact-box-two .title {
  font-weight: 700;
  margin-bottom: 0;
  line-height: 44px;
}

.inventory-section .side-bar-column .inner-column .contact-box-two small {
  font-size: 16px;
  color: var(--theme-color-dark);
}

.inventory-section .side-bar-column .inner-column .contact-box-two .btn-box {
  margin-top: 21px;
}

.inventory-section .side-bar-column .inner-column .contact-box-two .btn-box .side-btn {
  background-color: var(--theme-color1);
  color: var(--theme-color-light);
  font-size: 15px;
  font-weight: 500;
  height: 54px;
  line-height: 54px;
  text-align: center;
  display: inline-block;
  width: 100%;
  border-radius: 12px;
  margin-bottom: 20px;
}

.inventory-section .side-bar-column .inner-column .contact-box-two .btn-box .side-btn img {
  margin-right: 10px;
}

.inventory-section .side-bar-column .inner-column .contact-box-two .btn-box .side-btn.two {
  background: var(--White, #FFF);
  margin-bottom: 20px;
  color: var(--theme-color-dark);
  border: 1px solid var(--theme-color-dark);
}

.inventory-section .side-bar-column.v2 {
  margin-top: 0;
}

.inventory-section .side-bar-column.v2 .inner-column {
  padding-left: 49px;
}

.inventory-section .side-bar-column.v3 .contact-box-two {
  padding: 0;
  border: 0;
}

.inventory-section .side-bar-column.v3 .contact-box-two .btn-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.inventory-section .side-bar-column.v3 .contact-box-two .btn-box .side-btn {
  margin-right: 30px;
}

.inventory-section .side-bar-column.v3 .contact-box-two .btn-box .side-btn.two {
  margin-right: 0;
}

.inventory-section .side-bar-column.v3 .contact-box-two .content-box h2 {
  font-weight: 700;
  margin-bottom: 0;
}

.inventory-section .side-bar-column.v3 .contact-box-two .content-box .text {
  font-size: 15px;
  color: var(--theme-color-dark);
  line-height: 27px;
  margin-bottom: 12px;
}

.inventory-section .side-bar-column.v3 .contact-box-two .content-box .list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 13px;
}

.inventory-section .side-bar-column.v3 .contact-box-two .content-box .list li {
  position: relative;
  font-size: 14px;
  color: var(--theme-color-dark);
  margin-right: 18px;
}

.inventory-section .side-bar-column.v3 .contact-box-two .content-box .list li:last-child {
  margin-right: 0;
}

.inventory-section .side-bar-column.v3 .contact-box-two .content-box .list li:last-child::before {
  display: none;
}

.inventory-section .side-bar-column.v3 .contact-box-two .content-box .list li::before {
  content: "";
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  right: -11px;
  width: 4px;
  border-radius: 10px;
  height: 4px;
  background-color: #ABAAAA;
}

.inventory-section .side-bar-column.v3 .overview-box {
  border-radius: 16px;
  border: 1px solid var(--Border, #E1E1E1);
  background: var(--bg-1, #F9FBFC);
  padding: 21px 32px 22px;
}

.inventory-section .side-bar-column.v3 .overview-box .title {
  font-size: 26px;
  font-weight: 500;
  margin-bottom: 16px;
}

.inventory-section .side-bar-column.v3 .overview-box .list li {
  font-size: 15px;
  color: var(--theme-color-dark);
  font-weight: 500;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-bottom: 1px solid #e1e1e1;
  padding: 11px 0 9px;
}

.inventory-section .side-bar-column.v3 .overview-box .list li:last-child {
  border-bottom: 0;
  padding-bottom: 0;
}

.inventory-section .side-bar-column.v3 .overview-box .list li span {
  font-weight: 400;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 198px;
  display: inline-block;
}

.inventory-section .side-bar-column.v3 .overview-box .list li span img {
  margin-right: 15px;
  position: relative;
  top: -2px;
}

.inventory-section .side-bar-column.v4 .contact-box-two.v2 {
  border-radius: 16px;
  border: 1px solid var(--Border, #E1E1E1);
  background: var(--theme-color-light);
  -webkit-box-shadow: 0px 10px 40px 0px rgba(0, 0, 0, 0.05);
  box-shadow: 0px 10px 40px 0px rgba(0, 0, 0, 0.05);
  padding: 30px;
}

.inventory-section .side-bar-column.v4 .contact-box-two.v2 .btn-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.inventory-section .side-bar-column.v4 .contact-box-two.v2 .btn-box .side-btn {
  margin-right: 0;
  margin-bottom: 0;
}

.inventory-section .side-bar-column.v4 .contact-box-two.v2 .btn-box .side-btn.two {
  margin-right: 0;
}

.inventory-section .side-bar-column.v4 .contact-box-two.v2 .content-box h2 {
  font-weight: 700;
  margin-bottom: 0;
}

.inventory-section .side-bar-column.v4 .contact-box-two.v2 .content-box .text {
  font-size: 15px;
  color: var(--theme-color-dark);
  line-height: 27px;
  margin-bottom: 12px;
}

.inventory-section .side-bar-column.v4 .contact-box-two.v2 .content-box .list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 13px;
}

.inventory-section .side-bar-column.v4 .contact-box-two.v2 .content-box .list li {
  position: relative;
  font-size: 14px;
  color: var(--theme-color-dark);
  margin-right: 18px;
}

.inventory-section .side-bar-column.v4 .contact-box-two.v2 .content-box .list li:last-child {
  margin-right: 0;
}

.inventory-section .side-bar-column.v4 .contact-box-two.v2 .content-box .list li:last-child::before {
  display: none;
}

.inventory-section .side-bar-column.v4 .contact-box-two.v2 .content-box .list li::before {
  content: "";
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  right: -11px;
  width: 4px;
  border-radius: 10px;
  height: 4px;
  background-color: #ABAAAA;
}

.inventory-section .side-bar-column.v4 .overview-box {
  margin-bottom: 30px;
  box-shadow: 0px 10px 40px 0px rgba(0, 0, 0, 0.05);
  background-color: var(--theme-color-light);
}

.inventory-section .side-bar-column.v4 .overview-box .list.v2 li {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.inventory-section.v1 {
  background: var(--bg-1, #F9FBFC);
}

.inventory-section.v1 .description-sec.v2,
.inventory-section.v1 .features-sec.v2,
.inventory-section.v1 .faqs-section.v2,
.inventory-section.v1 .location-box.v2,
.inventory-section.v1 .form-box.v2,
.inventory-section.v1 .main-review-box,
.inventory-section.v1 .reply-c-box {
  border-radius: 16px;
  border: 1px solid var(--Border, #E1E1E1);
  background: var(--White, #FFF);
  -webkit-box-shadow: 0px 10px 40px 0px rgba(0, 0, 0, 0.05);
  box-shadow: 0px 10px 40px 0px rgba(0, 0, 0, 0.05);
  padding: 31px 40px 30px;
  margin-top: 30px;
}

.inventory-section.v1 .description-sec.v2 .form-list li,
.inventory-section.v1 .features-sec.v2 .form-list li,
.inventory-section.v1 .faqs-section.v2 .form-list li,
.inventory-section.v1 .location-box.v2 .form-list li,
.inventory-section.v1 .form-box.v2 .form-list li,
.inventory-section.v1 .main-review-box .form-list li,
.inventory-section.v1 .reply-c-box .form-list li {
  margin-right: 85px;
}

.inventory-section.v1 .description-sec.v2 .reviews .content-box.two,
.inventory-section.v1 .features-sec.v2 .reviews .content-box.two,
.inventory-section.v1 .faqs-section.v2 .reviews .content-box.two,
.inventory-section.v1 .location-box.v2 .reviews .content-box.two,
.inventory-section.v1 .form-box.v2 .reviews .content-box.two,
.inventory-section.v1 .main-review-box .reviews .content-box.two,
.inventory-section.v1 .reply-c-box .reviews .content-box.two {
  padding-bottom: 21px;
  border-bottom: 0;
}

.inventory-section.v1 .features-sec.v2 .list-column {
  margin-bottom: 0;
}

.inventory-section.v1 .faqs-section.v2 {
  padding-bottom: 7px;
}

.inventory-section.v1 .faqs-section.v2 .widget-accordion {
  padding-bottom: 0;
  border-bottom: 0;
}

.inventory-section.v1 .reply-c-box {
  padding-bottom: 40px;
}

.inventory-pager {
  padding: 50px 0 70px;
  background: #050B20;
}

.inventory-pager .inventory-form {
  max-width: 1162px;
  margin: 0 auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background: #fff;
  border-radius: 50px;
  padding: 0 10px;
  height: 76px;
}

.inventory-pager .inventory-form .form_boxes {
  width: 16.4%;
  height: 76px;
}

.inventory-pager .inventory-form .form_boxes a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: #050B20;
  font-size: 15px;
  height: 100%;
  margin-left: 30px;
}

.inventory-pager .inventory-form .form_boxes a img {
  margin-right: 10px;
}

.inventory-pager .inventory-form .form-submit .theme-btn {
  border-radius: 30px;
}

.inventory-pager .inventory-form .form-submit .theme-btn i {
  position: relative;
  top: 2px;
  margin-right: 8px;
}

.filter-btn {
  display: inline-block;
  padding: 0 35px 0 25px;
  height: 48px;
  line-height: 48px;
  border: 1px solid #e1e1e1;
  border-radius: 120px;
}

.header-style-v1.style-two.bb-0 {
  border-bottom: 0;
}

.inventory-sidebar .categories-box {
  padding: 0;
  margin-bottom: 25px;
  padding-bottom: 15px;
  border-top: 1px solid #E1E1E1;
  padding-top: 20px;
}

.inventory-sidebar .categories-box.border-none-bottom {
  margin: 0;
}

.inventory-sidebar .price-box {
  padding: 0;
}

.inventory-sidebar .price-box form .form-column {
  padding-right: 0;
}

.inventory-sidebar .ui-widget.ui-widget-content {
  margin-top: 25px;
}

.inventory-sidebar .show-more {
  display: inline-block;
  color: #405FF2;
  font-size: 15px;
}

.inventroy-widget {
  border: 1px solid #E1E1E1;
  padding: 20px;
  border-radius: 16px;
}

.inventroy-widget .row {
  margin-bottom: -20px;
}

.inventroy-widget .form_boxes {
  border-radius: 12px;
  border: 1px solid rgb(225, 225, 225);
  background: var(--color-white, #FFF);
  padding: 10px 15px;
  margin-bottom: 20px;
  width: 100%;
}

.inventroy-widget .form_boxes label {
  display: block;
  color: #818181;
  font-size: 13px;
  line-height: 16.93px;
  font-weight: 400;
  margin-bottom: 3px;
}

.inventroy-widget .form_boxes .drop-menu {
  height: unset;
}

.inventroy-widget .form_boxes .drop-menu .dropdown {
  top: 130%;
  border: 1px solid #E1E1E1;
  max-height: unset;
  border-radius: 10px;
}

.inventroy-widget .form_boxes .select {
  padding: 0;
  line-height: 19.53px;
}

.border-none-bottom {
  border-bottom: 0 !important;
}

.widget-sidebar-filter {
  position: fixed;
  top: 0;
  left: 0;
  width: 470px;
  height: 100%;
  background: #fff;
  z-index: 999999;
  overflow-y: auto;
  padding: 30px 50px;
  opacity: 0;
  visibility: hidden;
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.wrap-fixed-sidebar.active .widget-sidebar-filter {
  opacity: 1;
  visibility: visible;
  -webkit-transform: translateX(0);
  transform: translateX(0);
}

.wrap-fixed-sidebar .inventroy-widget {
  border-radius: 0;
  padding: 0;
  border: 0;
}

.wrap-fixed-sidebar .fixed-sidebar-title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-bottom: 1px solid #E1E1E1;
  margin-bottom: 40px;
  padding-bottom: 15px;
}

.wrap-fixed-sidebar .fixed-sidebar-title h3 {
  margin-bottom: 0;
  color: #050B20;
  font-size: 20px;
  font-weight: 500;
  text-transform: capitalize;
}

/*** 

====================================================================
    checkout-section
====================================================================

***/
.checkout-section {
  position: relative;
  padding: 52px 0 120px;
  border-radius: 80px;
  background-color: var(--theme-color-light);
}

.checkout-section .content-column .inner-column .title {
  font-weight: 500;
  margin-bottom: 23px;
}

.checkout-section .content-column .inner-column form {
  width: 100%;
  margin: 0;
}

.checkout-section .content-column .inner-column form .form-column {
  padding-right: 20px;
}

.checkout-section .content-column .inner-column form .form-column .title {
  font-weight: 500;
}

.checkout-section .content-column .inner-column form .form-column.v2 {
  padding-right: 0;
  padding-left: 20px;
}

.checkout-section .content-column .inner-column form .form_boxes {
  border-radius: 12px;
  border: 1px solid rgb(225, 225, 225);
  background: var(--color-white, #FFF);
  padding: 7px 15px;
  margin-bottom: 30px;
}

.checkout-section .content-column .inner-column form .form_boxes textarea {
  width: 100%;
  height: 290px;
}

.checkout-section .content-column .inner-column form .form_boxes textarea::-webkit-input-placeholder {
  color: var(--theme-color-dark);
}

.checkout-section .content-column .inner-column form .form_boxes textarea::-moz-placeholder {
  color: var(--theme-color-dark);
}

.checkout-section .content-column .inner-column form .form_boxes textarea:-ms-input-placeholder {
  color: var(--theme-color-dark);
}

.checkout-section .content-column .inner-column form .form_boxes textarea::-ms-input-placeholder {
  color: var(--theme-color-dark);
}

.checkout-section .content-column .inner-column form .form_boxes textarea::placeholder {
  color: var(--theme-color-dark);
}

.checkout-section .content-column .inner-column form .form_boxes label {
  display: block;
  color: #818181;
  font-size: 15px;
  font-weight: 400;
}

.checkout-section .content-column .inner-column form .form_boxes .drop-menu {
  height: unset;
}

.checkout-section .content-column .inner-column form .form_boxes .drop-menu .select {
  padding: 0;
  line-height: unset;
}

.checkout-section .content-column .inner-column form .form_boxes .drop-menu .select i {
  right: 0;
  top: -13px;
}

.checkout-section .content-column .inner-column form .form_boxes .drop-menu .dropdown {
  padding: 5px 15px;
  margin-top: 10px;
  border-radius: 12px;
  left: -15px;
  width: calc(100% + 30px);
  -webkit-box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.08);
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.08);
}

.checkout-section .content-column .inner-column form .form_boxes.v2 {
  padding-top: 15px;
}

.checkout-section .side-bar {
  padding-left: 42px;
}

.checkout-section .side-bar .order-box {
  border-radius: 16px;
  border: 1px solid var(--Border, #E1E1E1);
  background: var(--theme-color-light);
  padding: 24px 16px 35px;
}

.checkout-section .side-bar .order-box .title {
  font-weight: 500;
  color: var(--theme-color-dark);
  margin-bottom: 20px;
}

.checkout-section .side-bar .order-box .order-list li {
  font-size: 15px;
  color: var(--theme-color-dark);
  font-weight: 500;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  border-bottom: 1px solid #E9E9E9;
  padding-bottom: 9px;
  margin-bottom: 11px;
}

.checkout-section .side-bar .order-box .order-list li:last-child {
  margin-bottom: 0;
  padding-bottom: 0;
  border-bottom: 0;
}

.checkout-section .side-bar .order-box .order-list .v2 {
  border-bottom: 0;
  color: var(--theme-color1);
  padding-top: 7px;
}

.checkout-section .side-bar .order-box .order-list .v2.v3 {
  padding-top: 5px;
  margin-bottom: 9px;
}

/*** 

====================================================================
    dealler-section
====================================================================

***/
.dealer-ship-section {
  background-color: var(--theme-color-light);
  position: relative;
  padding: 52px 0 120px;
  border-radius: 80px;
}

.dealer-ship-section .boxcar-title h2 {
  margin-bottom: 48px;
}

.dealer-ship-section .boxcar-title .text {
  font-size: 15px;
  color: var(--theme-color-dark);
}

.pagination-sec {
  margin-top: 30px;
  text-align: center;
}

.pagination-sec .pagination {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.pagination-sec .pagination li:first-child a,
.pagination-sec .pagination li:last-child a {
  border: 1px solid #e1e1e1;
  width: 60px;
  font-size: 30px;
  line-height: 35px;
  border-radius: 50px;
}

.pagination-sec .pagination li a {
  font-size: 15px;
  margin: 0 5px;
  width: 40px;
  height: 40px;
  border-radius: 50px;
  text-align: center;
  font-weight: 500;
  padding: 0;
  line-height: 40px;
  border: 0;
  color: var(--theme-color-dark);
}

.pagination-sec .pagination li a:hover {
  background-color: #f9fbfc;
}

.pagination-sec .text {
  font-size: 14px;
  margin-top: 19px;
}

.dealer-block {
  margin-bottom: 30px;
}

.dealer-block .inner-box {
  text-align: center;
  border-radius: 16px;
  border: 1px solid #E1E1E1;
  background: var(--theme-color-light);
  padding: 30px;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.dealer-block .inner-box:hover {
  border-color: var(--theme-color-dark);
}

.dealer-block .inner-box:hover .content-box .deal-btn {
  background-color: var(--theme-color1);
  color: var(--theme-color-light);
}

.dealer-block .inner-box:hover .content-box .deal-btn svg path {
  fill: #fff;
}

.dealer-block .inner-box .image-box {
  margin-bottom: 22px;
  height: 170px;
  line-height: 170px;
}

.dealer-block .inner-box .content-box .title {
  font-weight: 500;
  line-height: 30px;
  margin-bottom: 5px;
}

.dealer-block .inner-box .content-box .text {
  font-size: 15px;
  line-height: 26px;
  margin-bottom: 30px;
  padding: 0 30px;
}

.dealer-block .inner-box .content-box .deal-btn {
  border-radius: 12px;
  border: 1px solid var(--theme-color1);
  background: var(--theme-color-light);
  height: 54px;
  line-height: 54px;
  width: 100%;
  display: inline-block;
  font-size: 15px;
  font-weight: 500;
  font-family: var(--title-font);
  color: var(--theme-color1);
}

.dealer-block .inner-box .content-box .deal-btn svg {
  position: relative;
  top: -2px;
  margin-left: 10px;
}

/*** 

====================================================================
    dealership-section
====================================================================

***/
.dealership-section {
  position: relative;
  padding: 120px 0;
}

.dealership-block .inner-box {
  position: relative;
  border: 1px solid #e1e1e1;
  border-radius: 16px;
  padding: 19px 19px 29px;
}

.dealership-block .inner-box:hover .image-box .image {
  border-radius: 16px;
}

.dealership-block .inner-box:hover .image-box .image img {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.dealership-block .inner-box:hover .content-box .read-more {
  background-color: var(--theme-color1);
  color: var(--theme-color-light);
}

.dealership-block .inner-box .image-box .image {
  border-radius: 16px;
  overflow: hidden;
}

.dealership-block .inner-box .image-box .image img {
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  width: 100%;
}

.dealership-block .inner-box .content-box {
  margin-top: 24px;
}

.dealership-block .inner-box .content-box .title {
  font-weight: 500;
  margin-bottom: 6px;
}

.dealership-block .inner-box .content-box .text {
  line-height: 26px;
  margin-bottom: 31px;
}

.dealership-block .inner-box .content-box .read-more {
  font-weight: 500;
  font-size: 15px;
  font-family: var(--title-font);
  line-height: 26px;
  text-transform: capitalize;
  padding: 14px 23px 12px;
  text-align: center;
  border-radius: 12px;
  width: 100%;
  border: 1px solid var(--theme-color1);
  background: var(--White, var(--theme-color-light));
  display: inline-block;
  color: var(--theme-color1);
}

.dealership-block .inner-box .content-box .read-more svg {
  position: relative;
  top: -4px;
  color: var(--theme-color1);
  width: 14px;
  height: 14px;
  margin-left: 10px;
}

/*** 

====================================================================
    dealership-section
====================================================================

***/
.dealer-ship-section-two {
  background-color: var(--theme-color-light);
  position: relative;
  padding: 52px 0 0;
  border-radius: 80px;
}

.dealer-ship-section-two .inner-column .brand-box {
  position: relative;
  padding-left: 294px;
  border-bottom: 1px solid #E1E1E1;
  padding-bottom: 53px;
}

.dealer-ship-section-two .inner-column .brand-box .image-box {
  position: absolute;
  top: 0;
  left: 0;
  margin: 0;
}

.dealer-ship-section-two .inner-column .brand-box .content-box {
  position: relative;
  top: -10px;
}

.dealer-ship-section-two .inner-column .brand-box .content-box .title {
  font-weight: 500;
  margin-bottom: 4px;
}

.dealer-ship-section-two .inner-column .brand-box .content-box .contact-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 22px;
}

.dealer-ship-section-two .inner-column .brand-box .content-box .contact-list li {
  position: relative;
  font-size: 15px;
  color: var(--theme-color-dark);
  padding-left: 35px;
  margin-right: 32px;
}

.dealer-ship-section-two .inner-column .brand-box .content-box .contact-list li:last-child {
  margin-right: 0;
}

.dealer-ship-section-two .inner-column .brand-box .content-box .contact-list li .icon {
  position: absolute;
  top: -2px;
  left: 0;
  font-size: 20px;
}

.dealer-ship-section-two .inner-column .brand-box .content-box .text {
  font-size: 15px;
  color: var(--theme-color-dark);
  margin-bottom: 21px;
}

.dealer-ship-section-two .inner-column .brand-box .content-box .brand-btn {
  font-size: 15px;
  font-weight: 500;
  color: var(--theme-color1);
  border-radius: 12px;
  border: 1px solid var(--color-secondary, #405FF2);
  background: var(--White, #FFF);
  height: 54px;
  line-height: 54px;
  display: inline-block;
  padding: 0 39px;
}

.dealer-ship-section-two .inner-column .brand-box .content-box .brand-btn svg {
  margin-left: 10px;
  position: relative;
  top: -1px;
}

.dealer-ship-section-two .inner-column .description-sec {
  margin-top: 52px;
  border-bottom: 1px solid #E1E1E1;
  padding-bottom: 50px;
}

.dealer-ship-section-two .inner-column .description-sec .title {
  font-size: 26px;
  font-weight: 500;
  margin-bottom: 43px;
}

.dealer-ship-section-two .inner-column .description-sec .text {
  font-size: 15px;
  color: var(--theme-color-dark);
}

.dealer-ship-section-two .inner-column .description-sec .text.two {
  margin-bottom: 24px;
}

.dealer-ship-section-two .inner-column .service-box {
  margin-top: 52px;
  border-bottom: 1px solid #E1E1E1;
  padding-bottom: 37px;
}

.dealer-ship-section-two .inner-column .service-box .title {
  font-size: 26px;
  font-weight: 500;
  margin-bottom: 33px;
}

.dealer-ship-section-two .inner-column .service-box .list-sec {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.dealer-ship-section-two .inner-column .service-box .list-sec .service-list .inner-title {
  font-size: 18px;
  font-weight: 500;
}

.dealer-ship-section-two .inner-column .service-box .list-sec .service-list .list-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.dealer-ship-section-two .inner-column .service-box .list-sec .service-list .list-box .list li {
  font-size: 15px;
  color: var(--theme-color-dark);
  line-height: 26px;
  margin-bottom: 15px;
}

.dealer-ship-section-two .inner-column .service-box .list-sec .service-list .list-box .list.two {
  margin-right: 65px;
}

.dealer-ship-section-two .inner-column .service-box .list-sec .service-list.two {
  margin-right: 143px;
}

.dealer-ship-section-two .inner-column .location-box {
  margin-top: 52px;
  border-bottom: 1px solid #E1E1E1;
  padding-bottom: 51px;
}

.dealer-ship-section-two .inner-column .location-box .title {
  font-size: 26px;
  font-weight: 500;
  margin-bottom: 22px;
}

.dealer-ship-section-two .inner-column .location-box .text {
  font-size: 15px;
  color: var(--theme-color-dark);
  max-width: 431px;
  margin-bottom: 14px;
}

.dealer-ship-section-two .inner-column .location-box .brand-btn {
  font-size: 15px;
  font-weight: 500;
  color: var(--theme-color1);
  display: inline-block;
  margin-bottom: 22px;
}

.dealer-ship-section-two .inner-column .location-box .brand-btn svg {
  margin-left: 9px;
  position: relative;
  top: -1px;
}

.dealer-ship-section-two .inner-column .location-box iframe {
  width: 100%;
  height: 450px;
  border-radius: 20px;
}

.dealer-ship-section-two .inner-column .review-sec {
  margin-top: 52px;
}

.dealer-ship-section-two .inner-column .review-sec .title {
  font-size: 26px;
  font-weight: 500;
  margin-bottom: 30px;
}

.dealer-ship-section-two .inner-column .review-sec .review-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.dealer-ship-section-two .inner-column .review-sec .review-box .rating-box {
  position: relative;
  width: 200px;
  height: 200px;
  border: 10px solid #E9F2FF;
  border-radius: 50%;
  margin-right: 39px;
}

.dealer-ship-section-two .inner-column .review-sec .review-box .rating-box .content-box {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 100%;
  text-align: center;
}

.dealer-ship-section-two .inner-column .review-sec .review-box .rating-box .content-box span {
  color: var(--theme-color1);
  font-size: 15px;
  font-weight: 500;
  display: inline-block;
}

.dealer-ship-section-two .inner-column .review-sec .review-box .rating-box .content-box .title {
  font-size: 40px;
  font-weight: 700;
  color: var(--theme-color1);
  margin-bottom: -1px;
  margin-top: -3px;
}

.dealer-ship-section-two .inner-column .review-sec .review-box .rating-box .content-box small {
  font-size: 15px;
  font-weight: 500;
  color: var(--theme-color1);
}

.dealer-ship-section-two .inner-column .review-sec .review-box .review-list {
  width: 303px;
  margin-top: 6px;
}

.dealer-ship-section-two .inner-column .review-sec .review-box .review-list li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  border-bottom: 1px solid #E1E1E1;
  padding-bottom: 5px;
  margin-bottom: 16px;
}

.dealer-ship-section-two .inner-column .review-sec .review-box .review-list li:last-child {
  padding-bottom: 0;
  border: 0;
  margin-bottom: 0;
}

.dealer-ship-section-two .inner-column .review-sec .review-box .review-list li .review-title span {
  display: block;
  color: var(--theme-color-dark);
  font-weight: 500;
  margin-bottom: -5px;
}

.dealer-ship-section-two .inner-column .review-sec .review-box .review-list li .review-title small {
  font-size: 15px;
  color: var(--theme-color-dark);
}

.dealer-ship-section-two .inner-column .review-sec .review-box .review-list li sub {
  font-weight: 500;
  color: var(--theme-color-dark);
}

.dealer-ship-section-two .inner-column .review-sec .review-box .review-list li sub i {
  color: var(--theme-color1);
  margin-right: 9px;
}

.dealer-ship-section-two .inner-column .review-sec .review-box .review-list.two {
  margin-right: 54px;
}

.dealer-ship-section-two .inner-column .reviews {
  margin-top: 38px;
}

.dealer-ship-section-two .inner-column .reviews .title {
  margin-bottom: 23px;
}

.dealer-ship-section-two .inner-column .reviews .auther-name {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 16px;
}

.dealer-ship-section-two .inner-column .reviews .auther-name span {
  font-size: 14px;
  font-weight: 500;
  width: 40px;
  height: 40px;
  line-height: 40px;
  background-color: var(--theme-color-dark);
  border-radius: 50%;
  text-align: center;
  color: var(--theme-color-light);
  display: inline-block;
  margin-right: 12px;
}

.dealer-ship-section-two .inner-column .reviews .auther-name .name {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 0;
  margin-right: 17px;
}

.dealer-ship-section-two .inner-column .reviews .auther-name small {
  font-size: 14px;
  color: var(--theme-color-dark);
}

.dealer-ship-section-two .inner-column .reviews .rating-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.dealer-ship-section-two .inner-column .reviews .rating-list .list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.dealer-ship-section-two .inner-column .reviews .rating-list .list li {
  font-size: 10px;
  margin-right: 4px;
  color: var(--theme-color1);
}

.dealer-ship-section-two .inner-column .reviews .rating-list .list li:last-child {
  margin-right: 0;
}

.dealer-ship-section-two .inner-column .reviews .rating-list span {
  font-size: 15px;
  color: var(--theme-color-dark);
  font-weight: 500;
  margin-left: 12px;
}

.dealer-ship-section-two .inner-column .reviews .text {
  font-size: 15px;
  color: var(--theme-color-dark);
  max-width: 849px;
}

.dealer-ship-section-two .inner-column .image-box {
  margin-top: 23px;
  margin-bottom: 20px;
}

.dealer-ship-section-two .inner-column .image-box img {
  border-radius: 12px;
  margin-right: 16px;
}

.dealer-ship-section-two .inner-column .image-box img:last-child {
  margin-right: 0;
}

.dealer-ship-section-two .inner-column .btn-box .like-btn {
  font-size: 15px;
  color: #818181;
  margin-right: 28px;
}

.dealer-ship-section-two .inner-column .btn-box .like-btn i {
  margin-right: 10px;
}

.dealer-ship-section-two .inner-column .btn-box .like-btn:hover {
  color: var(--theme-color1);
}

.dealer-ship-section-two .inner-column .content-box.two {
  margin-top: 29px;
  border-bottom: 1px solid #E1E1E1;
  padding-bottom: 60px;
}

.dealer-ship-section-two .inner-column .content-box.two .text {
  margin-bottom: 27px;
}

.dealer-ship-section-two .inner-column .content-box.two .review {
  font-size: 15px;
  color: var(--theme-color1);
  font-weight: 500;
  height: 54px;
  line-height: 54px;
  padding: 0 38px;
  border-radius: 12px;
  border: 1px solid var(--color-secondary, #405FF2);
  background: var(--White, #FFF);
  display: inline-block;
  margin-top: 30px;
}

.dealer-ship-section-two .inner-column .content-box.two .review svg {
  margin-left: 10px;
  position: relative;
  top: -1px;
}

.dealer-ship-section-two .inner-column .Reply-sec {
  margin-top: 52px;
  margin-bottom: 20px;
}

.dealer-ship-section-two .inner-column .Reply-sec .text {
  margin-bottom: 30px;
}

.dealer-ship-section-two .inner-column .Reply-sec .right-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.dealer-ship-section-two .inner-column .Reply-sec .right-box .rating-list {
  width: 330px;
}

.dealer-ship-section-two .inner-column .Reply-sec .right-box .rating-list .list-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-bottom: 20px;
}

.dealer-ship-section-two .inner-column .Reply-sec .right-box .rating-list .list-box span {
  font-size: 15px;
  color: var(--theme-color-dark);
}

.dealer-ship-section-two .inner-column .Reply-sec .right-box .rating-list .list-box .list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.dealer-ship-section-two .inner-column .Reply-sec .right-box .rating-list .list-box .list li {
  font-size: 12px;
  color: var(--theme-color1);
  margin-right: 4px;
}

.dealer-ship-section-two .inner-column .Reply-sec .right-box .rating-list .list-box .list li:last-child {
  margin-right: 0;
}

.dealer-ship-section-two .inner-column .Reply-sec .right-box .rating-list.two {
  margin-right: 144px;
}

.dealer-ship-section-two .inner-column form {
  width: 100%;
  margin: 0;
  margin-left: -12px;
}

.dealer-ship-section-two .inner-column form .form_boxes {
  border-radius: 12px;
  border: 1px solid rgb(225, 225, 225);
  background: var(--color-white, #FFF);
  padding: 7px 15px;
  margin-bottom: 30px;
}

.dealer-ship-section-two .inner-column form .form_boxes textarea {
  width: 100%;
  height: 200px;
}

.dealer-ship-section-two .inner-column form .form_boxes textarea::-webkit-input-placeholder {
  color: var(--theme-color-dark);
}

.dealer-ship-section-two .inner-column form .form_boxes textarea::-moz-placeholder {
  color: var(--theme-color-dark);
}

.dealer-ship-section-two .inner-column form .form_boxes textarea:-ms-input-placeholder {
  color: var(--theme-color-dark);
}

.dealer-ship-section-two .inner-column form .form_boxes textarea::-ms-input-placeholder {
  color: var(--theme-color-dark);
}

.dealer-ship-section-two .inner-column form .form_boxes textarea::placeholder {
  color: var(--theme-color-dark);
}

.dealer-ship-section-two .inner-column form .form_boxes label {
  display: block;
  color: #818181;
  font-size: 15px;
  font-weight: 400;
}

.dealer-ship-section-two .inner-column form .form_boxes .drop-menu {
  height: unset;
}

.dealer-ship-section-two .inner-column form .form_boxes .drop-menu .select {
  padding: 0;
  line-height: unset;
}

.dealer-ship-section-two .inner-column form .form_boxes .drop-menu .dropdown {
  padding: 5px 15px;
  margin-top: 10px;
  border-radius: 12px;
  left: -15px;
  width: calc(100% + 30px);
  -webkit-box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.08);
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.08);
}

.dealer-ship-section-two .inner-column form .form_boxes.v2 {
  padding-top: 14px;
}

.dealer-ship-section-two .inner-column form .theme-btn {
  background: #405FF2;
  color: #fff;
  height: 54px;
  line-height: 54px;
  border-radius: 12px;
  padding: 0 26px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.dealer-ship-section-two .inner-column form .theme-btn svg {
  margin-left: 10px;
}

.dealer-ship-section-two .side-bar .message {
  font-size: 15px;
  font-weight: 500;
  color: var(--theme-color-light);
  height: 54px;
  width: 100%;
  line-height: 54px;
  text-align: center;
  background-color: var(--theme-color1);
  display: inline-block;
  border: 1px solid var(--theme-color1);
  border-radius: 12px;
  margin-bottom: 30px;
}

.dealer-ship-section-two .side-bar .message svg {
  margin-left: 9px;
  position: relative;
  top: -1px;
}

.dealer-ship-section-two .side-bar .schedule-sec {
  border-radius: 16px;
  border: 1px solid var(--Border, #E1E1E1);
  background: var(--theme-color-light);
  padding: 23px 30px 7px;
}

.dealer-ship-section-two .side-bar .schedule-sec .title {
  font-weight: 500;
}

.dealer-ship-section-two .side-bar .schedule-sec .schedule-list li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  font-size: 15px;
  color: var(--theme-color-dark);
  margin-bottom: 13px;
}

.dealer-ship-section-two .side-bar .schedule-sec .schedule-list li span {
  font-weight: 500;
}

/*** 

====================================================================
    inspiration section
====================================================================

***/
.boxcar-inspiration-section {
  position: relative;
  padding: 120px 0;
}

.boxcar-inspiration-section .right-box .service-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.boxcar-inspiration-section .right-box .service-list li {
  margin-right: 28px;
}

.boxcar-inspiration-section .right-box .service-list li:last-child {
  margin-right: 0;
}

.boxcar-inspiration-section .right-box .service-list li a {
  font-weight: 500;
  color: var(--theme-color-dark);
  padding: 16px 25px 15px;
  border-radius: 16px;
  border: 1px solid var(--Border, #E1E1E1);
  display: inline-block;
  color: #050B20;
}

.boxcar-inspiration-section .right-box .service-list li a:hover {
  background: #050B20;
  color: #fff;
  border-color: transparent;
}

/*** 

====================================================================
    inspiration section two
====================================================================

***/
.boxcar-inspiration-section-two {
  position: relative;
  padding: 120px 0;
}

.boxcar-inspiration-section-two .right-box-two {
  border-radius: 16px;
  background-color: var(--theme-color-dark);
  padding: 120px 150px;
}

.boxcar-inspiration-section-two .right-box-two .uper-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: end;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-bottom: 105px;
}

.boxcar-inspiration-section-two .right-box-two .uper-box .content-box {
  position: relative;
  padding-left: 122px;
}

.boxcar-inspiration-section-two .right-box-two .uper-box .content-box .icon {
  position: absolute;
  top: 7px;
  left: 0;
}

.boxcar-inspiration-section-two .right-box-two .uper-box .content-box .boxcar-title {
  margin-bottom: 0;
}

.boxcar-inspiration-section-two .right-box-two .uper-box .content-box .boxcar-title .text {
  margin-top: 14px;
}

.boxcar-inspiration-section-two .right-box-two .uper-box .btn-box {
  margin-bottom: 10px;
}

.boxcar-inspiration-section-two .right-box-two .uper-box .btn-box .read-more {
  font-weight: 500;
  font-size: 15px;
  font-family: var(--title-font);
  line-height: 26px;
  text-transform: capitalize;
  padding: 15px 27px 14px;
  border-radius: 12px;
  background: var(--theme-color1);
  display: inline-block;
  color: var(--theme-color-light);
  margin-right: 26px;
}

.boxcar-inspiration-section-two .right-box-two .uper-box .btn-box .read-more:last-child {
  margin-right: 0;
}

.boxcar-inspiration-section-two .right-box-two .uper-box .btn-box .read-more svg {
  position: relative;
  top: -4px;
  color: var(--theme-color-light);
  width: 14px;
  height: 14px;
  margin-left: 10px;
}

.boxcar-inspiration-section-two .right-box .boxcar-title {
  margin-bottom: 24px;
}

.boxcar-inspiration-section-two .right-box .boxcar-title h6 {
  font-size: 16px;
  color: var(--theme-color-light);
}

.boxcar-inspiration-section-two .right-box .service-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.boxcar-inspiration-section-two .right-box .service-list li {
  margin-right: 28px;
}

.boxcar-inspiration-section-two .right-box .service-list li:last-child {
  margin-right: 0;
}

.boxcar-inspiration-section-two .right-box .service-list li a {
  font-weight: 500;
  color: var(--theme-color-light);
  padding: 16px 25px 15px;
  border-radius: 16px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  display: inline-block;
}

.boxcar-inspiration-section-two .right-box .service-list li a:hover {
  background: var(--theme-color1);
  color: #fff;
  border-color: transparent;
}

/***

==================================================================
	Main Footer
==================================================================

***/
.boxcar-footer {
  position: relative;
  background-color: var(--bg-theme-color2);
  /* Widget Section */
}

.boxcar-footer .bg {
  background-size: auto;
}

.boxcar-footer .footer-bg-1 {
  background-image: url(../images/icons/footer-bg-1.html);
  background-color: #101A29;
  background-position: left bottom;
}

.boxcar-footer .footer-bg-2 {
  background-image: url(../images/icons/footer-bg-2.html);
  background-position: right top;
}

.boxcar-footer .footer-bg-3 {
  background-image: url(../images/icons/footer-bg-3.html);
  background-position: right bottom;
}

.boxcar-footer .footer-bg-4 {
  background-image: url(../images/icons/footer-bg-4.html);
  background-position: right bottom;
}

.boxcar-footer .footer-bg-5 {
  background-image: url(../images/icons/footer-bg-5.html);
  background-position: center;
  background-size: cover;
}

.boxcar-footer .footer-bg-6 {
  background-image: url(../images/icons/footer-bg-6.html);
  background-position: right top;
}

.boxcar-footer .footer-bg-7 {
  background-image: url(../images/icons/footer-bg-7.html);
}

.boxcar-footer .footer-bg-8 {
  background-image: url(../images/icons/footer-bg-8.html);
}

.boxcar-footer .footer-bg-9 {
  background-image: url(../images/icons/footer-bg-9.html);
  background-position: right bottom;
}

.boxcar-footer .footer-bg-10 {
  background-image: url(../images/icons/footer-bg-10.html);
  background-position: right bottom;
}

.boxcar-footer .footer-bg-11 {
  background-image: url(../images/icons/footer-bg-11.html);
}

.boxcar-footer .widgets-section {
  position: relative;
  z-index: 999;
}

.boxcar-footer .footer-column {
  position: relative;
  margin-bottom: 30px;
}

.boxcar-footer .footer-widget {
  position: relative;
}

.boxcar-footer .widget-title {
  position: relative;
  color: var(--theme-color-light);
  margin-bottom: 15px;
  font-weight: 700;
  font-size: 20px;
}

.boxcar-footer .widget-content {
  position: relative;
}

.boxcar-footer .widget-content .text {
  color: var(--theme-color-light);
}

.recent-post {
  position: relative;
  margin-bottom: 30px;
}

.recent-post:last-child {
  margin-bottom: 0;
}

.recent-post .inner {
  position: relative;
  padding-left: 100px;
  min-height: 80px;
}

.recent-post .inner:hover .post-thumb img {
  opacity: 0.7;
}

.recent-post .inner .post-thumb {
  position: absolute;
  left: 0;
  top: 10px;
  height: 80px;
  width: 80px;
  border-radius: 5px;
  overflow: hidden;
}

.recent-post .inner .post-thumb img {
  width: 100%;
  width: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}

.recent-post .inner .post-info {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: var(--theme-color-light);
  line-height: 30px;
  margin-bottom: 5px;
}

.recent-post .inner .post-info i {
  font-size: 12px;
  margin-right: 10px;
  color: var(--theme-color1);
}

.recent-post .inner .post-title {
  color: var(--theme-color-light);
  letter-spacing: -0.8px;
  margin-bottom: 15px;
  font-family: var(--text-font);
}

/*=== Footer Bottom ===*/
.footer-bottom {
  position: relative;
  z-index: 3;
  background-color: #FBFBFB;
  padding: 29px 0 29px;
}

.footer-bottom .inner-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

@media (max-width: 991.98px) {
  .footer-bottom .inner-container {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
}

.footer-bottom .copyright-text {
  position: relative;
  margin-bottom: 0;
  color: #616670;
}

@media (max-width: 991.98px) {
  .footer-bottom .copyright-text {
    -webkit-box-ordinal-group: 4;
    -ms-flex-order: 3;
    order: 3;
    text-align: center;
    margin-top: 10px;
  }
}

.footer-bottom .copyright-text a {
  color: inherit;
}

.footer-bottom .copyright-text a:hover {
  color: #ffffff;
}

.footer-bottom .footer-nav {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

@media (max-width: 575.98px) {
  .footer-bottom .footer-nav {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
}

.footer-bottom .footer-nav li {
  font-size: 16px;
  line-height: 32px;
  color: #616670;
  margin-left: 25px;
}

.footer-bottom .footer-nav li:first-child {
  margin-left: 0;
}

@media (max-width: 575.98px) {
  .footer-bottom .footer-nav li {
    margin: 0 30px !important;
    text-align: center;
  }
}

.footer-bottom .footer-nav li a {
  color: inherit;
}

.footer-bottom .footer-nav li a:hover {
  color: var(--theme-color-light);
}

.footer-style-one {
  position: relative;
  background-color: var(--theme-color-dark);
}

.footer-style-one .footer-top .right-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  border-bottom: 1px solid #293040;
  padding: 60px 0 60px;
  /* Medium devices */
}

.footer-style-one.cus-st-1 .footer-top .right-box {
  padding-top: 140px;
}

@media (max-width: 991px) {
  .footer-style-one .footer-top .right-box {
    display: block;
  }

  .compare-section {
    padding-bottom: 90px;
  }
}

.footer-style-one .footer-top .right-box .top-left {
  /* Medium devices */
}

@media (max-width: 991px) {
  .footer-style-one .footer-top .right-box .top-left {
    margin-bottom: 30px;
  }
}

.footer-style-one .footer-top .right-box .top-left .title {
  font-size: 30px;
  font-weight: 500;
  color: var(--theme-color-light);
  margin-bottom: 3px;
}

.footer-style-one .footer-top .right-box .top-left .text {
  font-size: 15px;
  color: var(--theme-color-light);
  line-height: 26px;
}

.footer-style-one .footer-top .right-box .subscribe-form .form-group {
  position: relative;
  display: inline-block;
  /* Medium devices */
}

@media (max-width: 991px) {
  .footer-style-one .footer-top .right-box .subscribe-form .form-group {
    width: 100%;
  }
}

.footer-style-one .footer-top .right-box .subscribe-form .form-group input {
  width: 567px;
  height: 70px;
  background-color: #1e2336;
  border-radius: 50px;
  padding: 0 36px;
  font-size: 15px;
  /* Medium devices */
}

.footer-style-one .footer-top .right-box .subscribe-form .form-group input::-webkit-input-placeholder {
  color: var(--theme-color-light);
}

.footer-style-one .footer-top .right-box .subscribe-form .form-group input::-moz-placeholder {
  color: var(--theme-color-light);
}

.footer-style-one .footer-top .right-box .subscribe-form .form-group input:-ms-input-placeholder {
  color: var(--theme-color-light);
}

.footer-style-one .footer-top .right-box .subscribe-form .form-group input::-ms-input-placeholder {
  color: var(--theme-color-light);
}

.footer-style-one .footer-top .right-box .subscribe-form .form-group input::placeholder {
  color: var(--theme-color-light);
}

@media (max-width: 991px) {
  .footer-style-one .footer-top .right-box .subscribe-form .form-group input {
    width: 100%;
  }
}

.footer-style-one .footer-top .right-box .subscribe-form .form-group button {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 15px;
  color: var(--theme-color-light);
  background-color: var(--theme-color1);
  padding: 13px 30px 10px;
  border-radius: 50px;
  text-transform: capitalize;
}

.footer-style-one .widgets-section {
  padding: 54px 0 20px;
}

.footer-style-one .links-widget {
  margin-bottom: 30px;
  /* Medium devices */
}

@media (max-width: 991px) {
  .footer-style-one .links-widget {
    padding-left: 0;
  }
}

.footer-style-one .links-widget .user-links {
  position: relative;
}

.footer-style-one .links-widget .user-links li {
  margin-bottom: 7px;
}

.footer-style-one .links-widget .user-links li:last-child {
  margin-bottom: 0;
}

.footer-style-one .links-widget .user-links li a {
  position: relative;
  font-size: 15px;
  display: inline-block;
  color: var(--theme-color-light);
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}

.footer-style-one .links-widget .user-links li a i {
  position: absolute;
  left: 0;
  color: var(--theme-color1);
}

.footer-style-one .links-widget .user-links li a:hover {
  color: var(--theme-color1);
}

.footer-style-one .links-widget .user-links li a:before {
  position: absolute;
  left: 0;
  bottom: 3px;
  width: 0;
  height: 1px;
  background-color: var(--bg-theme-color1);
  content: "";
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}

.footer-style-one .links-widget .user-links li a:hover:before {
  width: 100%;
}

.footer-style-one .links-widget .user-links.style-two li {
  padding-left: 0;
}

.footer-style-one .links-widget .user-links.style-two li:before {
  display: none;
}

.footer-style-one .links-widget .user-links.two-column {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.footer-style-one .links-widget .user-links.two-column li {
  width: 50%;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
}

.footer-style-one .links-widget .user-links.two li {
  padding-left: 16px;
  margin-bottom: 6px;
}

.footer-style-one .links-widget .user-links.two li i {
  left: -17px;
}

.footer-style-one .social-widget .widget-content .store {
  position: relative;
  background-color: #161d30;
  display: inline-block;
  padding: 6px 40px 10px 70px;
  border-radius: 16px;
  /* Medium devices */
}

@media (max-width: 991px) {
  .footer-style-one .social-widget .widget-content .store {
    margin-right: 10px;
  }
}

.footer-style-one .social-widget .widget-content .store img {
  position: absolute;
  top: 15px;
  left: 30px;
}

.footer-style-one .social-widget .widget-content .store span {
  color: var(--theme-color-light);
  font-size: 13px;
  display: block;
}

.footer-style-one .social-widget .widget-content .store .title {
  font-size: 15px;
  color: var(--theme-color-light);
  font-weight: 500;
  margin-top: -4px;
  margin-bottom: 0;
}

.footer-style-one .social-widget .widget-content .store.two {
  padding-right: 60px;
  margin-top: 10px;
  margin-bottom: 22px;
}

.footer-style-one .social-widget .widget-content .social-icons .title {
  color: var(--theme-color-light);
  font-weight: 500;
  line-height: 30px;
  margin-bottom: 21px;
}

.footer-style-one .social-widget .widget-content .social-icons ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-left: -15px;
}

.footer-style-one .social-widget .widget-content .social-icons ul li a {
  color: var(--theme-color-light);
  width: 40px;
  height: 40px;
  font-size: 14px;
  line-height: 40px;
  text-align: center;
  border-radius: 50%;
  display: inline-block;
}

.footer-style-one .social-widget .widget-content .social-icons ul li a:hover {
  background-color: #161d30;
}

.footer-style-one .footer-bottom {
  background-color: var(--theme-color-dark);
  border-top: 1px solid #293040;
}

@media (max-width: 575.98px) {
  .footer-style-one .footer-bottom .inner-container .footer-nav {
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: start;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
  }
}

.footer-style-one .footer-bottom .inner-container .footer-nav li {
  position: relative;
}

.footer-style-one .footer-bottom .inner-container .footer-nav li:before {
  content: "";
  position: absolute;
  top: 50%;
  right: -13px;
  width: 4px;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  height: 4px;
  background: #fff;
  border-radius: 50%;
}

.footer-style-one .footer-bottom .inner-container .footer-nav li:last-child:before {
  display: none;
}

@media (max-width: 575.98px) {
  .footer-style-one .footer-bottom .inner-container .footer-nav li {
    margin: 0 8px !important;
  }
}

.footer-style-one .footer-bottom .inner-container .footer-nav li a {
  color: var(--theme-color-light);
}

.footer-style-one .footer-bottom .inner-container .copyright-text {
  color: var(--theme-color-light);
}

@media (max-width: 575.98px) {
  .footer-style-one .footer-bottom .inner-container .copyright-text {
    text-align: left;
  }
}

.footer-style-two {
  position: relative;
  background-color: unset;
}

.footer-style-two .footer-top .right-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  border-bottom: 1px solid #E1E1E1;
  padding: 60px 0 60px;
}

.footer-style-two .footer-top .right-box .top-left .title {
  font-size: 30px;
  font-weight: 500;
  color: var(--theme-color-dark);
  margin-bottom: 3px;
}

.footer-style-two .footer-top .right-box .top-left .text {
  font-size: 15px;
  color: var(--theme-color-dark);
  line-height: 26px;
}

.footer-style-two .footer-top .right-box .subscribe-form .form-group {
  position: relative;
  display: inline-block;
}

.footer-style-two .footer-top .right-box .subscribe-form .form-group input {
  width: 567px;
  height: 70px;
  border-radius: 16px;
  border: 1px solid var(--Border, #E1E1E1);
  padding: 0 36px;
  font-size: 15px;
}

.footer-style-two .footer-top .right-box .subscribe-form .form-group input::-webkit-input-placeholder {
  color: var(--theme-color-dark);
}

.footer-style-two .footer-top .right-box .subscribe-form .form-group input::-moz-placeholder {
  color: var(--theme-color-dark);
}

.footer-style-two .footer-top .right-box .subscribe-form .form-group input:-ms-input-placeholder {
  color: var(--theme-color-dark);
}

.footer-style-two .footer-top .right-box .subscribe-form .form-group input::-ms-input-placeholder {
  color: var(--theme-color-dark);
}

.footer-style-two .footer-top .right-box .subscribe-form .form-group input::placeholder {
  color: var(--theme-color-dark);
}

.footer-style-two .footer-top .right-box .subscribe-form .form-group button {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 15px;
  color: var(--theme-color-light);
  background-color: var(--theme-color-dark);
  padding: 13px 30px 10px;
  border-radius: 16px;
  text-transform: capitalize;
}

.footer-style-two .widgets-section {
  padding: 54px 0 20px;
}

.footer-style-two .widget-title {
  color: var(--theme-color-dark);
}

.footer-style-two .links-widget {
  padding-left: 18px;
  margin-bottom: 30px;
}

@media (max-width: 767.98px) {
  .footer-style-two .links-widget {
    padding-left: 0;
  }
}

.footer-style-two .links-widget .user-links {
  position: relative;
}

.footer-style-two .links-widget .user-links li {
  margin-bottom: 7px;
}

.footer-style-two .links-widget .user-links li:last-child {
  margin-bottom: 0;
}

.footer-style-two .links-widget .user-links li a {
  position: relative;
  font-size: 15px;
  display: inline-block;
  color: var(--theme-color-dark);
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}

.footer-style-two .links-widget .user-links li a i {
  position: absolute;
  left: 0;
  color: var(--theme-color1);
}

.footer-style-two .links-widget .user-links li a:hover {
  color: var(--theme-color1);
}

.footer-style-two .links-widget .user-links li a:before {
  position: absolute;
  left: 0;
  bottom: 3px;
  width: 0;
  height: 1px;
  background-color: var(--bg-theme-color1);
  content: "";
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}

.footer-style-two .links-widget .user-links li a:hover:before {
  width: 100%;
}

.footer-style-two .links-widget .user-links.style-two li {
  padding-left: 0;
}

.footer-style-two .links-widget .user-links.style-two li:before {
  display: none;
}

.footer-style-two .links-widget .user-links.two-column {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.footer-style-two .links-widget .user-links.two-column li {
  width: 50%;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
}

.footer-style-two .links-widget .user-links.two li {
  padding-left: 16px;
  margin-bottom: 6px;
}

.footer-style-two .links-widget .user-links.two li i {
  left: -17px;
}

.footer-style-two .social-widget .widget-content .text {
  line-height: 35px;
  font-size: 15px;
  color: var(--theme-color-dark);
  margin-bottom: 28px;
}

.footer-style-two .social-widget .widget-content .social-icons .title {
  color: var(--theme-color-dark);
  font-weight: 500;
  line-height: 30px;
  margin-bottom: 21px;
}

.footer-style-two .social-widget .widget-content .social-icons ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-left: -15px;
}

.footer-style-two .social-widget .widget-content .social-icons ul li a {
  color: var(--theme-color-dark);
  width: 40px;
  height: 40px;
  font-size: 14px;
  line-height: 40px;
  text-align: center;
  border-radius: 50%;
  display: inline-block;
}

.footer-style-two .social-widget .widget-content .social-icons ul li a:hover {
  background-color: #f9fbfc;
}

.footer-style-two .footer-bottom {
  border-top: 1px solid #E1E1E1;
}

@media (max-width: 575.98px) {
  .footer-style-two .footer-bottom .inner-container .footer-nav {
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: start;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
  }
}

@media (max-width: 575.98px) {
  .footer-style-two .footer-bottom .inner-container .footer-nav li {
    margin: 0 8px !important;
  }
}

.footer-style-two .footer-bottom .inner-container .footer-nav li a {
  color: var(--theme-color-dark);
}

.footer-style-two .footer-bottom .inner-container .copyright-text {
  color: var(--theme-color-dark);
}

@media (max-width: 575.98px) {
  .footer-style-two .footer-bottom .inner-container .copyright-text {
    text-align: left;
  }
}

.footer-style-two.v8 .widgets-section {
  padding-top: 120px;
}

.footer-style-two.v9 .widgets-section {
  padding-top: 64px;
}

.footer-style-four {
  position: relative;
  background-color: var(--theme-color-dark);
}

.footer-style-four .footer-top .right-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  border-bottom: 1px solid #293040;
  padding: 60px 0 60px;
}

.footer-style-four .footer-top .right-box .top-left .title {
  font-size: 35px;
  font-weight: 700;
  font-family: var(--title-font2);
  color: var(--theme-color-light);
  margin-bottom: 12px;
  letter-spacing: 1.75px;
}

.footer-style-four .footer-top .right-box .top-left .text {
  font-size: 15px;
  color: var(--theme-color-light);
  line-height: 26px;
  margin-bottom: 21px;
}

.footer-style-four .footer-top .right-box .top-left .btn-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.footer-style-four .footer-top .right-box .top-left .btn-box .btn-two {
  font-size: 15px;
  font-style: normal;
  display: inline-block;
  font-weight: 500;
  line-height: 28px;
  color: var(--theme-color-light);
  border-radius: 60px;
  padding: 7px 29px 7px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  margin-right: 20px;
}

.footer-style-four .footer-top .right-box .top-left .btn-box .btn-two:last-child {
  margin-right: 0;
}

.footer-style-four .footer-top .right-box .top-left .btn-box .btn-two svg {
  fill: var(--theme-color-light);
  width: 26px;
  height: 26px;
  margin-right: 8px;
}

.footer-style-four .footer-top .right-box .top-right .content-box {
  margin-bottom: 21px;
}

.footer-style-four .footer-top .right-box .top-right .content-box .title {
  font-size: 30px;
  font-weight: 500;
  color: var(--theme-color-light);
  margin-bottom: 3px;
}

.footer-style-four .footer-top .right-box .top-right .content-box .text {
  font-size: 15px;
  color: var(--theme-color-light);
  line-height: 26px;
}

.footer-style-four .footer-top .right-box .top-right .subscribe-form .form-group {
  position: relative;
  display: inline-block;
}

.footer-style-four .footer-top .right-box .top-right .subscribe-form .form-group input {
  width: 448px;
  height: 70px;
  background-color: #1e2336;
  border-radius: 16px;
  padding: 0 22px;
  font-size: 15px;
  margin-bottom: 11px;
  color: #fff;
}

.footer-style-four .footer-top .right-box .top-right .subscribe-form .form-group input::-webkit-input-placeholder {
  color: var(--theme-color-light);
}

.footer-style-four .footer-top .right-box .top-right .subscribe-form .form-group input::-moz-placeholder {
  color: var(--theme-color-light);
}

.footer-style-four .footer-top .right-box .top-right .subscribe-form .form-group input:-ms-input-placeholder {
  color: var(--theme-color-light);
}

.footer-style-four .footer-top .right-box .top-right .subscribe-form .form-group input::-ms-input-placeholder {
  color: var(--theme-color-light);
}

.footer-style-four .footer-top .right-box .top-right .subscribe-form .form-group input::placeholder {
  color: var(--theme-color-light);
}

.footer-style-four .footer-top .right-box .top-right .subscribe-form .form-group button {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 448px;
  font-size: 15px;
  color: var(--theme-color-light);
  background-color: var(--theme-color1);
  padding: 19px 30px 16px;
  border-radius: 16px;
  text-transform: capitalize;
}

.footer-style-four .widgets-section {
  padding: 54px 0 20px;
}

.footer-style-four .links-widget {
  padding-left: 18px;
  margin-bottom: 30px;
}

@media (max-width: 767.98px) {
  .footer-style-four .links-widget {
    padding-left: 0;
  }
}

.footer-style-four .links-widget .user-links {
  position: relative;
}

.footer-style-four .links-widget .user-links li {
  margin-bottom: 7px;
}

.footer-style-four .links-widget .user-links li:last-child {
  margin-bottom: 0;
}

.footer-style-four .links-widget .user-links li a {
  position: relative;
  font-size: 15px;
  display: inline-block;
  color: var(--theme-color-light);
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}

.footer-style-four .links-widget .user-links li a i {
  position: absolute;
  left: 0;
  color: var(--theme-color1);
}

.footer-style-four .links-widget .user-links li a:hover {
  color: var(--theme-color1);
}

.footer-style-four .links-widget .user-links li a:before {
  position: absolute;
  left: 0;
  bottom: 3px;
  width: 0;
  height: 1px;
  background-color: var(--bg-theme-color1);
  content: "";
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}

.footer-style-four .links-widget .user-links li a:hover:before {
  width: 100%;
}

.footer-style-four .links-widget .user-links.style-two li {
  padding-left: 0;
}

.footer-style-four .links-widget .user-links.style-two li:before {
  display: none;
}

.footer-style-four .links-widget .user-links.two-column {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.footer-style-four .links-widget .user-links.two-column li {
  width: 50%;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
}

.footer-style-four .links-widget .user-links.two li {
  padding-left: 16px;
  margin-bottom: 6px;
}

.footer-style-four .links-widget .user-links.two li i {
  left: -17px;
}

.footer-style-four .social-widget .widget-content .store {
  position: relative;
  background-color: #161d30;
  display: inline-block;
  padding: 6px 40px 10px 70px;
  border-radius: 16px;
}

.footer-style-four .social-widget .widget-content .store img {
  position: absolute;
  top: 15px;
  left: 30px;
}

.footer-style-four .social-widget .widget-content .store span {
  color: var(--theme-color-light);
  font-size: 13px;
  display: block;
}

.footer-style-four .social-widget .widget-content .store .title {
  font-size: 15px;
  color: var(--theme-color-light);
  font-weight: 500;
  margin-top: -4px;
  margin-bottom: 0;
}

.footer-style-four .social-widget .widget-content .store.two {
  padding-right: 60px;
  margin-top: 10px;
  margin-bottom: 22px;
}

.footer-style-four .social-widget .widget-content .social-icons .title {
  color: var(--theme-color-light);
  font-weight: 500;
  line-height: 30px;
  margin-bottom: 21px;
}

.footer-style-four .social-widget .widget-content .social-icons ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-left: -15px;
}

.footer-style-four .social-widget .widget-content .social-icons ul li a {
  color: var(--theme-color-light);
  width: 40px;
  height: 40px;
  font-size: 14px;
  line-height: 40px;
  text-align: center;
  border-radius: 50%;
  display: inline-block;
}

.footer-style-four .social-widget .widget-content .social-icons ul li a:hover {
  background-color: #161d30;
}

.footer-style-four .footer-bottom {
  background-color: var(--theme-color-dark);
  border-top: 1px solid #293040;
}

@media (max-width: 575.98px) {
  .footer-style-four .footer-bottom .inner-container .footer-nav {
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: start;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
  }
}

@media (max-width: 575.98px) {
  .footer-style-four .footer-bottom .inner-container .footer-nav li {
    margin: 0 8px !important;
  }
}

.footer-style-four .footer-bottom .inner-container .footer-nav li a {
  color: var(--theme-color-light);
}

.footer-style-four .footer-bottom .inner-container .copyright-text {
  color: var(--theme-color-light);
}

@media (max-width: 575.98px) {
  .footer-style-four .footer-bottom .inner-container .copyright-text {
    text-align: left;
  }
}

.footer-style-five {
  position: relative;
  background-color: unset;
}

.footer-style-five .footer-top .right-box {
  text-align: center;
  border-bottom: 1px solid #E1E1E1;
  padding: 60px 0 60px;
}

.footer-style-five .footer-top .right-box .top-left {
  margin-bottom: 21px;
}

.footer-style-five .footer-top .right-box .top-left .title {
  font-size: 30px;
  font-weight: 500;
  color: var(--theme-color-dark);
  margin-bottom: 3px;
}

.footer-style-five .footer-top .right-box .top-left .text {
  font-size: 15px;
  color: var(--theme-color-dark);
  line-height: 26px;
}

.footer-style-five .footer-top .right-box .subscribe-form .form-group {
  position: relative;
  display: inline-block;
}

.footer-style-five .footer-top .right-box .subscribe-form .form-group input {
  width: 567px;
  height: 70px;
  border-radius: 16px;
  border: 1px solid var(--Border, #E1E1E1);
  padding: 0 36px;
  font-size: 15px;
}

.footer-style-five .footer-top .right-box .subscribe-form .form-group input::-webkit-input-placeholder {
  color: var(--theme-color-dark);
}

.footer-style-five .footer-top .right-box .subscribe-form .form-group input::-moz-placeholder {
  color: var(--theme-color-dark);
}

.footer-style-five .footer-top .right-box .subscribe-form .form-group input:-ms-input-placeholder {
  color: var(--theme-color-dark);
}

.footer-style-five .footer-top .right-box .subscribe-form .form-group input::-ms-input-placeholder {
  color: var(--theme-color-dark);
}

.footer-style-five .footer-top .right-box .subscribe-form .form-group input::placeholder {
  color: var(--theme-color-dark);
}

.footer-style-five .footer-top .right-box .subscribe-form .form-group button {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 15px;
  color: var(--theme-color-light);
  background-color: var(--theme-color1);
  padding: 13px 30px 10px;
  border-radius: 16px;
  text-transform: capitalize;
}

.footer-style-five .widgets-section {
  padding: 54px 0 20px;
}

.footer-style-five .widget-title {
  color: var(--theme-color-dark);
}

.footer-style-five .links-widget {
  padding-left: 18px;
  margin-bottom: 30px;
}

@media (max-width: 767.98px) {
  .footer-style-five .links-widget {
    padding-left: 0;
  }
}

.footer-style-five .links-widget .user-links {
  position: relative;
}

.footer-style-five .links-widget .user-links li {
  margin-bottom: 7px;
}

.footer-style-five .links-widget .user-links li:last-child {
  margin-bottom: 0;
}

.footer-style-five .links-widget .user-links li a {
  position: relative;
  font-size: 15px;
  display: inline-block;
  color: var(--theme-color-dark);
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}

.footer-style-five .links-widget .user-links li a i {
  position: absolute;
  left: 0;
  color: var(--theme-color1);
}

.footer-style-five .links-widget .user-links li a:hover {
  color: var(--theme-color1);
}

.footer-style-five .links-widget .user-links li a:before {
  position: absolute;
  left: 0;
  bottom: 3px;
  width: 0;
  height: 1px;
  background-color: var(--bg-theme-color1);
  content: "";
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}

.footer-style-five .links-widget .user-links li a:hover:before {
  width: 100%;
}

.footer-style-five .links-widget .user-links.style-two li {
  padding-left: 0;
}

.footer-style-five .links-widget .user-links.style-two li:before {
  display: none;
}

.footer-style-five .links-widget .user-links.two-column {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.footer-style-five .links-widget .user-links.two-column li {
  width: 50%;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
}

.footer-style-five .links-widget .user-links.two li {
  padding-left: 16px;
  margin-bottom: 6px;
}

.footer-style-five .links-widget .user-links.two li i {
  left: -17px;
}

.footer-style-five .social-widget .widget-content .text {
  line-height: 35px;
  font-size: 15px;
  color: var(--theme-color-dark);
  margin-bottom: 28px;
}

.footer-style-five .social-widget .widget-content .social-icons .title {
  color: var(--theme-color-dark);
  font-weight: 500;
  line-height: 30px;
  margin-bottom: 21px;
}

.footer-style-five .social-widget .widget-content .social-icons ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-left: -15px;
}

.footer-style-five .social-widget .widget-content .social-icons ul li a {
  color: var(--theme-color-dark);
  width: 40px;
  height: 40px;
  font-size: 14px;
  line-height: 40px;
  text-align: center;
  border-radius: 50%;
  display: inline-block;
}

.footer-style-five .social-widget .widget-content .social-icons ul li a:hover {
  background-color: #f9fbfc;
}

.footer-style-five .footer-bottom {
  border-top: 1px solid #E1E1E1;
}

@media (max-width: 575.98px) {
  .footer-style-five .footer-bottom .inner-container .footer-nav {
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: start;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
  }
}

@media (max-width: 575.98px) {
  .footer-style-five .footer-bottom .inner-container .footer-nav li {
    margin: 0 8px !important;
  }
}

.footer-style-five .footer-bottom .inner-container .footer-nav li a {
  color: var(--theme-color-dark);
}

.footer-style-five .footer-bottom .inner-container .copyright-text {
  color: var(--theme-color-dark);
}

@media (max-width: 575.98px) {
  .footer-style-five .footer-bottom .inner-container .copyright-text {
    text-align: left;
  }
}

.footer-style-five.v6 {
  background-color: var(--theme-color-dark);
}

.footer-style-five.v6 .footer-top .right-box {
  border-bottom: 1px solid #2a3041;
}

.footer-style-five.v6 .footer-top .right-box .top-left .title {
  color: var(--theme-color-light);
}

.footer-style-five.v6 .footer-top .right-box .top-left .text {
  color: var(--theme-color-light);
}

.footer-style-five.v6 .widget-title {
  color: var(--theme-color-light);
}

.footer-style-five.v6 .links-widget .user-links li a {
  color: var(--theme-color-light);
}

.footer-style-five.v6 .social-widget .widget-content .text {
  color: var(--theme-color-light);
}

.footer-style-five.v6 .social-widget .widget-content .social-icons .title {
  color: var(--theme-color-light);
}

.footer-style-five.v6 .social-widget .widget-content .social-icons ul li a {
  color: var(--theme-color-light);
}

.footer-style-five.v6 .footer-bottom {
  background-color: var(--theme-color-dark);
  border-top: 1px solid #2a3041;
}

.footer-style-five.v6 .footer-bottom .inner-container .copyright-text {
  color: var(--theme-color-light);
}

.footer-style-five.v6 .footer-bottom .inner-container .footer-nav li a {
  color: var(--theme-color-light);
}

/*** 

====================================================================
        calculater-section
====================================================================

***/
.calculater-section {
  padding: 120px 0;
}

.calculater-section.v2 .right-box {
  background: #050B20;
}

.calculater-section .boxcar-container {
  max-width: 100%;
  padding: 0 50px;
}

.calculater-section .right-box {
  background-color: #EEF1FB;
  border-radius: 16px;
}

.calculater-section .content-column .inner-column {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  height: 100%;
  max-width: 565px;
  margin: 0 auto;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.calculater-section .content-column form {
  width: 100%;
  margin: 0;
}

.calculater-section .content-column form .form_boxes {
  background-color: #fff;
  border-radius: 12px;
  -webkit-box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.08);
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.08);
  padding: 7px 15px;
  margin-bottom: 30px;
}

.calculater-section .content-column form .form_boxes label {
  display: block;
  color: #818181;
  font-size: 13px;
  font-weight: 400;
}

.calculater-section .content-column form .form_boxes .drop-menu {
  height: unset;
}

.calculater-section .content-column form .form_boxes .drop-menu .select {
  padding: 0;
  line-height: unset;
}

.calculater-section .content-column form .form_boxes .drop-menu .dropdown {
  padding: 5px 15px;
  margin-top: 10px;
  border-radius: 12px;
  left: -15px;
  width: calc(100% + 30px);
}

.calculater-section .content-column form .theme-btn {
  background: #405FF2;
  width: 100%;
  color: #fff;
  height: 54px;
  line-height: 54px;
  border-radius: 12px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.calculater-section .content-column form .theme-btn svg,
.calculater-section .content-column form .theme-btn img {
  margin-left: 10px;
}

.calculater-section .image-column .image-box>img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

/*** 

====================================================================
        login-section
====================================================================

***/
.login-section {
  position: relative;
  padding: 120px 0;
  border-radius: 80px;
  background-color: var(--theme-color-light);
}

.login-section .inner-container {
  max-width: 448px;
  margin: 0 auto;
}

.login-section nav {
  margin-bottom: 30px;
}

.login-section .nav-tabs .nav-link {
  position: relative;
  font-size: 16px;
  font-weight: 500;
  color: var(--theme-color-dark);
  border-top: 0;
  border-left: 0;
  border-right: 0;
  padding: 0 0 7px 0;
  display: inline-block;
  margin-right: 32px;
}

.login-section .nav-tabs .nav-link.active::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: var(--theme-color1);
}

.login-section form {
  width: 100%;
  margin: 0;
  padding-bottom: 51px;
  border-bottom: 1px solid #E1E1E1;
}

.login-section form .form_boxes {
  border-radius: 12px;
  border: 1px solid rgb(225, 225, 225);
  background: var(--color-white, #FFF);
  padding: 7px 15px;
  margin-bottom: 30px;
  width: 100%;
}

.login-section form .form_boxes textarea {
  width: 100%;
  height: 200px;
}

.login-section form .form_boxes textarea::-webkit-input-placeholder {
  color: var(--theme-color-dark);
}

.login-section form .form_boxes textarea::-moz-placeholder {
  color: var(--theme-color-dark);
}

.login-section form .form_boxes textarea:-ms-input-placeholder {
  color: var(--theme-color-dark);
}

.login-section form .form_boxes textarea::-ms-input-placeholder {
  color: var(--theme-color-dark);
}

.login-section form .form_boxes textarea::placeholder {
  color: var(--theme-color-dark);
}

.login-section form .form_boxes label {
  display: block;
  color: #818181;
  font-size: 15px;
  font-weight: 400;
}

.login-section form .form_boxes .drop-menu {
  height: unset;
}

.login-section form .form_boxes .drop-menu .select {
  padding: 0;
  line-height: unset;
}

.login-section form .form_boxes .drop-menu .dropdown {
  padding: 5px 15px;
  margin-top: 10px;
  border-radius: 12px;
  left: -15px;
  width: calc(100% + 30px);
  -webkit-box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.08);
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.08);
}

.login-section form .form_boxes.v2 {
  padding-top: 14px;
}

.login-section form .theme-btn {
  background: #405FF2;
  color: #fff;
  width: 100%;
  height: 54px;
  line-height: 54px;
  border-radius: 12px;
  padding: 0 26px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.login-section form .theme-btn svg {
  margin-left: 10px;
}

.login-section .form-box.two form {
  padding-bottom: 47px;
}

.login-section .form-box.two form .btn-box {
  margin-bottom: 0;
  margin-top: 29px;
}

.login-section .btn-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-bottom: 25px;
  margin-top: -3px;
  /* Hide the browser's default checkbox */
  /* Create a custom checkbox */
  /* On mouse-over, add a grey background color */
  /* When the checkbox is checked, add a blue background */
  /* Create the checkmark/indicator (hidden when not checked) */
  /* Show the checkmark when checked */
  /* Style the checkmark/indicator */
}

.login-section .btn-box .pasword-btn {
  text-decoration-line: underline;
  font-size: 15px;
  color: var(--theme-color-dark);
}

.login-section .btn-box .contain {
  display: block;
  position: relative;
  padding-left: 30px;
  cursor: pointer;
  font-size: 15px;
  color: var(--theme-color-dark);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.login-section .btn-box .contain input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.login-section .btn-box .checkmark {
  position: absolute;
  top: 4px;
  left: 0;
  height: 20px;
  width: 20px;
  border: 1px solid rgb(225, 225, 225);
  border-radius: 5px;
}

.login-section .btn-box .contain input:checked~.checkmark {
  background-color: #2196F3;
}

.login-section .btn-box .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.login-section .btn-box .contain input:checked~.checkmark:after {
  display: block;
}

.login-section .btn-box .contain .checkmark:after {
  left: 5px;
  top: 3px;
  width: 8px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.login-section .btn-box-three {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-top: -3px;
  /* Hide the browser's default radio button */
  /* Create a custom radio button */
  /* When the radio button is checked, add a blue background */
  /* Create the indicator (the dot/circle - hidden when not checked) */
  /* Show the indicator (dot/circle) when checked */
  /* Style the indicator (dot/circle) */
}

.login-section .btn-box-three .contain {
  display: block;
  position: relative;
  padding-left: 30px;
  cursor: pointer;
  font-size: 15px;
  color: var(--theme-color-dark);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin-right: 15px;
  margin-bottom: 26px;
}

.login-section .btn-box-three .contain input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.login-section .btn-box-three .checkmark {
  position: absolute;
  top: 4px;
  left: 0;
  height: 20px;
  width: 20px;
  border: 1px solid rgb(225, 225, 225);
  border-radius: 50%;
}

.login-section .btn-box-three .contain input:checked~.checkmark {
  background-color: var(--theme-color-dark);
}

.login-section .btn-box-three .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.login-section .btn-box-three .contain input:checked~.checkmark:after {
  display: block;
}

.login-section .btn-box-three .contain .checkmark:after {
  top: 3px;
  left: 3px;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: white;
}

.login-section .btn-box-two {
  position: relative;
}

.login-section .btn-box-two span {
  color: var(--theme-color-dark);
  position: absolute;
  top: -15px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  background-color: var(--theme-color-light);
  padding: 0 9px;
}

.login-section .btn-box-two .social-btns {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding-top: 38px;
}

.login-section .btn-box-two .social-btns .fb-btn {
  font-size: 14px;
  color: #1967D2;
  padding: 0 33px;
  height: 54px;
  line-height: 54px;
  display: inline-block;
  border-radius: 8px;
  border: 1px solid var(--color-one, #1967D2);
  background: var(--color-white, #FFF);
}

.login-section .btn-box-two .social-btns .fb-btn i {
  margin-right: 9px;
  color: #1967D2;
}

.login-section .btn-box-two .social-btns .fb-btn.two {
  color: #D93025;
  border-color: #D93025;
}

.login-section .btn-box-two .social-btns .fb-btn.two i {
  color: #D93025;
}

.login-section .form-sec.two {
  margin-top: 113px;
}

.form_boxes input {
  width: 100%;
  color: #000;
}

.form_boxes input::-webkit-input-placeholder {
  color: #000;
}

.form_boxes input::-moz-placeholder {
  color: #000;
}

.form_boxes input:-ms-input-placeholder {
  color: #000;
}

.form_boxes input::-ms-input-placeholder {
  color: #000;
}

.form_boxes input::placeholder {
  color: #000;
}

.theme-btn img {
  margin-left: 10px;
}

.boxcar-team-section .team-block {
  margin-bottom: 0;
}

.car-block-five .inner-box .image-box,
.car-block-seven .inner-box .image-box {
  width: 100%;
}

.car-block-five .inner-box .image-box img,
.car-block-seven .inner-box .image-box img {
  width: 100%;
}

.news-letter-section .image-column .inner-column {
  height: 100%;
}

.boxcar-pricing-section .image-column {
  margin-bottom: 0;
}

.blog-block-four .inner-box .image-box .image img {
  width: 100%;
}

.blog-section-four .inner-column-two {
  margin-right: 0;
}

.blog-section-five .right-box-two .list-sec .inner-column .list li {
  line-height: unset;
  margin-bottom: 20px;
}

.cars-section-four .form-box .form_boxes .drop-menu {
  margin-bottom: 10px;
}

.inventory-section .side-bar-column.v3 .overview-box .list li span {
  text-align: left;
}

.inventory-section .side-bar-column.v4 .overview-box .list.v2 li {
  text-align: right;
}

.boxcar-team-section.v1 .team-block {
  margin-bottom: 50px;
}

.header-style-ten .header-inner .c-box .nav-out-bar .nav .navigation .current-dropdown.right-one .dropdown {
  left: auto;
  right: 0;
}

.header-style-ten .header-inner .current-dropdown.right-one .dropdown .nav-sub {
  position: relative;
}

.header-style-ten .header-inner .c-box .nav-out-bar .nav .navigation .current-dropdown.right-one .dropdown::before {
  left: unset;
  right: 20px;
}

.header-style-ten .header-inner .c-box .nav-out-bar .nav .navigation .current-dropdown .mega-menu {
  left: -245px;
  margin-top: 20px;
}

.header-style-ten .header-inner .c-box .nav-out-bar .nav .navigation .current-dropdown .mega-menu::before {
  left: 260px;
}

/* .header-style-ten .c-box .nav-out-bar .nav .navigation .current-dropdown .dropdown .nav-sub:hover .subnav-menu {
  visibility: visible;
  opacity: 1;
} */
.gm-control-active.gm-fullscreen-control {
  top: 20px !important;
  right: 30px !important;
}

@media only screen and (max-width: 1520px) {
  .hheader-style-v4 .header-inner .c-box .nav-out-bar {
    display: none;
  }

  .hheader-style-v4 .header-inner .right-box {
    margin-left: auto;
  }

  .brand-block-four {
    width: 10%;
  }

  .contact-us-section .map-sec {
    margin: 0;
  }
}

/* Medium Large devices */
@media (max-width: 1399px) {
  .boxcar-banner-section-two {
    height: 100vh;
  }

  .boxcar-banner-section-two .banner-slider {
    height: 100%;
  }

  .boxcar-banner-section-two .banner-slider .slick-list,
  .boxcar-banner-section-two .banner-slider .slick-track,
  .boxcar-banner-section-two .banner-slider .banner-slide {
    height: 100%;
  }

  .boxcar-banner-section-two .banner-slider .banner-slide>img {
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
  }

  .boxcar-service-section .right-box {
    padding: 70px;
  }

  .hheader-style-v4 .header-inner .c-box .nav-out-bar {
    display: none;
  }

  .hheader-style-v4 .header-inner .right-box {
    margin-left: auto;
  }

  .calculater-section .content-column .inner-column {
    padding: 50px;
  }

  .calculater-section .image-box {
    height: 100%;
  }

  .calculater-section .image-box img {
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
  }

  .boxcar-service-section-two .right-box {
    padding: 70px;
  }

  .boxcar-banner-section-four .banner-slider-v4 .banner-slide .right-box .list-box .items {
    margin-bottom: 25px;
  }

  .brand-block-four {
    width: auto;
    margin-bottom: 15px;
  }

  .why-choose-us-section-three .right-box {
    padding: 100px 50px 70px;
  }

  .boxcar-testimonial-section-three .right-box {
    padding: 100px 30px;
  }

  .boxcar-brand-section-three .right-box {
    padding: 70px;
  }

  .boxcar-testimonial-section-three .content-column .inner-column .boxcar-title .text {
    padding-right: 50px;
  }

  .boxcar-inspiration-section-two .right-box-two {
    padding: 120px 50px;
  }

  .why-choose-us-section.v2 .boxcar-container {
    max-width: 100%;
  }

  .boxcar-testimonial-section-four .right-box {
    padding: 120px 15px 150px;
  }

  .boxcar-pricing-section-four.v7 .content-column .inner-column {
    padding-left: 50px;
    padding-bottom: 50px;
  }

  .calculater-section {
    padding-top: 50px;
  }

  .calculater-section .boxcar-container {
    padding: 0 15px;
  }

  .boxcar-service-section.v7 .right-box .content-column .inner-column {
    margin-right: 0;
    margin-top: 0;
  }

  .boxcar-brand-section.v7::before {
    width: 100%;
  }

  .cars-section-ten .right-box {
    padding: 120px 50px;
  }

  .news-letter-section .form-column .inner-column {
    padding-left: 100px;
  }



  .boxcar-brand-section-six .cars-block-six {
    margin-bottom: 20px;
  }

  .boxcar-brand-section-five .right-box {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }

  .cars-block-five {
    margin-right: 0;
  }

  .boxcar-fun-fact-section-two .fact-counter {
    padding: 104px 70px 79px;
  }

  .header-style-v9 .header-inner .main-menu .navigation>li {
    margin-right: 0;
  }

  .header-style-v9 .header-inner .c-box .nav-out-bar {
    display: none;
  }

  .header-style-v9 .header-inner .c-box .logo {
    left: 0;
  }

  .header-style-v9 .header-inner .right-box {
    width: auto;
  }


  .boxcar-banner-section-nine .banner-content .content-box {
    margin-top: 100px;
  }

  .boxcar-pricing-section-five .content-column .inner-column {
    padding: 0 50px;
    background: transparent;
  }

  .boxcar-pricing-section-five .row {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background: var(--theme-color-dark);
  }

  .boxcar-pricing-section-six .content-column .inner-column {
    padding: 0 50px;
  }

  .boxcar-pricing-section-six .row {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .header-style-ten .header-inner .c-box .nav-out-bar {
    margin-left: 30px;
    margin-right: auto;
  }

  .dashboard-widget .content-column {
    width: calc(100% - 300px);
  }

  .dashboard-widget .content-column .inner-column .cheak-box-sec .right-box-two .cheak-box {
    margin-right: 20px;
  }

  .dashboard-widget .content-column .inner-column .gallery-sec .attachment-sec .right-box-four {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .dashboard-widget .content-column .inner-column .gallery-sec .attachment-sec .right-box-four .report-box {
    margin-bottom: 30px;
  }

  .car-block-forteen .inner-box {
    display: block;
    border: 1px solid var(--Border, #E1E1E1);
    border-radius: 16px;
  }

  .car-block-forteen .inner-box .content-box {
    width: 100%;
    border: 0;
  }

  .car-block-forteen .inner-box .image-box {
    width: 100%;
  }

  .car-block-forteen .inner-box .image-box .image img {
    width: 100%;
    margin-bottom: 20px;
  }

  .cars-section-thirteen .boxcar-title-three .service-list {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .cars-section-thirteen .boxcar-title-three .service-list li {
    margin-bottom: 15px;
  }

  .service-block-thirteen .image-box {
    width: 150px;
  }

  .service-block-thirteen .image-box .image {
    height: 100%;
  }

  .service-block-thirteen .image-box img {
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
  }

  .shop-single-product-slider {
    width: 80%;
  }

  .vehicles-section-three .right-box .auther-info-box {
    margin: 0;
    padding: 40px 15px;
  }

  .vehicles-section-three .right-box .auther-info-box .inner-column .auther-info .content-box {
    max-width: 100%;
  }

  .vehicles-section-three .right-box .auther-info-box .inner-column .auther-info .btn-box {
    margin-left: 50px;
  }

  .vehicles-section-three .right-box .auther-info-box .inner-column .auther-info .btn-box .button {
    min-width: 200px;
    padding: 0 30px;
    height: 60px;
    line-height: 60px;
    text-align: center;
  }
}

/* Large devices */
@media (max-width: 1199px) {
  .boxcar-service-section .right-box .image-column {
    display: none;
  }

  .brand-boxcar-banner-section {
    padding: 100px 0;
  }

  .brand-boxcar-banner-section .content-column .inner-column .title {
    font-size: 50px;
    line-height: 60px;
    max-width: 500px;
  }

  .calculater-section .image-column .image-box {
    display: none;
  }

  .calculater-section .col-lg-6 {
    max-width: 100%;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
  }

  .calculater-section .content-column .inner-column {
    display: block;
    max-width: 100%;
  }

  .calculater-section .content-column form {
    margin: 0 -15px;
  }

  .boxcar-service-section-two .image-column .inner-column .image-box {
    display: none;
  }

  .boxcar-service-section-two .col-lg-6 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }

  .hheader-style-v4.five .header-inner .mobile-navigation a {
    color: #000;
  }

  .choose-us-block .inner-box .content-box .text {
    padding-right: 0;
  }

  .boxcar-testimonial-section-three .content-column .inner-column .boxcar-title .text {
    padding-right: 0;
  }

  .boxcar-brand-section-three .image-column {
    display: none;
  }

  .boxcar-brand-section-three .content-column .inner-column {
    margin-top: 0;
  }

  .boxcar-banner-section-six::before {
    display: none;
  }

  .hheader-style-v4.v6 .header-inner .mobile-navigation {
    color: #000;
  }

  .hheader-style-v4.v6 .btn {
    display: none;
  }

  .hheader-style-v4.v6 .search {
    display: none;
  }

  .boxcar-banner-section-six .content-column .inner-column {
    padding: 100px 0 100px;
  }

  .boxcar-pricing-section-four .content-column .inner-column {
    padding: 0 0 0 30px;
  }

  .boxcar-fun-fact-section .fact-counter {
    padding: 55px 15px 29px;
  }

  .boxcar-inspiration-section-two .right-box-two {
    padding: 80px 15px;
  }

  .boxcar-inspiration-section-two .right-box-two .uper-box .btn-box .read-more {
    margin: 10px 0;
  }

  .header-style-v7 .header-inner .mobile-navigation a {
    color: #000;
  }

  .why-choose-us-section-four::before {
    display: none;
  }

  .why-choose-us-section-four {
    padding-top: 0;
    padding-bottom: 0;
  }

  .cars-section-nine {
    padding-top: 50px;
  }

  .boxcar-pricing-section-four.v7 .content-column .inner-column {
    padding-top: 0;
  }

  .boxcar-banner-section-v8 .banner-content-v8 {
    padding-top: 70px;
  }

  .news-letter-section .form-column .inner-column {
    padding-left: 30px;
  }

  .boxcar-pricing-section-four.v9 .content-column .inner-column {
    padding: 0 0 0 30px;
  }

  .hheader-style-v4.v10 .header-inner .mobile-navigation a {
    color: #000;
  }

  .boxcar-banner-section-seven .banner-slider-v7 .banner-slide {
    height: 650px;
  }

  .boxcar-banner-section-seven .banner-slider-v7 .banner-slide img {
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
  }

  .boxcar-banner-section-seven.v10 form .form_boxes {
    width: 19%;
  }

  .boxcar-pricing-section-six .content-column .inner-column .boxcar-title h2 {
    font-size: 36px;
    line-height: 48px;
  }

  .boxcar-pricing-section-six .content-column .inner-column {
    padding: 0;
  }

  .dashboard-widget .content-column .inner-column .form-box form .form-column {
    width: 50%;
  }

  .dashboard-widget .content-column .inner-column .form-box form .form-column.col-lg-12 {
    width: 100%;
  }

  .dashboard-widget .content-column .inner-column .cheak-box-sec .right-box-two {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .dashboard-widget .content-column .inner-column .cheak-box-sec .right-box-two .cheak-box {
    width: 45%;
  }

  .dashboard-widget .content-column .inner-column .gallery-sec .right-box-three .gallery-box .inner-box {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .dashboard-widget .content-column .inner-column .gallery-sec .right-box-three .gallery-box .inner-box .image-box {
    margin-bottom: 30px;
  }

  .dashboard-widget .content-column .inner-column .gallery-sec .attachment-sec .right-box-four .report-box {
    width: 100%;
    margin-right: 0;
  }

  .blog-block-four .inner-box {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .blog-block-four .inner-box .image-box {
    width: 100%;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }

  .blog-block-four .inner-box .content-box {
    margin-left: 0;
    margin-top: 30px;
  }

  .blog-block-four .inner-box .image-box img {
    width: 100%;
  }

  .blog-section-four .inner-column-two {
    margin-right: 0;
  }

  .sider-bar {
    margin-top: 70px;
  }

  .shop-product-cart-info {
    margin-top: 10px;
  }

  .checkout-section .side-bar {
    padding-left: 0;
  }

  .contact-us-section .calculater-sec .content-column .inner-column {
    padding-right: 0;
  }

  .contact-us-section .calculater-sec .contact-column .inner-column {
    margin-left: 0;
  }

  .contact-us-section .ofice-section .ofice-block .inner-box .contact-list {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .contact-us-section .ofice-section .ofice-block .inner-box .contact-list li {
    margin-bottom: 15px;
  }

  .dealer-ship-section-two .side-bar .message {
    width: auto;
    padding: 0 30px;
  }

  .barnd-box .side-bar {
    margin-top: 50px;
  }

  .inventory-pager .inventory-form .form_boxes {
    width: 20%;
    height: auto;
  }

  .inventory-pager {
    padding-bottom: 120px;
  }

  .inventory-pager .inventory-form .form-submit .theme-btn {
    position: relative;
    bottom: -15px;
  }

  .cars-section-four .form-box .form_boxes .drop-menu {
    margin-bottom: 10px;
  }

  .inventory-section .side-bar-column.v2 .inner-column {
    padding-left: 0;
    margin-top: 50px;
  }

  .inventory-section .side-bar-column .inner-column .contact-box-two .btn-box .side-btn {
    width: auto;
    padding: 0 25px;
    margin-right: 15px;
  }

  .inventory-section .side-bar-column .inner-column .contact-box .content-box .btn-box .side-btn {
    width: auto;
    margin-right: 15px;
    padding: 0 25px;
  }

  .inventory-section .side-bar-column .inner-column .contact-box .content-box .btn-box .side-btn-three {
    text-align: left;
  }

  .inventory-section .side-bar-column.v2 .inner-column {
    margin-top: 0;
  }

  .text-box {
    /* margin-top: 30px; */
  }

  .service-block-thirteen .image-box {
    width: 200px;
  }

  .service-block-thirteen .inner-box .right-box {
    width: calc(100% - 200px);
  }

  .inventory-sidebar .price-box form .form-column.v2 {
    padding: 0;
    margin-bottom: 30px;
  }

  .cars-section-fourteen .text-box {
    margin-top: 0;
  }

  .car-block-fourteen .inner-box .content-box .text {
    text-align: left;
  }

  .shop-single-product-slider {
    margin-bottom: 50px;
  }
}

/* Medium devices */
@media (max-width: 991px) {
  .cars-block-two .inner-box {
    margin-bottom: 25px;
  }

  .boxcar-customers-section .boxcar-title .text {
    display: none;
  }

  .boxcar-team-section .team-block {
    margin-bottom: 30px;
  }

  .boxcar-team-section {
    padding-bottom: 90px;
  }

  .boxcar-inspiration-section .right-box .service-list {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .boxcar-inspiration-section .right-box .service-list li {
    margin-bottom: 20px;
    margin-right: 20px;
  }

  .footer-style-two .footer-top .right-box {
    display: block;
  }

  .footer-style-two .footer-top .right-box .top-left {
    margin-bottom: 30px;
  }

  .footer-style-two .footer-top .right-box .subscribe-form .form-group {
    width: 100%;
  }

  .footer-style-two .footer-top .right-box .subscribe-form .form-group input {
    width: 100%;
  }

  .footer-style-two .links-widget {
    padding-left: 0;
  }

  .header-style-v3 .header-inner .right-box .search-btn {
    display: none;
  }

  .boxcar-pricing-section-three .content-column .inner-column {
    padding: 0 0 0 50px;
  }

  .boxcar-pricing-section-three {
    padding: 90px 0;
  }



  .boxcar-banner-section-four .banner-slider-v4 .banner-slide .right-box .list-box {
    display: none;
  }

  .search-btn {
    display: none;
  }

  .hheader-style-v4 .header-inner .right-box .search {
    display: none;
  }

  .boxcar-banner-section-four .banner-slider-v4 .slick-prev,
  .boxcar-banner-section-four .banner-slider-v4 .slick-next {
    display: none !important;
  }

  .boxcar-banner-section-four .banner-slider-v4 .slick-dots {
    margin-left: 0;
    left: 15px;
  }

  .cars-section-six {
    padding-bottom: 90px;
  }

  .boxcar-customers-section-two .boxcar-title .text {
    display: none;
  }

  .boxcar-banner-section-five .banner-content {
    width: 100%;
  }

  .boxcar-brand-section-four .right-box {
    /* -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center; */
  }

  .boxcar-testimonial-section-three .content-column .inner-column {
    margin-top: 0;
    margin-bottom: 50px;
  }

  .testimonial-block-three .inner-box {
    margin-bottom: 30px;
  }

  .boxcar-brand-section-three .col-md-6 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }

  .boxcar-pricing-section-four .content-column .inner-column {
    padding: 0;
    margin-top: 40px;
  }

  .boxcar-inspiration-section-two .right-box-two .uper-box .content-box .boxcar-title .text br {
    display: none;
  }

  .boxcar-inspiration-section-two .right-box .service-list {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .boxcar-inspiration-section-two .right-box .service-list li {
    margin-bottom: 28px;
  }

  .boxcar-banner-section-seven .inner-box .banner-slide {
    height: 450px;
  }

  .boxcar-banner-section-seven .inner-box .banner-slide img {
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
  }

  .boxcar-banner-section-seven .banner-slider-v7 .banner-slide .right-box .content-box {
    max-width: 70%;
    margin: 0 auto;
  }

  .boxcar-banner-section-seven .banner-slider-v7 .banner-slide .right-box .content-box h1 {
    font-size: 50px;
  }

  .boxcar-pricing-section-four.v7 .content-column .inner-column {
    padding-left: 15px;
    margin-top: 0;
  }

  .boxcar-brand-section.v7 {
    padding-bottom: 0;
    margin-bottom: -30px;
  }

  .header-style-v7 .header-inner .c-box .logo-inner {
    display: none;
  }

  .header-style-v7 .header-inner .c-box .logo {
    right: 0px;
  }

  .cars-block-five {
    width: 25%;
    margin-bottom: 30px;
  }

  .boxcar-brand-section-five {
    padding-bottom: 80px;
  }

  .news-letter-section .image-column {
    display: none;
  }

  .boxcar-banner-section-nine .banner-content {
    width: 100%;
    padding: 30px 15px 0 15px;
    position: static;
    -webkit-transform: unset;
    transform: unset;
  }

  .boxcar-banner-section-nine .banner-content .form-tab-pane form {
    display: block;
    height: auto;
    padding-bottom: 30px;
  }

  .boxcar-banner-section-nine .banner-content .form-tab-pane form .form_boxes {
    width: 100%;
  }

  .boxcar-banner-section-nine .banner-content .form-submit button {
    width: 100%;
  }

  .boxcar-banner-section-nine {
    height: unset;
  }

  .boxcar-banner-section-nine .banner-content .content-box h2 {
    font-size: 50px;
  }

  .boxcar-banner-section-nine .container {
    padding-bottom: 50px;
  }

  .boxcar-pricing-section-four.v9 .content-column .inner-column {
    margin: 0;
  }

  .boxcar-pricing-section-four.v9 .image-column .inner-column .image-box .image-3 {
    margin-left: 30px;
  }

  .boxcar-fun-fact-section-three {
    padding: 70px 15px 50px;
  }

  .blog-blockt-three .inner-box .hover-img .image {
    height: 400px;
  }

  .blog-blockt-three .inner-box .hover-img .image img {
    height: 100%;
  }

  .blog-blockt-three .inner-box .hover-img .content-box {
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    left: 0;
    padding: 0 15px;
  }

  .car-block-eleven .inner-box .image-box .image img {
    width: 100%;
  }

  .car-block-eleven .inner-box .image-box {
    width: 100%;
  }

  .boxcar-team-section-four .right-box {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .team-block-four {
    width: calc(50% - 0px);
    margin-right: 0;
    padding: 0 15px;
    margin-bottom: 40px;
  }

  .boxcar-team-section-four {
    padding-bottom: 80px;
  }

  .boxcar-team-section-four .right-box {
    margin: 0 -15px;
  }

  .boxcar-banner-section-seven.v10 .form-tab-content {
    position: static;
  }

  .boxcar-banner-section-seven.v10 form {
    display: block;
    padding-bottom: 30px;
    border-radius: 16px;
    margin-top: 30px;
  }

  .boxcar-banner-section-seven.v10 form .form_boxes {
    width: 100%;
  }

  .boxcar-banner-section-seven.v10 form .form_boxes::before {
    display: none;
  }

  .boxcar-banner-section-seven.v10 form .drop-menu .select {
    padding: 0;
  }

  .boxcar-banner-section-seven.v10 .form-submit button {
    width: 100%;
  }

  .boxcar-pricing-section-six .image-column {
    display: none;
  }

  .car-block-twelve .inner-box .image-box {
    width: 100%;
  }

  .car-block-twelve .inner-box .image-box .image img {
    width: 100%;
  }

  .boxcar-pricing-section-five .content-column .inner-column {
    padding: 70px 50px;
  }

  .footer-style-four .footer-top .right-box {
    display: block;
  }

  .footer-style-four .footer-top .right-box .top-left {
    margin-bottom: 50px;
  }

  .footer-style-four .footer-top .right-box .top-right .subscribe-form .form-group {
    width: 100%;
  }

  .footer-style-four .footer-top .right-box .top-right .subscribe-form .form-group input {
    width: 100%;
  }

  .footer-style-four .footer-top .right-box .top-right .subscribe-form .form-group button {
    width: 100%;
  }

  .dashboard-widget .side-bar {
    display: none;
  }

  .dashboard-widget .content-column {
    width: 100%;
  }

  .dashboard-widget .content-column .inner-column {
    margin-right: 0;
  }

  .dashboard-widget .content-column .inner-column {
    padding: 50px 15px;
  }

  .blog-section-five .right-box-two form {
    width: unset;
    margin: 0 -15px;
  }

  .cart-totals {
    margin-top: 50px;
  }

  .checkout-section .content-column .inner-column form .form-column {
    padding-right: 0;
  }

  .contact-us-section .calculater-sec .content-column .inner-column {
    margin-bottom: 50px;
  }

  .ofice-block .inner-box {
    margin-bottom: 40px;
  }

  .contact-us-section .ofice-section {
    margin-bottom: -50px;
  }

  .dealer-ship-section-two .inner-column .brand-box .image-box {
    position: static;
    margin-bottom: 30px;
  }

  .dealer-ship-section-two .inner-column .brand-box {
    padding-left: 0;
  }

  .inventory-pager .inventory-form {
    height: auto;
    display: block;
    border-radius: 16px;
    padding-bottom: 30px;
  }

  .inventory-pager .inventory-form .form_boxes {
    width: 100%;
  }

  .inventory-pager .inventory-form .form_boxes>a {
    padding: 0 30px;
    margin-left: 0;
  }

  .inventory-pager .inventory-form .form-submit .theme-btn {
    bottom: 0;
    margin-top: 30px;
    width: 100%;
  }

  .cars-section-four.v1.v5 .right-box {
    padding: 40px 15px;
  }

  .car-block-forteen .inner-box .image-box .image img {
    height: auto;
    -o-object-fit: cover;
    object-fit: cover;
  }

  .inventory-section .inspection-column .inner-column form {
    width: auto;
    margin: 0 -15px;
  }

  .inventory-section .side-bar-column.v2 {
    margin-top: 50px;
  }

  .inventory-section .boxcar-title-three .content-box {
    position: static;
    margin-top: 25px;
  }

  .inventory-section .boxcar-title-three .content-box .title {
    text-align: left;
  }

  .inventory-section .boxcar-title-three .content-box {
    text-align: left;
  }

  .inventory-section .gallery-sec .image-column-two .inner-column .image-box {
    width: 100%;
  }

  .inventory-section .gallery-sec .image-column-two .inner-column .image-box img {
    width: 100%;
  }



  .inventory-section .gallery-sec .image-column-two .inner-column .image-box .image {
    border-radius: 0px;
  }




  .gallery-sec .item4 .image {
    border-bottom-left-radius: 15px !important;
  }

  .gallery-sec .item5 .image {
    border-bottom-right-radius: 15px !important;

  }

  .features-sec .list-column {
    margin-bottom: 40px;
  }

  .service-block-thirteen .image-box {
    width: 100%;
  }

  .service-block-thirteen .inner-box .right-box {
    width: 100%;
  }

  .service-block-thirteen .inner-box {
    display: block;
  }

  .service-block-thirteen .inner-box .image-box .image img {
    height: 350px;
    -o-object-fit: cover;
    object-fit: cover;
    border-radius: 0;
    width: 100%;
  }

  .service-block-thirteen .inner-box .right-box {
    padding: 20px 0;
    border: 0;
  }

  .service-block-thirteen .inner-box .right-box {
    display: block;
  }

  .service-block-thirteen .inner-box .right-box .content-box-two:before {
    display: none;
  }

  .service-block-thirteen .inner-box .right-box .content-box-two {
    text-align: left;
    margin-left: 0;
  }

  .contact-block .inner-box {
    max-width: 100%;
  }

  .order_list_raw {
    padding: 30px;
  }

  .order_details {
    padding: 30px;
  }

  .shop-cart.pt30 .container {
    padding-bottom: 0;
  }

  .pricing-block-seven {
    margin-bottom: 30px;
  }

  .boxcar-pricing-section-seven {
    padding-bottom: 90px;
  }

  .dashboard-widget-two .right-box {
    display: block;
  }

  .dashboard-widget-two .side-bar {
    /* width: 100%;
    padding: 0 15px;
    margin-bottom: 60px; */
    display: none;
  }

  .dashboard-widget-two .right-box-two {
    margin-right: 0;
    padding: 30px 15px;
  }

  .dashboard-widget-two .right-box-two .cars-section-four {
    padding: 15px;
  }

  .dashboard-widget-two .content-column .inner-column {
    margin-right: 0;
    padding: 50px 15px;
  }

  .services-inner-section .calculater-sec .timing-block {
    width: 100%;
    margin-top: 20px;
    padding-left: 15px;
    padding-right: 15px;
  }

  .price-box form .form-column.v2 {
    padding-left: 0;
  }

  .cars-section-fourteen .content-column {
    margin-top: 50px;
  }

  .cars-section-fifteen .right-box {
    margin-bottom: 40px;
  }

  .cars-section-fifteen .content-sec .content-column .inner-column form {
    width: auto;
    margin: 0 -15px;
  }

  .vehicles-section-three .right-box .auther-info-box .inner-column {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .vehicles-section-three .right-box .auther-info-box .inner-column .image-box {
    margin-bottom: 50px;
  }
}

/* Small devices */
@media (max-width: 767px) {
  .image a {
    display: block;
    height: 100%;
  }

  .image a img {
    height: 100%;
    width: 100%;
    -o-object-fit: cover;
    object-fit: cover;
  }

  .boxcar-pricing-section-two .content-column .inner-column {
    padding-top: 0;
    padding-bottom: 70px;
  }

  .choose-us-block:last-child {
    margin-bottom: 0;
  }

  .car-block-three .inner-box .content-box ul li {
    margin-right: 0;
    width: 33%;
  }

  .boxcar-service-section .right-box {
    padding: 40px;
  }

  .boxcar-service-section .content-column .inner-column .store.two {
    margin-left: 0;
    margin: 10px 0;
  }

  .boxcar-pricing-section-two .image-column .inner-column .image-box .image img {
    height: auto;
  }

  .boxcar-banner-section-v1.banner-style-three .banner-content-three {
    position: static;
    -webkit-transform: unset;
    transform: unset;
  }

  .boxcar-banner-section-v1.banner-style-three {
    padding: 150px 0 50px;
  }

  .header-style-v3 .header-inner .right-box span {
    display: none;
  }

  .header-style-v3 .btn {
    display: none;
  }

  .header-style-v3 .header-inner .c-box .logo-inner .menu {
    display: none;
  }

  .header-style-v3 {
    top: 20px;
  }

  .car-block-five .inner-box .image-box {
    width: 100%;
  }

  .testimonila-section-two .content-column {
    margin-bottom: 70px;
  }

  .boxcar-pricing-section-three .content-column .inner-column {
    margin-top: 50px;
    padding: 0;
  }

  .boxcar-fun-fact-section .fact-counter {
    padding: 70px 0 40px;
  }

  .calculater-section .boxcar-container {
    padding: 0 15px;
  }

  .boxcar-service-section-two .content-column .inner-column .store.two {
    margin: 10px 0;
  }

  .boxcar-service-section-two .right-box {
    padding: 60px 40px;
  }

  .boxcar-banner-section-five .banner-content h2 {
    font-size: 50px;
    line-height: 60px;
  }

  .boxcar-banner-section-five .banner-content .form-tab-pane form {
    height: auto;
    display: block;
  }

  .boxcar-banner-section-five .banner-content .form-tab-pane form .form_boxes {
    width: 100%;
    border: 1px solid #eaeaea;
    border-radius: 16px;
    margin-bottom: 20px;
  }

  .boxcar-banner-section-five .banner-content .form-tab-pane form .form_boxes:before {
    display: none;
  }

  .boxcar-banner-section-five .banner-content .form-tab-pane {
    margin-bottom: 0;
  }

  .boxcar-banner-section-five .image-column {
    margin-top: 0;
  }

  .boxcar-banner-section-five:before {
    height: calc(100% - 100px);
  }

  .boxcar-banner-section-five .banner-content .form-tab-pane form {
    padding: 30px;
    border-radius: 15px;
  }

  .boxcar-banner-section-five .banner-content .form-submit {
    /* padding-left: 30px; */
  }

  .brand-block-four .image img {
    width: auto;
  }

  .image a img {
    width: auto;
  }

  .team-block-two {
    margin-bottom: 30px;
  }

  .blog-block .inner-box .image-box .image img {
    width: 100%;
  }

  .boxcar-banner-section-six .content-column .inner-column .right-box .service-list {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .boxcar-banner-section-six .content-column .inner-column .right-box .service-list li {
    margin-bottom: 15px;
  }

  .dealership-block {
    margin-bottom: 30px;
  }

  .dealership-section {
    padding-bottom: 90px;
  }

  .boxcar-inspiration-section-two .right-box-two .uper-box {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .boxcar-inspiration-section-two .right-box-two .uper-box .content-box {
    margin-bottom: 40px;
  }

  .boxcar-inspiration-section-two .right-box-two .uper-box {
    margin-bottom: 40px;
  }

  .boxcar-title .text {
    display: none;
  }

  .boxcar-team-section-three .right-box {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .team-block-three {
    margin-right: 0;
    width: 50%;
  }

  .choose-us-block-four {
    margin-bottom: 30px;
  }

  .car-block-nine .inner-box .image-box {
    width: 100%;
  }

  .car-block-nine .inner-box .image-box img {
    width: 100%;
  }

  .boxcar-pricing-section-four.v7 .content-column .inner-column {
    padding: 0;
    margin-top: 50px;
  }

  .calculater-section .content-column .inner-column {
    padding-right: 15px;
  }

  .boxcar-banner-section-v8 .banner-content-v8 h2 {
    font-size: 40px;
  }

  .boxcar-banner-section-v8 .banner-content-v8 .banner-v8-form {
    max-width: 100%;
  }

  .boxcar-brand-section-five .right-box {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .car-block-ten .inner-box .image-box .image img {
    width: 100%;
  }

  .car-block-ten .inner-box .image-box {
    width: 100%;
  }

  .news-letter-section .form-column .inner-column .subscribe-form .form-group input {
    width: 100%;
  }

  .news-letter-section .form-column .inner-column .subscribe-form .form-group {
    width: 100%;
  }

  .news-letter-section .form-column .inner-column {
    padding: 70px 30px;
  }

  .boxcar-pricing-section-four.v9 .content-column .inner-column {
    padding-left: 0;
    margin-top: 50px;
  }

  .blog-blockt-three .inner-box {
    margin-bottom: 30px;
  }

  .blog-blockt-three .inner-box .hover-img .image img {
    border-radius: 0;
  }

  .boxcar-pricing-section-five .image-column .inner-column .image-box .image img {
    height: auto;
  }

  .boxcar-pricing-section-six .content-column .inner-column .btn-box {
    display: block;
    margin-bottom: 20px;
  }

  .boxcar-pricing-section-six .content-column .inner-column .btn-box .btn-two {
    width: 100%;
    margin-bottom: 15px;
  }

  .error-section .right-box .content-box {
    margin-top: 0;
  }

  .about-inner-one .upper-box .content-box {
    margin-top: 0;
  }

  .dashboard-widget .content-column .inner-column .cheak-box-sec .right-box-two .cheak-box {
    width: 100%;
  }

  .dashboard-widget .content-column .inner-column .gallery-sec .right-box-three .gallery-box .inner-box .image-box {
    width: 100%;
    margin-right: 0;
  }

  .dashboard-widget .content-column .inner-column .gallery-sec .right-box-three .gallery-box .inner-box .image-box>img {
    width: 100%;
  }

  .dashboard-widget .content-column .inner-column .gallery-sec .attachment-sec .right-box-four .uplode-box {
    width: 100%;
  }

  .blog-section-five .right-box-two .list-sec .inner-column {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .ruls-sec .content-box {
    padding: 0 15px;
  }

  .cart-table-bottom .coupan-form {
    margin-bottom: 20px;
  }

  .contact-us-section .map-sec .goole-iframe iframe {
    height: 350px;
  }

  .contact-us-section .calculater-sec .content-column form {
    margin: 0 -15px;
    width: auto;
  }

  .dealer-ship-section-two .inner-column .service-box .list-sec .service-list.two {
    margin-right: 50px;
  }

  .dealer-ship-section-two .inner-column .review-sec .review-box {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .dealer-ship-section-two .inner-column .review-sec .review-box .rating-box {
    margin-right: 0;
    margin-bottom: 40px;
  }

  .dealer-ship-section-two .inner-column .review-sec .review-box .review-list {
    margin-right: 0;
    width: 100%;
  }

  .dealer-ship-section-two .inner-column .review-sec .review-box .review-list.two {
    margin-right: 0;
    margin-bottom: 40px;
  }

  .dealer-ship-section-two .inner-column form {
    width: auto;
    margin: 0 -15px;
  }

  .inventory-section .boxcar-title-three .content-box {
    position: static;
    margin-top: 20px;
  }

  .features-sec .list-column .inner-column {
    margin-bottom: 40px;
  }

  .inventory-section .inspection-column .inner-column .review-sec .review-box {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .inventory-section .inspection-column .inner-column .review-sec .review-box .rating-box {
    margin-right: 0;
    margin-bottom: 30px;
  }

  .inventory-section .inspection-column .inner-column .review-sec .review-box .review-list.two {
    margin-right: 0;
    width: 100%;
    margin-bottom: 30px;
  }

  .inventory-section .inspection-column .inner-column .review-sec .review-box .review-list {
    width: 100%;
  }

  .inventory-section .inspection-column .inner-column .description-sec .des-list li img {
    margin-right: 10px;
  }

  .inventory-section .inspection-column .inner-column .form-box .form-list {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .inventory-section .inspection-column .inner-column .form-box .form-list li {
    margin-right: 0;
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    margin-bottom: 10px;
  }

  .inventory-section .inspection-column .inner-column .form-box .form-list li:last-child {
    margin-bottom: 0;
  }

  .inventory-section .inspection-column .inner-column .review-sec.v2 .content-box-three {
    display: block;
  }




  .inventory-section .inspection-column .inner-column .features-sec.v2 .list-column .inner-column {
    margin-bottom: 0;
  }

  .inventory-section .inspection-column .inner-column .description-sec .des-list {
    display: block;
  }

  .inventory-section .inspection-column .inner-column .description-sec .des-list li {
    margin-right: 0;
    margin-bottom: 10px;
  }

  .inventory-section .inspection-column .inner-column .description-sec .des-list li:last-child {
    margin-bottom: 0;
  }

  .contact-us-section.v2 .right-box .content-column .inner-column {
    padding: 50px 15px;
  }

  .contact-us-section .calculater-sec .content-column .inner-column .boxcar-title {
    padding-left: 0;
  }

  .order_list_raw ul {
    display: block;
  }

  .order_list_raw li {
    width: 100%;
    margin-bottom: 15px;
  }

  .order_list_raw li:last-child {
    margin-bottom: 0;
  }

  .dashboard-widget-two .content-column .inner-column .gallery-sec .right-box-three .gallery-box .inner-box {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .dashboard-widget-two .content-column .inner-column .gallery-sec .right-box-three .gallery-box .inner-box .image-box {
    width: 50%;
    margin-right: 0;
    margin-bottom: 10px;
  }

  .services-section-three {
    padding-top: 80px;
  }

  .service-block-three .inner-box img {
    width: 100%;
  }

  .vehicles-section-three .right-box .auther-info-box .inner-column .auther-info {
    display: block;
  }

  .vehicles-section-three .right-box .auther-info-box .inner-column .auther-info .btn-box {
    margin-left: 0;
    margin-top: 25px;
  }

  .tabs-column {
    margin-bottom: 50px;
  }

  .boxcar-banner-section-v1 .banner-content .form-tab-pane form {
    border-radius: 16px;
    padding: 30px 30px 30px;
  }

  .boxcar-banner-section-v1 .banner-content .form-submit button {
    border-radius: 16px;
  }

  .boxcar-banner-section-v1 .banner-content .form-tab-pane form .form_boxes {
    border: 1px solid #eaeaea;
    border-radius: 16px;
    margin-bottom: 20px;
  }

  .model-links {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    /* flex-wrap: wrap; */
    overflow: auto;
    margin-right: -30px;
    padding-right: 30px;

  }

  .boxcar-brand-section .row {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    overflow-x: scroll;
  }

  .boxcar-brand-section .col-sm-6 {
    width: auto;
  }
}

/* Extra small devices */
@media (max-width: 575px) {
  .header-style-v1 .btn {
    display: none;
  }

  .car-block-two .inner-box {
    display: block;
  }

  .car-block-two .inner-box .image-box {
    width: 100%;
  }

  .car-block-two .inner-box .image-box .image img {
    border-radius: 0;
  }

  .car-block-two .inner-box .content-box {
    padding: 20px;
  }

  .cars-section {
    padding: 90px 0;
  }

  .boxcar-testimonial-section .content-column .inner-column .text {
    margin-right: 0;
  }

  .blog-section {
    padding: 90px 0;
  }

  .header-style-v1.style-two {
    padding: 20px 0;
  }

  .car-block-four .inner-box .content-box ul li {
    margin-right: 0;
    width: 33%;
  }

  .boxcar-service-section .content-column .inner-column .title {
    font-size: 40px;
    line-height: 50px;
  }

  .boxcar-banner-section-two .banner-slider .banner-slide .right-box .content-box h1 {
    font-size: 50px;
    line-height: 60px;
  }

  header .user {
    display: none;
  }

  header .btn {
    display: none;
  }

  .car-block-six .inner-box {
    display: block;
  }

  .car-block-six .inner-box .image-box {
    width: 100%;
  }

  .car-block-six .inner-box .content-box {
    width: 100%;
    padding: 20px;
  }

  .car-block-six .inner-box .content-box ul,
  .car-block-six .inner-box .content-box .text {
    margin-bottom: 15px;
  }

  .car-block-six .inner-box .content-box ul li {
    margin-bottom: 10px;
  }

  .hheader-style-v4.five {
    padding: 25px 0;
  }

  .boxcar-banner-section-six .content-column .inner-column h1 {
    font-size: 50px;
    line-height: 60px;
  }

  .counter-block {
    width: 50%;
  }

  .boxcar-inspiration-section-two .right-box .service-list li {
    margin-right: 15px;
    margin-bottom: 15px;
  }

  .boxcar-testimonial-section-four .right-box {
    padding: 80px 15px;
  }

  .header-style-v7 .header-inner {
    padding: 20px 0;
  }

  .team-block-three {
    padding: 0 15px;
    width: calc(50% - 0px);
    margin-bottom: 30px;
  }

  .boxcar-team-section-three .right-box {
    margin: 0 -15px;
  }

  .boxcar-service-section .content-column .inner-column .title br {
    display: none;
  }

  .boxcar-brand-section-six .cars-block-six {
    margin-right: 10px;
    margin-bottom: 10px;
  }

  .cars-section-ten .right-box {
    padding-top: 70px;
  }

  .news-letter-section .form-column .inner-column .boxcar-title h2 br {
    display: none;
  }

  .team-block-four {
    width: 100%;
  }

  .boxcar-title h2 {
    font-size: 32px;
  }

  .footer-style-four .footer-top .right-box .top-left .btn-box {
    display: block;
  }

  .footer-style-four .footer-top .right-box .top-left .btn-box .btn-two {
    margin-right: 0;
    width: 100%;
    margin-bottom: 15px;
  }

  .footer-style-five .footer-top .right-box .subscribe-form .form-group input {
    width: 100%;
  }

  .footer-style-five .footer-top .right-box .subscribe-form .form-group {
    width: 100%;
  }

  .dashboard-widget .nav-tabs {
    display: block;
  }

  .dashboard-widget .nav-tabs li {
    margin-bottom: 15px;
  }

  .header-style-ten .header-inner .right-box .haeder-img {
    display: none;
  }

  .header-style-ten .header-inner .c-box .nav-out-bar {
    display: none;
  }

  .header-style-ten .header-inner .c-box .logo-inner {
    width: 100%;
  }

  .header-style-ten .header-inner .c-box .logo-inner .logo {
    margin-right: auto;
  }

  .header-style-ten .header-inner .c-box .btn-box .menu-btn .button {
    margin-right: 0;
    font-size: 30px;
  }

  .blog-section-five .right-box-two .social-section .inner-column .social-icons {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .blog-section-five .right-box-two .social-section .inner-column {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .blog-section-five .right-box-two .social-section .inner-column .social-icons {
    margin-bottom: 15px;
  }

  .blog-section-five .right-box-two .social-section {
    padding: 30px 0;
  }

  .blog-section-five .right-box-two .Reply-sec .right-box .rating-list.two {
    margin-right: 0;
  }

  .blog-section-five .right-box-two .Reply-sec .right-box {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .blog-section-five .right-box-two .list-sec-two .list-two li {
    line-height: unset;
  }

  .cart-table {
    padding-right: 0;
  }

  .cart-table table tr {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .cart-table table tr td {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    width: 100%;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    text-align: center;
  }

  .cart-table .shop-cart-product {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .cart-table .shop-cart-product .shop-product-cart-img {
    margin-right: 0;
  }

  .shop-product-cart-info {
    text-align: center;
  }

  .cart-table-bottom .coupan-form {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .cart-table-bottom .coupan-form input {
    margin-right: 0;
    width: 100%;
    margin-bottom: 20px;
  }

  .theme-btn-web {
    width: 100%;
    text-align: center;
  }

  .cart-table table .number {
    margin: 0 auto;
  }

  .cart-table table thead th {
    width: 100%;
    text-align: center;
  }

  .cart-table table thead th:last-child {
    display: none;
  }

  .contact-us-section .ofice-section {
    padding-top: 70px;
  }

  .dealer-ship-section-two .inner-column .brand-box .content-box .contact-list {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .dealer-ship-section-two .inner-column .brand-box .content-box .contact-list li {
    margin-bottom: 20px;
  }

  .dealer-ship-section-two .inner-column .service-box .list-sec .service-list .list-box .list.two {
    margin-right: 0;
  }

  .dealer-ship-section-two .inner-column .service-box .list-sec .service-list .list-box {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .cars-section-four .pagination-sec .pagination {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .inventory-pager {
    padding-bottom: 80px;
  }

  .inventory-section .inspection-column .inner-column .Reply-sec .right-box .rating-list .list-box {
    display: block;
  }

  .inventory-section .inspection-column .inner-column .Reply-sec .right-box .rating-list.two {
    margin-right: 0;
  }



  /* .inventory-section .boxcar-title-three .spectes-list li {
    display: block;
    margin-right: 0;
    margin-bottom: 10px;
  } */

  .inventory-section .boxcar-title-three .spectes-list li span {
    width: 100%;
  }

  .inventory-section .inspection-column .inner-column .image-box {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .inventory-section .inspection-column .inner-column .image-box>img {
    width: 20%;
  }

  .inventory-section .gallery-sec .image-column .inner-column .image-box .content-box .video-list li a {
    font-size: 12px;
  }

  .inventory-section .inspection-column .inner-column .contact-box-three .content-box .btn-box {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .inventory-section .inspection-column .inner-column .contact-box-three .content-box .btn-box .side-btn {
    margin-right: 0;
    margin-bottom: 20px;
  }

  .inventory-section .inspection-column .inner-column .contact-list .image-box>img {
    width: auto;
  }

  .inventory-section .inspection-column .inner-column .contact-box-three .content-box .inner-box .contact-list li a .image-box {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .cars-section-three {
    padding-top: 80px;
  }

  .contact-block .inner-box {
    padding: 50px 25px;
  }

  .login-section .inner-container {
    padding: 0 15px;
  }

  .login-section .btn-box-two .social-btns {
    display: block;
  }

  .login-section .btn-box-two .social-btns .fb-btn {
    width: 100%;
    margin-bottom: 10px;
    text-align: center;
  }

  .shop-cart.pt30 .container {
    padding-top: 50px;
  }

  .boxcar-pricing-section-seven {
    padding-top: 70px;
  }

  .boxcar-pricing-section-two .content-column .inner-column .boxcar-title h2 {
    line-height: 40px;
  }

  .services-inner-section .why-choose-us-section.v3 .right-box {
    padding-top: 80px;
  }

  .shop-single-thumbs-slider {
    display: none;
  }

  .shop-single-product-slider {
    width: 100%;
    margin-left: 0;
  }

  .cars-section-fifteen .content-sec .content-column .inner-column .Reply-sec .right-box {
    display: block;
  }

  .boxcar-banner-section-two .banner-slider .banner-slide .right-box .content-box ul {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .wrap-fixed-sidebar .widget-sidebar-filter {
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
  }

  .boxcar-brand-section {
    padding: 60px 0;
  }

  .blog-section-two {
    padding-bottom: 90px;
  }

  .cars-section-four {
    padding: 120px 0 40px;
  }

  .why-choose-us-section {
    padding: 70px 0 64px;
  }

  .boxcar-customers-section {
    padding: 60px 0;
  }

  .boxcar-customers-section .boxcar-title {
    margin-bottom: 15px;
  }

  .boxcar-team-section {
    padding: 60px 0;
  }

  .boxcar-inspiration-section {
    padding-bottom: 60px;
  }

  .boxcar-service-section .content-column .inner-column .title {
    font-size: 32px;
    line-height: 42px;
  }

  .boxcar-service-section {
    padding-bottom: 60px;
  }

  .brand-boxcar-banner-section .content-column .inner-column .title {
    font-size: 40px;
    line-height: 50px;
  }

  .brand-boxcar-banner-section {
    padding: 60px 0;
  }

  .boxcar-banner-section-v1 {
    background-position: top;
  }
}

/* Extra small devices */
@media (max-width: 416px) {
  .header-style-v1 .header-inner .right-box>a {
    display: none;
  }

  .cars-section-three .nav-tabs .nav-link {
    width: 100%;
    /* margin-bottom: 15px; */
    margin-right: 0;
  }

  .boxcar-pricing-section .content-column .inner-column .boxcar-title h2 {
    font-size: 30px;
    line-height: 40px;
    margin-bottom: 15px;
  }

  .choose-us-block .inner-box .content-box .text {
    padding-right: 0;
  }

  .blog-blockt-two .inner-box .hover-img {
    display: none;
  }

  .blog-blockt-two .inner-box .title br {
    display: none;
  }

  .cars-section-four .nav-tabs .nav-link {
    width: 100%;
  }

  .boxcar-service-section .content-column .inner-column .title {
    font-size: 32px;
    line-height: 40px;
  }

  .boxcar-service-section .content-column .inner-column .store span {
    font-size: 11px;
  }

  .brand-boxcar-banner-section .content-column .inner-column .title {
    font-size: 32px;
    line-height: 40px;
  }

  .brand-boxcar-banner-section .content-column .inner-column .btn {
    padding: 0 30px;
    height: 50px;
    line-height: 50px;
  }

  .boxcar-title h2 {
    font-size: 32px;
  }

  .blog-blockt-two .inner-box .title {
    line-height: 36px;
  }

  .boxcar-banner-section-four .banner-slider-v4 .banner-slide .right-box .content-box h1 {
    font-size: 50px;
    line-height: 60px;
  }

  .calculater-section .content-column .inner-column {
    padding: 40px;
  }

  .brand-block-four .inner-box {
    /* width: 100%; */
  }

  .brand-block-four {
    width: 100%;
    /* margin-right: 0; */
  }

  .why-choose-us-section-three .right-box {
    padding: 70px 30px 70px;
  }

  .boxcar-brand-section-three .right-box {
    padding: 70px 30px;
  }

  .boxcar-banner-section-six .content-column .inner-column .btn-box {
    display: block;
  }

  .boxcar-banner-section-six .content-column .inner-column .btn-box .read-more {
    margin-right: 0;
    display: block;
    margin-bottom: 15px;
    text-align: center;
  }

  .boxcar-pricing-section-four .content-column .inner-column .boxcar-title h2 {
    line-height: 42px;
  }

  .boxcar-inspiration-section-two .right-box-two .uper-box .content-box {
    padding-left: 0;
    margin-bottom: 10px;
  }

  .boxcar-inspiration-section-two .right-box-two .uper-box .content-box .icon {
    position: static;
  }

  .boxcar-banner-section-seven .banner-slider-v7 .banner-slide .right-box .content-box h1 {
    font-size: 32px;
    line-height: 40px;
  }

  .boxcar-service-section {
    padding: 90px 0;
  }




  .cars-block-five {
    width: 50%;
  }

  .cars-section-ten .nav-tabs .nav-link {
    margin-right: 0;
    width: 100%;
  }

  .cars-section-ten .right-box {
    padding-left: 15px;
    padding-right: 15px;
  }

  .news-letter-section .form-column .inner-column .subscribe-form .form-group button {
    position: static;
    width: 100%;
    margin-top: 20px;
  }

  .boxcar-banner-section-nine .banner-content .content-box h2 {
    font-size: 40px;
  }

  .boxcar-banner-section-nine .banner-content .content-box .btn-box {
    display: block;
  }

  .boxcar-banner-section-nine .banner-content .content-box .btn-box .read-more {
    margin-right: 0;
    width: 100%;
    margin-bottom: 15px;
  }

  .blog-section-three {
    padding-bottom: 80px;
  }

  .choose-us-block-five .inner-box .content-box .text {
    padding-right: 0;
  }

  .boxcar-pricing-section-five .content-column .inner-column .boxcar-title h2 {
    line-height: 40px;
  }

  .counter-block {
    width: 100%;
  }

  .boxcar-pricing-section-six .content-column .inner-column .boxcar-title h2 {
    font-size: 28px;
    line-height: 40px;
  }

  .boxcar-service-section-two .content-column .inner-column .title {
    font-size: 30px;
    line-height: 40px;
  }

  .boxcar-service-section-two .content-column .inner-column .store span {
    line-height: 16px;
    margin-bottom: 10px;
  }

  .boxcar-team-section-two {
    padding-bottom: 80px;
  }

  .about-inner-one .faqs-section .widget-accordion .block .acc-btn {
    font-size: 16px;
  }

  .dashboard-widget .content-column .inner-column .form-box form .form-column {
    width: 100%;
  }

  .dashboard-widget .content-column .inner-column .gallery-sec .right-box-three .gallery-box .inner-box .uplode-box {
    width: 100%;
  }



  .inventory-section .inspection-column .inner-column .form-box .form-list {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .inventory-section .inspection-column .inner-column .form-box .form-list li {
    margin-right: 0;
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }

  .inventory-section .inspection-column .inner-column .form-box .form-list li span {
    margin-right: auto;
  }

  .inventory-section .inspection-column .inner-column .form-box .form-list li {
    margin-bottom: 10px;
  }

  .inventory-section .side-bar-column .inner-column .contact-box-two .btn-box .side-btn {
    margin-bottom: 10px;
    margin-right: 0;
  }

  .inventory-section .gallery-sec .image-column .inner-column .image-box .content-box .video-list {
    display: none;
  }

  .inventory-section .inspection-column .inner-column .description-sec .des-list {
    display: block;
  }

  .inventory-section .inspection-column .inner-column .description-sec .des-list li {
    margin-right: 0;
    margin-bottom: 15px;
  }

  .inventory-section .inspection-column .inner-column .contact-box-three .icon-box {
    position: static;
  }

  .inventory-section .inspection-column .inner-column .contact-box-three .content-box .inner-box {
    padding-left: 0;
    margin-top: 30px;
  }

  .inventory-section .side-bar-column.v3 .contact-box-two .btn-box {
    display: block;
  }

  .inventory-section.v1 .description-sec.v2,
  .inventory-section.v1 .features-sec.v2,
  .inventory-section.v1 .faqs-section.v2,
  .inventory-section.v1 .location-box.v2,
  .inventory-section.v1 .form-box.v2,
  .inventory-section.v1 .main-review-box,
  .inventory-section.v1 .reply-c-box {
    padding-left: 15px;
    padding-right: 15px;
  }

  .text-box {
    display: block;
  }

  .text-box .text {
    margin-bottom: 15px;
  }

  .dashboard-widget-two .content-column .inner-column .gallery-sec .right-box-three .gallery-box .inner-box .image-box {
    width: 100%;
  }

  .dashboard-widget-two .content-column .inner-column .gallery-sec .right-box-three .gallery-box .inner-box .image-box img {
    width: 100%;
  }

  .dashboard-widget-two .content-column .inner-column .gallery-sec .right-box-three .gallery-box .inner-box .uplode-box {
    width: 100%;
  }

  .services-inner-section .calculater-sec .content-column .inner-column {
    padding: 30px 15px;
  }

  .cars-section-fifteen .inner-column .btn-box {
    display: block;
  }

  .cars-section-fifteen .inner-column .btn-box .number {
    height: 50px;
    margin-bottom: 20px;
  }

  .cars-section-fifteen .content-sec .content-column .inner-column .Reply-sec .right-box .rating-list .list-box {
    display: block;
  }

  .cars-section-fifteen .cars-section-fourteen.v1 {
    padding-top: 60px;
  }

  .vehicles-section-three .right-box .auther-info-box .inner-column .auther-info .content-box .contact-info {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .vehicles-section-three .right-box .auther-info-box .inner-column .auther-info .content-box .contact-info li {
    margin-right: 0;
    margin-bottom: 20px;
  }
}

.header-style-v1 .btn .header-btn-two:hover {
  border-color: transparent;
}



/* custom */
.layout-search .search-box {
  position: relative;
}

.layout-search .search-box .icon {
  position: absolute;
  left: 19px;
  top: 50%;
  transform: translateY(-50%);

}

.layout-search .search-box input {
  background: transparent;
  width: 400px;
  height: 47px;
  line-height: 47px;
  font-size: 15px;
  font-weight: 500;
  color: var(--theme-color-light);
  padding: 0px 20px 0px 46px;
  border-radius: 16px 16px 0px 0px;
  /* -webkit-transition: all 300ms ease;
  transition: all 300ms ease; */

}

@media (max-width: 1600px) {

  .boxcar-header.five .right-box .phone,
  .hheader-style-v4.style-1 .right-box .phone {
    display: none;
  }
}


.layout-search .search-box input::placeholder {
  color: var(--theme-color-light);

}

.layout-search.active .search-box input {
  color: var(--theme-color-dark);
  background-color: var(--theme-color-light);
}

.layout-search.active .search-box svg path {
  fill: var(--theme-color-dark);
}

.layout-search {
  margin-top: -12px;
  position: relative;
  z-index: 100;

}

.layout-search.style1 .box-content-search {
  border: 1px solid rgba(225, 225, 225, 1);

}


.layout-search .box-content-search {
  position: absolute;
  left: 0px;
  right: 0px;
  background-color: var(--theme-color-light);
  height: 382px;
  border-top: 1px solid rgba(225, 225, 225, 1);
  opacity: 0;
  visibility: hidden;
  border-radius: 0px 0px 16px 16px;
  /* -webkit-transition: all 300ms ease;
  transition: all 300ms ease; */
}

.layout-search .box-content-search.active {
  opacity: 1;
  visibility: visible;
}

.layout-search .box-content-search .car-search-item {
  display: flex;
  gap: 15px;
  padding: 15px 20px 14px;
  position: relative;

}

.layout-search .box-content-search .car-search-item::after {
  position: absolute;
  content: "";
  background: rgba(217, 217, 217, 1);
  height: 1px;
  left: 20px;
  right: 20px;
  bottom: 0;
}

.layout-search .box-car-search {
  height: 333px;
  overflow: auto;
  margin-right: 5px;
}

.layout-search .box-car-search::-webkit-scrollbar {
  width: 3px;
  margin-right: 5px;
}

.layout-search .box-car-search::-webkit-scrollbar-thumb {
  background: rgba(225, 225, 225, 1);

}

.box-content-search .car-search-item .name {
  font-size: 14px;
  line-height: 24px;
  margin-bottom: 5px;
  color: var(--theme-color-dark);
}

.box-content-search .car-search-item .price {
  font-size: 16px !important;
  line-height: 28px;
  font-weight: 500;
  color: var(--theme-color-dark) !important;
}

.box-content-search .car-search-item .box-img {
  border-radius: 16px;
  width: 70px;
  height: 70px;
  overflow: hidden;
  flex-shrink: 0;
}

.box-content-search .btn-view-search {
  font-size: 15px;
  line-height: 26px;
  color: rgba(64, 95, 242, 1);
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 12px 0px;
}

.boxcar-header.v10 .layout-search .search-box input,
.header-style-v9 .layout-search .search-box input,
.boxcar-header.v6 .layout-search .search-box input,
.header-style-v3 .layout-search .search-box input,
.header-style-v7 .layout-search .search-box input {
  width: auto;

}

.boxcar-header.v10 .layout-search.active .search-box input,
.header-style-v9 .layout-search.active .search-box input,
.boxcar-header.v6 .layout-search.active .search-box input,
.header-style-v3 .layout-search.active .search-box input,
.header-style-v7 .layout-search.active .search-box input {
  width: 380px;
}

.hheader-style-v4.v10 .header-inner .c-box .nav-out-bar {
  margin-right: 0px;
}

.boxcar-header.v10 .layout-search,
.header-style-v9 .layout-search,
.header-style-v3 .layout-search,
.boxcar-header.v6 .layout-search,
.header-style-v7 .layout-search {
  margin-top: 0;
  margin-right: 10px;
}

.boxcar-header.v10 .layout-search .search-box input,
.header-style-v7 .layout-search .search-box input,
.boxcar-header.v6 .layout-search .search-box input,
.header-style-v3 .layout-search .search-box input {
  padding-right: 10px;
}

.st-mt {
  left: 0px !important;
}

.boxcar-header.v10 .layout-search .search-box svg path,
.header-style-v7 .layout-search .search-box svg path,
.boxcar-header.five .layout-search .search-box svg path {
  fill: var(--theme-color-dark);
}

.boxcar-header.v10 .layout-search .search-box input::placeholder,
.header-style-v7 .layout-search .search-box input::placeholder,
.boxcar-header.five .layout-search .search-box input::placeholder {
  color: var(--theme-color-dark);

}

.header-style-ten .layout-search {
  margin-top: 0;
  margin-left: -25px;
}

.wrapper-invoice {
  background-color: rgba(233, 242, 255, 1);
  display: flex;
  justify-content: center;
  align-items: center;

}

.btn-default {
  padding: 20px 26px;
  border-radius: 12px;
  font-size: 15px;
  line-height: 14px;
  background-color: rgba(64, 95, 242, 1);
  font-weight: 500;
  color: var(--theme-color-light);
  display: inline-flex;
  align-items: center;
  gap: 10px;
}

.btn-default:hover {
  color: var(--theme-color-light);

}

.invoice-section {
  padding: 120px 0px;
  width: 100%;
}

.invoice-section .top {
  text-align: right;
  margin-bottom: 36px;

}

.box-invoice {
  background: var(--theme-color-light);
  box-shadow: 0px 10px 40px 0px rgba(0, 0, 0, 0.05);
  border-radius: 16px;

}

.box-invoice .header {
  padding: 133px 100px 122px;
  border-bottom: 1px solid rgba(225, 225, 225, 1);
}

.box-invoice .wrap-top {
  display: flex;
  margin-bottom: 56px;
}

.box-invoice .box-left {
  width: 60%;

}

.box-invoice .wrap-top .box-right {
  width: 40%;

}

.box-invoice .wrap-top .title {
  font-size: 28px;
  line-height: 36.46px;
  color: var(--theme-color-dark);
  font-weight: 700;
  white-space: nowrap;
}

.box-invoice .wrap-top .price {
  font-size: 17px;
  line-height: 28px;
  font-weight: 500;
  color: var(--theme-color-dark);
}

.box-invoice .wrap-date {
  display: flex;
  margin-bottom: 60px;
}

.box-invoice .wrap-date label {
  font-size: 15px;
  line-height: 28px;
  display: block;
}

.box-invoice .wrap-date .date {
  font-size: 15px;
  line-height: 28px;
  font-weight: 500;
}

.box-invoice .wrap-info {
  margin-bottom: 40px;
  display: flex;
}

.box-invoice .wrap-info .title {
  font-size: 20px;
  line-height: 26.04px;
  color: var(--theme-color-dark);
  font-weight: 500;
  margin-bottom: 12px;
}

.box-invoice .wrap-info .sub {
  color: var(--theme-color-dark);
  font-size: 15px;
  line-height: 28px;
  font-weight: 500;

}

.box-invoice .wrap-info .desc {
  color: var(--theme-color-dark);
  font-size: 15px;
  line-height: 28px;
  margin-bottom: 0;
  font-weight: 400;
}

.invoice-table {
  width: 100%;
}

.invoice-table thead {
  background-color: rgba(233, 242, 255, 1);
}

.invoice-table .title th {
  padding: 20px 65px 20px 40px;
  color: rgba(64, 95, 242, 1);
  font-weight: 500;
  font-size: 17px;
  line-height: 28px;
}

.invoice-table .title th:first-child {
  border-radius: 8px 0 0 8px;
  width: 40%;
}

.invoice-table .title th:last-child {
  border-radius: 0px 8px 8px 0px;

}

.invoice-table .content td {
  padding: 20px 65px 20px 40px;
  font-size: 15px;
  line-height: 28px;
  border-bottom: 1px solid rgba(225, 225, 225, 1);

}

.invoice-table .content .total {
  font-size: 17px;
  line-height: 28px;
  font-weight: 500;

}

.invoice-table .content:last-child td {
  border-bottom: none;

}

.box-invoice .footer {
  padding: 43px 30px;
}

.box-invoice .footer .box-contact {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 30px;
  flex-wrap: wrap;
}

.box-invoice .footer .box-contact li {
  font-size: 15px;
  line-height: 28px;
}

.box-invoice .footer .box-contact li:first-child {
  color: rgba(64, 95, 242, 1);
}

.element-section {
  padding: 60px 0px 100px;
}

.element-section .breadcrumb {
  margin-bottom: 25px;
}

.breadcrumb .item {
  font-size: 15px;
  line-height: 26px;
  color: var(--theme-color-dark);
}

.breadcrumb .item.active {
  color: rgba(64, 95, 242, 1);
}

.element-section h2 {
  font-size: 40px;
  line-height: 45px;
  font-weight: 700;
  margin-bottom: 60px;
}

.title-element {
  font-size: 20px;
  line-height: 30px;
  font-weight: 500;
  margin-bottom: 36px;
}

/* accordion */
.box-accordion {
  margin-bottom: 60px;
}



.box-accordion .accordion-button {
  justify-content: space-between;
  padding: 40px 30px 13px 40px;
  border-radius: 16px 16px 0px 0px !important;
  background-color: rgba(249, 251, 252, 1);
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  color: rgba(5, 11, 32, 1);
}

.box-accordion .accordion-button.collapsed {
  background-color: transparent;
}

.box-accordion .accordion-item {
  border: 0;
}



.box-accordion .accordion-collapse .accordion-body {
  padding: 0px 50px 40px 40px;
  font-size: 15px;
  line-height: 28px;
  font-weight: 400;
  color: rgba(5, 11, 32, 1);
  background-color: rgba(249, 251, 252, 1);
  border-radius: 0px 0px 16px 16px !important;
}

.box-accordion .accordion-button:focus {
  border-color: transparent;
  box-shadow: none;
}

.box-accordion .accordion-button::after {
  content: none;
}

.box-accordion .accordion-button.collapsed .icon .minus {
  display: none;
}

.box-accordion .accordion-button .icon .minus {
  display: block;
}

.box-accordion .accordion-button:not(.collapsed) {
  box-shadow: none;
}

.box-accordion .accordion-button:not(.collapsed) .icon .plus {
  display: none;
}

/* tab */
.box-tabs-element .tab-content p {
  font-size: 15px;
  line-height: 28px;
}

.box-tabs-element .nav-tab-el1 {
  margin-bottom: 30px;
}

.box-tabs-element .nav-tab-el2 {
  margin-bottom: 19px;
  display: flex;
  gap: 30px;
  border-bottom: 1px solid rgba(225, 225, 225, 1);
}

.box-tabs-element .nav-tab-el2 .nav-link {
  font-size: 16px;
  line-height: 28px;
  padding: 0;
  padding-bottom: 15px;
  border-bottom: 2px solid transparent;
  background-color: transparent;
  color: rgba(5, 11, 32, 1);
  border-radius: 0;

}

.box-tabs-element .nav-tab-el2 .nav-link.active {
  border-color: rgba(64, 95, 242, 1);
}

.box-tabs-element .nav-tab-el1 .nav-link {
  padding: 6px 17px 6px 17px;
  border-radius: 32px;
  font-size: 14px;
  line-height: 28px;
  font-weight: 500;
  color: rgba(5, 11, 32, 1);
  border: 1px solid transparent;
}

.box-tabs-element .nav-tab-el1 .nav-link.active {
  background-color: #fff;
  border-color: rgba(225, 225, 225, 1);
  box-shadow: 0px 6px 15px 0px rgba(64, 79, 104, 0.05);
  color: rgba(64, 95, 242, 1);

}

.box-tabs-element-2 {
  margin-top: 70px;
}

/* table */
.box-table-el table {
  width: 100%;
}

.box-table-el table th,
.box-table-el table td {
  padding: 23px 26px 13px;
  font-size: 15px;
  line-height: 28px;
  font-weight: 500;
  color: rgba(5, 11, 32, 1);
}

.box-table-el table thead {
  background-color: rgba(233, 242, 255, 1);
}

.box-table-el table thead th {
  color: rgba(64, 95, 242, 1);
}

.box-table-el table thead th:first-child {
  border-radius: 8px 0px 0px 8px;
}

.box-table-el table thead th:last-child {
  border-radius: 0px 8px 8px 0px;
}

/* alert */
.messages-box-el .alert {
  font-size: 15px;
  line-height: 28px;
  font-weight: 500;
  padding: 24px 13px 21px 28px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 16px;
  border-color: transparent;
}

.messages-box-el .alert-info {
  background-color: rgba(205, 233, 246, 0.5);
}

.messages-box-el .alert-warning {
  background-color: rgba(247, 243, 215, 0.5);

}

.messages-box-el .alert-error {
  background-color: rgba(236, 200, 197, 0.5);

}

.messages-box-el .alert-success {
  background-color: rgba(222, 242, 215, 0.5);
}

.messages-box-el .alert-info p {
  color: rgba(71, 128, 170, 1);

}

.messages-box-el .alert-warning p {
  color: rgba(146, 114, 56, 1);

}

.messages-box-el .alert-error p {
  color: rgba(171, 51, 49, 1);
}

.messages-box-el .alert-success p {
  color: rgba(91, 112, 82, 1);

}

.messages-box-el .alert p {
  margin-bottom: 0px;
}

.messages-box-el .alert:not(:last-child) {
  margin-bottom: 20px;
}

/* button */
.tf-btn {
  padding: 13px 26px;
  display: inline-flex;
  align-items: center;
  gap: 10px;
  border-radius: 12px;
  font-size: 15px;
  line-height: 26px;
  font-weight: 500;
  border: 1px solid transparent;
}

.tf-btn.primary {
  border-color: rgba(64, 95, 242, 1);
  background-color: rgba(64, 95, 242, 1);
  color: #fff;

}

.tf-btn.primary:hover {
  background-color: rgba(78, 108, 251, 1);
  border-color: rgba(78, 108, 251, 1);
}

.tf-btn.primary-hover {
  border-color: rgba(78, 108, 251, 1);
  background-color: rgba(78, 108, 251, 1);
  color: #fff;
}

.tf-btn.secondary {
  border-color: rgba(64, 95, 242, 1);
  background-color: rgba(78, 108, 251, 0.07);
  color: rgba(64, 95, 242, 1);
}

.tf-btn.secondary:hover {
  background-color: rgba(64, 95, 242, 1);
  border-color: rgba(64, 95, 242, 1);
  color: #fff;
}

.tf-btn.secondary-hover {
  background-color: rgba(64, 95, 242, 1);
  border-color: rgba(64, 95, 242, 1);
  color: #fff;
}

.tf-btn.third {
  border-color: rgba(64, 95, 242, 1);
  background-color: #fff;
  color: rgba(64, 95, 242, 1);
}

.tf-btn.third:hover,
.tf-btn.third-hover {
  background-color: rgba(64, 95, 242, 1);
  border-color: rgba(64, 95, 242, 1);
  color: #fff;
}

.tf-btn.fourth {
  border-color: rgba(5, 11, 32, 1);
  background-color: #fff;
  color: rgba(5, 11, 32, 1);
}

.tf-btn.fourth:hover,
.tf-btn.fourth-hover {
  background-color: rgba(5, 11, 32, 1);
  border-color: rgba(5, 11, 32, 1);
  color: #fff;
}

.box-button-el {
  display: flex;
  row-gap: 26px;
  column-gap: 40px;
  flex-wrap: wrap;
  margin-bottom: 70px;
}

.box-button-el .tf-btn.secondary {
  margin-left: 55px;
}

.box-button-el .tf-btn.fourth {
  margin-left: 90px;

}

/* form */

.input-box label {
  color: rgba(129, 129, 129, 1);
  font-size: 13px;
  line-height: 16.93px;
  display: block;
  font-weight: 400;
}

.input-box {
  padding: 10px 15px;
  height: 60px;
  border: 1px solid rgba(225, 225, 225, 1);
  border-radius: 12px;
  margin-bottom: 30px;

}

.input-box:hover,
.input-box:focus,
.input-box.active {
  border: 2px solid rgba(5, 11, 32, 1);


}

.input-box input,
.input-box input::placeholder {
  font-size: 15px;
  line-height: 19.53px;
  color: rgba(5, 11, 32, 1);

}

.select-box-el {
  margin-bottom: 60px;
}

.select-box-el.style1 {
  margin-bottom: 30px;
}

.select-box-el .select-header {
  padding: 10px 15px;
  height: 60px;
  border: 1px solid rgba(225, 225, 225, 1);
  border-radius: 12px;
  cursor: pointer;
  position: relative;
}

.select-box-el .select-header .label {
  color: rgba(129, 129, 129, 1);
  font-size: 13px;
  line-height: 16.93px;
  font-weight: 400;
}

.select-box-el .select-header .icon {
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
}

.select-box-el .select-header .fied-select {
  font-size: 15px;
  line-height: 19.53px;
  color: rgba(5, 11, 32, 1);
}

.select-box-el .select-header:hover,
.select-box-el .select-header:not(.collapsed) {
  border: 2px solid rgba(5, 11, 32, 1);
}

.select-box-el .select-body {
  padding: 24px;
  border: 2px solid rgba(31, 75, 63, 1);
  border-radius: 12px;
  margin-top: 20px;
}

.select-box-el .select-body .search-box {
  position: relative;
  height: 50px;
  margin-bottom: 4px;
}

.select-box-el .select-body .search-box input {
  padding: 0px;
  padding-left: 20px;
  padding-right: 30px;
  border: 1px solid rgba(225, 225, 225, 1);
  width: 100%;
  line-height: 50px;
  font-size: 15px;
  color: rgba(5, 11, 32, 1);
  border-radius: 16px;

}

.select-box-el .select-body .search-box input::placeholder {
  color: rgba(5, 11, 32, 1);

}

.select-box-el .select-body .search-box .icon {
  position: absolute;
  right: 18px;
  top: 50%;
  transform: translateY(-50%);

}

.select-box-el .box-search-select li {
  padding: 0px 15px;
  border-radius: 12px;
  font-size: 15px;
  line-height: 45px;
  color: rgba(34, 34, 34, 1);
  cursor: pointer;

}

.select-box-el .box-search-select li.active {
  background-color: rgba(233, 242, 255, 1);
  color: rgba(64, 95, 242, 1);
}

.tf-check {
  border: 1px solid rgba(225, 225, 225, 1);
  border-radius: 4px;
  background: none;
  cursor: pointer;
  outline: 0;
  height: 20px;
  width: 20px;
  -webkit-appearance: none;
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.tf-check.circle {
  border-radius: 50%;
}

.tf-check.circle::after {
  position: absolute;
  top: 3px;
  left: 3px;
  border-radius: 50%;
  width: 12px;
  height: 12px;
  background-color: #fff;
  margin: 0;
}

.tf-check::after {
  position: absolute;
  content: "";
  border-bottom: 2px solid transparent;
  border-right: 2px solid transparent;
  width: 6px;
  height: 10px;
  margin-top: -3px;
  transform: rotate(45deg);
}

.tf-check:checked {
  background-color: rgba(5, 11, 32, 1);
  border-color: rgba(5, 11, 32, 1);
}

.tf-check:checked::after {
  border-color: #fff;
}

.box-check-el li {
  display: flex;
  align-items: center;
  gap: 9px;
  cursor: pointer;
}

.box-check-el.style2 li {
  gap: 11px;
}

.box-check-el li label {
  font-size: 15px;
  line-height: 40px;
}

.wrap-check-btn-el {
  display: flex;
  justify-content: space-between;
}

.wrap-check-btn-el p {
  font-size: 15px;
  line-height: 28px;
  font-weight: 500;
  margin-bottom: 10px;
  color: rgba(34, 34, 34, 1);
}

.tf-switch-check {
  position: relative;
  width: 55px;
  height: 30px;
  -webkit-appearance: none;
  background: rgba(225, 225, 225, 1);
  border-radius: 30px;
  transition: 0.2s;
  margin-right: 0;
}

.tf-switch-check::before {
  content: "";
  border-radius: 100px;
  width: 24px;
  height: 24px;
  position: absolute;
  top: 3px;
  left: 3px;
  background: #fff;
  transition: 0.5s;
}

.tf-switch-check:checked {
  background-color: rgba(5, 11, 32, 1);
  transition: 0.5s;
}

.tf-switch-check:checked::before {
  left: 28px;

}

.box-switch .box-check-el li {
  gap: 16px;
  margin-bottom: 10px;
}

.wrap-check-btn-el {
  margin-bottom: 65px;
  padding-right: 60px;
}

.box-textarea {
  height: 243px;
  padding: 20px;
  border-radius: 12px;
  border: 1px solid rgba(225, 225, 225, 1);
  margin-bottom: 65px;
}

.box-textarea label {
  font-size: 13px;
  line-height: 16.93px;
  font-weight: 400;
  color: rgba(129, 129, 129, 1);
  display: block;
  margin-bottom: 3px;
}

.box-textarea textarea {
  width: 100%;
  height: 80%;
  font-size: 15px;
  line-height: 26px;
  font-weight: 400;
  color: rgba(5, 11, 32, 1);
}

.box-tooltip {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.box-tooltip .btn-tooltip {
  background-color: rgba(233, 242, 255, 1);
  border-radius: 8px;
  width: 100px;
  height: 50px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  line-height: 28px;
  color: rgba(64, 95, 242, 1);

}

.tooltip-inner {
  font-size: 14px;
  line-height: 30px;
  padding: 0px 12px;
}

.wrap-booltip-el {
  margin-bottom: 50px;
}

.wrap-range-el .noUi-target {
  height: 4px;
}

.wrap-range-el .noUi-background {
  background-color: rgba(225, 225, 225, 1);
}

.wrap-range-el .noUi-connect {
  background: rgba(64, 95, 242, 1);
}

.wrap-range-el .noUi-horizontal .noUi-handle {
  width: 14px;
  height: 14px;
  border-color: rgba(64, 95, 242, 1);
}

.wrap-range-el .noUi-horizontal .noUi-handle.noUi-handle-upper {
  left: 0;
}

.wrap-range-el .box-val-range-el {
  text-align: center;
  margin-top: 14px;
}

.wrap-range-el .box-val-range-el span {
  font-size: 14px;
  line-height: 28px;
  color: rgba(34, 34, 34, 1);

}

.box-process-el .progress {
  overflow: unset;
  height: 10px;
  border-radius: 10px;
  background-color: rgba(233, 242, 255, 1);
}

.box-process-el .progress:not(:last-child) {
  margin-bottom: 46px;
}

.box-process-el .progress-bar {
  position: relative;
  overflow: unset;
  background-color: rgba(64, 95, 242, 1);
  border-radius: 10px;
}

.box-process-el .progress-bar span {
  position: absolute;
  right: 0;
  top: -22px;
  font-size: 15px;
  line-height: 20.13px;
  color: rgba(5, 11, 32, 1);
}

.wrap-process {
  margin-top: 100px;
}

.wrap-process .title-element {
  margin-bottom: 56px;
}

.box-quote {
  padding: 40px 240px 30px 55px;
  background-color: rgba(233, 242, 255, 1);
  border-radius: 16px;
  border-left: 16px solid rgba(64, 95, 242, 1);
}

.box-quote .quote {
  font-size: 15px;
  line-height: 28px;
  font-style: italic;
  color: rgba(5, 11, 32, 1);
  margin-bottom: 15px;
}

.box-quote .author {
  font-size: 17px;
  line-height: 28px;
  font-weight: 500;
  color: rgba(5, 11, 32, 1);
  font-style: normal;

}

.box-listview {
  display: flex;
  gap: 30px;
  justify-content: space-between;
  flex-wrap: wrap;
}

.box-listview .listview-item {
  display: flex;
  gap: 10px;
  align-items: center;
  font-size: 15px;
  line-height: 46px;
  color: rgba(5, 11, 32, 1);
  font-weight: 400;
}

.box-listview .listview-item .icon-check {
  width: 18px;
  height: 18px;
  background-color: rgba(233, 242, 255, 1);
  border-radius: 50%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.wrap-typo {
  margin-bottom: 60px;
  margin-top: 60px;
}

.wrap-typo .title-element {
  margin-bottom: 25px;
}

.wrap-typo .box-p {
  margin-bottom: 60px;
}

.wrap-typo .box-p p {
  font-size: 15px;
  line-height: 28px;
  font-weight: 400;
  margin-bottom: 0;
}

.wrap-typo .box-p p:first-child {
  margin-bottom: 40px;
}

.wrap-typo .title-typo,
.wrap-listview .title-listview {
  font-size: 26px;
  line-height: 30px;
  font-weight: 500px;
  margin-bottom: 30px;
  color: rgba(5, 11, 32, 1);

}

.element-section .form-box {
  padding-right: 108px;
}

.wrap-range-el {
  padding-right: 130px;
}

.wrap-listview {
  padding-right: 140px;
}

.wrap-range-el .widget-price {
  margin-bottom: 0;
}

@media (max-width: 1440px) {
  .layout-search .search-box input {
    width: auto;
  }

  .layout-search {
    display: none;
  }

  .box-button-el .tf-btn.fourth,
  .box-button-el .tf-btn.secondary {
    margin-left: 0px;
  }


}


@media (max-width: 991px) {
  .box-invoice .header {
    padding: 80px 60px;
  }

  .box-invoice .wrap-top,
  .box-invoice .wrap-date,
  .box-invoice .wrap-info {
    gap: 20px;
  }

  .box-invoice .box-left,
  .box-invoice .box-right {
    width: 50%;
  }

  .wrap-table {
    overflow: auto;
  }

  .wrap-range-el,
  .element-section .form-box,
  .wrap-listview {
    padding-right: 0;
  }

  .box-quote {
    padding-right: 90px;
  }

  .box-table-el,
  .messages-box-el,
  .wrap-range-el .widget-price {
    margin-bottom: 60px;
  }

  .box-tabs-element-2 {
    margin-top: 40px;
    margin-bottom: 60px;
  }

  .box-accordion .accordion-collapse .accordion-body,
  .box-accordion .accordion-button {
    padding-left: 20px;
    padding-right: 20px;
  }

  .box-tabs-element .nav-tab-el1 {
    gap: 15px;
  }

}

@media (max-width: 600px) {

  .box-invoice .wrap-top,
  .box-invoice .wrap-date,
  .box-invoice .wrap-info {
    flex-wrap: wrap;
  }

  .box-invoice .box-left,
  .box-invoice .box-right {
    width: 100%;
  }

  .box-quote {
    padding-left: 40px;
    padding-right: 30px;
  }

  .wrap-check-btn-el {
    padding-right: 0;
  }
}




.header-inner .c-box .nav-out-bar .nav .navigation .current-dropdown .dropdown .nav-sub {
  position: relative;

}

.header-inner .c-box .nav-out-bar .nav .navigation .current-dropdown .dropdown .nav-sub .dropdown.subnav-menu {
  margin: 0;
  margin-left: 28px;
  top: 0;
  left: 100%;
}

.header-style-v9 .c-box .nav-out-bar .nav .navigation .current-dropdown .dropdown .nav-sub .dropdown.subnav-menu {
  margin-left: 15px;

}

.header-inner .c-box .nav-out-bar .nav .navigation .current-dropdown .dropdown .nav-sub:hover .subnav-menu {
  visibility: visible;
  opacity: 1;

}

.header-inner .c-box .nav-out-bar .nav .navigation .current-dropdown .dropdown .subnav-menu::after {
  position: absolute;
  content: "";
  width: 32px;
  height: 100%;
  display: block;
  left: -20px;
  top: -10px;


}

.header-inner .c-box .nav-out-bar .nav .navigation .current-dropdown .dropdown .subnav-menu::before {
  content: none;
}

.form_boxes.line-r {
  position: relative;
}

.form_boxes.line-r:before {
  content: "";
  position: absolute;
  top: 50%;
  right: 0;
  width: 1px;
  height: 36px;
  background: #e1e1e1;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.boxcar-banner-section-nine .form_boxes.line-r:before {
  opacity: 0.2;
}

@media (max-width: 1199px) {
  .header-style-ten .header-inner {
    padding: 0px;
  }

  .header-style-ten .header-inner .c-box .btn-box .menu-btn .button {
    display: none;
  }
}

@media (max-width: 991px) {
  .form_boxes.line-r:before {
    content: none;
  }


}

.boxcar-title.style-1 {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  justify-content: space-between;
  gap: 20px;

}



.boxcar-title.style-1 h2 {
  margin-bottom: 0;
}

.boxcar-title.style-1 .text {
  margin-top: 0;
}

.cus-slider {
  padding-bottom: 30px;
}

.cus-layout-1 {
  margin-left: 60px;
  margin-right: 60px;
}

.cus-footer {
  margin: 0px 110px 99px;
  border-radius: 18px;
  overflow: hidden;
}

.cus-radius-16 {
  border-radius: 16px;
  overflow: hidden;
}

@media (max-width: 1440px) {
  .cus-footer {
    margin: 0px 15px 15px;
  }
}



@media (max-width: 991px) {
  .cus-layout-1 {
    margin-left: 30px;
    margin-right: 30px;
  }
}

.car-block-three.style-2 .inner-box {
  border-radius: 15px;
  border: 1px solid rgba(225, 225, 225, 1);
}

.car-block-three.style-2 .inner-box .image-box {
  padding: 10px 10px 0px;
}

.car-block-three.style-2 .inner-box .image-box .image {
  border-radius: 15px;
}

.car-block-three.style-2 .inner-box .image-box img {
  border-radius: 0;
}

.car-block-three.style-2 .inner-box .content-box {
  border: 0;

}

.boxcar-header.v6 .mobile-navigation a {
  color: var(--theme-color-dark);
}

.boxcar-header.v10 .header-inner .right-box .box-account {
  color: var(--theme-color-dark);
}

.cus-layout-home10 {
  max-width: 1580px;
  margin-left: auto;
  margin-right: auto;
}

.hheader-style-v4.v10 .header-inner .inner-container {
  max-width: 1430px;
}

.cus-container10 {
  position: static;
  max-width: 1192px;
  padding: 0px 15px;
  margin: 0 auto;
  width: 100%;
}

.cus-container2 {
  position: static;
  max-width: 1200px;
  padding: 0px 15px;
  margin: 0 auto;
  width: 100%;
}


.layout-radius {
  border-radius: 80px;
  margin-top: -80px;
  margin-bottom: -80px;
  background-color: #fff;
}

.inventory-pager.style-1 {
  padding-bottom: 150px;
}

@media (max-width: 991px) {
  .layout-radius {
    border-radius: 40px;
  }

  .cars-section-four.v1 {
    padding-bottom: 90px;
  }
}

.sidebar-backdrop {
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 99999;
  visibility: hidden;
  transition: all 0.3s ease;
  background: rgba(0, 0, 0, 0.5);
  opacity: 0;
}


.wrap-fixed-sidebar.active .sidebar-backdrop {
  opacity: 1;
  visibility: visible;
}

.widget-price {
  margin: 33px 0px 40px;

}

li.nav-sub.mm-listitem {
  position: relative;
}

li.nav-sub.mm-listitem .mm-listitem__btn {
  position: absolute;
  right: 0;
  width: 100%;
}

.chat-widget .card-footer {
  padding: 30px 30px 40px;
}

.chat-widget .card-footer .form-group button .text-mb {
  display: none;
}

.chat-widget .card-footer .type_msg {
  padding-left: 0;
  padding-right: 200px;
}

.banner-footer-section {
  background-color: rgba(64, 95, 242, 1);
  padding: 50px 0px;
}

.banner-footer-section .wrapper-banner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 10px;
}

.banner-footer-section .wrapper-banner .box-right {
  display: flex;
  align-items: center;
  gap: 10px;
}

.banner-footer-section .wrapper-banner .title,
.banner-footer-section .wrapper-banner .box-right span {
  font-size: 30px;
  line-height: 45px;
  font-weight: 500;
  color: var(--theme-color-light);
}


@media (max-width: 991px) {
  .chat-widget .card-footer {
    padding: 20px 15px;
  }

  .chat-widget .card-footer .form-group button {
    padding: 10px 20px;
  }

  .chat-widget .card-footer .form-group button .text-mb {
    display: block;
  }

  .chat-widget .card-footer .form-group button .text-dk {
    display: none;
  }

  .chat-widget .card-footer .type_msg {
    padding-right: 110px;
  }
}

.chat-widget .card-footer .form-group button {
  right: 0;
}


.wrap-body-compare {
  overflow: auto;
}

/* .wrap-body-compare tr {
  flex-wrap: nowrap !important;
}

.wrap-body-compare tr th,
.wrap-body-compare td {
  padding-right: 40px !important;
} */


/* price slider */
.noUi-target,
.noUi-target * {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -ms-touch-action: none;
  touch-action: none;
  -ms-user-select: none;
  -moz-user-select: none;
  user-select: none;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.noUi-target {
  position: relative;
  direction: ltr;
}

.noUi-base {
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 1;
}

.noUi-origin {
  position: absolute;
  right: 0;
  top: 0;
  left: 0;
  bottom: 0;
}

.noUi-handle {
  position: relative;
  z-index: 1;
}

.noUi-stacking .noUi-handle {
  z-index: 10;
}

.noUi-state-tap .noUi-origin {
  -webkit-transition: left 0.3s, top .3s;
  transition: left 0.3s, top .3s;
}

.noUi-state-drag * {
  cursor: inherit !important;
}

.noUi-base,
.noUi-handle {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.noUi-horizontal {
  height: 3px;
}

.noUi-horizontal .noUi-handle {
  position: relative;
  width: 29px;
  height: 29px;
  border-radius: 50%;
  left: 0px;
  top: 50%;
  transform: translateY(-50%);
  background-color: #fff;
  border: 2px solid rgba(5, 11, 32, 1);
  cursor: pointer;
}

.caption {
  margin-bottom: 10px;
}

.boxcar-header.cus-style-1 {
  position: unset !important;
  padding-bottom: 80px;
}

.box-bookmark {
  width: 36px;
  height: 36px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  border: 1px solid rgba(225, 225, 225, 1);
  box-shadow: 0px 10px 40px 0px rgba(0, 0, 0, 0.05);

}

@media (max-width: 1199px) {
  .boxcar-header.cus-style-1 {
    padding: 20px 0px 100px !important;
  }

  .about-inner-one .galler-section .exp-block .inner-box .exp-box {
    padding: 20px;
  }

  .about-inner-one .galler-section .exp-block .inner-box .exp-box .title {
    font-size: 30px;
    line-height: 1;
  }

  .about-inner-one .galler-section .exp-block .inner-box .exp-box .text {
    font-size: 18px;
    line-height: 22px;
  }
}



.gallery-sec {
  margin-bottom: 34px;
}

.gallery-sec .item2,
.gallery-sec .item3 {
  margin-bottom: 4px;
}

.gallery-sec .item2 .image,
.gallery-sec .item4 .image {
  border-radius: 0;
}

.gallery-sec .item3 .image {
  border-radius: 0;
  border-top-right-radius: 15px;

}

.gallery-sec .item5 .image {
  border-radius: 0;
  border-bottom-right-radius: 15px;

}



.side-bar-column.style-1 .inner-column {
  margin-left: 50px;
}

.dashboard-widget .content-column .inner-column .cheak-box-sec.style1,
.dashboard-widget .content-column .inner-column .gallery-sec.style1,
.dashboard-widget .content-column .inner-column .map-sec.style1 {
  padding: 15px;
  border: 0;
  border-radius: 0;
}

.header-style-ten .header-inner .c-box .nav-out-bar .nav .navigation .current-dropdown .subnav-menu {
  min-width: 200px;
}





@media (max-width: 1400px) {
  .side-bar-column.style-1 .inner-column {
    margin-left: 0px;
  }
}

@media (min-width: 991px) {
  .gallery-sec .image-column-two {
    padding-left: 0;
  }
}

@media (max-width: 991px) {
  .inventory-section .gallery-sec .image-column .inner-column {
    margin-bottom: 12px;
  }

  .inventory-section .gallery-sec .image-column .inner-column .image-box .image {
    border-radius: 16px 16px 0px 0px;
  }

  /* .header-style-ten .header-inner .c-box .btn-box {
    display: none;
  } */

  .gallery-sec .item2,
  .gallery-sec .item4 {
    padding-right: 6px;
  }

  .gallery-sec .item3,
  .gallery-sec .item5 {
    padding-left: 6px;
  }
}

.wrap-gallery-box {
  position: relative;
}

.wrap-gallery-box .content-box {
  position: absolute;
  bottom: 10px;
  left: 10px;
  right: 10px;
}

@media (max-width: 768px) {
  .wrap-gallery-box .content-box {
    display: none;
  }

}

.wrap-gallery-box .content-box .video-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 10px;

}

.wrap-gallery-box .content-box .video-list a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 10px;
  font-size: 15px;
  color: var(--theme-color-dark);
  background-color: var(--theme-color-light);
  padding: 7px 20px 5px;
  border-radius: 12px;
}

.wrap-gallery-box .content-box .video-list li:last-child {
  margin-left: auto;
}

.gallery-sec-two.style-1 .content-box {
  left: 50%;
  transform: translateX(-50%);
  right: unset;
  width: 730px;
}

@media (max-width: 1800px) {
  .gallery-sec-two.style-1 .content-box {

    width: auto;
  }
}



.wrap-listing {
  border: 1px solid rgba(225, 225, 225, 1);
  padding: 30px 30px 40px;
  border-radius: 16px;
  overflow: auto;
}

.wrap-listing .title-listing {
  margin-bottom: 38px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 20px;
}

.box-ip-search {
  position: relative;
}

.box-ip-search .icon {
  position: absolute;
  top: -1;
  left: 0;
}

.box-ip-search input {
  padding: 0;
  padding-left: 22px;
  color: var(--theme-color-dark);
  font-weight: 400;
  font-size: 15px;
  line-height: 26px;
}

.wrap-listing .cart-table {
  padding: 0;
}

.wrap-listing .cart-table tr th {
  padding: 20px 36px;
}

.wrap-listing .cart-table tr th:last-child {
  text-align: right;
}

.wrap-listing .cart-table tr td:last-child {
  text-align: right;
  padding-right: 0;
  white-space: nowrap;

}

.wrap-listing .cart-table tr td:last-child a:last-child {
  margin-left: 12px;
}

.wrap-listing .cart-table table tr td {
  padding: 20px 36px;
}


/* thumb slider */

.slider-thumb {
  border-radius: 15px 15px 0px 0px;
  overflow: hidden;
}

.slider-thumb .slick-list::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(180deg, rgba(5, 11, 32, 0) 0%, #050B20 100%);
  opacity: 0;
  z-index: 123;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;

}

.slider-thumb .slick-list {
  padding: 0;
  margin: 0;


}

.slider-thumb .slick-dots {
  opacity: 0;
  bottom: 0;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  display: none;
}

.slider-thumb .slick-dots li {
  background: #fff;
  width: 15px;
  height: 4px;
  border-radius: 10px;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.slider-thumb .slick-dots .slick-active {
  width: 30px;
}

.slider-thumb img {
  width: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.box-car.style-2 .slider-thumb .image,
.box-car .slider-thumb .image {
  border-radius: 0 !important;

}

.box-car.style-2 .slider-thumb {
  border-radius: 15px;
  overflow: hidden;
}

.section-tab-car {
  padding-top: 0;
  margin-top: -66px;

}

.section-tab-car .nav-car-tab {
  margin-bottom: 60px;
  border: 0;
  display: flex;
  gap: 60px;
  overflow: auto;
}

@media (max-width:991px) {


  .section-tab-car .nav-car-tab {
    gap: 30px;
    margin-right: -15px;
    padding-right: 15px;
  }
}

.section-tab-car .nav-car-tab::-webkit-scrollbar {
  width: 2px;
}

.section-tab-car .nav-car-tab::-webkit-scrollbar-thumb {
  background: transparent;
}

.section-tab-car .nav-car-tab .nav-link {
  padding: 16px 40px;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  border-radius: 16px 16px 0px 0px;
  display: flex;
  align-items: center;
  gap: 10px;
  margin-right: 0px;
}

.section-tab-car .nav-car-tab .nav-link svg path {
  fill: var(--theme-color-light);
}

.section-tab-car .nav-car-tab .nav-link.active svg path {
  fill: var(--theme-color-dark);
}

.section-tab-car .nav-car-tab .nav-link.active {
  background-color: var(--theme-color-light);
  border: 0;

}

.section-tab-car .nav-car-tab .nav-link.active::before {
  content: none;
}

.section-tab-car .nav-car-tab .nav-link span {
  font-size: 18px;
  line-height: 32px;
  font-weight: 500;
  color: var(--theme-color-light);
}

.section-tab-car .nav-car-tab .nav-link.active span {
  color: var(--theme-color-dark);
}

.calculator-section-home9 {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url(../images/banner/banner-calc-9.jpg);
  padding: 213px 0px;
}

.calculator-section-home9 .box-loan-calc {
  margin-right: 40px;

}

@media (max-width: 1299px) {
  .calculator-section-home9 .box-loan-calc {
    margin-right: 0px;
  }
}

.box-loan-calc {
  padding: 50px;
  background-color: var(--theme-color-light);
  border-radius: 16px;
  overflow: hidden;
}

.box-loan-calc .drop-menu.active:hover {
  border-radius: 12px;

}

.box-loan-calc .boxcar-title p {
  font-size: 15px;
  line-height: 26px;

}

.box-loan-calc .drop-menu,
.box-loan-calc .drop-menu .select {
  height: 55px;
}

.box-loan-calc .drop-menu .select {
  font-size: 14px;
  line-height: 55px;
  padding: 0 15px;
  font-weight: 500;
}

.box-loan-calc .drop-menu {
  border: 1px solid rgba(225, 225, 225, 1);
  border-radius: 12px;
}

.box-loan-calc input {
  font-size: 14px;
  line-height: 53px;
  padding: 0 15px;
  border-radius: 12px;
  font-weight: 500;

}

.box-loan-calc label {
  font-size: 15px;
  line-height: 26px;
  font-weight: 500;
  color: rgba(5, 11, 32, 1)
}

.box-loan-calc .box-ip {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 30px;
  margin-bottom: 30px;
}

.box-loan-calc .theme-btn {
  width: 100%;
}

.box-loan-calc .title {
  margin-bottom: 40px;
}

.blog-blockt-three.home9 .inner-box:hover img {
  transform: unset;

}

.category-section {
  background-color: var(--theme-color-dark);
  padding: 60px 0px;
}

.category-section .title {
  margin-bottom: 50px;
  color: var(--theme-color-light);
  text-align: center;
}

.category-section .cate-nav-tab {
  padding-bottom: 76px;
  overflow: auto;
  flex-wrap: nowrap;
}

.category-section .cate-nav-tab {}


.category-section .cate-nav-tab::-webkit-scrollbar {
  width: 2px;
}

.category-section .cate-nav-tab::-webkit-scrollbar-thumb {
  background: transparent;
}



.cate-nav-tab {
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 0;
  gap: 33px;
}

.cate-nav-tab .nav-link {
  padding: 0px 20px;
  border: 1px solid transparent;
  border-radius: 300px;
  font-size: 16px;
  line-height: 40px;
  font-weight: 500;
  color: var(--theme-color-light);
  background-color: transparent;
}

.cate-nav-tab .nav-link.active {
  background-color: transparent;
  border-color: rgba(255, 255, 255, 0.3);
  color: var(--theme-color-light);


}

.cate-nav-tab .nav-link:hover {
  border-color: rgba(255, 255, 255, 0.3);
}

.wrap-slider-car {
  padding-bottom: 0;
}

.wrap-slider-car .slick-list {
  max-width: 1400px;
  margin: auto;
  padding-bottom: 0;

}

.wrap-slider-car .slick-arrow {
  background-color: transparent;
  top: 50%;
  transform: translateY(-50%);
  border: 1px solid rgba(255, 255, 255, 0.2);
}

.wrap-slider-car .slick-arrow::before {
  color: var(--theme-color-light);

}

.wrap-slider-car .slick-prev {
  left: 0;

}

.wrap-slider-car .slick-next {
  right: 0;
  left: unset;

}

.wrap-slider-car .box-cate-car {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
  justify-content: center;
}

.box-cate-car .name {
  font-size: 20px;
  line-height: 30px;
  color: var(--theme-color-light);
  font-weight: 500;
}

.filter-search-section {
  margin-top: -70px;
}

.search-nav-tab {
  background: rgba(255, 255, 255, 0.2);
  box-shadow: 0px 10px 40px 0px rgba(0, 0, 0, 0.05);
  backdrop-filter: blur(10px);
  border-radius: 16px 16px 0px 0px;
  position: relative;
  z-index: 100;
  border: 0;
  padding: 20px 40px 13px;
  gap: 30px;
}

.search-nav-tab .nav-link {
  font-size: 16px;
  line-height: 28px;
  color: var(--theme-color-light);
  font-weight: 500;
  border: 0;
  border-bottom: 2px solid transparent;
  padding: 0;
  padding-bottom: 8px;
}

.search-nav-tab .nav-link.active {
  background: transparent;
  color: var(--theme-color-light);


}

.search-nav-tab .nav-link:hover {
  border-color: var(--theme-color-light);
}


.form-filter-search {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  /* -ms-flex-wrap: wrap;
  flex-wrap: wrap; */
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background: #fff;
  border-radius: 0px 0px 16px 16px;
  padding: 20px;
  padding-left: 10px;
  background: rgba(255, 255, 255, 0.4);
  box-shadow: 0px 10px 40px 0px rgba(0, 0, 0, 0.05);


}

.form-filter-search .form_boxes {
  width: 100%;
  padding: 0px 30px;

}

.form-filter-search .form_boxes label {
  font-size: 18px;
  line-height: 32px;
  text-transform: capitalize;
  font-weight: 500;
}

.form-filter-search .form_boxes .select {
  padding: 0;
  line-height: unset;
}

.form-filter-search .form_boxes span {
  font-size: 15px;
  line-height: 26px;
}

.form-filter-search .form_boxes .drop-menu {
  height: auto;
}

.form-filter-search .form-submit {
  width: 209px;
}

.form-filter-search .form-submit .theme-btn {
  font-size: 15px;
  line-height: 26px;
  gap: 10px;
  border-radius: 16px;
}

.form-filter-search .form-submit .theme-btn i {
  font-size: 16px;
  line-height: 16px;
}

.wrap-sidebar-dk .sidebar-handle {
  display: none;
  margin-bottom: 20px;
  color: var(--theme-color1);
  font-weight: 500;
}

.wrap-sidebar-dk .sidebar-handle svg path {
  fill: var(--theme-color1);
}


@media (max-width: 1499px) {
  .category-section {
    padding-bottom: 120px;
  }

  .wrap-slider-car .slick-arrow {
    top: unset;
    transform: unset;
    bottom: -80px;
    left: 0;
  }

  .wrap-slider-car .slick-arrow.slick-next {
    right: unset;
    left: 95px;
  }
}

@media (max-width: 1199px) {
  .form-filter-search {
    flex-wrap: wrap;
    gap: 15px;
    padding-left: 20px;
  }

  .form-filter-search .form_boxes {
    width: 45%;
    flex-grow: 1;
    padding: 10px 20px;
    border: 1px solid rgba(225, 225, 225, 1);
    border-radius: 12px;
  }

  .wrap-sidebar-dk .sidebar-handle {
    display: block;
  }

  .wrap-sidebar-dk .inventory-sidebar {
    display: none;
    margin-bottom: 30px;

  }

}






@media (max-width: 1140px) {
  .cate-nav-tab {
    justify-content: flex-start;
  }
}

@media (max-width: 550px) {
  .box-loan-calc .box-ip {
    grid-template-columns: 1fr;
  }

  .box-loan-calc {
    padding: 30px;
  }
}






@media (max-width: 767px) {
  .slider-thumb a img {
    width: 100%;
  }

  .calculator-section-home9 {
    padding: 100px 0px;
  }

  .form-filter-search .form_boxes {
    width: 100%;
  }

  .form-filter-search .form-submit,
  .form-filter-search .form-submit .theme-btn {
    width: 100%;
  }
}




.inner-box:hover .slider-thumb .slick-dots {
  opacity: 1;
  display: block;
}

.inner-box:hover .slick-list::before {
  opacity: 0.3;
  /* transition: all 5s ease; */

}


/* Styling; */
.noUi-background {
  background: #D6D7D9;
}

.noUi-connect {
  background: rgba(5, 11, 32, 1);
  -webkit-transition: background 450ms;
  transition: background 450ms;
  cursor: pointer;
}

.noUi-origin {
  border-radius: 2px;
}

.noUi-target {
  width: 100%;
  height: 3px;
  border-radius: 2px;
}

.noUi-horizontal .noUi-handle.noUi-handle-upper {
  left: -30px;
}

/* Handles and cursors;
*/
.noUi-draggable {
  cursor: w-resize;
}

.noUi-vertical .noUi-draggable {
  cursor: n-resize;
}

.noUi-handle {
  cursor: default;
  -webkit-box-sizing: content-box !important;
  -moz-box-sizing: content-box !important;
  box-sizing: content-box !important;
}



/* Disabled state; */
[disabled].noUi-connect,
[disabled] .noUi-connect {
  background: #B8B8B8;
}

[disabled].noUi-origin,
[disabled] .noUi-handle {
  cursor: not-allowed;
}

.slider-labels .caption {
  font-weight: 500;
  font-size: 16px;
}

#slider-range-value01::after,
#slider-range-value1::after {
  margin: 0 2px;
}

.cursor-pointer{
  cursor: pointer;
}


  .loader-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: rgba(255, 255, 255, 0.7); 
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999; 
    pointer-events: none; 
    overflow: hidden;
  }


.loader {
  border: 4px solid #f3f3f3;
  border-top: 4px solid #000000;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}


.address-side-bar {
  padding: 15px;
  border: 1px solid #ddd;
  border-radius: 16px;
}

.address-title {
  font-size: 19px;
  margin-bottom: 10px;
  font-weight: 500;
  padding: 9px 0px;
}

.address-list {
  list-style: none;
  padding: 0;
}

.address-item {
  margin-bottom: 15px;
}

.address-box {
  display: flex;
  align-items: center;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  background-color: #f9f9f9;
}

.address-label {
  flex: 1;
  margin-left: 10px;
}

.address-actions {
  display: flex;
  gap: 10px;
}

.address-edit-btn,
.address-delete-btn {
  background-color: #f1f1f1;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  border-radius: 3px;
}

.address-edit-btn:hover,
.address-delete-btn:hover {
  background-color: #e1e1e1;
}

.address-add-btn {
  margin-top: 15px;
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
  width: 100%;
}

.address-add-btn:hover {
  background-color: #0056b3;
}

.address-empty {
  text-align: center;
  font-size: 14px;
  color: #777;
}

