.colors {
    display: flex;
    margin-bottom: 15px;
    bottom: 2em;
    right: 2em;
    z-index: 3;
  }
  
  .color {
    height: 36px;
    width: 36px;
    margin-left: 0.5em;
    border-radius: 9px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);
    border: 2px solid #050b20;
    cursor: pointer;
  }

  .css-13cymwt-control {
    border: none !important;
  }
