/*!
 * Font Awesome Pro 6.0.0-alpha3 by @fontawesome - https://fontawesome.com
 * License - https://fontawesome.com/license (Commercial License)
 */
.fa {
  font-family: "Font Awesome 6 Pro";
  font-family: var(--fa-style-family, "Font Awesome 6 Pro");
  font-weight: 900;
  font-weight: var(--fa-style, 900);
}

.fa,
.fas,
.fa-solid,
.far,
.fa-regular,
.fal,
.fa-light,
.fat,
.fa-thin,
.fad,
.fa-duotone,
.fab,
.fa-brands {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  display: var(--fa-display, inline-block);
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
}

.fa-1x {
  font-size: 1em;
}

.fa-2x {
  font-size: 2em;
}

.fa-3x {
  font-size: 3em;
}

.fa-4x {
  font-size: 4em;
}

.fa-5x {
  font-size: 5em;
}

.fa-6x {
  font-size: 6em;
}

.fa-7x {
  font-size: 7em;
}

.fa-8x {
  font-size: 8em;
}

.fa-9x {
  font-size: 9em;
}

.fa-10x {
  font-size: 10em;
}

.fa-2xs {
  font-size: 0.625em;
  line-height: 0.1em;
  vertical-align: 0.225em;
}

.fa-xs {
  font-size: 0.75em;
  line-height: 0.08333em;
  vertical-align: 0.125em;
}

.fa-sm {
  font-size: 0.875em;
  line-height: 0.07143em;
  vertical-align: 0.05357em;
}

.fa-lg {
  font-size: 1.25em;
  line-height: 0.05em;
  vertical-align: -0.075em;
}

.fa-xl {
  font-size: 1.5em;
  line-height: 0.04167em;
  vertical-align: -0.125em;
}

.fa-2xl {
  font-size: 2em;
  line-height: 0.03125em;
  vertical-align: -0.1875em;
}

.fa-fw {
  text-align: center;
  width: 1.25em;
}

.fa-ul {
  list-style-type: none;
  margin-left: 2.5em;
  margin-left: var(--fa-li-margin, 2.5em);
  padding-left: 0;
}

.fa-ul>li {
  position: relative;
}

.fa-li {
  left: calc(2em * -1);
  left: calc(var(--fa-li-width, 2em) * -1);
  position: absolute;
  text-align: center;
  width: 2em;
  width: var(--fa-li-width, 2em);
  line-height: inherit;
}

.fa-border {
  border-color: #eee;
  border-color: var(--fa-border-color, #eee);
  border-radius: 0.1em;
  border-radius: var(--fa-border-radius, 0.1em);
  border-style: solid;
  border-style: var(--fa-border-style, solid);
  border-width: 0.08em;
  border-width: var(--fa-border-width, 0.08em);
  padding: 0.2em 0.25em 0.15em;
  padding: var(--fa-border-padding, 0.2em 0.25em 0.15em);
}

.fa-pull-left {
  float: left;
  margin-right: 0.3em;
  margin-right: var(--fa-pull-margin, 0.3em);
}

.fa-pull-right {
  float: right;
  margin-left: 0.3em;
  margin-left: var(--fa-pull-margin, 0.3em);
}

.fa-beat {
  -webkit-animation-name: fa-beat;
  animation-name: fa-beat;
  -webkit-animation-delay: 0;
  animation-delay: 0;
  -webkit-animation-delay: var(--fa-animation-delay, 0);
  animation-delay: var(--fa-animation-delay, 0);
  -webkit-animation-direction: normal;
  animation-direction: normal;
  -webkit-animation-direction: var(--fa-animation-direction, normal);
  animation-direction: var(--fa-animation-direction, normal);
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-duration: var(--fa-animation-duration, 1s);
  animation-duration: var(--fa-animation-duration, 1s);
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: var(--fa-animation-timing, ease-in-out);
  animation-timing-function: var(--fa-animation-timing, ease-in-out);
}

.fa-fade {
  -webkit-animation-name: fa-fade;
  animation-name: fa-fade;
  -webkit-animation-delay: 0;
  animation-delay: 0;
  -webkit-animation-delay: var(--fa-animation-delay, 0);
  animation-delay: var(--fa-animation-delay, 0);
  -webkit-animation-direction: normal;
  animation-direction: normal;
  -webkit-animation-direction: var(--fa-animation-direction, normal);
  animation-direction: var(--fa-animation-direction, normal);
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-duration: var(--fa-animation-duration, 1s);
  animation-duration: var(--fa-animation-duration, 1s);
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  -webkit-animation-timing-function: cubic-bezier(0.4, 0, 0.6, 1);
  animation-timing-function: cubic-bezier(0.4, 0, 0.6, 1);
  -webkit-animation-timing-function: var(--fa-animation-timing, cubic-bezier(0.4, 0, 0.6, 1));
  animation-timing-function: var(--fa-animation-timing, cubic-bezier(0.4, 0, 0.6, 1));
}

.fa-flash {
  -webkit-animation-name: fa-flash;
  animation-name: fa-flash;
  -webkit-animation-delay: 0;
  animation-delay: 0;
  -webkit-animation-delay: var(--fa-animation-delay, 0);
  animation-delay: var(--fa-animation-delay, 0);
  -webkit-animation-direction: normal;
  animation-direction: normal;
  -webkit-animation-direction: var(--fa-animation-direction, normal);
  animation-direction: var(--fa-animation-direction, normal);
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-duration: var(--fa-animation-duration, 1s);
  animation-duration: var(--fa-animation-duration, 1s);
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  -webkit-animation-timing-function: cubic-bezier(0.4, 0, 0.6, 1);
  animation-timing-function: cubic-bezier(0.4, 0, 0.6, 1);
  -webkit-animation-timing-function: var(--fa-animation-timing, cubic-bezier(0.4, 0, 0.6, 1));
  animation-timing-function: var(--fa-animation-timing, cubic-bezier(0.4, 0, 0.6, 1));
}

.fa-flip {
  -webkit-animation-name: fa-flip;
  animation-name: fa-flip;
  -webkit-animation-delay: 0;
  animation-delay: 0;
  -webkit-animation-delay: var(--fa-animation-delay, 0);
  animation-delay: var(--fa-animation-delay, 0);
  -webkit-animation-direction: normal;
  animation-direction: normal;
  -webkit-animation-direction: var(--fa-animation-direction, normal);
  animation-direction: var(--fa-animation-direction, normal);
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-duration: var(--fa-animation-duration, 1s);
  animation-duration: var(--fa-animation-duration, 1s);
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: var(--fa-animation-timing, ease-in-out);
  animation-timing-function: var(--fa-animation-timing, ease-in-out);
}

.fa-spin {
  -webkit-animation-name: fa-spin;
  animation-name: fa-spin;
  -webkit-animation-delay: 0;
  animation-delay: 0;
  -webkit-animation-delay: var(--fa-animation-delay, 0);
  animation-delay: var(--fa-animation-delay, 0);
  -webkit-animation-direction: normal;
  animation-direction: normal;
  -webkit-animation-direction: var(--fa-animation-direction, normal);
  animation-direction: var(--fa-animation-direction, normal);
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
  -webkit-animation-duration: var(--fa-animation-duration, 2s);
  animation-duration: var(--fa-animation-duration, 2s);
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-timing-function: var(--fa-animation-timing, linear);
  animation-timing-function: var(--fa-animation-timing, linear);
}

.fa-spin-reverse {
  --fa-animation-direction: reverse;
}

.fa-pulse,
.fa-spin-pulse {
  -webkit-animation-name: fa-spin;
  animation-name: fa-spin;
  -webkit-animation-direction: normal;
  animation-direction: normal;
  -webkit-animation-direction: var(--fa-animation-direction, normal);
  animation-direction: var(--fa-animation-direction, normal);
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-duration: var(--fa-animation-duration, 1s);
  animation-duration: var(--fa-animation-duration, 1s);
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  -webkit-animation-timing-function: steps(8);
  animation-timing-function: steps(8);
  -webkit-animation-timing-function: var(--fa-animation-timing, steps(8));
  animation-timing-function: var(--fa-animation-timing, steps(8));
}

@media (prefers-reduced-motion: reduce) {

  .fa-beat,
  .fa-fade,
  .fa-flash,
  .fa-flip,
  .fa-pulse,
  .fa-spin,
  .fa-spin-pulse {
    -webkit-animation-delay: -1ms;
    animation-delay: -1ms;
    -webkit-animation-duration: 1ms;
    animation-duration: 1ms;
    -webkit-animation-iteration-count: 1;
    animation-iteration-count: 1;
    -webkit-transition-delay: 0s;
    transition-delay: 0s;
    -webkit-transition-duration: 0s;
    transition-duration: 0s;
  }
}

@-webkit-keyframes fa-beat {

  0%,
  90% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  45% {
    -webkit-transform: scale(1.25);
    transform: scale(1.25);
    -webkit-transform: scale(var(--fa-beat-scale, 1.25));
    transform: scale(var(--fa-beat-scale, 1.25));
  }
}

@keyframes fa-beat {

  0%,
  90% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  45% {
    -webkit-transform: scale(1.25);
    transform: scale(1.25);
    -webkit-transform: scale(var(--fa-beat-scale, 1.25));
    transform: scale(var(--fa-beat-scale, 1.25));
  }
}

@-webkit-keyframes fa-fade {
  50% {
    opacity: 0.4;
    opacity: var(--fa-fade-opacity, 0.4);
  }
}

@keyframes fa-fade {
  50% {
    opacity: 0.4;
    opacity: var(--fa-fade-opacity, 0.4);
  }
}

@-webkit-keyframes fa-flash {

  0%,
  100% {
    opacity: 0.4;
    opacity: var(--fa-flash-opacity, 0.4);
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  50% {
    opacity: 1;
    -webkit-transform: scale(1.125);
    transform: scale(1.125);
    -webkit-transform: scale(var(--fa-flash-scale, 1.125));
    transform: scale(var(--fa-flash-scale, 1.125));
  }
}

@keyframes fa-flash {

  0%,
  100% {
    opacity: 0.4;
    opacity: var(--fa-flash-opacity, 0.4);
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  50% {
    opacity: 1;
    -webkit-transform: scale(1.125);
    transform: scale(1.125);
    -webkit-transform: scale(var(--fa-flash-scale, 1.125));
    transform: scale(var(--fa-flash-scale, 1.125));
  }
}

@-webkit-keyframes fa-flip {
  50% {
    -webkit-transform: rotate3d(0, 1, 0, -180deg);
    transform: rotate3d(0, 1, 0, -180deg);
    -webkit-transform: rotate3d(var(--fa-flip-x, 0), var(--fa-flip-y, 1), var(--fa-flip-z, 0), var(--fa-flip-angle, -180deg));
    transform: rotate3d(var(--fa-flip-x, 0), var(--fa-flip-y, 1), var(--fa-flip-z, 0), var(--fa-flip-angle, -180deg));
  }
}

@keyframes fa-flip {
  50% {
    -webkit-transform: rotate3d(0, 1, 0, -180deg);
    transform: rotate3d(0, 1, 0, -180deg);
    -webkit-transform: rotate3d(var(--fa-flip-x, 0), var(--fa-flip-y, 1), var(--fa-flip-z, 0), var(--fa-flip-angle, -180deg));
    transform: rotate3d(var(--fa-flip-x, 0), var(--fa-flip-y, 1), var(--fa-flip-z, 0), var(--fa-flip-angle, -180deg));
  }
}

@-webkit-keyframes fa-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes fa-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.fa-rotate-90 {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}

.fa-rotate-180 {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

.fa-rotate-270 {
  -webkit-transform: rotate(270deg);
  transform: rotate(270deg);
}

.fa-flip-horizontal {
  -webkit-transform: scale(-1, 1);
  transform: scale(-1, 1);
}

.fa-flip-vertical {
  -webkit-transform: scale(1, -1);
  transform: scale(1, -1);
}

.fa-flip-both,
.fa-flip-horizontal.fa-flip-vertical {
  -webkit-transform: scale(-1, -1);
  transform: scale(-1, -1);
}

.fa-rotate-by {
  -webkit-transform: rotate(none);
  transform: rotate(none);
  -webkit-transform: rotate(var(--fa-rotate-angle, none));
  transform: rotate(var(--fa-rotate-angle, none));
}

.fa-stack {
  display: inline-block;
  height: 2em;
  line-height: 2em;
  position: relative;
  vertical-align: middle;
  width: 2.5em;
}

.fa-stack-1x,
.fa-stack-2x {
  left: 0;
  position: absolute;
  text-align: center;
  width: 100%;
  z-index: auto;
  z-index: var(--fa-stack-z-index, auto);
}

.fa-stack-1x {
  line-height: inherit;
}

.fa-stack-2x {
  font-size: 2em;
}

.fa-inverse {
  color: #fff;
  color: var(--fa-inverse, #fff);
}

/* Font Awesome uses the Unicode Private Use Area (PUA) to ensure screen
readers do not read off random characters that represent icons */
.fa-0::before {
  content: "\e2d2";
}

.fa-1::before {
  content: "\e2d3";
}

.fa-2::before {
  content: "\e2d4";
}

.fa-3::before {
  content: "\e2d5";
}

.fa-4::before {
  content: "\e2d6";
}

.fa-5::before {
  content: "\e2d7";
}

.fa-6::before {
  content: "\e2d8";
}

.fa-7::before {
  content: "\e2d9";
}

.fa-8::before {
  content: "\e2da";
}

.fa-9::before {
  content: "\e2db";
}

.fa-360-degrees::before {
  content: "\e2dc";
}

.fa-a::before {
  content: "\e2dd";
}

.fa-abacus::before {
  content: "\f640";
}

.fa-accent-grave::before {
  content: "\e2de";
}

.fa-acorn::before {
  content: "\f6ae";
}

.fa-address-book::before {
  content: "\f2b9";
}

.fa-contact-book::before {
  content: "\f2b9";
}

.fa-address-card::before {
  content: "\f2bb";
}

.fa-contact-card::before {
  content: "\f2bb";
}

.fa-vcard::before {
  content: "\f2bb";
}

.fa-air-conditioner::before {
  content: "\f8f4";
}

.fa-airplay::before {
  content: "\e089";
}

.fa-alarm-clock::before {
  content: "\f34e";
}

.fa-alarm-exclamation::before {
  content: "\f843";
}

.fa-alarm-plus::before {
  content: "\f844";
}

.fa-alarm-snooze::before {
  content: "\f845";
}

.fa-album::before {
  content: "\f89f";
}

.fa-album-collection::before {
  content: "\f8a0";
}

.fa-alicorn::before {
  content: "\f6b0";
}

.fa-alien::before {
  content: "\f8f5";
}

.fa-alien-8bit::before {
  content: "\f8f6";
}

.fa-alien-monster::before {
  content: "\f8f6";
}

.fa-align-center::before {
  content: "\f037";
}

.fa-align-justify::before {
  content: "\f039";
}

.fa-align-left::before {
  content: "\f036";
}

.fa-align-right::before {
  content: "\f038";
}

.fa-align-slash::before {
  content: "\f846";
}

.fa-alt::before {
  content: "\e08a";
}

.fa-amp-guitar::before {
  content: "\f8a1";
}

.fa-ampersand::before {
  content: "\e08b";
}

.fa-anchor::before {
  content: "\f13d";
}

.fa-angel::before {
  content: "\f779";
}

.fa-angle::before {
  content: "\e08c";
}

.fa-angle-90::before {
  content: "\e08d";
}

.fa-angle-down::before {
  content: "\f107";
}

.fa-angle-left::before {
  content: "\f104";
}

.fa-angle-right::before {
  content: "\f105";
}

.fa-angle-up::before {
  content: "\f106";
}

.fa-angles-down::before {
  content: "\f103";
}

.fa-angle-double-down::before {
  content: "\f103";
}

.fa-angles-left::before {
  content: "\f100";
}

.fa-angle-double-left::before {
  content: "\f100";
}

.fa-angles-right::before {
  content: "\f101";
}

.fa-angle-double-right::before {
  content: "\f101";
}

.fa-angles-up::before {
  content: "\f102";
}

.fa-angle-double-up::before {
  content: "\f102";
}

.fa-ankh::before {
  content: "\f644";
}

.fa-aperture::before {
  content: "\e2df";
}

.fa-apostrophe::before {
  content: "\e2e0";
}

.fa-apple-core::before {
  content: "\e08f";
}

.fa-apple-whole::before {
  content: "\f5d1";
}

.fa-apple-alt::before {
  content: "\f5d1";
}

.fa-archway::before {
  content: "\f557";
}

.fa-arrow-down::before {
  content: "\f063";
}

.fa-arrow-down-1-9::before {
  content: "\f162";
}

.fa-sort-numeric-asc::before {
  content: "\f162";
}

.fa-sort-numeric-down::before {
  content: "\f162";
}

.fa-arrow-down-9-1::before {
  content: "\f886";
}

.fa-sort-numeric-desc::before {
  content: "\f886";
}

.fa-sort-numeric-down-alt::before {
  content: "\f886";
}

.fa-arrow-down-a-z::before {
  content: "\f15d";
}

.fa-sort-alpha-asc::before {
  content: "\f15d";
}

.fa-sort-alpha-down::before {
  content: "\f15d";
}

.fa-arrow-down-arrow-up::before {
  content: "\f883";
}

.fa-sort-alt::before {
  content: "\f883";
}

.fa-arrow-down-big-small::before {
  content: "\f88c";
}

.fa-sort-size-down::before {
  content: "\f88c";
}

.fa-arrow-down-from-dotted-line::before {
  content: "\e090";
}

.fa-arrow-down-from-line::before {
  content: "\f345";
}

.fa-arrow-from-top::before {
  content: "\f345";
}

.fa-arrow-down-left::before {
  content: "\e091";
}

.fa-arrow-down-left-and-arrow-up-right-to-center::before {
  content: "\e092";
}

.fa-arrow-down-long::before {
  content: "\f175";
}

.fa-long-arrow-down::before {
  content: "\f175";
}

.fa-arrow-down-right::before {
  content: "\e093";
}

.fa-arrow-down-short-wide::before {
  content: "\f884";
}

.fa-sort-amount-desc::before {
  content: "\f884";
}

.fa-sort-amount-down-alt::before {
  content: "\f884";
}

.fa-arrow-down-small-big::before {
  content: "\f88d";
}

.fa-sort-size-down-alt::before {
  content: "\f88d";
}

.fa-arrow-down-square-triangle::before {
  content: "\f889";
}

.fa-sort-shapes-down-alt::before {
  content: "\f889";
}

.fa-arrow-down-to-bracket::before {
  content: "\e094";
}

.fa-arrow-down-to-dotted-line::before {
  content: "\e095";
}

.fa-arrow-down-to-line::before {
  content: "\f33d";
}

.fa-arrow-to-bottom::before {
  content: "\f33d";
}

.fa-arrow-down-to-square::before {
  content: "\e096";
}

.fa-arrow-down-triangle-square::before {
  content: "\f888";
}

.fa-sort-shapes-down::before {
  content: "\f888";
}

.fa-arrow-down-wide-short::before {
  content: "\f160";
}

.fa-sort-amount-asc::before {
  content: "\f160";
}

.fa-sort-amount-down::before {
  content: "\f160";
}

.fa-arrow-down-z-a::before {
  content: "\f881";
}

.fa-sort-alpha-desc::before {
  content: "\f881";
}

.fa-sort-alpha-down-alt::before {
  content: "\f881";
}

.fa-arrow-left::before {
  content: "\f060";
}

.fa-arrow-left-from-line::before {
  content: "\f344";
}

.fa-arrow-from-right::before {
  content: "\f344";
}

.fa-arrow-left-long::before {
  content: "\f177";
}

.fa-long-arrow-left::before {
  content: "\f177";
}

.fa-arrow-left-to-line::before {
  content: "\f33e";
}

.fa-arrow-to-left::before {
  content: "\f33e";
}

.fa-arrow-pointer::before {
  content: "\f245";
}

.fa-mouse-pointer::before {
  content: "\f245";
}

.fa-arrow-right::before {
  content: "\f061";
}

.fa-arrow-right-arrow-left::before {
  content: "\f0ec";
}

.fa-exchange::before {
  content: "\f0ec";
}

.fa-arrow-right-from-bracket::before {
  content: "\f08b";
}

.fa-sign-out::before {
  content: "\f08b";
}

.fa-arrow-right-from-line::before {
  content: "\f343";
}

.fa-arrow-from-left::before {
  content: "\f343";
}

.fa-arrow-right-long::before {
  content: "\f178";
}

.fa-long-arrow-right::before {
  content: "\f178";
}

.fa-arrow-right-to-bracket::before {
  content: "\f090";
}

.fa-sign-in::before {
  content: "\f090";
}

.fa-arrow-right-to-line::before {
  content: "\f340";
}

.fa-arrow-to-right::before {
  content: "\f340";
}

.fa-arrow-rotate-left::before {
  content: "\f0e2";
}

.fa-arrow-left-rotate::before {
  content: "\f0e2";
}

.fa-arrow-rotate-back::before {
  content: "\f0e2";
}

.fa-arrow-rotate-backward::before {
  content: "\f0e2";
}

.fa-undo::before {
  content: "\f0e2";
}

.fa-arrow-rotate-right::before {
  content: "\f01e";
}

.fa-arrow-right-rotate::before {
  content: "\f01e";
}

.fa-arrow-rotate-forward::before {
  content: "\f01e";
}

.fa-redo::before {
  content: "\f01e";
}

.fa-arrow-trend-down::before {
  content: "\e097";
}

.fa-arrow-trend-up::before {
  content: "\e098";
}

.fa-arrow-turn-down::before {
  content: "\f149";
}

.fa-level-down::before {
  content: "\f149";
}

.fa-arrow-turn-down-left::before {
  content: "\e2e1";
}

.fa-arrow-turn-up::before {
  content: "\f148";
}

.fa-level-up::before {
  content: "\f148";
}

.fa-arrow-up::before {
  content: "\f062";
}

.fa-arrow-up-1-9::before {
  content: "\f163";
}

.fa-sort-numeric-up::before {
  content: "\f163";
}

.fa-arrow-up-9-1::before {
  content: "\f887";
}

.fa-sort-numeric-up-alt::before {
  content: "\f887";
}

.fa-arrow-up-a-z::before {
  content: "\f15e";
}

.fa-sort-alpha-up::before {
  content: "\f15e";
}

.fa-arrow-up-arrow-down::before {
  content: "\e099";
}

.fa-sort-up-down::before {
  content: "\e099";
}

.fa-arrow-up-big-small::before {
  content: "\f88e";
}

.fa-sort-size-up::before {
  content: "\f88e";
}

.fa-arrow-up-from-bracket::before {
  content: "\e09a";
}

.fa-arrow-up-from-dotted-line::before {
  content: "\e09b";
}

.fa-arrow-up-from-line::before {
  content: "\f342";
}

.fa-arrow-from-bottom::before {
  content: "\f342";
}

.fa-arrow-up-from-square::before {
  content: "\e09c";
}

.fa-arrow-up-left::before {
  content: "\e09d";
}

.fa-arrow-up-left-from-circle::before {
  content: "\e09e";
}

.fa-arrow-up-long::before {
  content: "\f176";
}

.fa-long-arrow-up::before {
  content: "\f176";
}

.fa-arrow-up-right::before {
  content: "\e09f";
}

.fa-arrow-up-right-and-arrow-down-left-from-center::before {
  content: "\e0a0";
}

.fa-arrow-up-right-from-square::before {
  content: "\f08e";
}

.fa-external-link::before {
  content: "\f08e";
}

.fa-arrow-up-short-wide::before {
  content: "\f885";
}

.fa-sort-amount-up-alt::before {
  content: "\f885";
}

.fa-arrow-up-small-big::before {
  content: "\f88f";
}

.fa-sort-size-up-alt::before {
  content: "\f88f";
}

.fa-arrow-up-square-triangle::before {
  content: "\f88b";
}

.fa-sort-shapes-up-alt::before {
  content: "\f88b";
}

.fa-arrow-up-to-dotted-line::before {
  content: "\e0a1";
}

.fa-arrow-up-to-line::before {
  content: "\f341";
}

.fa-arrow-to-top::before {
  content: "\f341";
}

.fa-arrow-up-triangle-square::before {
  content: "\f88a";
}

.fa-sort-shapes-up::before {
  content: "\f88a";
}

.fa-arrow-up-wide-short::before {
  content: "\f161";
}

.fa-sort-amount-up::before {
  content: "\f161";
}

.fa-arrow-up-z-a::before {
  content: "\f882";
}

.fa-sort-alpha-up-alt::before {
  content: "\f882";
}

.fa-arrows-cross::before {
  content: "\e0a2";
}

.fa-arrows-from-dotted-line::before {
  content: "\e0a3";
}

.fa-arrows-from-line::before {
  content: "\e0a4";
}

.fa-arrows-left-right::before {
  content: "\f07e";
}

.fa-arrows-h::before {
  content: "\f07e";
}

.fa-arrows-maximize::before {
  content: "\f31d";
}

.fa-expand-arrows::before {
  content: "\f31d";
}

.fa-arrows-minimize::before {
  content: "\e0a5";
}

.fa-compress-arrows::before {
  content: "\e0a5";
}

.fa-arrows-repeat::before {
  content: "\f364";
}

.fa-repeat-alt::before {
  content: "\f364";
}

.fa-arrows-repeat-1::before {
  content: "\f366";
}

.fa-repeat-1-alt::before {
  content: "\f366";
}

.fa-arrows-retweet::before {
  content: "\f361";
}

.fa-retweet-alt::before {
  content: "\f361";
}

.fa-arrows-rotate::before {
  content: "\f021";
}

.fa-refresh::before {
  content: "\f021";
}

.fa-sync::before {
  content: "\f021";
}

.fa-arrows-to-dotted-line::before {
  content: "\e0a6";
}

.fa-arrows-to-line::before {
  content: "\e0a7";
}

.fa-arrows-up-down::before {
  content: "\f07d";
}

.fa-arrows-v::before {
  content: "\f07d";
}

.fa-arrows-up-down-left-right::before {
  content: "\f047";
}

.fa-arrows::before {
  content: "\f047";
}

.fa-asterisk::before {
  content: "\f069";
}

.fa-at::before {
  content: "\f1fa";
}

.fa-atom::before {
  content: "\f5d2";
}

.fa-atom-simple::before {
  content: "\f5d3";
}

.fa-atom-alt::before {
  content: "\f5d3";
}

.fa-audio-description::before {
  content: "\f29e";
}

.fa-audio-description-slash::before {
  content: "\e0a8";
}

.fa-austral-sign::before {
  content: "\e0a9";
}

.fa-avocado::before {
  content: "\e0aa";
}

.fa-award::before {
  content: "\f559";
}

.fa-award-simple::before {
  content: "\e0ab";
}

.fa-axe::before {
  content: "\f6b2";
}

.fa-axe-battle::before {
  content: "\f6b3";
}

.fa-b::before {
  content: "\e2e2";
}

.fa-baby::before {
  content: "\f77c";
}

.fa-baby-carriage::before {
  content: "\f77d";
}

.fa-carriage-baby::before {
  content: "\f77d";
}

.fa-backpack::before {
  content: "\f5d4";
}

.fa-backward::before {
  content: "\f04a";
}

.fa-backward-fast::before {
  content: "\f049";
}

.fa-fast-backward::before {
  content: "\f049";
}

.fa-backward-step::before {
  content: "\f048";
}

.fa-step-backward::before {
  content: "\f048";
}

.fa-bacon::before {
  content: "\f7e5";
}

.fa-bacteria::before {
  content: "\e059";
}

.fa-bacterium::before {
  content: "\e05a";
}

.fa-badge::before {
  content: "\f335";
}

.fa-badge-check::before {
  content: "\f336";
}

.fa-badge-dollar::before {
  content: "\f645";
}

.fa-badge-percent::before {
  content: "\f646";
}

.fa-badge-sheriff::before {
  content: "\f8a2";
}

.fa-badger-honey::before {
  content: "\f6b4";
}

.fa-bag-shopping::before {
  content: "\f290";
}

.fa-shopping-bag::before {
  content: "\f290";
}

.fa-bags-shopping::before {
  content: "\f847";
}

.fa-bahai::before {
  content: "\f666";
}

.fa-baht-sign::before {
  content: "\e0ac";
}

.fa-ball-pile::before {
  content: "\f77e";
}

.fa-balloon::before {
  content: "\e2e3";
}

.fa-balloons::before {
  content: "\e2e4";
}

.fa-ballot::before {
  content: "\f732";
}

.fa-ballot-check::before {
  content: "\f733";
}

.fa-ban::before {
  content: "\f05e";
}

.fa-cancel::before {
  content: "\f05e";
}

.fa-ban-bug::before {
  content: "\f7f9";
}

.fa-debug::before {
  content: "\f7f9";
}

.fa-ban-parking::before {
  content: "\f616";
}

.fa-parking-circle-slash::before {
  content: "\f616";
}

.fa-ban-smoking::before {
  content: "\f54d";
}

.fa-smoking-ban::before {
  content: "\f54d";
}

.fa-banana::before {
  content: "\e2e5";
}

.fa-bandage::before {
  content: "\f462";
}

.fa-band-aid::before {
  content: "\f462";
}

.fa-bangladeshi-taka-sign::before {
  content: "\e2e6";
}

.fa-banjo::before {
  content: "\f8a3";
}

.fa-bank::before {
  content: "\f19c";
}

.fa-institution::before {
  content: "\f19c";
}

.fa-university::before {
  content: "\f19c";
}

.fa-barcode::before {
  content: "\f02a";
}

.fa-barcode-read::before {
  content: "\f464";
}

.fa-barcode-scan::before {
  content: "\f465";
}

.fa-bars::before {
  content: "\f0c9";
}

.fa-navicon::before {
  content: "\f0c9";
}

.fa-bars-filter::before {
  content: "\e0ad";
}

.fa-bars-progress::before {
  content: "\f828";
}

.fa-tasks-alt::before {
  content: "\f828";
}

.fa-bars-sort::before {
  content: "\e0ae";
}

.fa-bars-staggered::before {
  content: "\f550";
}

.fa-reorder::before {
  content: "\f550";
}

.fa-stream::before {
  content: "\f550";
}

.fa-baseball-ball::before {
  content: "\f433";
}

.fa-baseball-bat-ball::before {
  content: "\f432";
}

.fa-baseball::before {
  content: "\f432";
}

.fa-basket-shopping::before {
  content: "\f291";
}

.fa-shopping-basket::before {
  content: "\f291";
}

.fa-basket-shopping-simple::before {
  content: "\e0af";
}

.fa-shopping-basket-alt::before {
  content: "\e0af";
}

.fa-basketball-ball::before {
  content: "\f434";
}

.fa-basketball-hoop::before {
  content: "\f435";
}

.fa-bat::before {
  content: "\f6b5";
}

.fa-bath::before {
  content: "\f2cd";
}

.fa-bathtub::before {
  content: "\f2cd";
}

.fa-battery-bolt::before {
  content: "\f376";
}

.fa-battery-empty::before {
  content: "\f244";
}

.fa-battery-0::before {
  content: "\f244";
}

.fa-battery-exclamation::before {
  content: "\e0b0";
}

.fa-battery-full::before {
  content: "\f240";
}

.fa-battery::before {
  content: "\f240";
}

.fa-battery-5::before {
  content: "\f240";
}

.fa-battery-half::before {
  content: "\f242";
}

.fa-battery-3::before {
  content: "\f242";
}

.fa-battery-low::before {
  content: "\e0b1";
}

.fa-battery-1::before {
  content: "\e0b1";
}

.fa-battery-quarter::before {
  content: "\f243";
}

.fa-battery-2::before {
  content: "\f243";
}

.fa-battery-slash::before {
  content: "\f377";
}

.fa-battery-three-quarters::before {
  content: "\f241";
}

.fa-battery-4::before {
  content: "\f241";
}

.fa-bed::before {
  content: "\f236";
}

.fa-bed-bunk::before {
  content: "\f8f8";
}

.fa-bed-empty::before {
  content: "\f8f9";
}

.fa-bed-front::before {
  content: "\f8f7";
}

.fa-bed-alt::before {
  content: "\f8f7";
}

.fa-bed-pulse::before {
  content: "\f487";
}

.fa-procedures::before {
  content: "\f487";
}

.fa-bee::before {
  content: "\e0b2";
}

.fa-beer-mug::before {
  content: "\e0b3";
}

.fa-beer-foam::before {
  content: "\e0b3";
}

.fa-beer-mug-empty::before {
  content: "\f0fc";
}

.fa-beer::before {
  content: "\f0fc";
}

.fa-bell::before {
  content: "\f0f3";
}

.fa-bell-concierge::before {
  content: "\f562";
}

.fa-concierge-bell::before {
  content: "\f562";
}

.fa-bell-exclamation::before {
  content: "\f848";
}

.fa-bell-on::before {
  content: "\f8fa";
}

.fa-bell-plus::before {
  content: "\f849";
}

.fa-bell-school::before {
  content: "\f5d5";
}

.fa-bell-school-slash::before {
  content: "\f5d6";
}

.fa-bell-slash::before {
  content: "\f1f6";
}

.fa-bells::before {
  content: "\f77f";
}

.fa-bench-tree::before {
  content: "\e2e7";
}

.fa-bezier-curve::before {
  content: "\f55b";
}

.fa-bicycle::before {
  content: "\f206";
}

.fa-binoculars::before {
  content: "\f1e5";
}

.fa-biohazard::before {
  content: "\f780";
}

.fa-bitcoin-sign::before {
  content: "\e0b4";
}

.fa-blanket::before {
  content: "\f498";
}

.fa-blender::before {
  content: "\f517";
}

.fa-blender-phone::before {
  content: "\f6b6";
}

.fa-blinds::before {
  content: "\f8fb";
}

.fa-blinds-open::before {
  content: "\f8fc";
}

.fa-blinds-raised::before {
  content: "\f8fd";
}

.fa-block-quote::before {
  content: "\e0b5";
}

.fa-blog::before {
  content: "\f781";
}

.fa-blueberries::before {
  content: "\e2e8";
}

.fa-bold::before {
  content: "\f032";
}

.fa-bolt::before {
  content: "\f0e7";
}

.fa-flash::before {
  content: "\f0e7";
}

.fa-bolt-auto::before {
  content: "\e0b6";
}

.fa-bolt-lightning::before {
  content: "\e0b7";
}

.fa-bolt-slash::before {
  content: "\e0b8";
}

.fa-bomb::before {
  content: "\f1e2";
}

.fa-bone::before {
  content: "\f5d7";
}

.fa-bone-break::before {
  content: "\f5d8";
}

.fa-bong::before {
  content: "\f55c";
}

.fa-book::before {
  content: "\f02d";
}

.fa-book-arrow-right::before {
  content: "\e0b9";
}

.fa-book-arrow-up::before {
  content: "\e0ba";
}

.fa-book-atlas::before {
  content: "\f558";
}

.fa-atlas::before {
  content: "\f558";
}

.fa-book-bible::before {
  content: "\f647";
}

.fa-bible::before {
  content: "\f647";
}

.fa-book-blank::before {
  content: "\f5d9";
}

.fa-book-alt::before {
  content: "\f5d9";
}

.fa-book-bookmark::before {
  content: "\e0bb";
}

.fa-book-circle-arrow-right::before {
  content: "\e0bc";
}

.fa-book-circle-arrow-up::before {
  content: "\e0bd";
}

.fa-book-copy::before {
  content: "\e0be";
}

.fa-book-font::before {
  content: "\e0bf";
}

.fa-book-heart::before {
  content: "\f499";
}

.fa-book-journal-whills::before {
  content: "\f66a";
}

.fa-journal-whills::before {
  content: "\f66a";
}

.fa-book-medical::before {
  content: "\f7e6";
}

.fa-book-open::before {
  content: "\f518";
}

.fa-book-open-cover::before {
  content: "\e0c0";
}

.fa-book-open-alt::before {
  content: "\e0c0";
}

.fa-book-open-reader::before {
  content: "\f5da";
}

.fa-book-reader::before {
  content: "\f5da";
}

.fa-book-quran::before {
  content: "\f687";
}

.fa-quran::before {
  content: "\f687";
}

.fa-book-section::before {
  content: "\e0c1";
}

.fa-book-law::before {
  content: "\e0c1";
}

.fa-book-skull::before {
  content: "\f6b7";
}

.fa-book-dead::before {
  content: "\f6b7";
}

.fa-book-sparkles::before {
  content: "\f6b8";
}

.fa-book-spells::before {
  content: "\f6b8";
}

.fa-book-tanakh::before {
  content: "\f827";
}

.fa-tanakh::before {
  content: "\f827";
}

.fa-book-user::before {
  content: "\f7e7";
}

.fa-bookmark::before {
  content: "\f02e";
}

.fa-bookmark-slash::before {
  content: "\e0c2";
}

.fa-books::before {
  content: "\f5db";
}

.fa-books-medical::before {
  content: "\f7e8";
}

.fa-boombox::before {
  content: "\f8a5";
}

.fa-boot::before {
  content: "\f782";
}

.fa-booth-curtain::before {
  content: "\f734";
}

.fa-border-all::before {
  content: "\f84c";
}

.fa-border-bottom::before {
  content: "\f84d";
}

.fa-border-bottom-right::before {
  content: "\f854";
}

.fa-border-style-alt::before {
  content: "\f854";
}

.fa-border-center-h::before {
  content: "\f89c";
}

.fa-border-center-v::before {
  content: "\f89d";
}

.fa-border-inner::before {
  content: "\f84e";
}

.fa-border-left::before {
  content: "\f84f";
}

.fa-border-none::before {
  content: "\f850";
}

.fa-border-outer::before {
  content: "\f851";
}

.fa-border-right::before {
  content: "\f852";
}

.fa-border-top::before {
  content: "\f855";
}

.fa-border-top-left::before {
  content: "\f853";
}

.fa-border-style::before {
  content: "\f853";
}

.fa-bow-arrow::before {
  content: "\f6b9";
}

.fa-bowl-chopsticks::before {
  content: "\e2e9";
}

.fa-bowl-chopsticks-noodles::before {
  content: "\e2ea";
}

.fa-bowl-hot::before {
  content: "\f823";
}

.fa-soup::before {
  content: "\f823";
}

.fa-bowl-rice::before {
  content: "\e2eb";
}

.fa-bowling-ball::before {
  content: "\f436";
}

.fa-bowling-ball-pin::before {
  content: "\e0c3";
}

.fa-bowling-pins::before {
  content: "\f437";
}

.fa-box::before {
  content: "\f466";
}

.fa-box-archive::before {
  content: "\f187";
}

.fa-archive::before {
  content: "\f187";
}

.fa-box-ballot::before {
  content: "\f735";
}

.fa-box-check::before {
  content: "\f467";
}

.fa-box-circle-check::before {
  content: "\e0c4";
}

.fa-box-dollar::before {
  content: "\f4a0";
}

.fa-box-usd::before {
  content: "\f4a0";
}

.fa-box-heart::before {
  content: "\f49d";
}

.fa-box-open::before {
  content: "\f49e";
}

.fa-box-open-full::before {
  content: "\f49c";
}

.fa-box-full::before {
  content: "\f49c";
}

.fa-box-taped::before {
  content: "\f49a";
}

.fa-box-alt::before {
  content: "\f49a";
}

.fa-box-tissue::before {
  content: "\e05b";
}

.fa-boxes-stacked::before {
  content: "\f468";
}

.fa-boxes::before {
  content: "\f468";
}

.fa-boxes-alt::before {
  content: "\f468";
}

.fa-boxing-glove::before {
  content: "\f438";
}

.fa-glove-boxing::before {
  content: "\f438";
}

.fa-bracket-curly::before {
  content: "\e2ec";
}

.fa-bracket-curly-left::before {
  content: "\e2ec";
}

.fa-bracket-curly-right::before {
  content: "\e2ed";
}

.fa-bracket-round::before {
  content: "\e2ee";
}

.fa-parenthesis::before {
  content: "\e2ee";
}

.fa-bracket-round-right::before {
  content: "\e2ef";
}

.fa-bracket-square::before {
  content: "\e2f0";
}

.fa-bracket::before {
  content: "\e2f0";
}

.fa-bracket-left::before {
  content: "\e2f0";
}

.fa-bracket-square-right::before {
  content: "\e2f1";
}

.fa-brackets-curly::before {
  content: "\f7ea";
}

.fa-brackets-round::before {
  content: "\e0c5";
}

.fa-parentheses::before {
  content: "\e0c5";
}

.fa-brackets-square::before {
  content: "\f7e9";
}

.fa-brackets::before {
  content: "\f7e9";
}

.fa-braille::before {
  content: "\f2a1";
}

.fa-brain::before {
  content: "\f5dc";
}

.fa-brain-arrow-curved-right::before {
  content: "\f677";
}

.fa-mind-share::before {
  content: "\f677";
}

.fa-brain-circuit::before {
  content: "\e0c6";
}

.fa-brake-warning::before {
  content: "\e0c7";
}

.fa-bread-loaf::before {
  content: "\f7eb";
}

.fa-bread-slice::before {
  content: "\f7ec";
}

.fa-briefcase::before {
  content: "\f0b1";
}

.fa-briefcase-arrow-right::before {
  content: "\e2f2";
}

.fa-briefcase-blank::before {
  content: "\e0c8";
}

.fa-briefcase-clock::before {
  content: "\f64a";
}

.fa-business-time::before {
  content: "\f64a";
}

.fa-briefcase-medical::before {
  content: "\f469";
}

.fa-brightness::before {
  content: "\e0c9";
}

.fa-brightness-low::before {
  content: "\e0ca";
}

.fa-bring-forward::before {
  content: "\f856";
}

.fa-bring-front::before {
  content: "\f857";
}

.fa-broom::before {
  content: "\f51a";
}

.fa-browser::before {
  content: "\f37e";
}

.fa-browsers::before {
  content: "\e0cb";
}

.fa-brush::before {
  content: "\f55d";
}

.fa-bug::before {
  content: "\f188";
}

.fa-building::before {
  content: "\f1ad";
}

.fa-buildings::before {
  content: "\e0cc";
}

.fa-bullhorn::before {
  content: "\f0a1";
}

.fa-bullseye::before {
  content: "\f140";
}

.fa-bullseye-arrow::before {
  content: "\f648";
}

.fa-bullseye-pointer::before {
  content: "\f649";
}

.fa-burger::before {
  content: "\f805";
}

.fa-hamburger::before {
  content: "\f805";
}

.fa-burger-cheese::before {
  content: "\f7f1";
}

.fa-cheeseburger::before {
  content: "\f7f1";
}

.fa-burger-fries::before {
  content: "\e0cd";
}

.fa-burger-glass::before {
  content: "\e0ce";
}

.fa-burger-soda::before {
  content: "\f858";
}

.fa-burrito::before {
  content: "\f7ed";
}

.fa-bus::before {
  content: "\f207";
}

.fa-bus-school::before {
  content: "\f5dd";
}

.fa-bus-simple::before {
  content: "\f55e";
}

.fa-bus-alt::before {
  content: "\f55e";
}

.fa-c::before {
  content: "\e2f3";
}

.fa-cabinet-filing::before {
  content: "\f64b";
}

.fa-cable-car::before {
  content: "\e0cf";
}

.fa-cactus::before {
  content: "\f8a7";
}

.fa-cake-candles::before {
  content: "\f1fd";
}

.fa-birthday-cake::before {
  content: "\f1fd";
}

.fa-calculator::before {
  content: "\f1ec";
}

.fa-calculator-simple::before {
  content: "\f64c";
}

.fa-calculator-alt::before {
  content: "\f64c";
}

.fa-calendar::before {
  content: "\f133";
}

.fa-calendar-arrow-down::before {
  content: "\e0d0";
}

.fa-calendar-download::before {
  content: "\e0d0";
}

.fa-calendar-arrow-up::before {
  content: "\e0d1";
}

.fa-calendar-upload::before {
  content: "\e0d1";
}

.fa-calendar-check::before {
  content: "\f274";
}

.fa-calendar-clock::before {
  content: "\e0d2";
}

.fa-calendar-time::before {
  content: "\e0d2";
}

.fa-calendar-day::before {
  content: "\f783";
}

.fa-calendar-days::before {
  content: "\f073";
}

.fa-calendar-alt::before {
  content: "\f073";
}

.fa-calendar-exclamation::before {
  content: "\f334";
}

.fa-calendar-heart::before {
  content: "\e0d3";
}

.fa-calendar-image::before {
  content: "\e0d4";
}

.fa-calendar-lines::before {
  content: "\e0d5";
}

.fa-calendar-note::before {
  content: "\e0d5";
}

.fa-calendar-minus::before {
  content: "\f272";
}

.fa-calendar-pen::before {
  content: "\f333";
}

.fa-calendar-edit::before {
  content: "\f333";
}

.fa-calendar-plus::before {
  content: "\f271";
}

.fa-calendar-range::before {
  content: "\e0d6";
}

.fa-calendar-star::before {
  content: "\f736";
}

.fa-calendar-week::before {
  content: "\f784";
}

.fa-calendar-xmark::before {
  content: "\f273";
}

.fa-calendar-times::before {
  content: "\f273";
}

.fa-calendars::before {
  content: "\e0d7";
}

.fa-camcorder::before {
  content: "\f8a8";
}

.fa-video-handheld::before {
  content: "\f8a8";
}

.fa-camera::before {
  content: "\f030";
}

.fa-camera-alt::before {
  content: "\f030";
}

.fa-camera-cctv::before {
  content: "\f8ac";
}

.fa-cctv::before {
  content: "\f8ac";
}

.fa-camera-movie::before {
  content: "\f8a9";
}

.fa-camera-polaroid::before {
  content: "\f8aa";
}

.fa-camera-retro::before {
  content: "\f083";
}

.fa-camera-rotate::before {
  content: "\e0d8";
}

.fa-camera-security::before {
  content: "\f8fe";
}

.fa-camera-home::before {
  content: "\f8fe";
}

.fa-camera-slash::before {
  content: "\e0d9";
}

.fa-camera-viewfinder::before {
  content: "\e0da";
}

.fa-camera-web::before {
  content: "\f832";
}

.fa-webcam::before {
  content: "\f832";
}

.fa-camera-web-slash::before {
  content: "\f833";
}

.fa-webcam-slash::before {
  content: "\f833";
}

.fa-campfire::before {
  content: "\f6ba";
}

.fa-campground::before {
  content: "\f6bb";
}

.fa-candle-holder::before {
  content: "\f6bc";
}

.fa-candy-cane::before {
  content: "\f786";
}

.fa-candy-corn::before {
  content: "\f6bd";
}

.fa-cannabis::before {
  content: "\f55f";
}

.fa-capsules::before {
  content: "\f46b";
}

.fa-car::before {
  content: "\f1b9";
}

.fa-automobile::before {
  content: "\f1b9";
}

.fa-car-battery::before {
  content: "\f5df";
}

.fa-battery-car::before {
  content: "\f5df";
}

.fa-car-building::before {
  content: "\f859";
}

.fa-car-bump::before {
  content: "\f5e0";
}

.fa-car-bus::before {
  content: "\f85a";
}

.fa-car-crash::before {
  content: "\f5e1";
}

.fa-car-garage::before {
  content: "\f5e2";
}

.fa-car-rear::before {
  content: "\f5de";
}

.fa-car-alt::before {
  content: "\f5de";
}

.fa-car-side::before {
  content: "\f5e4";
}

.fa-car-tilt::before {
  content: "\f5e5";
}

.fa-car-wash::before {
  content: "\f5e6";
}

.fa-car-wrench::before {
  content: "\f5e3";
}

.fa-car-mechanic::before {
  content: "\f5e3";
}

.fa-caravan::before {
  content: "\f8ff";
}

.fa-caravan-simple::before {
  content: "\e000";
}

.fa-caravan-alt::before {
  content: "\e000";
}

.fa-caret-down::before {
  content: "\f0d7";
}

.fa-caret-left::before {
  content: "\f0d9";
}

.fa-caret-right::before {
  content: "\f0da";
}

.fa-caret-up::before {
  content: "\f0d8";
}

.fa-carrot::before {
  content: "\f787";
}

.fa-cars::before {
  content: "\f85b";
}

.fa-cart-arrow-down::before {
  content: "\f218";
}

.fa-cart-flatbed::before {
  content: "\f474";
}

.fa-dolly-flatbed::before {
  content: "\f474";
}

.fa-cart-flatbed-boxes::before {
  content: "\f475";
}

.fa-dolly-flatbed-alt::before {
  content: "\f475";
}

.fa-cart-flatbed-empty::before {
  content: "\f476";
}

.fa-dolly-flatbed-empty::before {
  content: "\f476";
}

.fa-cart-flatbed-suitcase::before {
  content: "\f59d";
}

.fa-luggage-cart::before {
  content: "\f59d";
}

.fa-cart-minus::before {
  content: "\e0db";
}

.fa-cart-plus::before {
  content: "\f217";
}

.fa-cart-shopping::before {
  content: "\f07a";
}

.fa-shopping-cart::before {
  content: "\f07a";
}

.fa-cart-shopping-fast::before {
  content: "\e0dc";
}

.fa-cart-xmark::before {
  content: "\e0dd";
}

.fa-cash-register::before {
  content: "\f788";
}

.fa-cassette-betamax::before {
  content: "\f8a4";
}

.fa-betamax::before {
  content: "\f8a4";
}

.fa-cassette-tape::before {
  content: "\f8ab";
}

.fa-cassette-vhs::before {
  content: "\f8ec";
}

.fa-vhs::before {
  content: "\f8ec";
}

.fa-castle::before {
  content: "\e0de";
}

.fa-cat::before {
  content: "\f6be";
}

.fa-cat-space::before {
  content: "\e001";
}

.fa-cauldron::before {
  content: "\f6bf";
}

.fa-cedi-sign::before {
  content: "\e0df";
}

.fa-cent-sign::before {
  content: "\e0e0";
}

.fa-certificate::before {
  content: "\f0a3";
}

.fa-chair::before {
  content: "\f6c0";
}

.fa-chair-office::before {
  content: "\f6c1";
}

.fa-chalkboard::before {
  content: "\f51b";
}

.fa-blackboard::before {
  content: "\f51b";
}

.fa-chalkboard-user::before {
  content: "\f51c";
}

.fa-chalkboard-teacher::before {
  content: "\f51c";
}

.fa-champagne-glass::before {
  content: "\f79e";
}

.fa-glass-champagne::before {
  content: "\f79e";
}

.fa-champagne-glasses::before {
  content: "\f79f";
}

.fa-glass-cheers::before {
  content: "\f79f";
}

.fa-charging-station::before {
  content: "\f5e7";
}

.fa-chart-area::before {
  content: "\f1fe";
}

.fa-area-chart::before {
  content: "\f1fe";
}

.fa-chart-bar::before {
  content: "\f080";
}

.fa-bar-chart::before {
  content: "\f080";
}

.fa-chart-bullet::before {
  content: "\e0e1";
}

.fa-chart-candlestick::before {
  content: "\e0e2";
}

.fa-chart-column::before {
  content: "\e0e3";
}

.fa-chart-gantt::before {
  content: "\e0e4";
}

.fa-chart-line::before {
  content: "\f201";
}

.fa-line-chart::before {
  content: "\f201";
}

.fa-chart-line-down::before {
  content: "\f64d";
}

.fa-chart-line-up::before {
  content: "\e0e5";
}

.fa-chart-mixed::before {
  content: "\f643";
}

.fa-analytics::before {
  content: "\f643";
}

.fa-chart-network::before {
  content: "\f78a";
}

.fa-chart-pie::before {
  content: "\f200";
}

.fa-pie-chart::before {
  content: "\f200";
}

.fa-chart-pie-simple::before {
  content: "\f64e";
}

.fa-chart-pie-alt::before {
  content: "\f64e";
}

.fa-chart-pyramid::before {
  content: "\e0e6";
}

.fa-chart-radar::before {
  content: "\e0e7";
}

.fa-chart-scatter::before {
  content: "\f7ee";
}

.fa-chart-scatter-3d::before {
  content: "\e0e8";
}

.fa-chart-scatter-bubble::before {
  content: "\e0e9";
}

.fa-chart-tree-map::before {
  content: "\e0ea";
}

.fa-chart-user::before {
  content: "\f6a3";
}

.fa-user-chart::before {
  content: "\f6a3";
}

.fa-chart-waterfall::before {
  content: "\e0eb";
}

.fa-check::before {
  content: "\f00c";
}

.fa-check-double::before {
  content: "\f560";
}

.fa-check-to-slot::before {
  content: "\f772";
}

.fa-vote-yea::before {
  content: "\f772";
}

.fa-cheese::before {
  content: "\f7ef";
}

.fa-cheese-swiss::before {
  content: "\f7f0";
}

.fa-cherries::before {
  content: "\e0ec";
}

.fa-chess::before {
  content: "\f439";
}

.fa-chess-bishop::before {
  content: "\f43a";
}

.fa-chess-bishop-piece::before {
  content: "\f43b";
}

.fa-chess-bishop-alt::before {
  content: "\f43b";
}

.fa-chess-board::before {
  content: "\f43c";
}

.fa-chess-clock::before {
  content: "\f43d";
}

.fa-chess-clock-flip::before {
  content: "\f43e";
}

.fa-chess-clock-alt::before {
  content: "\f43e";
}

.fa-chess-king::before {
  content: "\f43f";
}

.fa-chess-king-piece::before {
  content: "\f440";
}

.fa-chess-king-alt::before {
  content: "\f440";
}

.fa-chess-knight::before {
  content: "\f441";
}

.fa-chess-knight-piece::before {
  content: "\f442";
}

.fa-chess-knight-alt::before {
  content: "\f442";
}

.fa-chess-pawn::before {
  content: "\f443";
}

.fa-chess-pawn-piece::before {
  content: "\f444";
}

.fa-chess-pawn-alt::before {
  content: "\f444";
}

.fa-chess-queen::before {
  content: "\f445";
}

.fa-chess-queen-piece::before {
  content: "\f446";
}

.fa-chess-queen-alt::before {
  content: "\f446";
}

.fa-chess-rook::before {
  content: "\f447";
}

.fa-chess-rook-piece::before {
  content: "\f448";
}

.fa-chess-rook-alt::before {
  content: "\f448";
}

.fa-chevron-down::before {
  content: "\f078";
}

.fa-chevron-left::before {
  content: "\f053";
}

.fa-chevron-right::before {
  content: "\f054";
}

.fa-chevron-up::before {
  content: "\f077";
}

.fa-chevrons-down::before {
  content: "\f322";
}

.fa-chevron-double-down::before {
  content: "\f322";
}

.fa-chevrons-left::before {
  content: "\f323";
}

.fa-chevron-double-left::before {
  content: "\f323";
}

.fa-chevrons-right::before {
  content: "\f324";
}

.fa-chevron-double-right::before {
  content: "\f324";
}

.fa-chevrons-up::before {
  content: "\f325";
}

.fa-chevron-double-up::before {
  content: "\f325";
}

.fa-child::before {
  content: "\f1ae";
}

.fa-chimney::before {
  content: "\f78b";
}

.fa-church::before {
  content: "\f51d";
}

.fa-circle::before {
  content: "\f111";
}

.fa-circle-0::before {
  content: "\e0ed";
}

.fa-circle-1::before {
  content: "\e0ee";
}

.fa-circle-2::before {
  content: "\e0ef";
}

.fa-circle-3::before {
  content: "\e0f0";
}

.fa-circle-4::before {
  content: "\e0f1";
}

.fa-circle-5::before {
  content: "\e0f2";
}

.fa-circle-6::before {
  content: "\e0f3";
}

.fa-circle-7::before {
  content: "\e0f4";
}

.fa-circle-8::before {
  content: "\e0f5";
}

.fa-circle-9::before {
  content: "\e0f6";
}

.fa-circle-a::before {
  content: "\e0f7";
}

.fa-circle-ampersand::before {
  content: "\e0f8";
}

.fa-circle-arrow-down::before {
  content: "\f0ab";
}

.fa-arrow-circle-down::before {
  content: "\f0ab";
}

.fa-circle-arrow-down-left::before {
  content: "\e0f9";
}

.fa-circle-arrow-down-right::before {
  content: "\e0fa";
}

.fa-circle-arrow-left::before {
  content: "\f0a8";
}

.fa-arrow-circle-left::before {
  content: "\f0a8";
}

.fa-circle-arrow-right::before {
  content: "\f0a9";
}

.fa-arrow-circle-right::before {
  content: "\f0a9";
}

.fa-circle-arrow-up::before {
  content: "\f0aa";
}

.fa-arrow-circle-up::before {
  content: "\f0aa";
}

.fa-circle-arrow-up-left::before {
  content: "\e0fb";
}

.fa-circle-arrow-up-right::before {
  content: "\e0fc";
}

.fa-circle-b::before {
  content: "\e0fd";
}

.fa-circle-bolt::before {
  content: "\e0fe";
}

.fa-circle-book-open::before {
  content: "\e0ff";
}

.fa-book-circle::before {
  content: "\e0ff";
}

.fa-circle-bookmark::before {
  content: "\e100";
}

.fa-bookmark-circle::before {
  content: "\e100";
}

.fa-circle-c::before {
  content: "\e101";
}

.fa-circle-calendar::before {
  content: "\e102";
}

.fa-calendar-circle::before {
  content: "\e102";
}

.fa-circle-camera::before {
  content: "\e103";
}

.fa-camera-circle::before {
  content: "\e103";
}

.fa-circle-caret-down::before {
  content: "\f32d";
}

.fa-caret-circle-down::before {
  content: "\f32d";
}

.fa-circle-caret-left::before {
  content: "\f32e";
}

.fa-caret-circle-left::before {
  content: "\f32e";
}

.fa-circle-caret-right::before {
  content: "\f330";
}

.fa-caret-circle-right::before {
  content: "\f330";
}

.fa-circle-caret-up::before {
  content: "\f331";
}

.fa-caret-circle-up::before {
  content: "\f331";
}

.fa-circle-check::before {
  content: "\f058";
}

.fa-check-circle::before {
  content: "\f058";
}

.fa-circle-chevron-down::before {
  content: "\f13a";
}

.fa-chevron-circle-down::before {
  content: "\f13a";
}

.fa-circle-chevron-left::before {
  content: "\f137";
}

.fa-chevron-circle-left::before {
  content: "\f137";
}

.fa-circle-chevron-right::before {
  content: "\f138";
}

.fa-chevron-circle-right::before {
  content: "\f138";
}

.fa-circle-chevron-up::before {
  content: "\f139";
}

.fa-chevron-circle-up::before {
  content: "\f139";
}

.fa-circle-d::before {
  content: "\e104";
}

.fa-circle-dashed::before {
  content: "\e105";
}

.fa-circle-divide::before {
  content: "\e106";
}

.fa-circle-dollar::before {
  content: "\f2e8";
}

.fa-dollar-circle::before {
  content: "\f2e8";
}

.fa-usd-circle::before {
  content: "\f2e8";
}

.fa-circle-dollar-to-slot::before {
  content: "\f4b9";
}

.fa-donate::before {
  content: "\f4b9";
}

.fa-circle-dot::before {
  content: "\f192";
}

.fa-dot-circle::before {
  content: "\f192";
}

.fa-circle-down::before {
  content: "\f358";
}

.fa-arrow-alt-circle-down::before {
  content: "\f358";
}

.fa-circle-down-left::before {
  content: "\e107";
}

.fa-circle-down-right::before {
  content: "\e108";
}

.fa-circle-e::before {
  content: "\e109";
}

.fa-circle-ellipsis::before {
  content: "\e10a";
}

.fa-circle-ellipsis-vertical::before {
  content: "\e10b";
}

.fa-circle-envelope::before {
  content: "\e10c";
}

.fa-envelope-circle::before {
  content: "\e10c";
}

.fa-circle-exclamation::before {
  content: "\f06a";
}

.fa-exclamation-circle::before {
  content: "\f06a";
}

.fa-circle-exclamation-check::before {
  content: "\e10d";
}

.fa-circle-f::before {
  content: "\e10e";
}

.fa-circle-g::before {
  content: "\e10f";
}

.fa-circle-h::before {
  content: "\f47e";
}

.fa-hospital-symbol::before {
  content: "\f47e";
}

.fa-circle-half::before {
  content: "\e110";
}

.fa-circle-half-stroke::before {
  content: "\f042";
}

.fa-adjust::before {
  content: "\f042";
}

.fa-circle-heart::before {
  content: "\f4c7";
}

.fa-heart-circle::before {
  content: "\f4c7";
}

.fa-circle-i::before {
  content: "\e111";
}

.fa-circle-info::before {
  content: "\f05a";
}

.fa-info-circle::before {
  content: "\f05a";
}

.fa-circle-j::before {
  content: "\e112";
}

.fa-circle-k::before {
  content: "\e113";
}

.fa-circle-l::before {
  content: "\e114";
}

.fa-circle-left::before {
  content: "\f359";
}

.fa-arrow-alt-circle-left::before {
  content: "\f359";
}

.fa-circle-location-arrow::before {
  content: "\f602";
}

.fa-location-circle::before {
  content: "\f602";
}

.fa-circle-m::before {
  content: "\e115";
}

.fa-circle-microphone::before {
  content: "\e116";
}

.fa-microphone-circle::before {
  content: "\e116";
}

.fa-circle-microphone-lines::before {
  content: "\e117";
}

.fa-microphone-circle-alt::before {
  content: "\e117";
}

.fa-circle-minus::before {
  content: "\f056";
}

.fa-minus-circle::before {
  content: "\f056";
}

.fa-circle-n::before {
  content: "\e118";
}

.fa-circle-notch::before {
  content: "\f1ce";
}

.fa-circle-o::before {
  content: "\e119";
}

.fa-circle-p::before {
  content: "\e11a";
}

.fa-circle-parking::before {
  content: "\f615";
}

.fa-parking-circle::before {
  content: "\f615";
}

.fa-circle-pause::before {
  content: "\f28b";
}

.fa-pause-circle::before {
  content: "\f28b";
}

.fa-circle-phone::before {
  content: "\e11b";
}

.fa-phone-circle::before {
  content: "\e11b";
}

.fa-circle-phone-flip::before {
  content: "\e11c";
}

.fa-phone-circle-alt::before {
  content: "\e11c";
}

.fa-circle-phone-hangup::before {
  content: "\e11d";
}

.fa-phone-circle-down::before {
  content: "\e11d";
}

.fa-circle-play::before {
  content: "\f144";
}

.fa-play-circle::before {
  content: "\f144";
}

.fa-circle-plus::before {
  content: "\f055";
}

.fa-plus-circle::before {
  content: "\f055";
}

.fa-circle-q::before {
  content: "\e11e";
}

.fa-circle-quarter::before {
  content: "\e11f";
}

.fa-circle-question::before {
  content: "\f059";
}

.fa-question-circle::before {
  content: "\f059";
}

.fa-circle-r::before {
  content: "\e120";
}

.fa-circle-radiation::before {
  content: "\f7ba";
}

.fa-radiation-alt::before {
  content: "\f7ba";
}

.fa-circle-right::before {
  content: "\f35a";
}

.fa-arrow-alt-circle-right::before {
  content: "\f35a";
}

.fa-circle-s::before {
  content: "\e121";
}

.fa-circle-small::before {
  content: "\e122";
}

.fa-circle-sort::before {
  content: "\e030";
}

.fa-sort-circle::before {
  content: "\e030";
}

.fa-circle-sort-down::before {
  content: "\e031";
}

.fa-sort-circle-down::before {
  content: "\e031";
}

.fa-circle-sort-up::before {
  content: "\e032";
}

.fa-sort-circle-up::before {
  content: "\e032";
}

.fa-circle-star::before {
  content: "\e123";
}

.fa-star-circle::before {
  content: "\e123";
}

.fa-circle-stop::before {
  content: "\f28d";
}

.fa-stop-circle::before {
  content: "\f28d";
}

.fa-circle-t::before {
  content: "\e124";
}

.fa-circle-three-quarters::before {
  content: "\e125";
}

.fa-circle-trash::before {
  content: "\e126";
}

.fa-trash-circle::before {
  content: "\e126";
}

.fa-circle-u::before {
  content: "\e127";
}

.fa-circle-up::before {
  content: "\f35b";
}

.fa-arrow-alt-circle-up::before {
  content: "\f35b";
}

.fa-circle-up-left::before {
  content: "\e128";
}

.fa-circle-up-right::before {
  content: "\e129";
}

.fa-circle-user::before {
  content: "\f2bd";
}

.fa-user-circle::before {
  content: "\f2bd";
}

.fa-circle-v::before {
  content: "\e12a";
}

.fa-circle-video::before {
  content: "\e12b";
}

.fa-video-circle::before {
  content: "\e12b";
}

.fa-circle-w::before {
  content: "\e12c";
}

.fa-circle-waveform-lines::before {
  content: "\e12d";
}

.fa-waveform-circle::before {
  content: "\e12d";
}

.fa-circle-x::before {
  content: "\e12e";
}

.fa-circle-xmark::before {
  content: "\f057";
}

.fa-times-circle::before {
  content: "\f057";
}

.fa-xmark-circle::before {
  content: "\f057";
}

.fa-circle-y::before {
  content: "\e12f";
}

.fa-circle-z::before {
  content: "\e130";
}

.fa-citrus::before {
  content: "\e2f4";
}

.fa-citrus-slice::before {
  content: "\e2f5";
}

.fa-city::before {
  content: "\f64f";
}

.fa-clapperboard::before {
  content: "\e131";
}

.fa-clapperboard-play::before {
  content: "\e132";
}

.fa-clarinet::before {
  content: "\f8ad";
}

.fa-claw-marks::before {
  content: "\f6c2";
}

.fa-clipboard::before {
  content: "\f328";
}

.fa-clipboard-check::before {
  content: "\f46c";
}

.fa-clipboard-list::before {
  content: "\f46d";
}

.fa-clipboard-list-check::before {
  content: "\f737";
}

.fa-clipboard-medical::before {
  content: "\e133";
}

.fa-clipboard-prescription::before {
  content: "\f5e8";
}

.fa-clipboard-user::before {
  content: "\f7f3";
}

.fa-clock::before {
  content: "\f017";
}

.fa-clock-desk::before {
  content: "\e134";
}

.fa-clock-rotate-left::before {
  content: "\f1da";
}

.fa-history::before {
  content: "\f1da";
}

.fa-clone::before {
  content: "\f24d";
}

.fa-closed-captioning::before {
  content: "\f20a";
}

.fa-closed-captioning-slash::before {
  content: "\e135";
}

.fa-clothes-hanger::before {
  content: "\e136";
}

.fa-cloud::before {
  content: "\f0c2";
}

.fa-cloud-arrow-down::before {
  content: "\f0ed";
}

.fa-cloud-download::before {
  content: "\f0ed";
}

.fa-cloud-download-alt::before {
  content: "\f0ed";
}

.fa-cloud-arrow-up::before {
  content: "\f0ee";
}

.fa-cloud-upload::before {
  content: "\f0ee";
}

.fa-cloud-upload-alt::before {
  content: "\f0ee";
}

.fa-cloud-bolt::before {
  content: "\f76c";
}

.fa-thunderstorm::before {
  content: "\f76c";
}

.fa-cloud-bolt-moon::before {
  content: "\f76d";
}

.fa-thunderstorm-moon::before {
  content: "\f76d";
}

.fa-cloud-bolt-sun::before {
  content: "\f76e";
}

.fa-thunderstorm-sun::before {
  content: "\f76e";
}

.fa-cloud-drizzle::before {
  content: "\f738";
}

.fa-cloud-fog::before {
  content: "\f74e";
}

.fa-fog::before {
  content: "\f74e";
}

.fa-cloud-hail::before {
  content: "\f739";
}

.fa-cloud-hail-mixed::before {
  content: "\f73a";
}

.fa-cloud-meatball::before {
  content: "\f73b";
}

.fa-cloud-moon::before {
  content: "\f6c3";
}

.fa-cloud-moon-rain::before {
  content: "\f73c";
}

.fa-cloud-music::before {
  content: "\f8ae";
}

.fa-cloud-rain::before {
  content: "\f73d";
}

.fa-cloud-rainbow::before {
  content: "\f73e";
}

.fa-cloud-showers::before {
  content: "\f73f";
}

.fa-cloud-showers-heavy::before {
  content: "\f740";
}

.fa-cloud-slash::before {
  content: "\e137";
}

.fa-cloud-sleet::before {
  content: "\f741";
}

.fa-cloud-snow::before {
  content: "\f742";
}

.fa-cloud-sun::before {
  content: "\f6c4";
}

.fa-cloud-sun-rain::before {
  content: "\f743";
}

.fa-cloud-word::before {
  content: "\e138";
}

.fa-clouds::before {
  content: "\f744";
}

.fa-clouds-moon::before {
  content: "\f745";
}

.fa-clouds-sun::before {
  content: "\f746";
}

.fa-clover::before {
  content: "\e139";
}

.fa-club::before {
  content: "\f327";
}

.fa-coconut::before {
  content: "\e2f6";
}

.fa-code::before {
  content: "\f121";
}

.fa-code-branch::before {
  content: "\f126";
}

.fa-code-commit::before {
  content: "\f386";
}

.fa-code-compare::before {
  content: "\e13a";
}

.fa-code-fork::before {
  content: "\e13b";
}

.fa-code-merge::before {
  content: "\f387";
}

.fa-code-pull-request::before {
  content: "\e13c";
}

.fa-code-simple::before {
  content: "\e13d";
}

.fa-coffee-bean::before {
  content: "\e13e";
}

.fa-coffee-beans::before {
  content: "\e13f";
}

.fa-coffee-pot::before {
  content: "\e002";
}

.fa-coffin::before {
  content: "\f6c6";
}

.fa-coffin-cross::before {
  content: "\e051";
}

.fa-coin::before {
  content: "\f85c";
}

.fa-coins::before {
  content: "\f51e";
}

.fa-colon::before {
  content: "\e2f7";
}

.fa-colon-sign::before {
  content: "\e140";
}

.fa-comet::before {
  content: "\e003";
}

.fa-comma::before {
  content: "\e141";
}

.fa-command::before {
  content: "\e142";
}

.fa-comment::before {
  content: "\f075";
}

.fa-comment-arrow-down::before {
  content: "\e143";
}

.fa-comment-arrow-up::before {
  content: "\e144";
}

.fa-comment-arrow-up-right::before {
  content: "\e145";
}

.fa-comment-captions::before {
  content: "\e146";
}

.fa-comment-check::before {
  content: "\f4ac";
}

.fa-comment-code::before {
  content: "\e147";
}

.fa-comment-dollar::before {
  content: "\f651";
}

.fa-comment-dots::before {
  content: "\f4ad";
}

.fa-commenting::before {
  content: "\f4ad";
}

.fa-comment-exclamation::before {
  content: "\f4af";
}

.fa-comment-image::before {
  content: "\e148";
}

.fa-comment-lines::before {
  content: "\f4b0";
}

.fa-comment-medical::before {
  content: "\f7f5";
}

.fa-comment-middle::before {
  content: "\e149";
}

.fa-comment-middle-top::before {
  content: "\e14a";
}

.fa-comment-minus::before {
  content: "\f4b1";
}

.fa-comment-music::before {
  content: "\f8b0";
}

.fa-comment-pen::before {
  content: "\f4ae";
}

.fa-comment-edit::before {
  content: "\f4ae";
}

.fa-comment-plus::before {
  content: "\f4b2";
}

.fa-comment-question::before {
  content: "\e14b";
}

.fa-comment-quote::before {
  content: "\e14c";
}

.fa-comment-slash::before {
  content: "\f4b3";
}

.fa-comment-smile::before {
  content: "\f4b4";
}

.fa-comment-sms::before {
  content: "\f7cd";
}

.fa-sms::before {
  content: "\f7cd";
}

.fa-comment-text::before {
  content: "\e14d";
}

.fa-comment-xmark::before {
  content: "\f4b5";
}

.fa-comment-times::before {
  content: "\f4b5";
}

.fa-comments::before {
  content: "\f086";
}

.fa-comments-dollar::before {
  content: "\f653";
}

.fa-comments-question::before {
  content: "\e14e";
}

.fa-comments-question-check::before {
  content: "\e14f";
}

.fa-compact-disc::before {
  content: "\f51f";
}

.fa-compass::before {
  content: "\f14e";
}

.fa-compass-drafting::before {
  content: "\f568";
}

.fa-drafting-compass::before {
  content: "\f568";
}

.fa-compass-slash::before {
  content: "\f5e9";
}

.fa-compress::before {
  content: "\f066";
}

.fa-compress-wide::before {
  content: "\f326";
}

.fa-computer-classic::before {
  content: "\f8b1";
}

.fa-computer-mouse::before {
  content: "\f8cc";
}

.fa-mouse::before {
  content: "\f8cc";
}

.fa-computer-mouse-scrollwheel::before {
  content: "\f8cd";
}

.fa-mouse-alt::before {
  content: "\f8cd";
}

.fa-computer-speaker::before {
  content: "\f8b2";
}

.fa-container-storage::before {
  content: "\f4b7";
}

.fa-conveyor-belt::before {
  content: "\f46e";
}

.fa-conveyor-belt-boxes::before {
  content: "\f46f";
}

.fa-conveyor-belt-alt::before {
  content: "\f46f";
}

.fa-conveyor-belt-empty::before {
  content: "\e150";
}

.fa-cookie::before {
  content: "\f563";
}

.fa-cookie-bite::before {
  content: "\f564";
}

.fa-copy::before {
  content: "\f0c5";
}

.fa-copyright::before {
  content: "\f1f9";
}

.fa-corn::before {
  content: "\f6c7";
}

.fa-corner::before {
  content: "\e2f8";
}

.fa-couch::before {
  content: "\f4b8";
}

.fa-cow::before {
  content: "\f6c8";
}

.fa-cowbell::before {
  content: "\f8b3";
}

.fa-cowbell-circle-plus::before {
  content: "\f8b4";
}

.fa-cowbell-more::before {
  content: "\f8b4";
}

.fa-crate-apple::before {
  content: "\f6b1";
}

.fa-apple-crate::before {
  content: "\f6b1";
}

.fa-crate-empty::before {
  content: "\e151";
}

.fa-credit-card::before {
  content: "\f09d";
}

.fa-credit-card-alt::before {
  content: "\f09d";
}

.fa-credit-card-blank::before {
  content: "\f389";
}

.fa-credit-card-front::before {
  content: "\f38a";
}

.fa-cricket-bat-ball::before {
  content: "\f449";
}

.fa-cricket::before {
  content: "\f449";
}

.fa-croissant::before {
  content: "\f7f6";
}

.fa-crop::before {
  content: "\f125";
}

.fa-crop-simple::before {
  content: "\f565";
}

.fa-crop-alt::before {
  content: "\f565";
}

.fa-cross::before {
  content: "\f654";
}

.fa-crosshairs::before {
  content: "\f05b";
}

.fa-crow::before {
  content: "\f520";
}

.fa-crown::before {
  content: "\f521";
}

.fa-crutch::before {
  content: "\f7f7";
}

.fa-crutches::before {
  content: "\f7f8";
}

.fa-cruzeiro-sign::before {
  content: "\e152";
}

.fa-cube::before {
  content: "\f1b2";
}

.fa-cubes::before {
  content: "\f1b3";
}

.fa-cup-togo::before {
  content: "\f6c5";
}

.fa-coffee-togo::before {
  content: "\f6c5";
}

.fa-curling-stone::before {
  content: "\f44a";
}

.fa-curling::before {
  content: "\f44a";
}

.fa-d::before {
  content: "\e2f9";
}

.fa-dagger::before {
  content: "\f6cb";
}

.fa-dash::before {
  content: "\e153";
}

.fa-database::before {
  content: "\f1c0";
}

.fa-deer::before {
  content: "\f78e";
}

.fa-deer-rudolph::before {
  content: "\f78f";
}

.fa-delete-left::before {
  content: "\f55a";
}

.fa-backspace::before {
  content: "\f55a";
}

.fa-delete-right::before {
  content: "\e154";
}

.fa-democrat::before {
  content: "\f747";
}

.fa-desktop::before {
  content: "\f108";
}

.fa-desktop-alt::before {
  content: "\f108";
}

.fa-desktop-arrow-down::before {
  content: "\e155";
}

.fa-dharmachakra::before {
  content: "\f655";
}

.fa-diagram-lean-canvas::before {
  content: "\e156";
}

.fa-diagram-nested::before {
  content: "\e157";
}

.fa-diagram-project::before {
  content: "\f542";
}

.fa-project-diagram::before {
  content: "\f542";
}

.fa-diagram-sankey::before {
  content: "\e158";
}

.fa-diagram-venn::before {
  content: "\e15a";
}

.fa-dial::before {
  content: "\e15b";
}

.fa-dial-med-high::before {
  content: "\e15b";
}

.fa-dial-high::before {
  content: "\e15c";
}

.fa-dial-low::before {
  content: "\e15d";
}

.fa-dial-max::before {
  content: "\e15e";
}

.fa-dial-med::before {
  content: "\e15f";
}

.fa-dial-med-low::before {
  content: "\e160";
}

.fa-dial-min::before {
  content: "\e161";
}

.fa-dial-off::before {
  content: "\e162";
}

.fa-diamond::before {
  content: "\f219";
}

.fa-diamond-turn-right::before {
  content: "\f5eb";
}

.fa-directions::before {
  content: "\f5eb";
}

.fa-dice::before {
  content: "\f522";
}

.fa-dice-d10::before {
  content: "\f6cd";
}

.fa-dice-d12::before {
  content: "\f6ce";
}

.fa-dice-d20::before {
  content: "\f6cf";
}

.fa-dice-d4::before {
  content: "\f6d0";
}

.fa-dice-d6::before {
  content: "\f6d1";
}

.fa-dice-d8::before {
  content: "\f6d2";
}

.fa-dice-five::before {
  content: "\f523";
}

.fa-dice-four::before {
  content: "\f524";
}

.fa-dice-one::before {
  content: "\f525";
}

.fa-dice-six::before {
  content: "\f526";
}

.fa-dice-three::before {
  content: "\f527";
}

.fa-dice-two::before {
  content: "\f528";
}

.fa-diploma::before {
  content: "\f5ea";
}

.fa-scroll-ribbon::before {
  content: "\f5ea";
}

.fa-disc-drive::before {
  content: "\f8b5";
}

.fa-disease::before {
  content: "\f7fa";
}

.fa-display::before {
  content: "\e163";
}

.fa-display-arrow-down::before {
  content: "\e164";
}

.fa-display-code::before {
  content: "\e165";
}

.fa-desktop-code::before {
  content: "\e165";
}

.fa-display-medical::before {
  content: "\e166";
}

.fa-desktop-medical::before {
  content: "\e166";
}

.fa-display-slash::before {
  content: "\e2fa";
}

.fa-desktop-slash::before {
  content: "\e2fa";
}

.fa-ditto::before {
  content: "\e2fb";
}

.fa-divide::before {
  content: "\f529";
}

.fa-dna::before {
  content: "\f471";
}

.fa-do-not-enter::before {
  content: "\f5ec";
}

.fa-dog::before {
  content: "\f6d3";
}

.fa-dog-leashed::before {
  content: "\f6d4";
}

.fa-dollar-sign::before {
  content: "\f155";
}

.fa-dollar::before {
  content: "\f155";
}

.fa-usd::before {
  content: "\f155";
}

.fa-dolly::before {
  content: "\f472";
}

.fa-dolly-box::before {
  content: "\f472";
}

.fa-dolly-empty::before {
  content: "\f473";
}

.fa-dolphin::before {
  content: "\e168";
}

.fa-dong-sign::before {
  content: "\e169";
}

.fa-door-closed::before {
  content: "\f52a";
}

.fa-door-open::before {
  content: "\f52b";
}

.fa-dove::before {
  content: "\f4ba";
}

.fa-down::before {
  content: "\f354";
}

.fa-arrow-alt-down::before {
  content: "\f354";
}

.fa-down-from-line::before {
  content: "\f349";
}

.fa-arrow-alt-from-top::before {
  content: "\f349";
}

.fa-down-left::before {
  content: "\e16a";
}

.fa-down-left-and-up-right-to-center::before {
  content: "\f422";
}

.fa-compress-alt::before {
  content: "\f422";
}

.fa-down-long::before {
  content: "\f309";
}

.fa-long-arrow-alt-down::before {
  content: "\f309";
}

.fa-down-right::before {
  content: "\e16b";
}

.fa-down-to-line::before {
  content: "\f34a";
}

.fa-arrow-alt-to-bottom::before {
  content: "\f34a";
}

.fa-download::before {
  content: "\f019";
}

.fa-dragon::before {
  content: "\f6d5";
}

.fa-draw-circle::before {
  content: "\f5ed";
}

.fa-draw-polygon::before {
  content: "\f5ee";
}

.fa-draw-square::before {
  content: "\f5ef";
}

.fa-dreidel::before {
  content: "\f792";
}

.fa-drone::before {
  content: "\f85f";
}

.fa-drone-front::before {
  content: "\f860";
}

.fa-drone-alt::before {
  content: "\f860";
}

.fa-droplet::before {
  content: "\f043";
}

.fa-tint::before {
  content: "\f043";
}

.fa-droplet-degree::before {
  content: "\f748";
}

.fa-dewpoint::before {
  content: "\f748";
}

.fa-droplet-percent::before {
  content: "\f750";
}

.fa-humidity::before {
  content: "\f750";
}

.fa-droplet-slash::before {
  content: "\f5c7";
}

.fa-tint-slash::before {
  content: "\f5c7";
}

.fa-drum::before {
  content: "\f569";
}

.fa-drum-steelpan::before {
  content: "\f56a";
}

.fa-drumstick::before {
  content: "\f6d6";
}

.fa-drumstick-bite::before {
  content: "\f6d7";
}

.fa-dryer::before {
  content: "\f861";
}

.fa-dryer-heat::before {
  content: "\f862";
}

.fa-dryer-alt::before {
  content: "\f862";
}

.fa-duck::before {
  content: "\f6d8";
}

.fa-dumbbell::before {
  content: "\f44b";
}

.fa-dumpster::before {
  content: "\f793";
}

.fa-dumpster-fire::before {
  content: "\f794";
}

.fa-dungeon::before {
  content: "\f6d9";
}

.fa-e::before {
  content: "\e2fc";
}

.fa-ear::before {
  content: "\f5f0";
}

.fa-ear-deaf::before {
  content: "\f2a4";
}

.fa-deaf::before {
  content: "\f2a4";
}

.fa-deafness::before {
  content: "\f2a4";
}

.fa-hard-of-hearing::before {
  content: "\f2a4";
}

.fa-ear-listen::before {
  content: "\f2a2";
}

.fa-assistive-listening-systems::before {
  content: "\f2a2";
}

.fa-ear-muffs::before {
  content: "\f795";
}

.fa-earth-africa::before {
  content: "\f57c";
}

.fa-globe-africa::before {
  content: "\f57c";
}

.fa-earth-americas::before {
  content: "\f57d";
}

.fa-earth::before {
  content: "\f57d";
}

.fa-globe-americas::before {
  content: "\f57d";
}

.fa-earth-asia::before {
  content: "\f57e";
}

.fa-globe-asia::before {
  content: "\f57e";
}

.fa-earth-europa::before {
  content: "\f7a2";
}

.fa-globe-europe::before {
  content: "\f7a2";
}

.fa-eclipse::before {
  content: "\f749";
}

.fa-egg::before {
  content: "\f7fb";
}

.fa-egg-fried::before {
  content: "\f7fc";
}

.fa-eggplant::before {
  content: "\e16c";
}

.fa-eject::before {
  content: "\f052";
}

.fa-elephant::before {
  content: "\f6da";
}

.fa-elevator::before {
  content: "\e16d";
}

.fa-ellipsis::before {
  content: "\f141";
}

.fa-ellipsis-h::before {
  content: "\f141";
}

.fa-ellipsis-stroke::before {
  content: "\f39b";
}

.fa-ellipsis-h-alt::before {
  content: "\f39b";
}

.fa-ellipsis-stroke-vertical::before {
  content: "\f39c";
}

.fa-ellipsis-v-alt::before {
  content: "\f39c";
}

.fa-ellipsis-vertical::before {
  content: "\f142";
}

.fa-ellipsis-v::before {
  content: "\f142";
}

.fa-empty-set::before {
  content: "\f656";
}

.fa-engine::before {
  content: "\e16e";
}

.fa-engine-warning::before {
  content: "\f5f2";
}

.fa-engine-exclamation::before {
  content: "\f5f2";
}

.fa-envelope::before {
  content: "\f0e0";
}

.fa-envelope-dot::before {
  content: "\e16f";
}

.fa-envelope-badge::before {
  content: "\e16f";
}

.fa-envelope-open::before {
  content: "\f2b6";
}

.fa-envelope-open-dollar::before {
  content: "\f657";
}

.fa-envelope-open-text::before {
  content: "\f658";
}

.fa-envelopes::before {
  content: "\e170";
}

.fa-envelopes-bulk::before {
  content: "\f674";
}

.fa-mail-bulk::before {
  content: "\f674";
}

.fa-equals::before {
  content: "\f52c";
}

.fa-eraser::before {
  content: "\f12d";
}

.fa-escalator::before {
  content: "\e171";
}

.fa-ethernet::before {
  content: "\f796";
}

.fa-euro-sign::before {
  content: "\f153";
}

.fa-eur::before {
  content: "\f153";
}

.fa-euro::before {
  content: "\f153";
}

.fa-exclamation::before {
  content: "\f12a";
}

.fa-expand::before {
  content: "\f065";
}

.fa-expand-wide::before {
  content: "\f320";
}

.fa-eye::before {
  content: "\f06e";
}

.fa-eye-dropper::before {
  content: "\f1fb";
}

.fa-eye-dropper-empty::before {
  content: "\f1fb";
}

.fa-eyedropper::before {
  content: "\f1fb";
}

.fa-eye-dropper-full::before {
  content: "\e172";
}

.fa-eye-dropper-half::before {
  content: "\e173";
}

.fa-eye-evil::before {
  content: "\f6db";
}

.fa-eye-low-vision::before {
  content: "\f2a8";
}

.fa-low-vision::before {
  content: "\f2a8";
}

.fa-eye-slash::before {
  content: "\f070";
}

.fa-f::before {
  content: "\e2fd";
}

.fa-face-angry::before {
  content: "\f556";
}

.fa-angry::before {
  content: "\f556";
}

.fa-face-dizzy::before {
  content: "\f567";
}

.fa-dizzy::before {
  content: "\f567";
}

.fa-face-explode::before {
  content: "\e2fe";
}

.fa-exploding-head::before {
  content: "\e2fe";
}

.fa-face-flushed::before {
  content: "\f579";
}

.fa-flushed::before {
  content: "\f579";
}

.fa-face-frown::before {
  content: "\f119";
}

.fa-frown::before {
  content: "\f119";
}

.fa-face-frown-open::before {
  content: "\f57a";
}

.fa-frown-open::before {
  content: "\f57a";
}

.fa-face-grimace::before {
  content: "\f57f";
}

.fa-grimace::before {
  content: "\f57f";
}

.fa-face-grin::before {
  content: "\f580";
}

.fa-grin::before {
  content: "\f580";
}

.fa-face-grin-beam::before {
  content: "\f582";
}

.fa-grin-beam::before {
  content: "\f582";
}

.fa-face-grin-beam-sweat::before {
  content: "\f583";
}

.fa-grin-beam-sweat::before {
  content: "\f583";
}

.fa-face-grin-hearts::before {
  content: "\f584";
}

.fa-grin-hearts::before {
  content: "\f584";
}

.fa-face-grin-squint::before {
  content: "\f585";
}

.fa-grin-squint::before {
  content: "\f585";
}

.fa-face-grin-squint-tears::before {
  content: "\f586";
}

.fa-grin-squint-tears::before {
  content: "\f586";
}

.fa-face-grin-stars::before {
  content: "\f587";
}

.fa-grin-stars::before {
  content: "\f587";
}

.fa-face-grin-tears::before {
  content: "\f588";
}

.fa-grin-tears::before {
  content: "\f588";
}

.fa-face-grin-tongue::before {
  content: "\f589";
}

.fa-grin-tongue::before {
  content: "\f589";
}

.fa-face-grin-tongue-squint::before {
  content: "\f58a";
}

.fa-grin-tongue-squint::before {
  content: "\f58a";
}

.fa-face-grin-tongue-wink::before {
  content: "\f58b";
}

.fa-grin-tongue-wink::before {
  content: "\f58b";
}

.fa-face-grin-wide::before {
  content: "\f581";
}

.fa-grin-alt::before {
  content: "\f581";
}

.fa-face-grin-wink::before {
  content: "\f58c";
}

.fa-grin-wink::before {
  content: "\f58c";
}

.fa-face-kiss::before {
  content: "\f596";
}

.fa-kiss::before {
  content: "\f596";
}

.fa-face-kiss-beam::before {
  content: "\f597";
}

.fa-kiss-beam::before {
  content: "\f597";
}

.fa-face-kiss-wink-heart::before {
  content: "\f598";
}

.fa-kiss-wink-heart::before {
  content: "\f598";
}

.fa-face-laugh::before {
  content: "\f599";
}

.fa-laugh::before {
  content: "\f599";
}

.fa-face-laugh-beam::before {
  content: "\f59a";
}

.fa-laugh-beam::before {
  content: "\f59a";
}

.fa-face-laugh-squint::before {
  content: "\f59b";
}

.fa-laugh-squint::before {
  content: "\f59b";
}

.fa-face-laugh-wink::before {
  content: "\f59c";
}

.fa-laugh-wink::before {
  content: "\f59c";
}

.fa-face-meh::before {
  content: "\f11a";
}

.fa-meh::before {
  content: "\f11a";
}

.fa-face-meh-blank::before {
  content: "\f5a4";
}

.fa-meh-blank::before {
  content: "\f5a4";
}

.fa-face-rolling-eyes::before {
  content: "\f5a5";
}

.fa-meh-rolling-eyes::before {
  content: "\f5a5";
}

.fa-face-sad-cry::before {
  content: "\f5b3";
}

.fa-sad-cry::before {
  content: "\f5b3";
}

.fa-face-sad-tear::before {
  content: "\f5b4";
}

.fa-sad-tear::before {
  content: "\f5b4";
}

.fa-face-smile::before {
  content: "\f118";
}

.fa-smile::before {
  content: "\f118";
}

.fa-face-smile-beam::before {
  content: "\f5b8";
}

.fa-smile-beam::before {
  content: "\f5b8";
}

.fa-face-smile-plus::before {
  content: "\f5b9";
}

.fa-smile-plus::before {
  content: "\f5b9";
}

.fa-face-smile-wink::before {
  content: "\f4da";
}

.fa-smile-wink::before {
  content: "\f4da";
}

.fa-face-surprise::before {
  content: "\f5c2";
}

.fa-surprise::before {
  content: "\f5c2";
}

.fa-face-tired::before {
  content: "\f5c8";
}

.fa-tired::before {
  content: "\f5c8";
}

.fa-face-viewfinder::before {
  content: "\e2ff";
}

.fa-family::before {
  content: "\e300";
}

.fa-family-dress::before {
  content: "\e301";
}

.fa-family-pants::before {
  content: "\e302";
}

.fa-fan::before {
  content: "\f863";
}

.fa-fan-table::before {
  content: "\e004";
}

.fa-farm::before {
  content: "\f864";
}

.fa-barn-silo::before {
  content: "\f864";
}

.fa-faucet::before {
  content: "\e005";
}

.fa-faucet-drip::before {
  content: "\e006";
}

.fa-fax::before {
  content: "\f1ac";
}

.fa-feather::before {
  content: "\f52d";
}

.fa-feather-pointed::before {
  content: "\f56b";
}

.fa-feather-alt::before {
  content: "\f56b";
}

.fa-fence::before {
  content: "\e303";
}

.fa-ferris-wheel::before {
  content: "\e174";
}

.fa-field-hockey-stick-ball::before {
  content: "\f44c";
}

.fa-field-hockey::before {
  content: "\f44c";
}

.fa-file::before {
  content: "\f15b";
}

.fa-file-arrow-down::before {
  content: "\f56d";
}

.fa-file-download::before {
  content: "\f56d";
}

.fa-file-arrow-up::before {
  content: "\f574";
}

.fa-file-upload::before {
  content: "\f574";
}

.fa-file-audio::before {
  content: "\f1c7";
}

.fa-file-binary::before {
  content: "\e175";
}

.fa-file-certificate::before {
  content: "\f5f3";
}

.fa-file-award::before {
  content: "\f5f3";
}

.fa-file-chart-column::before {
  content: "\f659";
}

.fa-file-chart-line::before {
  content: "\f659";
}

.fa-file-chart-pie::before {
  content: "\f65a";
}

.fa-file-check::before {
  content: "\f316";
}

.fa-file-code::before {
  content: "\f1c9";
}

.fa-file-contract::before {
  content: "\f56c";
}

.fa-file-csv::before {
  content: "\f6dd";
}

.fa-file-dashed-line::before {
  content: "\f877";
}

.fa-page-break::before {
  content: "\f877";
}

.fa-file-excel::before {
  content: "\f1c3";
}

.fa-file-exclamation::before {
  content: "\f31a";
}

.fa-file-export::before {
  content: "\f56e";
}

.fa-arrow-right-from-file::before {
  content: "\f56e";
}

.fa-file-heart::before {
  content: "\e176";
}

.fa-file-image::before {
  content: "\f1c5";
}

.fa-file-import::before {
  content: "\f56f";
}

.fa-arrow-right-to-file::before {
  content: "\f56f";
}

.fa-file-invoice::before {
  content: "\f570";
}

.fa-file-invoice-dollar::before {
  content: "\f571";
}

.fa-file-lines::before {
  content: "\f15c";
}

.fa-file-alt::before {
  content: "\f15c";
}

.fa-file-text::before {
  content: "\f15c";
}

.fa-file-magnifying-glass::before {
  content: "\f865";
}

.fa-file-search::before {
  content: "\f865";
}

.fa-file-medical::before {
  content: "\f477";
}

.fa-file-minus::before {
  content: "\f318";
}

.fa-file-music::before {
  content: "\f8b6";
}

.fa-file-pdf::before {
  content: "\f1c1";
}

.fa-file-pen::before {
  content: "\f31c";
}

.fa-file-edit::before {
  content: "\f31c";
}

.fa-file-plus::before {
  content: "\f319";
}

.fa-file-plus-minus::before {
  content: "\e177";
}

.fa-file-powerpoint::before {
  content: "\f1c4";
}

.fa-file-prescription::before {
  content: "\f572";
}

.fa-file-signature::before {
  content: "\f573";
}

.fa-file-spreadsheet::before {
  content: "\f65b";
}

.fa-file-user::before {
  content: "\f65c";
}

.fa-file-video::before {
  content: "\f1c8";
}

.fa-file-waveform::before {
  content: "\f478";
}

.fa-file-medical-alt::before {
  content: "\f478";
}

.fa-file-word::before {
  content: "\f1c2";
}

.fa-file-xmark::before {
  content: "\f317";
}

.fa-file-times::before {
  content: "\f317";
}

.fa-file-zipper::before {
  content: "\f1c6";
}

.fa-file-archive::before {
  content: "\f1c6";
}

.fa-files::before {
  content: "\e178";
}

.fa-files-medical::before {
  content: "\f7fd";
}

.fa-fill::before {
  content: "\f575";
}

.fa-fill-drip::before {
  content: "\f576";
}

.fa-film::before {
  content: "\f008";
}

.fa-film-canister::before {
  content: "\f8b7";
}

.fa-film-simple::before {
  content: "\f3a0";
}

.fa-film-alt::before {
  content: "\f3a0";
}

.fa-film-slash::before {
  content: "\e179";
}

.fa-films::before {
  content: "\e17a";
}

.fa-filter::before {
  content: "\f0b0";
}

.fa-filter-circle-dollar::before {
  content: "\f662";
}

.fa-funnel-dollar::before {
  content: "\f662";
}

.fa-filter-circle-xmark::before {
  content: "\e17b";
}

.fa-filter-list::before {
  content: "\e17c";
}

.fa-filter-slash::before {
  content: "\e17d";
}

.fa-filters::before {
  content: "\e17e";
}

.fa-fingerprint::before {
  content: "\f577";
}

.fa-fire::before {
  content: "\f06d";
}

.fa-fire-extinguisher::before {
  content: "\f134";
}

.fa-fire-flame::before {
  content: "\f6df";
}

.fa-flame::before {
  content: "\f6df";
}

.fa-fire-flame-curved::before {
  content: "\f7e4";
}

.fa-fire-alt::before {
  content: "\f7e4";
}

.fa-fire-flame-simple::before {
  content: "\f46a";
}

.fa-burn::before {
  content: "\f46a";
}

.fa-fire-hydrant::before {
  content: "\e17f";
}

.fa-fire-smoke::before {
  content: "\f74b";
}

.fa-fireplace::before {
  content: "\f79a";
}

.fa-fish::before {
  content: "\f578";
}

.fa-fish-bones::before {
  content: "\e304";
}

.fa-fish-cooked::before {
  content: "\f7fe";
}

.fa-flag::before {
  content: "\f024";
}

.fa-flag-checkered::before {
  content: "\f11e";
}

.fa-flag-pennant::before {
  content: "\f456";
}

.fa-pennant::before {
  content: "\f456";
}

.fa-flag-swallowtail::before {
  content: "\f74c";
}

.fa-flag-alt::before {
  content: "\f74c";
}

.fa-flag-usa::before {
  content: "\f74d";
}

.fa-flashlight::before {
  content: "\f8b8";
}

.fa-flask::before {
  content: "\f0c3";
}

.fa-flask-round-poison::before {
  content: "\f6e0";
}

.fa-flask-poison::before {
  content: "\f6e0";
}

.fa-flask-round-potion::before {
  content: "\f6e1";
}

.fa-flask-potion::before {
  content: "\f6e1";
}

.fa-floppy-disk::before {
  content: "\f0c7";
}

.fa-save::before {
  content: "\f0c7";
}

.fa-floppy-disk-circle-arrow-right::before {
  content: "\e180";
}

.fa-save-circle-arrow-right::before {
  content: "\e180";
}

.fa-floppy-disk-circle-xmark::before {
  content: "\e181";
}

.fa-floppy-disk-times::before {
  content: "\e181";
}

.fa-save-circle-xmark::before {
  content: "\e181";
}

.fa-save-times::before {
  content: "\e181";
}

.fa-floppy-disk-pen::before {
  content: "\e182";
}

.fa-floppy-disks::before {
  content: "\e183";
}

.fa-florin-sign::before {
  content: "\e184";
}

.fa-flower::before {
  content: "\f7ff";
}

.fa-flower-daffodil::before {
  content: "\f800";
}

.fa-flower-tulip::before {
  content: "\f801";
}

.fa-flute::before {
  content: "\f8b9";
}

.fa-flux-capacitor::before {
  content: "\f8ba";
}

.fa-folder::before {
  content: "\f07b";
}

.fa-folder-arrow-down::before {
  content: "\e053";
}

.fa-folder-download::before {
  content: "\e053";
}

.fa-folder-arrow-up::before {
  content: "\e054";
}

.fa-folder-upload::before {
  content: "\e054";
}

.fa-folder-blank::before {
  content: "\e185";
}

.fa-folder-bookmark::before {
  content: "\e186";
}

.fa-folder-gear::before {
  content: "\e187";
}

.fa-folder-cog::before {
  content: "\e187";
}

.fa-folder-grid::before {
  content: "\e188";
}

.fa-folder-heart::before {
  content: "\e189";
}

.fa-folder-image::before {
  content: "\e18a";
}

.fa-folder-magnifying-glass::before {
  content: "\e18b";
}

.fa-folder-search::before {
  content: "\e18b";
}

.fa-folder-medical::before {
  content: "\e18c";
}

.fa-folder-minus::before {
  content: "\f65d";
}

.fa-folder-music::before {
  content: "\e18d";
}

.fa-folder-open::before {
  content: "\f07c";
}

.fa-folder-plus::before {
  content: "\f65e";
}

.fa-folder-tree::before {
  content: "\f802";
}

.fa-folder-user::before {
  content: "\e18e";
}

.fa-folder-xmark::before {
  content: "\f65f";
}

.fa-folder-times::before {
  content: "\f65f";
}

.fa-folders::before {
  content: "\f660";
}

.fa-font::before {
  content: "\f031";
}

.fa-font-case::before {
  content: "\f866";
}

.fa-football-ball::before {
  content: "\f44e";
}

.fa-football-helmet::before {
  content: "\f44f";
}

.fa-fork::before {
  content: "\f2e3";
}

.fa-utensil-fork::before {
  content: "\f2e3";
}

.fa-fork-knife::before {
  content: "\f2e6";
}

.fa-utensils-alt::before {
  content: "\f2e6";
}

.fa-forklift::before {
  content: "\f47a";
}

.fa-forward::before {
  content: "\f04e";
}

.fa-forward-fast::before {
  content: "\f050";
}

.fa-fast-forward::before {
  content: "\f050";
}

.fa-forward-step::before {
  content: "\f051";
}

.fa-step-forward::before {
  content: "\f051";
}

.fa-franc-sign::before {
  content: "\e18f";
}

.fa-french-fries::before {
  content: "\f803";
}

.fa-frog::before {
  content: "\f52e";
}

.fa-function::before {
  content: "\f661";
}

.fa-futbol-ball::before {
  content: "\f1e3";
}

.fa-futbol::before {
  content: "\f1e3";
}

.fa-soccer-ball::before {
  content: "\f1e3";
}

.fa-g::before {
  content: "\e305";
}

.fa-galaxy::before {
  content: "\e008";
}

.fa-game-board::before {
  content: "\f867";
}

.fa-game-board-simple::before {
  content: "\f868";
}

.fa-game-board-alt::before {
  content: "\f868";
}

.fa-game-console-handheld::before {
  content: "\f8bb";
}

.fa-gamepad::before {
  content: "\f11b";
}

.fa-gamepad-modern::before {
  content: "\f8bc";
}

.fa-gamepad-alt::before {
  content: "\f8bc";
}

.fa-garage::before {
  content: "\e009";
}

.fa-garage-car::before {
  content: "\e00a";
}

.fa-garage-open::before {
  content: "\e00b";
}

.fa-gas-pump::before {
  content: "\f52f";
}

.fa-gas-pump-slash::before {
  content: "\f5f4";
}

.fa-gauge::before {
  content: "\f625";
}

.fa-dashboard::before {
  content: "\f625";
}

.fa-gauge-high::before {
  content: "\f625";
}

.fa-tachometer-alt::before {
  content: "\f625";
}

.fa-tachometer-alt-fast::before {
  content: "\f625";
}

.fa-gauge-low::before {
  content: "\f627";
}

.fa-tachometer-alt-slow::before {
  content: "\f627";
}

.fa-gauge-max::before {
  content: "\f626";
}

.fa-tachometer-alt-fastest::before {
  content: "\f626";
}

.fa-gauge-med::before {
  content: "\f624";
}

.fa-tachometer-alt-average::before {
  content: "\f624";
}

.fa-gauge-min::before {
  content: "\f628";
}

.fa-tachometer-alt-slowest::before {
  content: "\f628";
}

.fa-gauge-simple::before {
  content: "\f62a";
}

.fa-gauge-simple-high::before {
  content: "\f62a";
}

.fa-tachometer::before {
  content: "\f62a";
}

.fa-gauge-simple-low::before {
  content: "\f62c";
}

.fa-tachometer-slow::before {
  content: "\f62c";
}

.fa-gauge-simple-max::before {
  content: "\f62b";
}

.fa-tachometer-fastest::before {
  content: "\f62b";
}

.fa-gauge-simple-med::before {
  content: "\f629";
}

.fa-tachometer-average::before {
  content: "\f629";
}

.fa-gauge-simple-min::before {
  content: "\f62d";
}

.fa-tachometer-slowest::before {
  content: "\f62d";
}

.fa-gavel::before {
  content: "\f0e3";
}

.fa-legal::before {
  content: "\f0e3";
}

.fa-gear::before {
  content: "\f013";
}

.fa-cog::before {
  content: "\f013";
}

.fa-gears::before {
  content: "\f085";
}

.fa-cogs::before {
  content: "\f085";
}

.fa-gem::before {
  content: "\f3a5";
}

.fa-genderless::before {
  content: "\f22d";
}

.fa-ghost::before {
  content: "\f6e2";
}

.fa-gif::before {
  content: "\e190";
}

.fa-gift::before {
  content: "\f06b";
}

.fa-gift-card::before {
  content: "\f663";
}

.fa-gifts::before {
  content: "\f79c";
}

.fa-gingerbread-man::before {
  content: "\f79d";
}

.fa-glass::before {
  content: "\f804";
}

.fa-glass-citrus::before {
  content: "\f869";
}

.fa-glass-empty::before {
  content: "\e191";
}

.fa-glass-half::before {
  content: "\e192";
}

.fa-glass-half-empty::before {
  content: "\e192";
}

.fa-glass-half-full::before {
  content: "\e192";
}

.fa-glasses::before {
  content: "\f530";
}

.fa-glasses-round::before {
  content: "\f5f5";
}

.fa-glasses-alt::before {
  content: "\f5f5";
}

.fa-globe::before {
  content: "\f0ac";
}

.fa-globe-snow::before {
  content: "\f7a3";
}

.fa-globe-stand::before {
  content: "\f5f6";
}

.fa-golf-ball-tee::before {
  content: "\f450";
}

.fa-golf-ball::before {
  content: "\f450";
}

.fa-golf-club::before {
  content: "\f451";
}

.fa-gopuram::before {
  content: "\f664";
}

.fa-graduation-cap::before {
  content: "\f19d";
}

.fa-mortar-board::before {
  content: "\f19d";
}

.fa-gramophone::before {
  content: "\f8bd";
}

.fa-grapes::before {
  content: "\e306";
}

.fa-grate::before {
  content: "\e193";
}

.fa-grate-droplet::before {
  content: "\e194";
}

.fa-greater-than::before {
  content: "\f531";
}

.fa-greater-than-equal::before {
  content: "\f532";
}

.fa-grid::before {
  content: "\e195";
}

.fa-grid-3::before {
  content: "\e195";
}

.fa-grid-2::before {
  content: "\e196";
}

.fa-grid-2-plus::before {
  content: "\e197";
}

.fa-grid-4::before {
  content: "\e198";
}

.fa-grid-5::before {
  content: "\e199";
}

.fa-grid-horizontal::before {
  content: "\e307";
}

.fa-grip::before {
  content: "\f58d";
}

.fa-grip-horizontal::before {
  content: "\f58d";
}

.fa-grip-lines::before {
  content: "\f7a4";
}

.fa-grip-lines-vertical::before {
  content: "\f7a5";
}

.fa-grip-vertical::before {
  content: "\f58e";
}

.fa-guarani-sign::before {
  content: "\e19a";
}

.fa-guitar::before {
  content: "\f7a6";
}

.fa-guitar-electric::before {
  content: "\f8be";
}

.fa-guitars::before {
  content: "\f8bf";
}

.fa-gun::before {
  content: "\e19b";
}

.fa-gun-slash::before {
  content: "\e19c";
}

.fa-gun-squirt::before {
  content: "\e19d";
}

.fa-h::before {
  content: "\e308";
}

.fa-h1::before {
  content: "\f313";
}

.fa-h2::before {
  content: "\f314";
}

.fa-h3::before {
  content: "\f315";
}

.fa-h4::before {
  content: "\f86a";
}

.fa-hammer::before {
  content: "\f6e3";
}

.fa-hammer-war::before {
  content: "\f6e4";
}

.fa-hamsa::before {
  content: "\f665";
}

.fa-hand-back-point-down::before {
  content: "\e19e";
}

.fa-hand-back-point-left::before {
  content: "\e19f";
}

.fa-hand-back-point-ribbon::before {
  content: "\e1a0";
}

.fa-hand-back-point-right::before {
  content: "\e1a1";
}

.fa-hand-back-point-up::before {
  content: "\e1a2";
}

.fa-hand-dots::before {
  content: "\f461";
}

.fa-allergies::before {
  content: "\f461";
}

.fa-hand-fingers-crossed::before {
  content: "\e1a3";
}

.fa-hand-fist::before {
  content: "\f6de";
}

.fa-fist-raised::before {
  content: "\f6de";
}

.fa-hand-heart::before {
  content: "\f4bc";
}

.fa-hand-holding::before {
  content: "\f4bd";
}

.fa-hand-holding-box::before {
  content: "\f47b";
}

.fa-hand-holding-dollar::before {
  content: "\f4c0";
}

.fa-hand-holding-usd::before {
  content: "\f4c0";
}

.fa-hand-holding-droplet::before {
  content: "\f4c1";
}

.fa-hand-holding-water::before {
  content: "\f4c1";
}

.fa-hand-holding-heart::before {
  content: "\f4be";
}

.fa-hand-holding-magic::before {
  content: "\f6e5";
}

.fa-hand-holding-medical::before {
  content: "\e05c";
}

.fa-hand-holding-seedling::before {
  content: "\f4bf";
}

.fa-hand-holding-skull::before {
  content: "\e1a4";
}

.fa-hand-horns::before {
  content: "\e1a9";
}

.fa-hand-lizard::before {
  content: "\f258";
}

.fa-hand-love::before {
  content: "\e1a5";
}

.fa-hand-middle-finger::before {
  content: "\f806";
}

.fa-hand-paper::before {
  content: "\f256";
}

.fa-hand-peace::before {
  content: "\f25b";
}

.fa-hand-point-down::before {
  content: "\f0a7";
}

.fa-hand-point-left::before {
  content: "\f0a5";
}

.fa-hand-point-ribbon::before {
  content: "\e1a6";
}

.fa-hand-point-right::before {
  content: "\f0a4";
}

.fa-hand-point-up::before {
  content: "\f0a6";
}

.fa-hand-pointer::before {
  content: "\f25a";
}

.fa-hand-rock::before {
  content: "\f255";
}

.fa-hand-scissors::before {
  content: "\f257";
}

.fa-hand-sparkles::before {
  content: "\e05d";
}

.fa-hand-spock::before {
  content: "\f259";
}

.fa-hand-wave::before {
  content: "\e1a7";
}

.fa-hands::before {
  content: "\f2a7";
}

.fa-sign-language::before {
  content: "\f2a7";
}

.fa-signing::before {
  content: "\f2a7";
}

.fa-hands-asl-interpreting::before {
  content: "\f2a3";
}

.fa-american-sign-language-interpreting::before {
  content: "\f2a3";
}

.fa-asl-interpreting::before {
  content: "\f2a3";
}

.fa-hands-american-sign-language-interpreting::before {
  content: "\f2a3";
}

.fa-hands-bubbles::before {
  content: "\e05e";
}

.fa-hands-wash::before {
  content: "\e05e";
}

.fa-hands-clapping::before {
  content: "\e1a8";
}

.fa-hands-holding::before {
  content: "\f4c2";
}

.fa-hands-holding-diamond::before {
  content: "\f47c";
}

.fa-hand-receiving::before {
  content: "\f47c";
}

.fa-hands-holding-dollar::before {
  content: "\f4c5";
}

.fa-hands-usd::before {
  content: "\f4c5";
}

.fa-hands-holding-heart::before {
  content: "\f4c3";
}

.fa-hands-heart::before {
  content: "\f4c3";
}

.fa-hands-praying::before {
  content: "\f684";
}

.fa-praying-hands::before {
  content: "\f684";
}

.fa-handshake::before {
  content: "\f2b5";
}

.fa-handshake-angle::before {
  content: "\f4c4";
}

.fa-hands-helping::before {
  content: "\f4c4";
}

.fa-handshake-simple::before {
  content: "\f4c6";
}

.fa-handshake-alt::before {
  content: "\f4c6";
}

.fa-handshake-simple-slash::before {
  content: "\e05f";
}

.fa-handshake-alt-slash::before {
  content: "\e05f";
}

.fa-handshake-slash::before {
  content: "\e060";
}

.fa-hanukiah::before {
  content: "\f6e6";
}

.fa-hard-drive::before {
  content: "\f0a0";
}

.fa-hdd::before {
  content: "\f0a0";
}

.fa-hashtag::before {
  content: "\f292";
}

.fa-hat-chef::before {
  content: "\f86b";
}

.fa-hat-cowboy::before {
  content: "\f8c0";
}

.fa-hat-cowboy-side::before {
  content: "\f8c1";
}

.fa-hat-santa::before {
  content: "\f7a7";
}

.fa-hat-winter::before {
  content: "\f7a8";
}

.fa-hat-witch::before {
  content: "\f6e7";
}

.fa-hat-wizard::before {
  content: "\f6e8";
}

.fa-head-side::before {
  content: "\f6e9";
}

.fa-head-side-brain::before {
  content: "\f808";
}

.fa-head-side-cough::before {
  content: "\e061";
}

.fa-head-side-cough-slash::before {
  content: "\e062";
}

.fa-head-side-goggles::before {
  content: "\f6ea";
}

.fa-head-vr::before {
  content: "\f6ea";
}

.fa-head-side-headphones::before {
  content: "\f8c2";
}

.fa-head-side-heart::before {
  content: "\e1aa";
}

.fa-head-side-mask::before {
  content: "\e063";
}

.fa-head-side-medical::before {
  content: "\f809";
}

.fa-head-side-virus::before {
  content: "\e064";
}

.fa-heading::before {
  content: "\f1dc";
}

.fa-header::before {
  content: "\f1dc";
}

.fa-headphones::before {
  content: "\f025";
}

.fa-headphones-simple::before {
  content: "\f58f";
}

.fa-headphones-alt::before {
  content: "\f58f";
}

.fa-headset::before {
  content: "\f590";
}

.fa-heart::before {
  content: "\f004";
}

.fa-heart-crack::before {
  content: "\f7a9";
}

.fa-heart-broken::before {
  content: "\f7a9";
}

.fa-heart-half::before {
  content: "\e1ab";
}

.fa-heart-half-stroke::before {
  content: "\e1ac";
}

.fa-heart-half-alt::before {
  content: "\e1ac";
}

.fa-heart-pulse::before {
  content: "\f21e";
}

.fa-heartbeat::before {
  content: "\f21e";
}

.fa-heat::before {
  content: "\e00c";
}

.fa-helicopter::before {
  content: "\f533";
}

.fa-helmet-battle::before {
  content: "\f6eb";
}

.fa-helmet-safety::before {
  content: "\f807";
}

.fa-hard-hat::before {
  content: "\f807";
}

.fa-hat-hard::before {
  content: "\f807";
}

.fa-hexagon::before {
  content: "\f312";
}

.fa-hexagon-divide::before {
  content: "\e1ad";
}

.fa-hexagon-minus::before {
  content: "\f307";
}

.fa-minus-hexagon::before {
  content: "\f307";
}

.fa-hexagon-plus::before {
  content: "\f300";
}

.fa-plus-hexagon::before {
  content: "\f300";
}

.fa-hexagon-xmark::before {
  content: "\f2ee";
}

.fa-times-hexagon::before {
  content: "\f2ee";
}

.fa-xmark-hexagon::before {
  content: "\f2ee";
}

.fa-high-definition::before {
  content: "\e1ae";
}

.fa-rectangle-hd::before {
  content: "\e1ae";
}

.fa-highlighter::before {
  content: "\f591";
}

.fa-highlighter-line::before {
  content: "\e1af";
}

.fa-hippo::before {
  content: "\f6ed";
}

.fa-hockey-mask::before {
  content: "\f6ee";
}

.fa-hockey-puck::before {
  content: "\f453";
}

.fa-hockey-sticks::before {
  content: "\f454";
}

.fa-holly-berry::before {
  content: "\f7aa";
}

.fa-home::before {
  content: "\f015";
}

.fa-home-lg::before {
  content: "\f015";
}

.fa-home-blank::before {
  content: "\f80a";
}

.fa-home-lg-alt::before {
  content: "\f80a";
}

.fa-home-simple::before {
  content: "\f80a";
}

.fa-home-heart::before {
  content: "\f4c9";
}

.fa-home-user::before {
  content: "\e1b0";
}

.fa-hood-cloak::before {
  content: "\f6ef";
}

.fa-horizontal-rule::before {
  content: "\f86c";
}

.fa-horse::before {
  content: "\f6f0";
}

.fa-horse-head::before {
  content: "\f7ab";
}

.fa-horse-saddle::before {
  content: "\f8c3";
}

.fa-hospital::before {
  content: "\f0f8";
}

.fa-hospital-user::before {
  content: "\f80d";
}

.fa-hospital-wide::before {
  content: "\f47d";
}

.fa-hospital-alt::before {
  content: "\f47d";
}

.fa-hospitals::before {
  content: "\f80e";
}

.fa-hot-tub-person::before {
  content: "\f593";
}

.fa-hot-tub::before {
  content: "\f593";
}

.fa-hotdog::before {
  content: "\f80f";
}

.fa-hotel::before {
  content: "\f594";
}

.fa-hourglass::before {
  content: "\f254";
}

.fa-hourglass-2::before {
  content: "\f254";
}

.fa-hourglass-half::before {
  content: "\f254";
}

.fa-hourglass-empty::before {
  content: "\f252";
}

.fa-hourglass-end::before {
  content: "\f253";
}

.fa-hourglass-3::before {
  content: "\f253";
}

.fa-hourglass-start::before {
  content: "\f251";
}

.fa-hourglass-1::before {
  content: "\f251";
}

.fa-house::before {
  content: "\e00d";
}

.fa-house-building::before {
  content: "\e1b1";
}

.fa-house-crack::before {
  content: "\f6f1";
}

.fa-house-damage::before {
  content: "\f6f1";
}

.fa-house-day::before {
  content: "\e00e";
}

.fa-house-flood::before {
  content: "\f74f";
}

.fa-house-heart::before {
  content: "\e1b2";
}

.fa-house-laptop::before {
  content: "\e066";
}

.fa-laptop-house::before {
  content: "\e066";
}

.fa-house-medical::before {
  content: "\f7f2";
}

.fa-clinic-medical::before {
  content: "\f7f2";
}

.fa-house-night::before {
  content: "\e010";
}

.fa-house-person-leave::before {
  content: "\e00f";
}

.fa-house-person-depart::before {
  content: "\e00f";
}

.fa-house-person-return::before {
  content: "\e011";
}

.fa-house-person-arrive::before {
  content: "\e011";
}

.fa-house-signal::before {
  content: "\e012";
}

.fa-house-tree::before {
  content: "\e1b3";
}

.fa-house-turret::before {
  content: "\e1b4";
}

.fa-house-user::before {
  content: "\e065";
}

.fa-hryvnia-sign::before {
  content: "\f6f2";
}

.fa-hryvnia::before {
  content: "\f6f2";
}

.fa-hurricane::before {
  content: "\f751";
}

.fa-i::before {
  content: "\e309";
}

.fa-i-cursor::before {
  content: "\f246";
}

.fa-ice-cream::before {
  content: "\f810";
}

.fa-ice-skate::before {
  content: "\f7ac";
}

.fa-icicles::before {
  content: "\f7ad";
}

.fa-icons::before {
  content: "\f86d";
}

.fa-heart-music-camera-bolt::before {
  content: "\f86d";
}

.fa-id-badge::before {
  content: "\f2c1";
}

.fa-id-card::before {
  content: "\f2c2";
}

.fa-drivers-license::before {
  content: "\f2c2";
}

.fa-id-card-clip::before {
  content: "\f47f";
}

.fa-id-card-alt::before {
  content: "\f47f";
}

.fa-igloo::before {
  content: "\f7ae";
}

.fa-image::before {
  content: "\f03e";
}

.fa-image-landscape::before {
  content: "\e1b5";
}

.fa-landscape::before {
  content: "\e1b5";
}

.fa-image-polaroid::before {
  content: "\f8c4";
}

.fa-image-polaroid-user::before {
  content: "\e1b6";
}

.fa-image-portrait::before {
  content: "\f3e0";
}

.fa-portrait::before {
  content: "\f3e0";
}

.fa-image-slash::before {
  content: "\e1b7";
}

.fa-image-user::before {
  content: "\e1b8";
}

.fa-images::before {
  content: "\f302";
}

.fa-images-user::before {
  content: "\e1b9";
}

.fa-inbox::before {
  content: "\f01c";
}

.fa-inbox-full::before {
  content: "\e1ba";
}

.fa-inbox-in::before {
  content: "\f310";
}

.fa-inbox-arrow-down::before {
  content: "\f310";
}

.fa-inbox-out::before {
  content: "\f311";
}

.fa-inbox-arrow-up::before {
  content: "\f311";
}

.fa-inboxes::before {
  content: "\e1bb";
}

.fa-indent::before {
  content: "\f03c";
}

.fa-indian-rupee-sign::before {
  content: "\e1bc";
}

.fa-indian-rupee::before {
  content: "\e1bc";
}

.fa-inr::before {
  content: "\e1bc";
}

.fa-industry::before {
  content: "\f275";
}

.fa-industry-windows::before {
  content: "\f3b3";
}

.fa-industry-alt::before {
  content: "\f3b3";
}

.fa-infinity::before {
  content: "\f534";
}

.fa-info::before {
  content: "\f129";
}

.fa-inhaler::before {
  content: "\f5f9";
}

.fa-input-numeric::before {
  content: "\e1bd";
}

.fa-input-pipe::before {
  content: "\e1be";
}

.fa-input-text::before {
  content: "\e1bf";
}

.fa-integral::before {
  content: "\f667";
}

.fa-intersection::before {
  content: "\f668";
}

.fa-island-tropical::before {
  content: "\f811";
}

.fa-island-tree-palm::before {
  content: "\f811";
}

.fa-italic::before {
  content: "\f033";
}

.fa-j::before {
  content: "\e30a";
}

.fa-jack-o-lantern::before {
  content: "\f30e";
}

.fa-jedi::before {
  content: "\f669";
}

.fa-jet-fighter::before {
  content: "\f0fb";
}

.fa-fighter-jet::before {
  content: "\f0fb";
}

.fa-joint::before {
  content: "\f595";
}

.fa-joystick::before {
  content: "\f8c5";
}

.fa-jug::before {
  content: "\f8c6";
}

.fa-k::before {
  content: "\e30b";
}

.fa-kaaba::before {
  content: "\f66b";
}

.fa-kazoo::before {
  content: "\f8c7";
}

.fa-kerning::before {
  content: "\f86f";
}

.fa-key::before {
  content: "\f084";
}

.fa-key-skeleton::before {
  content: "\f6f3";
}

.fa-keyboard::before {
  content: "\f11c";
}

.fa-keyboard-brightness::before {
  content: "\e1c0";
}

.fa-keyboard-brightness-low::before {
  content: "\e1c1";
}

.fa-keyboard-down::before {
  content: "\e1c2";
}

.fa-keyboard-left::before {
  content: "\e1c3";
}

.fa-keynote::before {
  content: "\f66c";
}

.fa-khanda::before {
  content: "\f66d";
}

.fa-kidneys::before {
  content: "\f5fb";
}

.fa-kip-sign::before {
  content: "\e1c4";
}

.fa-kit-medical::before {
  content: "\f479";
}

.fa-first-aid::before {
  content: "\f479";
}

.fa-kite::before {
  content: "\f6f4";
}

.fa-kiwi-bird::before {
  content: "\f535";
}

.fa-kiwi-fruit::before {
  content: "\e30c";
}

.fa-knife::before {
  content: "\f2e4";
}

.fa-utensil-knife::before {
  content: "\f2e4";
}

.fa-knife-kitchen::before {
  content: "\f6f5";
}

.fa-l::before {
  content: "\e30d";
}

.fa-lambda::before {
  content: "\f66e";
}

.fa-lamp::before {
  content: "\f4ca";
}

.fa-lamp-desk::before {
  content: "\e014";
}

.fa-lamp-floor::before {
  content: "\e015";
}

.fa-lamp-street::before {
  content: "\e1c5";
}

.fa-landmark::before {
  content: "\f66f";
}

.fa-landmark-dome::before {
  content: "\f752";
}

.fa-landmark-alt::before {
  content: "\f752";
}

.fa-language::before {
  content: "\f1ab";
}

.fa-laptop::before {
  content: "\f109";
}

.fa-laptop-arrow-down::before {
  content: "\e1c6";
}

.fa-laptop-code::before {
  content: "\f5fc";
}

.fa-laptop-medical::before {
  content: "\f812";
}

.fa-laptop-mobile::before {
  content: "\f87a";
}

.fa-phone-laptop::before {
  content: "\f87a";
}

.fa-laptop-slash::before {
  content: "\e1c7";
}

.fa-lari-sign::before {
  content: "\e1c8";
}

.fa-lasso::before {
  content: "\f8c8";
}

.fa-lasso-sparkles::before {
  content: "\e1c9";
}

.fa-layer-group::before {
  content: "\f5fd";
}

.fa-layer-minus::before {
  content: "\f5fe";
}

.fa-layer-group-minus::before {
  content: "\f5fe";
}

.fa-layer-plus::before {
  content: "\f5ff";
}

.fa-layer-group-plus::before {
  content: "\f5ff";
}

.fa-leaf::before {
  content: "\f06c";
}

.fa-leaf-heart::before {
  content: "\f4cb";
}

.fa-leaf-maple::before {
  content: "\f6f6";
}

.fa-leaf-oak::before {
  content: "\f6f7";
}

.fa-left::before {
  content: "\f355";
}

.fa-arrow-alt-left::before {
  content: "\f355";
}

.fa-left-from-line::before {
  content: "\f348";
}

.fa-arrow-alt-from-right::before {
  content: "\f348";
}

.fa-left-long::before {
  content: "\f30a";
}

.fa-long-arrow-alt-left::before {
  content: "\f30a";
}

.fa-left-right::before {
  content: "\f337";
}

.fa-arrows-alt-h::before {
  content: "\f337";
}

.fa-left-to-line::before {
  content: "\f34b";
}

.fa-arrow-alt-to-left::before {
  content: "\f34b";
}

.fa-lemon::before {
  content: "\f094";
}

.fa-less-than::before {
  content: "\f536";
}

.fa-less-than-equal::before {
  content: "\f537";
}

.fa-life-ring::before {
  content: "\f1cd";
}

.fa-light-ceiling::before {
  content: "\e016";
}

.fa-light-switch::before {
  content: "\e017";
}

.fa-light-switch-off::before {
  content: "\e018";
}

.fa-light-switch-on::before {
  content: "\e019";
}

.fa-lightbulb::before {
  content: "\f0eb";
}

.fa-lightbulb-dollar::before {
  content: "\f670";
}

.fa-lightbulb-exclamation::before {
  content: "\f671";
}

.fa-lightbulb-exclamation-on::before {
  content: "\e1ca";
}

.fa-lightbulb-on::before {
  content: "\f672";
}

.fa-lightbulb-slash::before {
  content: "\f673";
}

.fa-lights-holiday::before {
  content: "\f7b2";
}

.fa-line-columns::before {
  content: "\f870";
}

.fa-line-height::before {
  content: "\f871";
}

.fa-link::before {
  content: "\f0c1";
}

.fa-chain::before {
  content: "\f0c1";
}

.fa-link-horizontal::before {
  content: "\e1cb";
}

.fa-chain-horizontal::before {
  content: "\e1cb";
}

.fa-link-horizontal-slash::before {
  content: "\e1cc";
}

.fa-chain-horizontal-slash::before {
  content: "\e1cc";
}

.fa-link-simple::before {
  content: "\e1cd";
}

.fa-link-simple-slash::before {
  content: "\e1ce";
}

.fa-link-slash::before {
  content: "\f127";
}

.fa-chain-broken::before {
  content: "\f127";
}

.fa-chain-slash::before {
  content: "\f127";
}

.fa-unlink::before {
  content: "\f127";
}

.fa-lips::before {
  content: "\f600";
}

.fa-lira-sign::before {
  content: "\f195";
}

.fa-list::before {
  content: "\f03a";
}

.fa-list-squares::before {
  content: "\f03a";
}

.fa-list-check::before {
  content: "\f0ae";
}

.fa-tasks::before {
  content: "\f0ae";
}

.fa-list-dropdown::before {
  content: "\e1cf";
}

.fa-list-music::before {
  content: "\f8c9";
}

.fa-list-ol::before {
  content: "\f0cb";
}

.fa-list-1-2::before {
  content: "\f0cb";
}

.fa-list-numeric::before {
  content: "\f0cb";
}

.fa-list-radio::before {
  content: "\e1d0";
}

.fa-list-timeline::before {
  content: "\e1d1";
}

.fa-list-tree::before {
  content: "\e1d2";
}

.fa-list-ul::before {
  content: "\f0ca";
}

.fa-list-dots::before {
  content: "\f0ca";
}

.fa-litecoin-sign::before {
  content: "\e1d3";
}

.fa-loader::before {
  content: "\e1d4";
}

.fa-location::before {
  content: "\f041";
}

.fa-map-marker::before {
  content: "\f041";
}

.fa-location-arrow::before {
  content: "\f124";
}

.fa-location-check::before {
  content: "\f606";
}

.fa-map-marker-check::before {
  content: "\f606";
}

.fa-location-crosshairs::before {
  content: "\f601";
}

.fa-location-crosshairs-slash::before {
  content: "\f603";
}

.fa-location-dot::before {
  content: "\f3c5";
}

.fa-map-marker-alt::before {
  content: "\f3c5";
}

.fa-location-dot-slash::before {
  content: "\f605";
}

.fa-map-marker-alt-slash::before {
  content: "\f605";
}

.fa-location-exclamation::before {
  content: "\f608";
}

.fa-map-marker-exclamation::before {
  content: "\f608";
}

.fa-location-minus::before {
  content: "\f609";
}

.fa-map-marker-minus::before {
  content: "\f609";
}

.fa-location-pen::before {
  content: "\f607";
}

.fa-map-marker-edit::before {
  content: "\f607";
}

.fa-location-plus::before {
  content: "\f60a";
}

.fa-map-marker-plus::before {
  content: "\f60a";
}

.fa-location-question::before {
  content: "\f60b";
}

.fa-map-marker-question::before {
  content: "\f60b";
}

.fa-location-slash::before {
  content: "\f60c";
}

.fa-map-marker-slash::before {
  content: "\f60c";
}

.fa-location-smile::before {
  content: "\f60d";
}

.fa-map-marker-smile::before {
  content: "\f60d";
}

.fa-location-xmark::before {
  content: "\f60e";
}

.fa-map-marker-times::before {
  content: "\f60e";
}

.fa-map-marker-xmark::before {
  content: "\f60e";
}

.fa-lock::before {
  content: "\f023";
}

.fa-lock-keyhole::before {
  content: "\f30d";
}

.fa-lock-alt::before {
  content: "\f30d";
}

.fa-lock-keyhole-open::before {
  content: "\f3c2";
}

.fa-lock-open-alt::before {
  content: "\f3c2";
}

.fa-lock-open::before {
  content: "\f3c1";
}

.fa-loveseat::before {
  content: "\f4cc";
}

.fa-couch-small::before {
  content: "\f4cc";
}

.fa-luchador-mask::before {
  content: "\f455";
}

.fa-luchador::before {
  content: "\f455";
}

.fa-mask-luchador::before {
  content: "\f455";
}

.fa-lungs::before {
  content: "\f604";
}

.fa-lungs-virus::before {
  content: "\e067";
}

.fa-m::before {
  content: "\e30e";
}

.fa-mace::before {
  content: "\f6f8";
}

.fa-magnet::before {
  content: "\f076";
}

.fa-magnifying-glass::before {
  content: "\f002";
}

.fa-search::before {
  content: "\f002";
}

.fa-magnifying-glass-dollar::before {
  content: "\f688";
}

.fa-search-dollar::before {
  content: "\f688";
}

.fa-magnifying-glass-location::before {
  content: "\f689";
}

.fa-search-location::before {
  content: "\f689";
}

.fa-magnifying-glass-minus::before {
  content: "\f010";
}

.fa-search-minus::before {
  content: "\f010";
}

.fa-magnifying-glass-plus::before {
  content: "\f00e";
}

.fa-search-plus::before {
  content: "\f00e";
}

.fa-mailbox::before {
  content: "\f813";
}

.fa-manat-sign::before {
  content: "\e1d5";
}

.fa-mandolin::before {
  content: "\f6f9";
}

.fa-mango::before {
  content: "\e30f";
}

.fa-manhole::before {
  content: "\e1d6";
}

.fa-map::before {
  content: "\f279";
}

.fa-map-location::before {
  content: "\f59f";
}

.fa-map-marked::before {
  content: "\f59f";
}

.fa-map-location-dot::before {
  content: "\f5a0";
}

.fa-map-marked-alt::before {
  content: "\f5a0";
}

.fa-map-pin::before {
  content: "\f276";
}

.fa-marker::before {
  content: "\f5a1";
}

.fa-mars::before {
  content: "\f222";
}

.fa-mars-double::before {
  content: "\f227";
}

.fa-mars-stroke::before {
  content: "\f229";
}

.fa-mars-stroke-right::before {
  content: "\f22b";
}

.fa-mars-stroke-h::before {
  content: "\f22b";
}

.fa-mars-stroke-up::before {
  content: "\f22a";
}

.fa-mars-stroke-v::before {
  content: "\f22a";
}

.fa-martini-glass::before {
  content: "\f57b";
}

.fa-glass-martini-alt::before {
  content: "\f57b";
}

.fa-martini-glass-citrus::before {
  content: "\f561";
}

.fa-cocktail::before {
  content: "\f561";
}

.fa-martini-glass-empty::before {
  content: "\f000";
}

.fa-glass-martini::before {
  content: "\f000";
}

.fa-mask::before {
  content: "\f6fa";
}

.fa-mask-face::before {
  content: "\e1d7";
}

.fa-masks-theater::before {
  content: "\f630";
}

.fa-theater-masks::before {
  content: "\f630";
}

.fa-maximize::before {
  content: "\f31e";
}

.fa-expand-arrows-alt::before {
  content: "\f31e";
}

.fa-meat::before {
  content: "\f814";
}

.fa-medal::before {
  content: "\f5a2";
}

.fa-megaphone::before {
  content: "\f675";
}

.fa-melon::before {
  content: "\e310";
}

.fa-melon-slice::before {
  content: "\e311";
}

.fa-memo::before {
  content: "\e1d8";
}

.fa-memo-circle-check::before {
  content: "\e1d9";
}

.fa-memo-pad::before {
  content: "\e1da";
}

.fa-memory::before {
  content: "\f538";
}

.fa-menorah::before {
  content: "\f676";
}

.fa-mercury::before {
  content: "\f223";
}

.fa-message::before {
  content: "\f27a";
}

.fa-comment-alt::before {
  content: "\f27a";
}

.fa-message-arrow-down::before {
  content: "\e1db";
}

.fa-comment-alt-arrow-down::before {
  content: "\e1db";
}

.fa-message-arrow-up::before {
  content: "\e1dc";
}

.fa-comment-alt-arrow-up::before {
  content: "\e1dc";
}

.fa-message-arrow-up-right::before {
  content: "\e1dd";
}

.fa-message-captions::before {
  content: "\e1de";
}

.fa-comment-alt-captions::before {
  content: "\e1de";
}

.fa-message-check::before {
  content: "\f4a2";
}

.fa-comment-alt-check::before {
  content: "\f4a2";
}

.fa-message-code::before {
  content: "\e1df";
}

.fa-message-dollar::before {
  content: "\f650";
}

.fa-comment-alt-dollar::before {
  content: "\f650";
}

.fa-message-dots::before {
  content: "\f4a3";
}

.fa-comment-alt-dots::before {
  content: "\f4a3";
}

.fa-messaging::before {
  content: "\f4a3";
}

.fa-message-exclamation::before {
  content: "\f4a5";
}

.fa-comment-alt-exclamation::before {
  content: "\f4a5";
}

.fa-message-image::before {
  content: "\e1e0";
}

.fa-comment-alt-image::before {
  content: "\e1e0";
}

.fa-message-lines::before {
  content: "\f4a6";
}

.fa-comment-alt-lines::before {
  content: "\f4a6";
}

.fa-message-medical::before {
  content: "\f7f4";
}

.fa-comment-alt-medical::before {
  content: "\f7f4";
}

.fa-message-middle::before {
  content: "\e1e1";
}

.fa-comment-middle-alt::before {
  content: "\e1e1";
}

.fa-message-middle-top::before {
  content: "\e1e2";
}

.fa-comment-middle-top-alt::before {
  content: "\e1e2";
}

.fa-message-minus::before {
  content: "\f4a7";
}

.fa-comment-alt-minus::before {
  content: "\f4a7";
}

.fa-message-music::before {
  content: "\f8af";
}

.fa-comment-alt-music::before {
  content: "\f8af";
}

.fa-message-pen::before {
  content: "\f4a4";
}

.fa-comment-alt-edit::before {
  content: "\f4a4";
}

.fa-message-edit::before {
  content: "\f4a4";
}

.fa-message-plus::before {
  content: "\f4a8";
}

.fa-comment-alt-plus::before {
  content: "\f4a8";
}

.fa-message-question::before {
  content: "\e1e3";
}

.fa-message-quote::before {
  content: "\e1e4";
}

.fa-comment-alt-quote::before {
  content: "\e1e4";
}

.fa-message-slash::before {
  content: "\f4a9";
}

.fa-comment-alt-slash::before {
  content: "\f4a9";
}

.fa-message-smile::before {
  content: "\f4aa";
}

.fa-comment-alt-smile::before {
  content: "\f4aa";
}

.fa-message-sms::before {
  content: "\e1e5";
}

.fa-message-text::before {
  content: "\e1e6";
}

.fa-comment-alt-text::before {
  content: "\e1e6";
}

.fa-message-xmark::before {
  content: "\f4ab";
}

.fa-comment-alt-times::before {
  content: "\f4ab";
}

.fa-message-times::before {
  content: "\f4ab";
}

.fa-messages::before {
  content: "\f4b6";
}

.fa-comments-alt::before {
  content: "\f4b6";
}

.fa-messages-dollar::before {
  content: "\f652";
}

.fa-comments-alt-dollar::before {
  content: "\f652";
}

.fa-messages-question::before {
  content: "\e1e7";
}

.fa-meteor::before {
  content: "\f753";
}

.fa-meter::before {
  content: "\e1e8";
}

.fa-meter-bolt::before {
  content: "\e1e9";
}

.fa-meter-droplet::before {
  content: "\e1ea";
}

.fa-meter-fire::before {
  content: "\e1eb";
}

.fa-microchip::before {
  content: "\f2db";
}

.fa-microchip-ai::before {
  content: "\e1ec";
}

.fa-microphone::before {
  content: "\f130";
}

.fa-microphone-lines::before {
  content: "\f3c9";
}

.fa-microphone-alt::before {
  content: "\f3c9";
}

.fa-microphone-lines-slash::before {
  content: "\f539";
}

.fa-microphone-alt-slash::before {
  content: "\f539";
}

.fa-microphone-slash::before {
  content: "\f131";
}

.fa-microphone-stand::before {
  content: "\f8cb";
}

.fa-microscope::before {
  content: "\f610";
}

.fa-microwave::before {
  content: "\e01b";
}

.fa-mill-sign::before {
  content: "\e1ed";
}

.fa-minimize::before {
  content: "\f78c";
}

.fa-compress-arrows-alt::before {
  content: "\f78c";
}

.fa-minus::before {
  content: "\f068";
}

.fa-subtract::before {
  content: "\f068";
}

.fa-mistletoe::before {
  content: "\f7b4";
}

.fa-mitten::before {
  content: "\f7b5";
}

.fa-mobile::before {
  content: "\f3ce";
}

.fa-mobile-android::before {
  content: "\f3ce";
}

.fa-mobile-phone::before {
  content: "\f3ce";
}

.fa-mobile-button::before {
  content: "\f10b";
}

.fa-mobile-notch::before {
  content: "\e1ee";
}

.fa-mobile-iphone::before {
  content: "\e1ee";
}

.fa-mobile-screen::before {
  content: "\f3cf";
}

.fa-mobile-android-alt::before {
  content: "\f3cf";
}

.fa-mobile-screen-button::before {
  content: "\f3cd";
}

.fa-mobile-alt::before {
  content: "\f3cd";
}

.fa-mobile-signal::before {
  content: "\e1ef";
}

.fa-mobile-signal-out::before {
  content: "\e1f0";
}

.fa-money-bill::before {
  content: "\f0d6";
}

.fa-money-bill-1::before {
  content: "\f3d1";
}

.fa-money-bill-alt::before {
  content: "\f3d1";
}

.fa-money-bill-1-wave::before {
  content: "\f53b";
}

.fa-money-bill-wave-alt::before {
  content: "\f53b";
}

.fa-money-bill-simple::before {
  content: "\e1f1";
}

.fa-money-bill-simple-wave::before {
  content: "\e1f2";
}

.fa-money-bill-wave::before {
  content: "\f53a";
}

.fa-money-bills::before {
  content: "\e1f3";
}

.fa-money-bills-simple::before {
  content: "\e1f4";
}

.fa-money-bills-alt::before {
  content: "\e1f4";
}

.fa-money-check::before {
  content: "\f53c";
}

.fa-money-check-dollar::before {
  content: "\f53d";
}

.fa-money-check-alt::before {
  content: "\f53d";
}

.fa-money-check-dollar-pen::before {
  content: "\f873";
}

.fa-money-check-edit-alt::before {
  content: "\f873";
}

.fa-money-check-pen::before {
  content: "\f872";
}

.fa-money-check-edit::before {
  content: "\f872";
}

.fa-money-from-bracket::before {
  content: "\e312";
}

.fa-money-simple-from-bracket::before {
  content: "\e313";
}

.fa-monitor-waveform::before {
  content: "\f611";
}

.fa-monitor-heart-rate::before {
  content: "\f611";
}

.fa-monkey::before {
  content: "\f6fb";
}

.fa-monument::before {
  content: "\f5a6";
}

.fa-moon::before {
  content: "\f186";
}

.fa-moon-cloud::before {
  content: "\f754";
}

.fa-moon-over-sun::before {
  content: "\f74a";
}

.fa-eclipse-alt::before {
  content: "\f74a";
}

.fa-moon-stars::before {
  content: "\f755";
}

.fa-mortar-pestle::before {
  content: "\f5a7";
}

.fa-mosque::before {
  content: "\f678";
}

.fa-motorcycle::before {
  content: "\f21c";
}

.fa-mountain::before {
  content: "\f6fc";
}

.fa-mountains::before {
  content: "\f6fd";
}

.fa-mp3-player::before {
  content: "\f8ce";
}

.fa-mug::before {
  content: "\f874";
}

.fa-mug-hot::before {
  content: "\f7b6";
}

.fa-mug-marshmallows::before {
  content: "\f7b7";
}

.fa-mug-saucer::before {
  content: "\f0f4";
}

.fa-coffee::before {
  content: "\f0f4";
}

.fa-mug-tea::before {
  content: "\f875";
}

.fa-mug-tea-saucer::before {
  content: "\e1f5";
}

.fa-music::before {
  content: "\f001";
}

.fa-music-note::before {
  content: "\f8cf";
}

.fa-music-alt::before {
  content: "\f8cf";
}

.fa-music-note-slash::before {
  content: "\f8d0";
}

.fa-music-alt-slash::before {
  content: "\f8d0";
}

.fa-music-slash::before {
  content: "\f8d1";
}

.fa-n::before {
  content: "\e314";
}

.fa-naira-sign::before {
  content: "\e1f6";
}

.fa-narwhal::before {
  content: "\f6fe";
}

.fa-network-wired::before {
  content: "\f6ff";
}

.fa-neuter::before {
  content: "\f22c";
}

.fa-newspaper::before {
  content: "\f1ea";
}

.fa-nfc::before {
  content: "\e1f7";
}

.fa-nfc-lock::before {
  content: "\e1f8";
}

.fa-nfc-magnifying-glass::before {
  content: "\e1f9";
}

.fa-nfc-pen::before {
  content: "\e1fa";
}

.fa-nfc-signal::before {
  content: "\e1fb";
}

.fa-nfc-slash::before {
  content: "\e1fc";
}

.fa-nfc-trash::before {
  content: "\e1fd";
}

.fa-not-equal::before {
  content: "\f53e";
}

.fa-notdef::before {
  content: "\e1fe";
}

.fa-note::before {
  content: "\e1ff";
}

.fa-note-medical::before {
  content: "\e200";
}

.fa-note-sticky::before {
  content: "\f249";
}

.fa-sticky-note::before {
  content: "\f249";
}

.fa-notebook::before {
  content: "\e201";
}

.fa-notes::before {
  content: "\e202";
}

.fa-notes-medical::before {
  content: "\f481";
}

.fa-o::before {
  content: "\e315";
}

.fa-object-group::before {
  content: "\f247";
}

.fa-object-ungroup::before {
  content: "\f248";
}

.fa-octagon::before {
  content: "\f306";
}

.fa-octagon-divide::before {
  content: "\e203";
}

.fa-octagon-exclamation::before {
  content: "\e204";
}

.fa-octagon-minus::before {
  content: "\f308";
}

.fa-minus-octagon::before {
  content: "\f308";
}

.fa-octagon-plus::before {
  content: "\f301";
}

.fa-plus-octagon::before {
  content: "\f301";
}

.fa-octagon-xmark::before {
  content: "\f2f0";
}

.fa-times-octagon::before {
  content: "\f2f0";
}

.fa-xmark-octagon::before {
  content: "\f2f0";
}

.fa-oil-can::before {
  content: "\f613";
}

.fa-oil-can-drip::before {
  content: "\e205";
}

.fa-oil-temperature::before {
  content: "\f614";
}

.fa-oil-temp::before {
  content: "\f614";
}

.fa-olive::before {
  content: "\e316";
}

.fa-olive-branch::before {
  content: "\e317";
}

.fa-om::before {
  content: "\f679";
}

.fa-omega::before {
  content: "\f67a";
}

.fa-option::before {
  content: "\e318";
}

.fa-ornament::before {
  content: "\f7b8";
}

.fa-otter::before {
  content: "\f700";
}

.fa-outdent::before {
  content: "\f03b";
}

.fa-dedent::before {
  content: "\f03b";
}

.fa-outlet::before {
  content: "\e01c";
}

.fa-oven::before {
  content: "\e01d";
}

.fa-overline::before {
  content: "\f876";
}

.fa-p::before {
  content: "\e319";
}

.fa-pager::before {
  content: "\f815";
}

.fa-paint-brush::before {
  content: "\f1fc";
}

.fa-paint-brush-fine::before {
  content: "\f5a9";
}

.fa-paint-brush-alt::before {
  content: "\f5a9";
}

.fa-paint-roller::before {
  content: "\f5aa";
}

.fa-paintbrush-pencil::before {
  content: "\e206";
}

.fa-palette::before {
  content: "\f53f";
}

.fa-pallet::before {
  content: "\f482";
}

.fa-pallet-box::before {
  content: "\e208";
}

.fa-pallet-boxes::before {
  content: "\f483";
}

.fa-pallet-alt::before {
  content: "\f483";
}

.fa-palette-boxes::before {
  content: "\f483";
}

.fa-panorama::before {
  content: "\e209";
}

.fa-paper-plane::before {
  content: "\f1d8";
}

.fa-paper-plane-top::before {
  content: "\e20a";
}

.fa-paper-plane-alt::before {
  content: "\e20a";
}

.fa-send::before {
  content: "\e20a";
}

.fa-paperclip::before {
  content: "\f0c6";
}

.fa-parachute-box::before {
  content: "\f4cd";
}

.fa-paragraph::before {
  content: "\f1dd";
}

.fa-paragraph-left::before {
  content: "\f878";
}

.fa-paragraph-rtl::before {
  content: "\f878";
}

.fa-party-bell::before {
  content: "\e31a";
}

.fa-party-horn::before {
  content: "\e31b";
}

.fa-passport::before {
  content: "\f5ab";
}

.fa-paste::before {
  content: "\f0ea";
}

.fa-file-clipboard::before {
  content: "\f0ea";
}

.fa-pause::before {
  content: "\f04c";
}

.fa-paw::before {
  content: "\f1b0";
}

.fa-paw-claws::before {
  content: "\f702";
}

.fa-paw-simple::before {
  content: "\f701";
}

.fa-paw-alt::before {
  content: "\f701";
}

.fa-peace::before {
  content: "\f67c";
}

.fa-peach::before {
  content: "\e20b";
}

.fa-peapod::before {
  content: "\e31c";
}

.fa-pear::before {
  content: "\e20c";
}

.fa-pedestal::before {
  content: "\e20d";
}

.fa-pegasus::before {
  content: "\f703";
}

.fa-pen::before {
  content: "\f304";
}

.fa-pen-circle::before {
  content: "\e20e";
}

.fa-pen-clip::before {
  content: "\f305";
}

.fa-pen-alt::before {
  content: "\f305";
}

.fa-pen-clip-slash::before {
  content: "\e20f";
}

.fa-pen-alt-slash::before {
  content: "\e20f";
}

.fa-pen-fancy::before {
  content: "\f5ac";
}

.fa-pen-fancy-slash::before {
  content: "\e210";
}

.fa-pen-field::before {
  content: "\e211";
}

.fa-pen-line::before {
  content: "\e212";
}

.fa-pen-nib::before {
  content: "\f5ad";
}

.fa-pen-paintbrush::before {
  content: "\f618";
}

.fa-pencil-paintbrush::before {
  content: "\f618";
}

.fa-pen-ruler::before {
  content: "\f5ae";
}

.fa-pencil-ruler::before {
  content: "\f5ae";
}

.fa-pen-slash::before {
  content: "\e213";
}

.fa-pen-swirl::before {
  content: "\e214";
}

.fa-pen-to-square::before {
  content: "\f044";
}

.fa-edit::before {
  content: "\f044";
}

.fa-pencil::before {
  content: "\f040";
}

.fa-pencil-alt::before {
  content: "\f040";
}

.fa-pencil-slash::before {
  content: "\e215";
}

.fa-people::before {
  content: "\e216";
}

.fa-people-arrows-left-right::before {
  content: "\e068";
}

.fa-people-arrows::before {
  content: "\e068";
}

.fa-people-carry-box::before {
  content: "\f4ce";
}

.fa-people-carry::before {
  content: "\f4ce";
}

.fa-people-dress::before {
  content: "\e217";
}

.fa-people-dress-simple::before {
  content: "\e218";
}

.fa-people-pants::before {
  content: "\e219";
}

.fa-people-pants-simple::before {
  content: "\e21a";
}

.fa-people-simple::before {
  content: "\e21b";
}

.fa-pepper-hot::before {
  content: "\f816";
}

.fa-percent::before {
  content: "\f295";
}

.fa-percentage::before {
  content: "\f295";
}

.fa-period::before {
  content: "\e31d";
}

.fa-person::before {
  content: "\f183";
}

.fa-male::before {
  content: "\f183";
}

.fa-person-biking::before {
  content: "\f84a";
}

.fa-biking::before {
  content: "\f84a";
}

.fa-person-biking-mountain::before {
  content: "\f84b";
}

.fa-biking-mountain::before {
  content: "\f84b";
}

.fa-person-booth::before {
  content: "\f756";
}

.fa-person-carry-box::before {
  content: "\f4cf";
}

.fa-person-carry::before {
  content: "\f4cf";
}

.fa-person-digging::before {
  content: "\f85e";
}

.fa-digging::before {
  content: "\f85e";
}

.fa-person-dolly::before {
  content: "\f4d0";
}

.fa-person-dolly-empty::before {
  content: "\f4d1";
}

.fa-person-dots-from-line::before {
  content: "\f470";
}

.fa-diagnoses::before {
  content: "\f470";
}

.fa-person-dress::before {
  content: "\f182";
}

.fa-female::before {
  content: "\f182";
}

.fa-person-dress-simple::before {
  content: "\e21c";
}

.fa-person-from-portal::before {
  content: "\e023";
}

.fa-portal-exit::before {
  content: "\e023";
}

.fa-person-hiking::before {
  content: "\f6ec";
}

.fa-hiking::before {
  content: "\f6ec";
}

.fa-person-pinball::before {
  content: "\e21d";
}

.fa-person-praying::before {
  content: "\f683";
}

.fa-pray::before {
  content: "\f683";
}

.fa-person-pregnant::before {
  content: "\e31e";
}

.fa-person-running::before {
  content: "\f70c";
}

.fa-running::before {
  content: "\f70c";
}

.fa-person-seat::before {
  content: "\e21e";
}

.fa-person-seat-reclined::before {
  content: "\e21f";
}

.fa-person-sign::before {
  content: "\f757";
}

.fa-person-simple::before {
  content: "\e220";
}

.fa-person-skating::before {
  content: "\f7c5";
}

.fa-skating::before {
  content: "\f7c5";
}

.fa-person-ski-jumping::before {
  content: "\f7c7";
}

.fa-ski-jump::before {
  content: "\f7c7";
}

.fa-person-ski-lift::before {
  content: "\f7c8";
}

.fa-ski-lift::before {
  content: "\f7c8";
}

.fa-person-skiing::before {
  content: "\f7c9";
}

.fa-skiing::before {
  content: "\f7c9";
}

.fa-person-skiing-nordic::before {
  content: "\f7ca";
}

.fa-skiing-nordic::before {
  content: "\f7ca";
}

.fa-person-sledding::before {
  content: "\f7cb";
}

.fa-sledding::before {
  content: "\f7cb";
}

.fa-person-snowboarding::before {
  content: "\f7ce";
}

.fa-snowboarding::before {
  content: "\f7ce";
}

.fa-person-snowmobiling::before {
  content: "\f7d1";
}

.fa-snowmobile::before {
  content: "\f7d1";
}

.fa-person-swimming::before {
  content: "\f5c4";
}

.fa-swimmer::before {
  content: "\f5c4";
}

.fa-person-to-portal::before {
  content: "\e022";
}

.fa-portal-enter::before {
  content: "\e022";
}

.fa-person-walking::before {
  content: "\f554";
}

.fa-walking::before {
  content: "\f554";
}

.fa-person-walking-with-cane::before {
  content: "\f29d";
}

.fa-blind::before {
  content: "\f29d";
}

.fa-peseta-sign::before {
  content: "\e221";
}

.fa-peso-sign::before {
  content: "\e222";
}

.fa-phone::before {
  content: "\f095";
}

.fa-phone-arrow-down-left::before {
  content: "\e223";
}

.fa-phone-arrow-down::before {
  content: "\e223";
}

.fa-phone-incoming::before {
  content: "\e223";
}

.fa-phone-arrow-up-right::before {
  content: "\e224";
}

.fa-phone-arrow-up::before {
  content: "\e224";
}

.fa-phone-outgoing::before {
  content: "\e224";
}

.fa-phone-flip::before {
  content: "\f879";
}

.fa-phone-alt::before {
  content: "\f879";
}

.fa-phone-hangup::before {
  content: "\e225";
}

.fa-phone-missed::before {
  content: "\e226";
}

.fa-phone-office::before {
  content: "\f67d";
}

.fa-phone-plus::before {
  content: "\f4d2";
}

.fa-phone-rotary::before {
  content: "\f8d3";
}

.fa-phone-slash::before {
  content: "\f3dd";
}

.fa-phone-volume::before {
  content: "\f2a0";
}

.fa-volume-control-phone::before {
  content: "\f2a0";
}

.fa-phone-xmark::before {
  content: "\e227";
}

.fa-photo-film::before {
  content: "\f87c";
}

.fa-photo-video::before {
  content: "\f87c";
}

.fa-photo-film-music::before {
  content: "\e228";
}

.fa-pi::before {
  content: "\f67e";
}

.fa-piano::before {
  content: "\f8d4";
}

.fa-piano-keyboard::before {
  content: "\f8d5";
}

.fa-pie::before {
  content: "\f705";
}

.fa-pig::before {
  content: "\f706";
}

.fa-piggy-bank::before {
  content: "\f4d3";
}

.fa-pills::before {
  content: "\f484";
}

.fa-pinball::before {
  content: "\e229";
}

.fa-pineapple::before {
  content: "\e31f";
}

.fa-pipe::before {
  content: "\e22a";
}

.fa-pizza::before {
  content: "\f817";
}

.fa-pizza-slice::before {
  content: "\f818";
}

.fa-place-of-worship::before {
  content: "\f67f";
}

.fa-plane::before {
  content: "\f072";
}

.fa-plane-arrival::before {
  content: "\f5af";
}

.fa-plane-departure::before {
  content: "\f5b0";
}

.fa-plane-engines::before {
  content: "\f3de";
}

.fa-plane-alt::before {
  content: "\f3de";
}

.fa-plane-prop::before {
  content: "\e22b";
}

.fa-plane-slash::before {
  content: "\e069";
}

.fa-plane-tail::before {
  content: "\e22c";
}

.fa-plane-up::before {
  content: "\e22d";
}

.fa-plane-up-slash::before {
  content: "\e22e";
}

.fa-planet-moon::before {
  content: "\e01f";
}

.fa-planet-ringed::before {
  content: "\e020";
}

.fa-play::before {
  content: "\f04b";
}

.fa-play-pause::before {
  content: "\e22f";
}

.fa-plug::before {
  content: "\f1e6";
}

.fa-plus::before {
  content: "\f067";
}

.fa-add::before {
  content: "\f067";
}

.fa-plus-minus::before {
  content: "\e230";
}

.fa-podcast::before {
  content: "\f2ce";
}

.fa-podium::before {
  content: "\f680";
}

.fa-podium-star::before {
  content: "\f758";
}

.fa-police-box::before {
  content: "\e021";
}

.fa-poll-people::before {
  content: "\f759";
}

.fa-poo::before {
  content: "\f2fe";
}

.fa-poo-bolt::before {
  content: "\f75a";
}

.fa-poo-storm::before {
  content: "\f75a";
}

.fa-poop::before {
  content: "\f619";
}

.fa-popcorn::before {
  content: "\f819";
}

.fa-power-off::before {
  content: "\f011";
}

.fa-prescription::before {
  content: "\f5b1";
}

.fa-prescription-bottle::before {
  content: "\f485";
}

.fa-prescription-bottle-medical::before {
  content: "\f486";
}

.fa-prescription-bottle-alt::before {
  content: "\f486";
}

.fa-presentation-screen::before {
  content: "\f685";
}

.fa-presentation::before {
  content: "\f685";
}

.fa-print::before {
  content: "\f02f";
}

.fa-print-magnifying-glass::before {
  content: "\f81a";
}

.fa-print-search::before {
  content: "\f81a";
}

.fa-print-slash::before {
  content: "\f686";
}

.fa-projector::before {
  content: "\f8d6";
}

.fa-pump-medical::before {
  content: "\e06a";
}

.fa-pump-soap::before {
  content: "\e06b";
}

.fa-pumpkin::before {
  content: "\f707";
}

.fa-puzzle-piece::before {
  content: "\f12e";
}

.fa-puzzle-piece-simple::before {
  content: "\e231";
}

.fa-puzzle-piece-alt::before {
  content: "\e231";
}

.fa-q::before {
  content: "\e320";
}

.fa-qrcode::before {
  content: "\f029";
}

.fa-question::before {
  content: "\f128";
}

.fa-quidditch-broom-ball::before {
  content: "\f458";
}

.fa-broom-ball::before {
  content: "\f458";
}

.fa-quidditch::before {
  content: "\f458";
}

.fa-quote-left::before {
  content: "\f10d";
}

.fa-quote-left-alt::before {
  content: "\f10d";
}

.fa-quote-right::before {
  content: "\f10e";
}

.fa-quote-right-alt::before {
  content: "\f10e";
}

.fa-quotes::before {
  content: "\e234";
}

.fa-r::before {
  content: "\e321";
}

.fa-rabbit::before {
  content: "\f708";
}

.fa-rabbit-running::before {
  content: "\f709";
}

.fa-rabbit-fast::before {
  content: "\f709";
}

.fa-racquet::before {
  content: "\f45a";
}

.fa-radar::before {
  content: "\e024";
}

.fa-radiation::before {
  content: "\f7b9";
}

.fa-radio::before {
  content: "\f8d7";
}

.fa-radio-tuner::before {
  content: "\f8d8";
}

.fa-radio-alt::before {
  content: "\f8d8";
}

.fa-rainbow::before {
  content: "\f75b";
}

.fa-raindrops::before {
  content: "\f75c";
}

.fa-ram::before {
  content: "\f70a";
}

.fa-ramp-loading::before {
  content: "\f4d4";
}

.fa-raygun::before {
  content: "\e025";
}

.fa-receipt::before {
  content: "\f543";
}

.fa-record-vinyl::before {
  content: "\f8d9";
}

.fa-rectangle::before {
  content: "\f2fa";
}

.fa-rectangle-landscape::before {
  content: "\f2fa";
}

.fa-rectangle-ad::before {
  content: "\f641";
}

.fa-ad::before {
  content: "\f641";
}

.fa-rectangle-barcode::before {
  content: "\f463";
}

.fa-barcode-alt::before {
  content: "\f463";
}

.fa-rectangle-code::before {
  content: "\e322";
}

.fa-rectangle-list::before {
  content: "\f022";
}

.fa-list-alt::before {
  content: "\f022";
}

.fa-rectangle-pro::before {
  content: "\e235";
}

.fa-pro::before {
  content: "\e235";
}

.fa-rectangle-terminal::before {
  content: "\e236";
}

.fa-rectangle-vertical::before {
  content: "\f2fb";
}

.fa-rectangle-portrait::before {
  content: "\f2fb";
}

.fa-rectangle-vertical-history::before {
  content: "\e237";
}

.fa-rectangle-wide::before {
  content: "\f2fc";
}

.fa-rectangle-xmark::before {
  content: "\f410";
}

.fa-rectangle-times::before {
  content: "\f410";
}

.fa-times-rectangle::before {
  content: "\f410";
}

.fa-window-close::before {
  content: "\f410";
}

.fa-rectangles-mixed::before {
  content: "\e323";
}

.fa-recycle::before {
  content: "\f1b8";
}

.fa-reel::before {
  content: "\e238";
}

.fa-refrigerator::before {
  content: "\e026";
}

.fa-registered::before {
  content: "\f25d";
}

.fa-repeat::before {
  content: "\f363";
}

.fa-repeat-1::before {
  content: "\f365";
}

.fa-reply::before {
  content: "\f3e5";
}

.fa-mail-reply::before {
  content: "\f3e5";
}

.fa-reply-all::before {
  content: "\f122";
}

.fa-mail-reply-all::before {
  content: "\f122";
}

.fa-reply-clock::before {
  content: "\e239";
}

.fa-reply-time::before {
  content: "\e239";
}

.fa-republican::before {
  content: "\f75e";
}

.fa-restroom::before {
  content: "\f7bd";
}

.fa-restroom-simple::before {
  content: "\e23a";
}

.fa-retweet::before {
  content: "\f079";
}

.fa-rhombus::before {
  content: "\e23b";
}

.fa-ribbon::before {
  content: "\f4d6";
}

.fa-right::before {
  content: "\f356";
}

.fa-arrow-alt-right::before {
  content: "\f356";
}

.fa-right-from-bracket::before {
  content: "\f2f5";
}

.fa-sign-out-alt::before {
  content: "\f2f5";
}

.fa-right-from-line::before {
  content: "\f347";
}

.fa-arrow-alt-from-left::before {
  content: "\f347";
}

.fa-right-left::before {
  content: "\f362";
}

.fa-exchange-alt::before {
  content: "\f362";
}

.fa-right-long::before {
  content: "\f30b";
}

.fa-long-arrow-alt-right::before {
  content: "\f30b";
}

.fa-right-to-bracket::before {
  content: "\f2f6";
}

.fa-sign-in-alt::before {
  content: "\f2f6";
}

.fa-right-to-line::before {
  content: "\f34c";
}

.fa-arrow-alt-to-right::before {
  content: "\f34c";
}

.fa-ring::before {
  content: "\f70b";
}

.fa-rings-wedding::before {
  content: "\f81b";
}

.fa-road::before {
  content: "\f018";
}

.fa-robot::before {
  content: "\f544";
}

.fa-rocket::before {
  content: "\f135";
}

.fa-rocket-launch::before {
  content: "\e027";
}

.fa-roller-coaster::before {
  content: "\e324";
}

.fa-rotate::before {
  content: "\f2f1";
}

.fa-sync-alt::before {
  content: "\f2f1";
}

.fa-rotate-exclamation::before {
  content: "\e23c";
}

.fa-rotate-left::before {
  content: "\f2ea";
}

.fa-rotate-back::before {
  content: "\f2ea";
}

.fa-rotate-backward::before {
  content: "\f2ea";
}

.fa-undo-alt::before {
  content: "\f2ea";
}

.fa-rotate-right::before {
  content: "\f2f9";
}

.fa-redo-alt::before {
  content: "\f2f9";
}

.fa-rotate-forward::before {
  content: "\f2f9";
}

.fa-route::before {
  content: "\f4d7";
}

.fa-route-highway::before {
  content: "\f61a";
}

.fa-route-interstate::before {
  content: "\f61b";
}

.fa-router::before {
  content: "\f8da";
}

.fa-rss::before {
  content: "\f09e";
}

.fa-feed::before {
  content: "\f09e";
}

.fa-ruble-sign::before {
  content: "\f158";
}

.fa-rouble::before {
  content: "\f158";
}

.fa-rub::before {
  content: "\f158";
}

.fa-ruble::before {
  content: "\f158";
}

.fa-ruler::before {
  content: "\f545";
}

.fa-ruler-combined::before {
  content: "\f546";
}

.fa-ruler-horizontal::before {
  content: "\f547";
}

.fa-ruler-triangle::before {
  content: "\f61c";
}

.fa-ruler-vertical::before {
  content: "\f548";
}

.fa-rupee-sign::before {
  content: "\f156";
}

.fa-rupee::before {
  content: "\f156";
}

.fa-rupiah-sign::before {
  content: "\e23d";
}

.fa-rv::before {
  content: "\f7be";
}

.fa-s::before {
  content: "\e325";
}

.fa-sack::before {
  content: "\f81c";
}

.fa-sack-dollar::before {
  content: "\f81d";
}

.fa-salad::before {
  content: "\f81e";
}

.fa-bowl-salad::before {
  content: "\f81e";
}

.fa-sandwich::before {
  content: "\f81f";
}

.fa-satellite::before {
  content: "\f7bf";
}

.fa-satellite-dish::before {
  content: "\f7c0";
}

.fa-sausage::before {
  content: "\f820";
}

.fa-saxophone::before {
  content: "\f8dc";
}

.fa-saxophone-fire::before {
  content: "\f8db";
}

.fa-sax-hot::before {
  content: "\f8db";
}

.fa-scale-balanced::before {
  content: "\f24e";
}

.fa-balance-scale::before {
  content: "\f24e";
}

.fa-scale-unbalanced::before {
  content: "\f515";
}

.fa-balance-scale-left::before {
  content: "\f515";
}

.fa-scale-unbalanced-flip::before {
  content: "\f516";
}

.fa-balance-scale-right::before {
  content: "\f516";
}

.fa-scalpel::before {
  content: "\f61d";
}

.fa-scalpel-line-dashed::before {
  content: "\f61e";
}

.fa-scalpel-path::before {
  content: "\f61e";
}

.fa-scanner::before {
  content: "\f8f3";
}

.fa-scanner-image::before {
  content: "\f8f3";
}

.fa-scanner-gun::before {
  content: "\f488";
}

.fa-scanner-keyboard::before {
  content: "\f489";
}

.fa-scanner-touchscreen::before {
  content: "\f48a";
}

.fa-scarecrow::before {
  content: "\f70d";
}

.fa-scarf::before {
  content: "\f7c1";
}

.fa-school::before {
  content: "\f549";
}

.fa-scissors::before {
  content: "\f0c4";
}

.fa-cut::before {
  content: "\f0c4";
}

.fa-screen-users::before {
  content: "\f63d";
}

.fa-users-class::before {
  content: "\f63d";
}

.fa-screencast::before {
  content: "\e23e";
}

.fa-screwdriver::before {
  content: "\f54a";
}

.fa-screwdriver-wrench::before {
  content: "\f7d9";
}

.fa-tools::before {
  content: "\f7d9";
}

.fa-scribble::before {
  content: "\e23f";
}

.fa-scroll::before {
  content: "\f70e";
}

.fa-scroll-old::before {
  content: "\f70f";
}

.fa-scroll-torah::before {
  content: "\f6a0";
}

.fa-torah::before {
  content: "\f6a0";
}

.fa-scrubber::before {
  content: "\f2f8";
}

.fa-scythe::before {
  content: "\f710";
}

.fa-sd-card::before {
  content: "\f7c2";
}

.fa-sd-cards::before {
  content: "\e240";
}

.fa-seal::before {
  content: "\e241";
}

.fa-seal-exclamation::before {
  content: "\e242";
}

.fa-seal-question::before {
  content: "\e243";
}

.fa-seat-airline::before {
  content: "\e244";
}

.fa-section::before {
  content: "\e245";
}

.fa-seedling::before {
  content: "\f4d8";
}

.fa-sprout::before {
  content: "\f4d8";
}

.fa-semicolon::before {
  content: "\e326";
}

.fa-send-back::before {
  content: "\f87e";
}

.fa-send-backward::before {
  content: "\f87f";
}

.fa-sensor::before {
  content: "\e028";
}

.fa-sensor-cloud::before {
  content: "\e02c";
}

.fa-sensor-smoke::before {
  content: "\e02c";
}

.fa-sensor-fire::before {
  content: "\e02a";
}

.fa-sensor-on::before {
  content: "\e02b";
}

.fa-sensor-triangle-exclamation::before {
  content: "\e029";
}

.fa-sensor-alert::before {
  content: "\e029";
}

.fa-server::before {
  content: "\f233";
}

.fa-shapes::before {
  content: "\f61f";
}

.fa-triangle-circle-square::before {
  content: "\f61f";
}

.fa-share::before {
  content: "\f064";
}

.fa-arrow-turn-right::before {
  content: "\f064";
}

.fa-mail-forward::before {
  content: "\f064";
}

.fa-share-all::before {
  content: "\f367";
}

.fa-arrows-turn-right::before {
  content: "\f367";
}

.fa-share-from-square::before {
  content: "\f14d";
}

.fa-share-square::before {
  content: "\f14d";
}

.fa-share-nodes::before {
  content: "\f1e0";
}

.fa-share-alt::before {
  content: "\f1e0";
}

.fa-sheep::before {
  content: "\f711";
}

.fa-shekel-sign::before {
  content: "\f20b";
}

.fa-ils::before {
  content: "\f20b";
}

.fa-shekel::before {
  content: "\f20b";
}

.fa-sheqel::before {
  content: "\f20b";
}

.fa-sheqel-sign::before {
  content: "\f20b";
}

.fa-shelves::before {
  content: "\f480";
}

.fa-inventory::before {
  content: "\f480";
}

.fa-shelves-empty::before {
  content: "\e246";
}

.fa-shield::before {
  content: "\f132";
}

.fa-shield-blank::before {
  content: "\f3ed";
}

.fa-shield-alt::before {
  content: "\f3ed";
}

.fa-shield-check::before {
  content: "\f2f7";
}

.fa-shield-cross::before {
  content: "\f712";
}

.fa-shield-exclamation::before {
  content: "\e247";
}

.fa-shield-keyhole::before {
  content: "\e248";
}

.fa-shield-minus::before {
  content: "\e249";
}

.fa-shield-plus::before {
  content: "\e24a";
}

.fa-shield-slash::before {
  content: "\e24b";
}

.fa-shield-virus::before {
  content: "\e06c";
}

.fa-shield-xmark::before {
  content: "\e24c";
}

.fa-shield-times::before {
  content: "\e24c";
}

.fa-ship::before {
  content: "\f21a";
}

.fa-shish-kebab::before {
  content: "\f821";
}

.fa-shoe-prints::before {
  content: "\f54b";
}

.fa-shop::before {
  content: "\f54f";
}

.fa-store-alt::before {
  content: "\f54f";
}

.fa-shop-slash::before {
  content: "\e070";
}

.fa-store-alt-slash::before {
  content: "\e070";
}

.fa-shovel::before {
  content: "\f713";
}

.fa-shovel-snow::before {
  content: "\f7c3";
}

.fa-shower::before {
  content: "\f2cc";
}

.fa-shower-down::before {
  content: "\e24d";
}

.fa-shower-alt::before {
  content: "\e24d";
}

.fa-shredder::before {
  content: "\f68a";
}

.fa-shuffle::before {
  content: "\f074";
}

.fa-random::before {
  content: "\f074";
}

.fa-shuttle-space::before {
  content: "\f197";
}

.fa-space-shuttle::before {
  content: "\f197";
}

.fa-shuttlecock::before {
  content: "\f45b";
}

.fa-sickle::before {
  content: "\f822";
}

.fa-sidebar::before {
  content: "\e24e";
}

.fa-sidebar-flip::before {
  content: "\e24f";
}

.fa-sigma::before {
  content: "\f68b";
}

.fa-sign-hanging::before {
  content: "\f4d9";
}

.fa-sign::before {
  content: "\f4d9";
}

.fa-signal::before {
  content: "\f012";
}

.fa-signal-5::before {
  content: "\f012";
}

.fa-signal-perfect::before {
  content: "\f012";
}

.fa-signal-bars::before {
  content: "\f690";
}

.fa-signal-alt::before {
  content: "\f690";
}

.fa-signal-alt-4::before {
  content: "\f690";
}

.fa-signal-bars-strong::before {
  content: "\f690";
}

.fa-signal-bars-fair::before {
  content: "\f692";
}

.fa-signal-alt-2::before {
  content: "\f692";
}

.fa-signal-bars-good::before {
  content: "\f693";
}

.fa-signal-alt-3::before {
  content: "\f693";
}

.fa-signal-bars-slash::before {
  content: "\f694";
}

.fa-signal-alt-slash::before {
  content: "\f694";
}

.fa-signal-bars-weak::before {
  content: "\f691";
}

.fa-signal-alt-1::before {
  content: "\f691";
}

.fa-signal-fair::before {
  content: "\f68d";
}

.fa-signal-2::before {
  content: "\f68d";
}

.fa-signal-good::before {
  content: "\f68e";
}

.fa-signal-3::before {
  content: "\f68e";
}

.fa-signal-slash::before {
  content: "\f695";
}

.fa-signal-stream::before {
  content: "\f8dd";
}

.fa-signal-stream-slash::before {
  content: "\e250";
}

.fa-signal-strong::before {
  content: "\f68f";
}

.fa-signal-4::before {
  content: "\f68f";
}

.fa-signal-weak::before {
  content: "\f68c";
}

.fa-signal-1::before {
  content: "\f68c";
}

.fa-signature::before {
  content: "\f5b7";
}

.fa-signs-post::before {
  content: "\f277";
}

.fa-map-signs::before {
  content: "\f277";
}

.fa-sim-card::before {
  content: "\f7c4";
}

.fa-sim-cards::before {
  content: "\e251";
}

.fa-sink::before {
  content: "\e06d";
}

.fa-siren::before {
  content: "\e02d";
}

.fa-siren-on::before {
  content: "\e02e";
}

.fa-sitemap::before {
  content: "\f0e8";
}

.fa-skeleton::before {
  content: "\f620";
}

.fa-skull::before {
  content: "\f54c";
}

.fa-skull-cow::before {
  content: "\f8de";
}

.fa-skull-crossbones::before {
  content: "\f714";
}

.fa-slash::before {
  content: "\f715";
}

.fa-slash-back::before {
  content: "\e327";
}

.fa-slash-forward::before {
  content: "\e328";
}

.fa-sleigh::before {
  content: "\f7cc";
}

.fa-slider::before {
  content: "\e252";
}

.fa-sliders::before {
  content: "\f1de";
}

.fa-sliders-h::before {
  content: "\f1de";
}

.fa-sliders-simple::before {
  content: "\e253";
}

.fa-sliders-up::before {
  content: "\f3f1";
}

.fa-sliders-v::before {
  content: "\f3f1";
}

.fa-smog::before {
  content: "\f75f";
}

.fa-smoke::before {
  content: "\f760";
}

.fa-smoking::before {
  content: "\f48d";
}

.fa-snake::before {
  content: "\f716";
}

.fa-snooze::before {
  content: "\f880";
}

.fa-zzz::before {
  content: "\f880";
}

.fa-snow-blowing::before {
  content: "\f761";
}

.fa-snowflake::before {
  content: "\f2dc";
}

.fa-snowflakes::before {
  content: "\f7cf";
}

.fa-snowman::before {
  content: "\f7d0";
}

.fa-snowman-head::before {
  content: "\f79b";
}

.fa-frosty-head::before {
  content: "\f79b";
}

.fa-snowplow::before {
  content: "\f7d2";
}

.fa-soap::before {
  content: "\e06e";
}

.fa-socks::before {
  content: "\f696";
}

.fa-solar-panel::before {
  content: "\f5ba";
}

.fa-solar-system::before {
  content: "\e02f";
}

.fa-sort::before {
  content: "\f0dc";
}

.fa-unsorted::before {
  content: "\f0dc";
}

.fa-sort-down::before {
  content: "\f0dd";
}

.fa-sort-desc::before {
  content: "\f0dd";
}

.fa-sort-up::before {
  content: "\f0de";
}

.fa-sort-asc::before {
  content: "\f0de";
}

.fa-spa::before {
  content: "\f5bb";
}

.fa-space-station-moon::before {
  content: "\e033";
}

.fa-space-station-moon-construction::before {
  content: "\e034";
}

.fa-space-station-moon-alt::before {
  content: "\e034";
}

.fa-spade::before {
  content: "\f2f4";
}

.fa-spaghetti-monster-flying::before {
  content: "\f67b";
}

.fa-pastafarianism::before {
  content: "\f67b";
}

.fa-sparkles::before {
  content: "\f890";
}

.fa-speaker::before {
  content: "\f8df";
}

.fa-speakers::before {
  content: "\f8e0";
}

.fa-spell-check::before {
  content: "\f891";
}

.fa-spider::before {
  content: "\f717";
}

.fa-spider-black-widow::before {
  content: "\f718";
}

.fa-spider-web::before {
  content: "\f719";
}

.fa-spinner::before {
  content: "\f110";
}

.fa-spinner-third::before {
  content: "\f3f4";
}

.fa-split::before {
  content: "\e254";
}

.fa-splotch::before {
  content: "\f5bc";
}

.fa-spoon::before {
  content: "\f2e5";
}

.fa-utensil-spoon::before {
  content: "\f2e5";
}

.fa-spray-can::before {
  content: "\f5bd";
}

.fa-spray-can-sparkles::before {
  content: "\f5d0";
}

.fa-air-freshener::before {
  content: "\f5d0";
}

.fa-sprinkler::before {
  content: "\e035";
}

.fa-square::before {
  content: "\f0c8";
}

.fa-square-0::before {
  content: "\e255";
}

.fa-square-1::before {
  content: "\e256";
}

.fa-square-2::before {
  content: "\e257";
}

.fa-square-3::before {
  content: "\e258";
}

.fa-square-4::before {
  content: "\e259";
}

.fa-square-5::before {
  content: "\e25a";
}

.fa-square-6::before {
  content: "\e25b";
}

.fa-square-7::before {
  content: "\e25c";
}

.fa-square-8::before {
  content: "\e25d";
}

.fa-square-9::before {
  content: "\e25e";
}

.fa-square-a::before {
  content: "\e25f";
}

.fa-square-ampersand::before {
  content: "\e260";
}

.fa-square-arrow-down::before {
  content: "\f339";
}

.fa-arrow-square-down::before {
  content: "\f339";
}

.fa-square-arrow-down-left::before {
  content: "\e261";
}

.fa-square-arrow-down-right::before {
  content: "\e262";
}

.fa-square-arrow-left::before {
  content: "\f33a";
}

.fa-arrow-square-left::before {
  content: "\f33a";
}

.fa-square-arrow-right::before {
  content: "\f33b";
}

.fa-arrow-square-right::before {
  content: "\f33b";
}

.fa-square-arrow-up::before {
  content: "\f33c";
}

.fa-arrow-square-up::before {
  content: "\f33c";
}

.fa-square-arrow-up-left::before {
  content: "\e263";
}

.fa-square-arrow-up-right::before {
  content: "\f14c";
}

.fa-external-link-square::before {
  content: "\f14c";
}

.fa-square-b::before {
  content: "\e264";
}

.fa-square-bolt::before {
  content: "\e265";
}

.fa-square-c::before {
  content: "\e266";
}

.fa-square-caret-down::before {
  content: "\f150";
}

.fa-caret-square-down::before {
  content: "\f150";
}

.fa-square-caret-left::before {
  content: "\f191";
}

.fa-caret-square-left::before {
  content: "\f191";
}

.fa-square-caret-right::before {
  content: "\f152";
}

.fa-caret-square-right::before {
  content: "\f152";
}

.fa-square-caret-up::before {
  content: "\f151";
}

.fa-caret-square-up::before {
  content: "\f151";
}

.fa-square-check::before {
  content: "\f14a";
}

.fa-check-square::before {
  content: "\f14a";
}

.fa-square-chevron-down::before {
  content: "\f329";
}

.fa-chevron-square-down::before {
  content: "\f329";
}

.fa-square-chevron-left::before {
  content: "\f32a";
}

.fa-chevron-square-left::before {
  content: "\f32a";
}

.fa-square-chevron-right::before {
  content: "\f32b";
}

.fa-chevron-square-right::before {
  content: "\f32b";
}

.fa-square-chevron-up::before {
  content: "\f32c";
}

.fa-chevron-square-up::before {
  content: "\f32c";
}

.fa-square-code::before {
  content: "\e267";
}

.fa-square-d::before {
  content: "\e268";
}

.fa-square-dashed::before {
  content: "\e269";
}

.fa-square-divide::before {
  content: "\e26a";
}

.fa-square-dollar::before {
  content: "\f2e9";
}

.fa-dollar-square::before {
  content: "\f2e9";
}

.fa-usd-square::before {
  content: "\f2e9";
}

.fa-square-down::before {
  content: "\f350";
}

.fa-arrow-alt-square-down::before {
  content: "\f350";
}

.fa-square-down-left::before {
  content: "\e26b";
}

.fa-square-down-right::before {
  content: "\e26c";
}

.fa-square-e::before {
  content: "\e26d";
}

.fa-square-ellipsis::before {
  content: "\e26e";
}

.fa-square-ellipsis-vertical::before {
  content: "\e26f";
}

.fa-square-envelope::before {
  content: "\f199";
}

.fa-envelope-square::before {
  content: "\f199";
}

.fa-square-exclamation::before {
  content: "\f321";
}

.fa-exclamation-square::before {
  content: "\f321";
}

.fa-square-f::before {
  content: "\e270";
}

.fa-square-fragile::before {
  content: "\f49b";
}

.fa-box-fragile::before {
  content: "\f49b";
}

.fa-square-wine-glass-crack::before {
  content: "\f49b";
}

.fa-square-full::before {
  content: "\f45c";
}

.fa-square-g::before {
  content: "\e271";
}

.fa-square-h::before {
  content: "\f0fd";
}

.fa-h-square::before {
  content: "\f0fd";
}

.fa-square-heart::before {
  content: "\f4c8";
}

.fa-heart-square::before {
  content: "\f4c8";
}

.fa-square-i::before {
  content: "\e272";
}

.fa-square-info::before {
  content: "\f30f";
}

.fa-info-square::before {
  content: "\f30f";
}

.fa-square-j::before {
  content: "\e273";
}

.fa-square-k::before {
  content: "\e274";
}

.fa-square-l::before {
  content: "\e275";
}

.fa-square-left::before {
  content: "\f351";
}

.fa-arrow-alt-square-left::before {
  content: "\f351";
}

.fa-square-m::before {
  content: "\e276";
}

.fa-square-minus::before {
  content: "\f146";
}

.fa-minus-square::before {
  content: "\f146";
}

.fa-square-n::before {
  content: "\e277";
}

.fa-square-o::before {
  content: "\e278";
}

.fa-square-p::before {
  content: "\e279";
}

.fa-square-parking::before {
  content: "\f540";
}

.fa-parking::before {
  content: "\f540";
}

.fa-square-parking-slash::before {
  content: "\f617";
}

.fa-parking-slash::before {
  content: "\f617";
}

.fa-square-pen::before {
  content: "\f14b";
}

.fa-pen-square::before {
  content: "\f14b";
}

.fa-pencil-square::before {
  content: "\f14b";
}

.fa-square-phone::before {
  content: "\f098";
}

.fa-phone-square::before {
  content: "\f098";
}

.fa-square-phone-flip::before {
  content: "\f87b";
}

.fa-phone-square-alt::before {
  content: "\f87b";
}

.fa-square-phone-hangup::before {
  content: "\e27a";
}

.fa-phone-square-down::before {
  content: "\e27a";
}

.fa-square-plus::before {
  content: "\f0fe";
}

.fa-plus-square::before {
  content: "\f0fe";
}

.fa-square-poll-horizontal::before {
  content: "\f682";
}

.fa-poll-h::before {
  content: "\f682";
}

.fa-square-poll-vertical::before {
  content: "\f681";
}

.fa-poll::before {
  content: "\f681";
}

.fa-square-q::before {
  content: "\e27b";
}

.fa-square-question::before {
  content: "\f2fd";
}

.fa-question-square::before {
  content: "\f2fd";
}

.fa-square-quote::before {
  content: "\e329";
}

.fa-square-r::before {
  content: "\e27c";
}

.fa-square-right::before {
  content: "\f352";
}

.fa-arrow-alt-square-right::before {
  content: "\f352";
}

.fa-square-root::before {
  content: "\f697";
}

.fa-square-root-variable::before {
  content: "\f698";
}

.fa-square-root-alt::before {
  content: "\f698";
}

.fa-square-rss::before {
  content: "\f143";
}

.fa-rss-square::before {
  content: "\f143";
}

.fa-square-s::before {
  content: "\e27d";
}

.fa-square-share-nodes::before {
  content: "\f1e1";
}

.fa-share-alt-square::before {
  content: "\f1e1";
}

.fa-square-sliders::before {
  content: "\f3f0";
}

.fa-sliders-h-square::before {
  content: "\f3f0";
}

.fa-square-sliders-vertical::before {
  content: "\f3f2";
}

.fa-sliders-v-square::before {
  content: "\f3f2";
}

.fa-square-small::before {
  content: "\e27e";
}

.fa-square-star::before {
  content: "\e27f";
}

.fa-square-t::before {
  content: "\e280";
}

.fa-square-terminal::before {
  content: "\e32a";
}

.fa-square-this-way-up::before {
  content: "\f49f";
}

.fa-box-up::before {
  content: "\f49f";
}

.fa-square-u::before {
  content: "\e281";
}

.fa-square-up::before {
  content: "\f353";
}

.fa-arrow-alt-square-up::before {
  content: "\f353";
}

.fa-square-up-left::before {
  content: "\e282";
}

.fa-square-up-right::before {
  content: "\f360";
}

.fa-external-link-square-alt::before {
  content: "\f360";
}

.fa-square-user::before {
  content: "\e283";
}

.fa-square-v::before {
  content: "\e284";
}

.fa-square-w::before {
  content: "\e285";
}

.fa-square-x::before {
  content: "\e286";
}

.fa-square-xmark::before {
  content: "\f2d3";
}

.fa-times-square::before {
  content: "\f2d3";
}

.fa-xmark-square::before {
  content: "\f2d3";
}

.fa-square-y::before {
  content: "\e287";
}

.fa-square-z::before {
  content: "\e288";
}

.fa-squirrel::before {
  content: "\f71a";
}

.fa-staff::before {
  content: "\f71b";
}

.fa-stairs::before {
  content: "\e289";
}

.fa-stamp::before {
  content: "\f5bf";
}

.fa-standard-definition::before {
  content: "\e28a";
}

.fa-rectangle-sd::before {
  content: "\e28a";
}

.fa-star::before {
  content: "\f005";
}

.fa-star-and-crescent::before {
  content: "\f699";
}

.fa-star-christmas::before {
  content: "\f7d4";
}

.fa-star-exclamation::before {
  content: "\f2f3";
}

.fa-star-half::before {
  content: "\f089";
}

.fa-star-half-stroke::before {
  content: "\f5c0";
}

.fa-star-half-alt::before {
  content: "\f5c0";
}

.fa-star-of-david::before {
  content: "\f69a";
}

.fa-star-of-life::before {
  content: "\f621";
}

.fa-star-sharp::before {
  content: "\e28b";
}

.fa-star-sharp-half::before {
  content: "\e28c";
}

.fa-star-sharp-half-stroke::before {
  content: "\e28d";
}

.fa-star-sharp-half-alt::before {
  content: "\e28d";
}

.fa-star-shooting::before {
  content: "\e036";
}

.fa-starfighter::before {
  content: "\e037";
}

.fa-starfighter-twin-ion-engine::before {
  content: "\e038";
}

.fa-starfighter-alt::before {
  content: "\e038";
}

.fa-starfighter-twin-ion-engine-advanced::before {
  content: "\e28e";
}

.fa-starfighter-alt-advanced::before {
  content: "\e28e";
}

.fa-stars::before {
  content: "\f762";
}

.fa-starship::before {
  content: "\e039";
}

.fa-starship-freighter::before {
  content: "\e03a";
}

.fa-steak::before {
  content: "\f824";
}

.fa-steering-wheel::before {
  content: "\f622";
}

.fa-sterling-sign::before {
  content: "\f154";
}

.fa-gbp::before {
  content: "\f154";
}

.fa-pound-sign::before {
  content: "\f154";
}

.fa-stethoscope::before {
  content: "\f0f1";
}

.fa-stocking::before {
  content: "\f7d5";
}

.fa-stomach::before {
  content: "\f623";
}

.fa-stop::before {
  content: "\f04d";
}

.fa-stopwatch::before {
  content: "\f2f2";
}

.fa-stopwatch-20::before {
  content: "\e06f";
}

.fa-store::before {
  content: "\f54e";
}

.fa-store-slash::before {
  content: "\e071";
}

.fa-strawberry::before {
  content: "\e32b";
}

.fa-street-view::before {
  content: "\f21d";
}

.fa-stretcher::before {
  content: "\f825";
}

.fa-strikethrough::before {
  content: "\f0cc";
}

.fa-stroopwafel::before {
  content: "\f551";
}

.fa-subscript::before {
  content: "\f12c";
}

.fa-suitcase::before {
  content: "\f0f2";
}

.fa-suitcase-medical::before {
  content: "\f0fa";
}

.fa-medkit::before {
  content: "\f0fa";
}

.fa-suitcase-rolling::before {
  content: "\f5c1";
}

.fa-sun::before {
  content: "\f185";
}

.fa-sun-bright::before {
  content: "\e28f";
}

.fa-sun-alt::before {
  content: "\e28f";
}

.fa-sun-cloud::before {
  content: "\f763";
}

.fa-sun-dust::before {
  content: "\f764";
}

.fa-sun-haze::before {
  content: "\f765";
}

.fa-sunglasses::before {
  content: "\f892";
}

.fa-sunrise::before {
  content: "\f766";
}

.fa-sunset::before {
  content: "\f767";
}

.fa-superscript::before {
  content: "\f12b";
}

.fa-swatchbook::before {
  content: "\f5c3";
}

.fa-sword::before {
  content: "\f71c";
}

.fa-sword-laser::before {
  content: "\e03b";
}

.fa-sword-laser-alt::before {
  content: "\e03c";
}

.fa-swords::before {
  content: "\f71d";
}

.fa-swords-laser::before {
  content: "\e03d";
}

.fa-symbols::before {
  content: "\f86e";
}

.fa-icons-alt::before {
  content: "\f86e";
}

.fa-synagogue::before {
  content: "\f69b";
}

.fa-syringe::before {
  content: "\f48e";
}

.fa-t::before {
  content: "\e32c";
}

.fa-table::before {
  content: "\f0ce";
}

.fa-table-cells::before {
  content: "\f00a";
}

.fa-th::before {
  content: "\f00a";
}

.fa-table-cells-large::before {
  content: "\f009";
}

.fa-th-large::before {
  content: "\f009";
}

.fa-table-columns::before {
  content: "\f0db";
}

.fa-columns::before {
  content: "\f0db";
}

.fa-table-layout::before {
  content: "\e290";
}

.fa-table-list::before {
  content: "\f00b";
}

.fa-th-list::before {
  content: "\f00b";
}

.fa-table-picnic::before {
  content: "\e32d";
}

.fa-table-pivot::before {
  content: "\e291";
}

.fa-table-rows::before {
  content: "\e292";
}

.fa-rows::before {
  content: "\e292";
}

.fa-table-tennis-paddle-ball::before {
  content: "\f45d";
}

.fa-ping-pong-paddle-ball::before {
  content: "\f45d";
}

.fa-table-tennis::before {
  content: "\f45d";
}

.fa-table-tree::before {
  content: "\e293";
}

.fa-tablet::before {
  content: "\f3fb";
}

.fa-tablet-android::before {
  content: "\f3fb";
}

.fa-tablet-button::before {
  content: "\f10a";
}

.fa-tablet-rugged::before {
  content: "\f48f";
}

.fa-tablet-screen::before {
  content: "\f3fc";
}

.fa-tablet-android-alt::before {
  content: "\f3fc";
}

.fa-tablet-screen-button::before {
  content: "\f3fa";
}

.fa-tablet-alt::before {
  content: "\f3fa";
}

.fa-tablets::before {
  content: "\f490";
}

.fa-tachograph-digital::before {
  content: "\f566";
}

.fa-digital-tachograph::before {
  content: "\f566";
}

.fa-taco::before {
  content: "\f826";
}

.fa-tag::before {
  content: "\f02b";
}

.fa-tags::before {
  content: "\f02c";
}

.fa-tally::before {
  content: "\f69c";
}

.fa-tally-5::before {
  content: "\f69c";
}

.fa-tally-1::before {
  content: "\e294";
}

.fa-tally-2::before {
  content: "\e295";
}

.fa-tally-3::before {
  content: "\e296";
}

.fa-tally-4::before {
  content: "\e297";
}

.fa-tape::before {
  content: "\f4db";
}

.fa-taxi::before {
  content: "\f1ba";
}

.fa-cab::before {
  content: "\f1ba";
}

.fa-taxi-bus::before {
  content: "\e298";
}

.fa-teeth::before {
  content: "\f62e";
}

.fa-teeth-open::before {
  content: "\f62f";
}

.fa-telescope::before {
  content: "\e03e";
}

.fa-temperature-arrow-down::before {
  content: "\e03f";
}

.fa-temperature-down::before {
  content: "\e03f";
}

.fa-temperature-arrow-up::before {
  content: "\e040";
}

.fa-temperature-up::before {
  content: "\e040";
}

.fa-temperature-empty::before {
  content: "\f2cb";
}

.fa-temperature-0::before {
  content: "\f2cb";
}

.fa-thermometer-0::before {
  content: "\f2cb";
}

.fa-thermometer-empty::before {
  content: "\f2cb";
}

.fa-temperature-full::before {
  content: "\f2c7";
}

.fa-temperature-4::before {
  content: "\f2c7";
}

.fa-thermometer-4::before {
  content: "\f2c7";
}

.fa-thermometer-full::before {
  content: "\f2c7";
}

.fa-temperature-half::before {
  content: "\f2c9";
}

.fa-temperature-2::before {
  content: "\f2c9";
}

.fa-thermometer-2::before {
  content: "\f2c9";
}

.fa-thermometer-half::before {
  content: "\f2c9";
}

.fa-temperature-high::before {
  content: "\f769";
}

.fa-temperature-list::before {
  content: "\e299";
}

.fa-temperature-low::before {
  content: "\f76b";
}

.fa-temperature-quarter::before {
  content: "\f2ca";
}

.fa-temperature-1::before {
  content: "\f2ca";
}

.fa-thermometer-1::before {
  content: "\f2ca";
}

.fa-thermometer-quarter::before {
  content: "\f2ca";
}

.fa-temperature-snow::before {
  content: "\f768";
}

.fa-temperature-frigid::before {
  content: "\f768";
}

.fa-temperature-sun::before {
  content: "\f76a";
}

.fa-temperature-hot::before {
  content: "\f76a";
}

.fa-temperature-three-quarters::before {
  content: "\f2c8";
}

.fa-temperature-3::before {
  content: "\f2c8";
}

.fa-thermometer-3::before {
  content: "\f2c8";
}

.fa-thermometer-three-quarters::before {
  content: "\f2c8";
}

.fa-tenge-sign::before {
  content: "\f7d7";
}

.fa-tenge::before {
  content: "\f7d7";
}

.fa-tennis-ball::before {
  content: "\f45e";
}

.fa-terminal::before {
  content: "\f120";
}

.fa-text::before {
  content: "\f893";
}

.fa-text-height::before {
  content: "\f034";
}

.fa-text-size::before {
  content: "\f894";
}

.fa-text-slash::before {
  content: "\f87d";
}

.fa-remove-format::before {
  content: "\f87d";
}

.fa-text-width::before {
  content: "\f035";
}

.fa-thermometer::before {
  content: "\f491";
}

.fa-theta::before {
  content: "\f69e";
}

.fa-thought-bubble::before {
  content: "\e32e";
}

.fa-thumbs-down::before {
  content: "\f165";
}

.fa-thumbs-up::before {
  content: "\f164";
}

.fa-thumbtack::before {
  content: "\f08d";
}

.fa-thumb-tack::before {
  content: "\f08d";
}

.fa-tick::before {
  content: "\e32f";
}

.fa-ticket::before {
  content: "\f145";
}

.fa-ticket-airline::before {
  content: "\e29a";
}

.fa-ticket-simple::before {
  content: "\f3ff";
}

.fa-ticket-alt::before {
  content: "\f3ff";
}

.fa-tickets-airline::before {
  content: "\e29b";
}

.fa-tilde::before {
  content: "\f69f";
}

.fa-timeline::before {
  content: "\e29c";
}

.fa-timeline-arrow::before {
  content: "\e29d";
}

.fa-timer::before {
  content: "\e29e";
}

.fa-tire::before {
  content: "\f631";
}

.fa-tire-flat::before {
  content: "\f632";
}

.fa-tire-pressure-warning::before {
  content: "\f633";
}

.fa-tire-rugged::before {
  content: "\f634";
}

.fa-toggle-off::before {
  content: "\f204";
}

.fa-toggle-on::before {
  content: "\f205";
}

.fa-toilet::before {
  content: "\f7d8";
}

.fa-toilet-paper::before {
  content: "\f71e";
}

.fa-toilet-paper-blank::before {
  content: "\f71f";
}

.fa-toilet-paper-alt::before {
  content: "\f71f";
}

.fa-toilet-paper-blank-under::before {
  content: "\e29f";
}

.fa-toilet-paper-reverse-alt::before {
  content: "\e29f";
}

.fa-toilet-paper-slash::before {
  content: "\e072";
}

.fa-toilet-paper-under::before {
  content: "\e2a0";
}

.fa-toilet-paper-reverse::before {
  content: "\e2a0";
}

.fa-toilet-paper-under-slash::before {
  content: "\e2a1";
}

.fa-toilet-paper-reverse-slash::before {
  content: "\e2a1";
}

.fa-tomato::before {
  content: "\e330";
}

.fa-tombstone::before {
  content: "\f720";
}

.fa-tombstone-blank::before {
  content: "\f721";
}

.fa-tombstone-alt::before {
  content: "\f721";
}

.fa-toolbox::before {
  content: "\f552";
}

.fa-tooth::before {
  content: "\f5c9";
}

.fa-toothbrush::before {
  content: "\f635";
}

.fa-torii-gate::before {
  content: "\f6a1";
}

.fa-tornado::before {
  content: "\f76f";
}

.fa-tower-broadcast::before {
  content: "\f519";
}

.fa-broadcast-tower::before {
  content: "\f519";
}

.fa-tower-control::before {
  content: "\e2a2";
}

.fa-tractor::before {
  content: "\f722";
}

.fa-trademark::before {
  content: "\f25c";
}

.fa-traffic-cone::before {
  content: "\f636";
}

.fa-traffic-light::before {
  content: "\f637";
}

.fa-traffic-light-go::before {
  content: "\f638";
}

.fa-traffic-light-slow::before {
  content: "\f639";
}

.fa-traffic-light-stop::before {
  content: "\f63a";
}

.fa-trailer::before {
  content: "\e041";
}

.fa-train::before {
  content: "\f238";
}

.fa-train-subway::before {
  content: "\f239";
}

.fa-subway::before {
  content: "\f239";
}

.fa-train-subway-tunnel::before {
  content: "\e2a3";
}

.fa-subway-tunnel::before {
  content: "\e2a3";
}

.fa-train-tram::before {
  content: "\f7da";
}

.fa-tram::before {
  content: "\f7da";
}

.fa-transformer-bolt::before {
  content: "\e2a4";
}

.fa-transgender::before {
  content: "\f224";
}

.fa-transgender-alt::before {
  content: "\f225";
}

.fa-transporter::before {
  content: "\e042";
}

.fa-transporter-1::before {
  content: "\e043";
}

.fa-transporter-2::before {
  content: "\e044";
}

.fa-transporter-3::before {
  content: "\e045";
}

.fa-transporter-4::before {
  content: "\e2a5";
}

.fa-transporter-5::before {
  content: "\e2a6";
}

.fa-transporter-6::before {
  content: "\e2a7";
}

.fa-transporter-7::before {
  content: "\e2a8";
}

.fa-transporter-empty::before {
  content: "\e046";
}

.fa-trash::before {
  content: "\f1f8";
}

.fa-trash-arrow-up::before {
  content: "\f829";
}

.fa-trash-restore::before {
  content: "\f829";
}

.fa-trash-can::before {
  content: "\f2ed";
}

.fa-trash-alt::before {
  content: "\f2ed";
}

.fa-trash-can-arrow-up::before {
  content: "\f82a";
}

.fa-trash-restore-alt::before {
  content: "\f82a";
}

.fa-trash-can-check::before {
  content: "\e2a9";
}

.fa-trash-can-clock::before {
  content: "\e2aa";
}

.fa-trash-can-list::before {
  content: "\e2ab";
}

.fa-trash-can-plus::before {
  content: "\e2ac";
}

.fa-trash-can-slash::before {
  content: "\e2ad";
}

.fa-trash-alt-slash::before {
  content: "\e2ad";
}

.fa-trash-can-undo::before {
  content: "\f896";
}

.fa-trash-can-arrow-turn-left::before {
  content: "\f896";
}

.fa-trash-undo-alt::before {
  content: "\f896";
}

.fa-trash-can-xmark::before {
  content: "\e2ae";
}

.fa-trash-check::before {
  content: "\e2af";
}

.fa-trash-clock::before {
  content: "\e2b0";
}

.fa-trash-list::before {
  content: "\e2b1";
}

.fa-trash-plus::before {
  content: "\e2b2";
}

.fa-trash-slash::before {
  content: "\e2b3";
}

.fa-trash-undo::before {
  content: "\f895";
}

.fa-trash-arrow-turn-left::before {
  content: "\f895";
}

.fa-trash-xmark::before {
  content: "\e2b4";
}

.fa-treasure-chest::before {
  content: "\f723";
}

.fa-tree::before {
  content: "\f1bb";
}

.fa-tree-christmas::before {
  content: "\f7db";
}

.fa-tree-deciduous::before {
  content: "\f400";
}

.fa-tree-alt::before {
  content: "\f400";
}

.fa-tree-decorated::before {
  content: "\f7dc";
}

.fa-tree-large::before {
  content: "\f7dd";
}

.fa-tree-palm::before {
  content: "\f82b";
}

.fa-trees::before {
  content: "\f724";
}

.fa-triangle::before {
  content: "\f2ec";
}

.fa-triangle-exclamation::before {
  content: "\f071";
}

.fa-exclamation-triangle::before {
  content: "\f071";
}

.fa-warning::before {
  content: "\f071";
}

.fa-triangle-instrument::before {
  content: "\f8e2";
}

.fa-triangle-music::before {
  content: "\f8e2";
}

.fa-triangle-person-digging::before {
  content: "\f85d";
}

.fa-construction::before {
  content: "\f85d";
}

.fa-trophy::before {
  content: "\f091";
}

.fa-trophy-star::before {
  content: "\f2eb";
}

.fa-trophy-alt::before {
  content: "\f2eb";
}

.fa-truck::before {
  content: "\f0d1";
}

.fa-truck-clock::before {
  content: "\f48c";
}

.fa-shipping-timed::before {
  content: "\f48c";
}

.fa-truck-container::before {
  content: "\f4dc";
}

.fa-truck-container-empty::before {
  content: "\e2b5";
}

.fa-truck-fast::before {
  content: "\f48b";
}

.fa-shipping-fast::before {
  content: "\f48b";
}

.fa-truck-flatbed::before {
  content: "\e2b6";
}

.fa-truck-front::before {
  content: "\e2b7";
}

.fa-truck-medical::before {
  content: "\f0f9";
}

.fa-ambulance::before {
  content: "\f0f9";
}

.fa-truck-monster::before {
  content: "\f63b";
}

.fa-truck-moving::before {
  content: "\f4df";
}

.fa-truck-pickup::before {
  content: "\f63c";
}

.fa-truck-plow::before {
  content: "\f7de";
}

.fa-truck-ramp::before {
  content: "\f4e0";
}

.fa-truck-ramp-box::before {
  content: "\f4de";
}

.fa-truck-loading::before {
  content: "\f4de";
}

.fa-truck-ramp-couch::before {
  content: "\f4dd";
}

.fa-truck-couch::before {
  content: "\f4dd";
}

.fa-truck-tow::before {
  content: "\e2b8";
}

.fa-trumpet::before {
  content: "\f8e3";
}

.fa-tshirt::before {
  content: "\f553";
}

.fa-tty::before {
  content: "\f1e4";
}

.fa-teletype::before {
  content: "\f1e4";
}

.fa-tty-answer::before {
  content: "\e2b9";
}

.fa-teletype-answer::before {
  content: "\e2b9";
}

.fa-tugrik-sign::before {
  content: "\e2ba";
}

.fa-turkey::before {
  content: "\f725";
}

.fa-turkish-lira-sign::before {
  content: "\e2bb";
}

.fa-try::before {
  content: "\e2bb";
}

.fa-turkish-lira::before {
  content: "\e2bb";
}

.fa-turn-down::before {
  content: "\f3be";
}

.fa-level-down-alt::before {
  content: "\f3be";
}

.fa-turn-down-left::before {
  content: "\e331";
}

.fa-turn-up::before {
  content: "\f3bf";
}

.fa-level-up-alt::before {
  content: "\f3bf";
}

.fa-turntable::before {
  content: "\f8e4";
}

.fa-turtle::before {
  content: "\f726";
}

.fa-tv::before {
  content: "\f26c";
}

.fa-television::before {
  content: "\f26c";
}

.fa-tv-alt::before {
  content: "\f26c";
}

.fa-tv-music::before {
  content: "\f8e6";
}

.fa-tv-retro::before {
  content: "\f401";
}

.fa-typewriter::before {
  content: "\f8e7";
}

.fa-u::before {
  content: "\e332";
}

.fa-ufo::before {
  content: "\e047";
}

.fa-ufo-beam::before {
  content: "\e048";
}

.fa-umbrella::before {
  content: "\f0e9";
}

.fa-umbrella-beach::before {
  content: "\f5ca";
}

.fa-umbrella-simple::before {
  content: "\e2bc";
}

.fa-umbrella-alt::before {
  content: "\e2bc";
}

.fa-underline::before {
  content: "\f0cd";
}

.fa-unicorn::before {
  content: "\f727";
}

.fa-union::before {
  content: "\f6a2";
}

.fa-universal-access::before {
  content: "\f29a";
}

.fa-unlock::before {
  content: "\f09c";
}

.fa-unlock-keyhole::before {
  content: "\f13e";
}

.fa-unlock-alt::before {
  content: "\f13e";
}

.fa-up::before {
  content: "\f357";
}

.fa-arrow-alt-up::before {
  content: "\f357";
}

.fa-up-down::before {
  content: "\f338";
}

.fa-arrows-alt-v::before {
  content: "\f338";
}

.fa-up-down-left-right::before {
  content: "\f0b2";
}

.fa-arrows-alt::before {
  content: "\f0b2";
}

.fa-up-from-line::before {
  content: "\f346";
}

.fa-arrow-alt-from-bottom::before {
  content: "\f346";
}

.fa-up-left::before {
  content: "\e2bd";
}

.fa-up-long::before {
  content: "\f30c";
}

.fa-long-arrow-alt-up::before {
  content: "\f30c";
}

.fa-up-right::before {
  content: "\e2be";
}

.fa-up-right-and-down-left-from-center::before {
  content: "\f424";
}

.fa-expand-alt::before {
  content: "\f424";
}

.fa-up-right-from-square::before {
  content: "\f35d";
}

.fa-external-link-alt::before {
  content: "\f35d";
}

.fa-up-to-line::before {
  content: "\f34d";
}

.fa-arrow-alt-to-top::before {
  content: "\f34d";
}

.fa-upload::before {
  content: "\f093";
}

.fa-usb-drive::before {
  content: "\f8e9";
}

.fa-user::before {
  content: "\f007";
}

.fa-user-alien::before {
  content: "\e04a";
}

.fa-user-astronaut::before {
  content: "\f4fb";
}

.fa-user-bounty-hunter::before {
  content: "\e2bf";
}

.fa-user-check::before {
  content: "\f4fc";
}

.fa-user-clock::before {
  content: "\f4fd";
}

.fa-user-cowboy::before {
  content: "\f8ea";
}

.fa-user-crown::before {
  content: "\f6a4";
}

.fa-user-doctor::before {
  content: "\f0f0";
}

.fa-user-md::before {
  content: "\f0f0";
}

.fa-user-doctor-message::before {
  content: "\f82e";
}

.fa-user-md-chat::before {
  content: "\f82e";
}

.fa-user-gear::before {
  content: "\f4fe";
}

.fa-user-cog::before {
  content: "\f4fe";
}

.fa-user-graduate::before {
  content: "\f501";
}

.fa-user-group::before {
  content: "\f500";
}

.fa-user-friends::before {
  content: "\f500";
}

.fa-user-group-crown::before {
  content: "\f6a5";
}

.fa-users-crown::before {
  content: "\f6a5";
}

.fa-user-headset::before {
  content: "\f82d";
}

.fa-user-helmet-safety::before {
  content: "\f82c";
}

.fa-user-construction::before {
  content: "\f82c";
}

.fa-user-hard-hat::before {
  content: "\f82c";
}

.fa-user-injured::before {
  content: "\f728";
}

.fa-user-large::before {
  content: "\f406";
}

.fa-user-alt::before {
  content: "\f406";
}

.fa-user-large-slash::before {
  content: "\f4fa";
}

.fa-user-alt-slash::before {
  content: "\f4fa";
}

.fa-user-lock::before {
  content: "\f502";
}

.fa-user-minus::before {
  content: "\f503";
}

.fa-user-music::before {
  content: "\f8eb";
}

.fa-user-ninja::before {
  content: "\f504";
}

.fa-user-nurse::before {
  content: "\f82f";
}

.fa-user-pen::before {
  content: "\f4ff";
}

.fa-user-edit::before {
  content: "\f4ff";
}

.fa-user-pilot::before {
  content: "\e2c0";
}

.fa-user-pilot-tie::before {
  content: "\e2c1";
}

.fa-user-plus::before {
  content: "\f234";
}

.fa-user-police::before {
  content: "\e333";
}

.fa-user-police-tie::before {
  content: "\e334";
}

.fa-user-robot::before {
  content: "\e04b";
}

.fa-user-secret::before {
  content: "\f21b";
}

.fa-user-shakespeare::before {
  content: "\e2c2";
}

.fa-user-shield::before {
  content: "\f505";
}

.fa-user-slash::before {
  content: "\f506";
}

.fa-user-tag::before {
  content: "\f507";
}

.fa-user-tie::before {
  content: "\f508";
}

.fa-user-unlock::before {
  content: "\e058";
}

.fa-user-visor::before {
  content: "\e04c";
}

.fa-user-xmark::before {
  content: "\f235";
}

.fa-user-times::before {
  content: "\f235";
}

.fa-users::before {
  content: "\f0c0";
}

.fa-group::before {
  content: "\f0c0";
}

.fa-users-gear::before {
  content: "\f509";
}

.fa-users-cog::before {
  content: "\f509";
}

.fa-users-medical::before {
  content: "\f830";
}

.fa-users-slash::before {
  content: "\e073";
}

.fa-utensils::before {
  content: "\f2e7";
}

.fa-cutlery::before {
  content: "\f2e7";
}

.fa-utility-pole::before {
  content: "\e2c3";
}

.fa-utility-pole-double::before {
  content: "\e2c4";
}

.fa-v::before {
  content: "\e335";
}

.fa-vacuum::before {
  content: "\e04d";
}

.fa-vacuum-robot::before {
  content: "\e04e";
}

.fa-value-absolute::before {
  content: "\f6a6";
}

.fa-van-shuttle::before {
  content: "\f5b6";
}

.fa-shuttle-van::before {
  content: "\f5b6";
}

.fa-vault::before {
  content: "\e2c5";
}

.fa-vector-circle::before {
  content: "\e2c6";
}

.fa-vector-polygon::before {
  content: "\e2c7";
}

.fa-vector-square::before {
  content: "\f5cb";
}

.fa-venus::before {
  content: "\f221";
}

.fa-venus-double::before {
  content: "\f226";
}

.fa-venus-mars::before {
  content: "\f228";
}

.fa-vest::before {
  content: "\e085";
}

.fa-vest-patches::before {
  content: "\e086";
}

.fa-vial::before {
  content: "\f492";
}

.fa-vials::before {
  content: "\f493";
}

.fa-video::before {
  content: "\f03d";
}

.fa-video-camera::before {
  content: "\f03d";
}

.fa-video-arrow-down-left::before {
  content: "\e2c8";
}

.fa-video-arrow-up-right::before {
  content: "\e2c9";
}

.fa-video-plus::before {
  content: "\f4e1";
}

.fa-video-slash::before {
  content: "\f4e2";
}

.fa-vihara::before {
  content: "\f6a7";
}

.fa-violin::before {
  content: "\f8ed";
}

.fa-virus::before {
  content: "\e074";
}

.fa-virus-slash::before {
  content: "\e075";
}

.fa-viruses::before {
  content: "\e076";
}

.fa-voicemail::before {
  content: "\f897";
}

.fa-volcano::before {
  content: "\f770";
}

.fa-volleyball-ball::before {
  content: "\f45f";
}

.fa-volume::before {
  content: "\f6a8";
}

.fa-volume-medium::before {
  content: "\f6a8";
}

.fa-volume-high::before {
  content: "\f028";
}

.fa-volume-up::before {
  content: "\f028";
}

.fa-volume-low::before {
  content: "\f027";
}

.fa-volume-down::before {
  content: "\f027";
}

.fa-volume-off::before {
  content: "\f026";
}

.fa-volume-slash::before {
  content: "\f2e2";
}

.fa-volume-xmark::before {
  content: "\f6a9";
}

.fa-volume-mute::before {
  content: "\f6a9";
}

.fa-volume-times::before {
  content: "\f6a9";
}

.fa-vr-cardboard::before {
  content: "\f729";
}

.fa-w::before {
  content: "\e336";
}

.fa-wagon-covered::before {
  content: "\f8ee";
}

.fa-walker::before {
  content: "\f831";
}

.fa-walkie-talkie::before {
  content: "\f8ef";
}

.fa-wallet::before {
  content: "\f555";
}

.fa-wand::before {
  content: "\f72a";
}

.fa-wand-magic::before {
  content: "\f0d0";
}

.fa-magic::before {
  content: "\f0d0";
}

.fa-wand-magic-sparkles::before {
  content: "\e2ca";
}

.fa-magic-wand-sparkles::before {
  content: "\e2ca";
}

.fa-wand-sparkles::before {
  content: "\f72b";
}

.fa-warehouse::before {
  content: "\f494";
}

.fa-warehouse-full::before {
  content: "\f495";
}

.fa-warehouse-alt::before {
  content: "\f495";
}

.fa-washing-machine::before {
  content: "\f898";
}

.fa-washer::before {
  content: "\f898";
}

.fa-watch::before {
  content: "\f2e1";
}

.fa-watch-apple::before {
  content: "\e2cb";
}

.fa-watch-calculator::before {
  content: "\f8f0";
}

.fa-watch-fitness::before {
  content: "\f63e";
}

.fa-watch-smart::before {
  content: "\e2cc";
}

.fa-water::before {
  content: "\f773";
}

.fa-water-arrow-down::before {
  content: "\f774";
}

.fa-water-lower::before {
  content: "\f774";
}

.fa-water-arrow-up::before {
  content: "\f775";
}

.fa-water-rise::before {
  content: "\f775";
}

.fa-water-ladder::before {
  content: "\f5c5";
}

.fa-ladder-water::before {
  content: "\f5c5";
}

.fa-swimming-pool::before {
  content: "\f5c5";
}

.fa-watermelon-slice::before {
  content: "\e337";
}

.fa-wave-pulse::before {
  content: "\f5f8";
}

.fa-heart-rate::before {
  content: "\f5f8";
}

.fa-wave-sine::before {
  content: "\f899";
}

.fa-wave-square::before {
  content: "\f83e";
}

.fa-wave-triangle::before {
  content: "\f89a";
}

.fa-waveform::before {
  content: "\f8f1";
}

.fa-waveform-lines::before {
  content: "\f8f2";
}

.fa-weight-hanging::before {
  content: "\f5cd";
}

.fa-weight-scale::before {
  content: "\f496";
}

.fa-weight::before {
  content: "\f496";
}

.fa-whale::before {
  content: "\f72c";
}

.fa-wheat::before {
  content: "\f72d";
}

.fa-wheat-awn::before {
  content: "\e2cd";
}

.fa-wheat-alt::before {
  content: "\e2cd";
}

.fa-wheat-awn-slash::before {
  content: "\e338";
}

.fa-wheat-slash::before {
  content: "\e339";
}

.fa-wheelchair::before {
  content: "\f193";
}

.fa-wheelchair-move::before {
  content: "\e2ce";
}

.fa-wheelchair-alt::before {
  content: "\e2ce";
}

.fa-whiskey-glass::before {
  content: "\f7a0";
}

.fa-glass-whiskey::before {
  content: "\f7a0";
}

.fa-whiskey-glass-ice::before {
  content: "\f7a1";
}

.fa-glass-whiskey-rocks::before {
  content: "\f7a1";
}

.fa-whistle::before {
  content: "\f460";
}

.fa-wifi::before {
  content: "\f1eb";
}

.fa-wifi-3::before {
  content: "\f1eb";
}

.fa-wifi-strong::before {
  content: "\f1eb";
}

.fa-wifi-exclamation::before {
  content: "\e2cf";
}

.fa-wifi-fair::before {
  content: "\f6ab";
}

.fa-wifi-2::before {
  content: "\f6ab";
}

.fa-wifi-slash::before {
  content: "\f6ac";
}

.fa-wifi-weak::before {
  content: "\f6aa";
}

.fa-wifi-1::before {
  content: "\f6aa";
}

.fa-wind::before {
  content: "\f72e";
}

.fa-wind-turbine::before {
  content: "\f89b";
}

.fa-wind-warning::before {
  content: "\f776";
}

.fa-wind-circle-exclamation::before {
  content: "\f776";
}

.fa-window::before {
  content: "\f40e";
}

.fa-window-flip::before {
  content: "\f40f";
}

.fa-window-alt::before {
  content: "\f40f";
}

.fa-window-frame::before {
  content: "\e04f";
}

.fa-window-frame-open::before {
  content: "\e050";
}

.fa-window-maximize::before {
  content: "\f2d0";
}

.fa-window-minimize::before {
  content: "\f2d1";
}

.fa-window-restore::before {
  content: "\f2d2";
}

.fa-windsock::before {
  content: "\f777";
}

.fa-wine-bottle::before {
  content: "\f72f";
}

.fa-wine-glass::before {
  content: "\f4e3";
}

.fa-wine-glass-crack::before {
  content: "\f4bb";
}

.fa-fragile::before {
  content: "\f4bb";
}

.fa-wine-glass-empty::before {
  content: "\f5ce";
}

.fa-wine-glass-alt::before {
  content: "\f5ce";
}

.fa-won-sign::before {
  content: "\f159";
}

.fa-krw::before {
  content: "\f159";
}

.fa-won::before {
  content: "\f159";
}

.fa-wreath::before {
  content: "\f7e2";
}

.fa-wrench::before {
  content: "\f0ad";
}

.fa-wrench-simple::before {
  content: "\e2d1";
}

.fa-x::before {
  content: "\e33a";
}

.fa-x-ray::before {
  content: "\f497";
}

.fa-xmark::before {
  content: "\f00d";
}

.fa-close::before {
  content: "\f00d";
}

.fa-multiply::before {
  content: "\f00d";
}

.fa-remove::before {
  content: "\f00d";
}

.fa-times::before {
  content: "\f00d";
}

.fa-xmark-to-slot::before {
  content: "\f771";
}

.fa-times-to-slot::before {
  content: "\f771";
}

.fa-vote-nay::before {
  content: "\f771";
}

.fa-y::before {
  content: "\e33b";
}

.fa-yen-sign::before {
  content: "\f157";
}

.fa-cny::before {
  content: "\f157";
}

.fa-jpy::before {
  content: "\f157";
}

.fa-rmb::before {
  content: "\f157";
}

.fa-yen::before {
  content: "\f157";
}

.fa-yin-yang::before {
  content: "\f6ad";
}

.fa-z::before {
  content: "\e33c";
}

.sr-only,
.fa-sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
}

.sr-only-focusable:not(:focus),
.fa-sr-only-focusable:not(:focus) {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
}

@font-face {
  font-family: 'Font Awesome 6 Brands';
  font-style: normal;
  font-weight: 400;
  font-display: block;
  src: url("../fonts/fa-brands-400.woff2") format("woff2"), url("../fonts/fa-brands-400.woff") format("woff"), url("../fonts/fa-brands-400.ttf") format("truetype");
}

.fab,
.fa-brands {
  font-family: 'Font Awesome 6 Brands';
  font-weight: 400;
}

.fa-500px:before {
  content: "\f26e";
}

.fa-accessible-icon:before {
  content: "\f368";
}

.fa-accusoft:before {
  content: "\f369";
}

.fa-acquisitions-incorporated:before {
  content: "\f6af";
}

.fa-adn:before {
  content: "\f170";
}

.fa-adversal:before {
  content: "\f36a";
}

.fa-affiliatetheme:before {
  content: "\f36b";
}

.fa-airbnb:before {
  content: "\f834";
}

.fa-algolia:before {
  content: "\f36c";
}

.fa-alipay:before {
  content: "\f642";
}

.fa-amazon:before {
  content: "\f270";
}

.fa-amazon-pay:before {
  content: "\f42c";
}

.fa-amilia:before {
  content: "\f36d";
}

.fa-android:before {
  content: "\f17b";
}

.fa-angellist:before {
  content: "\f209";
}

.fa-angrycreative:before {
  content: "\f36e";
}

.fa-angular:before {
  content: "\f420";
}

.fa-app-store:before {
  content: "\f36f";
}

.fa-app-store-ios:before {
  content: "\f370";
}

.fa-apper:before {
  content: "\f371";
}

.fa-apple:before {
  content: "\f179";
}

.fa-apple-pay:before {
  content: "\f415";
}

.fa-artstation:before {
  content: "\f77a";
}

.fa-asymmetrik:before {
  content: "\f372";
}

.fa-atlassian:before {
  content: "\f77b";
}

.fa-audible:before {
  content: "\f373";
}

.fa-autoprefixer:before {
  content: "\f41c";
}

.fa-avianex:before {
  content: "\f374";
}

.fa-aviato:before {
  content: "\f421";
}

.fa-aws:before {
  content: "\f375";
}

.fa-bandcamp:before {
  content: "\f2d5";
}

.fa-battle-net:before {
  content: "\f835";
}

.fa-behance:before {
  content: "\f1b4";
}

.fa-behance-square:before {
  content: "\f1b5";
}

.fa-bimobject:before {
  content: "\f378";
}

.fa-bitbucket:before {
  content: "\f171";
}

.fa-bitcoin:before {
  content: "\f379";
}

.fa-bity:before {
  content: "\f37a";
}

.fa-black-tie:before {
  content: "\f27e";
}

.fa-blackberry:before {
  content: "\f37b";
}

.fa-blogger:before {
  content: "\f37c";
}

.fa-blogger-b:before {
  content: "\f37d";
}

.fa-bluetooth:before {
  content: "\f293";
}

.fa-bluetooth-b:before {
  content: "\f294";
}

.fa-bootstrap:before {
  content: "\f836";
}

.fa-btc:before {
  content: "\f15a";
}

.fa-buffer:before {
  content: "\f837";
}

.fa-buromobelexperte:before {
  content: "\f37f";
}

.fa-buy-n-large:before {
  content: "\f8a6";
}

.fa-buysellads:before {
  content: "\f20d";
}

.fa-canadian-maple-leaf:before {
  content: "\f785";
}

.fa-cc-amazon-pay:before {
  content: "\f42d";
}

.fa-cc-amex:before {
  content: "\f1f3";
}

.fa-cc-apple-pay:before {
  content: "\f416";
}

.fa-cc-diners-club:before {
  content: "\f24c";
}

.fa-cc-discover:before {
  content: "\f1f2";
}

.fa-cc-jcb:before {
  content: "\f24b";
}

.fa-cc-mastercard:before {
  content: "\f1f1";
}

.fa-cc-paypal:before {
  content: "\f1f4";
}

.fa-cc-stripe:before {
  content: "\f1f5";
}

.fa-cc-visa:before {
  content: "\f1f0";
}

.fa-centercode:before {
  content: "\f380";
}

.fa-centos:before {
  content: "\f789";
}

.fa-chrome:before {
  content: "\f268";
}

.fa-chromecast:before {
  content: "\f838";
}

.fa-cloudflare:before {
  content: "\e07d";
}

.fa-cloudscale:before {
  content: "\f383";
}

.fa-cloudsmith:before {
  content: "\f384";
}

.fa-cloudversify:before {
  content: "\f385";
}

.fa-codepen:before {
  content: "\f1cb";
}

.fa-codiepie:before {
  content: "\f284";
}

.fa-confluence:before {
  content: "\f78d";
}

.fa-connectdevelop:before {
  content: "\f20e";
}

.fa-contao:before {
  content: "\f26d";
}

.fa-cotton-bureau:before {
  content: "\f89e";
}

.fa-cpanel:before {
  content: "\f388";
}

.fa-creative-commons:before {
  content: "\f25e";
}

.fa-creative-commons-by:before {
  content: "\f4e7";
}

.fa-creative-commons-nc:before {
  content: "\f4e8";
}

.fa-creative-commons-nc-eu:before {
  content: "\f4e9";
}

.fa-creative-commons-nc-jp:before {
  content: "\f4ea";
}

.fa-creative-commons-nd:before {
  content: "\f4eb";
}

.fa-creative-commons-pd:before {
  content: "\f4ec";
}

.fa-creative-commons-pd-alt:before {
  content: "\f4ed";
}

.fa-creative-commons-remix:before {
  content: "\f4ee";
}

.fa-creative-commons-sa:before {
  content: "\f4ef";
}

.fa-creative-commons-sampling:before {
  content: "\f4f0";
}

.fa-creative-commons-sampling-plus:before {
  content: "\f4f1";
}

.fa-creative-commons-share:before {
  content: "\f4f2";
}

.fa-creative-commons-zero:before {
  content: "\f4f3";
}

.fa-critical-role:before {
  content: "\f6c9";
}

.fa-css3:before {
  content: "\f13c";
}

.fa-css3-alt:before {
  content: "\f38b";
}

.fa-cuttlefish:before {
  content: "\f38c";
}

.fa-d-and-d:before {
  content: "\f38d";
}

.fa-d-and-d-beyond:before {
  content: "\f6ca";
}

.fa-dailymotion:before {
  content: "\e052";
}

.fa-dashcube:before {
  content: "\f210";
}

.fa-deezer:before {
  content: "\e077";
}

.fa-delicious:before {
  content: "\f1a5";
}

.fa-deploydog:before {
  content: "\f38e";
}

.fa-deskpro:before {
  content: "\f38f";
}

.fa-dev:before {
  content: "\f6cc";
}

.fa-deviantart:before {
  content: "\f1bd";
}

.fa-dhl:before {
  content: "\f790";
}

.fa-diaspora:before {
  content: "\f791";
}

.fa-digg:before {
  content: "\f1a6";
}

.fa-digital-ocean:before {
  content: "\f391";
}

.fa-discord:before {
  content: "\f392";
}

.fa-discourse:before {
  content: "\f393";
}

.fa-dochub:before {
  content: "\f394";
}

.fa-docker:before {
  content: "\f395";
}

.fa-draft2digital:before {
  content: "\f396";
}

.fa-dribbble:before {
  content: "\f17d";
}

.fa-dribbble-square:before {
  content: "\f397";
}

.fa-dropbox:before {
  content: "\f16b";
}

.fa-drupal:before {
  content: "\f1a9";
}

.fa-dyalog:before {
  content: "\f399";
}

.fa-earlybirds:before {
  content: "\f39a";
}

.fa-ebay:before {
  content: "\f4f4";
}

.fa-edge:before {
  content: "\f282";
}

.fa-edge-legacy:before {
  content: "\e078";
}

.fa-elementor:before {
  content: "\f430";
}

.fa-ello:before {
  content: "\f5f1";
}

.fa-ember:before {
  content: "\f423";
}

.fa-empire:before {
  content: "\f1d1";
}

.fa-envira:before {
  content: "\f299";
}

.fa-erlang:before {
  content: "\f39d";
}

.fa-ethereum:before {
  content: "\f42e";
}

.fa-etsy:before {
  content: "\f2d7";
}

.fa-evernote:before {
  content: "\f839";
}

.fa-expeditedssl:before {
  content: "\f23e";
}

.fa-facebook:before {
  content: "\f09a";
}

.fa-facebook-f:before {
  content: "\f39e";
}

.fa-facebook-messenger:before {
  content: "\f39f";
}

.fa-facebook-square:before {
  content: "\f082";
}

.fa-fantasy-flight-games:before {
  content: "\f6dc";
}

.fa-fedex:before {
  content: "\f797";
}

.fa-fedora:before {
  content: "\f798";
}

.fa-figma:before {
  content: "\f799";
}

.fa-firefox:before {
  content: "\f269";
}

.fa-firefox-browser:before {
  content: "\e007";
}

.fa-first-order:before {
  content: "\f2b0";
}

.fa-first-order-alt:before {
  content: "\f50a";
}

.fa-firstdraft:before {
  content: "\f3a1";
}

.fa-flickr:before {
  content: "\f16e";
}

.fa-flipboard:before {
  content: "\f44d";
}

.fa-fly:before {
  content: "\f417";
}

.fa-font-awesome:before {
  content: "\f2b4";
}

.fa-font-awesome-flag:before {
  content: "\f2b4";
}

.fa-font-awesome-logo-full:before {
  content: "\f2b4";
}

.fa-fonticons:before {
  content: "\f280";
}

.fa-fonticons-fi:before {
  content: "\f3a2";
}

.fa-fort-awesome:before {
  content: "\f286";
}

.fa-fort-awesome-alt:before {
  content: "\f3a3";
}

.fa-forumbee:before {
  content: "\f211";
}

.fa-foursquare:before {
  content: "\f180";
}

.fa-free-code-camp:before {
  content: "\f2c5";
}

.fa-freebsd:before {
  content: "\f3a4";
}

.fa-fulcrum:before {
  content: "\f50b";
}

.fa-galactic-republic:before {
  content: "\f50c";
}

.fa-galactic-senate:before {
  content: "\f50d";
}

.fa-get-pocket:before {
  content: "\f265";
}

.fa-gg:before {
  content: "\f260";
}

.fa-gg-circle:before {
  content: "\f261";
}

.fa-git:before {
  content: "\f1d3";
}

.fa-git-alt:before {
  content: "\f841";
}

.fa-git-square:before {
  content: "\f1d2";
}

.fa-github:before {
  content: "\f09b";
}

.fa-github-alt:before {
  content: "\f113";
}

.fa-github-square:before {
  content: "\f092";
}

.fa-gitkraken:before {
  content: "\f3a6";
}

.fa-gitlab:before {
  content: "\f296";
}

.fa-gitter:before {
  content: "\f426";
}

.fa-glide:before {
  content: "\f2a5";
}

.fa-glide-g:before {
  content: "\f2a6";
}

.fa-gofore:before {
  content: "\f3a7";
}

.fa-goodreads:before {
  content: "\f3a8";
}

.fa-goodreads-g:before {
  content: "\f3a9";
}

.fa-google:before {
  content: "\f1a0";
}

.fa-google-drive:before {
  content: "\f3aa";
}

.fa-google-pay:before {
  content: "\e079";
}

.fa-google-play:before {
  content: "\f3ab";
}

.fa-google-plus:before {
  content: "\f2b3";
}

.fa-google-plus-g:before {
  content: "\f0d5";
}

.fa-google-plus-square:before {
  content: "\f0d4";
}

.fa-google-wallet:before {
  content: "\f1ee";
}

.fa-gratipay:before {
  content: "\f184";
}

.fa-grav:before {
  content: "\f2d6";
}

.fa-gripfire:before {
  content: "\f3ac";
}

.fa-grunt:before {
  content: "\f3ad";
}

.fa-guilded:before {
  content: "\e07e";
}

.fa-gulp:before {
  content: "\f3ae";
}

.fa-hacker-news:before {
  content: "\f1d4";
}

.fa-hacker-news-square:before {
  content: "\f3af";
}

.fa-hackerrank:before {
  content: "\f5f7";
}

.fa-hips:before {
  content: "\f452";
}

.fa-hire-a-helper:before {
  content: "\f3b0";
}

.fa-hive:before {
  content: "\e07f";
}

.fa-hooli:before {
  content: "\f427";
}

.fa-hornbill:before {
  content: "\f592";
}

.fa-hotjar:before {
  content: "\f3b1";
}

.fa-houzz:before {
  content: "\f27c";
}

.fa-html5:before {
  content: "\f13b";
}

.fa-hubspot:before {
  content: "\f3b2";
}

.fa-ideal:before {
  content: "\e013";
}

.fa-imdb:before {
  content: "\f2d8";
}

.fa-innosoft:before {
  content: "\e080";
}

.fa-instagram:before {
  content: "\f16d";
}

.fa-instagram-square:before {
  content: "\e055";
}

.fa-instalod:before {
  content: "\e081";
}

.fa-intercom:before {
  content: "\f7af";
}

.fa-internet-explorer:before {
  content: "\f26b";
}

.fa-invision:before {
  content: "\f7b0";
}

.fa-ioxhost:before {
  content: "\f208";
}

.fa-itch-io:before {
  content: "\f83a";
}

.fa-itunes:before {
  content: "\f3b4";
}

.fa-itunes-note:before {
  content: "\f3b5";
}

.fa-java:before {
  content: "\f4e4";
}

.fa-jedi-order:before {
  content: "\f50e";
}

.fa-jenkins:before {
  content: "\f3b6";
}

.fa-jira:before {
  content: "\f7b1";
}

.fa-joget:before {
  content: "\f3b7";
}

.fa-joomla:before {
  content: "\f1aa";
}

.fa-js:before {
  content: "\f3b8";
}

.fa-js-square:before {
  content: "\f3b9";
}

.fa-jsfiddle:before {
  content: "\f1cc";
}

.fa-kaggle:before {
  content: "\f5fa";
}

.fa-keybase:before {
  content: "\f4f5";
}

.fa-keycdn:before {
  content: "\f3ba";
}

.fa-kickstarter:before {
  content: "\f3bb";
}

.fa-kickstarter-k:before {
  content: "\f3bc";
}

.fa-korvue:before {
  content: "\f42f";
}

.fa-laravel:before {
  content: "\f3bd";
}

.fa-lastfm:before {
  content: "\f202";
}

.fa-lastfm-square:before {
  content: "\f203";
}

.fa-leanpub:before {
  content: "\f212";
}

.fa-less:before {
  content: "\f41d";
}

.fa-line:before {
  content: "\f3c0";
}

.fa-linkedin:before {
  content: "\f08c";
}

.fa-linkedin-in:before {
  content: "\f0e1";
}

.fa-linode:before {
  content: "\f2b8";
}

.fa-linux:before {
  content: "\f17c";
}

.fa-lyft:before {
  content: "\f3c3";
}

.fa-magento:before {
  content: "\f3c4";
}

.fa-mailchimp:before {
  content: "\f59e";
}

.fa-mandalorian:before {
  content: "\f50f";
}

.fa-markdown:before {
  content: "\f60f";
}

.fa-mastodon:before {
  content: "\f4f6";
}

.fa-maxcdn:before {
  content: "\f136";
}

.fa-mdb:before {
  content: "\f8ca";
}

.fa-medapps:before {
  content: "\f3c6";
}

.fa-medium:before {
  content: "\f23a";
}

.fa-medium-m:before {
  content: "\f23a";
}

.fa-medrt:before {
  content: "\f3c8";
}

.fa-meetup:before {
  content: "\f2e0";
}

.fa-megaport:before {
  content: "\f5a3";
}

.fa-mendeley:before {
  content: "\f7b3";
}

.fa-microblog:before {
  content: "\e01a";
}

.fa-microsoft:before {
  content: "\f3ca";
}

.fa-mix:before {
  content: "\f3cb";
}

.fa-mixcloud:before {
  content: "\f289";
}

.fa-mixer:before {
  content: "\e056";
}

.fa-mizuni:before {
  content: "\f3cc";
}

.fa-modx:before {
  content: "\f285";
}

.fa-monero:before {
  content: "\f3d0";
}

.fa-napster:before {
  content: "\f3d2";
}

.fa-neos:before {
  content: "\f612";
}

.fa-nimblr:before {
  content: "\f5a8";
}

.fa-node:before {
  content: "\f419";
}

.fa-node-js:before {
  content: "\f3d3";
}

.fa-npm:before {
  content: "\f3d4";
}

.fa-ns8:before {
  content: "\f3d5";
}

.fa-nutritionix:before {
  content: "\f3d6";
}

.fa-octopus-deploy:before {
  content: "\e082";
}

.fa-odnoklassniki:before {
  content: "\f263";
}

.fa-odnoklassniki-square:before {
  content: "\f264";
}

.fa-old-republic:before {
  content: "\f510";
}

.fa-opencart:before {
  content: "\f23d";
}

.fa-openid:before {
  content: "\f19b";
}

.fa-opera:before {
  content: "\f26a";
}

.fa-optin-monster:before {
  content: "\f23c";
}

.fa-orcid:before {
  content: "\f8d2";
}

.fa-osi:before {
  content: "\f41a";
}

.fa-page4:before {
  content: "\f3d7";
}

.fa-pagelines:before {
  content: "\f18c";
}

.fa-palfed:before {
  content: "\f3d8";
}

.fa-patreon:before {
  content: "\f3d9";
}

.fa-paypal:before {
  content: "\f1ed";
}

.fa-penny-arcade:before {
  content: "\f704";
}

.fa-perbyte:before {
  content: "\e083";
}

.fa-periscope:before {
  content: "\f3da";
}

.fa-phabricator:before {
  content: "\f3db";
}

.fa-phoenix-framework:before {
  content: "\f3dc";
}

.fa-phoenix-squadron:before {
  content: "\f511";
}

.fa-php:before {
  content: "\f457";
}

.fa-pied-piper:before {
  content: "\f2ae";
}

.fa-pied-piper-alt:before {
  content: "\f1a8";
}

.fa-pied-piper-hat:before {
  content: "\f4e5";
}

.fa-pied-piper-pp:before {
  content: "\f1a7";
}

.fa-pied-piper-square:before {
  content: "\e01e";
}

.fa-pinterest:before {
  content: "\f0d2";
}

.fa-pinterest-p:before {
  content: "\f231";
}

.fa-pinterest-square:before {
  content: "\f0d3";
}

.fa-playstation:before {
  content: "\f3df";
}

.fa-product-hunt:before {
  content: "\f288";
}

.fa-pushed:before {
  content: "\f3e1";
}

.fa-python:before {
  content: "\f3e2";
}

.fa-qq:before {
  content: "\f1d6";
}

.fa-quinscape:before {
  content: "\f459";
}

.fa-quora:before {
  content: "\f2c4";
}

.fa-r-project:before {
  content: "\f4f7";
}

.fa-raspberry-pi:before {
  content: "\f7bb";
}

.fa-ravelry:before {
  content: "\f2d9";
}

.fa-react:before {
  content: "\f41b";
}

.fa-reacteurope:before {
  content: "\f75d";
}

.fa-readme:before {
  content: "\f4d5";
}

.fa-rebel:before {
  content: "\f1d0";
}

.fa-red-river:before {
  content: "\f3e3";
}

.fa-reddit:before {
  content: "\f1a1";
}

.fa-reddit-alien:before {
  content: "\f281";
}

.fa-reddit-square:before {
  content: "\f1a2";
}

.fa-redhat:before {
  content: "\f7bc";
}

.fa-renren:before {
  content: "\f18b";
}

.fa-replyd:before {
  content: "\f3e6";
}

.fa-researchgate:before {
  content: "\f4f8";
}

.fa-resolving:before {
  content: "\f3e7";
}

.fa-rev:before {
  content: "\f5b2";
}

.fa-rocketchat:before {
  content: "\f3e8";
}

.fa-rockrms:before {
  content: "\f3e9";
}

.fa-rust:before {
  content: "\e07a";
}

.fa-safari:before {
  content: "\f267";
}

.fa-salesforce:before {
  content: "\f83b";
}

.fa-sass:before {
  content: "\f41e";
}

.fa-schlix:before {
  content: "\f3ea";
}

.fa-scribd:before {
  content: "\f28a";
}

.fa-searchengin:before {
  content: "\f3eb";
}

.fa-sellcast:before {
  content: "\f2da";
}

.fa-sellsy:before {
  content: "\f213";
}

.fa-servicestack:before {
  content: "\f3ec";
}

.fa-shirtsinbulk:before {
  content: "\f214";
}

.fa-shopify:before {
  content: "\e057";
}

.fa-shopware:before {
  content: "\f5b5";
}

.fa-simplybuilt:before {
  content: "\f215";
}

.fa-sistrix:before {
  content: "\f3ee";
}

.fa-sith:before {
  content: "\f512";
}

.fa-sketch:before {
  content: "\f7c6";
}

.fa-skyatlas:before {
  content: "\f216";
}

.fa-skype:before {
  content: "\f17e";
}

.fa-slack:before {
  content: "\f198";
}

.fa-slack-hash:before {
  content: "\f198";
}

.fa-slideshare:before {
  content: "\f1e7";
}

.fa-snapchat:before {
  content: "\f2ab";
}

.fa-snapchat-ghost:before {
  content: "\f2ab";
}

.fa-snapchat-square:before {
  content: "\f2ad";
}

.fa-soundcloud:before {
  content: "\f1be";
}

.fa-sourcetree:before {
  content: "\f7d3";
}

.fa-speakap:before {
  content: "\f3f3";
}

.fa-speaker-deck:before {
  content: "\f83c";
}

.fa-spotify:before {
  content: "\f1bc";
}

.fa-square-font-awesome:before {
  content: "\f425";
}

.fa-square-font-awesome-stroke:before {
  content: "\f35c";
}

.fa-font-awesome-alt:before {
  content: "\f35c";
}

.fa-squarespace:before {
  content: "\f5be";
}

.fa-stack-exchange:before {
  content: "\f18d";
}

.fa-stack-overflow:before {
  content: "\f16c";
}

.fa-stackpath:before {
  content: "\f842";
}

.fa-staylinked:before {
  content: "\f3f5";
}

.fa-steam:before {
  content: "\f1b6";
}

.fa-steam-square:before {
  content: "\f1b7";
}

.fa-steam-symbol:before {
  content: "\f3f6";
}

.fa-sticker-mule:before {
  content: "\f3f7";
}

.fa-strava:before {
  content: "\f428";
}

.fa-stripe:before {
  content: "\f429";
}

.fa-stripe-s:before {
  content: "\f42a";
}

.fa-studiovinari:before {
  content: "\f3f8";
}

.fa-stumbleupon:before {
  content: "\f1a4";
}

.fa-stumbleupon-circle:before {
  content: "\f1a3";
}

.fa-superpowers:before {
  content: "\f2dd";
}

.fa-supple:before {
  content: "\f3f9";
}

.fa-suse:before {
  content: "\f7d6";
}

.fa-swift:before {
  content: "\f8e1";
}

.fa-symfony:before {
  content: "\f83d";
}

.fa-teamspeak:before {
  content: "\f4f9";
}

.fa-telegram:before {
  content: "\f2c6";
}

.fa-telegram-plane:before {
  content: "\f2c6";
}

.fa-tencent-weibo:before {
  content: "\f1d5";
}

.fa-the-red-yeti:before {
  content: "\f69d";
}

.fa-themeco:before {
  content: "\f5c6";
}

.fa-themeisle:before {
  content: "\f2b2";
}

.fa-think-peaks:before {
  content: "\f731";
}

.fa-tiktok:before {
  content: "\e07b";
}

.fa-trade-federation:before {
  content: "\f513";
}

.fa-trello:before {
  content: "\f181";
}

.fa-tripadvisor:before {
  content: "\f262";
}

.fa-tumblr:before {
  content: "\f173";
}

.fa-tumblr-square:before {
  content: "\f174";
}

.fa-twitch:before {
  content: "\f1e8";
}

.fa-twitter:before {
  content: "\f099";
}

.fa-twitter-square:before {
  content: "\f081";
}

.fa-typo3:before {
  content: "\f42b";
}

.fa-uber:before {
  content: "\f402";
}

.fa-ubuntu:before {
  content: "\f7df";
}

.fa-uikit:before {
  content: "\f403";
}

.fa-umbraco:before {
  content: "\f8e8";
}

.fa-uncharted:before {
  content: "\e084";
}

.fa-uniregistry:before {
  content: "\f404";
}

.fa-unity:before {
  content: "\e049";
}

.fa-unsplash:before {
  content: "\e07c";
}

.fa-untappd:before {
  content: "\f405";
}

.fa-ups:before {
  content: "\f7e0";
}

.fa-usb:before {
  content: "\f287";
}

.fa-usps:before {
  content: "\f7e1";
}

.fa-ussunnah:before {
  content: "\f407";
}

.fa-vaadin:before {
  content: "\f408";
}

.fa-viacoin:before {
  content: "\f237";
}

.fa-viadeo:before {
  content: "\f2a9";
}

.fa-viadeo-square:before {
  content: "\f2aa";
}

.fa-viber:before {
  content: "\f409";
}

.fa-vimeo:before {
  content: "\f40a";
}

.fa-vimeo-square:before {
  content: "\f194";
}

.fa-vimeo-v:before {
  content: "\f27d";
}

.fa-vine:before {
  content: "\f1ca";
}

.fa-vk:before {
  content: "\f189";
}

.fa-vnv:before {
  content: "\f40b";
}

.fa-vuejs:before {
  content: "\f41f";
}

.fa-watchman-monitoring:before {
  content: "\e087";
}

.fa-waze:before {
  content: "\f83f";
}

.fa-weebly:before {
  content: "\f5cc";
}

.fa-weibo:before {
  content: "\f18a";
}

.fa-weixin:before {
  content: "\f1d7";
}

.fa-whatsapp:before {
  content: "\f232";
}

.fa-whatsapp-square:before {
  content: "\f40c";
}

.fa-whmcs:before {
  content: "\f40d";
}

.fa-wikipedia-w:before {
  content: "\f266";
}

.fa-windows:before {
  content: "\f17a";
}

.fa-wirsindhanderk:before {
  content: "\e2d0";
}

.fa-wsh:before {
  content: "\e2d0";
}

.fa-wix:before {
  content: "\f5cf";
}

.fa-wizards-of-the-coast:before {
  content: "\f730";
}

.fa-wodu:before {
  content: "\e088";
}

.fa-wolf-pack-battalion:before {
  content: "\f514";
}

.fa-wordpress:before {
  content: "\f19a";
}

.fa-wordpress-simple:before {
  content: "\f411";
}

.fa-wpbeginner:before {
  content: "\f297";
}

.fa-wpexplorer:before {
  content: "\f2de";
}

.fa-wpforms:before {
  content: "\f298";
}

.fa-wpressr:before {
  content: "\f3e4";
}

.fa-xbox:before {
  content: "\f412";
}

.fa-xing:before {
  content: "\f168";
}

.fa-xing-square:before {
  content: "\f169";
}

.fa-y-combinator:before {
  content: "\f23b";
}

.fa-yahoo:before {
  content: "\f19e";
}

.fa-yammer:before {
  content: "\f840";
}

.fa-yandex:before {
  content: "\f413";
}

.fa-yandex-international:before {
  content: "\f414";
}

.fa-yarn:before {
  content: "\f7e3";
}

.fa-yelp:before {
  content: "\f1e9";
}

.fa-yoast:before {
  content: "\f2b1";
}

.fa-youtube:before {
  content: "\f167";
}

.fa-youtube-square:before {
  content: "\f431";
}

.fa-zhihu:before {
  content: "\f63f";
}

@font-face {
  font-family: 'Font Awesome 6 Duotone';
  font-style: normal;
  font-weight: 900;
  font-display: block;
  src: url("../fonts/fa-duotone-900.woff2") format("woff2"), url("../fonts/fa-duotone-900.woff") format("woff"), url("../fonts/fa-duotone-900.ttf") format("truetype");
}

.fad,
.fa-duotone {
  position: relative;
  font-family: 'Font Awesome 6 Duotone';
  font-weight: 900;
}

.fad:before,
.fa-duotone:before {
  position: absolute;
  color: inherit;
  color: var(--fa-primary-color, inherit);
  opacity: 1;
  opacity: var(--fa-primary-opacity, 1);
}

.fad:after,
.fa-duotone:after {
  color: inherit;
  color: var(--fa-secondary-color, inherit);
  opacity: 0.4;
  opacity: var(--fa-secondary-opacity, 0.4);
}

.fa-swap-opacity .fad:before,
.fa-swap-opacity .fa-duotone:before,
.fad.fa-swap-opacity:before,
.fa-duotone.fa-swap-opacity:before {
  opacity: 0.4;
  opacity: var(--fa-secondary-opacity, 0.4);
}

.fa-swap-opacity .fad:after,
.fa-swap-opacity .fa-duotone:after,
.fad.fa-swap-opacity:after,
.fa-duotone.fa-swap-opacity:after {
  opacity: 1;
  opacity: var(--fa-primary-opacity, 1);
}

.fad.fa-inverse,
.fa-duotone.fa-inverse {
  color: #fff;
  color: var(--fa-inverse, #fff);
}

.fad.fa-stack-1x,
.fad.fa-stack-2x,
.fa-duotone.fa-stack-1x,
.fa-duotone.fa-stack-2x {
  position: absolute;
}

.fad.fa-stack-1x:before,
.fa-duotone.fa-stack-1x:before,
.fad.fa-stack-2x:before,
.fa-duotone.fa-stack-2x:before,
.fad.fa-fw:before,
.fa-duotone.fa-fw:before {
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}

.fad.fa-0:after,
.fa-duotone.fa-0:after {
  content: "\10e2d2";
}

.fad.fa-1:after,
.fa-duotone.fa-1:after {
  content: "\10e2d3";
}

.fad.fa-2:after,
.fa-duotone.fa-2:after {
  content: "\10e2d4";
}

.fad.fa-3:after,
.fa-duotone.fa-3:after {
  content: "\10e2d5";
}

.fad.fa-4:after,
.fa-duotone.fa-4:after {
  content: "\10e2d6";
}

.fad.fa-5:after,
.fa-duotone.fa-5:after {
  content: "\10e2d7";
}

.fad.fa-6:after,
.fa-duotone.fa-6:after {
  content: "\10e2d8";
}

.fad.fa-7:after,
.fa-duotone.fa-7:after {
  content: "\10e2d9";
}

.fad.fa-8:after,
.fa-duotone.fa-8:after {
  content: "\10e2da";
}

.fad.fa-9:after,
.fa-duotone.fa-9:after {
  content: "\10e2db";
}

.fad.fa-360-degrees:after,
.fa-duotone.fa-360-degrees:after {
  content: "\10e2dc";
}

.fad.fa-a:after,
.fa-duotone.fa-a:after {
  content: "\10e2dd";
}

.fad.fa-abacus:after,
.fa-duotone.fa-abacus:after {
  content: "\10f640";
}

.fad.fa-accent-grave:after,
.fa-duotone.fa-accent-grave:after {
  content: "\10e2de";
}

.fad.fa-acorn:after,
.fa-duotone.fa-acorn:after {
  content: "\10f6ae";
}

.fad.fa-address-book:after,
.fa-duotone.fa-address-book:after {
  content: "\10f2b9";
}

.fad.fa-contact-book:after,
.fa-duotone.fa-contact-book:after {
  content: "\10f2b9";
}

.fad.fa-address-card:after,
.fa-duotone.fa-address-card:after {
  content: "\10f2bb";
}

.fad.fa-contact-card:after,
.fa-duotone.fa-contact-card:after {
  content: "\10f2bb";
}

.fad.fa-vcard:after,
.fa-duotone.fa-vcard:after {
  content: "\10f2bb";
}

.fad.fa-air-conditioner:after,
.fa-duotone.fa-air-conditioner:after {
  content: "\10f8f4";
}

.fad.fa-airplay:after,
.fa-duotone.fa-airplay:after {
  content: "\10e089";
}

.fad.fa-alarm-clock:after,
.fa-duotone.fa-alarm-clock:after {
  content: "\10f34e";
}

.fad.fa-alarm-exclamation:after,
.fa-duotone.fa-alarm-exclamation:after {
  content: "\10f843";
}

.fad.fa-alarm-plus:after,
.fa-duotone.fa-alarm-plus:after {
  content: "\10f844";
}

.fad.fa-alarm-snooze:after,
.fa-duotone.fa-alarm-snooze:after {
  content: "\10f845";
}

.fad.fa-album:after,
.fa-duotone.fa-album:after {
  content: "\10f89f";
}

.fad.fa-album-collection:after,
.fa-duotone.fa-album-collection:after {
  content: "\10f8a0";
}

.fad.fa-alicorn:after,
.fa-duotone.fa-alicorn:after {
  content: "\10f6b0";
}

.fad.fa-alien:after,
.fa-duotone.fa-alien:after {
  content: "\10f8f5";
}

.fad.fa-alien-8bit:after,
.fa-duotone.fa-alien-8bit:after {
  content: "\10f8f6";
}

.fad.fa-alien-monster:after,
.fa-duotone.fa-alien-monster:after {
  content: "\10f8f6";
}

.fad.fa-align-center:after,
.fa-duotone.fa-align-center:after {
  content: "\10f037";
}

.fad.fa-align-justify:after,
.fa-duotone.fa-align-justify:after {
  content: "\10f039";
}

.fad.fa-align-left:after,
.fa-duotone.fa-align-left:after {
  content: "\10f036";
}

.fad.fa-align-right:after,
.fa-duotone.fa-align-right:after {
  content: "\10f038";
}

.fad.fa-align-slash:after,
.fa-duotone.fa-align-slash:after {
  content: "\10f846";
}

.fad.fa-alt:after,
.fa-duotone.fa-alt:after {
  content: "\10e08a";
}

.fad.fa-amp-guitar:after,
.fa-duotone.fa-amp-guitar:after {
  content: "\10f8a1";
}

.fad.fa-ampersand:after,
.fa-duotone.fa-ampersand:after {
  content: "\10e08b";
}

.fad.fa-anchor:after,
.fa-duotone.fa-anchor:after {
  content: "\10f13d";
}

.fad.fa-angel:after,
.fa-duotone.fa-angel:after {
  content: "\10f779";
}

.fad.fa-angle:after,
.fa-duotone.fa-angle:after {
  content: "\10e08c";
}

.fad.fa-angle-90:after,
.fa-duotone.fa-angle-90:after {
  content: "\10e08d";
}

.fad.fa-angle-down:after,
.fa-duotone.fa-angle-down:after {
  content: "\10f107";
}

.fad.fa-angle-left:after,
.fa-duotone.fa-angle-left:after {
  content: "\10f104";
}

.fad.fa-angle-right:after,
.fa-duotone.fa-angle-right:after {
  content: "\10f105";
}

.fad.fa-angle-up:after,
.fa-duotone.fa-angle-up:after {
  content: "\10f106";
}

.fad.fa-angles-down:after,
.fa-duotone.fa-angles-down:after {
  content: "\10f103";
}

.fad.fa-angle-double-down:after,
.fa-duotone.fa-angle-double-down:after {
  content: "\10f103";
}

.fad.fa-angles-left:after,
.fa-duotone.fa-angles-left:after {
  content: "\10f100";
}

.fad.fa-angle-double-left:after,
.fa-duotone.fa-angle-double-left:after {
  content: "\10f100";
}

.fad.fa-angles-right:after,
.fa-duotone.fa-angles-right:after {
  content: "\10f101";
}

.fad.fa-angle-double-right:after,
.fa-duotone.fa-angle-double-right:after {
  content: "\10f101";
}

.fad.fa-angles-up:after,
.fa-duotone.fa-angles-up:after {
  content: "\10f102";
}

.fad.fa-angle-double-up:after,
.fa-duotone.fa-angle-double-up:after {
  content: "\10f102";
}

.fad.fa-ankh:after,
.fa-duotone.fa-ankh:after {
  content: "\10f644";
}

.fad.fa-aperture:after,
.fa-duotone.fa-aperture:after {
  content: "\10e2df";
}

.fad.fa-apostrophe:after,
.fa-duotone.fa-apostrophe:after {
  content: "\10e2e0";
}

.fad.fa-apple-core:after,
.fa-duotone.fa-apple-core:after {
  content: "\10e08f";
}

.fad.fa-apple-whole:after,
.fa-duotone.fa-apple-whole:after {
  content: "\10f5d1";
}

.fad.fa-apple-alt:after,
.fa-duotone.fa-apple-alt:after {
  content: "\10f5d1";
}

.fad.fa-archway:after,
.fa-duotone.fa-archway:after {
  content: "\10f557";
}

.fad.fa-arrow-down:after,
.fa-duotone.fa-arrow-down:after {
  content: "\10f063";
}

.fad.fa-arrow-down-1-9:after,
.fa-duotone.fa-arrow-down-1-9:after {
  content: "\10f162";
}

.fad.fa-sort-numeric-asc:after,
.fa-duotone.fa-sort-numeric-asc:after {
  content: "\10f162";
}

.fad.fa-sort-numeric-down:after,
.fa-duotone.fa-sort-numeric-down:after {
  content: "\10f162";
}

.fad.fa-arrow-down-9-1:after,
.fa-duotone.fa-arrow-down-9-1:after {
  content: "\10f886";
}

.fad.fa-sort-numeric-desc:after,
.fa-duotone.fa-sort-numeric-desc:after {
  content: "\10f886";
}

.fad.fa-sort-numeric-down-alt:after,
.fa-duotone.fa-sort-numeric-down-alt:after {
  content: "\10f886";
}

.fad.fa-arrow-down-a-z:after,
.fa-duotone.fa-arrow-down-a-z:after {
  content: "\10f15d";
}

.fad.fa-sort-alpha-asc:after,
.fa-duotone.fa-sort-alpha-asc:after {
  content: "\10f15d";
}

.fad.fa-sort-alpha-down:after,
.fa-duotone.fa-sort-alpha-down:after {
  content: "\10f15d";
}

.fad.fa-arrow-down-arrow-up:after,
.fa-duotone.fa-arrow-down-arrow-up:after {
  content: "\10f883";
}

.fad.fa-sort-alt:after,
.fa-duotone.fa-sort-alt:after {
  content: "\10f883";
}

.fad.fa-arrow-down-big-small:after,
.fa-duotone.fa-arrow-down-big-small:after {
  content: "\10f88c";
}

.fad.fa-sort-size-down:after,
.fa-duotone.fa-sort-size-down:after {
  content: "\10f88c";
}

.fad.fa-arrow-down-from-dotted-line:after,
.fa-duotone.fa-arrow-down-from-dotted-line:after {
  content: "\10e090";
}

.fad.fa-arrow-down-from-line:after,
.fa-duotone.fa-arrow-down-from-line:after {
  content: "\10f345";
}

.fad.fa-arrow-from-top:after,
.fa-duotone.fa-arrow-from-top:after {
  content: "\10f345";
}

.fad.fa-arrow-down-left:after,
.fa-duotone.fa-arrow-down-left:after {
  content: "\10e091";
}

.fad.fa-arrow-down-left-and-arrow-up-right-to-center:after,
.fa-duotone.fa-arrow-down-left-and-arrow-up-right-to-center:after {
  content: "\10e092";
}

.fad.fa-arrow-down-long:after,
.fa-duotone.fa-arrow-down-long:after {
  content: "\10f175";
}

.fad.fa-long-arrow-down:after,
.fa-duotone.fa-long-arrow-down:after {
  content: "\10f175";
}

.fad.fa-arrow-down-right:after,
.fa-duotone.fa-arrow-down-right:after {
  content: "\10e093";
}

.fad.fa-arrow-down-short-wide:after,
.fa-duotone.fa-arrow-down-short-wide:after {
  content: "\10f884";
}

.fad.fa-sort-amount-desc:after,
.fa-duotone.fa-sort-amount-desc:after {
  content: "\10f884";
}

.fad.fa-sort-amount-down-alt:after,
.fa-duotone.fa-sort-amount-down-alt:after {
  content: "\10f884";
}

.fad.fa-arrow-down-small-big:after,
.fa-duotone.fa-arrow-down-small-big:after {
  content: "\10f88d";
}

.fad.fa-sort-size-down-alt:after,
.fa-duotone.fa-sort-size-down-alt:after {
  content: "\10f88d";
}

.fad.fa-arrow-down-square-triangle:after,
.fa-duotone.fa-arrow-down-square-triangle:after {
  content: "\10f889";
}

.fad.fa-sort-shapes-down-alt:after,
.fa-duotone.fa-sort-shapes-down-alt:after {
  content: "\10f889";
}

.fad.fa-arrow-down-to-bracket:after,
.fa-duotone.fa-arrow-down-to-bracket:after {
  content: "\10e094";
}

.fad.fa-arrow-down-to-dotted-line:after,
.fa-duotone.fa-arrow-down-to-dotted-line:after {
  content: "\10e095";
}

.fad.fa-arrow-down-to-line:after,
.fa-duotone.fa-arrow-down-to-line:after {
  content: "\10f33d";
}

.fad.fa-arrow-to-bottom:after,
.fa-duotone.fa-arrow-to-bottom:after {
  content: "\10f33d";
}

.fad.fa-arrow-down-to-square:after,
.fa-duotone.fa-arrow-down-to-square:after {
  content: "\10e096";
}

.fad.fa-arrow-down-triangle-square:after,
.fa-duotone.fa-arrow-down-triangle-square:after {
  content: "\10f888";
}

.fad.fa-sort-shapes-down:after,
.fa-duotone.fa-sort-shapes-down:after {
  content: "\10f888";
}

.fad.fa-arrow-down-wide-short:after,
.fa-duotone.fa-arrow-down-wide-short:after {
  content: "\10f160";
}

.fad.fa-sort-amount-asc:after,
.fa-duotone.fa-sort-amount-asc:after {
  content: "\10f160";
}

.fad.fa-sort-amount-down:after,
.fa-duotone.fa-sort-amount-down:after {
  content: "\10f160";
}

.fad.fa-arrow-down-z-a:after,
.fa-duotone.fa-arrow-down-z-a:after {
  content: "\10f881";
}

.fad.fa-sort-alpha-desc:after,
.fa-duotone.fa-sort-alpha-desc:after {
  content: "\10f881";
}

.fad.fa-sort-alpha-down-alt:after,
.fa-duotone.fa-sort-alpha-down-alt:after {
  content: "\10f881";
}

.fad.fa-arrow-left:after,
.fa-duotone.fa-arrow-left:after {
  content: "\10f060";
}

.fad.fa-arrow-left-from-line:after,
.fa-duotone.fa-arrow-left-from-line:after {
  content: "\10f344";
}

.fad.fa-arrow-from-right:after,
.fa-duotone.fa-arrow-from-right:after {
  content: "\10f344";
}

.fad.fa-arrow-left-long:after,
.fa-duotone.fa-arrow-left-long:after {
  content: "\10f177";
}

.fad.fa-long-arrow-left:after,
.fa-duotone.fa-long-arrow-left:after {
  content: "\10f177";
}

.fad.fa-arrow-left-to-line:after,
.fa-duotone.fa-arrow-left-to-line:after {
  content: "\10f33e";
}

.fad.fa-arrow-to-left:after,
.fa-duotone.fa-arrow-to-left:after {
  content: "\10f33e";
}

.fad.fa-arrow-pointer:after,
.fa-duotone.fa-arrow-pointer:after {
  content: "\10f245";
}

.fad.fa-mouse-pointer:after,
.fa-duotone.fa-mouse-pointer:after {
  content: "\10f245";
}

.fad.fa-arrow-right:after,
.fa-duotone.fa-arrow-right:after {
  content: "\10f061";
}

.fad.fa-arrow-right-arrow-left:after,
.fa-duotone.fa-arrow-right-arrow-left:after {
  content: "\10f0ec";
}

.fad.fa-exchange:after,
.fa-duotone.fa-exchange:after {
  content: "\10f0ec";
}

.fad.fa-arrow-right-from-bracket:after,
.fa-duotone.fa-arrow-right-from-bracket:after {
  content: "\10f08b";
}

.fad.fa-sign-out:after,
.fa-duotone.fa-sign-out:after {
  content: "\10f08b";
}

.fad.fa-arrow-right-from-line:after,
.fa-duotone.fa-arrow-right-from-line:after {
  content: "\10f343";
}

.fad.fa-arrow-from-left:after,
.fa-duotone.fa-arrow-from-left:after {
  content: "\10f343";
}

.fad.fa-arrow-right-long:after,
.fa-duotone.fa-arrow-right-long:after {
  content: "\10f178";
}

.fad.fa-long-arrow-right:after,
.fa-duotone.fa-long-arrow-right:after {
  content: "\10f178";
}

.fad.fa-arrow-right-to-bracket:after,
.fa-duotone.fa-arrow-right-to-bracket:after {
  content: "\10f090";
}

.fad.fa-sign-in:after,
.fa-duotone.fa-sign-in:after {
  content: "\10f090";
}

.fad.fa-arrow-right-to-line:after,
.fa-duotone.fa-arrow-right-to-line:after {
  content: "\10f340";
}

.fad.fa-arrow-to-right:after,
.fa-duotone.fa-arrow-to-right:after {
  content: "\10f340";
}

.fad.fa-arrow-rotate-left:after,
.fa-duotone.fa-arrow-rotate-left:after {
  content: "\10f0e2";
}

.fad.fa-arrow-left-rotate:after,
.fa-duotone.fa-arrow-left-rotate:after {
  content: "\10f0e2";
}

.fad.fa-arrow-rotate-back:after,
.fa-duotone.fa-arrow-rotate-back:after {
  content: "\10f0e2";
}

.fad.fa-arrow-rotate-backward:after,
.fa-duotone.fa-arrow-rotate-backward:after {
  content: "\10f0e2";
}

.fad.fa-undo:after,
.fa-duotone.fa-undo:after {
  content: "\10f0e2";
}

.fad.fa-arrow-rotate-right:after,
.fa-duotone.fa-arrow-rotate-right:after {
  content: "\10f01e";
}

.fad.fa-arrow-right-rotate:after,
.fa-duotone.fa-arrow-right-rotate:after {
  content: "\10f01e";
}

.fad.fa-arrow-rotate-forward:after,
.fa-duotone.fa-arrow-rotate-forward:after {
  content: "\10f01e";
}

.fad.fa-redo:after,
.fa-duotone.fa-redo:after {
  content: "\10f01e";
}

.fad.fa-arrow-trend-down:after,
.fa-duotone.fa-arrow-trend-down:after {
  content: "\10e097";
}

.fad.fa-arrow-trend-up:after,
.fa-duotone.fa-arrow-trend-up:after {
  content: "\10e098";
}

.fad.fa-arrow-turn-down:after,
.fa-duotone.fa-arrow-turn-down:after {
  content: "\10f149";
}

.fad.fa-level-down:after,
.fa-duotone.fa-level-down:after {
  content: "\10f149";
}

.fad.fa-arrow-turn-down-left:after,
.fa-duotone.fa-arrow-turn-down-left:after {
  content: "\10e2e1";
}

.fad.fa-arrow-turn-up:after,
.fa-duotone.fa-arrow-turn-up:after {
  content: "\10f148";
}

.fad.fa-level-up:after,
.fa-duotone.fa-level-up:after {
  content: "\10f148";
}

.fad.fa-arrow-up:after,
.fa-duotone.fa-arrow-up:after {
  content: "\10f062";
}

.fad.fa-arrow-up-1-9:after,
.fa-duotone.fa-arrow-up-1-9:after {
  content: "\10f163";
}

.fad.fa-sort-numeric-up:after,
.fa-duotone.fa-sort-numeric-up:after {
  content: "\10f163";
}

.fad.fa-arrow-up-9-1:after,
.fa-duotone.fa-arrow-up-9-1:after {
  content: "\10f887";
}

.fad.fa-sort-numeric-up-alt:after,
.fa-duotone.fa-sort-numeric-up-alt:after {
  content: "\10f887";
}

.fad.fa-arrow-up-a-z:after,
.fa-duotone.fa-arrow-up-a-z:after {
  content: "\10f15e";
}

.fad.fa-sort-alpha-up:after,
.fa-duotone.fa-sort-alpha-up:after {
  content: "\10f15e";
}

.fad.fa-arrow-up-arrow-down:after,
.fa-duotone.fa-arrow-up-arrow-down:after {
  content: "\10e099";
}

.fad.fa-sort-up-down:after,
.fa-duotone.fa-sort-up-down:after {
  content: "\10e099";
}

.fad.fa-arrow-up-big-small:after,
.fa-duotone.fa-arrow-up-big-small:after {
  content: "\10f88e";
}

.fad.fa-sort-size-up:after,
.fa-duotone.fa-sort-size-up:after {
  content: "\10f88e";
}

.fad.fa-arrow-up-from-bracket:after,
.fa-duotone.fa-arrow-up-from-bracket:after {
  content: "\10e09a";
}

.fad.fa-arrow-up-from-dotted-line:after,
.fa-duotone.fa-arrow-up-from-dotted-line:after {
  content: "\10e09b";
}

.fad.fa-arrow-up-from-line:after,
.fa-duotone.fa-arrow-up-from-line:after {
  content: "\10f342";
}

.fad.fa-arrow-from-bottom:after,
.fa-duotone.fa-arrow-from-bottom:after {
  content: "\10f342";
}

.fad.fa-arrow-up-from-square:after,
.fa-duotone.fa-arrow-up-from-square:after {
  content: "\10e09c";
}

.fad.fa-arrow-up-left:after,
.fa-duotone.fa-arrow-up-left:after {
  content: "\10e09d";
}

.fad.fa-arrow-up-left-from-circle:after,
.fa-duotone.fa-arrow-up-left-from-circle:after {
  content: "\10e09e";
}

.fad.fa-arrow-up-long:after,
.fa-duotone.fa-arrow-up-long:after {
  content: "\10f176";
}

.fad.fa-long-arrow-up:after,
.fa-duotone.fa-long-arrow-up:after {
  content: "\10f176";
}

.fad.fa-arrow-up-right:after,
.fa-duotone.fa-arrow-up-right:after {
  content: "\10e09f";
}

.fad.fa-arrow-up-right-and-arrow-down-left-from-center:after,
.fa-duotone.fa-arrow-up-right-and-arrow-down-left-from-center:after {
  content: "\10e0a0";
}

.fad.fa-arrow-up-right-from-square:after,
.fa-duotone.fa-arrow-up-right-from-square:after {
  content: "\10f08e";
}

.fad.fa-external-link:after,
.fa-duotone.fa-external-link:after {
  content: "\10f08e";
}

.fad.fa-arrow-up-short-wide:after,
.fa-duotone.fa-arrow-up-short-wide:after {
  content: "\10f885";
}

.fad.fa-sort-amount-up-alt:after,
.fa-duotone.fa-sort-amount-up-alt:after {
  content: "\10f885";
}

.fad.fa-arrow-up-small-big:after,
.fa-duotone.fa-arrow-up-small-big:after {
  content: "\10f88f";
}

.fad.fa-sort-size-up-alt:after,
.fa-duotone.fa-sort-size-up-alt:after {
  content: "\10f88f";
}

.fad.fa-arrow-up-square-triangle:after,
.fa-duotone.fa-arrow-up-square-triangle:after {
  content: "\10f88b";
}

.fad.fa-sort-shapes-up-alt:after,
.fa-duotone.fa-sort-shapes-up-alt:after {
  content: "\10f88b";
}

.fad.fa-arrow-up-to-dotted-line:after,
.fa-duotone.fa-arrow-up-to-dotted-line:after {
  content: "\10e0a1";
}

.fad.fa-arrow-up-to-line:after,
.fa-duotone.fa-arrow-up-to-line:after {
  content: "\10f341";
}

.fad.fa-arrow-to-top:after,
.fa-duotone.fa-arrow-to-top:after {
  content: "\10f341";
}

.fad.fa-arrow-up-triangle-square:after,
.fa-duotone.fa-arrow-up-triangle-square:after {
  content: "\10f88a";
}

.fad.fa-sort-shapes-up:after,
.fa-duotone.fa-sort-shapes-up:after {
  content: "\10f88a";
}

.fad.fa-arrow-up-wide-short:after,
.fa-duotone.fa-arrow-up-wide-short:after {
  content: "\10f161";
}

.fad.fa-sort-amount-up:after,
.fa-duotone.fa-sort-amount-up:after {
  content: "\10f161";
}

.fad.fa-arrow-up-z-a:after,
.fa-duotone.fa-arrow-up-z-a:after {
  content: "\10f882";
}

.fad.fa-sort-alpha-up-alt:after,
.fa-duotone.fa-sort-alpha-up-alt:after {
  content: "\10f882";
}

.fad.fa-arrows-cross:after,
.fa-duotone.fa-arrows-cross:after {
  content: "\10e0a2";
}

.fad.fa-arrows-from-dotted-line:after,
.fa-duotone.fa-arrows-from-dotted-line:after {
  content: "\10e0a3";
}

.fad.fa-arrows-from-line:after,
.fa-duotone.fa-arrows-from-line:after {
  content: "\10e0a4";
}

.fad.fa-arrows-left-right:after,
.fa-duotone.fa-arrows-left-right:after {
  content: "\10f07e";
}

.fad.fa-arrows-h:after,
.fa-duotone.fa-arrows-h:after {
  content: "\10f07e";
}

.fad.fa-arrows-maximize:after,
.fa-duotone.fa-arrows-maximize:after {
  content: "\10f31d";
}

.fad.fa-expand-arrows:after,
.fa-duotone.fa-expand-arrows:after {
  content: "\10f31d";
}

.fad.fa-arrows-minimize:after,
.fa-duotone.fa-arrows-minimize:after {
  content: "\10e0a5";
}

.fad.fa-compress-arrows:after,
.fa-duotone.fa-compress-arrows:after {
  content: "\10e0a5";
}

.fad.fa-arrows-repeat:after,
.fa-duotone.fa-arrows-repeat:after {
  content: "\10f364";
}

.fad.fa-repeat-alt:after,
.fa-duotone.fa-repeat-alt:after {
  content: "\10f364";
}

.fad.fa-arrows-repeat-1:after,
.fa-duotone.fa-arrows-repeat-1:after {
  content: "\10f366";
}

.fad.fa-repeat-1-alt:after,
.fa-duotone.fa-repeat-1-alt:after {
  content: "\10f366";
}

.fad.fa-arrows-retweet:after,
.fa-duotone.fa-arrows-retweet:after {
  content: "\10f361";
}

.fad.fa-retweet-alt:after,
.fa-duotone.fa-retweet-alt:after {
  content: "\10f361";
}

.fad.fa-arrows-rotate:after,
.fa-duotone.fa-arrows-rotate:after {
  content: "\10f021";
}

.fad.fa-refresh:after,
.fa-duotone.fa-refresh:after {
  content: "\10f021";
}

.fad.fa-sync:after,
.fa-duotone.fa-sync:after {
  content: "\10f021";
}

.fad.fa-arrows-to-dotted-line:after,
.fa-duotone.fa-arrows-to-dotted-line:after {
  content: "\10e0a6";
}

.fad.fa-arrows-to-line:after,
.fa-duotone.fa-arrows-to-line:after {
  content: "\10e0a7";
}

.fad.fa-arrows-up-down:after,
.fa-duotone.fa-arrows-up-down:after {
  content: "\10f07d";
}

.fad.fa-arrows-v:after,
.fa-duotone.fa-arrows-v:after {
  content: "\10f07d";
}

.fad.fa-arrows-up-down-left-right:after,
.fa-duotone.fa-arrows-up-down-left-right:after {
  content: "\10f047";
}

.fad.fa-arrows:after,
.fa-duotone.fa-arrows:after {
  content: "\10f047";
}

.fad.fa-asterisk:after,
.fa-duotone.fa-asterisk:after {
  content: "\10f069";
}

.fad.fa-at:after,
.fa-duotone.fa-at:after {
  content: "\10f1fa";
}

.fad.fa-atom:after,
.fa-duotone.fa-atom:after {
  content: "\10f5d2";
}

.fad.fa-atom-simple:after,
.fa-duotone.fa-atom-simple:after {
  content: "\10f5d3";
}

.fad.fa-atom-alt:after,
.fa-duotone.fa-atom-alt:after {
  content: "\10f5d3";
}

.fad.fa-audio-description:after,
.fa-duotone.fa-audio-description:after {
  content: "\10f29e";
}

.fad.fa-audio-description-slash:after,
.fa-duotone.fa-audio-description-slash:after {
  content: "\10e0a8";
}

.fad.fa-austral-sign:after,
.fa-duotone.fa-austral-sign:after {
  content: "\10e0a9";
}

.fad.fa-avocado:after,
.fa-duotone.fa-avocado:after {
  content: "\10e0aa";
}

.fad.fa-award:after,
.fa-duotone.fa-award:after {
  content: "\10f559";
}

.fad.fa-award-simple:after,
.fa-duotone.fa-award-simple:after {
  content: "\10e0ab";
}

.fad.fa-axe:after,
.fa-duotone.fa-axe:after {
  content: "\10f6b2";
}

.fad.fa-axe-battle:after,
.fa-duotone.fa-axe-battle:after {
  content: "\10f6b3";
}

.fad.fa-b:after,
.fa-duotone.fa-b:after {
  content: "\10e2e2";
}

.fad.fa-baby:after,
.fa-duotone.fa-baby:after {
  content: "\10f77c";
}

.fad.fa-baby-carriage:after,
.fa-duotone.fa-baby-carriage:after {
  content: "\10f77d";
}

.fad.fa-carriage-baby:after,
.fa-duotone.fa-carriage-baby:after {
  content: "\10f77d";
}

.fad.fa-backpack:after,
.fa-duotone.fa-backpack:after {
  content: "\10f5d4";
}

.fad.fa-backward:after,
.fa-duotone.fa-backward:after {
  content: "\10f04a";
}

.fad.fa-backward-fast:after,
.fa-duotone.fa-backward-fast:after {
  content: "\10f049";
}

.fad.fa-fast-backward:after,
.fa-duotone.fa-fast-backward:after {
  content: "\10f049";
}

.fad.fa-backward-step:after,
.fa-duotone.fa-backward-step:after {
  content: "\10f048";
}

.fad.fa-step-backward:after,
.fa-duotone.fa-step-backward:after {
  content: "\10f048";
}

.fad.fa-bacon:after,
.fa-duotone.fa-bacon:after {
  content: "\10f7e5";
}

.fad.fa-bacteria:after,
.fa-duotone.fa-bacteria:after {
  content: "\10e059";
}

.fad.fa-bacterium:after,
.fa-duotone.fa-bacterium:after {
  content: "\10e05a";
}

.fad.fa-badge:after,
.fa-duotone.fa-badge:after {
  content: "\10f335";
}

.fad.fa-badge-check:after,
.fa-duotone.fa-badge-check:after {
  content: "\10f336";
}

.fad.fa-badge-dollar:after,
.fa-duotone.fa-badge-dollar:after {
  content: "\10f645";
}

.fad.fa-badge-percent:after,
.fa-duotone.fa-badge-percent:after {
  content: "\10f646";
}

.fad.fa-badge-sheriff:after,
.fa-duotone.fa-badge-sheriff:after {
  content: "\10f8a2";
}

.fad.fa-badger-honey:after,
.fa-duotone.fa-badger-honey:after {
  content: "\10f6b4";
}

.fad.fa-bag-shopping:after,
.fa-duotone.fa-bag-shopping:after {
  content: "\10f290";
}

.fad.fa-shopping-bag:after,
.fa-duotone.fa-shopping-bag:after {
  content: "\10f290";
}

.fad.fa-bags-shopping:after,
.fa-duotone.fa-bags-shopping:after {
  content: "\10f847";
}

.fad.fa-bahai:after,
.fa-duotone.fa-bahai:after {
  content: "\10f666";
}

.fad.fa-baht-sign:after,
.fa-duotone.fa-baht-sign:after {
  content: "\10e0ac";
}

.fad.fa-ball-pile:after,
.fa-duotone.fa-ball-pile:after {
  content: "\10f77e";
}

.fad.fa-balloon:after,
.fa-duotone.fa-balloon:after {
  content: "\10e2e3";
}

.fad.fa-balloons:after,
.fa-duotone.fa-balloons:after {
  content: "\10e2e4";
}

.fad.fa-ballot:after,
.fa-duotone.fa-ballot:after {
  content: "\10f732";
}

.fad.fa-ballot-check:after,
.fa-duotone.fa-ballot-check:after {
  content: "\10f733";
}

.fad.fa-ban:after,
.fa-duotone.fa-ban:after {
  content: "\10f05e";
}

.fad.fa-cancel:after,
.fa-duotone.fa-cancel:after {
  content: "\10f05e";
}

.fad.fa-ban-bug:after,
.fa-duotone.fa-ban-bug:after {
  content: "\10f7f9";
}

.fad.fa-debug:after,
.fa-duotone.fa-debug:after {
  content: "\10f7f9";
}

.fad.fa-ban-parking:after,
.fa-duotone.fa-ban-parking:after {
  content: "\10f616";
}

.fad.fa-parking-circle-slash:after,
.fa-duotone.fa-parking-circle-slash:after {
  content: "\10f616";
}

.fad.fa-ban-smoking:after,
.fa-duotone.fa-ban-smoking:after {
  content: "\10f54d";
}

.fad.fa-smoking-ban:after,
.fa-duotone.fa-smoking-ban:after {
  content: "\10f54d";
}

.fad.fa-banana:after,
.fa-duotone.fa-banana:after {
  content: "\10e2e5";
}

.fad.fa-bandage:after,
.fa-duotone.fa-bandage:after {
  content: "\10f462";
}

.fad.fa-band-aid:after,
.fa-duotone.fa-band-aid:after {
  content: "\10f462";
}

.fad.fa-bangladeshi-taka-sign:after,
.fa-duotone.fa-bangladeshi-taka-sign:after {
  content: "\10e2e6";
}

.fad.fa-banjo:after,
.fa-duotone.fa-banjo:after {
  content: "\10f8a3";
}

.fad.fa-bank:after,
.fa-duotone.fa-bank:after {
  content: "\10f19c";
}

.fad.fa-institution:after,
.fa-duotone.fa-institution:after {
  content: "\10f19c";
}

.fad.fa-university:after,
.fa-duotone.fa-university:after {
  content: "\10f19c";
}

.fad.fa-barcode:after,
.fa-duotone.fa-barcode:after {
  content: "\10f02a";
}

.fad.fa-barcode-read:after,
.fa-duotone.fa-barcode-read:after {
  content: "\10f464";
}

.fad.fa-barcode-scan:after,
.fa-duotone.fa-barcode-scan:after {
  content: "\10f465";
}

.fad.fa-bars:after,
.fa-duotone.fa-bars:after {
  content: "\10f0c9";
}

.fad.fa-navicon:after,
.fa-duotone.fa-navicon:after {
  content: "\10f0c9";
}

.fad.fa-bars-filter:after,
.fa-duotone.fa-bars-filter:after {
  content: "\10e0ad";
}

.fad.fa-bars-progress:after,
.fa-duotone.fa-bars-progress:after {
  content: "\10f828";
}

.fad.fa-tasks-alt:after,
.fa-duotone.fa-tasks-alt:after {
  content: "\10f828";
}

.fad.fa-bars-sort:after,
.fa-duotone.fa-bars-sort:after {
  content: "\10e0ae";
}

.fad.fa-bars-staggered:after,
.fa-duotone.fa-bars-staggered:after {
  content: "\10f550";
}

.fad.fa-reorder:after,
.fa-duotone.fa-reorder:after {
  content: "\10f550";
}

.fad.fa-stream:after,
.fa-duotone.fa-stream:after {
  content: "\10f550";
}

.fad.fa-baseball-ball:after,
.fa-duotone.fa-baseball-ball:after {
  content: "\10f433";
}

.fad.fa-baseball-bat-ball:after,
.fa-duotone.fa-baseball-bat-ball:after {
  content: "\10f432";
}

.fad.fa-baseball:after,
.fa-duotone.fa-baseball:after {
  content: "\10f432";
}

.fad.fa-basket-shopping:after,
.fa-duotone.fa-basket-shopping:after {
  content: "\10f291";
}

.fad.fa-shopping-basket:after,
.fa-duotone.fa-shopping-basket:after {
  content: "\10f291";
}

.fad.fa-basket-shopping-simple:after,
.fa-duotone.fa-basket-shopping-simple:after {
  content: "\10e0af";
}

.fad.fa-shopping-basket-alt:after,
.fa-duotone.fa-shopping-basket-alt:after {
  content: "\10e0af";
}

.fad.fa-basketball-ball:after,
.fa-duotone.fa-basketball-ball:after {
  content: "\10f434";
}

.fad.fa-basketball-hoop:after,
.fa-duotone.fa-basketball-hoop:after {
  content: "\10f435";
}

.fad.fa-bat:after,
.fa-duotone.fa-bat:after {
  content: "\10f6b5";
}

.fad.fa-bath:after,
.fa-duotone.fa-bath:after {
  content: "\10f2cd";
}

.fad.fa-bathtub:after,
.fa-duotone.fa-bathtub:after {
  content: "\10f2cd";
}

.fad.fa-battery-bolt:after,
.fa-duotone.fa-battery-bolt:after {
  content: "\10f376";
}

.fad.fa-battery-empty:after,
.fa-duotone.fa-battery-empty:after {
  content: "\10f244";
}

.fad.fa-battery-0:after,
.fa-duotone.fa-battery-0:after {
  content: "\10f244";
}

.fad.fa-battery-exclamation:after,
.fa-duotone.fa-battery-exclamation:after {
  content: "\10e0b0";
}

.fad.fa-battery-full:after,
.fa-duotone.fa-battery-full:after {
  content: "\10f240";
}

.fad.fa-battery:after,
.fa-duotone.fa-battery:after {
  content: "\10f240";
}

.fad.fa-battery-5:after,
.fa-duotone.fa-battery-5:after {
  content: "\10f240";
}

.fad.fa-battery-half:after,
.fa-duotone.fa-battery-half:after {
  content: "\10f242";
}

.fad.fa-battery-3:after,
.fa-duotone.fa-battery-3:after {
  content: "\10f242";
}

.fad.fa-battery-low:after,
.fa-duotone.fa-battery-low:after {
  content: "\10e0b1";
}

.fad.fa-battery-1:after,
.fa-duotone.fa-battery-1:after {
  content: "\10e0b1";
}

.fad.fa-battery-quarter:after,
.fa-duotone.fa-battery-quarter:after {
  content: "\10f243";
}

.fad.fa-battery-2:after,
.fa-duotone.fa-battery-2:after {
  content: "\10f243";
}

.fad.fa-battery-slash:after,
.fa-duotone.fa-battery-slash:after {
  content: "\10f377";
}

.fad.fa-battery-three-quarters:after,
.fa-duotone.fa-battery-three-quarters:after {
  content: "\10f241";
}

.fad.fa-battery-4:after,
.fa-duotone.fa-battery-4:after {
  content: "\10f241";
}

.fad.fa-bed:after,
.fa-duotone.fa-bed:after {
  content: "\10f236";
}

.fad.fa-bed-bunk:after,
.fa-duotone.fa-bed-bunk:after {
  content: "\10f8f8";
}

.fad.fa-bed-empty:after,
.fa-duotone.fa-bed-empty:after {
  content: "\10f8f9";
}

.fad.fa-bed-front:after,
.fa-duotone.fa-bed-front:after {
  content: "\10f8f7";
}

.fad.fa-bed-alt:after,
.fa-duotone.fa-bed-alt:after {
  content: "\10f8f7";
}

.fad.fa-bed-pulse:after,
.fa-duotone.fa-bed-pulse:after {
  content: "\10f487";
}

.fad.fa-procedures:after,
.fa-duotone.fa-procedures:after {
  content: "\10f487";
}

.fad.fa-bee:after,
.fa-duotone.fa-bee:after {
  content: "\10e0b2";
}

.fad.fa-beer-mug:after,
.fa-duotone.fa-beer-mug:after {
  content: "\10e0b3";
}

.fad.fa-beer-foam:after,
.fa-duotone.fa-beer-foam:after {
  content: "\10e0b3";
}

.fad.fa-beer-mug-empty:after,
.fa-duotone.fa-beer-mug-empty:after {
  content: "\10f0fc";
}

.fad.fa-beer:after,
.fa-duotone.fa-beer:after {
  content: "\10f0fc";
}

.fad.fa-bell:after,
.fa-duotone.fa-bell:after {
  content: "\10f0f3";
}

.fad.fa-bell-concierge:after,
.fa-duotone.fa-bell-concierge:after {
  content: "\10f562";
}

.fad.fa-concierge-bell:after,
.fa-duotone.fa-concierge-bell:after {
  content: "\10f562";
}

.fad.fa-bell-exclamation:after,
.fa-duotone.fa-bell-exclamation:after {
  content: "\10f848";
}

.fad.fa-bell-on:after,
.fa-duotone.fa-bell-on:after {
  content: "\10f8fa";
}

.fad.fa-bell-plus:after,
.fa-duotone.fa-bell-plus:after {
  content: "\10f849";
}

.fad.fa-bell-school:after,
.fa-duotone.fa-bell-school:after {
  content: "\10f5d5";
}

.fad.fa-bell-school-slash:after,
.fa-duotone.fa-bell-school-slash:after {
  content: "\10f5d6";
}

.fad.fa-bell-slash:after,
.fa-duotone.fa-bell-slash:after {
  content: "\10f1f6";
}

.fad.fa-bells:after,
.fa-duotone.fa-bells:after {
  content: "\10f77f";
}

.fad.fa-bench-tree:after,
.fa-duotone.fa-bench-tree:after {
  content: "\10e2e7";
}

.fad.fa-bezier-curve:after,
.fa-duotone.fa-bezier-curve:after {
  content: "\10f55b";
}

.fad.fa-bicycle:after,
.fa-duotone.fa-bicycle:after {
  content: "\10f206";
}

.fad.fa-binoculars:after,
.fa-duotone.fa-binoculars:after {
  content: "\10f1e5";
}

.fad.fa-biohazard:after,
.fa-duotone.fa-biohazard:after {
  content: "\10f780";
}

.fad.fa-bitcoin-sign:after,
.fa-duotone.fa-bitcoin-sign:after {
  content: "\10e0b4";
}

.fad.fa-blanket:after,
.fa-duotone.fa-blanket:after {
  content: "\10f498";
}

.fad.fa-blender:after,
.fa-duotone.fa-blender:after {
  content: "\10f517";
}

.fad.fa-blender-phone:after,
.fa-duotone.fa-blender-phone:after {
  content: "\10f6b6";
}

.fad.fa-blinds:after,
.fa-duotone.fa-blinds:after {
  content: "\10f8fb";
}

.fad.fa-blinds-open:after,
.fa-duotone.fa-blinds-open:after {
  content: "\10f8fc";
}

.fad.fa-blinds-raised:after,
.fa-duotone.fa-blinds-raised:after {
  content: "\10f8fd";
}

.fad.fa-block-quote:after,
.fa-duotone.fa-block-quote:after {
  content: "\10e0b5";
}

.fad.fa-blog:after,
.fa-duotone.fa-blog:after {
  content: "\10f781";
}

.fad.fa-blueberries:after,
.fa-duotone.fa-blueberries:after {
  content: "\10e2e8";
}

.fad.fa-bluetooth:after,
.fa-duotone.fa-bluetooth:after {
  content: "\10f293";
}

.fad.fa-bold:after,
.fa-duotone.fa-bold:after {
  content: "\10f032";
}

.fad.fa-bolt:after,
.fa-duotone.fa-bolt:after {
  content: "\10f0e7";
}

.fad.fa-flash:after,
.fa-duotone.fa-flash:after {
  content: "\10f0e7";
}

.fad.fa-bolt-auto:after,
.fa-duotone.fa-bolt-auto:after {
  content: "\10e0b6";
}

.fad.fa-bolt-lightning:after,
.fa-duotone.fa-bolt-lightning:after {
  content: "\10e0b7";
}

.fad.fa-bolt-slash:after,
.fa-duotone.fa-bolt-slash:after {
  content: "\10e0b8";
}

.fad.fa-bomb:after,
.fa-duotone.fa-bomb:after {
  content: "\10f1e2";
}

.fad.fa-bone:after,
.fa-duotone.fa-bone:after {
  content: "\10f5d7";
}

.fad.fa-bone-break:after,
.fa-duotone.fa-bone-break:after {
  content: "\10f5d8";
}

.fad.fa-bong:after,
.fa-duotone.fa-bong:after {
  content: "\10f55c";
}

.fad.fa-book:after,
.fa-duotone.fa-book:after {
  content: "\10f02d";
}

.fad.fa-book-arrow-right:after,
.fa-duotone.fa-book-arrow-right:after {
  content: "\10e0b9";
}

.fad.fa-book-arrow-up:after,
.fa-duotone.fa-book-arrow-up:after {
  content: "\10e0ba";
}

.fad.fa-book-atlas:after,
.fa-duotone.fa-book-atlas:after {
  content: "\10f558";
}

.fad.fa-atlas:after,
.fa-duotone.fa-atlas:after {
  content: "\10f558";
}

.fad.fa-book-bible:after,
.fa-duotone.fa-book-bible:after {
  content: "\10f647";
}

.fad.fa-bible:after,
.fa-duotone.fa-bible:after {
  content: "\10f647";
}

.fad.fa-book-blank:after,
.fa-duotone.fa-book-blank:after {
  content: "\10f5d9";
}

.fad.fa-book-alt:after,
.fa-duotone.fa-book-alt:after {
  content: "\10f5d9";
}

.fad.fa-book-bookmark:after,
.fa-duotone.fa-book-bookmark:after {
  content: "\10e0bb";
}

.fad.fa-book-circle-arrow-right:after,
.fa-duotone.fa-book-circle-arrow-right:after {
  content: "\10e0bc";
}

.fad.fa-book-circle-arrow-up:after,
.fa-duotone.fa-book-circle-arrow-up:after {
  content: "\10e0bd";
}

.fad.fa-book-copy:after,
.fa-duotone.fa-book-copy:after {
  content: "\10e0be";
}

.fad.fa-book-font:after,
.fa-duotone.fa-book-font:after {
  content: "\10e0bf";
}

.fad.fa-book-heart:after,
.fa-duotone.fa-book-heart:after {
  content: "\10f499";
}

.fad.fa-book-journal-whills:after,
.fa-duotone.fa-book-journal-whills:after {
  content: "\10f66a";
}

.fad.fa-journal-whills:after,
.fa-duotone.fa-journal-whills:after {
  content: "\10f66a";
}

.fad.fa-book-medical:after,
.fa-duotone.fa-book-medical:after {
  content: "\10f7e6";
}

.fad.fa-book-open:after,
.fa-duotone.fa-book-open:after {
  content: "\10f518";
}

.fad.fa-book-open-cover:after,
.fa-duotone.fa-book-open-cover:after {
  content: "\10e0c0";
}

.fad.fa-book-open-alt:after,
.fa-duotone.fa-book-open-alt:after {
  content: "\10e0c0";
}

.fad.fa-book-open-reader:after,
.fa-duotone.fa-book-open-reader:after {
  content: "\10f5da";
}

.fad.fa-book-reader:after,
.fa-duotone.fa-book-reader:after {
  content: "\10f5da";
}

.fad.fa-book-quran:after,
.fa-duotone.fa-book-quran:after {
  content: "\10f687";
}

.fad.fa-quran:after,
.fa-duotone.fa-quran:after {
  content: "\10f687";
}

.fad.fa-book-section:after,
.fa-duotone.fa-book-section:after {
  content: "\10e0c1";
}

.fad.fa-book-law:after,
.fa-duotone.fa-book-law:after {
  content: "\10e0c1";
}

.fad.fa-book-skull:after,
.fa-duotone.fa-book-skull:after {
  content: "\10f6b7";
}

.fad.fa-book-dead:after,
.fa-duotone.fa-book-dead:after {
  content: "\10f6b7";
}

.fad.fa-book-sparkles:after,
.fa-duotone.fa-book-sparkles:after {
  content: "\10f6b8";
}

.fad.fa-book-spells:after,
.fa-duotone.fa-book-spells:after {
  content: "\10f6b8";
}

.fad.fa-book-tanakh:after,
.fa-duotone.fa-book-tanakh:after {
  content: "\10f827";
}

.fad.fa-tanakh:after,
.fa-duotone.fa-tanakh:after {
  content: "\10f827";
}

.fad.fa-book-user:after,
.fa-duotone.fa-book-user:after {
  content: "\10f7e7";
}

.fad.fa-bookmark:after,
.fa-duotone.fa-bookmark:after {
  content: "\10f02e";
}

.fad.fa-bookmark-slash:after,
.fa-duotone.fa-bookmark-slash:after {
  content: "\10e0c2";
}

.fad.fa-books:after,
.fa-duotone.fa-books:after {
  content: "\10f5db";
}

.fad.fa-books-medical:after,
.fa-duotone.fa-books-medical:after {
  content: "\10f7e8";
}

.fad.fa-boombox:after,
.fa-duotone.fa-boombox:after {
  content: "\10f8a5";
}

.fad.fa-boot:after,
.fa-duotone.fa-boot:after {
  content: "\10f782";
}

.fad.fa-booth-curtain:after,
.fa-duotone.fa-booth-curtain:after {
  content: "\10f734";
}

.fad.fa-border-all:after,
.fa-duotone.fa-border-all:after {
  content: "\10f84c";
}

.fad.fa-border-bottom:after,
.fa-duotone.fa-border-bottom:after {
  content: "\10f84d";
}

.fad.fa-border-bottom-right:after,
.fa-duotone.fa-border-bottom-right:after {
  content: "\10f854";
}

.fad.fa-border-style-alt:after,
.fa-duotone.fa-border-style-alt:after {
  content: "\10f854";
}

.fad.fa-border-center-h:after,
.fa-duotone.fa-border-center-h:after {
  content: "\10f89c";
}

.fad.fa-border-center-v:after,
.fa-duotone.fa-border-center-v:after {
  content: "\10f89d";
}

.fad.fa-border-inner:after,
.fa-duotone.fa-border-inner:after {
  content: "\10f84e";
}

.fad.fa-border-left:after,
.fa-duotone.fa-border-left:after {
  content: "\10f84f";
}

.fad.fa-border-none:after,
.fa-duotone.fa-border-none:after {
  content: "\10f850";
}

.fad.fa-border-outer:after,
.fa-duotone.fa-border-outer:after {
  content: "\10f851";
}

.fad.fa-border-right:after,
.fa-duotone.fa-border-right:after {
  content: "\10f852";
}

.fad.fa-border-top:after,
.fa-duotone.fa-border-top:after {
  content: "\10f855";
}

.fad.fa-border-top-left:after,
.fa-duotone.fa-border-top-left:after {
  content: "\10f853";
}

.fad.fa-border-style:after,
.fa-duotone.fa-border-style:after {
  content: "\10f853";
}

.fad.fa-bow-arrow:after,
.fa-duotone.fa-bow-arrow:after {
  content: "\10f6b9";
}

.fad.fa-bowl-chopsticks:after,
.fa-duotone.fa-bowl-chopsticks:after {
  content: "\10e2e9";
}

.fad.fa-bowl-chopsticks-noodles:after,
.fa-duotone.fa-bowl-chopsticks-noodles:after {
  content: "\10e2ea";
}

.fad.fa-bowl-hot:after,
.fa-duotone.fa-bowl-hot:after {
  content: "\10f823";
}

.fad.fa-soup:after,
.fa-duotone.fa-soup:after {
  content: "\10f823";
}

.fad.fa-bowl-rice:after,
.fa-duotone.fa-bowl-rice:after {
  content: "\10e2eb";
}

.fad.fa-bowling-ball:after,
.fa-duotone.fa-bowling-ball:after {
  content: "\10f436";
}

.fad.fa-bowling-ball-pin:after,
.fa-duotone.fa-bowling-ball-pin:after {
  content: "\10e0c3";
}

.fad.fa-bowling-pins:after,
.fa-duotone.fa-bowling-pins:after {
  content: "\10f437";
}

.fad.fa-box:after,
.fa-duotone.fa-box:after {
  content: "\10f466";
}

.fad.fa-box-archive:after,
.fa-duotone.fa-box-archive:after {
  content: "\10f187";
}

.fad.fa-archive:after,
.fa-duotone.fa-archive:after {
  content: "\10f187";
}

.fad.fa-box-ballot:after,
.fa-duotone.fa-box-ballot:after {
  content: "\10f735";
}

.fad.fa-box-check:after,
.fa-duotone.fa-box-check:after {
  content: "\10f467";
}

.fad.fa-box-circle-check:after,
.fa-duotone.fa-box-circle-check:after {
  content: "\10e0c4";
}

.fad.fa-box-dollar:after,
.fa-duotone.fa-box-dollar:after {
  content: "\10f4a0";
}

.fad.fa-box-usd:after,
.fa-duotone.fa-box-usd:after {
  content: "\10f4a0";
}

.fad.fa-box-heart:after,
.fa-duotone.fa-box-heart:after {
  content: "\10f49d";
}

.fad.fa-box-open:after,
.fa-duotone.fa-box-open:after {
  content: "\10f49e";
}

.fad.fa-box-open-full:after,
.fa-duotone.fa-box-open-full:after {
  content: "\10f49c";
}

.fad.fa-box-full:after,
.fa-duotone.fa-box-full:after {
  content: "\10f49c";
}

.fad.fa-box-taped:after,
.fa-duotone.fa-box-taped:after {
  content: "\10f49a";
}

.fad.fa-box-alt:after,
.fa-duotone.fa-box-alt:after {
  content: "\10f49a";
}

.fad.fa-box-tissue:after,
.fa-duotone.fa-box-tissue:after {
  content: "\10e05b";
}

.fad.fa-boxes-stacked:after,
.fa-duotone.fa-boxes-stacked:after {
  content: "\10f468";
}

.fad.fa-boxes:after,
.fa-duotone.fa-boxes:after {
  content: "\10f468";
}

.fad.fa-boxes-alt:after,
.fa-duotone.fa-boxes-alt:after {
  content: "\10f468";
}

.fad.fa-boxing-glove:after,
.fa-duotone.fa-boxing-glove:after {
  content: "\10f438";
}

.fad.fa-glove-boxing:after,
.fa-duotone.fa-glove-boxing:after {
  content: "\10f438";
}

.fad.fa-bracket-curly:after,
.fa-duotone.fa-bracket-curly:after {
  content: "\10e2ec";
}

.fad.fa-bracket-curly-left:after,
.fa-duotone.fa-bracket-curly-left:after {
  content: "\10e2ec";
}

.fad.fa-bracket-curly-right:after,
.fa-duotone.fa-bracket-curly-right:after {
  content: "\10e2ed";
}

.fad.fa-bracket-round:after,
.fa-duotone.fa-bracket-round:after {
  content: "\10e2ee";
}

.fad.fa-parenthesis:after,
.fa-duotone.fa-parenthesis:after {
  content: "\10e2ee";
}

.fad.fa-bracket-round-right:after,
.fa-duotone.fa-bracket-round-right:after {
  content: "\10e2ef";
}

.fad.fa-bracket-square:after,
.fa-duotone.fa-bracket-square:after {
  content: "\10e2f0";
}

.fad.fa-bracket:after,
.fa-duotone.fa-bracket:after {
  content: "\10e2f0";
}

.fad.fa-bracket-left:after,
.fa-duotone.fa-bracket-left:after {
  content: "\10e2f0";
}

.fad.fa-bracket-square-right:after,
.fa-duotone.fa-bracket-square-right:after {
  content: "\10e2f1";
}

.fad.fa-brackets-curly:after,
.fa-duotone.fa-brackets-curly:after {
  content: "\10f7ea";
}

.fad.fa-brackets-round:after,
.fa-duotone.fa-brackets-round:after {
  content: "\10e0c5";
}

.fad.fa-parentheses:after,
.fa-duotone.fa-parentheses:after {
  content: "\10e0c5";
}

.fad.fa-brackets-square:after,
.fa-duotone.fa-brackets-square:after {
  content: "\10f7e9";
}

.fad.fa-brackets:after,
.fa-duotone.fa-brackets:after {
  content: "\10f7e9";
}

.fad.fa-braille:after,
.fa-duotone.fa-braille:after {
  content: "\10f2a1";
}

.fad.fa-brain:after,
.fa-duotone.fa-brain:after {
  content: "\10f5dc";
}

.fad.fa-brain-arrow-curved-right:after,
.fa-duotone.fa-brain-arrow-curved-right:after {
  content: "\10f677";
}

.fad.fa-mind-share:after,
.fa-duotone.fa-mind-share:after {
  content: "\10f677";
}

.fad.fa-brain-circuit:after,
.fa-duotone.fa-brain-circuit:after {
  content: "\10e0c6";
}

.fad.fa-brake-warning:after,
.fa-duotone.fa-brake-warning:after {
  content: "\10e0c7";
}

.fad.fa-bread-loaf:after,
.fa-duotone.fa-bread-loaf:after {
  content: "\10f7eb";
}

.fad.fa-bread-slice:after,
.fa-duotone.fa-bread-slice:after {
  content: "\10f7ec";
}

.fad.fa-briefcase:after,
.fa-duotone.fa-briefcase:after {
  content: "\10f0b1";
}

.fad.fa-briefcase-arrow-right:after,
.fa-duotone.fa-briefcase-arrow-right:after {
  content: "\10e2f2";
}

.fad.fa-briefcase-blank:after,
.fa-duotone.fa-briefcase-blank:after {
  content: "\10e0c8";
}

.fad.fa-briefcase-clock:after,
.fa-duotone.fa-briefcase-clock:after {
  content: "\10f64a";
}

.fad.fa-business-time:after,
.fa-duotone.fa-business-time:after {
  content: "\10f64a";
}

.fad.fa-briefcase-medical:after,
.fa-duotone.fa-briefcase-medical:after {
  content: "\10f469";
}

.fad.fa-brightness:after,
.fa-duotone.fa-brightness:after {
  content: "\10e0c9";
}

.fad.fa-brightness-low:after,
.fa-duotone.fa-brightness-low:after {
  content: "\10e0ca";
}

.fad.fa-bring-forward:after,
.fa-duotone.fa-bring-forward:after {
  content: "\10f856";
}

.fad.fa-bring-front:after,
.fa-duotone.fa-bring-front:after {
  content: "\10f857";
}

.fad.fa-broom:after,
.fa-duotone.fa-broom:after {
  content: "\10f51a";
}

.fad.fa-browser:after,
.fa-duotone.fa-browser:after {
  content: "\10f37e";
}

.fad.fa-browsers:after,
.fa-duotone.fa-browsers:after {
  content: "\10e0cb";
}

.fad.fa-brush:after,
.fa-duotone.fa-brush:after {
  content: "\10f55d";
}

.fad.fa-bug:after,
.fa-duotone.fa-bug:after {
  content: "\10f188";
}

.fad.fa-building:after,
.fa-duotone.fa-building:after {
  content: "\10f1ad";
}

.fad.fa-buildings:after,
.fa-duotone.fa-buildings:after {
  content: "\10e0cc";
}

.fad.fa-bullhorn:after,
.fa-duotone.fa-bullhorn:after {
  content: "\10f0a1";
}

.fad.fa-bullseye:after,
.fa-duotone.fa-bullseye:after {
  content: "\10f140";
}

.fad.fa-bullseye-arrow:after,
.fa-duotone.fa-bullseye-arrow:after {
  content: "\10f648";
}

.fad.fa-bullseye-pointer:after,
.fa-duotone.fa-bullseye-pointer:after {
  content: "\10f649";
}

.fad.fa-burger:after,
.fa-duotone.fa-burger:after {
  content: "\10f805";
}

.fad.fa-hamburger:after,
.fa-duotone.fa-hamburger:after {
  content: "\10f805";
}

.fad.fa-burger-cheese:after,
.fa-duotone.fa-burger-cheese:after {
  content: "\10f7f1";
}

.fad.fa-cheeseburger:after,
.fa-duotone.fa-cheeseburger:after {
  content: "\10f7f1";
}

.fad.fa-burger-fries:after,
.fa-duotone.fa-burger-fries:after {
  content: "\10e0cd";
}

.fad.fa-burger-glass:after,
.fa-duotone.fa-burger-glass:after {
  content: "\10e0ce";
}

.fad.fa-burger-soda:after,
.fa-duotone.fa-burger-soda:after {
  content: "\10f858";
}

.fad.fa-burrito:after,
.fa-duotone.fa-burrito:after {
  content: "\10f7ed";
}

.fad.fa-bus:after,
.fa-duotone.fa-bus:after {
  content: "\10f207";
}

.fad.fa-bus-school:after,
.fa-duotone.fa-bus-school:after {
  content: "\10f5dd";
}

.fad.fa-bus-simple:after,
.fa-duotone.fa-bus-simple:after {
  content: "\10f55e";
}

.fad.fa-bus-alt:after,
.fa-duotone.fa-bus-alt:after {
  content: "\10f55e";
}

.fad.fa-c:after,
.fa-duotone.fa-c:after {
  content: "\10e2f3";
}

.fad.fa-cabinet-filing:after,
.fa-duotone.fa-cabinet-filing:after {
  content: "\10f64b";
}

.fad.fa-cable-car:after,
.fa-duotone.fa-cable-car:after {
  content: "\10e0cf";
}

.fad.fa-cactus:after,
.fa-duotone.fa-cactus:after {
  content: "\10f8a7";
}

.fad.fa-cake-candles:after,
.fa-duotone.fa-cake-candles:after {
  content: "\10f1fd";
}

.fad.fa-birthday-cake:after,
.fa-duotone.fa-birthday-cake:after {
  content: "\10f1fd";
}

.fad.fa-calculator:after,
.fa-duotone.fa-calculator:after {
  content: "\10f1ec";
}

.fad.fa-calculator-simple:after,
.fa-duotone.fa-calculator-simple:after {
  content: "\10f64c";
}

.fad.fa-calculator-alt:after,
.fa-duotone.fa-calculator-alt:after {
  content: "\10f64c";
}

.fad.fa-calendar:after,
.fa-duotone.fa-calendar:after {
  content: "\10f133";
}

.fad.fa-calendar-arrow-down:after,
.fa-duotone.fa-calendar-arrow-down:after {
  content: "\10e0d0";
}

.fad.fa-calendar-download:after,
.fa-duotone.fa-calendar-download:after {
  content: "\10e0d0";
}

.fad.fa-calendar-arrow-up:after,
.fa-duotone.fa-calendar-arrow-up:after {
  content: "\10e0d1";
}

.fad.fa-calendar-upload:after,
.fa-duotone.fa-calendar-upload:after {
  content: "\10e0d1";
}

.fad.fa-calendar-check:after,
.fa-duotone.fa-calendar-check:after {
  content: "\10f274";
}

.fad.fa-calendar-clock:after,
.fa-duotone.fa-calendar-clock:after {
  content: "\10e0d2";
}

.fad.fa-calendar-time:after,
.fa-duotone.fa-calendar-time:after {
  content: "\10e0d2";
}

.fad.fa-calendar-day:after,
.fa-duotone.fa-calendar-day:after {
  content: "\10f783";
}

.fad.fa-calendar-days:after,
.fa-duotone.fa-calendar-days:after {
  content: "\10f073";
}

.fad.fa-calendar-alt:after,
.fa-duotone.fa-calendar-alt:after {
  content: "\10f073";
}

.fad.fa-calendar-exclamation:after,
.fa-duotone.fa-calendar-exclamation:after {
  content: "\10f334";
}

.fad.fa-calendar-heart:after,
.fa-duotone.fa-calendar-heart:after {
  content: "\10e0d3";
}

.fad.fa-calendar-image:after,
.fa-duotone.fa-calendar-image:after {
  content: "\10e0d4";
}

.fad.fa-calendar-lines:after,
.fa-duotone.fa-calendar-lines:after {
  content: "\10e0d5";
}

.fad.fa-calendar-note:after,
.fa-duotone.fa-calendar-note:after {
  content: "\10e0d5";
}

.fad.fa-calendar-minus:after,
.fa-duotone.fa-calendar-minus:after {
  content: "\10f272";
}

.fad.fa-calendar-pen:after,
.fa-duotone.fa-calendar-pen:after {
  content: "\10f333";
}

.fad.fa-calendar-edit:after,
.fa-duotone.fa-calendar-edit:after {
  content: "\10f333";
}

.fad.fa-calendar-plus:after,
.fa-duotone.fa-calendar-plus:after {
  content: "\10f271";
}

.fad.fa-calendar-range:after,
.fa-duotone.fa-calendar-range:after {
  content: "\10e0d6";
}

.fad.fa-calendar-star:after,
.fa-duotone.fa-calendar-star:after {
  content: "\10f736";
}

.fad.fa-calendar-week:after,
.fa-duotone.fa-calendar-week:after {
  content: "\10f784";
}

.fad.fa-calendar-xmark:after,
.fa-duotone.fa-calendar-xmark:after {
  content: "\10f273";
}

.fad.fa-calendar-times:after,
.fa-duotone.fa-calendar-times:after {
  content: "\10f273";
}

.fad.fa-calendars:after,
.fa-duotone.fa-calendars:after {
  content: "\10e0d7";
}

.fad.fa-camcorder:after,
.fa-duotone.fa-camcorder:after {
  content: "\10f8a8";
}

.fad.fa-video-handheld:after,
.fa-duotone.fa-video-handheld:after {
  content: "\10f8a8";
}

.fad.fa-camera:after,
.fa-duotone.fa-camera:after {
  content: "\10f030";
}

.fad.fa-camera-alt:after,
.fa-duotone.fa-camera-alt:after {
  content: "\10f030";
}

.fad.fa-camera-cctv:after,
.fa-duotone.fa-camera-cctv:after {
  content: "\10f8ac";
}

.fad.fa-cctv:after,
.fa-duotone.fa-cctv:after {
  content: "\10f8ac";
}

.fad.fa-camera-movie:after,
.fa-duotone.fa-camera-movie:after {
  content: "\10f8a9";
}

.fad.fa-camera-polaroid:after,
.fa-duotone.fa-camera-polaroid:after {
  content: "\10f8aa";
}

.fad.fa-camera-retro:after,
.fa-duotone.fa-camera-retro:after {
  content: "\10f083";
}

.fad.fa-camera-rotate:after,
.fa-duotone.fa-camera-rotate:after {
  content: "\10e0d8";
}

.fad.fa-camera-security:after,
.fa-duotone.fa-camera-security:after {
  content: "\10f8fe";
}

.fad.fa-camera-home:after,
.fa-duotone.fa-camera-home:after {
  content: "\10f8fe";
}

.fad.fa-camera-slash:after,
.fa-duotone.fa-camera-slash:after {
  content: "\10e0d9";
}

.fad.fa-camera-viewfinder:after,
.fa-duotone.fa-camera-viewfinder:after {
  content: "\10e0da";
}

.fad.fa-camera-web:after,
.fa-duotone.fa-camera-web:after {
  content: "\10f832";
}

.fad.fa-webcam:after,
.fa-duotone.fa-webcam:after {
  content: "\10f832";
}

.fad.fa-camera-web-slash:after,
.fa-duotone.fa-camera-web-slash:after {
  content: "\10f833";
}

.fad.fa-webcam-slash:after,
.fa-duotone.fa-webcam-slash:after {
  content: "\10f833";
}

.fad.fa-campfire:after,
.fa-duotone.fa-campfire:after {
  content: "\10f6ba";
}

.fad.fa-campground:after,
.fa-duotone.fa-campground:after {
  content: "\10f6bb";
}

.fad.fa-candle-holder:after,
.fa-duotone.fa-candle-holder:after {
  content: "\10f6bc";
}

.fad.fa-candy-cane:after,
.fa-duotone.fa-candy-cane:after {
  content: "\10f786";
}

.fad.fa-candy-corn:after,
.fa-duotone.fa-candy-corn:after {
  content: "\10f6bd";
}

.fad.fa-cannabis:after,
.fa-duotone.fa-cannabis:after {
  content: "\10f55f";
}

.fad.fa-capsules:after,
.fa-duotone.fa-capsules:after {
  content: "\10f46b";
}

.fad.fa-car:after,
.fa-duotone.fa-car:after {
  content: "\10f1b9";
}

.fad.fa-automobile:after,
.fa-duotone.fa-automobile:after {
  content: "\10f1b9";
}

.fad.fa-car-battery:after,
.fa-duotone.fa-car-battery:after {
  content: "\10f5df";
}

.fad.fa-battery-car:after,
.fa-duotone.fa-battery-car:after {
  content: "\10f5df";
}

.fad.fa-car-building:after,
.fa-duotone.fa-car-building:after {
  content: "\10f859";
}

.fad.fa-car-bump:after,
.fa-duotone.fa-car-bump:after {
  content: "\10f5e0";
}

.fad.fa-car-bus:after,
.fa-duotone.fa-car-bus:after {
  content: "\10f85a";
}

.fad.fa-car-crash:after,
.fa-duotone.fa-car-crash:after {
  content: "\10f5e1";
}

.fad.fa-car-garage:after,
.fa-duotone.fa-car-garage:after {
  content: "\10f5e2";
}

.fad.fa-car-rear:after,
.fa-duotone.fa-car-rear:after {
  content: "\10f5de";
}

.fad.fa-car-alt:after,
.fa-duotone.fa-car-alt:after {
  content: "\10f5de";
}

.fad.fa-car-side:after,
.fa-duotone.fa-car-side:after {
  content: "\10f5e4";
}

.fad.fa-car-tilt:after,
.fa-duotone.fa-car-tilt:after {
  content: "\10f5e5";
}

.fad.fa-car-wash:after,
.fa-duotone.fa-car-wash:after {
  content: "\10f5e6";
}

.fad.fa-car-wrench:after,
.fa-duotone.fa-car-wrench:after {
  content: "\10f5e3";
}

.fad.fa-car-mechanic:after,
.fa-duotone.fa-car-mechanic:after {
  content: "\10f5e3";
}

.fad.fa-caravan:after,
.fa-duotone.fa-caravan:after {
  content: "\10f8ff";
}

.fad.fa-caravan-simple:after,
.fa-duotone.fa-caravan-simple:after {
  content: "\10e000";
}

.fad.fa-caravan-alt:after,
.fa-duotone.fa-caravan-alt:after {
  content: "\10e000";
}

.fad.fa-caret-down:after,
.fa-duotone.fa-caret-down:after {
  content: "\10f0d7";
}

.fad.fa-caret-left:after,
.fa-duotone.fa-caret-left:after {
  content: "\10f0d9";
}

.fad.fa-caret-right:after,
.fa-duotone.fa-caret-right:after {
  content: "\10f0da";
}

.fad.fa-caret-up:after,
.fa-duotone.fa-caret-up:after {
  content: "\10f0d8";
}

.fad.fa-carrot:after,
.fa-duotone.fa-carrot:after {
  content: "\10f787";
}

.fad.fa-cars:after,
.fa-duotone.fa-cars:after {
  content: "\10f85b";
}

.fad.fa-cart-arrow-down:after,
.fa-duotone.fa-cart-arrow-down:after {
  content: "\10f218";
}

.fad.fa-cart-flatbed:after,
.fa-duotone.fa-cart-flatbed:after {
  content: "\10f474";
}

.fad.fa-dolly-flatbed:after,
.fa-duotone.fa-dolly-flatbed:after {
  content: "\10f474";
}

.fad.fa-cart-flatbed-boxes:after,
.fa-duotone.fa-cart-flatbed-boxes:after {
  content: "\10f475";
}

.fad.fa-dolly-flatbed-alt:after,
.fa-duotone.fa-dolly-flatbed-alt:after {
  content: "\10f475";
}

.fad.fa-cart-flatbed-empty:after,
.fa-duotone.fa-cart-flatbed-empty:after {
  content: "\10f476";
}

.fad.fa-dolly-flatbed-empty:after,
.fa-duotone.fa-dolly-flatbed-empty:after {
  content: "\10f476";
}

.fad.fa-cart-flatbed-suitcase:after,
.fa-duotone.fa-cart-flatbed-suitcase:after {
  content: "\10f59d";
}

.fad.fa-luggage-cart:after,
.fa-duotone.fa-luggage-cart:after {
  content: "\10f59d";
}

.fad.fa-cart-minus:after,
.fa-duotone.fa-cart-minus:after {
  content: "\10e0db";
}

.fad.fa-cart-plus:after,
.fa-duotone.fa-cart-plus:after {
  content: "\10f217";
}

.fad.fa-cart-shopping:after,
.fa-duotone.fa-cart-shopping:after {
  content: "\10f07a";
}

.fad.fa-shopping-cart:after,
.fa-duotone.fa-shopping-cart:after {
  content: "\10f07a";
}

.fad.fa-cart-shopping-fast:after,
.fa-duotone.fa-cart-shopping-fast:after {
  content: "\10e0dc";
}

.fad.fa-cart-xmark:after,
.fa-duotone.fa-cart-xmark:after {
  content: "\10e0dd";
}

.fad.fa-cash-register:after,
.fa-duotone.fa-cash-register:after {
  content: "\10f788";
}

.fad.fa-cassette-betamax:after,
.fa-duotone.fa-cassette-betamax:after {
  content: "\10f8a4";
}

.fad.fa-betamax:after,
.fa-duotone.fa-betamax:after {
  content: "\10f8a4";
}

.fad.fa-cassette-tape:after,
.fa-duotone.fa-cassette-tape:after {
  content: "\10f8ab";
}

.fad.fa-cassette-vhs:after,
.fa-duotone.fa-cassette-vhs:after {
  content: "\10f8ec";
}

.fad.fa-vhs:after,
.fa-duotone.fa-vhs:after {
  content: "\10f8ec";
}

.fad.fa-castle:after,
.fa-duotone.fa-castle:after {
  content: "\10e0de";
}

.fad.fa-cat:after,
.fa-duotone.fa-cat:after {
  content: "\10f6be";
}

.fad.fa-cat-space:after,
.fa-duotone.fa-cat-space:after {
  content: "\10e001";
}

.fad.fa-cauldron:after,
.fa-duotone.fa-cauldron:after {
  content: "\10f6bf";
}

.fad.fa-cedi-sign:after,
.fa-duotone.fa-cedi-sign:after {
  content: "\10e0df";
}

.fad.fa-cent-sign:after,
.fa-duotone.fa-cent-sign:after {
  content: "\10e0e0";
}

.fad.fa-certificate:after,
.fa-duotone.fa-certificate:after {
  content: "\10f0a3";
}

.fad.fa-chair:after,
.fa-duotone.fa-chair:after {
  content: "\10f6c0";
}

.fad.fa-chair-office:after,
.fa-duotone.fa-chair-office:after {
  content: "\10f6c1";
}

.fad.fa-chalkboard:after,
.fa-duotone.fa-chalkboard:after {
  content: "\10f51b";
}

.fad.fa-blackboard:after,
.fa-duotone.fa-blackboard:after {
  content: "\10f51b";
}

.fad.fa-chalkboard-user:after,
.fa-duotone.fa-chalkboard-user:after {
  content: "\10f51c";
}

.fad.fa-chalkboard-teacher:after,
.fa-duotone.fa-chalkboard-teacher:after {
  content: "\10f51c";
}

.fad.fa-champagne-glass:after,
.fa-duotone.fa-champagne-glass:after {
  content: "\10f79e";
}

.fad.fa-glass-champagne:after,
.fa-duotone.fa-glass-champagne:after {
  content: "\10f79e";
}

.fad.fa-champagne-glasses:after,
.fa-duotone.fa-champagne-glasses:after {
  content: "\10f79f";
}

.fad.fa-glass-cheers:after,
.fa-duotone.fa-glass-cheers:after {
  content: "\10f79f";
}

.fad.fa-charging-station:after,
.fa-duotone.fa-charging-station:after {
  content: "\10f5e7";
}

.fad.fa-chart-area:after,
.fa-duotone.fa-chart-area:after {
  content: "\10f1fe";
}

.fad.fa-area-chart:after,
.fa-duotone.fa-area-chart:after {
  content: "\10f1fe";
}

.fad.fa-chart-bar:after,
.fa-duotone.fa-chart-bar:after {
  content: "\10f080";
}

.fad.fa-bar-chart:after,
.fa-duotone.fa-bar-chart:after {
  content: "\10f080";
}

.fad.fa-chart-bullet:after,
.fa-duotone.fa-chart-bullet:after {
  content: "\10e0e1";
}

.fad.fa-chart-candlestick:after,
.fa-duotone.fa-chart-candlestick:after {
  content: "\10e0e2";
}

.fad.fa-chart-column:after,
.fa-duotone.fa-chart-column:after {
  content: "\10e0e3";
}

.fad.fa-chart-gantt:after,
.fa-duotone.fa-chart-gantt:after {
  content: "\10e0e4";
}

.fad.fa-chart-line:after,
.fa-duotone.fa-chart-line:after {
  content: "\10f201";
}

.fad.fa-line-chart:after,
.fa-duotone.fa-line-chart:after {
  content: "\10f201";
}

.fad.fa-chart-line-down:after,
.fa-duotone.fa-chart-line-down:after {
  content: "\10f64d";
}

.fad.fa-chart-line-up:after,
.fa-duotone.fa-chart-line-up:after {
  content: "\10e0e5";
}

.fad.fa-chart-mixed:after,
.fa-duotone.fa-chart-mixed:after {
  content: "\10f643";
}

.fad.fa-analytics:after,
.fa-duotone.fa-analytics:after {
  content: "\10f643";
}

.fad.fa-chart-network:after,
.fa-duotone.fa-chart-network:after {
  content: "\10f78a";
}

.fad.fa-chart-pie:after,
.fa-duotone.fa-chart-pie:after {
  content: "\10f200";
}

.fad.fa-pie-chart:after,
.fa-duotone.fa-pie-chart:after {
  content: "\10f200";
}

.fad.fa-chart-pie-simple:after,
.fa-duotone.fa-chart-pie-simple:after {
  content: "\10f64e";
}

.fad.fa-chart-pie-alt:after,
.fa-duotone.fa-chart-pie-alt:after {
  content: "\10f64e";
}

.fad.fa-chart-pyramid:after,
.fa-duotone.fa-chart-pyramid:after {
  content: "\10e0e6";
}

.fad.fa-chart-radar:after,
.fa-duotone.fa-chart-radar:after {
  content: "\10e0e7";
}

.fad.fa-chart-scatter:after,
.fa-duotone.fa-chart-scatter:after {
  content: "\10f7ee";
}

.fad.fa-chart-scatter-3d:after,
.fa-duotone.fa-chart-scatter-3d:after {
  content: "\10e0e8";
}

.fad.fa-chart-scatter-bubble:after,
.fa-duotone.fa-chart-scatter-bubble:after {
  content: "\10e0e9";
}

.fad.fa-chart-tree-map:after,
.fa-duotone.fa-chart-tree-map:after {
  content: "\10e0ea";
}

.fad.fa-chart-user:after,
.fa-duotone.fa-chart-user:after {
  content: "\10f6a3";
}

.fad.fa-user-chart:after,
.fa-duotone.fa-user-chart:after {
  content: "\10f6a3";
}

.fad.fa-chart-waterfall:after,
.fa-duotone.fa-chart-waterfall:after {
  content: "\10e0eb";
}

.fad.fa-check:after,
.fa-duotone.fa-check:after {
  content: "\10f00c";
}

.fad.fa-check-double:after,
.fa-duotone.fa-check-double:after {
  content: "\10f560";
}

.fad.fa-check-to-slot:after,
.fa-duotone.fa-check-to-slot:after {
  content: "\10f772";
}

.fad.fa-vote-yea:after,
.fa-duotone.fa-vote-yea:after {
  content: "\10f772";
}

.fad.fa-cheese:after,
.fa-duotone.fa-cheese:after {
  content: "\10f7ef";
}

.fad.fa-cheese-swiss:after,
.fa-duotone.fa-cheese-swiss:after {
  content: "\10f7f0";
}

.fad.fa-cherries:after,
.fa-duotone.fa-cherries:after {
  content: "\10e0ec";
}

.fad.fa-chess:after,
.fa-duotone.fa-chess:after {
  content: "\10f439";
}

.fad.fa-chess-bishop:after,
.fa-duotone.fa-chess-bishop:after {
  content: "\10f43a";
}

.fad.fa-chess-bishop-piece:after,
.fa-duotone.fa-chess-bishop-piece:after {
  content: "\10f43b";
}

.fad.fa-chess-bishop-alt:after,
.fa-duotone.fa-chess-bishop-alt:after {
  content: "\10f43b";
}

.fad.fa-chess-board:after,
.fa-duotone.fa-chess-board:after {
  content: "\10f43c";
}

.fad.fa-chess-clock:after,
.fa-duotone.fa-chess-clock:after {
  content: "\10f43d";
}

.fad.fa-chess-clock-flip:after,
.fa-duotone.fa-chess-clock-flip:after {
  content: "\10f43e";
}

.fad.fa-chess-clock-alt:after,
.fa-duotone.fa-chess-clock-alt:after {
  content: "\10f43e";
}

.fad.fa-chess-king:after,
.fa-duotone.fa-chess-king:after {
  content: "\10f43f";
}

.fad.fa-chess-king-piece:after,
.fa-duotone.fa-chess-king-piece:after {
  content: "\10f440";
}

.fad.fa-chess-king-alt:after,
.fa-duotone.fa-chess-king-alt:after {
  content: "\10f440";
}

.fad.fa-chess-knight:after,
.fa-duotone.fa-chess-knight:after {
  content: "\10f441";
}

.fad.fa-chess-knight-piece:after,
.fa-duotone.fa-chess-knight-piece:after {
  content: "\10f442";
}

.fad.fa-chess-knight-alt:after,
.fa-duotone.fa-chess-knight-alt:after {
  content: "\10f442";
}

.fad.fa-chess-pawn:after,
.fa-duotone.fa-chess-pawn:after {
  content: "\10f443";
}

.fad.fa-chess-pawn-piece:after,
.fa-duotone.fa-chess-pawn-piece:after {
  content: "\10f444";
}

.fad.fa-chess-pawn-alt:after,
.fa-duotone.fa-chess-pawn-alt:after {
  content: "\10f444";
}

.fad.fa-chess-queen:after,
.fa-duotone.fa-chess-queen:after {
  content: "\10f445";
}

.fad.fa-chess-queen-piece:after,
.fa-duotone.fa-chess-queen-piece:after {
  content: "\10f446";
}

.fad.fa-chess-queen-alt:after,
.fa-duotone.fa-chess-queen-alt:after {
  content: "\10f446";
}

.fad.fa-chess-rook:after,
.fa-duotone.fa-chess-rook:after {
  content: "\10f447";
}

.fad.fa-chess-rook-piece:after,
.fa-duotone.fa-chess-rook-piece:after {
  content: "\10f448";
}

.fad.fa-chess-rook-alt:after,
.fa-duotone.fa-chess-rook-alt:after {
  content: "\10f448";
}

.fad.fa-chevron-down:after,
.fa-duotone.fa-chevron-down:after {
  content: "\10f078";
}

.fad.fa-chevron-left:after,
.fa-duotone.fa-chevron-left:after {
  content: "\10f053";
}

.fad.fa-chevron-right:after,
.fa-duotone.fa-chevron-right:after {
  content: "\10f054";
}

.fad.fa-chevron-up:after,
.fa-duotone.fa-chevron-up:after {
  content: "\10f077";
}

.fad.fa-chevrons-down:after,
.fa-duotone.fa-chevrons-down:after {
  content: "\10f322";
}

.fad.fa-chevron-double-down:after,
.fa-duotone.fa-chevron-double-down:after {
  content: "\10f322";
}

.fad.fa-chevrons-left:after,
.fa-duotone.fa-chevrons-left:after {
  content: "\10f323";
}

.fad.fa-chevron-double-left:after,
.fa-duotone.fa-chevron-double-left:after {
  content: "\10f323";
}

.fad.fa-chevrons-right:after,
.fa-duotone.fa-chevrons-right:after {
  content: "\10f324";
}

.fad.fa-chevron-double-right:after,
.fa-duotone.fa-chevron-double-right:after {
  content: "\10f324";
}

.fad.fa-chevrons-up:after,
.fa-duotone.fa-chevrons-up:after {
  content: "\10f325";
}

.fad.fa-chevron-double-up:after,
.fa-duotone.fa-chevron-double-up:after {
  content: "\10f325";
}

.fad.fa-child:after,
.fa-duotone.fa-child:after {
  content: "\10f1ae";
}

.fad.fa-chimney:after,
.fa-duotone.fa-chimney:after {
  content: "\10f78b";
}

.fad.fa-church:after,
.fa-duotone.fa-church:after {
  content: "\10f51d";
}

.fad.fa-circle:after,
.fa-duotone.fa-circle:after {
  content: "\10f111";
}

.fad.fa-circle-0:after,
.fa-duotone.fa-circle-0:after {
  content: "\10e0ed";
}

.fad.fa-circle-1:after,
.fa-duotone.fa-circle-1:after {
  content: "\10e0ee";
}

.fad.fa-circle-2:after,
.fa-duotone.fa-circle-2:after {
  content: "\10e0ef";
}

.fad.fa-circle-3:after,
.fa-duotone.fa-circle-3:after {
  content: "\10e0f0";
}

.fad.fa-circle-4:after,
.fa-duotone.fa-circle-4:after {
  content: "\10e0f1";
}

.fad.fa-circle-5:after,
.fa-duotone.fa-circle-5:after {
  content: "\10e0f2";
}

.fad.fa-circle-6:after,
.fa-duotone.fa-circle-6:after {
  content: "\10e0f3";
}

.fad.fa-circle-7:after,
.fa-duotone.fa-circle-7:after {
  content: "\10e0f4";
}

.fad.fa-circle-8:after,
.fa-duotone.fa-circle-8:after {
  content: "\10e0f5";
}

.fad.fa-circle-9:after,
.fa-duotone.fa-circle-9:after {
  content: "\10e0f6";
}

.fad.fa-circle-a:after,
.fa-duotone.fa-circle-a:after {
  content: "\10e0f7";
}

.fad.fa-circle-ampersand:after,
.fa-duotone.fa-circle-ampersand:after {
  content: "\10e0f8";
}

.fad.fa-circle-arrow-down:after,
.fa-duotone.fa-circle-arrow-down:after {
  content: "\10f0ab";
}

.fad.fa-arrow-circle-down:after,
.fa-duotone.fa-arrow-circle-down:after {
  content: "\10f0ab";
}

.fad.fa-circle-arrow-down-left:after,
.fa-duotone.fa-circle-arrow-down-left:after {
  content: "\10e0f9";
}

.fad.fa-circle-arrow-down-right:after,
.fa-duotone.fa-circle-arrow-down-right:after {
  content: "\10e0fa";
}

.fad.fa-circle-arrow-left:after,
.fa-duotone.fa-circle-arrow-left:after {
  content: "\10f0a8";
}

.fad.fa-arrow-circle-left:after,
.fa-duotone.fa-arrow-circle-left:after {
  content: "\10f0a8";
}

.fad.fa-circle-arrow-right:after,
.fa-duotone.fa-circle-arrow-right:after {
  content: "\10f0a9";
}

.fad.fa-arrow-circle-right:after,
.fa-duotone.fa-arrow-circle-right:after {
  content: "\10f0a9";
}

.fad.fa-circle-arrow-up:after,
.fa-duotone.fa-circle-arrow-up:after {
  content: "\10f0aa";
}

.fad.fa-arrow-circle-up:after,
.fa-duotone.fa-arrow-circle-up:after {
  content: "\10f0aa";
}

.fad.fa-circle-arrow-up-left:after,
.fa-duotone.fa-circle-arrow-up-left:after {
  content: "\10e0fb";
}

.fad.fa-circle-arrow-up-right:after,
.fa-duotone.fa-circle-arrow-up-right:after {
  content: "\10e0fc";
}

.fad.fa-circle-b:after,
.fa-duotone.fa-circle-b:after {
  content: "\10e0fd";
}

.fad.fa-circle-bolt:after,
.fa-duotone.fa-circle-bolt:after {
  content: "\10e0fe";
}

.fad.fa-circle-book-open:after,
.fa-duotone.fa-circle-book-open:after {
  content: "\10e0ff";
}

.fad.fa-book-circle:after,
.fa-duotone.fa-book-circle:after {
  content: "\10e0ff";
}

.fad.fa-circle-bookmark:after,
.fa-duotone.fa-circle-bookmark:after {
  content: "\10e100";
}

.fad.fa-bookmark-circle:after,
.fa-duotone.fa-bookmark-circle:after {
  content: "\10e100";
}

.fad.fa-circle-c:after,
.fa-duotone.fa-circle-c:after {
  content: "\10e101";
}

.fad.fa-circle-calendar:after,
.fa-duotone.fa-circle-calendar:after {
  content: "\10e102";
}

.fad.fa-calendar-circle:after,
.fa-duotone.fa-calendar-circle:after {
  content: "\10e102";
}

.fad.fa-circle-camera:after,
.fa-duotone.fa-circle-camera:after {
  content: "\10e103";
}

.fad.fa-camera-circle:after,
.fa-duotone.fa-camera-circle:after {
  content: "\10e103";
}

.fad.fa-circle-caret-down:after,
.fa-duotone.fa-circle-caret-down:after {
  content: "\10f32d";
}

.fad.fa-caret-circle-down:after,
.fa-duotone.fa-caret-circle-down:after {
  content: "\10f32d";
}

.fad.fa-circle-caret-left:after,
.fa-duotone.fa-circle-caret-left:after {
  content: "\10f32e";
}

.fad.fa-caret-circle-left:after,
.fa-duotone.fa-caret-circle-left:after {
  content: "\10f32e";
}

.fad.fa-circle-caret-right:after,
.fa-duotone.fa-circle-caret-right:after {
  content: "\10f330";
}

.fad.fa-caret-circle-right:after,
.fa-duotone.fa-caret-circle-right:after {
  content: "\10f330";
}

.fad.fa-circle-caret-up:after,
.fa-duotone.fa-circle-caret-up:after {
  content: "\10f331";
}

.fad.fa-caret-circle-up:after,
.fa-duotone.fa-caret-circle-up:after {
  content: "\10f331";
}

.fad.fa-circle-check:after,
.fa-duotone.fa-circle-check:after {
  content: "\10f058";
}

.fad.fa-check-circle:after,
.fa-duotone.fa-check-circle:after {
  content: "\10f058";
}

.fad.fa-circle-chevron-down:after,
.fa-duotone.fa-circle-chevron-down:after {
  content: "\10f13a";
}

.fad.fa-chevron-circle-down:after,
.fa-duotone.fa-chevron-circle-down:after {
  content: "\10f13a";
}

.fad.fa-circle-chevron-left:after,
.fa-duotone.fa-circle-chevron-left:after {
  content: "\10f137";
}

.fad.fa-chevron-circle-left:after,
.fa-duotone.fa-chevron-circle-left:after {
  content: "\10f137";
}

.fad.fa-circle-chevron-right:after,
.fa-duotone.fa-circle-chevron-right:after {
  content: "\10f138";
}

.fad.fa-chevron-circle-right:after,
.fa-duotone.fa-chevron-circle-right:after {
  content: "\10f138";
}

.fad.fa-circle-chevron-up:after,
.fa-duotone.fa-circle-chevron-up:after {
  content: "\10f139";
}

.fad.fa-chevron-circle-up:after,
.fa-duotone.fa-chevron-circle-up:after {
  content: "\10f139";
}

.fad.fa-circle-d:after,
.fa-duotone.fa-circle-d:after {
  content: "\10e104";
}

.fad.fa-circle-dashed:after,
.fa-duotone.fa-circle-dashed:after {
  content: "\10e105";
}

.fad.fa-circle-divide:after,
.fa-duotone.fa-circle-divide:after {
  content: "\10e106";
}

.fad.fa-circle-dollar:after,
.fa-duotone.fa-circle-dollar:after {
  content: "\10f2e8";
}

.fad.fa-dollar-circle:after,
.fa-duotone.fa-dollar-circle:after {
  content: "\10f2e8";
}

.fad.fa-usd-circle:after,
.fa-duotone.fa-usd-circle:after {
  content: "\10f2e8";
}

.fad.fa-circle-dollar-to-slot:after,
.fa-duotone.fa-circle-dollar-to-slot:after {
  content: "\10f4b9";
}

.fad.fa-donate:after,
.fa-duotone.fa-donate:after {
  content: "\10f4b9";
}

.fad.fa-circle-dot:after,
.fa-duotone.fa-circle-dot:after {
  content: "\10f192";
}

.fad.fa-dot-circle:after,
.fa-duotone.fa-dot-circle:after {
  content: "\10f192";
}

.fad.fa-circle-down:after,
.fa-duotone.fa-circle-down:after {
  content: "\10f358";
}

.fad.fa-arrow-alt-circle-down:after,
.fa-duotone.fa-arrow-alt-circle-down:after {
  content: "\10f358";
}

.fad.fa-circle-down-left:after,
.fa-duotone.fa-circle-down-left:after {
  content: "\10e107";
}

.fad.fa-circle-down-right:after,
.fa-duotone.fa-circle-down-right:after {
  content: "\10e108";
}

.fad.fa-circle-e:after,
.fa-duotone.fa-circle-e:after {
  content: "\10e109";
}

.fad.fa-circle-ellipsis:after,
.fa-duotone.fa-circle-ellipsis:after {
  content: "\10e10a";
}

.fad.fa-circle-ellipsis-vertical:after,
.fa-duotone.fa-circle-ellipsis-vertical:after {
  content: "\10e10b";
}

.fad.fa-circle-envelope:after,
.fa-duotone.fa-circle-envelope:after {
  content: "\10e10c";
}

.fad.fa-envelope-circle:after,
.fa-duotone.fa-envelope-circle:after {
  content: "\10e10c";
}

.fad.fa-circle-exclamation:after,
.fa-duotone.fa-circle-exclamation:after {
  content: "\10f06a";
}

.fad.fa-exclamation-circle:after,
.fa-duotone.fa-exclamation-circle:after {
  content: "\10f06a";
}

.fad.fa-circle-exclamation-check:after,
.fa-duotone.fa-circle-exclamation-check:after {
  content: "\10e10d";
}

.fad.fa-circle-f:after,
.fa-duotone.fa-circle-f:after {
  content: "\10e10e";
}

.fad.fa-circle-g:after,
.fa-duotone.fa-circle-g:after {
  content: "\10e10f";
}

.fad.fa-circle-h:after,
.fa-duotone.fa-circle-h:after {
  content: "\10f47e";
}

.fad.fa-hospital-symbol:after,
.fa-duotone.fa-hospital-symbol:after {
  content: "\10f47e";
}

.fad.fa-circle-half:after,
.fa-duotone.fa-circle-half:after {
  content: "\10e110";
}

.fad.fa-circle-half-stroke:after,
.fa-duotone.fa-circle-half-stroke:after {
  content: "\10f042";
}

.fad.fa-adjust:after,
.fa-duotone.fa-adjust:after {
  content: "\10f042";
}

.fad.fa-circle-heart:after,
.fa-duotone.fa-circle-heart:after {
  content: "\10f4c7";
}

.fad.fa-heart-circle:after,
.fa-duotone.fa-heart-circle:after {
  content: "\10f4c7";
}

.fad.fa-circle-i:after,
.fa-duotone.fa-circle-i:after {
  content: "\10e111";
}

.fad.fa-circle-info:after,
.fa-duotone.fa-circle-info:after {
  content: "\10f05a";
}

.fad.fa-info-circle:after,
.fa-duotone.fa-info-circle:after {
  content: "\10f05a";
}

.fad.fa-circle-j:after,
.fa-duotone.fa-circle-j:after {
  content: "\10e112";
}

.fad.fa-circle-k:after,
.fa-duotone.fa-circle-k:after {
  content: "\10e113";
}

.fad.fa-circle-l:after,
.fa-duotone.fa-circle-l:after {
  content: "\10e114";
}

.fad.fa-circle-left:after,
.fa-duotone.fa-circle-left:after {
  content: "\10f359";
}

.fad.fa-arrow-alt-circle-left:after,
.fa-duotone.fa-arrow-alt-circle-left:after {
  content: "\10f359";
}

.fad.fa-circle-location-arrow:after,
.fa-duotone.fa-circle-location-arrow:after {
  content: "\10f602";
}

.fad.fa-location-circle:after,
.fa-duotone.fa-location-circle:after {
  content: "\10f602";
}

.fad.fa-circle-m:after,
.fa-duotone.fa-circle-m:after {
  content: "\10e115";
}

.fad.fa-circle-microphone:after,
.fa-duotone.fa-circle-microphone:after {
  content: "\10e116";
}

.fad.fa-microphone-circle:after,
.fa-duotone.fa-microphone-circle:after {
  content: "\10e116";
}

.fad.fa-circle-microphone-lines:after,
.fa-duotone.fa-circle-microphone-lines:after {
  content: "\10e117";
}

.fad.fa-microphone-circle-alt:after,
.fa-duotone.fa-microphone-circle-alt:after {
  content: "\10e117";
}

.fad.fa-circle-minus:after,
.fa-duotone.fa-circle-minus:after {
  content: "\10f056";
}

.fad.fa-minus-circle:after,
.fa-duotone.fa-minus-circle:after {
  content: "\10f056";
}

.fad.fa-circle-n:after,
.fa-duotone.fa-circle-n:after {
  content: "\10e118";
}

.fad.fa-circle-notch:after,
.fa-duotone.fa-circle-notch:after {
  content: "\10f1ce";
}

.fad.fa-circle-o:after,
.fa-duotone.fa-circle-o:after {
  content: "\10e119";
}

.fad.fa-circle-p:after,
.fa-duotone.fa-circle-p:after {
  content: "\10e11a";
}

.fad.fa-circle-parking:after,
.fa-duotone.fa-circle-parking:after {
  content: "\10f615";
}

.fad.fa-parking-circle:after,
.fa-duotone.fa-parking-circle:after {
  content: "\10f615";
}

.fad.fa-circle-pause:after,
.fa-duotone.fa-circle-pause:after {
  content: "\10f28b";
}

.fad.fa-pause-circle:after,
.fa-duotone.fa-pause-circle:after {
  content: "\10f28b";
}

.fad.fa-circle-phone:after,
.fa-duotone.fa-circle-phone:after {
  content: "\10e11b";
}

.fad.fa-phone-circle:after,
.fa-duotone.fa-phone-circle:after {
  content: "\10e11b";
}

.fad.fa-circle-phone-flip:after,
.fa-duotone.fa-circle-phone-flip:after {
  content: "\10e11c";
}

.fad.fa-phone-circle-alt:after,
.fa-duotone.fa-phone-circle-alt:after {
  content: "\10e11c";
}

.fad.fa-circle-phone-hangup:after,
.fa-duotone.fa-circle-phone-hangup:after {
  content: "\10e11d";
}

.fad.fa-phone-circle-down:after,
.fa-duotone.fa-phone-circle-down:after {
  content: "\10e11d";
}

.fad.fa-circle-play:after,
.fa-duotone.fa-circle-play:after {
  content: "\10f144";
}

.fad.fa-play-circle:after,
.fa-duotone.fa-play-circle:after {
  content: "\10f144";
}

.fad.fa-circle-plus:after,
.fa-duotone.fa-circle-plus:after {
  content: "\10f055";
}

.fad.fa-plus-circle:after,
.fa-duotone.fa-plus-circle:after {
  content: "\10f055";
}

.fad.fa-circle-q:after,
.fa-duotone.fa-circle-q:after {
  content: "\10e11e";
}

.fad.fa-circle-quarter:after,
.fa-duotone.fa-circle-quarter:after {
  content: "\10e11f";
}

.fad.fa-circle-question:after,
.fa-duotone.fa-circle-question:after {
  content: "\10f059";
}

.fad.fa-question-circle:after,
.fa-duotone.fa-question-circle:after {
  content: "\10f059";
}

.fad.fa-circle-r:after,
.fa-duotone.fa-circle-r:after {
  content: "\10e120";
}

.fad.fa-circle-radiation:after,
.fa-duotone.fa-circle-radiation:after {
  content: "\10f7ba";
}

.fad.fa-radiation-alt:after,
.fa-duotone.fa-radiation-alt:after {
  content: "\10f7ba";
}

.fad.fa-circle-right:after,
.fa-duotone.fa-circle-right:after {
  content: "\10f35a";
}

.fad.fa-arrow-alt-circle-right:after,
.fa-duotone.fa-arrow-alt-circle-right:after {
  content: "\10f35a";
}

.fad.fa-circle-s:after,
.fa-duotone.fa-circle-s:after {
  content: "\10e121";
}

.fad.fa-circle-small:after,
.fa-duotone.fa-circle-small:after {
  content: "\10e122";
}

.fad.fa-circle-sort:after,
.fa-duotone.fa-circle-sort:after {
  content: "\10e030";
}

.fad.fa-sort-circle:after,
.fa-duotone.fa-sort-circle:after {
  content: "\10e030";
}

.fad.fa-circle-sort-down:after,
.fa-duotone.fa-circle-sort-down:after {
  content: "\10e031";
}

.fad.fa-sort-circle-down:after,
.fa-duotone.fa-sort-circle-down:after {
  content: "\10e031";
}

.fad.fa-circle-sort-up:after,
.fa-duotone.fa-circle-sort-up:after {
  content: "\10e032";
}

.fad.fa-sort-circle-up:after,
.fa-duotone.fa-sort-circle-up:after {
  content: "\10e032";
}

.fad.fa-circle-star:after,
.fa-duotone.fa-circle-star:after {
  content: "\10e123";
}

.fad.fa-star-circle:after,
.fa-duotone.fa-star-circle:after {
  content: "\10e123";
}

.fad.fa-circle-stop:after,
.fa-duotone.fa-circle-stop:after {
  content: "\10f28d";
}

.fad.fa-stop-circle:after,
.fa-duotone.fa-stop-circle:after {
  content: "\10f28d";
}

.fad.fa-circle-t:after,
.fa-duotone.fa-circle-t:after {
  content: "\10e124";
}

.fad.fa-circle-three-quarters:after,
.fa-duotone.fa-circle-three-quarters:after {
  content: "\10e125";
}

.fad.fa-circle-trash:after,
.fa-duotone.fa-circle-trash:after {
  content: "\10e126";
}

.fad.fa-trash-circle:after,
.fa-duotone.fa-trash-circle:after {
  content: "\10e126";
}

.fad.fa-circle-u:after,
.fa-duotone.fa-circle-u:after {
  content: "\10e127";
}

.fad.fa-circle-up:after,
.fa-duotone.fa-circle-up:after {
  content: "\10f35b";
}

.fad.fa-arrow-alt-circle-up:after,
.fa-duotone.fa-arrow-alt-circle-up:after {
  content: "\10f35b";
}

.fad.fa-circle-up-left:after,
.fa-duotone.fa-circle-up-left:after {
  content: "\10e128";
}

.fad.fa-circle-up-right:after,
.fa-duotone.fa-circle-up-right:after {
  content: "\10e129";
}

.fad.fa-circle-user:after,
.fa-duotone.fa-circle-user:after {
  content: "\10f2bd";
}

.fad.fa-user-circle:after,
.fa-duotone.fa-user-circle:after {
  content: "\10f2bd";
}

.fad.fa-circle-v:after,
.fa-duotone.fa-circle-v:after {
  content: "\10e12a";
}

.fad.fa-circle-video:after,
.fa-duotone.fa-circle-video:after {
  content: "\10e12b";
}

.fad.fa-video-circle:after,
.fa-duotone.fa-video-circle:after {
  content: "\10e12b";
}

.fad.fa-circle-w:after,
.fa-duotone.fa-circle-w:after {
  content: "\10e12c";
}

.fad.fa-circle-waveform-lines:after,
.fa-duotone.fa-circle-waveform-lines:after {
  content: "\10e12d";
}

.fad.fa-waveform-circle:after,
.fa-duotone.fa-waveform-circle:after {
  content: "\10e12d";
}

.fad.fa-circle-x:after,
.fa-duotone.fa-circle-x:after {
  content: "\10e12e";
}

.fad.fa-circle-xmark:after,
.fa-duotone.fa-circle-xmark:after {
  content: "\10f057";
}

.fad.fa-times-circle:after,
.fa-duotone.fa-times-circle:after {
  content: "\10f057";
}

.fad.fa-xmark-circle:after,
.fa-duotone.fa-xmark-circle:after {
  content: "\10f057";
}

.fad.fa-circle-y:after,
.fa-duotone.fa-circle-y:after {
  content: "\10e12f";
}

.fad.fa-circle-z:after,
.fa-duotone.fa-circle-z:after {
  content: "\10e130";
}

.fad.fa-citrus:after,
.fa-duotone.fa-citrus:after {
  content: "\10e2f4";
}

.fad.fa-citrus-slice:after,
.fa-duotone.fa-citrus-slice:after {
  content: "\10e2f5";
}

.fad.fa-city:after,
.fa-duotone.fa-city:after {
  content: "\10f64f";
}

.fad.fa-clapperboard:after,
.fa-duotone.fa-clapperboard:after {
  content: "\10e131";
}

.fad.fa-clapperboard-play:after,
.fa-duotone.fa-clapperboard-play:after {
  content: "\10e132";
}

.fad.fa-clarinet:after,
.fa-duotone.fa-clarinet:after {
  content: "\10f8ad";
}

.fad.fa-claw-marks:after,
.fa-duotone.fa-claw-marks:after {
  content: "\10f6c2";
}

.fad.fa-clipboard:after,
.fa-duotone.fa-clipboard:after {
  content: "\10f328";
}

.fad.fa-clipboard-check:after,
.fa-duotone.fa-clipboard-check:after {
  content: "\10f46c";
}

.fad.fa-clipboard-list:after,
.fa-duotone.fa-clipboard-list:after {
  content: "\10f46d";
}

.fad.fa-clipboard-list-check:after,
.fa-duotone.fa-clipboard-list-check:after {
  content: "\10f737";
}

.fad.fa-clipboard-medical:after,
.fa-duotone.fa-clipboard-medical:after {
  content: "\10e133";
}

.fad.fa-clipboard-prescription:after,
.fa-duotone.fa-clipboard-prescription:after {
  content: "\10f5e8";
}

.fad.fa-clipboard-user:after,
.fa-duotone.fa-clipboard-user:after {
  content: "\10f7f3";
}

.fad.fa-clock:after,
.fa-duotone.fa-clock:after {
  content: "\10f017";
}

.fad.fa-clock-desk:after,
.fa-duotone.fa-clock-desk:after {
  content: "\10e134";
}

.fad.fa-clock-rotate-left:after,
.fa-duotone.fa-clock-rotate-left:after {
  content: "\10f1da";
}

.fad.fa-history:after,
.fa-duotone.fa-history:after {
  content: "\10f1da";
}

.fad.fa-clone:after,
.fa-duotone.fa-clone:after {
  content: "\10f24d";
}

.fad.fa-closed-captioning:after,
.fa-duotone.fa-closed-captioning:after {
  content: "\10f20a";
}

.fad.fa-closed-captioning-slash:after,
.fa-duotone.fa-closed-captioning-slash:after {
  content: "\10e135";
}

.fad.fa-clothes-hanger:after,
.fa-duotone.fa-clothes-hanger:after {
  content: "\10e136";
}

.fad.fa-cloud:after,
.fa-duotone.fa-cloud:after {
  content: "\10f0c2";
}

.fad.fa-cloud-arrow-down:after,
.fa-duotone.fa-cloud-arrow-down:after {
  content: "\10f0ed";
}

.fad.fa-cloud-download:after,
.fa-duotone.fa-cloud-download:after {
  content: "\10f0ed";
}

.fad.fa-cloud-download-alt:after,
.fa-duotone.fa-cloud-download-alt:after {
  content: "\10f0ed";
}

.fad.fa-cloud-arrow-up:after,
.fa-duotone.fa-cloud-arrow-up:after {
  content: "\10f0ee";
}

.fad.fa-cloud-upload:after,
.fa-duotone.fa-cloud-upload:after {
  content: "\10f0ee";
}

.fad.fa-cloud-upload-alt:after,
.fa-duotone.fa-cloud-upload-alt:after {
  content: "\10f0ee";
}

.fad.fa-cloud-bolt:after,
.fa-duotone.fa-cloud-bolt:after {
  content: "\10f76c";
}

.fad.fa-thunderstorm:after,
.fa-duotone.fa-thunderstorm:after {
  content: "\10f76c";
}

.fad.fa-cloud-bolt-moon:after,
.fa-duotone.fa-cloud-bolt-moon:after {
  content: "\10f76d";
}

.fad.fa-thunderstorm-moon:after,
.fa-duotone.fa-thunderstorm-moon:after {
  content: "\10f76d";
}

.fad.fa-cloud-bolt-sun:after,
.fa-duotone.fa-cloud-bolt-sun:after {
  content: "\10f76e";
}

.fad.fa-thunderstorm-sun:after,
.fa-duotone.fa-thunderstorm-sun:after {
  content: "\10f76e";
}

.fad.fa-cloud-drizzle:after,
.fa-duotone.fa-cloud-drizzle:after {
  content: "\10f738";
}

.fad.fa-cloud-fog:after,
.fa-duotone.fa-cloud-fog:after {
  content: "\10f74e";
}

.fad.fa-fog:after,
.fa-duotone.fa-fog:after {
  content: "\10f74e";
}

.fad.fa-cloud-hail:after,
.fa-duotone.fa-cloud-hail:after {
  content: "\10f739";
}

.fad.fa-cloud-hail-mixed:after,
.fa-duotone.fa-cloud-hail-mixed:after {
  content: "\10f73a";
}

.fad.fa-cloud-meatball:after,
.fa-duotone.fa-cloud-meatball:after {
  content: "\10f73b";
}

.fad.fa-cloud-moon:after,
.fa-duotone.fa-cloud-moon:after {
  content: "\10f6c3";
}

.fad.fa-cloud-moon-rain:after,
.fa-duotone.fa-cloud-moon-rain:after {
  content: "\10f73c";
}

.fad.fa-cloud-music:after,
.fa-duotone.fa-cloud-music:after {
  content: "\10f8ae";
}

.fad.fa-cloud-rain:after,
.fa-duotone.fa-cloud-rain:after {
  content: "\10f73d";
}

.fad.fa-cloud-rainbow:after,
.fa-duotone.fa-cloud-rainbow:after {
  content: "\10f73e";
}

.fad.fa-cloud-showers:after,
.fa-duotone.fa-cloud-showers:after {
  content: "\10f73f";
}

.fad.fa-cloud-showers-heavy:after,
.fa-duotone.fa-cloud-showers-heavy:after {
  content: "\10f740";
}

.fad.fa-cloud-slash:after,
.fa-duotone.fa-cloud-slash:after {
  content: "\10e137";
}

.fad.fa-cloud-sleet:after,
.fa-duotone.fa-cloud-sleet:after {
  content: "\10f741";
}

.fad.fa-cloud-snow:after,
.fa-duotone.fa-cloud-snow:after {
  content: "\10f742";
}

.fad.fa-cloud-sun:after,
.fa-duotone.fa-cloud-sun:after {
  content: "\10f6c4";
}

.fad.fa-cloud-sun-rain:after,
.fa-duotone.fa-cloud-sun-rain:after {
  content: "\10f743";
}

.fad.fa-cloud-word:after,
.fa-duotone.fa-cloud-word:after {
  content: "\10e138";
}

.fad.fa-clouds:after,
.fa-duotone.fa-clouds:after {
  content: "\10f744";
}

.fad.fa-clouds-moon:after,
.fa-duotone.fa-clouds-moon:after {
  content: "\10f745";
}

.fad.fa-clouds-sun:after,
.fa-duotone.fa-clouds-sun:after {
  content: "\10f746";
}

.fad.fa-clover:after,
.fa-duotone.fa-clover:after {
  content: "\10e139";
}

.fad.fa-club:after,
.fa-duotone.fa-club:after {
  content: "\10f327";
}

.fad.fa-coconut:after,
.fa-duotone.fa-coconut:after {
  content: "\10e2f6";
}

.fad.fa-code:after,
.fa-duotone.fa-code:after {
  content: "\10f121";
}

.fad.fa-code-branch:after,
.fa-duotone.fa-code-branch:after {
  content: "\10f126";
}

.fad.fa-code-commit:after,
.fa-duotone.fa-code-commit:after {
  content: "\10f386";
}

.fad.fa-code-compare:after,
.fa-duotone.fa-code-compare:after {
  content: "\10e13a";
}

.fad.fa-code-fork:after,
.fa-duotone.fa-code-fork:after {
  content: "\10e13b";
}

.fad.fa-code-merge:after,
.fa-duotone.fa-code-merge:after {
  content: "\10f387";
}

.fad.fa-code-pull-request:after,
.fa-duotone.fa-code-pull-request:after {
  content: "\10e13c";
}

.fad.fa-code-simple:after,
.fa-duotone.fa-code-simple:after {
  content: "\10e13d";
}

.fad.fa-coffee-bean:after,
.fa-duotone.fa-coffee-bean:after {
  content: "\10e13e";
}

.fad.fa-coffee-beans:after,
.fa-duotone.fa-coffee-beans:after {
  content: "\10e13f";
}

.fad.fa-coffee-pot:after,
.fa-duotone.fa-coffee-pot:after {
  content: "\10e002";
}

.fad.fa-coffin:after,
.fa-duotone.fa-coffin:after {
  content: "\10f6c6";
}

.fad.fa-coffin-cross:after,
.fa-duotone.fa-coffin-cross:after {
  content: "\10e051";
}

.fad.fa-coin:after,
.fa-duotone.fa-coin:after {
  content: "\10f85c";
}

.fad.fa-coins:after,
.fa-duotone.fa-coins:after {
  content: "\10f51e";
}

.fad.fa-colon:after,
.fa-duotone.fa-colon:after {
  content: "\10e2f7";
}

.fad.fa-colon-sign:after,
.fa-duotone.fa-colon-sign:after {
  content: "\10e140";
}

.fad.fa-comet:after,
.fa-duotone.fa-comet:after {
  content: "\10e003";
}

.fad.fa-comma:after,
.fa-duotone.fa-comma:after {
  content: "\10e141";
}

.fad.fa-command:after,
.fa-duotone.fa-command:after {
  content: "\10e142";
}

.fad.fa-comment:after,
.fa-duotone.fa-comment:after {
  content: "\10f075";
}

.fad.fa-comment-arrow-down:after,
.fa-duotone.fa-comment-arrow-down:after {
  content: "\10e143";
}

.fad.fa-comment-arrow-up:after,
.fa-duotone.fa-comment-arrow-up:after {
  content: "\10e144";
}

.fad.fa-comment-arrow-up-right:after,
.fa-duotone.fa-comment-arrow-up-right:after {
  content: "\10e145";
}

.fad.fa-comment-captions:after,
.fa-duotone.fa-comment-captions:after {
  content: "\10e146";
}

.fad.fa-comment-check:after,
.fa-duotone.fa-comment-check:after {
  content: "\10f4ac";
}

.fad.fa-comment-code:after,
.fa-duotone.fa-comment-code:after {
  content: "\10e147";
}

.fad.fa-comment-dollar:after,
.fa-duotone.fa-comment-dollar:after {
  content: "\10f651";
}

.fad.fa-comment-dots:after,
.fa-duotone.fa-comment-dots:after {
  content: "\10f4ad";
}

.fad.fa-commenting:after,
.fa-duotone.fa-commenting:after {
  content: "\10f4ad";
}

.fad.fa-comment-exclamation:after,
.fa-duotone.fa-comment-exclamation:after {
  content: "\10f4af";
}

.fad.fa-comment-image:after,
.fa-duotone.fa-comment-image:after {
  content: "\10e148";
}

.fad.fa-comment-lines:after,
.fa-duotone.fa-comment-lines:after {
  content: "\10f4b0";
}

.fad.fa-comment-medical:after,
.fa-duotone.fa-comment-medical:after {
  content: "\10f7f5";
}

.fad.fa-comment-middle:after,
.fa-duotone.fa-comment-middle:after {
  content: "\10e149";
}

.fad.fa-comment-middle-top:after,
.fa-duotone.fa-comment-middle-top:after {
  content: "\10e14a";
}

.fad.fa-comment-minus:after,
.fa-duotone.fa-comment-minus:after {
  content: "\10f4b1";
}

.fad.fa-comment-music:after,
.fa-duotone.fa-comment-music:after {
  content: "\10f8b0";
}

.fad.fa-comment-pen:after,
.fa-duotone.fa-comment-pen:after {
  content: "\10f4ae";
}

.fad.fa-comment-edit:after,
.fa-duotone.fa-comment-edit:after {
  content: "\10f4ae";
}

.fad.fa-comment-plus:after,
.fa-duotone.fa-comment-plus:after {
  content: "\10f4b2";
}

.fad.fa-comment-question:after,
.fa-duotone.fa-comment-question:after {
  content: "\10e14b";
}

.fad.fa-comment-quote:after,
.fa-duotone.fa-comment-quote:after {
  content: "\10e14c";
}

.fad.fa-comment-slash:after,
.fa-duotone.fa-comment-slash:after {
  content: "\10f4b3";
}

.fad.fa-comment-smile:after,
.fa-duotone.fa-comment-smile:after {
  content: "\10f4b4";
}

.fad.fa-comment-sms:after,
.fa-duotone.fa-comment-sms:after {
  content: "\10f7cd";
}

.fad.fa-sms:after,
.fa-duotone.fa-sms:after {
  content: "\10f7cd";
}

.fad.fa-comment-text:after,
.fa-duotone.fa-comment-text:after {
  content: "\10e14d";
}

.fad.fa-comment-xmark:after,
.fa-duotone.fa-comment-xmark:after {
  content: "\10f4b5";
}

.fad.fa-comment-times:after,
.fa-duotone.fa-comment-times:after {
  content: "\10f4b5";
}

.fad.fa-comments:after,
.fa-duotone.fa-comments:after {
  content: "\10f086";
}

.fad.fa-comments-dollar:after,
.fa-duotone.fa-comments-dollar:after {
  content: "\10f653";
}

.fad.fa-comments-question:after,
.fa-duotone.fa-comments-question:after {
  content: "\10e14e";
}

.fad.fa-comments-question-check:after,
.fa-duotone.fa-comments-question-check:after {
  content: "\10e14f";
}

.fad.fa-compact-disc:after,
.fa-duotone.fa-compact-disc:after {
  content: "\10f51f";
}

.fad.fa-compass:after,
.fa-duotone.fa-compass:after {
  content: "\10f14e";
}

.fad.fa-compass-drafting:after,
.fa-duotone.fa-compass-drafting:after {
  content: "\10f568";
}

.fad.fa-drafting-compass:after,
.fa-duotone.fa-drafting-compass:after {
  content: "\10f568";
}

.fad.fa-compass-slash:after,
.fa-duotone.fa-compass-slash:after {
  content: "\10f5e9";
}

.fad.fa-compress:after,
.fa-duotone.fa-compress:after {
  content: "\10f066";
}

.fad.fa-compress-wide:after,
.fa-duotone.fa-compress-wide:after {
  content: "\10f326";
}

.fad.fa-computer-classic:after,
.fa-duotone.fa-computer-classic:after {
  content: "\10f8b1";
}

.fad.fa-computer-mouse:after,
.fa-duotone.fa-computer-mouse:after {
  content: "\10f8cc";
}

.fad.fa-mouse:after,
.fa-duotone.fa-mouse:after {
  content: "\10f8cc";
}

.fad.fa-computer-mouse-scrollwheel:after,
.fa-duotone.fa-computer-mouse-scrollwheel:after {
  content: "\10f8cd";
}

.fad.fa-mouse-alt:after,
.fa-duotone.fa-mouse-alt:after {
  content: "\10f8cd";
}

.fad.fa-computer-speaker:after,
.fa-duotone.fa-computer-speaker:after {
  content: "\10f8b2";
}

.fad.fa-container-storage:after,
.fa-duotone.fa-container-storage:after {
  content: "\10f4b7";
}

.fad.fa-conveyor-belt:after,
.fa-duotone.fa-conveyor-belt:after {
  content: "\10f46e";
}

.fad.fa-conveyor-belt-boxes:after,
.fa-duotone.fa-conveyor-belt-boxes:after {
  content: "\10f46f";
}

.fad.fa-conveyor-belt-alt:after,
.fa-duotone.fa-conveyor-belt-alt:after {
  content: "\10f46f";
}

.fad.fa-conveyor-belt-empty:after,
.fa-duotone.fa-conveyor-belt-empty:after {
  content: "\10e150";
}

.fad.fa-cookie:after,
.fa-duotone.fa-cookie:after {
  content: "\10f563";
}

.fad.fa-cookie-bite:after,
.fa-duotone.fa-cookie-bite:after {
  content: "\10f564";
}

.fad.fa-copy:after,
.fa-duotone.fa-copy:after {
  content: "\10f0c5";
}

.fad.fa-copyright:after,
.fa-duotone.fa-copyright:after {
  content: "\10f1f9";
}

.fad.fa-corn:after,
.fa-duotone.fa-corn:after {
  content: "\10f6c7";
}

.fad.fa-corner:after,
.fa-duotone.fa-corner:after {
  content: "\10e2f8";
}

.fad.fa-couch:after,
.fa-duotone.fa-couch:after {
  content: "\10f4b8";
}

.fad.fa-cow:after,
.fa-duotone.fa-cow:after {
  content: "\10f6c8";
}

.fad.fa-cowbell:after,
.fa-duotone.fa-cowbell:after {
  content: "\10f8b3";
}

.fad.fa-cowbell-circle-plus:after,
.fa-duotone.fa-cowbell-circle-plus:after {
  content: "\10f8b4";
}

.fad.fa-cowbell-more:after,
.fa-duotone.fa-cowbell-more:after {
  content: "\10f8b4";
}

.fad.fa-crate-apple:after,
.fa-duotone.fa-crate-apple:after {
  content: "\10f6b1";
}

.fad.fa-apple-crate:after,
.fa-duotone.fa-apple-crate:after {
  content: "\10f6b1";
}

.fad.fa-crate-empty:after,
.fa-duotone.fa-crate-empty:after {
  content: "\10e151";
}

.fad.fa-credit-card:after,
.fa-duotone.fa-credit-card:after {
  content: "\10f09d";
}

.fad.fa-credit-card-alt:after,
.fa-duotone.fa-credit-card-alt:after {
  content: "\10f09d";
}

.fad.fa-credit-card-blank:after,
.fa-duotone.fa-credit-card-blank:after {
  content: "\10f389";
}

.fad.fa-credit-card-front:after,
.fa-duotone.fa-credit-card-front:after {
  content: "\10f38a";
}

.fad.fa-cricket-bat-ball:after,
.fa-duotone.fa-cricket-bat-ball:after {
  content: "\10f449";
}

.fad.fa-cricket:after,
.fa-duotone.fa-cricket:after {
  content: "\10f449";
}

.fad.fa-croissant:after,
.fa-duotone.fa-croissant:after {
  content: "\10f7f6";
}

.fad.fa-crop:after,
.fa-duotone.fa-crop:after {
  content: "\10f125";
}

.fad.fa-crop-simple:after,
.fa-duotone.fa-crop-simple:after {
  content: "\10f565";
}

.fad.fa-crop-alt:after,
.fa-duotone.fa-crop-alt:after {
  content: "\10f565";
}

.fad.fa-cross:after,
.fa-duotone.fa-cross:after {
  content: "\10f654";
}

.fad.fa-crosshairs:after,
.fa-duotone.fa-crosshairs:after {
  content: "\10f05b";
}

.fad.fa-crow:after,
.fa-duotone.fa-crow:after {
  content: "\10f520";
}

.fad.fa-crown:after,
.fa-duotone.fa-crown:after {
  content: "\10f521";
}

.fad.fa-crutch:after,
.fa-duotone.fa-crutch:after {
  content: "\10f7f7";
}

.fad.fa-crutches:after,
.fa-duotone.fa-crutches:after {
  content: "\10f7f8";
}

.fad.fa-cruzeiro-sign:after,
.fa-duotone.fa-cruzeiro-sign:after {
  content: "\10e152";
}

.fad.fa-cube:after,
.fa-duotone.fa-cube:after {
  content: "\10f1b2";
}

.fad.fa-cubes:after,
.fa-duotone.fa-cubes:after {
  content: "\10f1b3";
}

.fad.fa-cup-togo:after,
.fa-duotone.fa-cup-togo:after {
  content: "\10f6c5";
}

.fad.fa-coffee-togo:after,
.fa-duotone.fa-coffee-togo:after {
  content: "\10f6c5";
}

.fad.fa-curling-stone:after,
.fa-duotone.fa-curling-stone:after {
  content: "\10f44a";
}

.fad.fa-curling:after,
.fa-duotone.fa-curling:after {
  content: "\10f44a";
}

.fad.fa-d:after,
.fa-duotone.fa-d:after {
  content: "\10e2f9";
}

.fad.fa-dagger:after,
.fa-duotone.fa-dagger:after {
  content: "\10f6cb";
}

.fad.fa-dash:after,
.fa-duotone.fa-dash:after {
  content: "\10e153";
}

.fad.fa-database:after,
.fa-duotone.fa-database:after {
  content: "\10f1c0";
}

.fad.fa-deer:after,
.fa-duotone.fa-deer:after {
  content: "\10f78e";
}

.fad.fa-deer-rudolph:after,
.fa-duotone.fa-deer-rudolph:after {
  content: "\10f78f";
}

.fad.fa-delete-left:after,
.fa-duotone.fa-delete-left:after {
  content: "\10f55a";
}

.fad.fa-backspace:after,
.fa-duotone.fa-backspace:after {
  content: "\10f55a";
}

.fad.fa-delete-right:after,
.fa-duotone.fa-delete-right:after {
  content: "\10e154";
}

.fad.fa-democrat:after,
.fa-duotone.fa-democrat:after {
  content: "\10f747";
}

.fad.fa-desktop:after,
.fa-duotone.fa-desktop:after {
  content: "\10f108";
}

.fad.fa-desktop-alt:after,
.fa-duotone.fa-desktop-alt:after {
  content: "\10f108";
}

.fad.fa-desktop-arrow-down:after,
.fa-duotone.fa-desktop-arrow-down:after {
  content: "\10e155";
}

.fad.fa-dharmachakra:after,
.fa-duotone.fa-dharmachakra:after {
  content: "\10f655";
}

.fad.fa-diagram-lean-canvas:after,
.fa-duotone.fa-diagram-lean-canvas:after {
  content: "\10e156";
}

.fad.fa-diagram-nested:after,
.fa-duotone.fa-diagram-nested:after {
  content: "\10e157";
}

.fad.fa-diagram-project:after,
.fa-duotone.fa-diagram-project:after {
  content: "\10f542";
}

.fad.fa-project-diagram:after,
.fa-duotone.fa-project-diagram:after {
  content: "\10f542";
}

.fad.fa-diagram-sankey:after,
.fa-duotone.fa-diagram-sankey:after {
  content: "\10e158";
}

.fad.fa-diagram-venn:after,
.fa-duotone.fa-diagram-venn:after {
  content: "\10e15a";
}

.fad.fa-dial:after,
.fa-duotone.fa-dial:after {
  content: "\10e15b";
}

.fad.fa-dial-med-high:after,
.fa-duotone.fa-dial-med-high:after {
  content: "\10e15b";
}

.fad.fa-dial-high:after,
.fa-duotone.fa-dial-high:after {
  content: "\10e15c";
}

.fad.fa-dial-low:after,
.fa-duotone.fa-dial-low:after {
  content: "\10e15d";
}

.fad.fa-dial-max:after,
.fa-duotone.fa-dial-max:after {
  content: "\10e15e";
}

.fad.fa-dial-med:after,
.fa-duotone.fa-dial-med:after {
  content: "\10e15f";
}

.fad.fa-dial-med-low:after,
.fa-duotone.fa-dial-med-low:after {
  content: "\10e160";
}

.fad.fa-dial-min:after,
.fa-duotone.fa-dial-min:after {
  content: "\10e161";
}

.fad.fa-dial-off:after,
.fa-duotone.fa-dial-off:after {
  content: "\10e162";
}

.fad.fa-diamond:after,
.fa-duotone.fa-diamond:after {
  content: "\10f219";
}

.fad.fa-diamond-turn-right:after,
.fa-duotone.fa-diamond-turn-right:after {
  content: "\10f5eb";
}

.fad.fa-directions:after,
.fa-duotone.fa-directions:after {
  content: "\10f5eb";
}

.fad.fa-dice:after,
.fa-duotone.fa-dice:after {
  content: "\10f522";
}

.fad.fa-dice-d10:after,
.fa-duotone.fa-dice-d10:after {
  content: "\10f6cd";
}

.fad.fa-dice-d12:after,
.fa-duotone.fa-dice-d12:after {
  content: "\10f6ce";
}

.fad.fa-dice-d20:after,
.fa-duotone.fa-dice-d20:after {
  content: "\10f6cf";
}

.fad.fa-dice-d4:after,
.fa-duotone.fa-dice-d4:after {
  content: "\10f6d0";
}

.fad.fa-dice-d6:after,
.fa-duotone.fa-dice-d6:after {
  content: "\10f6d1";
}

.fad.fa-dice-d8:after,
.fa-duotone.fa-dice-d8:after {
  content: "\10f6d2";
}

.fad.fa-dice-five:after,
.fa-duotone.fa-dice-five:after {
  content: "\10f523";
}

.fad.fa-dice-four:after,
.fa-duotone.fa-dice-four:after {
  content: "\10f524";
}

.fad.fa-dice-one:after,
.fa-duotone.fa-dice-one:after {
  content: "\10f525";
}

.fad.fa-dice-six:after,
.fa-duotone.fa-dice-six:after {
  content: "\10f526";
}

.fad.fa-dice-three:after,
.fa-duotone.fa-dice-three:after {
  content: "\10f527";
}

.fad.fa-dice-two:after,
.fa-duotone.fa-dice-two:after {
  content: "\10f528";
}

.fad.fa-diploma:after,
.fa-duotone.fa-diploma:after {
  content: "\10f5ea";
}

.fad.fa-scroll-ribbon:after,
.fa-duotone.fa-scroll-ribbon:after {
  content: "\10f5ea";
}

.fad.fa-disc-drive:after,
.fa-duotone.fa-disc-drive:after {
  content: "\10f8b5";
}

.fad.fa-disease:after,
.fa-duotone.fa-disease:after {
  content: "\10f7fa";
}

.fad.fa-display:after,
.fa-duotone.fa-display:after {
  content: "\10e163";
}

.fad.fa-display-arrow-down:after,
.fa-duotone.fa-display-arrow-down:after {
  content: "\10e164";
}

.fad.fa-display-code:after,
.fa-duotone.fa-display-code:after {
  content: "\10e165";
}

.fad.fa-desktop-code:after,
.fa-duotone.fa-desktop-code:after {
  content: "\10e165";
}

.fad.fa-display-medical:after,
.fa-duotone.fa-display-medical:after {
  content: "\10e166";
}

.fad.fa-desktop-medical:after,
.fa-duotone.fa-desktop-medical:after {
  content: "\10e166";
}

.fad.fa-display-slash:after,
.fa-duotone.fa-display-slash:after {
  content: "\10e2fa";
}

.fad.fa-desktop-slash:after,
.fa-duotone.fa-desktop-slash:after {
  content: "\10e2fa";
}

.fad.fa-ditto:after,
.fa-duotone.fa-ditto:after {
  content: "\10e2fb";
}

.fad.fa-divide:after,
.fa-duotone.fa-divide:after {
  content: "\10f529";
}

.fad.fa-dna:after,
.fa-duotone.fa-dna:after {
  content: "\10f471";
}

.fad.fa-do-not-enter:after,
.fa-duotone.fa-do-not-enter:after {
  content: "\10f5ec";
}

.fad.fa-dog:after,
.fa-duotone.fa-dog:after {
  content: "\10f6d3";
}

.fad.fa-dog-leashed:after,
.fa-duotone.fa-dog-leashed:after {
  content: "\10f6d4";
}

.fad.fa-dollar-sign:after,
.fa-duotone.fa-dollar-sign:after {
  content: "\10f155";
}

.fad.fa-dollar:after,
.fa-duotone.fa-dollar:after {
  content: "\10f155";
}

.fad.fa-usd:after,
.fa-duotone.fa-usd:after {
  content: "\10f155";
}

.fad.fa-dolly:after,
.fa-duotone.fa-dolly:after {
  content: "\10f472";
}

.fad.fa-dolly-box:after,
.fa-duotone.fa-dolly-box:after {
  content: "\10f472";
}

.fad.fa-dolly-empty:after,
.fa-duotone.fa-dolly-empty:after {
  content: "\10f473";
}

.fad.fa-dolphin:after,
.fa-duotone.fa-dolphin:after {
  content: "\10e168";
}

.fad.fa-dong-sign:after,
.fa-duotone.fa-dong-sign:after {
  content: "\10e169";
}

.fad.fa-door-closed:after,
.fa-duotone.fa-door-closed:after {
  content: "\10f52a";
}

.fad.fa-door-open:after,
.fa-duotone.fa-door-open:after {
  content: "\10f52b";
}

.fad.fa-dove:after,
.fa-duotone.fa-dove:after {
  content: "\10f4ba";
}

.fad.fa-down:after,
.fa-duotone.fa-down:after {
  content: "\10f354";
}

.fad.fa-arrow-alt-down:after,
.fa-duotone.fa-arrow-alt-down:after {
  content: "\10f354";
}

.fad.fa-down-from-line:after,
.fa-duotone.fa-down-from-line:after {
  content: "\10f349";
}

.fad.fa-arrow-alt-from-top:after,
.fa-duotone.fa-arrow-alt-from-top:after {
  content: "\10f349";
}

.fad.fa-down-left:after,
.fa-duotone.fa-down-left:after {
  content: "\10e16a";
}

.fad.fa-down-left-and-up-right-to-center:after,
.fa-duotone.fa-down-left-and-up-right-to-center:after {
  content: "\10f422";
}

.fad.fa-compress-alt:after,
.fa-duotone.fa-compress-alt:after {
  content: "\10f422";
}

.fad.fa-down-long:after,
.fa-duotone.fa-down-long:after {
  content: "\10f309";
}

.fad.fa-long-arrow-alt-down:after,
.fa-duotone.fa-long-arrow-alt-down:after {
  content: "\10f309";
}

.fad.fa-down-right:after,
.fa-duotone.fa-down-right:after {
  content: "\10e16b";
}

.fad.fa-down-to-line:after,
.fa-duotone.fa-down-to-line:after {
  content: "\10f34a";
}

.fad.fa-arrow-alt-to-bottom:after,
.fa-duotone.fa-arrow-alt-to-bottom:after {
  content: "\10f34a";
}

.fad.fa-download:after,
.fa-duotone.fa-download:after {
  content: "\10f019";
}

.fad.fa-dragon:after,
.fa-duotone.fa-dragon:after {
  content: "\10f6d5";
}

.fad.fa-draw-circle:after,
.fa-duotone.fa-draw-circle:after {
  content: "\10f5ed";
}

.fad.fa-draw-polygon:after,
.fa-duotone.fa-draw-polygon:after {
  content: "\10f5ee";
}

.fad.fa-draw-square:after,
.fa-duotone.fa-draw-square:after {
  content: "\10f5ef";
}

.fad.fa-dreidel:after,
.fa-duotone.fa-dreidel:after {
  content: "\10f792";
}

.fad.fa-drone:after,
.fa-duotone.fa-drone:after {
  content: "\10f85f";
}

.fad.fa-drone-front:after,
.fa-duotone.fa-drone-front:after {
  content: "\10f860";
}

.fad.fa-drone-alt:after,
.fa-duotone.fa-drone-alt:after {
  content: "\10f860";
}

.fad.fa-droplet:after,
.fa-duotone.fa-droplet:after {
  content: "\10f043";
}

.fad.fa-tint:after,
.fa-duotone.fa-tint:after {
  content: "\10f043";
}

.fad.fa-droplet-degree:after,
.fa-duotone.fa-droplet-degree:after {
  content: "\10f748";
}

.fad.fa-dewpoint:after,
.fa-duotone.fa-dewpoint:after {
  content: "\10f748";
}

.fad.fa-droplet-percent:after,
.fa-duotone.fa-droplet-percent:after {
  content: "\10f750";
}

.fad.fa-humidity:after,
.fa-duotone.fa-humidity:after {
  content: "\10f750";
}

.fad.fa-droplet-slash:after,
.fa-duotone.fa-droplet-slash:after {
  content: "\10f5c7";
}

.fad.fa-tint-slash:after,
.fa-duotone.fa-tint-slash:after {
  content: "\10f5c7";
}

.fad.fa-drum:after,
.fa-duotone.fa-drum:after {
  content: "\10f569";
}

.fad.fa-drum-steelpan:after,
.fa-duotone.fa-drum-steelpan:after {
  content: "\10f56a";
}

.fad.fa-drumstick:after,
.fa-duotone.fa-drumstick:after {
  content: "\10f6d6";
}

.fad.fa-drumstick-bite:after,
.fa-duotone.fa-drumstick-bite:after {
  content: "\10f6d7";
}

.fad.fa-dryer:after,
.fa-duotone.fa-dryer:after {
  content: "\10f861";
}

.fad.fa-dryer-heat:after,
.fa-duotone.fa-dryer-heat:after {
  content: "\10f862";
}

.fad.fa-dryer-alt:after,
.fa-duotone.fa-dryer-alt:after {
  content: "\10f862";
}

.fad.fa-duck:after,
.fa-duotone.fa-duck:after {
  content: "\10f6d8";
}

.fad.fa-dumbbell:after,
.fa-duotone.fa-dumbbell:after {
  content: "\10f44b";
}

.fad.fa-dumpster:after,
.fa-duotone.fa-dumpster:after {
  content: "\10f793";
}

.fad.fa-dumpster-fire:after,
.fa-duotone.fa-dumpster-fire:after {
  content: "\10f794";
}

.fad.fa-dungeon:after,
.fa-duotone.fa-dungeon:after {
  content: "\10f6d9";
}

.fad.fa-e:after,
.fa-duotone.fa-e:after {
  content: "\10e2fc";
}

.fad.fa-ear:after,
.fa-duotone.fa-ear:after {
  content: "\10f5f0";
}

.fad.fa-ear-deaf:after,
.fa-duotone.fa-ear-deaf:after {
  content: "\10f2a4";
}

.fad.fa-deaf:after,
.fa-duotone.fa-deaf:after {
  content: "\10f2a4";
}

.fad.fa-deafness:after,
.fa-duotone.fa-deafness:after {
  content: "\10f2a4";
}

.fad.fa-hard-of-hearing:after,
.fa-duotone.fa-hard-of-hearing:after {
  content: "\10f2a4";
}

.fad.fa-ear-listen:after,
.fa-duotone.fa-ear-listen:after {
  content: "\10f2a2";
}

.fad.fa-assistive-listening-systems:after,
.fa-duotone.fa-assistive-listening-systems:after {
  content: "\10f2a2";
}

.fad.fa-ear-muffs:after,
.fa-duotone.fa-ear-muffs:after {
  content: "\10f795";
}

.fad.fa-earth-africa:after,
.fa-duotone.fa-earth-africa:after {
  content: "\10f57c";
}

.fad.fa-globe-africa:after,
.fa-duotone.fa-globe-africa:after {
  content: "\10f57c";
}

.fad.fa-earth-americas:after,
.fa-duotone.fa-earth-americas:after {
  content: "\10f57d";
}

.fad.fa-earth:after,
.fa-duotone.fa-earth:after {
  content: "\10f57d";
}

.fad.fa-globe-americas:after,
.fa-duotone.fa-globe-americas:after {
  content: "\10f57d";
}

.fad.fa-earth-asia:after,
.fa-duotone.fa-earth-asia:after {
  content: "\10f57e";
}

.fad.fa-globe-asia:after,
.fa-duotone.fa-globe-asia:after {
  content: "\10f57e";
}

.fad.fa-earth-europa:after,
.fa-duotone.fa-earth-europa:after {
  content: "\10f7a2";
}

.fad.fa-globe-europe:after,
.fa-duotone.fa-globe-europe:after {
  content: "\10f7a2";
}

.fad.fa-eclipse:after,
.fa-duotone.fa-eclipse:after {
  content: "\10f749";
}

.fad.fa-egg:after,
.fa-duotone.fa-egg:after {
  content: "\10f7fb";
}

.fad.fa-egg-fried:after,
.fa-duotone.fa-egg-fried:after {
  content: "\10f7fc";
}

.fad.fa-eggplant:after,
.fa-duotone.fa-eggplant:after {
  content: "\10e16c";
}

.fad.fa-eject:after,
.fa-duotone.fa-eject:after {
  content: "\10f052";
}

.fad.fa-elephant:after,
.fa-duotone.fa-elephant:after {
  content: "\10f6da";
}

.fad.fa-elevator:after,
.fa-duotone.fa-elevator:after {
  content: "\10e16d";
}

.fad.fa-ellipsis:after,
.fa-duotone.fa-ellipsis:after {
  content: "\10f141";
}

.fad.fa-ellipsis-h:after,
.fa-duotone.fa-ellipsis-h:after {
  content: "\10f141";
}

.fad.fa-ellipsis-stroke:after,
.fa-duotone.fa-ellipsis-stroke:after {
  content: "\10f39b";
}

.fad.fa-ellipsis-h-alt:after,
.fa-duotone.fa-ellipsis-h-alt:after {
  content: "\10f39b";
}

.fad.fa-ellipsis-stroke-vertical:after,
.fa-duotone.fa-ellipsis-stroke-vertical:after {
  content: "\10f39c";
}

.fad.fa-ellipsis-v-alt:after,
.fa-duotone.fa-ellipsis-v-alt:after {
  content: "\10f39c";
}

.fad.fa-ellipsis-vertical:after,
.fa-duotone.fa-ellipsis-vertical:after {
  content: "\10f142";
}

.fad.fa-ellipsis-v:after,
.fa-duotone.fa-ellipsis-v:after {
  content: "\10f142";
}

.fad.fa-empty-set:after,
.fa-duotone.fa-empty-set:after {
  content: "\10f656";
}

.fad.fa-engine:after,
.fa-duotone.fa-engine:after {
  content: "\10e16e";
}

.fad.fa-engine-warning:after,
.fa-duotone.fa-engine-warning:after {
  content: "\10f5f2";
}

.fad.fa-engine-exclamation:after,
.fa-duotone.fa-engine-exclamation:after {
  content: "\10f5f2";
}

.fad.fa-envelope:after,
.fa-duotone.fa-envelope:after {
  content: "\10f0e0";
}

.fad.fa-envelope-dot:after,
.fa-duotone.fa-envelope-dot:after {
  content: "\10e16f";
}

.fad.fa-envelope-badge:after,
.fa-duotone.fa-envelope-badge:after {
  content: "\10e16f";
}

.fad.fa-envelope-open:after,
.fa-duotone.fa-envelope-open:after {
  content: "\10f2b6";
}

.fad.fa-envelope-open-dollar:after,
.fa-duotone.fa-envelope-open-dollar:after {
  content: "\10f657";
}

.fad.fa-envelope-open-text:after,
.fa-duotone.fa-envelope-open-text:after {
  content: "\10f658";
}

.fad.fa-envelopes:after,
.fa-duotone.fa-envelopes:after {
  content: "\10e170";
}

.fad.fa-envelopes-bulk:after,
.fa-duotone.fa-envelopes-bulk:after {
  content: "\10f674";
}

.fad.fa-mail-bulk:after,
.fa-duotone.fa-mail-bulk:after {
  content: "\10f674";
}

.fad.fa-equals:after,
.fa-duotone.fa-equals:after {
  content: "\10f52c";
}

.fad.fa-eraser:after,
.fa-duotone.fa-eraser:after {
  content: "\10f12d";
}

.fad.fa-escalator:after,
.fa-duotone.fa-escalator:after {
  content: "\10e171";
}

.fad.fa-ethernet:after,
.fa-duotone.fa-ethernet:after {
  content: "\10f796";
}

.fad.fa-euro-sign:after,
.fa-duotone.fa-euro-sign:after {
  content: "\10f153";
}

.fad.fa-eur:after,
.fa-duotone.fa-eur:after {
  content: "\10f153";
}

.fad.fa-euro:after,
.fa-duotone.fa-euro:after {
  content: "\10f153";
}

.fad.fa-exclamation:after,
.fa-duotone.fa-exclamation:after {
  content: "\10f12a";
}

.fad.fa-expand:after,
.fa-duotone.fa-expand:after {
  content: "\10f065";
}

.fad.fa-expand-wide:after,
.fa-duotone.fa-expand-wide:after {
  content: "\10f320";
}

.fad.fa-eye:after,
.fa-duotone.fa-eye:after {
  content: "\10f06e";
}

.fad.fa-eye-dropper:after,
.fa-duotone.fa-eye-dropper:after {
  content: "\10f1fb";
}

.fad.fa-eye-dropper-empty:after,
.fa-duotone.fa-eye-dropper-empty:after {
  content: "\10f1fb";
}

.fad.fa-eyedropper:after,
.fa-duotone.fa-eyedropper:after {
  content: "\10f1fb";
}

.fad.fa-eye-dropper-full:after,
.fa-duotone.fa-eye-dropper-full:after {
  content: "\10e172";
}

.fad.fa-eye-dropper-half:after,
.fa-duotone.fa-eye-dropper-half:after {
  content: "\10e173";
}

.fad.fa-eye-evil:after,
.fa-duotone.fa-eye-evil:after {
  content: "\10f6db";
}

.fad.fa-eye-low-vision:after,
.fa-duotone.fa-eye-low-vision:after {
  content: "\10f2a8";
}

.fad.fa-low-vision:after,
.fa-duotone.fa-low-vision:after {
  content: "\10f2a8";
}

.fad.fa-eye-slash:after,
.fa-duotone.fa-eye-slash:after {
  content: "\10f070";
}

.fad.fa-f:after,
.fa-duotone.fa-f:after {
  content: "\10e2fd";
}

.fad.fa-face-angry:after,
.fa-duotone.fa-face-angry:after {
  content: "\10f556";
}

.fad.fa-angry:after,
.fa-duotone.fa-angry:after {
  content: "\10f556";
}

.fad.fa-face-dizzy:after,
.fa-duotone.fa-face-dizzy:after {
  content: "\10f567";
}

.fad.fa-dizzy:after,
.fa-duotone.fa-dizzy:after {
  content: "\10f567";
}

.fad.fa-face-explode:after,
.fa-duotone.fa-face-explode:after {
  content: "\10e2fe";
}

.fad.fa-exploding-head:after,
.fa-duotone.fa-exploding-head:after {
  content: "\10e2fe";
}

.fad.fa-face-flushed:after,
.fa-duotone.fa-face-flushed:after {
  content: "\10f579";
}

.fad.fa-flushed:after,
.fa-duotone.fa-flushed:after {
  content: "\10f579";
}

.fad.fa-face-frown:after,
.fa-duotone.fa-face-frown:after {
  content: "\10f119";
}

.fad.fa-frown:after,
.fa-duotone.fa-frown:after {
  content: "\10f119";
}

.fad.fa-face-frown-open:after,
.fa-duotone.fa-face-frown-open:after {
  content: "\10f57a";
}

.fad.fa-frown-open:after,
.fa-duotone.fa-frown-open:after {
  content: "\10f57a";
}

.fad.fa-face-grimace:after,
.fa-duotone.fa-face-grimace:after {
  content: "\10f57f";
}

.fad.fa-grimace:after,
.fa-duotone.fa-grimace:after {
  content: "\10f57f";
}

.fad.fa-face-grin:after,
.fa-duotone.fa-face-grin:after {
  content: "\10f580";
}

.fad.fa-grin:after,
.fa-duotone.fa-grin:after {
  content: "\10f580";
}

.fad.fa-face-grin-beam:after,
.fa-duotone.fa-face-grin-beam:after {
  content: "\10f582";
}

.fad.fa-grin-beam:after,
.fa-duotone.fa-grin-beam:after {
  content: "\10f582";
}

.fad.fa-face-grin-beam-sweat:after,
.fa-duotone.fa-face-grin-beam-sweat:after {
  content: "\10f583";
}

.fad.fa-grin-beam-sweat:after,
.fa-duotone.fa-grin-beam-sweat:after {
  content: "\10f583";
}

.fad.fa-face-grin-hearts:after,
.fa-duotone.fa-face-grin-hearts:after {
  content: "\10f584";
}

.fad.fa-grin-hearts:after,
.fa-duotone.fa-grin-hearts:after {
  content: "\10f584";
}

.fad.fa-face-grin-squint:after,
.fa-duotone.fa-face-grin-squint:after {
  content: "\10f585";
}

.fad.fa-grin-squint:after,
.fa-duotone.fa-grin-squint:after {
  content: "\10f585";
}

.fad.fa-face-grin-squint-tears:after,
.fa-duotone.fa-face-grin-squint-tears:after {
  content: "\10f586";
}

.fad.fa-grin-squint-tears:after,
.fa-duotone.fa-grin-squint-tears:after {
  content: "\10f586";
}

.fad.fa-face-grin-stars:after,
.fa-duotone.fa-face-grin-stars:after {
  content: "\10f587";
}

.fad.fa-grin-stars:after,
.fa-duotone.fa-grin-stars:after {
  content: "\10f587";
}

.fad.fa-face-grin-tears:after,
.fa-duotone.fa-face-grin-tears:after {
  content: "\10f588";
}

.fad.fa-grin-tears:after,
.fa-duotone.fa-grin-tears:after {
  content: "\10f588";
}

.fad.fa-face-grin-tongue:after,
.fa-duotone.fa-face-grin-tongue:after {
  content: "\10f589";
}

.fad.fa-grin-tongue:after,
.fa-duotone.fa-grin-tongue:after {
  content: "\10f589";
}

.fad.fa-face-grin-tongue-squint:after,
.fa-duotone.fa-face-grin-tongue-squint:after {
  content: "\10f58a";
}

.fad.fa-grin-tongue-squint:after,
.fa-duotone.fa-grin-tongue-squint:after {
  content: "\10f58a";
}

.fad.fa-face-grin-tongue-wink:after,
.fa-duotone.fa-face-grin-tongue-wink:after {
  content: "\10f58b";
}

.fad.fa-grin-tongue-wink:after,
.fa-duotone.fa-grin-tongue-wink:after {
  content: "\10f58b";
}

.fad.fa-face-grin-wide:after,
.fa-duotone.fa-face-grin-wide:after {
  content: "\10f581";
}

.fad.fa-grin-alt:after,
.fa-duotone.fa-grin-alt:after {
  content: "\10f581";
}

.fad.fa-face-grin-wink:after,
.fa-duotone.fa-face-grin-wink:after {
  content: "\10f58c";
}

.fad.fa-grin-wink:after,
.fa-duotone.fa-grin-wink:after {
  content: "\10f58c";
}

.fad.fa-face-kiss:after,
.fa-duotone.fa-face-kiss:after {
  content: "\10f596";
}

.fad.fa-kiss:after,
.fa-duotone.fa-kiss:after {
  content: "\10f596";
}

.fad.fa-face-kiss-beam:after,
.fa-duotone.fa-face-kiss-beam:after {
  content: "\10f597";
}

.fad.fa-kiss-beam:after,
.fa-duotone.fa-kiss-beam:after {
  content: "\10f597";
}

.fad.fa-face-kiss-wink-heart:after,
.fa-duotone.fa-face-kiss-wink-heart:after {
  content: "\10f598";
}

.fad.fa-kiss-wink-heart:after,
.fa-duotone.fa-kiss-wink-heart:after {
  content: "\10f598";
}

.fad.fa-face-laugh:after,
.fa-duotone.fa-face-laugh:after {
  content: "\10f599";
}

.fad.fa-laugh:after,
.fa-duotone.fa-laugh:after {
  content: "\10f599";
}

.fad.fa-face-laugh-beam:after,
.fa-duotone.fa-face-laugh-beam:after {
  content: "\10f59a";
}

.fad.fa-laugh-beam:after,
.fa-duotone.fa-laugh-beam:after {
  content: "\10f59a";
}

.fad.fa-face-laugh-squint:after,
.fa-duotone.fa-face-laugh-squint:after {
  content: "\10f59b";
}

.fad.fa-laugh-squint:after,
.fa-duotone.fa-laugh-squint:after {
  content: "\10f59b";
}

.fad.fa-face-laugh-wink:after,
.fa-duotone.fa-face-laugh-wink:after {
  content: "\10f59c";
}

.fad.fa-laugh-wink:after,
.fa-duotone.fa-laugh-wink:after {
  content: "\10f59c";
}

.fad.fa-face-meh:after,
.fa-duotone.fa-face-meh:after {
  content: "\10f11a";
}

.fad.fa-meh:after,
.fa-duotone.fa-meh:after {
  content: "\10f11a";
}

.fad.fa-face-meh-blank:after,
.fa-duotone.fa-face-meh-blank:after {
  content: "\10f5a4";
}

.fad.fa-meh-blank:after,
.fa-duotone.fa-meh-blank:after {
  content: "\10f5a4";
}

.fad.fa-face-rolling-eyes:after,
.fa-duotone.fa-face-rolling-eyes:after {
  content: "\10f5a5";
}

.fad.fa-meh-rolling-eyes:after,
.fa-duotone.fa-meh-rolling-eyes:after {
  content: "\10f5a5";
}

.fad.fa-face-sad-cry:after,
.fa-duotone.fa-face-sad-cry:after {
  content: "\10f5b3";
}

.fad.fa-sad-cry:after,
.fa-duotone.fa-sad-cry:after {
  content: "\10f5b3";
}

.fad.fa-face-sad-tear:after,
.fa-duotone.fa-face-sad-tear:after {
  content: "\10f5b4";
}

.fad.fa-sad-tear:after,
.fa-duotone.fa-sad-tear:after {
  content: "\10f5b4";
}

.fad.fa-face-smile:after,
.fa-duotone.fa-face-smile:after {
  content: "\10f118";
}

.fad.fa-smile:after,
.fa-duotone.fa-smile:after {
  content: "\10f118";
}

.fad.fa-face-smile-beam:after,
.fa-duotone.fa-face-smile-beam:after {
  content: "\10f5b8";
}

.fad.fa-smile-beam:after,
.fa-duotone.fa-smile-beam:after {
  content: "\10f5b8";
}

.fad.fa-face-smile-plus:after,
.fa-duotone.fa-face-smile-plus:after {
  content: "\10f5b9";
}

.fad.fa-smile-plus:after,
.fa-duotone.fa-smile-plus:after {
  content: "\10f5b9";
}

.fad.fa-face-smile-wink:after,
.fa-duotone.fa-face-smile-wink:after {
  content: "\10f4da";
}

.fad.fa-smile-wink:after,
.fa-duotone.fa-smile-wink:after {
  content: "\10f4da";
}

.fad.fa-face-surprise:after,
.fa-duotone.fa-face-surprise:after {
  content: "\10f5c2";
}

.fad.fa-surprise:after,
.fa-duotone.fa-surprise:after {
  content: "\10f5c2";
}

.fad.fa-face-tired:after,
.fa-duotone.fa-face-tired:after {
  content: "\10f5c8";
}

.fad.fa-tired:after,
.fa-duotone.fa-tired:after {
  content: "\10f5c8";
}

.fad.fa-face-viewfinder:after,
.fa-duotone.fa-face-viewfinder:after {
  content: "\10e2ff";
}

.fad.fa-family:after,
.fa-duotone.fa-family:after {
  content: "\10e300";
}

.fad.fa-family-dress:after,
.fa-duotone.fa-family-dress:after {
  content: "\10e301";
}

.fad.fa-family-pants:after,
.fa-duotone.fa-family-pants:after {
  content: "\10e302";
}

.fad.fa-fan:after,
.fa-duotone.fa-fan:after {
  content: "\10f863";
}

.fad.fa-fan-table:after,
.fa-duotone.fa-fan-table:after {
  content: "\10e004";
}

.fad.fa-farm:after,
.fa-duotone.fa-farm:after {
  content: "\10f864";
}

.fad.fa-barn-silo:after,
.fa-duotone.fa-barn-silo:after {
  content: "\10f864";
}

.fad.fa-faucet:after,
.fa-duotone.fa-faucet:after {
  content: "\10e005";
}

.fad.fa-faucet-drip:after,
.fa-duotone.fa-faucet-drip:after {
  content: "\10e006";
}

.fad.fa-fax:after,
.fa-duotone.fa-fax:after {
  content: "\10f1ac";
}

.fad.fa-feather:after,
.fa-duotone.fa-feather:after {
  content: "\10f52d";
}

.fad.fa-feather-pointed:after,
.fa-duotone.fa-feather-pointed:after {
  content: "\10f56b";
}

.fad.fa-feather-alt:after,
.fa-duotone.fa-feather-alt:after {
  content: "\10f56b";
}

.fad.fa-fence:after,
.fa-duotone.fa-fence:after {
  content: "\10e303";
}

.fad.fa-ferris-wheel:after,
.fa-duotone.fa-ferris-wheel:after {
  content: "\10e174";
}

.fad.fa-field-hockey-stick-ball:after,
.fa-duotone.fa-field-hockey-stick-ball:after {
  content: "\10f44c";
}

.fad.fa-field-hockey:after,
.fa-duotone.fa-field-hockey:after {
  content: "\10f44c";
}

.fad.fa-file:after,
.fa-duotone.fa-file:after {
  content: "\10f15b";
}

.fad.fa-file-arrow-down:after,
.fa-duotone.fa-file-arrow-down:after {
  content: "\10f56d";
}

.fad.fa-file-download:after,
.fa-duotone.fa-file-download:after {
  content: "\10f56d";
}

.fad.fa-file-arrow-up:after,
.fa-duotone.fa-file-arrow-up:after {
  content: "\10f574";
}

.fad.fa-file-upload:after,
.fa-duotone.fa-file-upload:after {
  content: "\10f574";
}

.fad.fa-file-audio:after,
.fa-duotone.fa-file-audio:after {
  content: "\10f1c7";
}

.fad.fa-file-binary:after,
.fa-duotone.fa-file-binary:after {
  content: "\10e175";
}

.fad.fa-file-certificate:after,
.fa-duotone.fa-file-certificate:after {
  content: "\10f5f3";
}

.fad.fa-file-award:after,
.fa-duotone.fa-file-award:after {
  content: "\10f5f3";
}

.fad.fa-file-chart-column:after,
.fa-duotone.fa-file-chart-column:after {
  content: "\10f659";
}

.fad.fa-file-chart-line:after,
.fa-duotone.fa-file-chart-line:after {
  content: "\10f659";
}

.fad.fa-file-chart-pie:after,
.fa-duotone.fa-file-chart-pie:after {
  content: "\10f65a";
}

.fad.fa-file-check:after,
.fa-duotone.fa-file-check:after {
  content: "\10f316";
}

.fad.fa-file-code:after,
.fa-duotone.fa-file-code:after {
  content: "\10f1c9";
}

.fad.fa-file-contract:after,
.fa-duotone.fa-file-contract:after {
  content: "\10f56c";
}

.fad.fa-file-csv:after,
.fa-duotone.fa-file-csv:after {
  content: "\10f6dd";
}

.fad.fa-file-dashed-line:after,
.fa-duotone.fa-file-dashed-line:after {
  content: "\10f877";
}

.fad.fa-page-break:after,
.fa-duotone.fa-page-break:after {
  content: "\10f877";
}

.fad.fa-file-excel:after,
.fa-duotone.fa-file-excel:after {
  content: "\10f1c3";
}

.fad.fa-file-exclamation:after,
.fa-duotone.fa-file-exclamation:after {
  content: "\10f31a";
}

.fad.fa-file-export:after,
.fa-duotone.fa-file-export:after {
  content: "\10f56e";
}

.fad.fa-arrow-right-from-file:after,
.fa-duotone.fa-arrow-right-from-file:after {
  content: "\10f56e";
}

.fad.fa-file-heart:after,
.fa-duotone.fa-file-heart:after {
  content: "\10e176";
}

.fad.fa-file-image:after,
.fa-duotone.fa-file-image:after {
  content: "\10f1c5";
}

.fad.fa-file-import:after,
.fa-duotone.fa-file-import:after {
  content: "\10f56f";
}

.fad.fa-arrow-right-to-file:after,
.fa-duotone.fa-arrow-right-to-file:after {
  content: "\10f56f";
}

.fad.fa-file-invoice:after,
.fa-duotone.fa-file-invoice:after {
  content: "\10f570";
}

.fad.fa-file-invoice-dollar:after,
.fa-duotone.fa-file-invoice-dollar:after {
  content: "\10f571";
}

.fad.fa-file-lines:after,
.fa-duotone.fa-file-lines:after {
  content: "\10f15c";
}

.fad.fa-file-alt:after,
.fa-duotone.fa-file-alt:after {
  content: "\10f15c";
}

.fad.fa-file-text:after,
.fa-duotone.fa-file-text:after {
  content: "\10f15c";
}

.fad.fa-file-magnifying-glass:after,
.fa-duotone.fa-file-magnifying-glass:after {
  content: "\10f865";
}

.fad.fa-file-search:after,
.fa-duotone.fa-file-search:after {
  content: "\10f865";
}

.fad.fa-file-medical:after,
.fa-duotone.fa-file-medical:after {
  content: "\10f477";
}

.fad.fa-file-minus:after,
.fa-duotone.fa-file-minus:after {
  content: "\10f318";
}

.fad.fa-file-music:after,
.fa-duotone.fa-file-music:after {
  content: "\10f8b6";
}

.fad.fa-file-pdf:after,
.fa-duotone.fa-file-pdf:after {
  content: "\10f1c1";
}

.fad.fa-file-pen:after,
.fa-duotone.fa-file-pen:after {
  content: "\10f31c";
}

.fad.fa-file-edit:after,
.fa-duotone.fa-file-edit:after {
  content: "\10f31c";
}

.fad.fa-file-plus:after,
.fa-duotone.fa-file-plus:after {
  content: "\10f319";
}

.fad.fa-file-plus-minus:after,
.fa-duotone.fa-file-plus-minus:after {
  content: "\10e177";
}

.fad.fa-file-powerpoint:after,
.fa-duotone.fa-file-powerpoint:after {
  content: "\10f1c4";
}

.fad.fa-file-prescription:after,
.fa-duotone.fa-file-prescription:after {
  content: "\10f572";
}

.fad.fa-file-signature:after,
.fa-duotone.fa-file-signature:after {
  content: "\10f573";
}

.fad.fa-file-spreadsheet:after,
.fa-duotone.fa-file-spreadsheet:after {
  content: "\10f65b";
}

.fad.fa-file-user:after,
.fa-duotone.fa-file-user:after {
  content: "\10f65c";
}

.fad.fa-file-video:after,
.fa-duotone.fa-file-video:after {
  content: "\10f1c8";
}

.fad.fa-file-waveform:after,
.fa-duotone.fa-file-waveform:after {
  content: "\10f478";
}

.fad.fa-file-medical-alt:after,
.fa-duotone.fa-file-medical-alt:after {
  content: "\10f478";
}

.fad.fa-file-word:after,
.fa-duotone.fa-file-word:after {
  content: "\10f1c2";
}

.fad.fa-file-xmark:after,
.fa-duotone.fa-file-xmark:after {
  content: "\10f317";
}

.fad.fa-file-times:after,
.fa-duotone.fa-file-times:after {
  content: "\10f317";
}

.fad.fa-file-zipper:after,
.fa-duotone.fa-file-zipper:after {
  content: "\10f1c6";
}

.fad.fa-file-archive:after,
.fa-duotone.fa-file-archive:after {
  content: "\10f1c6";
}

.fad.fa-files:after,
.fa-duotone.fa-files:after {
  content: "\10e178";
}

.fad.fa-files-medical:after,
.fa-duotone.fa-files-medical:after {
  content: "\10f7fd";
}

.fad.fa-fill:after,
.fa-duotone.fa-fill:after {
  content: "\10f575";
}

.fad.fa-fill-drip:after,
.fa-duotone.fa-fill-drip:after {
  content: "\10f576";
}

.fad.fa-film:after,
.fa-duotone.fa-film:after {
  content: "\10f008";
}

.fad.fa-film-canister:after,
.fa-duotone.fa-film-canister:after {
  content: "\10f8b7";
}

.fad.fa-film-simple:after,
.fa-duotone.fa-film-simple:after {
  content: "\10f3a0";
}

.fad.fa-film-alt:after,
.fa-duotone.fa-film-alt:after {
  content: "\10f3a0";
}

.fad.fa-film-slash:after,
.fa-duotone.fa-film-slash:after {
  content: "\10e179";
}

.fad.fa-films:after,
.fa-duotone.fa-films:after {
  content: "\10e17a";
}

.fad.fa-filter:after,
.fa-duotone.fa-filter:after {
  content: "\10f0b0";
}

.fad.fa-filter-circle-dollar:after,
.fa-duotone.fa-filter-circle-dollar:after {
  content: "\10f662";
}

.fad.fa-funnel-dollar:after,
.fa-duotone.fa-funnel-dollar:after {
  content: "\10f662";
}

.fad.fa-filter-circle-xmark:after,
.fa-duotone.fa-filter-circle-xmark:after {
  content: "\10e17b";
}

.fad.fa-filter-list:after,
.fa-duotone.fa-filter-list:after {
  content: "\10e17c";
}

.fad.fa-filter-slash:after,
.fa-duotone.fa-filter-slash:after {
  content: "\10e17d";
}

.fad.fa-filters:after,
.fa-duotone.fa-filters:after {
  content: "\10e17e";
}

.fad.fa-fingerprint:after,
.fa-duotone.fa-fingerprint:after {
  content: "\10f577";
}

.fad.fa-fire:after,
.fa-duotone.fa-fire:after {
  content: "\10f06d";
}

.fad.fa-fire-extinguisher:after,
.fa-duotone.fa-fire-extinguisher:after {
  content: "\10f134";
}

.fad.fa-fire-flame:after,
.fa-duotone.fa-fire-flame:after {
  content: "\10f6df";
}

.fad.fa-flame:after,
.fa-duotone.fa-flame:after {
  content: "\10f6df";
}

.fad.fa-fire-flame-curved:after,
.fa-duotone.fa-fire-flame-curved:after {
  content: "\10f7e4";
}

.fad.fa-fire-alt:after,
.fa-duotone.fa-fire-alt:after {
  content: "\10f7e4";
}

.fad.fa-fire-flame-simple:after,
.fa-duotone.fa-fire-flame-simple:after {
  content: "\10f46a";
}

.fad.fa-burn:after,
.fa-duotone.fa-burn:after {
  content: "\10f46a";
}

.fad.fa-fire-hydrant:after,
.fa-duotone.fa-fire-hydrant:after {
  content: "\10e17f";
}

.fad.fa-fire-smoke:after,
.fa-duotone.fa-fire-smoke:after {
  content: "\10f74b";
}

.fad.fa-fireplace:after,
.fa-duotone.fa-fireplace:after {
  content: "\10f79a";
}

.fad.fa-fish:after,
.fa-duotone.fa-fish:after {
  content: "\10f578";
}

.fad.fa-fish-bones:after,
.fa-duotone.fa-fish-bones:after {
  content: "\10e304";
}

.fad.fa-fish-cooked:after,
.fa-duotone.fa-fish-cooked:after {
  content: "\10f7fe";
}

.fad.fa-flag:after,
.fa-duotone.fa-flag:after {
  content: "\10f024";
}

.fad.fa-flag-checkered:after,
.fa-duotone.fa-flag-checkered:after {
  content: "\10f11e";
}

.fad.fa-flag-pennant:after,
.fa-duotone.fa-flag-pennant:after {
  content: "\10f456";
}

.fad.fa-pennant:after,
.fa-duotone.fa-pennant:after {
  content: "\10f456";
}

.fad.fa-flag-swallowtail:after,
.fa-duotone.fa-flag-swallowtail:after {
  content: "\10f74c";
}

.fad.fa-flag-alt:after,
.fa-duotone.fa-flag-alt:after {
  content: "\10f74c";
}

.fad.fa-flag-usa:after,
.fa-duotone.fa-flag-usa:after {
  content: "\10f74d";
}

.fad.fa-flashlight:after,
.fa-duotone.fa-flashlight:after {
  content: "\10f8b8";
}

.fad.fa-flask:after,
.fa-duotone.fa-flask:after {
  content: "\10f0c3";
}

.fad.fa-flask-round-poison:after,
.fa-duotone.fa-flask-round-poison:after {
  content: "\10f6e0";
}

.fad.fa-flask-poison:after,
.fa-duotone.fa-flask-poison:after {
  content: "\10f6e0";
}

.fad.fa-flask-round-potion:after,
.fa-duotone.fa-flask-round-potion:after {
  content: "\10f6e1";
}

.fad.fa-flask-potion:after,
.fa-duotone.fa-flask-potion:after {
  content: "\10f6e1";
}

.fad.fa-floppy-disk:after,
.fa-duotone.fa-floppy-disk:after {
  content: "\10f0c7";
}

.fad.fa-save:after,
.fa-duotone.fa-save:after {
  content: "\10f0c7";
}

.fad.fa-floppy-disk-circle-arrow-right:after,
.fa-duotone.fa-floppy-disk-circle-arrow-right:after {
  content: "\10e180";
}

.fad.fa-save-circle-arrow-right:after,
.fa-duotone.fa-save-circle-arrow-right:after {
  content: "\10e180";
}

.fad.fa-floppy-disk-circle-xmark:after,
.fa-duotone.fa-floppy-disk-circle-xmark:after {
  content: "\10e181";
}

.fad.fa-floppy-disk-times:after,
.fa-duotone.fa-floppy-disk-times:after {
  content: "\10e181";
}

.fad.fa-save-circle-xmark:after,
.fa-duotone.fa-save-circle-xmark:after {
  content: "\10e181";
}

.fad.fa-save-times:after,
.fa-duotone.fa-save-times:after {
  content: "\10e181";
}

.fad.fa-floppy-disk-pen:after,
.fa-duotone.fa-floppy-disk-pen:after {
  content: "\10e182";
}

.fad.fa-floppy-disks:after,
.fa-duotone.fa-floppy-disks:after {
  content: "\10e183";
}

.fad.fa-florin-sign:after,
.fa-duotone.fa-florin-sign:after {
  content: "\10e184";
}

.fad.fa-flower:after,
.fa-duotone.fa-flower:after {
  content: "\10f7ff";
}

.fad.fa-flower-daffodil:after,
.fa-duotone.fa-flower-daffodil:after {
  content: "\10f800";
}

.fad.fa-flower-tulip:after,
.fa-duotone.fa-flower-tulip:after {
  content: "\10f801";
}

.fad.fa-flute:after,
.fa-duotone.fa-flute:after {
  content: "\10f8b9";
}

.fad.fa-flux-capacitor:after,
.fa-duotone.fa-flux-capacitor:after {
  content: "\10f8ba";
}

.fad.fa-folder:after,
.fa-duotone.fa-folder:after {
  content: "\10f07b";
}

.fad.fa-folder-arrow-down:after,
.fa-duotone.fa-folder-arrow-down:after {
  content: "\10e053";
}

.fad.fa-folder-download:after,
.fa-duotone.fa-folder-download:after {
  content: "\10e053";
}

.fad.fa-folder-arrow-up:after,
.fa-duotone.fa-folder-arrow-up:after {
  content: "\10e054";
}

.fad.fa-folder-upload:after,
.fa-duotone.fa-folder-upload:after {
  content: "\10e054";
}

.fad.fa-folder-blank:after,
.fa-duotone.fa-folder-blank:after {
  content: "\10e185";
}

.fad.fa-folder-bookmark:after,
.fa-duotone.fa-folder-bookmark:after {
  content: "\10e186";
}

.fad.fa-folder-gear:after,
.fa-duotone.fa-folder-gear:after {
  content: "\10e187";
}

.fad.fa-folder-cog:after,
.fa-duotone.fa-folder-cog:after {
  content: "\10e187";
}

.fad.fa-folder-grid:after,
.fa-duotone.fa-folder-grid:after {
  content: "\10e188";
}

.fad.fa-folder-heart:after,
.fa-duotone.fa-folder-heart:after {
  content: "\10e189";
}

.fad.fa-folder-image:after,
.fa-duotone.fa-folder-image:after {
  content: "\10e18a";
}

.fad.fa-folder-magnifying-glass:after,
.fa-duotone.fa-folder-magnifying-glass:after {
  content: "\10e18b";
}

.fad.fa-folder-search:after,
.fa-duotone.fa-folder-search:after {
  content: "\10e18b";
}

.fad.fa-folder-medical:after,
.fa-duotone.fa-folder-medical:after {
  content: "\10e18c";
}

.fad.fa-folder-minus:after,
.fa-duotone.fa-folder-minus:after {
  content: "\10f65d";
}

.fad.fa-folder-music:after,
.fa-duotone.fa-folder-music:after {
  content: "\10e18d";
}

.fad.fa-folder-open:after,
.fa-duotone.fa-folder-open:after {
  content: "\10f07c";
}

.fad.fa-folder-plus:after,
.fa-duotone.fa-folder-plus:after {
  content: "\10f65e";
}

.fad.fa-folder-tree:after,
.fa-duotone.fa-folder-tree:after {
  content: "\10f802";
}

.fad.fa-folder-user:after,
.fa-duotone.fa-folder-user:after {
  content: "\10e18e";
}

.fad.fa-folder-xmark:after,
.fa-duotone.fa-folder-xmark:after {
  content: "\10f65f";
}

.fad.fa-folder-times:after,
.fa-duotone.fa-folder-times:after {
  content: "\10f65f";
}

.fad.fa-folders:after,
.fa-duotone.fa-folders:after {
  content: "\10f660";
}

.fad.fa-font:after,
.fa-duotone.fa-font:after {
  content: "\10f031";
}

.fad.fa-font-awesome:after,
.fa-duotone.fa-font-awesome:after {
  content: "\10f2b4";
}

.fad.fa-font-awesome-flag:after,
.fa-duotone.fa-font-awesome-flag:after {
  content: "\10f2b4";
}

.fad.fa-font-awesome-logo-full:after,
.fa-duotone.fa-font-awesome-logo-full:after {
  content: "\10f2b4";
}

.fad.fa-font-case:after,
.fa-duotone.fa-font-case:after {
  content: "\10f866";
}

.fad.fa-football-ball:after,
.fa-duotone.fa-football-ball:after {
  content: "\10f44e";
}

.fad.fa-football-helmet:after,
.fa-duotone.fa-football-helmet:after {
  content: "\10f44f";
}

.fad.fa-fork:after,
.fa-duotone.fa-fork:after {
  content: "\10f2e3";
}

.fad.fa-utensil-fork:after,
.fa-duotone.fa-utensil-fork:after {
  content: "\10f2e3";
}

.fad.fa-fork-knife:after,
.fa-duotone.fa-fork-knife:after {
  content: "\10f2e6";
}

.fad.fa-utensils-alt:after,
.fa-duotone.fa-utensils-alt:after {
  content: "\10f2e6";
}

.fad.fa-forklift:after,
.fa-duotone.fa-forklift:after {
  content: "\10f47a";
}

.fad.fa-forward:after,
.fa-duotone.fa-forward:after {
  content: "\10f04e";
}

.fad.fa-forward-fast:after,
.fa-duotone.fa-forward-fast:after {
  content: "\10f050";
}

.fad.fa-fast-forward:after,
.fa-duotone.fa-fast-forward:after {
  content: "\10f050";
}

.fad.fa-forward-step:after,
.fa-duotone.fa-forward-step:after {
  content: "\10f051";
}

.fad.fa-step-forward:after,
.fa-duotone.fa-step-forward:after {
  content: "\10f051";
}

.fad.fa-franc-sign:after,
.fa-duotone.fa-franc-sign:after {
  content: "\10e18f";
}

.fad.fa-french-fries:after,
.fa-duotone.fa-french-fries:after {
  content: "\10f803";
}

.fad.fa-frog:after,
.fa-duotone.fa-frog:after {
  content: "\10f52e";
}

.fad.fa-function:after,
.fa-duotone.fa-function:after {
  content: "\10f661";
}

.fad.fa-futbol-ball:after,
.fa-duotone.fa-futbol-ball:after {
  content: "\10f1e3";
}

.fad.fa-futbol:after,
.fa-duotone.fa-futbol:after {
  content: "\10f1e3";
}

.fad.fa-soccer-ball:after,
.fa-duotone.fa-soccer-ball:after {
  content: "\10f1e3";
}

.fad.fa-g:after,
.fa-duotone.fa-g:after {
  content: "\10e305";
}

.fad.fa-galaxy:after,
.fa-duotone.fa-galaxy:after {
  content: "\10e008";
}

.fad.fa-game-board:after,
.fa-duotone.fa-game-board:after {
  content: "\10f867";
}

.fad.fa-game-board-simple:after,
.fa-duotone.fa-game-board-simple:after {
  content: "\10f868";
}

.fad.fa-game-board-alt:after,
.fa-duotone.fa-game-board-alt:after {
  content: "\10f868";
}

.fad.fa-game-console-handheld:after,
.fa-duotone.fa-game-console-handheld:after {
  content: "\10f8bb";
}

.fad.fa-gamepad:after,
.fa-duotone.fa-gamepad:after {
  content: "\10f11b";
}

.fad.fa-gamepad-modern:after,
.fa-duotone.fa-gamepad-modern:after {
  content: "\10f8bc";
}

.fad.fa-gamepad-alt:after,
.fa-duotone.fa-gamepad-alt:after {
  content: "\10f8bc";
}

.fad.fa-garage:after,
.fa-duotone.fa-garage:after {
  content: "\10e009";
}

.fad.fa-garage-car:after,
.fa-duotone.fa-garage-car:after {
  content: "\10e00a";
}

.fad.fa-garage-open:after,
.fa-duotone.fa-garage-open:after {
  content: "\10e00b";
}

.fad.fa-gas-pump:after,
.fa-duotone.fa-gas-pump:after {
  content: "\10f52f";
}

.fad.fa-gas-pump-slash:after,
.fa-duotone.fa-gas-pump-slash:after {
  content: "\10f5f4";
}

.fad.fa-gauge:after,
.fa-duotone.fa-gauge:after {
  content: "\10f625";
}

.fad.fa-dashboard:after,
.fa-duotone.fa-dashboard:after {
  content: "\10f625";
}

.fad.fa-gauge-high:after,
.fa-duotone.fa-gauge-high:after {
  content: "\10f625";
}

.fad.fa-tachometer-alt:after,
.fa-duotone.fa-tachometer-alt:after {
  content: "\10f625";
}

.fad.fa-tachometer-alt-fast:after,
.fa-duotone.fa-tachometer-alt-fast:after {
  content: "\10f625";
}

.fad.fa-gauge-low:after,
.fa-duotone.fa-gauge-low:after {
  content: "\10f627";
}

.fad.fa-tachometer-alt-slow:after,
.fa-duotone.fa-tachometer-alt-slow:after {
  content: "\10f627";
}

.fad.fa-gauge-max:after,
.fa-duotone.fa-gauge-max:after {
  content: "\10f626";
}

.fad.fa-tachometer-alt-fastest:after,
.fa-duotone.fa-tachometer-alt-fastest:after {
  content: "\10f626";
}

.fad.fa-gauge-med:after,
.fa-duotone.fa-gauge-med:after {
  content: "\10f624";
}

.fad.fa-tachometer-alt-average:after,
.fa-duotone.fa-tachometer-alt-average:after {
  content: "\10f624";
}

.fad.fa-gauge-min:after,
.fa-duotone.fa-gauge-min:after {
  content: "\10f628";
}

.fad.fa-tachometer-alt-slowest:after,
.fa-duotone.fa-tachometer-alt-slowest:after {
  content: "\10f628";
}

.fad.fa-gauge-simple:after,
.fa-duotone.fa-gauge-simple:after {
  content: "\10f62a";
}

.fad.fa-gauge-simple-high:after,
.fa-duotone.fa-gauge-simple-high:after {
  content: "\10f62a";
}

.fad.fa-tachometer:after,
.fa-duotone.fa-tachometer:after {
  content: "\10f62a";
}

.fad.fa-gauge-simple-low:after,
.fa-duotone.fa-gauge-simple-low:after {
  content: "\10f62c";
}

.fad.fa-tachometer-slow:after,
.fa-duotone.fa-tachometer-slow:after {
  content: "\10f62c";
}

.fad.fa-gauge-simple-max:after,
.fa-duotone.fa-gauge-simple-max:after {
  content: "\10f62b";
}

.fad.fa-tachometer-fastest:after,
.fa-duotone.fa-tachometer-fastest:after {
  content: "\10f62b";
}

.fad.fa-gauge-simple-med:after,
.fa-duotone.fa-gauge-simple-med:after {
  content: "\10f629";
}

.fad.fa-tachometer-average:after,
.fa-duotone.fa-tachometer-average:after {
  content: "\10f629";
}

.fad.fa-gauge-simple-min:after,
.fa-duotone.fa-gauge-simple-min:after {
  content: "\10f62d";
}

.fad.fa-tachometer-slowest:after,
.fa-duotone.fa-tachometer-slowest:after {
  content: "\10f62d";
}

.fad.fa-gavel:after,
.fa-duotone.fa-gavel:after {
  content: "\10f0e3";
}

.fad.fa-legal:after,
.fa-duotone.fa-legal:after {
  content: "\10f0e3";
}

.fad.fa-gear:after,
.fa-duotone.fa-gear:after {
  content: "\10f013";
}

.fad.fa-cog:after,
.fa-duotone.fa-cog:after {
  content: "\10f013";
}

.fad.fa-gears:after,
.fa-duotone.fa-gears:after {
  content: "\10f085";
}

.fad.fa-cogs:after,
.fa-duotone.fa-cogs:after {
  content: "\10f085";
}

.fad.fa-gem:after,
.fa-duotone.fa-gem:after {
  content: "\10f3a5";
}

.fad.fa-genderless:after,
.fa-duotone.fa-genderless:after {
  content: "\10f22d";
}

.fad.fa-ghost:after,
.fa-duotone.fa-ghost:after {
  content: "\10f6e2";
}

.fad.fa-gif:after,
.fa-duotone.fa-gif:after {
  content: "\10e190";
}

.fad.fa-gift:after,
.fa-duotone.fa-gift:after {
  content: "\10f06b";
}

.fad.fa-gift-card:after,
.fa-duotone.fa-gift-card:after {
  content: "\10f663";
}

.fad.fa-gifts:after,
.fa-duotone.fa-gifts:after {
  content: "\10f79c";
}

.fad.fa-gingerbread-man:after,
.fa-duotone.fa-gingerbread-man:after {
  content: "\10f79d";
}

.fad.fa-glass:after,
.fa-duotone.fa-glass:after {
  content: "\10f804";
}

.fad.fa-glass-citrus:after,
.fa-duotone.fa-glass-citrus:after {
  content: "\10f869";
}

.fad.fa-glass-empty:after,
.fa-duotone.fa-glass-empty:after {
  content: "\10e191";
}

.fad.fa-glass-half:after,
.fa-duotone.fa-glass-half:after {
  content: "\10e192";
}

.fad.fa-glass-half-empty:after,
.fa-duotone.fa-glass-half-empty:after {
  content: "\10e192";
}

.fad.fa-glass-half-full:after,
.fa-duotone.fa-glass-half-full:after {
  content: "\10e192";
}

.fad.fa-glasses:after,
.fa-duotone.fa-glasses:after {
  content: "\10f530";
}

.fad.fa-glasses-round:after,
.fa-duotone.fa-glasses-round:after {
  content: "\10f5f5";
}

.fad.fa-glasses-alt:after,
.fa-duotone.fa-glasses-alt:after {
  content: "\10f5f5";
}

.fad.fa-globe:after,
.fa-duotone.fa-globe:after {
  content: "\10f0ac";
}

.fad.fa-globe-snow:after,
.fa-duotone.fa-globe-snow:after {
  content: "\10f7a3";
}

.fad.fa-globe-stand:after,
.fa-duotone.fa-globe-stand:after {
  content: "\10f5f6";
}

.fad.fa-golf-ball-tee:after,
.fa-duotone.fa-golf-ball-tee:after {
  content: "\10f450";
}

.fad.fa-golf-ball:after,
.fa-duotone.fa-golf-ball:after {
  content: "\10f450";
}

.fad.fa-golf-club:after,
.fa-duotone.fa-golf-club:after {
  content: "\10f451";
}

.fad.fa-gopuram:after,
.fa-duotone.fa-gopuram:after {
  content: "\10f664";
}

.fad.fa-graduation-cap:after,
.fa-duotone.fa-graduation-cap:after {
  content: "\10f19d";
}

.fad.fa-mortar-board:after,
.fa-duotone.fa-mortar-board:after {
  content: "\10f19d";
}

.fad.fa-gramophone:after,
.fa-duotone.fa-gramophone:after {
  content: "\10f8bd";
}

.fad.fa-grapes:after,
.fa-duotone.fa-grapes:after {
  content: "\10e306";
}

.fad.fa-grate:after,
.fa-duotone.fa-grate:after {
  content: "\10e193";
}

.fad.fa-grate-droplet:after,
.fa-duotone.fa-grate-droplet:after {
  content: "\10e194";
}

.fad.fa-greater-than:after,
.fa-duotone.fa-greater-than:after {
  content: "\10f531";
}

.fad.fa-greater-than-equal:after,
.fa-duotone.fa-greater-than-equal:after {
  content: "\10f532";
}

.fad.fa-grid:after,
.fa-duotone.fa-grid:after {
  content: "\10e195";
}

.fad.fa-grid-3:after,
.fa-duotone.fa-grid-3:after {
  content: "\10e195";
}

.fad.fa-grid-2:after,
.fa-duotone.fa-grid-2:after {
  content: "\10e196";
}

.fad.fa-grid-2-plus:after,
.fa-duotone.fa-grid-2-plus:after {
  content: "\10e197";
}

.fad.fa-grid-4:after,
.fa-duotone.fa-grid-4:after {
  content: "\10e198";
}

.fad.fa-grid-5:after,
.fa-duotone.fa-grid-5:after {
  content: "\10e199";
}

.fad.fa-grid-horizontal:after,
.fa-duotone.fa-grid-horizontal:after {
  content: "\10e307";
}

.fad.fa-grip:after,
.fa-duotone.fa-grip:after {
  content: "\10f58d";
}

.fad.fa-grip-horizontal:after,
.fa-duotone.fa-grip-horizontal:after {
  content: "\10f58d";
}

.fad.fa-grip-lines:after,
.fa-duotone.fa-grip-lines:after {
  content: "\10f7a4";
}

.fad.fa-grip-lines-vertical:after,
.fa-duotone.fa-grip-lines-vertical:after {
  content: "\10f7a5";
}

.fad.fa-grip-vertical:after,
.fa-duotone.fa-grip-vertical:after {
  content: "\10f58e";
}

.fad.fa-guarani-sign:after,
.fa-duotone.fa-guarani-sign:after {
  content: "\10e19a";
}

.fad.fa-guitar:after,
.fa-duotone.fa-guitar:after {
  content: "\10f7a6";
}

.fad.fa-guitar-electric:after,
.fa-duotone.fa-guitar-electric:after {
  content: "\10f8be";
}

.fad.fa-guitars:after,
.fa-duotone.fa-guitars:after {
  content: "\10f8bf";
}

.fad.fa-gun:after,
.fa-duotone.fa-gun:after {
  content: "\10e19b";
}

.fad.fa-gun-slash:after,
.fa-duotone.fa-gun-slash:after {
  content: "\10e19c";
}

.fad.fa-gun-squirt:after,
.fa-duotone.fa-gun-squirt:after {
  content: "\10e19d";
}

.fad.fa-h:after,
.fa-duotone.fa-h:after {
  content: "\10e308";
}

.fad.fa-h1:after,
.fa-duotone.fa-h1:after {
  content: "\10f313";
}

.fad.fa-h2:after,
.fa-duotone.fa-h2:after {
  content: "\10f314";
}

.fad.fa-h3:after,
.fa-duotone.fa-h3:after {
  content: "\10f315";
}

.fad.fa-h4:after,
.fa-duotone.fa-h4:after {
  content: "\10f86a";
}

.fad.fa-hammer:after,
.fa-duotone.fa-hammer:after {
  content: "\10f6e3";
}

.fad.fa-hammer-war:after,
.fa-duotone.fa-hammer-war:after {
  content: "\10f6e4";
}

.fad.fa-hamsa:after,
.fa-duotone.fa-hamsa:after {
  content: "\10f665";
}

.fad.fa-hand-back-point-down:after,
.fa-duotone.fa-hand-back-point-down:after {
  content: "\10e19e";
}

.fad.fa-hand-back-point-left:after,
.fa-duotone.fa-hand-back-point-left:after {
  content: "\10e19f";
}

.fad.fa-hand-back-point-ribbon:after,
.fa-duotone.fa-hand-back-point-ribbon:after {
  content: "\10e1a0";
}

.fad.fa-hand-back-point-right:after,
.fa-duotone.fa-hand-back-point-right:after {
  content: "\10e1a1";
}

.fad.fa-hand-back-point-up:after,
.fa-duotone.fa-hand-back-point-up:after {
  content: "\10e1a2";
}

.fad.fa-hand-dots:after,
.fa-duotone.fa-hand-dots:after {
  content: "\10f461";
}

.fad.fa-allergies:after,
.fa-duotone.fa-allergies:after {
  content: "\10f461";
}

.fad.fa-hand-fingers-crossed:after,
.fa-duotone.fa-hand-fingers-crossed:after {
  content: "\10e1a3";
}

.fad.fa-hand-fist:after,
.fa-duotone.fa-hand-fist:after {
  content: "\10f6de";
}

.fad.fa-fist-raised:after,
.fa-duotone.fa-fist-raised:after {
  content: "\10f6de";
}

.fad.fa-hand-heart:after,
.fa-duotone.fa-hand-heart:after {
  content: "\10f4bc";
}

.fad.fa-hand-holding:after,
.fa-duotone.fa-hand-holding:after {
  content: "\10f4bd";
}

.fad.fa-hand-holding-box:after,
.fa-duotone.fa-hand-holding-box:after {
  content: "\10f47b";
}

.fad.fa-hand-holding-dollar:after,
.fa-duotone.fa-hand-holding-dollar:after {
  content: "\10f4c0";
}

.fad.fa-hand-holding-usd:after,
.fa-duotone.fa-hand-holding-usd:after {
  content: "\10f4c0";
}

.fad.fa-hand-holding-droplet:after,
.fa-duotone.fa-hand-holding-droplet:after {
  content: "\10f4c1";
}

.fad.fa-hand-holding-water:after,
.fa-duotone.fa-hand-holding-water:after {
  content: "\10f4c1";
}

.fad.fa-hand-holding-heart:after,
.fa-duotone.fa-hand-holding-heart:after {
  content: "\10f4be";
}

.fad.fa-hand-holding-magic:after,
.fa-duotone.fa-hand-holding-magic:after {
  content: "\10f6e5";
}

.fad.fa-hand-holding-medical:after,
.fa-duotone.fa-hand-holding-medical:after {
  content: "\10e05c";
}

.fad.fa-hand-holding-seedling:after,
.fa-duotone.fa-hand-holding-seedling:after {
  content: "\10f4bf";
}

.fad.fa-hand-holding-skull:after,
.fa-duotone.fa-hand-holding-skull:after {
  content: "\10e1a4";
}

.fad.fa-hand-horns:after,
.fa-duotone.fa-hand-horns:after {
  content: "\10e1a9";
}

.fad.fa-hand-lizard:after,
.fa-duotone.fa-hand-lizard:after {
  content: "\10f258";
}

.fad.fa-hand-love:after,
.fa-duotone.fa-hand-love:after {
  content: "\10e1a5";
}

.fad.fa-hand-middle-finger:after,
.fa-duotone.fa-hand-middle-finger:after {
  content: "\10f806";
}

.fad.fa-hand-paper:after,
.fa-duotone.fa-hand-paper:after {
  content: "\10f256";
}

.fad.fa-hand-peace:after,
.fa-duotone.fa-hand-peace:after {
  content: "\10f25b";
}

.fad.fa-hand-point-down:after,
.fa-duotone.fa-hand-point-down:after {
  content: "\10f0a7";
}

.fad.fa-hand-point-left:after,
.fa-duotone.fa-hand-point-left:after {
  content: "\10f0a5";
}

.fad.fa-hand-point-ribbon:after,
.fa-duotone.fa-hand-point-ribbon:after {
  content: "\10e1a6";
}

.fad.fa-hand-point-right:after,
.fa-duotone.fa-hand-point-right:after {
  content: "\10f0a4";
}

.fad.fa-hand-point-up:after,
.fa-duotone.fa-hand-point-up:after {
  content: "\10f0a6";
}

.fad.fa-hand-pointer:after,
.fa-duotone.fa-hand-pointer:after {
  content: "\10f25a";
}

.fad.fa-hand-rock:after,
.fa-duotone.fa-hand-rock:after {
  content: "\10f255";
}

.fad.fa-hand-scissors:after,
.fa-duotone.fa-hand-scissors:after {
  content: "\10f257";
}

.fad.fa-hand-sparkles:after,
.fa-duotone.fa-hand-sparkles:after {
  content: "\10e05d";
}

.fad.fa-hand-spock:after,
.fa-duotone.fa-hand-spock:after {
  content: "\10f259";
}

.fad.fa-hand-wave:after,
.fa-duotone.fa-hand-wave:after {
  content: "\10e1a7";
}

.fad.fa-hands:after,
.fa-duotone.fa-hands:after {
  content: "\10f2a7";
}

.fad.fa-sign-language:after,
.fa-duotone.fa-sign-language:after {
  content: "\10f2a7";
}

.fad.fa-signing:after,
.fa-duotone.fa-signing:after {
  content: "\10f2a7";
}

.fad.fa-hands-asl-interpreting:after,
.fa-duotone.fa-hands-asl-interpreting:after {
  content: "\10f2a3";
}

.fad.fa-american-sign-language-interpreting:after,
.fa-duotone.fa-american-sign-language-interpreting:after {
  content: "\10f2a3";
}

.fad.fa-asl-interpreting:after,
.fa-duotone.fa-asl-interpreting:after {
  content: "\10f2a3";
}

.fad.fa-hands-american-sign-language-interpreting:after,
.fa-duotone.fa-hands-american-sign-language-interpreting:after {
  content: "\10f2a3";
}

.fad.fa-hands-bubbles:after,
.fa-duotone.fa-hands-bubbles:after {
  content: "\10e05e";
}

.fad.fa-hands-wash:after,
.fa-duotone.fa-hands-wash:after {
  content: "\10e05e";
}

.fad.fa-hands-clapping:after,
.fa-duotone.fa-hands-clapping:after {
  content: "\10e1a8";
}

.fad.fa-hands-holding:after,
.fa-duotone.fa-hands-holding:after {
  content: "\10f4c2";
}

.fad.fa-hands-holding-diamond:after,
.fa-duotone.fa-hands-holding-diamond:after {
  content: "\10f47c";
}

.fad.fa-hand-receiving:after,
.fa-duotone.fa-hand-receiving:after {
  content: "\10f47c";
}

.fad.fa-hands-holding-dollar:after,
.fa-duotone.fa-hands-holding-dollar:after {
  content: "\10f4c5";
}

.fad.fa-hands-usd:after,
.fa-duotone.fa-hands-usd:after {
  content: "\10f4c5";
}

.fad.fa-hands-holding-heart:after,
.fa-duotone.fa-hands-holding-heart:after {
  content: "\10f4c3";
}

.fad.fa-hands-heart:after,
.fa-duotone.fa-hands-heart:after {
  content: "\10f4c3";
}

.fad.fa-hands-praying:after,
.fa-duotone.fa-hands-praying:after {
  content: "\10f684";
}

.fad.fa-praying-hands:after,
.fa-duotone.fa-praying-hands:after {
  content: "\10f684";
}

.fad.fa-handshake:after,
.fa-duotone.fa-handshake:after {
  content: "\10f2b5";
}

.fad.fa-handshake-angle:after,
.fa-duotone.fa-handshake-angle:after {
  content: "\10f4c4";
}

.fad.fa-hands-helping:after,
.fa-duotone.fa-hands-helping:after {
  content: "\10f4c4";
}

.fad.fa-handshake-simple:after,
.fa-duotone.fa-handshake-simple:after {
  content: "\10f4c6";
}

.fad.fa-handshake-alt:after,
.fa-duotone.fa-handshake-alt:after {
  content: "\10f4c6";
}

.fad.fa-handshake-simple-slash:after,
.fa-duotone.fa-handshake-simple-slash:after {
  content: "\10e05f";
}

.fad.fa-handshake-alt-slash:after,
.fa-duotone.fa-handshake-alt-slash:after {
  content: "\10e05f";
}

.fad.fa-handshake-slash:after,
.fa-duotone.fa-handshake-slash:after {
  content: "\10e060";
}

.fad.fa-hanukiah:after,
.fa-duotone.fa-hanukiah:after {
  content: "\10f6e6";
}

.fad.fa-hard-drive:after,
.fa-duotone.fa-hard-drive:after {
  content: "\10f0a0";
}

.fad.fa-hdd:after,
.fa-duotone.fa-hdd:after {
  content: "\10f0a0";
}

.fad.fa-hashtag:after,
.fa-duotone.fa-hashtag:after {
  content: "\10f292";
}

.fad.fa-hat-chef:after,
.fa-duotone.fa-hat-chef:after {
  content: "\10f86b";
}

.fad.fa-hat-cowboy:after,
.fa-duotone.fa-hat-cowboy:after {
  content: "\10f8c0";
}

.fad.fa-hat-cowboy-side:after,
.fa-duotone.fa-hat-cowboy-side:after {
  content: "\10f8c1";
}

.fad.fa-hat-santa:after,
.fa-duotone.fa-hat-santa:after {
  content: "\10f7a7";
}

.fad.fa-hat-winter:after,
.fa-duotone.fa-hat-winter:after {
  content: "\10f7a8";
}

.fad.fa-hat-witch:after,
.fa-duotone.fa-hat-witch:after {
  content: "\10f6e7";
}

.fad.fa-hat-wizard:after,
.fa-duotone.fa-hat-wizard:after {
  content: "\10f6e8";
}

.fad.fa-head-side:after,
.fa-duotone.fa-head-side:after {
  content: "\10f6e9";
}

.fad.fa-head-side-brain:after,
.fa-duotone.fa-head-side-brain:after {
  content: "\10f808";
}

.fad.fa-head-side-cough:after,
.fa-duotone.fa-head-side-cough:after {
  content: "\10e061";
}

.fad.fa-head-side-cough-slash:after,
.fa-duotone.fa-head-side-cough-slash:after {
  content: "\10e062";
}

.fad.fa-head-side-goggles:after,
.fa-duotone.fa-head-side-goggles:after {
  content: "\10f6ea";
}

.fad.fa-head-vr:after,
.fa-duotone.fa-head-vr:after {
  content: "\10f6ea";
}

.fad.fa-head-side-headphones:after,
.fa-duotone.fa-head-side-headphones:after {
  content: "\10f8c2";
}

.fad.fa-head-side-heart:after,
.fa-duotone.fa-head-side-heart:after {
  content: "\10e1aa";
}

.fad.fa-head-side-mask:after,
.fa-duotone.fa-head-side-mask:after {
  content: "\10e063";
}

.fad.fa-head-side-medical:after,
.fa-duotone.fa-head-side-medical:after {
  content: "\10f809";
}

.fad.fa-head-side-virus:after,
.fa-duotone.fa-head-side-virus:after {
  content: "\10e064";
}

.fad.fa-heading:after,
.fa-duotone.fa-heading:after {
  content: "\10f1dc";
}

.fad.fa-header:after,
.fa-duotone.fa-header:after {
  content: "\10f1dc";
}

.fad.fa-headphones:after,
.fa-duotone.fa-headphones:after {
  content: "\10f025";
}

.fad.fa-headphones-simple:after,
.fa-duotone.fa-headphones-simple:after {
  content: "\10f58f";
}

.fad.fa-headphones-alt:after,
.fa-duotone.fa-headphones-alt:after {
  content: "\10f58f";
}

.fad.fa-headset:after,
.fa-duotone.fa-headset:after {
  content: "\10f590";
}

.fad.fa-heart:after,
.fa-duotone.fa-heart:after {
  content: "\10f004";
}

.fad.fa-heart-crack:after,
.fa-duotone.fa-heart-crack:after {
  content: "\10f7a9";
}

.fad.fa-heart-broken:after,
.fa-duotone.fa-heart-broken:after {
  content: "\10f7a9";
}

.fad.fa-heart-half:after,
.fa-duotone.fa-heart-half:after {
  content: "\10e1ab";
}

.fad.fa-heart-half-stroke:after,
.fa-duotone.fa-heart-half-stroke:after {
  content: "\10e1ac";
}

.fad.fa-heart-half-alt:after,
.fa-duotone.fa-heart-half-alt:after {
  content: "\10e1ac";
}

.fad.fa-heart-pulse:after,
.fa-duotone.fa-heart-pulse:after {
  content: "\10f21e";
}

.fad.fa-heartbeat:after,
.fa-duotone.fa-heartbeat:after {
  content: "\10f21e";
}

.fad.fa-heat:after,
.fa-duotone.fa-heat:after {
  content: "\10e00c";
}

.fad.fa-helicopter:after,
.fa-duotone.fa-helicopter:after {
  content: "\10f533";
}

.fad.fa-helmet-battle:after,
.fa-duotone.fa-helmet-battle:after {
  content: "\10f6eb";
}

.fad.fa-helmet-safety:after,
.fa-duotone.fa-helmet-safety:after {
  content: "\10f807";
}

.fad.fa-hard-hat:after,
.fa-duotone.fa-hard-hat:after {
  content: "\10f807";
}

.fad.fa-hat-hard:after,
.fa-duotone.fa-hat-hard:after {
  content: "\10f807";
}

.fad.fa-hexagon:after,
.fa-duotone.fa-hexagon:after {
  content: "\10f312";
}

.fad.fa-hexagon-divide:after,
.fa-duotone.fa-hexagon-divide:after {
  content: "\10e1ad";
}

.fad.fa-hexagon-minus:after,
.fa-duotone.fa-hexagon-minus:after {
  content: "\10f307";
}

.fad.fa-minus-hexagon:after,
.fa-duotone.fa-minus-hexagon:after {
  content: "\10f307";
}

.fad.fa-hexagon-plus:after,
.fa-duotone.fa-hexagon-plus:after {
  content: "\10f300";
}

.fad.fa-plus-hexagon:after,
.fa-duotone.fa-plus-hexagon:after {
  content: "\10f300";
}

.fad.fa-hexagon-xmark:after,
.fa-duotone.fa-hexagon-xmark:after {
  content: "\10f2ee";
}

.fad.fa-times-hexagon:after,
.fa-duotone.fa-times-hexagon:after {
  content: "\10f2ee";
}

.fad.fa-xmark-hexagon:after,
.fa-duotone.fa-xmark-hexagon:after {
  content: "\10f2ee";
}

.fad.fa-high-definition:after,
.fa-duotone.fa-high-definition:after {
  content: "\10e1ae";
}

.fad.fa-rectangle-hd:after,
.fa-duotone.fa-rectangle-hd:after {
  content: "\10e1ae";
}

.fad.fa-highlighter:after,
.fa-duotone.fa-highlighter:after {
  content: "\10f591";
}

.fad.fa-highlighter-line:after,
.fa-duotone.fa-highlighter-line:after {
  content: "\10e1af";
}

.fad.fa-hippo:after,
.fa-duotone.fa-hippo:after {
  content: "\10f6ed";
}

.fad.fa-hockey-mask:after,
.fa-duotone.fa-hockey-mask:after {
  content: "\10f6ee";
}

.fad.fa-hockey-puck:after,
.fa-duotone.fa-hockey-puck:after {
  content: "\10f453";
}

.fad.fa-hockey-sticks:after,
.fa-duotone.fa-hockey-sticks:after {
  content: "\10f454";
}

.fad.fa-holly-berry:after,
.fa-duotone.fa-holly-berry:after {
  content: "\10f7aa";
}

.fad.fa-home:after,
.fa-duotone.fa-home:after {
  content: "\10f015";
}

.fad.fa-home-lg:after,
.fa-duotone.fa-home-lg:after {
  content: "\10f015";
}

.fad.fa-home-blank:after,
.fa-duotone.fa-home-blank:after {
  content: "\10f80a";
}

.fad.fa-home-lg-alt:after,
.fa-duotone.fa-home-lg-alt:after {
  content: "\10f80a";
}

.fad.fa-home-simple:after,
.fa-duotone.fa-home-simple:after {
  content: "\10f80a";
}

.fad.fa-home-heart:after,
.fa-duotone.fa-home-heart:after {
  content: "\10f4c9";
}

.fad.fa-home-user:after,
.fa-duotone.fa-home-user:after {
  content: "\10e1b0";
}

.fad.fa-hood-cloak:after,
.fa-duotone.fa-hood-cloak:after {
  content: "\10f6ef";
}

.fad.fa-horizontal-rule:after,
.fa-duotone.fa-horizontal-rule:after {
  content: "\10f86c";
}

.fad.fa-horse:after,
.fa-duotone.fa-horse:after {
  content: "\10f6f0";
}

.fad.fa-horse-head:after,
.fa-duotone.fa-horse-head:after {
  content: "\10f7ab";
}

.fad.fa-horse-saddle:after,
.fa-duotone.fa-horse-saddle:after {
  content: "\10f8c3";
}

.fad.fa-hospital:after,
.fa-duotone.fa-hospital:after {
  content: "\10f0f8";
}

.fad.fa-hospital-user:after,
.fa-duotone.fa-hospital-user:after {
  content: "\10f80d";
}

.fad.fa-hospital-wide:after,
.fa-duotone.fa-hospital-wide:after {
  content: "\10f47d";
}

.fad.fa-hospital-alt:after,
.fa-duotone.fa-hospital-alt:after {
  content: "\10f47d";
}

.fad.fa-hospitals:after,
.fa-duotone.fa-hospitals:after {
  content: "\10f80e";
}

.fad.fa-hot-tub-person:after,
.fa-duotone.fa-hot-tub-person:after {
  content: "\10f593";
}

.fad.fa-hot-tub:after,
.fa-duotone.fa-hot-tub:after {
  content: "\10f593";
}

.fad.fa-hotdog:after,
.fa-duotone.fa-hotdog:after {
  content: "\10f80f";
}

.fad.fa-hotel:after,
.fa-duotone.fa-hotel:after {
  content: "\10f594";
}

.fad.fa-hourglass:after,
.fa-duotone.fa-hourglass:after {
  content: "\10f254";
}

.fad.fa-hourglass-2:after,
.fa-duotone.fa-hourglass-2:after {
  content: "\10f254";
}

.fad.fa-hourglass-half:after,
.fa-duotone.fa-hourglass-half:after {
  content: "\10f254";
}

.fad.fa-hourglass-empty:after,
.fa-duotone.fa-hourglass-empty:after {
  content: "\10f252";
}

.fad.fa-hourglass-end:after,
.fa-duotone.fa-hourglass-end:after {
  content: "\10f253";
}

.fad.fa-hourglass-3:after,
.fa-duotone.fa-hourglass-3:after {
  content: "\10f253";
}

.fad.fa-hourglass-start:after,
.fa-duotone.fa-hourglass-start:after {
  content: "\10f251";
}

.fad.fa-hourglass-1:after,
.fa-duotone.fa-hourglass-1:after {
  content: "\10f251";
}

.fad.fa-house:after,
.fa-duotone.fa-house:after {
  content: "\10e00d";
}

.fad.fa-house-building:after,
.fa-duotone.fa-house-building:after {
  content: "\10e1b1";
}

.fad.fa-house-crack:after,
.fa-duotone.fa-house-crack:after {
  content: "\10f6f1";
}

.fad.fa-house-damage:after,
.fa-duotone.fa-house-damage:after {
  content: "\10f6f1";
}

.fad.fa-house-day:after,
.fa-duotone.fa-house-day:after {
  content: "\10e00e";
}

.fad.fa-house-flood:after,
.fa-duotone.fa-house-flood:after {
  content: "\10f74f";
}

.fad.fa-house-heart:after,
.fa-duotone.fa-house-heart:after {
  content: "\10e1b2";
}

.fad.fa-house-laptop:after,
.fa-duotone.fa-house-laptop:after {
  content: "\10e066";
}

.fad.fa-laptop-house:after,
.fa-duotone.fa-laptop-house:after {
  content: "\10e066";
}

.fad.fa-house-medical:after,
.fa-duotone.fa-house-medical:after {
  content: "\10f7f2";
}

.fad.fa-clinic-medical:after,
.fa-duotone.fa-clinic-medical:after {
  content: "\10f7f2";
}

.fad.fa-house-night:after,
.fa-duotone.fa-house-night:after {
  content: "\10e010";
}

.fad.fa-house-person-leave:after,
.fa-duotone.fa-house-person-leave:after {
  content: "\10e00f";
}

.fad.fa-house-person-depart:after,
.fa-duotone.fa-house-person-depart:after {
  content: "\10e00f";
}

.fad.fa-house-person-return:after,
.fa-duotone.fa-house-person-return:after {
  content: "\10e011";
}

.fad.fa-house-person-arrive:after,
.fa-duotone.fa-house-person-arrive:after {
  content: "\10e011";
}

.fad.fa-house-signal:after,
.fa-duotone.fa-house-signal:after {
  content: "\10e012";
}

.fad.fa-house-tree:after,
.fa-duotone.fa-house-tree:after {
  content: "\10e1b3";
}

.fad.fa-house-turret:after,
.fa-duotone.fa-house-turret:after {
  content: "\10e1b4";
}

.fad.fa-house-user:after,
.fa-duotone.fa-house-user:after {
  content: "\10e065";
}

.fad.fa-hryvnia-sign:after,
.fa-duotone.fa-hryvnia-sign:after {
  content: "\10f6f2";
}

.fad.fa-hryvnia:after,
.fa-duotone.fa-hryvnia:after {
  content: "\10f6f2";
}

.fad.fa-hurricane:after,
.fa-duotone.fa-hurricane:after {
  content: "\10f751";
}

.fad.fa-i:after,
.fa-duotone.fa-i:after {
  content: "\10e309";
}

.fad.fa-i-cursor:after,
.fa-duotone.fa-i-cursor:after {
  content: "\10f246";
}

.fad.fa-ice-cream:after,
.fa-duotone.fa-ice-cream:after {
  content: "\10f810";
}

.fad.fa-ice-skate:after,
.fa-duotone.fa-ice-skate:after {
  content: "\10f7ac";
}

.fad.fa-icicles:after,
.fa-duotone.fa-icicles:after {
  content: "\10f7ad";
}

.fad.fa-icons:after,
.fa-duotone.fa-icons:after {
  content: "\10f86d";
}

.fad.fa-heart-music-camera-bolt:after,
.fa-duotone.fa-heart-music-camera-bolt:after {
  content: "\10f86d";
}

.fad.fa-id-badge:after,
.fa-duotone.fa-id-badge:after {
  content: "\10f2c1";
}

.fad.fa-id-card:after,
.fa-duotone.fa-id-card:after {
  content: "\10f2c2";
}

.fad.fa-drivers-license:after,
.fa-duotone.fa-drivers-license:after {
  content: "\10f2c2";
}

.fad.fa-id-card-clip:after,
.fa-duotone.fa-id-card-clip:after {
  content: "\10f47f";
}

.fad.fa-id-card-alt:after,
.fa-duotone.fa-id-card-alt:after {
  content: "\10f47f";
}

.fad.fa-igloo:after,
.fa-duotone.fa-igloo:after {
  content: "\10f7ae";
}

.fad.fa-image:after,
.fa-duotone.fa-image:after {
  content: "\10f03e";
}

.fad.fa-image-landscape:after,
.fa-duotone.fa-image-landscape:after {
  content: "\10e1b5";
}

.fad.fa-landscape:after,
.fa-duotone.fa-landscape:after {
  content: "\10e1b5";
}

.fad.fa-image-polaroid:after,
.fa-duotone.fa-image-polaroid:after {
  content: "\10f8c4";
}

.fad.fa-image-polaroid-user:after,
.fa-duotone.fa-image-polaroid-user:after {
  content: "\10e1b6";
}

.fad.fa-image-portrait:after,
.fa-duotone.fa-image-portrait:after {
  content: "\10f3e0";
}

.fad.fa-portrait:after,
.fa-duotone.fa-portrait:after {
  content: "\10f3e0";
}

.fad.fa-image-slash:after,
.fa-duotone.fa-image-slash:after {
  content: "\10e1b7";
}

.fad.fa-image-user:after,
.fa-duotone.fa-image-user:after {
  content: "\10e1b8";
}

.fad.fa-images:after,
.fa-duotone.fa-images:after {
  content: "\10f302";
}

.fad.fa-images-user:after,
.fa-duotone.fa-images-user:after {
  content: "\10e1b9";
}

.fad.fa-inbox:after,
.fa-duotone.fa-inbox:after {
  content: "\10f01c";
}

.fad.fa-inbox-full:after,
.fa-duotone.fa-inbox-full:after {
  content: "\10e1ba";
}

.fad.fa-inbox-in:after,
.fa-duotone.fa-inbox-in:after {
  content: "\10f310";
}

.fad.fa-inbox-arrow-down:after,
.fa-duotone.fa-inbox-arrow-down:after {
  content: "\10f310";
}

.fad.fa-inbox-out:after,
.fa-duotone.fa-inbox-out:after {
  content: "\10f311";
}

.fad.fa-inbox-arrow-up:after,
.fa-duotone.fa-inbox-arrow-up:after {
  content: "\10f311";
}

.fad.fa-inboxes:after,
.fa-duotone.fa-inboxes:after {
  content: "\10e1bb";
}

.fad.fa-indent:after,
.fa-duotone.fa-indent:after {
  content: "\10f03c";
}

.fad.fa-indian-rupee-sign:after,
.fa-duotone.fa-indian-rupee-sign:after {
  content: "\10e1bc";
}

.fad.fa-indian-rupee:after,
.fa-duotone.fa-indian-rupee:after {
  content: "\10e1bc";
}

.fad.fa-inr:after,
.fa-duotone.fa-inr:after {
  content: "\10e1bc";
}

.fad.fa-industry:after,
.fa-duotone.fa-industry:after {
  content: "\10f275";
}

.fad.fa-industry-windows:after,
.fa-duotone.fa-industry-windows:after {
  content: "\10f3b3";
}

.fad.fa-industry-alt:after,
.fa-duotone.fa-industry-alt:after {
  content: "\10f3b3";
}

.fad.fa-infinity:after,
.fa-duotone.fa-infinity:after {
  content: "\10f534";
}

.fad.fa-info:after,
.fa-duotone.fa-info:after {
  content: "\10f129";
}

.fad.fa-inhaler:after,
.fa-duotone.fa-inhaler:after {
  content: "\10f5f9";
}

.fad.fa-input-numeric:after,
.fa-duotone.fa-input-numeric:after {
  content: "\10e1bd";
}

.fad.fa-input-pipe:after,
.fa-duotone.fa-input-pipe:after {
  content: "\10e1be";
}

.fad.fa-input-text:after,
.fa-duotone.fa-input-text:after {
  content: "\10e1bf";
}

.fad.fa-integral:after,
.fa-duotone.fa-integral:after {
  content: "\10f667";
}

.fad.fa-intersection:after,
.fa-duotone.fa-intersection:after {
  content: "\10f668";
}

.fad.fa-island-tropical:after,
.fa-duotone.fa-island-tropical:after {
  content: "\10f811";
}

.fad.fa-island-tree-palm:after,
.fa-duotone.fa-island-tree-palm:after {
  content: "\10f811";
}

.fad.fa-italic:after,
.fa-duotone.fa-italic:after {
  content: "\10f033";
}

.fad.fa-j:after,
.fa-duotone.fa-j:after {
  content: "\10e30a";
}

.fad.fa-jack-o-lantern:after,
.fa-duotone.fa-jack-o-lantern:after {
  content: "\10f30e";
}

.fad.fa-jedi:after,
.fa-duotone.fa-jedi:after {
  content: "\10f669";
}

.fad.fa-jet-fighter:after,
.fa-duotone.fa-jet-fighter:after {
  content: "\10f0fb";
}

.fad.fa-fighter-jet:after,
.fa-duotone.fa-fighter-jet:after {
  content: "\10f0fb";
}

.fad.fa-joint:after,
.fa-duotone.fa-joint:after {
  content: "\10f595";
}

.fad.fa-joystick:after,
.fa-duotone.fa-joystick:after {
  content: "\10f8c5";
}

.fad.fa-jug:after,
.fa-duotone.fa-jug:after {
  content: "\10f8c6";
}

.fad.fa-k:after,
.fa-duotone.fa-k:after {
  content: "\10e30b";
}

.fad.fa-kaaba:after,
.fa-duotone.fa-kaaba:after {
  content: "\10f66b";
}

.fad.fa-kazoo:after,
.fa-duotone.fa-kazoo:after {
  content: "\10f8c7";
}

.fad.fa-kerning:after,
.fa-duotone.fa-kerning:after {
  content: "\10f86f";
}

.fad.fa-key:after,
.fa-duotone.fa-key:after {
  content: "\10f084";
}

.fad.fa-key-skeleton:after,
.fa-duotone.fa-key-skeleton:after {
  content: "\10f6f3";
}

.fad.fa-keyboard:after,
.fa-duotone.fa-keyboard:after {
  content: "\10f11c";
}

.fad.fa-keyboard-brightness:after,
.fa-duotone.fa-keyboard-brightness:after {
  content: "\10e1c0";
}

.fad.fa-keyboard-brightness-low:after,
.fa-duotone.fa-keyboard-brightness-low:after {
  content: "\10e1c1";
}

.fad.fa-keyboard-down:after,
.fa-duotone.fa-keyboard-down:after {
  content: "\10e1c2";
}

.fad.fa-keyboard-left:after,
.fa-duotone.fa-keyboard-left:after {
  content: "\10e1c3";
}

.fad.fa-keynote:after,
.fa-duotone.fa-keynote:after {
  content: "\10f66c";
}

.fad.fa-khanda:after,
.fa-duotone.fa-khanda:after {
  content: "\10f66d";
}

.fad.fa-kidneys:after,
.fa-duotone.fa-kidneys:after {
  content: "\10f5fb";
}

.fad.fa-kip-sign:after,
.fa-duotone.fa-kip-sign:after {
  content: "\10e1c4";
}

.fad.fa-kit-medical:after,
.fa-duotone.fa-kit-medical:after {
  content: "\10f479";
}

.fad.fa-first-aid:after,
.fa-duotone.fa-first-aid:after {
  content: "\10f479";
}

.fad.fa-kite:after,
.fa-duotone.fa-kite:after {
  content: "\10f6f4";
}

.fad.fa-kiwi-bird:after,
.fa-duotone.fa-kiwi-bird:after {
  content: "\10f535";
}

.fad.fa-kiwi-fruit:after,
.fa-duotone.fa-kiwi-fruit:after {
  content: "\10e30c";
}

.fad.fa-knife:after,
.fa-duotone.fa-knife:after {
  content: "\10f2e4";
}

.fad.fa-utensil-knife:after,
.fa-duotone.fa-utensil-knife:after {
  content: "\10f2e4";
}

.fad.fa-knife-kitchen:after,
.fa-duotone.fa-knife-kitchen:after {
  content: "\10f6f5";
}

.fad.fa-l:after,
.fa-duotone.fa-l:after {
  content: "\10e30d";
}

.fad.fa-lambda:after,
.fa-duotone.fa-lambda:after {
  content: "\10f66e";
}

.fad.fa-lamp:after,
.fa-duotone.fa-lamp:after {
  content: "\10f4ca";
}

.fad.fa-lamp-desk:after,
.fa-duotone.fa-lamp-desk:after {
  content: "\10e014";
}

.fad.fa-lamp-floor:after,
.fa-duotone.fa-lamp-floor:after {
  content: "\10e015";
}

.fad.fa-lamp-street:after,
.fa-duotone.fa-lamp-street:after {
  content: "\10e1c5";
}

.fad.fa-landmark:after,
.fa-duotone.fa-landmark:after {
  content: "\10f66f";
}

.fad.fa-landmark-dome:after,
.fa-duotone.fa-landmark-dome:after {
  content: "\10f752";
}

.fad.fa-landmark-alt:after,
.fa-duotone.fa-landmark-alt:after {
  content: "\10f752";
}

.fad.fa-language:after,
.fa-duotone.fa-language:after {
  content: "\10f1ab";
}

.fad.fa-laptop:after,
.fa-duotone.fa-laptop:after {
  content: "\10f109";
}

.fad.fa-laptop-arrow-down:after,
.fa-duotone.fa-laptop-arrow-down:after {
  content: "\10e1c6";
}

.fad.fa-laptop-code:after,
.fa-duotone.fa-laptop-code:after {
  content: "\10f5fc";
}

.fad.fa-laptop-medical:after,
.fa-duotone.fa-laptop-medical:after {
  content: "\10f812";
}

.fad.fa-laptop-mobile:after,
.fa-duotone.fa-laptop-mobile:after {
  content: "\10f87a";
}

.fad.fa-phone-laptop:after,
.fa-duotone.fa-phone-laptop:after {
  content: "\10f87a";
}

.fad.fa-laptop-slash:after,
.fa-duotone.fa-laptop-slash:after {
  content: "\10e1c7";
}

.fad.fa-lari-sign:after,
.fa-duotone.fa-lari-sign:after {
  content: "\10e1c8";
}

.fad.fa-lasso:after,
.fa-duotone.fa-lasso:after {
  content: "\10f8c8";
}

.fad.fa-lasso-sparkles:after,
.fa-duotone.fa-lasso-sparkles:after {
  content: "\10e1c9";
}

.fad.fa-layer-group:after,
.fa-duotone.fa-layer-group:after {
  content: "\10f5fd";
}

.fad.fa-layer-minus:after,
.fa-duotone.fa-layer-minus:after {
  content: "\10f5fe";
}

.fad.fa-layer-group-minus:after,
.fa-duotone.fa-layer-group-minus:after {
  content: "\10f5fe";
}

.fad.fa-layer-plus:after,
.fa-duotone.fa-layer-plus:after {
  content: "\10f5ff";
}

.fad.fa-layer-group-plus:after,
.fa-duotone.fa-layer-group-plus:after {
  content: "\10f5ff";
}

.fad.fa-leaf:after,
.fa-duotone.fa-leaf:after {
  content: "\10f06c";
}

.fad.fa-leaf-heart:after,
.fa-duotone.fa-leaf-heart:after {
  content: "\10f4cb";
}

.fad.fa-leaf-maple:after,
.fa-duotone.fa-leaf-maple:after {
  content: "\10f6f6";
}

.fad.fa-leaf-oak:after,
.fa-duotone.fa-leaf-oak:after {
  content: "\10f6f7";
}

.fad.fa-left:after,
.fa-duotone.fa-left:after {
  content: "\10f355";
}

.fad.fa-arrow-alt-left:after,
.fa-duotone.fa-arrow-alt-left:after {
  content: "\10f355";
}

.fad.fa-left-from-line:after,
.fa-duotone.fa-left-from-line:after {
  content: "\10f348";
}

.fad.fa-arrow-alt-from-right:after,
.fa-duotone.fa-arrow-alt-from-right:after {
  content: "\10f348";
}

.fad.fa-left-long:after,
.fa-duotone.fa-left-long:after {
  content: "\10f30a";
}

.fad.fa-long-arrow-alt-left:after,
.fa-duotone.fa-long-arrow-alt-left:after {
  content: "\10f30a";
}

.fad.fa-left-right:after,
.fa-duotone.fa-left-right:after {
  content: "\10f337";
}

.fad.fa-arrows-alt-h:after,
.fa-duotone.fa-arrows-alt-h:after {
  content: "\10f337";
}

.fad.fa-left-to-line:after,
.fa-duotone.fa-left-to-line:after {
  content: "\10f34b";
}

.fad.fa-arrow-alt-to-left:after,
.fa-duotone.fa-arrow-alt-to-left:after {
  content: "\10f34b";
}

.fad.fa-lemon:after,
.fa-duotone.fa-lemon:after {
  content: "\10f094";
}

.fad.fa-less-than:after,
.fa-duotone.fa-less-than:after {
  content: "\10f536";
}

.fad.fa-less-than-equal:after,
.fa-duotone.fa-less-than-equal:after {
  content: "\10f537";
}

.fad.fa-life-ring:after,
.fa-duotone.fa-life-ring:after {
  content: "\10f1cd";
}

.fad.fa-light-ceiling:after,
.fa-duotone.fa-light-ceiling:after {
  content: "\10e016";
}

.fad.fa-light-switch:after,
.fa-duotone.fa-light-switch:after {
  content: "\10e017";
}

.fad.fa-light-switch-off:after,
.fa-duotone.fa-light-switch-off:after {
  content: "\10e018";
}

.fad.fa-light-switch-on:after,
.fa-duotone.fa-light-switch-on:after {
  content: "\10e019";
}

.fad.fa-lightbulb:after,
.fa-duotone.fa-lightbulb:after {
  content: "\10f0eb";
}

.fad.fa-lightbulb-dollar:after,
.fa-duotone.fa-lightbulb-dollar:after {
  content: "\10f670";
}

.fad.fa-lightbulb-exclamation:after,
.fa-duotone.fa-lightbulb-exclamation:after {
  content: "\10f671";
}

.fad.fa-lightbulb-exclamation-on:after,
.fa-duotone.fa-lightbulb-exclamation-on:after {
  content: "\10e1ca";
}

.fad.fa-lightbulb-on:after,
.fa-duotone.fa-lightbulb-on:after {
  content: "\10f672";
}

.fad.fa-lightbulb-slash:after,
.fa-duotone.fa-lightbulb-slash:after {
  content: "\10f673";
}

.fad.fa-lights-holiday:after,
.fa-duotone.fa-lights-holiday:after {
  content: "\10f7b2";
}

.fad.fa-line-columns:after,
.fa-duotone.fa-line-columns:after {
  content: "\10f870";
}

.fad.fa-line-height:after,
.fa-duotone.fa-line-height:after {
  content: "\10f871";
}

.fad.fa-link:after,
.fa-duotone.fa-link:after {
  content: "\10f0c1";
}

.fad.fa-chain:after,
.fa-duotone.fa-chain:after {
  content: "\10f0c1";
}

.fad.fa-link-horizontal:after,
.fa-duotone.fa-link-horizontal:after {
  content: "\10e1cb";
}

.fad.fa-chain-horizontal:after,
.fa-duotone.fa-chain-horizontal:after {
  content: "\10e1cb";
}

.fad.fa-link-horizontal-slash:after,
.fa-duotone.fa-link-horizontal-slash:after {
  content: "\10e1cc";
}

.fad.fa-chain-horizontal-slash:after,
.fa-duotone.fa-chain-horizontal-slash:after {
  content: "\10e1cc";
}

.fad.fa-link-simple:after,
.fa-duotone.fa-link-simple:after {
  content: "\10e1cd";
}

.fad.fa-link-simple-slash:after,
.fa-duotone.fa-link-simple-slash:after {
  content: "\10e1ce";
}

.fad.fa-link-slash:after,
.fa-duotone.fa-link-slash:after {
  content: "\10f127";
}

.fad.fa-chain-broken:after,
.fa-duotone.fa-chain-broken:after {
  content: "\10f127";
}

.fad.fa-chain-slash:after,
.fa-duotone.fa-chain-slash:after {
  content: "\10f127";
}

.fad.fa-unlink:after,
.fa-duotone.fa-unlink:after {
  content: "\10f127";
}

.fad.fa-lips:after,
.fa-duotone.fa-lips:after {
  content: "\10f600";
}

.fad.fa-lira-sign:after,
.fa-duotone.fa-lira-sign:after {
  content: "\10f195";
}

.fad.fa-list:after,
.fa-duotone.fa-list:after {
  content: "\10f03a";
}

.fad.fa-list-squares:after,
.fa-duotone.fa-list-squares:after {
  content: "\10f03a";
}

.fad.fa-list-check:after,
.fa-duotone.fa-list-check:after {
  content: "\10f0ae";
}

.fad.fa-tasks:after,
.fa-duotone.fa-tasks:after {
  content: "\10f0ae";
}

.fad.fa-list-dropdown:after,
.fa-duotone.fa-list-dropdown:after {
  content: "\10e1cf";
}

.fad.fa-list-music:after,
.fa-duotone.fa-list-music:after {
  content: "\10f8c9";
}

.fad.fa-list-ol:after,
.fa-duotone.fa-list-ol:after {
  content: "\10f0cb";
}

.fad.fa-list-1-2:after,
.fa-duotone.fa-list-1-2:after {
  content: "\10f0cb";
}

.fad.fa-list-numeric:after,
.fa-duotone.fa-list-numeric:after {
  content: "\10f0cb";
}

.fad.fa-list-radio:after,
.fa-duotone.fa-list-radio:after {
  content: "\10e1d0";
}

.fad.fa-list-timeline:after,
.fa-duotone.fa-list-timeline:after {
  content: "\10e1d1";
}

.fad.fa-list-tree:after,
.fa-duotone.fa-list-tree:after {
  content: "\10e1d2";
}

.fad.fa-list-ul:after,
.fa-duotone.fa-list-ul:after {
  content: "\10f0ca";
}

.fad.fa-list-dots:after,
.fa-duotone.fa-list-dots:after {
  content: "\10f0ca";
}

.fad.fa-litecoin-sign:after,
.fa-duotone.fa-litecoin-sign:after {
  content: "\10e1d3";
}

.fad.fa-loader:after,
.fa-duotone.fa-loader:after {
  content: "\10e1d4";
}

.fad.fa-location:after,
.fa-duotone.fa-location:after {
  content: "\10f041";
}

.fad.fa-map-marker:after,
.fa-duotone.fa-map-marker:after {
  content: "\10f041";
}

.fad.fa-location-arrow:after,
.fa-duotone.fa-location-arrow:after {
  content: "\10f124";
}

.fad.fa-location-check:after,
.fa-duotone.fa-location-check:after {
  content: "\10f606";
}

.fad.fa-map-marker-check:after,
.fa-duotone.fa-map-marker-check:after {
  content: "\10f606";
}

.fad.fa-location-crosshairs:after,
.fa-duotone.fa-location-crosshairs:after {
  content: "\10f601";
}

.fad.fa-location-crosshairs-slash:after,
.fa-duotone.fa-location-crosshairs-slash:after {
  content: "\10f603";
}

.fad.fa-location-dot:after,
.fa-duotone.fa-location-dot:after {
  content: "\10f3c5";
}

.fad.fa-map-marker-alt:after,
.fa-duotone.fa-map-marker-alt:after {
  content: "\10f3c5";
}

.fad.fa-location-dot-slash:after,
.fa-duotone.fa-location-dot-slash:after {
  content: "\10f605";
}

.fad.fa-map-marker-alt-slash:after,
.fa-duotone.fa-map-marker-alt-slash:after {
  content: "\10f605";
}

.fad.fa-location-exclamation:after,
.fa-duotone.fa-location-exclamation:after {
  content: "\10f608";
}

.fad.fa-map-marker-exclamation:after,
.fa-duotone.fa-map-marker-exclamation:after {
  content: "\10f608";
}

.fad.fa-location-minus:after,
.fa-duotone.fa-location-minus:after {
  content: "\10f609";
}

.fad.fa-map-marker-minus:after,
.fa-duotone.fa-map-marker-minus:after {
  content: "\10f609";
}

.fad.fa-location-pen:after,
.fa-duotone.fa-location-pen:after {
  content: "\10f607";
}

.fad.fa-map-marker-edit:after,
.fa-duotone.fa-map-marker-edit:after {
  content: "\10f607";
}

.fad.fa-location-plus:after,
.fa-duotone.fa-location-plus:after {
  content: "\10f60a";
}

.fad.fa-map-marker-plus:after,
.fa-duotone.fa-map-marker-plus:after {
  content: "\10f60a";
}

.fad.fa-location-question:after,
.fa-duotone.fa-location-question:after {
  content: "\10f60b";
}

.fad.fa-map-marker-question:after,
.fa-duotone.fa-map-marker-question:after {
  content: "\10f60b";
}

.fad.fa-location-slash:after,
.fa-duotone.fa-location-slash:after {
  content: "\10f60c";
}

.fad.fa-map-marker-slash:after,
.fa-duotone.fa-map-marker-slash:after {
  content: "\10f60c";
}

.fad.fa-location-smile:after,
.fa-duotone.fa-location-smile:after {
  content: "\10f60d";
}

.fad.fa-map-marker-smile:after,
.fa-duotone.fa-map-marker-smile:after {
  content: "\10f60d";
}

.fad.fa-location-xmark:after,
.fa-duotone.fa-location-xmark:after {
  content: "\10f60e";
}

.fad.fa-map-marker-times:after,
.fa-duotone.fa-map-marker-times:after {
  content: "\10f60e";
}

.fad.fa-map-marker-xmark:after,
.fa-duotone.fa-map-marker-xmark:after {
  content: "\10f60e";
}

.fad.fa-lock:after,
.fa-duotone.fa-lock:after {
  content: "\10f023";
}

.fad.fa-lock-keyhole:after,
.fa-duotone.fa-lock-keyhole:after {
  content: "\10f30d";
}

.fad.fa-lock-alt:after,
.fa-duotone.fa-lock-alt:after {
  content: "\10f30d";
}

.fad.fa-lock-keyhole-open:after,
.fa-duotone.fa-lock-keyhole-open:after {
  content: "\10f3c2";
}

.fad.fa-lock-open-alt:after,
.fa-duotone.fa-lock-open-alt:after {
  content: "\10f3c2";
}

.fad.fa-lock-open:after,
.fa-duotone.fa-lock-open:after {
  content: "\10f3c1";
}

.fad.fa-loveseat:after,
.fa-duotone.fa-loveseat:after {
  content: "\10f4cc";
}

.fad.fa-couch-small:after,
.fa-duotone.fa-couch-small:after {
  content: "\10f4cc";
}

.fad.fa-luchador-mask:after,
.fa-duotone.fa-luchador-mask:after {
  content: "\10f455";
}

.fad.fa-luchador:after,
.fa-duotone.fa-luchador:after {
  content: "\10f455";
}

.fad.fa-mask-luchador:after,
.fa-duotone.fa-mask-luchador:after {
  content: "\10f455";
}

.fad.fa-lungs:after,
.fa-duotone.fa-lungs:after {
  content: "\10f604";
}

.fad.fa-lungs-virus:after,
.fa-duotone.fa-lungs-virus:after {
  content: "\10e067";
}

.fad.fa-m:after,
.fa-duotone.fa-m:after {
  content: "\10e30e";
}

.fad.fa-mace:after,
.fa-duotone.fa-mace:after {
  content: "\10f6f8";
}

.fad.fa-magnet:after,
.fa-duotone.fa-magnet:after {
  content: "\10f076";
}

.fad.fa-magnifying-glass:after,
.fa-duotone.fa-magnifying-glass:after {
  content: "\10f002";
}

.fad.fa-search:after,
.fa-duotone.fa-search:after {
  content: "\10f002";
}

.fad.fa-magnifying-glass-dollar:after,
.fa-duotone.fa-magnifying-glass-dollar:after {
  content: "\10f688";
}

.fad.fa-search-dollar:after,
.fa-duotone.fa-search-dollar:after {
  content: "\10f688";
}

.fad.fa-magnifying-glass-location:after,
.fa-duotone.fa-magnifying-glass-location:after {
  content: "\10f689";
}

.fad.fa-search-location:after,
.fa-duotone.fa-search-location:after {
  content: "\10f689";
}

.fad.fa-magnifying-glass-minus:after,
.fa-duotone.fa-magnifying-glass-minus:after {
  content: "\10f010";
}

.fad.fa-search-minus:after,
.fa-duotone.fa-search-minus:after {
  content: "\10f010";
}

.fad.fa-magnifying-glass-plus:after,
.fa-duotone.fa-magnifying-glass-plus:after {
  content: "\10f00e";
}

.fad.fa-search-plus:after,
.fa-duotone.fa-search-plus:after {
  content: "\10f00e";
}

.fad.fa-mailbox:after,
.fa-duotone.fa-mailbox:after {
  content: "\10f813";
}

.fad.fa-manat-sign:after,
.fa-duotone.fa-manat-sign:after {
  content: "\10e1d5";
}

.fad.fa-mandolin:after,
.fa-duotone.fa-mandolin:after {
  content: "\10f6f9";
}

.fad.fa-mango:after,
.fa-duotone.fa-mango:after {
  content: "\10e30f";
}

.fad.fa-manhole:after,
.fa-duotone.fa-manhole:after {
  content: "\10e1d6";
}

.fad.fa-map:after,
.fa-duotone.fa-map:after {
  content: "\10f279";
}

.fad.fa-map-location:after,
.fa-duotone.fa-map-location:after {
  content: "\10f59f";
}

.fad.fa-map-marked:after,
.fa-duotone.fa-map-marked:after {
  content: "\10f59f";
}

.fad.fa-map-location-dot:after,
.fa-duotone.fa-map-location-dot:after {
  content: "\10f5a0";
}

.fad.fa-map-marked-alt:after,
.fa-duotone.fa-map-marked-alt:after {
  content: "\10f5a0";
}

.fad.fa-map-pin:after,
.fa-duotone.fa-map-pin:after {
  content: "\10f276";
}

.fad.fa-marker:after,
.fa-duotone.fa-marker:after {
  content: "\10f5a1";
}

.fad.fa-mars:after,
.fa-duotone.fa-mars:after {
  content: "\10f222";
}

.fad.fa-mars-double:after,
.fa-duotone.fa-mars-double:after {
  content: "\10f227";
}

.fad.fa-mars-stroke:after,
.fa-duotone.fa-mars-stroke:after {
  content: "\10f229";
}

.fad.fa-mars-stroke-right:after,
.fa-duotone.fa-mars-stroke-right:after {
  content: "\10f22b";
}

.fad.fa-mars-stroke-h:after,
.fa-duotone.fa-mars-stroke-h:after {
  content: "\10f22b";
}

.fad.fa-mars-stroke-up:after,
.fa-duotone.fa-mars-stroke-up:after {
  content: "\10f22a";
}

.fad.fa-mars-stroke-v:after,
.fa-duotone.fa-mars-stroke-v:after {
  content: "\10f22a";
}

.fad.fa-martini-glass:after,
.fa-duotone.fa-martini-glass:after {
  content: "\10f57b";
}

.fad.fa-glass-martini-alt:after,
.fa-duotone.fa-glass-martini-alt:after {
  content: "\10f57b";
}

.fad.fa-martini-glass-citrus:after,
.fa-duotone.fa-martini-glass-citrus:after {
  content: "\10f561";
}

.fad.fa-cocktail:after,
.fa-duotone.fa-cocktail:after {
  content: "\10f561";
}

.fad.fa-martini-glass-empty:after,
.fa-duotone.fa-martini-glass-empty:after {
  content: "\10f000";
}

.fad.fa-glass-martini:after,
.fa-duotone.fa-glass-martini:after {
  content: "\10f000";
}

.fad.fa-mask:after,
.fa-duotone.fa-mask:after {
  content: "\10f6fa";
}

.fad.fa-mask-face:after,
.fa-duotone.fa-mask-face:after {
  content: "\10e1d7";
}

.fad.fa-masks-theater:after,
.fa-duotone.fa-masks-theater:after {
  content: "\10f630";
}

.fad.fa-theater-masks:after,
.fa-duotone.fa-theater-masks:after {
  content: "\10f630";
}

.fad.fa-maximize:after,
.fa-duotone.fa-maximize:after {
  content: "\10f31e";
}

.fad.fa-expand-arrows-alt:after,
.fa-duotone.fa-expand-arrows-alt:after {
  content: "\10f31e";
}

.fad.fa-meat:after,
.fa-duotone.fa-meat:after {
  content: "\10f814";
}

.fad.fa-medal:after,
.fa-duotone.fa-medal:after {
  content: "\10f5a2";
}

.fad.fa-megaphone:after,
.fa-duotone.fa-megaphone:after {
  content: "\10f675";
}

.fad.fa-melon:after,
.fa-duotone.fa-melon:after {
  content: "\10e310";
}

.fad.fa-melon-slice:after,
.fa-duotone.fa-melon-slice:after {
  content: "\10e311";
}

.fad.fa-memo:after,
.fa-duotone.fa-memo:after {
  content: "\10e1d8";
}

.fad.fa-memo-circle-check:after,
.fa-duotone.fa-memo-circle-check:after {
  content: "\10e1d9";
}

.fad.fa-memo-pad:after,
.fa-duotone.fa-memo-pad:after {
  content: "\10e1da";
}

.fad.fa-memory:after,
.fa-duotone.fa-memory:after {
  content: "\10f538";
}

.fad.fa-menorah:after,
.fa-duotone.fa-menorah:after {
  content: "\10f676";
}

.fad.fa-mercury:after,
.fa-duotone.fa-mercury:after {
  content: "\10f223";
}

.fad.fa-message:after,
.fa-duotone.fa-message:after {
  content: "\10f27a";
}

.fad.fa-comment-alt:after,
.fa-duotone.fa-comment-alt:after {
  content: "\10f27a";
}

.fad.fa-message-arrow-down:after,
.fa-duotone.fa-message-arrow-down:after {
  content: "\10e1db";
}

.fad.fa-comment-alt-arrow-down:after,
.fa-duotone.fa-comment-alt-arrow-down:after {
  content: "\10e1db";
}

.fad.fa-message-arrow-up:after,
.fa-duotone.fa-message-arrow-up:after {
  content: "\10e1dc";
}

.fad.fa-comment-alt-arrow-up:after,
.fa-duotone.fa-comment-alt-arrow-up:after {
  content: "\10e1dc";
}

.fad.fa-message-arrow-up-right:after,
.fa-duotone.fa-message-arrow-up-right:after {
  content: "\10e1dd";
}

.fad.fa-message-captions:after,
.fa-duotone.fa-message-captions:after {
  content: "\10e1de";
}

.fad.fa-comment-alt-captions:after,
.fa-duotone.fa-comment-alt-captions:after {
  content: "\10e1de";
}

.fad.fa-message-check:after,
.fa-duotone.fa-message-check:after {
  content: "\10f4a2";
}

.fad.fa-comment-alt-check:after,
.fa-duotone.fa-comment-alt-check:after {
  content: "\10f4a2";
}

.fad.fa-message-code:after,
.fa-duotone.fa-message-code:after {
  content: "\10e1df";
}

.fad.fa-message-dollar:after,
.fa-duotone.fa-message-dollar:after {
  content: "\10f650";
}

.fad.fa-comment-alt-dollar:after,
.fa-duotone.fa-comment-alt-dollar:after {
  content: "\10f650";
}

.fad.fa-message-dots:after,
.fa-duotone.fa-message-dots:after {
  content: "\10f4a3";
}

.fad.fa-comment-alt-dots:after,
.fa-duotone.fa-comment-alt-dots:after {
  content: "\10f4a3";
}

.fad.fa-messaging:after,
.fa-duotone.fa-messaging:after {
  content: "\10f4a3";
}

.fad.fa-message-exclamation:after,
.fa-duotone.fa-message-exclamation:after {
  content: "\10f4a5";
}

.fad.fa-comment-alt-exclamation:after,
.fa-duotone.fa-comment-alt-exclamation:after {
  content: "\10f4a5";
}

.fad.fa-message-image:after,
.fa-duotone.fa-message-image:after {
  content: "\10e1e0";
}

.fad.fa-comment-alt-image:after,
.fa-duotone.fa-comment-alt-image:after {
  content: "\10e1e0";
}

.fad.fa-message-lines:after,
.fa-duotone.fa-message-lines:after {
  content: "\10f4a6";
}

.fad.fa-comment-alt-lines:after,
.fa-duotone.fa-comment-alt-lines:after {
  content: "\10f4a6";
}

.fad.fa-message-medical:after,
.fa-duotone.fa-message-medical:after {
  content: "\10f7f4";
}

.fad.fa-comment-alt-medical:after,
.fa-duotone.fa-comment-alt-medical:after {
  content: "\10f7f4";
}

.fad.fa-message-middle:after,
.fa-duotone.fa-message-middle:after {
  content: "\10e1e1";
}

.fad.fa-comment-middle-alt:after,
.fa-duotone.fa-comment-middle-alt:after {
  content: "\10e1e1";
}

.fad.fa-message-middle-top:after,
.fa-duotone.fa-message-middle-top:after {
  content: "\10e1e2";
}

.fad.fa-comment-middle-top-alt:after,
.fa-duotone.fa-comment-middle-top-alt:after {
  content: "\10e1e2";
}

.fad.fa-message-minus:after,
.fa-duotone.fa-message-minus:after {
  content: "\10f4a7";
}

.fad.fa-comment-alt-minus:after,
.fa-duotone.fa-comment-alt-minus:after {
  content: "\10f4a7";
}

.fad.fa-message-music:after,
.fa-duotone.fa-message-music:after {
  content: "\10f8af";
}

.fad.fa-comment-alt-music:after,
.fa-duotone.fa-comment-alt-music:after {
  content: "\10f8af";
}

.fad.fa-message-pen:after,
.fa-duotone.fa-message-pen:after {
  content: "\10f4a4";
}

.fad.fa-comment-alt-edit:after,
.fa-duotone.fa-comment-alt-edit:after {
  content: "\10f4a4";
}

.fad.fa-message-edit:after,
.fa-duotone.fa-message-edit:after {
  content: "\10f4a4";
}

.fad.fa-message-plus:after,
.fa-duotone.fa-message-plus:after {
  content: "\10f4a8";
}

.fad.fa-comment-alt-plus:after,
.fa-duotone.fa-comment-alt-plus:after {
  content: "\10f4a8";
}

.fad.fa-message-question:after,
.fa-duotone.fa-message-question:after {
  content: "\10e1e3";
}

.fad.fa-message-quote:after,
.fa-duotone.fa-message-quote:after {
  content: "\10e1e4";
}

.fad.fa-comment-alt-quote:after,
.fa-duotone.fa-comment-alt-quote:after {
  content: "\10e1e4";
}

.fad.fa-message-slash:after,
.fa-duotone.fa-message-slash:after {
  content: "\10f4a9";
}

.fad.fa-comment-alt-slash:after,
.fa-duotone.fa-comment-alt-slash:after {
  content: "\10f4a9";
}

.fad.fa-message-smile:after,
.fa-duotone.fa-message-smile:after {
  content: "\10f4aa";
}

.fad.fa-comment-alt-smile:after,
.fa-duotone.fa-comment-alt-smile:after {
  content: "\10f4aa";
}

.fad.fa-message-sms:after,
.fa-duotone.fa-message-sms:after {
  content: "\10e1e5";
}

.fad.fa-message-text:after,
.fa-duotone.fa-message-text:after {
  content: "\10e1e6";
}

.fad.fa-comment-alt-text:after,
.fa-duotone.fa-comment-alt-text:after {
  content: "\10e1e6";
}

.fad.fa-message-xmark:after,
.fa-duotone.fa-message-xmark:after {
  content: "\10f4ab";
}

.fad.fa-comment-alt-times:after,
.fa-duotone.fa-comment-alt-times:after {
  content: "\10f4ab";
}

.fad.fa-message-times:after,
.fa-duotone.fa-message-times:after {
  content: "\10f4ab";
}

.fad.fa-messages:after,
.fa-duotone.fa-messages:after {
  content: "\10f4b6";
}

.fad.fa-comments-alt:after,
.fa-duotone.fa-comments-alt:after {
  content: "\10f4b6";
}

.fad.fa-messages-dollar:after,
.fa-duotone.fa-messages-dollar:after {
  content: "\10f652";
}

.fad.fa-comments-alt-dollar:after,
.fa-duotone.fa-comments-alt-dollar:after {
  content: "\10f652";
}

.fad.fa-messages-question:after,
.fa-duotone.fa-messages-question:after {
  content: "\10e1e7";
}

.fad.fa-meteor:after,
.fa-duotone.fa-meteor:after {
  content: "\10f753";
}

.fad.fa-meter:after,
.fa-duotone.fa-meter:after {
  content: "\10e1e8";
}

.fad.fa-meter-bolt:after,
.fa-duotone.fa-meter-bolt:after {
  content: "\10e1e9";
}

.fad.fa-meter-droplet:after,
.fa-duotone.fa-meter-droplet:after {
  content: "\10e1ea";
}

.fad.fa-meter-fire:after,
.fa-duotone.fa-meter-fire:after {
  content: "\10e1eb";
}

.fad.fa-microchip:after,
.fa-duotone.fa-microchip:after {
  content: "\10f2db";
}

.fad.fa-microchip-ai:after,
.fa-duotone.fa-microchip-ai:after {
  content: "\10e1ec";
}

.fad.fa-microphone:after,
.fa-duotone.fa-microphone:after {
  content: "\10f130";
}

.fad.fa-microphone-lines:after,
.fa-duotone.fa-microphone-lines:after {
  content: "\10f3c9";
}

.fad.fa-microphone-alt:after,
.fa-duotone.fa-microphone-alt:after {
  content: "\10f3c9";
}

.fad.fa-microphone-lines-slash:after,
.fa-duotone.fa-microphone-lines-slash:after {
  content: "\10f539";
}

.fad.fa-microphone-alt-slash:after,
.fa-duotone.fa-microphone-alt-slash:after {
  content: "\10f539";
}

.fad.fa-microphone-slash:after,
.fa-duotone.fa-microphone-slash:after {
  content: "\10f131";
}

.fad.fa-microphone-stand:after,
.fa-duotone.fa-microphone-stand:after {
  content: "\10f8cb";
}

.fad.fa-microscope:after,
.fa-duotone.fa-microscope:after {
  content: "\10f610";
}

.fad.fa-microwave:after,
.fa-duotone.fa-microwave:after {
  content: "\10e01b";
}

.fad.fa-mill-sign:after,
.fa-duotone.fa-mill-sign:after {
  content: "\10e1ed";
}

.fad.fa-minimize:after,
.fa-duotone.fa-minimize:after {
  content: "\10f78c";
}

.fad.fa-compress-arrows-alt:after,
.fa-duotone.fa-compress-arrows-alt:after {
  content: "\10f78c";
}

.fad.fa-minus:after,
.fa-duotone.fa-minus:after {
  content: "\10f068";
}

.fad.fa-subtract:after,
.fa-duotone.fa-subtract:after {
  content: "\10f068";
}

.fad.fa-mistletoe:after,
.fa-duotone.fa-mistletoe:after {
  content: "\10f7b4";
}

.fad.fa-mitten:after,
.fa-duotone.fa-mitten:after {
  content: "\10f7b5";
}

.fad.fa-mobile:after,
.fa-duotone.fa-mobile:after {
  content: "\10f3ce";
}

.fad.fa-mobile-android:after,
.fa-duotone.fa-mobile-android:after {
  content: "\10f3ce";
}

.fad.fa-mobile-phone:after,
.fa-duotone.fa-mobile-phone:after {
  content: "\10f3ce";
}

.fad.fa-mobile-button:after,
.fa-duotone.fa-mobile-button:after {
  content: "\10f10b";
}

.fad.fa-mobile-notch:after,
.fa-duotone.fa-mobile-notch:after {
  content: "\10e1ee";
}

.fad.fa-mobile-iphone:after,
.fa-duotone.fa-mobile-iphone:after {
  content: "\10e1ee";
}

.fad.fa-mobile-screen:after,
.fa-duotone.fa-mobile-screen:after {
  content: "\10f3cf";
}

.fad.fa-mobile-android-alt:after,
.fa-duotone.fa-mobile-android-alt:after {
  content: "\10f3cf";
}

.fad.fa-mobile-screen-button:after,
.fa-duotone.fa-mobile-screen-button:after {
  content: "\10f3cd";
}

.fad.fa-mobile-alt:after,
.fa-duotone.fa-mobile-alt:after {
  content: "\10f3cd";
}

.fad.fa-mobile-signal:after,
.fa-duotone.fa-mobile-signal:after {
  content: "\10e1ef";
}

.fad.fa-mobile-signal-out:after,
.fa-duotone.fa-mobile-signal-out:after {
  content: "\10e1f0";
}

.fad.fa-money-bill:after,
.fa-duotone.fa-money-bill:after {
  content: "\10f0d6";
}

.fad.fa-money-bill-1:after,
.fa-duotone.fa-money-bill-1:after {
  content: "\10f3d1";
}

.fad.fa-money-bill-alt:after,
.fa-duotone.fa-money-bill-alt:after {
  content: "\10f3d1";
}

.fad.fa-money-bill-1-wave:after,
.fa-duotone.fa-money-bill-1-wave:after {
  content: "\10f53b";
}

.fad.fa-money-bill-wave-alt:after,
.fa-duotone.fa-money-bill-wave-alt:after {
  content: "\10f53b";
}

.fad.fa-money-bill-simple:after,
.fa-duotone.fa-money-bill-simple:after {
  content: "\10e1f1";
}

.fad.fa-money-bill-simple-wave:after,
.fa-duotone.fa-money-bill-simple-wave:after {
  content: "\10e1f2";
}

.fad.fa-money-bill-wave:after,
.fa-duotone.fa-money-bill-wave:after {
  content: "\10f53a";
}

.fad.fa-money-bills:after,
.fa-duotone.fa-money-bills:after {
  content: "\10e1f3";
}

.fad.fa-money-bills-simple:after,
.fa-duotone.fa-money-bills-simple:after {
  content: "\10e1f4";
}

.fad.fa-money-bills-alt:after,
.fa-duotone.fa-money-bills-alt:after {
  content: "\10e1f4";
}

.fad.fa-money-check:after,
.fa-duotone.fa-money-check:after {
  content: "\10f53c";
}

.fad.fa-money-check-dollar:after,
.fa-duotone.fa-money-check-dollar:after {
  content: "\10f53d";
}

.fad.fa-money-check-alt:after,
.fa-duotone.fa-money-check-alt:after {
  content: "\10f53d";
}

.fad.fa-money-check-dollar-pen:after,
.fa-duotone.fa-money-check-dollar-pen:after {
  content: "\10f873";
}

.fad.fa-money-check-edit-alt:after,
.fa-duotone.fa-money-check-edit-alt:after {
  content: "\10f873";
}

.fad.fa-money-check-pen:after,
.fa-duotone.fa-money-check-pen:after {
  content: "\10f872";
}

.fad.fa-money-check-edit:after,
.fa-duotone.fa-money-check-edit:after {
  content: "\10f872";
}

.fad.fa-money-from-bracket:after,
.fa-duotone.fa-money-from-bracket:after {
  content: "\10e312";
}

.fad.fa-money-simple-from-bracket:after,
.fa-duotone.fa-money-simple-from-bracket:after {
  content: "\10e313";
}

.fad.fa-monitor-waveform:after,
.fa-duotone.fa-monitor-waveform:after {
  content: "\10f611";
}

.fad.fa-monitor-heart-rate:after,
.fa-duotone.fa-monitor-heart-rate:after {
  content: "\10f611";
}

.fad.fa-monkey:after,
.fa-duotone.fa-monkey:after {
  content: "\10f6fb";
}

.fad.fa-monument:after,
.fa-duotone.fa-monument:after {
  content: "\10f5a6";
}

.fad.fa-moon:after,
.fa-duotone.fa-moon:after {
  content: "\10f186";
}

.fad.fa-moon-cloud:after,
.fa-duotone.fa-moon-cloud:after {
  content: "\10f754";
}

.fad.fa-moon-over-sun:after,
.fa-duotone.fa-moon-over-sun:after {
  content: "\10f74a";
}

.fad.fa-eclipse-alt:after,
.fa-duotone.fa-eclipse-alt:after {
  content: "\10f74a";
}

.fad.fa-moon-stars:after,
.fa-duotone.fa-moon-stars:after {
  content: "\10f755";
}

.fad.fa-mortar-pestle:after,
.fa-duotone.fa-mortar-pestle:after {
  content: "\10f5a7";
}

.fad.fa-mosque:after,
.fa-duotone.fa-mosque:after {
  content: "\10f678";
}

.fad.fa-motorcycle:after,
.fa-duotone.fa-motorcycle:after {
  content: "\10f21c";
}

.fad.fa-mountain:after,
.fa-duotone.fa-mountain:after {
  content: "\10f6fc";
}

.fad.fa-mountains:after,
.fa-duotone.fa-mountains:after {
  content: "\10f6fd";
}

.fad.fa-mp3-player:after,
.fa-duotone.fa-mp3-player:after {
  content: "\10f8ce";
}

.fad.fa-mug:after,
.fa-duotone.fa-mug:after {
  content: "\10f874";
}

.fad.fa-mug-hot:after,
.fa-duotone.fa-mug-hot:after {
  content: "\10f7b6";
}

.fad.fa-mug-marshmallows:after,
.fa-duotone.fa-mug-marshmallows:after {
  content: "\10f7b7";
}

.fad.fa-mug-saucer:after,
.fa-duotone.fa-mug-saucer:after {
  content: "\10f0f4";
}

.fad.fa-coffee:after,
.fa-duotone.fa-coffee:after {
  content: "\10f0f4";
}

.fad.fa-mug-tea:after,
.fa-duotone.fa-mug-tea:after {
  content: "\10f875";
}

.fad.fa-mug-tea-saucer:after,
.fa-duotone.fa-mug-tea-saucer:after {
  content: "\10e1f5";
}

.fad.fa-music:after,
.fa-duotone.fa-music:after {
  content: "\10f001";
}

.fad.fa-music-note:after,
.fa-duotone.fa-music-note:after {
  content: "\10f8cf";
}

.fad.fa-music-alt:after,
.fa-duotone.fa-music-alt:after {
  content: "\10f8cf";
}

.fad.fa-music-note-slash:after,
.fa-duotone.fa-music-note-slash:after {
  content: "\10f8d0";
}

.fad.fa-music-alt-slash:after,
.fa-duotone.fa-music-alt-slash:after {
  content: "\10f8d0";
}

.fad.fa-music-slash:after,
.fa-duotone.fa-music-slash:after {
  content: "\10f8d1";
}

.fad.fa-n:after,
.fa-duotone.fa-n:after {
  content: "\10e314";
}

.fad.fa-naira-sign:after,
.fa-duotone.fa-naira-sign:after {
  content: "\10e1f6";
}

.fad.fa-narwhal:after,
.fa-duotone.fa-narwhal:after {
  content: "\10f6fe";
}

.fad.fa-network-wired:after,
.fa-duotone.fa-network-wired:after {
  content: "\10f6ff";
}

.fad.fa-neuter:after,
.fa-duotone.fa-neuter:after {
  content: "\10f22c";
}

.fad.fa-newspaper:after,
.fa-duotone.fa-newspaper:after {
  content: "\10f1ea";
}

.fad.fa-nfc:after,
.fa-duotone.fa-nfc:after {
  content: "\10e1f7";
}

.fad.fa-nfc-lock:after,
.fa-duotone.fa-nfc-lock:after {
  content: "\10e1f8";
}

.fad.fa-nfc-magnifying-glass:after,
.fa-duotone.fa-nfc-magnifying-glass:after {
  content: "\10e1f9";
}

.fad.fa-nfc-pen:after,
.fa-duotone.fa-nfc-pen:after {
  content: "\10e1fa";
}

.fad.fa-nfc-signal:after,
.fa-duotone.fa-nfc-signal:after {
  content: "\10e1fb";
}

.fad.fa-nfc-slash:after,
.fa-duotone.fa-nfc-slash:after {
  content: "\10e1fc";
}

.fad.fa-nfc-trash:after,
.fa-duotone.fa-nfc-trash:after {
  content: "\10e1fd";
}

.fad.fa-not-equal:after,
.fa-duotone.fa-not-equal:after {
  content: "\10f53e";
}

.fad.fa-notdef:after,
.fa-duotone.fa-notdef:after {
  content: "\10e1fe";
}

.fad.fa-note:after,
.fa-duotone.fa-note:after {
  content: "\10e1ff";
}

.fad.fa-note-medical:after,
.fa-duotone.fa-note-medical:after {
  content: "\10e200";
}

.fad.fa-note-sticky:after,
.fa-duotone.fa-note-sticky:after {
  content: "\10f249";
}

.fad.fa-sticky-note:after,
.fa-duotone.fa-sticky-note:after {
  content: "\10f249";
}

.fad.fa-notebook:after,
.fa-duotone.fa-notebook:after {
  content: "\10e201";
}

.fad.fa-notes:after,
.fa-duotone.fa-notes:after {
  content: "\10e202";
}

.fad.fa-notes-medical:after,
.fa-duotone.fa-notes-medical:after {
  content: "\10f481";
}

.fad.fa-o:after,
.fa-duotone.fa-o:after {
  content: "\10e315";
}

.fad.fa-object-group:after,
.fa-duotone.fa-object-group:after {
  content: "\10f247";
}

.fad.fa-object-ungroup:after,
.fa-duotone.fa-object-ungroup:after {
  content: "\10f248";
}

.fad.fa-octagon:after,
.fa-duotone.fa-octagon:after {
  content: "\10f306";
}

.fad.fa-octagon-divide:after,
.fa-duotone.fa-octagon-divide:after {
  content: "\10e203";
}

.fad.fa-octagon-exclamation:after,
.fa-duotone.fa-octagon-exclamation:after {
  content: "\10e204";
}

.fad.fa-octagon-minus:after,
.fa-duotone.fa-octagon-minus:after {
  content: "\10f308";
}

.fad.fa-minus-octagon:after,
.fa-duotone.fa-minus-octagon:after {
  content: "\10f308";
}

.fad.fa-octagon-plus:after,
.fa-duotone.fa-octagon-plus:after {
  content: "\10f301";
}

.fad.fa-plus-octagon:after,
.fa-duotone.fa-plus-octagon:after {
  content: "\10f301";
}

.fad.fa-octagon-xmark:after,
.fa-duotone.fa-octagon-xmark:after {
  content: "\10f2f0";
}

.fad.fa-times-octagon:after,
.fa-duotone.fa-times-octagon:after {
  content: "\10f2f0";
}

.fad.fa-xmark-octagon:after,
.fa-duotone.fa-xmark-octagon:after {
  content: "\10f2f0";
}

.fad.fa-oil-can:after,
.fa-duotone.fa-oil-can:after {
  content: "\10f613";
}

.fad.fa-oil-can-drip:after,
.fa-duotone.fa-oil-can-drip:after {
  content: "\10e205";
}

.fad.fa-oil-temperature:after,
.fa-duotone.fa-oil-temperature:after {
  content: "\10f614";
}

.fad.fa-oil-temp:after,
.fa-duotone.fa-oil-temp:after {
  content: "\10f614";
}

.fad.fa-olive:after,
.fa-duotone.fa-olive:after {
  content: "\10e316";
}

.fad.fa-olive-branch:after,
.fa-duotone.fa-olive-branch:after {
  content: "\10e317";
}

.fad.fa-om:after,
.fa-duotone.fa-om:after {
  content: "\10f679";
}

.fad.fa-omega:after,
.fa-duotone.fa-omega:after {
  content: "\10f67a";
}

.fad.fa-option:after,
.fa-duotone.fa-option:after {
  content: "\10e318";
}

.fad.fa-ornament:after,
.fa-duotone.fa-ornament:after {
  content: "\10f7b8";
}

.fad.fa-otter:after,
.fa-duotone.fa-otter:after {
  content: "\10f700";
}

.fad.fa-outdent:after,
.fa-duotone.fa-outdent:after {
  content: "\10f03b";
}

.fad.fa-dedent:after,
.fa-duotone.fa-dedent:after {
  content: "\10f03b";
}

.fad.fa-outlet:after,
.fa-duotone.fa-outlet:after {
  content: "\10e01c";
}

.fad.fa-oven:after,
.fa-duotone.fa-oven:after {
  content: "\10e01d";
}

.fad.fa-overline:after,
.fa-duotone.fa-overline:after {
  content: "\10f876";
}

.fad.fa-p:after,
.fa-duotone.fa-p:after {
  content: "\10e319";
}

.fad.fa-pager:after,
.fa-duotone.fa-pager:after {
  content: "\10f815";
}

.fad.fa-paint-brush:after,
.fa-duotone.fa-paint-brush:after {
  content: "\10f1fc";
}

.fad.fa-paint-brush-fine:after,
.fa-duotone.fa-paint-brush-fine:after {
  content: "\10f5a9";
}

.fad.fa-paint-brush-alt:after,
.fa-duotone.fa-paint-brush-alt:after {
  content: "\10f5a9";
}

.fad.fa-paint-roller:after,
.fa-duotone.fa-paint-roller:after {
  content: "\10f5aa";
}

.fad.fa-paintbrush-pencil:after,
.fa-duotone.fa-paintbrush-pencil:after {
  content: "\10e206";
}

.fad.fa-palette:after,
.fa-duotone.fa-palette:after {
  content: "\10f53f";
}

.fad.fa-pallet:after,
.fa-duotone.fa-pallet:after {
  content: "\10f482";
}

.fad.fa-pallet-box:after,
.fa-duotone.fa-pallet-box:after {
  content: "\10e208";
}

.fad.fa-pallet-boxes:after,
.fa-duotone.fa-pallet-boxes:after {
  content: "\10f483";
}

.fad.fa-pallet-alt:after,
.fa-duotone.fa-pallet-alt:after {
  content: "\10f483";
}

.fad.fa-palette-boxes:after,
.fa-duotone.fa-palette-boxes:after {
  content: "\10f483";
}

.fad.fa-panorama:after,
.fa-duotone.fa-panorama:after {
  content: "\10e209";
}

.fad.fa-paper-plane:after,
.fa-duotone.fa-paper-plane:after {
  content: "\10f1d8";
}

.fad.fa-paper-plane-top:after,
.fa-duotone.fa-paper-plane-top:after {
  content: "\10e20a";
}

.fad.fa-paper-plane-alt:after,
.fa-duotone.fa-paper-plane-alt:after {
  content: "\10e20a";
}

.fad.fa-send:after,
.fa-duotone.fa-send:after {
  content: "\10e20a";
}

.fad.fa-paperclip:after,
.fa-duotone.fa-paperclip:after {
  content: "\10f0c6";
}

.fad.fa-parachute-box:after,
.fa-duotone.fa-parachute-box:after {
  content: "\10f4cd";
}

.fad.fa-paragraph:after,
.fa-duotone.fa-paragraph:after {
  content: "\10f1dd";
}

.fad.fa-paragraph-left:after,
.fa-duotone.fa-paragraph-left:after {
  content: "\10f878";
}

.fad.fa-paragraph-rtl:after,
.fa-duotone.fa-paragraph-rtl:after {
  content: "\10f878";
}

.fad.fa-party-bell:after,
.fa-duotone.fa-party-bell:after {
  content: "\10e31a";
}

.fad.fa-party-horn:after,
.fa-duotone.fa-party-horn:after {
  content: "\10e31b";
}

.fad.fa-passport:after,
.fa-duotone.fa-passport:after {
  content: "\10f5ab";
}

.fad.fa-paste:after,
.fa-duotone.fa-paste:after {
  content: "\10f0ea";
}

.fad.fa-file-clipboard:after,
.fa-duotone.fa-file-clipboard:after {
  content: "\10f0ea";
}

.fad.fa-pause:after,
.fa-duotone.fa-pause:after {
  content: "\10f04c";
}

.fad.fa-paw:after,
.fa-duotone.fa-paw:after {
  content: "\10f1b0";
}

.fad.fa-paw-claws:after,
.fa-duotone.fa-paw-claws:after {
  content: "\10f702";
}

.fad.fa-paw-simple:after,
.fa-duotone.fa-paw-simple:after {
  content: "\10f701";
}

.fad.fa-paw-alt:after,
.fa-duotone.fa-paw-alt:after {
  content: "\10f701";
}

.fad.fa-peace:after,
.fa-duotone.fa-peace:after {
  content: "\10f67c";
}

.fad.fa-peach:after,
.fa-duotone.fa-peach:after {
  content: "\10e20b";
}

.fad.fa-peapod:after,
.fa-duotone.fa-peapod:after {
  content: "\10e31c";
}

.fad.fa-pear:after,
.fa-duotone.fa-pear:after {
  content: "\10e20c";
}

.fad.fa-pedestal:after,
.fa-duotone.fa-pedestal:after {
  content: "\10e20d";
}

.fad.fa-pegasus:after,
.fa-duotone.fa-pegasus:after {
  content: "\10f703";
}

.fad.fa-pen:after,
.fa-duotone.fa-pen:after {
  content: "\10f304";
}

.fad.fa-pen-circle:after,
.fa-duotone.fa-pen-circle:after {
  content: "\10e20e";
}

.fad.fa-pen-clip:after,
.fa-duotone.fa-pen-clip:after {
  content: "\10f305";
}

.fad.fa-pen-alt:after,
.fa-duotone.fa-pen-alt:after {
  content: "\10f305";
}

.fad.fa-pen-clip-slash:after,
.fa-duotone.fa-pen-clip-slash:after {
  content: "\10e20f";
}

.fad.fa-pen-alt-slash:after,
.fa-duotone.fa-pen-alt-slash:after {
  content: "\10e20f";
}

.fad.fa-pen-fancy:after,
.fa-duotone.fa-pen-fancy:after {
  content: "\10f5ac";
}

.fad.fa-pen-fancy-slash:after,
.fa-duotone.fa-pen-fancy-slash:after {
  content: "\10e210";
}

.fad.fa-pen-field:after,
.fa-duotone.fa-pen-field:after {
  content: "\10e211";
}

.fad.fa-pen-line:after,
.fa-duotone.fa-pen-line:after {
  content: "\10e212";
}

.fad.fa-pen-nib:after,
.fa-duotone.fa-pen-nib:after {
  content: "\10f5ad";
}

.fad.fa-pen-paintbrush:after,
.fa-duotone.fa-pen-paintbrush:after {
  content: "\10f618";
}

.fad.fa-pencil-paintbrush:after,
.fa-duotone.fa-pencil-paintbrush:after {
  content: "\10f618";
}

.fad.fa-pen-ruler:after,
.fa-duotone.fa-pen-ruler:after {
  content: "\10f5ae";
}

.fad.fa-pencil-ruler:after,
.fa-duotone.fa-pencil-ruler:after {
  content: "\10f5ae";
}

.fad.fa-pen-slash:after,
.fa-duotone.fa-pen-slash:after {
  content: "\10e213";
}

.fad.fa-pen-swirl:after,
.fa-duotone.fa-pen-swirl:after {
  content: "\10e214";
}

.fad.fa-pen-to-square:after,
.fa-duotone.fa-pen-to-square:after {
  content: "\10f044";
}

.fad.fa-edit:after,
.fa-duotone.fa-edit:after {
  content: "\10f044";
}

.fad.fa-pencil:after,
.fa-duotone.fa-pencil:after {
  content: "\10f040";
}

.fad.fa-pencil-alt:after,
.fa-duotone.fa-pencil-alt:after {
  content: "\10f040";
}

.fad.fa-pencil-slash:after,
.fa-duotone.fa-pencil-slash:after {
  content: "\10e215";
}

.fad.fa-people:after,
.fa-duotone.fa-people:after {
  content: "\10e216";
}

.fad.fa-people-arrows-left-right:after,
.fa-duotone.fa-people-arrows-left-right:after {
  content: "\10e068";
}

.fad.fa-people-arrows:after,
.fa-duotone.fa-people-arrows:after {
  content: "\10e068";
}

.fad.fa-people-carry-box:after,
.fa-duotone.fa-people-carry-box:after {
  content: "\10f4ce";
}

.fad.fa-people-carry:after,
.fa-duotone.fa-people-carry:after {
  content: "\10f4ce";
}

.fad.fa-people-dress:after,
.fa-duotone.fa-people-dress:after {
  content: "\10e217";
}

.fad.fa-people-dress-simple:after,
.fa-duotone.fa-people-dress-simple:after {
  content: "\10e218";
}

.fad.fa-people-pants:after,
.fa-duotone.fa-people-pants:after {
  content: "\10e219";
}

.fad.fa-people-pants-simple:after,
.fa-duotone.fa-people-pants-simple:after {
  content: "\10e21a";
}

.fad.fa-people-simple:after,
.fa-duotone.fa-people-simple:after {
  content: "\10e21b";
}

.fad.fa-pepper-hot:after,
.fa-duotone.fa-pepper-hot:after {
  content: "\10f816";
}

.fad.fa-percent:after,
.fa-duotone.fa-percent:after {
  content: "\10f295";
}

.fad.fa-percentage:after,
.fa-duotone.fa-percentage:after {
  content: "\10f295";
}

.fad.fa-period:after,
.fa-duotone.fa-period:after {
  content: "\10e31d";
}

.fad.fa-person:after,
.fa-duotone.fa-person:after {
  content: "\10f183";
}

.fad.fa-male:after,
.fa-duotone.fa-male:after {
  content: "\10f183";
}

.fad.fa-person-biking:after,
.fa-duotone.fa-person-biking:after {
  content: "\10f84a";
}

.fad.fa-biking:after,
.fa-duotone.fa-biking:after {
  content: "\10f84a";
}

.fad.fa-person-biking-mountain:after,
.fa-duotone.fa-person-biking-mountain:after {
  content: "\10f84b";
}

.fad.fa-biking-mountain:after,
.fa-duotone.fa-biking-mountain:after {
  content: "\10f84b";
}

.fad.fa-person-booth:after,
.fa-duotone.fa-person-booth:after {
  content: "\10f756";
}

.fad.fa-person-carry-box:after,
.fa-duotone.fa-person-carry-box:after {
  content: "\10f4cf";
}

.fad.fa-person-carry:after,
.fa-duotone.fa-person-carry:after {
  content: "\10f4cf";
}

.fad.fa-person-digging:after,
.fa-duotone.fa-person-digging:after {
  content: "\10f85e";
}

.fad.fa-digging:after,
.fa-duotone.fa-digging:after {
  content: "\10f85e";
}

.fad.fa-person-dolly:after,
.fa-duotone.fa-person-dolly:after {
  content: "\10f4d0";
}

.fad.fa-person-dolly-empty:after,
.fa-duotone.fa-person-dolly-empty:after {
  content: "\10f4d1";
}

.fad.fa-person-dots-from-line:after,
.fa-duotone.fa-person-dots-from-line:after {
  content: "\10f470";
}

.fad.fa-diagnoses:after,
.fa-duotone.fa-diagnoses:after {
  content: "\10f470";
}

.fad.fa-person-dress:after,
.fa-duotone.fa-person-dress:after {
  content: "\10f182";
}

.fad.fa-female:after,
.fa-duotone.fa-female:after {
  content: "\10f182";
}

.fad.fa-person-dress-simple:after,
.fa-duotone.fa-person-dress-simple:after {
  content: "\10e21c";
}

.fad.fa-person-from-portal:after,
.fa-duotone.fa-person-from-portal:after {
  content: "\10e023";
}

.fad.fa-portal-exit:after,
.fa-duotone.fa-portal-exit:after {
  content: "\10e023";
}

.fad.fa-person-hiking:after,
.fa-duotone.fa-person-hiking:after {
  content: "\10f6ec";
}

.fad.fa-hiking:after,
.fa-duotone.fa-hiking:after {
  content: "\10f6ec";
}

.fad.fa-person-pinball:after,
.fa-duotone.fa-person-pinball:after {
  content: "\10e21d";
}

.fad.fa-person-praying:after,
.fa-duotone.fa-person-praying:after {
  content: "\10f683";
}

.fad.fa-pray:after,
.fa-duotone.fa-pray:after {
  content: "\10f683";
}

.fad.fa-person-pregnant:after,
.fa-duotone.fa-person-pregnant:after {
  content: "\10e31e";
}

.fad.fa-person-running:after,
.fa-duotone.fa-person-running:after {
  content: "\10f70c";
}

.fad.fa-running:after,
.fa-duotone.fa-running:after {
  content: "\10f70c";
}

.fad.fa-person-seat:after,
.fa-duotone.fa-person-seat:after {
  content: "\10e21e";
}

.fad.fa-person-seat-reclined:after,
.fa-duotone.fa-person-seat-reclined:after {
  content: "\10e21f";
}

.fad.fa-person-sign:after,
.fa-duotone.fa-person-sign:after {
  content: "\10f757";
}

.fad.fa-person-simple:after,
.fa-duotone.fa-person-simple:after {
  content: "\10e220";
}

.fad.fa-person-skating:after,
.fa-duotone.fa-person-skating:after {
  content: "\10f7c5";
}

.fad.fa-skating:after,
.fa-duotone.fa-skating:after {
  content: "\10f7c5";
}

.fad.fa-person-ski-jumping:after,
.fa-duotone.fa-person-ski-jumping:after {
  content: "\10f7c7";
}

.fad.fa-ski-jump:after,
.fa-duotone.fa-ski-jump:after {
  content: "\10f7c7";
}

.fad.fa-person-ski-lift:after,
.fa-duotone.fa-person-ski-lift:after {
  content: "\10f7c8";
}

.fad.fa-ski-lift:after,
.fa-duotone.fa-ski-lift:after {
  content: "\10f7c8";
}

.fad.fa-person-skiing:after,
.fa-duotone.fa-person-skiing:after {
  content: "\10f7c9";
}

.fad.fa-skiing:after,
.fa-duotone.fa-skiing:after {
  content: "\10f7c9";
}

.fad.fa-person-skiing-nordic:after,
.fa-duotone.fa-person-skiing-nordic:after {
  content: "\10f7ca";
}

.fad.fa-skiing-nordic:after,
.fa-duotone.fa-skiing-nordic:after {
  content: "\10f7ca";
}

.fad.fa-person-sledding:after,
.fa-duotone.fa-person-sledding:after {
  content: "\10f7cb";
}

.fad.fa-sledding:after,
.fa-duotone.fa-sledding:after {
  content: "\10f7cb";
}

.fad.fa-person-snowboarding:after,
.fa-duotone.fa-person-snowboarding:after {
  content: "\10f7ce";
}

.fad.fa-snowboarding:after,
.fa-duotone.fa-snowboarding:after {
  content: "\10f7ce";
}

.fad.fa-person-snowmobiling:after,
.fa-duotone.fa-person-snowmobiling:after {
  content: "\10f7d1";
}

.fad.fa-snowmobile:after,
.fa-duotone.fa-snowmobile:after {
  content: "\10f7d1";
}

.fad.fa-person-swimming:after,
.fa-duotone.fa-person-swimming:after {
  content: "\10f5c4";
}

.fad.fa-swimmer:after,
.fa-duotone.fa-swimmer:after {
  content: "\10f5c4";
}

.fad.fa-person-to-portal:after,
.fa-duotone.fa-person-to-portal:after {
  content: "\10e022";
}

.fad.fa-portal-enter:after,
.fa-duotone.fa-portal-enter:after {
  content: "\10e022";
}

.fad.fa-person-walking:after,
.fa-duotone.fa-person-walking:after {
  content: "\10f554";
}

.fad.fa-walking:after,
.fa-duotone.fa-walking:after {
  content: "\10f554";
}

.fad.fa-person-walking-with-cane:after,
.fa-duotone.fa-person-walking-with-cane:after {
  content: "\10f29d";
}

.fad.fa-blind:after,
.fa-duotone.fa-blind:after {
  content: "\10f29d";
}

.fad.fa-peseta-sign:after,
.fa-duotone.fa-peseta-sign:after {
  content: "\10e221";
}

.fad.fa-peso-sign:after,
.fa-duotone.fa-peso-sign:after {
  content: "\10e222";
}

.fad.fa-phone:after,
.fa-duotone.fa-phone:after {
  content: "\10f095";
}

.fad.fa-phone-arrow-down-left:after,
.fa-duotone.fa-phone-arrow-down-left:after {
  content: "\10e223";
}

.fad.fa-phone-arrow-down:after,
.fa-duotone.fa-phone-arrow-down:after {
  content: "\10e223";
}

.fad.fa-phone-incoming:after,
.fa-duotone.fa-phone-incoming:after {
  content: "\10e223";
}

.fad.fa-phone-arrow-up-right:after,
.fa-duotone.fa-phone-arrow-up-right:after {
  content: "\10e224";
}

.fad.fa-phone-arrow-up:after,
.fa-duotone.fa-phone-arrow-up:after {
  content: "\10e224";
}

.fad.fa-phone-outgoing:after,
.fa-duotone.fa-phone-outgoing:after {
  content: "\10e224";
}

.fad.fa-phone-flip:after,
.fa-duotone.fa-phone-flip:after {
  content: "\10f879";
}

.fad.fa-phone-alt:after,
.fa-duotone.fa-phone-alt:after {
  content: "\10f879";
}

.fad.fa-phone-hangup:after,
.fa-duotone.fa-phone-hangup:after {
  content: "\10e225";
}

.fad.fa-phone-missed:after,
.fa-duotone.fa-phone-missed:after {
  content: "\10e226";
}

.fad.fa-phone-office:after,
.fa-duotone.fa-phone-office:after {
  content: "\10f67d";
}

.fad.fa-phone-plus:after,
.fa-duotone.fa-phone-plus:after {
  content: "\10f4d2";
}

.fad.fa-phone-rotary:after,
.fa-duotone.fa-phone-rotary:after {
  content: "\10f8d3";
}

.fad.fa-phone-slash:after,
.fa-duotone.fa-phone-slash:after {
  content: "\10f3dd";
}

.fad.fa-phone-volume:after,
.fa-duotone.fa-phone-volume:after {
  content: "\10f2a0";
}

.fad.fa-volume-control-phone:after,
.fa-duotone.fa-volume-control-phone:after {
  content: "\10f2a0";
}

.fad.fa-phone-xmark:after,
.fa-duotone.fa-phone-xmark:after {
  content: "\10e227";
}

.fad.fa-photo-film:after,
.fa-duotone.fa-photo-film:after {
  content: "\10f87c";
}

.fad.fa-photo-video:after,
.fa-duotone.fa-photo-video:after {
  content: "\10f87c";
}

.fad.fa-photo-film-music:after,
.fa-duotone.fa-photo-film-music:after {
  content: "\10e228";
}

.fad.fa-pi:after,
.fa-duotone.fa-pi:after {
  content: "\10f67e";
}

.fad.fa-piano:after,
.fa-duotone.fa-piano:after {
  content: "\10f8d4";
}

.fad.fa-piano-keyboard:after,
.fa-duotone.fa-piano-keyboard:after {
  content: "\10f8d5";
}

.fad.fa-pie:after,
.fa-duotone.fa-pie:after {
  content: "\10f705";
}

.fad.fa-pig:after,
.fa-duotone.fa-pig:after {
  content: "\10f706";
}

.fad.fa-piggy-bank:after,
.fa-duotone.fa-piggy-bank:after {
  content: "\10f4d3";
}

.fad.fa-pills:after,
.fa-duotone.fa-pills:after {
  content: "\10f484";
}

.fad.fa-pinball:after,
.fa-duotone.fa-pinball:after {
  content: "\10e229";
}

.fad.fa-pineapple:after,
.fa-duotone.fa-pineapple:after {
  content: "\10e31f";
}

.fad.fa-pipe:after,
.fa-duotone.fa-pipe:after {
  content: "\10e22a";
}

.fad.fa-pizza:after,
.fa-duotone.fa-pizza:after {
  content: "\10f817";
}

.fad.fa-pizza-slice:after,
.fa-duotone.fa-pizza-slice:after {
  content: "\10f818";
}

.fad.fa-place-of-worship:after,
.fa-duotone.fa-place-of-worship:after {
  content: "\10f67f";
}

.fad.fa-plane:after,
.fa-duotone.fa-plane:after {
  content: "\10f072";
}

.fad.fa-plane-arrival:after,
.fa-duotone.fa-plane-arrival:after {
  content: "\10f5af";
}

.fad.fa-plane-departure:after,
.fa-duotone.fa-plane-departure:after {
  content: "\10f5b0";
}

.fad.fa-plane-engines:after,
.fa-duotone.fa-plane-engines:after {
  content: "\10f3de";
}

.fad.fa-plane-alt:after,
.fa-duotone.fa-plane-alt:after {
  content: "\10f3de";
}

.fad.fa-plane-prop:after,
.fa-duotone.fa-plane-prop:after {
  content: "\10e22b";
}

.fad.fa-plane-slash:after,
.fa-duotone.fa-plane-slash:after {
  content: "\10e069";
}

.fad.fa-plane-tail:after,
.fa-duotone.fa-plane-tail:after {
  content: "\10e22c";
}

.fad.fa-plane-up:after,
.fa-duotone.fa-plane-up:after {
  content: "\10e22d";
}

.fad.fa-plane-up-slash:after,
.fa-duotone.fa-plane-up-slash:after {
  content: "\10e22e";
}

.fad.fa-planet-moon:after,
.fa-duotone.fa-planet-moon:after {
  content: "\10e01f";
}

.fad.fa-planet-ringed:after,
.fa-duotone.fa-planet-ringed:after {
  content: "\10e020";
}

.fad.fa-play:after,
.fa-duotone.fa-play:after {
  content: "\10f04b";
}

.fad.fa-play-pause:after,
.fa-duotone.fa-play-pause:after {
  content: "\10e22f";
}

.fad.fa-plug:after,
.fa-duotone.fa-plug:after {
  content: "\10f1e6";
}

.fad.fa-plus:after,
.fa-duotone.fa-plus:after {
  content: "\10f067";
}

.fad.fa-add:after,
.fa-duotone.fa-add:after {
  content: "\10f067";
}

.fad.fa-plus-minus:after,
.fa-duotone.fa-plus-minus:after {
  content: "\10e230";
}

.fad.fa-podcast:after,
.fa-duotone.fa-podcast:after {
  content: "\10f2ce";
}

.fad.fa-podium:after,
.fa-duotone.fa-podium:after {
  content: "\10f680";
}

.fad.fa-podium-star:after,
.fa-duotone.fa-podium-star:after {
  content: "\10f758";
}

.fad.fa-police-box:after,
.fa-duotone.fa-police-box:after {
  content: "\10e021";
}

.fad.fa-poll-people:after,
.fa-duotone.fa-poll-people:after {
  content: "\10f759";
}

.fad.fa-poo:after,
.fa-duotone.fa-poo:after {
  content: "\10f2fe";
}

.fad.fa-poo-bolt:after,
.fa-duotone.fa-poo-bolt:after {
  content: "\10f75a";
}

.fad.fa-poo-storm:after,
.fa-duotone.fa-poo-storm:after {
  content: "\10f75a";
}

.fad.fa-poop:after,
.fa-duotone.fa-poop:after {
  content: "\10f619";
}

.fad.fa-popcorn:after,
.fa-duotone.fa-popcorn:after {
  content: "\10f819";
}

.fad.fa-power-off:after,
.fa-duotone.fa-power-off:after {
  content: "\10f011";
}

.fad.fa-prescription:after,
.fa-duotone.fa-prescription:after {
  content: "\10f5b1";
}

.fad.fa-prescription-bottle:after,
.fa-duotone.fa-prescription-bottle:after {
  content: "\10f485";
}

.fad.fa-prescription-bottle-medical:after,
.fa-duotone.fa-prescription-bottle-medical:after {
  content: "\10f486";
}

.fad.fa-prescription-bottle-alt:after,
.fa-duotone.fa-prescription-bottle-alt:after {
  content: "\10f486";
}

.fad.fa-presentation-screen:after,
.fa-duotone.fa-presentation-screen:after {
  content: "\10f685";
}

.fad.fa-presentation:after,
.fa-duotone.fa-presentation:after {
  content: "\10f685";
}

.fad.fa-print:after,
.fa-duotone.fa-print:after {
  content: "\10f02f";
}

.fad.fa-print-magnifying-glass:after,
.fa-duotone.fa-print-magnifying-glass:after {
  content: "\10f81a";
}

.fad.fa-print-search:after,
.fa-duotone.fa-print-search:after {
  content: "\10f81a";
}

.fad.fa-print-slash:after,
.fa-duotone.fa-print-slash:after {
  content: "\10f686";
}

.fad.fa-projector:after,
.fa-duotone.fa-projector:after {
  content: "\10f8d6";
}

.fad.fa-pump-medical:after,
.fa-duotone.fa-pump-medical:after {
  content: "\10e06a";
}

.fad.fa-pump-soap:after,
.fa-duotone.fa-pump-soap:after {
  content: "\10e06b";
}

.fad.fa-pumpkin:after,
.fa-duotone.fa-pumpkin:after {
  content: "\10f707";
}

.fad.fa-puzzle-piece:after,
.fa-duotone.fa-puzzle-piece:after {
  content: "\10f12e";
}

.fad.fa-puzzle-piece-simple:after,
.fa-duotone.fa-puzzle-piece-simple:after {
  content: "\10e231";
}

.fad.fa-puzzle-piece-alt:after,
.fa-duotone.fa-puzzle-piece-alt:after {
  content: "\10e231";
}

.fad.fa-q:after,
.fa-duotone.fa-q:after {
  content: "\10e320";
}

.fad.fa-qrcode:after,
.fa-duotone.fa-qrcode:after {
  content: "\10f029";
}

.fad.fa-question:after,
.fa-duotone.fa-question:after {
  content: "\10f128";
}

.fad.fa-quidditch-broom-ball:after,
.fa-duotone.fa-quidditch-broom-ball:after {
  content: "\10f458";
}

.fad.fa-broom-ball:after,
.fa-duotone.fa-broom-ball:after {
  content: "\10f458";
}

.fad.fa-quidditch:after,
.fa-duotone.fa-quidditch:after {
  content: "\10f458";
}

.fad.fa-quote-left:after,
.fa-duotone.fa-quote-left:after {
  content: "\10f10d";
}

.fad.fa-quote-left-alt:after,
.fa-duotone.fa-quote-left-alt:after {
  content: "\10f10d";
}

.fad.fa-quote-right:after,
.fa-duotone.fa-quote-right:after {
  content: "\10f10e";
}

.fad.fa-quote-right-alt:after,
.fa-duotone.fa-quote-right-alt:after {
  content: "\10f10e";
}

.fad.fa-quotes:after,
.fa-duotone.fa-quotes:after {
  content: "\10e234";
}

.fad.fa-r:after,
.fa-duotone.fa-r:after {
  content: "\10e321";
}

.fad.fa-rabbit:after,
.fa-duotone.fa-rabbit:after {
  content: "\10f708";
}

.fad.fa-rabbit-running:after,
.fa-duotone.fa-rabbit-running:after {
  content: "\10f709";
}

.fad.fa-rabbit-fast:after,
.fa-duotone.fa-rabbit-fast:after {
  content: "\10f709";
}

.fad.fa-racquet:after,
.fa-duotone.fa-racquet:after {
  content: "\10f45a";
}

.fad.fa-radar:after,
.fa-duotone.fa-radar:after {
  content: "\10e024";
}

.fad.fa-radiation:after,
.fa-duotone.fa-radiation:after {
  content: "\10f7b9";
}

.fad.fa-radio:after,
.fa-duotone.fa-radio:after {
  content: "\10f8d7";
}

.fad.fa-radio-tuner:after,
.fa-duotone.fa-radio-tuner:after {
  content: "\10f8d8";
}

.fad.fa-radio-alt:after,
.fa-duotone.fa-radio-alt:after {
  content: "\10f8d8";
}

.fad.fa-rainbow:after,
.fa-duotone.fa-rainbow:after {
  content: "\10f75b";
}

.fad.fa-raindrops:after,
.fa-duotone.fa-raindrops:after {
  content: "\10f75c";
}

.fad.fa-ram:after,
.fa-duotone.fa-ram:after {
  content: "\10f70a";
}

.fad.fa-ramp-loading:after,
.fa-duotone.fa-ramp-loading:after {
  content: "\10f4d4";
}

.fad.fa-raygun:after,
.fa-duotone.fa-raygun:after {
  content: "\10e025";
}

.fad.fa-receipt:after,
.fa-duotone.fa-receipt:after {
  content: "\10f543";
}

.fad.fa-record-vinyl:after,
.fa-duotone.fa-record-vinyl:after {
  content: "\10f8d9";
}

.fad.fa-rectangle:after,
.fa-duotone.fa-rectangle:after {
  content: "\10f2fa";
}

.fad.fa-rectangle-landscape:after,
.fa-duotone.fa-rectangle-landscape:after {
  content: "\10f2fa";
}

.fad.fa-rectangle-ad:after,
.fa-duotone.fa-rectangle-ad:after {
  content: "\10f641";
}

.fad.fa-ad:after,
.fa-duotone.fa-ad:after {
  content: "\10f641";
}

.fad.fa-rectangle-barcode:after,
.fa-duotone.fa-rectangle-barcode:after {
  content: "\10f463";
}

.fad.fa-barcode-alt:after,
.fa-duotone.fa-barcode-alt:after {
  content: "\10f463";
}

.fad.fa-rectangle-code:after,
.fa-duotone.fa-rectangle-code:after {
  content: "\10e322";
}

.fad.fa-rectangle-list:after,
.fa-duotone.fa-rectangle-list:after {
  content: "\10f022";
}

.fad.fa-list-alt:after,
.fa-duotone.fa-list-alt:after {
  content: "\10f022";
}

.fad.fa-rectangle-pro:after,
.fa-duotone.fa-rectangle-pro:after {
  content: "\10e235";
}

.fad.fa-pro:after,
.fa-duotone.fa-pro:after {
  content: "\10e235";
}

.fad.fa-rectangle-terminal:after,
.fa-duotone.fa-rectangle-terminal:after {
  content: "\10e236";
}

.fad.fa-rectangle-vertical:after,
.fa-duotone.fa-rectangle-vertical:after {
  content: "\10f2fb";
}

.fad.fa-rectangle-portrait:after,
.fa-duotone.fa-rectangle-portrait:after {
  content: "\10f2fb";
}

.fad.fa-rectangle-vertical-history:after,
.fa-duotone.fa-rectangle-vertical-history:after {
  content: "\10e237";
}

.fad.fa-rectangle-wide:after,
.fa-duotone.fa-rectangle-wide:after {
  content: "\10f2fc";
}

.fad.fa-rectangle-xmark:after,
.fa-duotone.fa-rectangle-xmark:after {
  content: "\10f410";
}

.fad.fa-rectangle-times:after,
.fa-duotone.fa-rectangle-times:after {
  content: "\10f410";
}

.fad.fa-times-rectangle:after,
.fa-duotone.fa-times-rectangle:after {
  content: "\10f410";
}

.fad.fa-window-close:after,
.fa-duotone.fa-window-close:after {
  content: "\10f410";
}

.fad.fa-rectangles-mixed:after,
.fa-duotone.fa-rectangles-mixed:after {
  content: "\10e323";
}

.fad.fa-recycle:after,
.fa-duotone.fa-recycle:after {
  content: "\10f1b8";
}

.fad.fa-reel:after,
.fa-duotone.fa-reel:after {
  content: "\10e238";
}

.fad.fa-refrigerator:after,
.fa-duotone.fa-refrigerator:after {
  content: "\10e026";
}

.fad.fa-registered:after,
.fa-duotone.fa-registered:after {
  content: "\10f25d";
}

.fad.fa-repeat:after,
.fa-duotone.fa-repeat:after {
  content: "\10f363";
}

.fad.fa-repeat-1:after,
.fa-duotone.fa-repeat-1:after {
  content: "\10f365";
}

.fad.fa-reply:after,
.fa-duotone.fa-reply:after {
  content: "\10f3e5";
}

.fad.fa-mail-reply:after,
.fa-duotone.fa-mail-reply:after {
  content: "\10f3e5";
}

.fad.fa-reply-all:after,
.fa-duotone.fa-reply-all:after {
  content: "\10f122";
}

.fad.fa-mail-reply-all:after,
.fa-duotone.fa-mail-reply-all:after {
  content: "\10f122";
}

.fad.fa-reply-clock:after,
.fa-duotone.fa-reply-clock:after {
  content: "\10e239";
}

.fad.fa-reply-time:after,
.fa-duotone.fa-reply-time:after {
  content: "\10e239";
}

.fad.fa-republican:after,
.fa-duotone.fa-republican:after {
  content: "\10f75e";
}

.fad.fa-restroom:after,
.fa-duotone.fa-restroom:after {
  content: "\10f7bd";
}

.fad.fa-restroom-simple:after,
.fa-duotone.fa-restroom-simple:after {
  content: "\10e23a";
}

.fad.fa-retweet:after,
.fa-duotone.fa-retweet:after {
  content: "\10f079";
}

.fad.fa-rhombus:after,
.fa-duotone.fa-rhombus:after {
  content: "\10e23b";
}

.fad.fa-ribbon:after,
.fa-duotone.fa-ribbon:after {
  content: "\10f4d6";
}

.fad.fa-right:after,
.fa-duotone.fa-right:after {
  content: "\10f356";
}

.fad.fa-arrow-alt-right:after,
.fa-duotone.fa-arrow-alt-right:after {
  content: "\10f356";
}

.fad.fa-right-from-bracket:after,
.fa-duotone.fa-right-from-bracket:after {
  content: "\10f2f5";
}

.fad.fa-sign-out-alt:after,
.fa-duotone.fa-sign-out-alt:after {
  content: "\10f2f5";
}

.fad.fa-right-from-line:after,
.fa-duotone.fa-right-from-line:after {
  content: "\10f347";
}

.fad.fa-arrow-alt-from-left:after,
.fa-duotone.fa-arrow-alt-from-left:after {
  content: "\10f347";
}

.fad.fa-right-left:after,
.fa-duotone.fa-right-left:after {
  content: "\10f362";
}

.fad.fa-exchange-alt:after,
.fa-duotone.fa-exchange-alt:after {
  content: "\10f362";
}

.fad.fa-right-long:after,
.fa-duotone.fa-right-long:after {
  content: "\10f30b";
}

.fad.fa-long-arrow-alt-right:after,
.fa-duotone.fa-long-arrow-alt-right:after {
  content: "\10f30b";
}

.fad.fa-right-to-bracket:after,
.fa-duotone.fa-right-to-bracket:after {
  content: "\10f2f6";
}

.fad.fa-sign-in-alt:after,
.fa-duotone.fa-sign-in-alt:after {
  content: "\10f2f6";
}

.fad.fa-right-to-line:after,
.fa-duotone.fa-right-to-line:after {
  content: "\10f34c";
}

.fad.fa-arrow-alt-to-right:after,
.fa-duotone.fa-arrow-alt-to-right:after {
  content: "\10f34c";
}

.fad.fa-ring:after,
.fa-duotone.fa-ring:after {
  content: "\10f70b";
}

.fad.fa-rings-wedding:after,
.fa-duotone.fa-rings-wedding:after {
  content: "\10f81b";
}

.fad.fa-road:after,
.fa-duotone.fa-road:after {
  content: "\10f018";
}

.fad.fa-robot:after,
.fa-duotone.fa-robot:after {
  content: "\10f544";
}

.fad.fa-rocket:after,
.fa-duotone.fa-rocket:after {
  content: "\10f135";
}

.fad.fa-rocket-launch:after,
.fa-duotone.fa-rocket-launch:after {
  content: "\10e027";
}

.fad.fa-roller-coaster:after,
.fa-duotone.fa-roller-coaster:after {
  content: "\10e324";
}

.fad.fa-rotate:after,
.fa-duotone.fa-rotate:after {
  content: "\10f2f1";
}

.fad.fa-sync-alt:after,
.fa-duotone.fa-sync-alt:after {
  content: "\10f2f1";
}

.fad.fa-rotate-exclamation:after,
.fa-duotone.fa-rotate-exclamation:after {
  content: "\10e23c";
}

.fad.fa-rotate-left:after,
.fa-duotone.fa-rotate-left:after {
  content: "\10f2ea";
}

.fad.fa-rotate-back:after,
.fa-duotone.fa-rotate-back:after {
  content: "\10f2ea";
}

.fad.fa-rotate-backward:after,
.fa-duotone.fa-rotate-backward:after {
  content: "\10f2ea";
}

.fad.fa-undo-alt:after,
.fa-duotone.fa-undo-alt:after {
  content: "\10f2ea";
}

.fad.fa-rotate-right:after,
.fa-duotone.fa-rotate-right:after {
  content: "\10f2f9";
}

.fad.fa-redo-alt:after,
.fa-duotone.fa-redo-alt:after {
  content: "\10f2f9";
}

.fad.fa-rotate-forward:after,
.fa-duotone.fa-rotate-forward:after {
  content: "\10f2f9";
}

.fad.fa-route:after,
.fa-duotone.fa-route:after {
  content: "\10f4d7";
}

.fad.fa-route-highway:after,
.fa-duotone.fa-route-highway:after {
  content: "\10f61a";
}

.fad.fa-route-interstate:after,
.fa-duotone.fa-route-interstate:after {
  content: "\10f61b";
}

.fad.fa-router:after,
.fa-duotone.fa-router:after {
  content: "\10f8da";
}

.fad.fa-rss:after,
.fa-duotone.fa-rss:after {
  content: "\10f09e";
}

.fad.fa-feed:after,
.fa-duotone.fa-feed:after {
  content: "\10f09e";
}

.fad.fa-ruble-sign:after,
.fa-duotone.fa-ruble-sign:after {
  content: "\10f158";
}

.fad.fa-rouble:after,
.fa-duotone.fa-rouble:after {
  content: "\10f158";
}

.fad.fa-rub:after,
.fa-duotone.fa-rub:after {
  content: "\10f158";
}

.fad.fa-ruble:after,
.fa-duotone.fa-ruble:after {
  content: "\10f158";
}

.fad.fa-ruler:after,
.fa-duotone.fa-ruler:after {
  content: "\10f545";
}

.fad.fa-ruler-combined:after,
.fa-duotone.fa-ruler-combined:after {
  content: "\10f546";
}

.fad.fa-ruler-horizontal:after,
.fa-duotone.fa-ruler-horizontal:after {
  content: "\10f547";
}

.fad.fa-ruler-triangle:after,
.fa-duotone.fa-ruler-triangle:after {
  content: "\10f61c";
}

.fad.fa-ruler-vertical:after,
.fa-duotone.fa-ruler-vertical:after {
  content: "\10f548";
}

.fad.fa-rupee-sign:after,
.fa-duotone.fa-rupee-sign:after {
  content: "\10f156";
}

.fad.fa-rupee:after,
.fa-duotone.fa-rupee:after {
  content: "\10f156";
}

.fad.fa-rupiah-sign:after,
.fa-duotone.fa-rupiah-sign:after {
  content: "\10e23d";
}

.fad.fa-rv:after,
.fa-duotone.fa-rv:after {
  content: "\10f7be";
}

.fad.fa-s:after,
.fa-duotone.fa-s:after {
  content: "\10e325";
}

.fad.fa-sack:after,
.fa-duotone.fa-sack:after {
  content: "\10f81c";
}

.fad.fa-sack-dollar:after,
.fa-duotone.fa-sack-dollar:after {
  content: "\10f81d";
}

.fad.fa-salad:after,
.fa-duotone.fa-salad:after {
  content: "\10f81e";
}

.fad.fa-bowl-salad:after,
.fa-duotone.fa-bowl-salad:after {
  content: "\10f81e";
}

.fad.fa-sandwich:after,
.fa-duotone.fa-sandwich:after {
  content: "\10f81f";
}

.fad.fa-satellite:after,
.fa-duotone.fa-satellite:after {
  content: "\10f7bf";
}

.fad.fa-satellite-dish:after,
.fa-duotone.fa-satellite-dish:after {
  content: "\10f7c0";
}

.fad.fa-sausage:after,
.fa-duotone.fa-sausage:after {
  content: "\10f820";
}

.fad.fa-saxophone:after,
.fa-duotone.fa-saxophone:after {
  content: "\10f8dc";
}

.fad.fa-saxophone-fire:after,
.fa-duotone.fa-saxophone-fire:after {
  content: "\10f8db";
}

.fad.fa-sax-hot:after,
.fa-duotone.fa-sax-hot:after {
  content: "\10f8db";
}

.fad.fa-scale-balanced:after,
.fa-duotone.fa-scale-balanced:after {
  content: "\10f24e";
}

.fad.fa-balance-scale:after,
.fa-duotone.fa-balance-scale:after {
  content: "\10f24e";
}

.fad.fa-scale-unbalanced:after,
.fa-duotone.fa-scale-unbalanced:after {
  content: "\10f515";
}

.fad.fa-balance-scale-left:after,
.fa-duotone.fa-balance-scale-left:after {
  content: "\10f515";
}

.fad.fa-scale-unbalanced-flip:after,
.fa-duotone.fa-scale-unbalanced-flip:after {
  content: "\10f516";
}

.fad.fa-balance-scale-right:after,
.fa-duotone.fa-balance-scale-right:after {
  content: "\10f516";
}

.fad.fa-scalpel:after,
.fa-duotone.fa-scalpel:after {
  content: "\10f61d";
}

.fad.fa-scalpel-line-dashed:after,
.fa-duotone.fa-scalpel-line-dashed:after {
  content: "\10f61e";
}

.fad.fa-scalpel-path:after,
.fa-duotone.fa-scalpel-path:after {
  content: "\10f61e";
}

.fad.fa-scanner:after,
.fa-duotone.fa-scanner:after {
  content: "\10f8f3";
}

.fad.fa-scanner-image:after,
.fa-duotone.fa-scanner-image:after {
  content: "\10f8f3";
}

.fad.fa-scanner-gun:after,
.fa-duotone.fa-scanner-gun:after {
  content: "\10f488";
}

.fad.fa-scanner-keyboard:after,
.fa-duotone.fa-scanner-keyboard:after {
  content: "\10f489";
}

.fad.fa-scanner-touchscreen:after,
.fa-duotone.fa-scanner-touchscreen:after {
  content: "\10f48a";
}

.fad.fa-scarecrow:after,
.fa-duotone.fa-scarecrow:after {
  content: "\10f70d";
}

.fad.fa-scarf:after,
.fa-duotone.fa-scarf:after {
  content: "\10f7c1";
}

.fad.fa-school:after,
.fa-duotone.fa-school:after {
  content: "\10f549";
}

.fad.fa-scissors:after,
.fa-duotone.fa-scissors:after {
  content: "\10f0c4";
}

.fad.fa-cut:after,
.fa-duotone.fa-cut:after {
  content: "\10f0c4";
}

.fad.fa-screen-users:after,
.fa-duotone.fa-screen-users:after {
  content: "\10f63d";
}

.fad.fa-users-class:after,
.fa-duotone.fa-users-class:after {
  content: "\10f63d";
}

.fad.fa-screencast:after,
.fa-duotone.fa-screencast:after {
  content: "\10e23e";
}

.fad.fa-screwdriver:after,
.fa-duotone.fa-screwdriver:after {
  content: "\10f54a";
}

.fad.fa-screwdriver-wrench:after,
.fa-duotone.fa-screwdriver-wrench:after {
  content: "\10f7d9";
}

.fad.fa-tools:after,
.fa-duotone.fa-tools:after {
  content: "\10f7d9";
}

.fad.fa-scribble:after,
.fa-duotone.fa-scribble:after {
  content: "\10e23f";
}

.fad.fa-scroll:after,
.fa-duotone.fa-scroll:after {
  content: "\10f70e";
}

.fad.fa-scroll-old:after,
.fa-duotone.fa-scroll-old:after {
  content: "\10f70f";
}

.fad.fa-scroll-torah:after,
.fa-duotone.fa-scroll-torah:after {
  content: "\10f6a0";
}

.fad.fa-torah:after,
.fa-duotone.fa-torah:after {
  content: "\10f6a0";
}

.fad.fa-scrubber:after,
.fa-duotone.fa-scrubber:after {
  content: "\10f2f8";
}

.fad.fa-scythe:after,
.fa-duotone.fa-scythe:after {
  content: "\10f710";
}

.fad.fa-sd-card:after,
.fa-duotone.fa-sd-card:after {
  content: "\10f7c2";
}

.fad.fa-sd-cards:after,
.fa-duotone.fa-sd-cards:after {
  content: "\10e240";
}

.fad.fa-seal:after,
.fa-duotone.fa-seal:after {
  content: "\10e241";
}

.fad.fa-seal-exclamation:after,
.fa-duotone.fa-seal-exclamation:after {
  content: "\10e242";
}

.fad.fa-seal-question:after,
.fa-duotone.fa-seal-question:after {
  content: "\10e243";
}

.fad.fa-seat-airline:after,
.fa-duotone.fa-seat-airline:after {
  content: "\10e244";
}

.fad.fa-section:after,
.fa-duotone.fa-section:after {
  content: "\10e245";
}

.fad.fa-seedling:after,
.fa-duotone.fa-seedling:after {
  content: "\10f4d8";
}

.fad.fa-sprout:after,
.fa-duotone.fa-sprout:after {
  content: "\10f4d8";
}

.fad.fa-semicolon:after,
.fa-duotone.fa-semicolon:after {
  content: "\10e326";
}

.fad.fa-send-back:after,
.fa-duotone.fa-send-back:after {
  content: "\10f87e";
}

.fad.fa-send-backward:after,
.fa-duotone.fa-send-backward:after {
  content: "\10f87f";
}

.fad.fa-sensor:after,
.fa-duotone.fa-sensor:after {
  content: "\10e028";
}

.fad.fa-sensor-cloud:after,
.fa-duotone.fa-sensor-cloud:after {
  content: "\10e02c";
}

.fad.fa-sensor-smoke:after,
.fa-duotone.fa-sensor-smoke:after {
  content: "\10e02c";
}

.fad.fa-sensor-fire:after,
.fa-duotone.fa-sensor-fire:after {
  content: "\10e02a";
}

.fad.fa-sensor-on:after,
.fa-duotone.fa-sensor-on:after {
  content: "\10e02b";
}

.fad.fa-sensor-triangle-exclamation:after,
.fa-duotone.fa-sensor-triangle-exclamation:after {
  content: "\10e029";
}

.fad.fa-sensor-alert:after,
.fa-duotone.fa-sensor-alert:after {
  content: "\10e029";
}

.fad.fa-server:after,
.fa-duotone.fa-server:after {
  content: "\10f233";
}

.fad.fa-shapes:after,
.fa-duotone.fa-shapes:after {
  content: "\10f61f";
}

.fad.fa-triangle-circle-square:after,
.fa-duotone.fa-triangle-circle-square:after {
  content: "\10f61f";
}

.fad.fa-share:after,
.fa-duotone.fa-share:after {
  content: "\10f064";
}

.fad.fa-arrow-turn-right:after,
.fa-duotone.fa-arrow-turn-right:after {
  content: "\10f064";
}

.fad.fa-mail-forward:after,
.fa-duotone.fa-mail-forward:after {
  content: "\10f064";
}

.fad.fa-share-all:after,
.fa-duotone.fa-share-all:after {
  content: "\10f367";
}

.fad.fa-arrows-turn-right:after,
.fa-duotone.fa-arrows-turn-right:after {
  content: "\10f367";
}

.fad.fa-share-from-square:after,
.fa-duotone.fa-share-from-square:after {
  content: "\10f14d";
}

.fad.fa-share-square:after,
.fa-duotone.fa-share-square:after {
  content: "\10f14d";
}

.fad.fa-share-nodes:after,
.fa-duotone.fa-share-nodes:after {
  content: "\10f1e0";
}

.fad.fa-share-alt:after,
.fa-duotone.fa-share-alt:after {
  content: "\10f1e0";
}

.fad.fa-sheep:after,
.fa-duotone.fa-sheep:after {
  content: "\10f711";
}

.fad.fa-shekel-sign:after,
.fa-duotone.fa-shekel-sign:after {
  content: "\10f20b";
}

.fad.fa-ils:after,
.fa-duotone.fa-ils:after {
  content: "\10f20b";
}

.fad.fa-shekel:after,
.fa-duotone.fa-shekel:after {
  content: "\10f20b";
}

.fad.fa-sheqel:after,
.fa-duotone.fa-sheqel:after {
  content: "\10f20b";
}

.fad.fa-sheqel-sign:after,
.fa-duotone.fa-sheqel-sign:after {
  content: "\10f20b";
}

.fad.fa-shelves:after,
.fa-duotone.fa-shelves:after {
  content: "\10f480";
}

.fad.fa-inventory:after,
.fa-duotone.fa-inventory:after {
  content: "\10f480";
}

.fad.fa-shelves-empty:after,
.fa-duotone.fa-shelves-empty:after {
  content: "\10e246";
}

.fad.fa-shield:after,
.fa-duotone.fa-shield:after {
  content: "\10f132";
}

.fad.fa-shield-blank:after,
.fa-duotone.fa-shield-blank:after {
  content: "\10f3ed";
}

.fad.fa-shield-alt:after,
.fa-duotone.fa-shield-alt:after {
  content: "\10f3ed";
}

.fad.fa-shield-check:after,
.fa-duotone.fa-shield-check:after {
  content: "\10f2f7";
}

.fad.fa-shield-cross:after,
.fa-duotone.fa-shield-cross:after {
  content: "\10f712";
}

.fad.fa-shield-exclamation:after,
.fa-duotone.fa-shield-exclamation:after {
  content: "\10e247";
}

.fad.fa-shield-keyhole:after,
.fa-duotone.fa-shield-keyhole:after {
  content: "\10e248";
}

.fad.fa-shield-minus:after,
.fa-duotone.fa-shield-minus:after {
  content: "\10e249";
}

.fad.fa-shield-plus:after,
.fa-duotone.fa-shield-plus:after {
  content: "\10e24a";
}

.fad.fa-shield-slash:after,
.fa-duotone.fa-shield-slash:after {
  content: "\10e24b";
}

.fad.fa-shield-virus:after,
.fa-duotone.fa-shield-virus:after {
  content: "\10e06c";
}

.fad.fa-shield-xmark:after,
.fa-duotone.fa-shield-xmark:after {
  content: "\10e24c";
}

.fad.fa-shield-times:after,
.fa-duotone.fa-shield-times:after {
  content: "\10e24c";
}

.fad.fa-ship:after,
.fa-duotone.fa-ship:after {
  content: "\10f21a";
}

.fad.fa-shish-kebab:after,
.fa-duotone.fa-shish-kebab:after {
  content: "\10f821";
}

.fad.fa-shoe-prints:after,
.fa-duotone.fa-shoe-prints:after {
  content: "\10f54b";
}

.fad.fa-shop:after,
.fa-duotone.fa-shop:after {
  content: "\10f54f";
}

.fad.fa-store-alt:after,
.fa-duotone.fa-store-alt:after {
  content: "\10f54f";
}

.fad.fa-shop-slash:after,
.fa-duotone.fa-shop-slash:after {
  content: "\10e070";
}

.fad.fa-store-alt-slash:after,
.fa-duotone.fa-store-alt-slash:after {
  content: "\10e070";
}

.fad.fa-shovel:after,
.fa-duotone.fa-shovel:after {
  content: "\10f713";
}

.fad.fa-shovel-snow:after,
.fa-duotone.fa-shovel-snow:after {
  content: "\10f7c3";
}

.fad.fa-shower:after,
.fa-duotone.fa-shower:after {
  content: "\10f2cc";
}

.fad.fa-shower-down:after,
.fa-duotone.fa-shower-down:after {
  content: "\10e24d";
}

.fad.fa-shower-alt:after,
.fa-duotone.fa-shower-alt:after {
  content: "\10e24d";
}

.fad.fa-shredder:after,
.fa-duotone.fa-shredder:after {
  content: "\10f68a";
}

.fad.fa-shuffle:after,
.fa-duotone.fa-shuffle:after {
  content: "\10f074";
}

.fad.fa-random:after,
.fa-duotone.fa-random:after {
  content: "\10f074";
}

.fad.fa-shuttle-space:after,
.fa-duotone.fa-shuttle-space:after {
  content: "\10f197";
}

.fad.fa-space-shuttle:after,
.fa-duotone.fa-space-shuttle:after {
  content: "\10f197";
}

.fad.fa-shuttlecock:after,
.fa-duotone.fa-shuttlecock:after {
  content: "\10f45b";
}

.fad.fa-sickle:after,
.fa-duotone.fa-sickle:after {
  content: "\10f822";
}

.fad.fa-sidebar:after,
.fa-duotone.fa-sidebar:after {
  content: "\10e24e";
}

.fad.fa-sidebar-flip:after,
.fa-duotone.fa-sidebar-flip:after {
  content: "\10e24f";
}

.fad.fa-sigma:after,
.fa-duotone.fa-sigma:after {
  content: "\10f68b";
}

.fad.fa-sign-hanging:after,
.fa-duotone.fa-sign-hanging:after {
  content: "\10f4d9";
}

.fad.fa-sign:after,
.fa-duotone.fa-sign:after {
  content: "\10f4d9";
}

.fad.fa-signal:after,
.fa-duotone.fa-signal:after {
  content: "\10f012";
}

.fad.fa-signal-5:after,
.fa-duotone.fa-signal-5:after {
  content: "\10f012";
}

.fad.fa-signal-perfect:after,
.fa-duotone.fa-signal-perfect:after {
  content: "\10f012";
}

.fad.fa-signal-bars:after,
.fa-duotone.fa-signal-bars:after {
  content: "\10f690";
}

.fad.fa-signal-alt:after,
.fa-duotone.fa-signal-alt:after {
  content: "\10f690";
}

.fad.fa-signal-alt-4:after,
.fa-duotone.fa-signal-alt-4:after {
  content: "\10f690";
}

.fad.fa-signal-bars-strong:after,
.fa-duotone.fa-signal-bars-strong:after {
  content: "\10f690";
}

.fad.fa-signal-bars-fair:after,
.fa-duotone.fa-signal-bars-fair:after {
  content: "\10f692";
}

.fad.fa-signal-alt-2:after,
.fa-duotone.fa-signal-alt-2:after {
  content: "\10f692";
}

.fad.fa-signal-bars-good:after,
.fa-duotone.fa-signal-bars-good:after {
  content: "\10f693";
}

.fad.fa-signal-alt-3:after,
.fa-duotone.fa-signal-alt-3:after {
  content: "\10f693";
}

.fad.fa-signal-bars-slash:after,
.fa-duotone.fa-signal-bars-slash:after {
  content: "\10f694";
}

.fad.fa-signal-alt-slash:after,
.fa-duotone.fa-signal-alt-slash:after {
  content: "\10f694";
}

.fad.fa-signal-bars-weak:after,
.fa-duotone.fa-signal-bars-weak:after {
  content: "\10f691";
}

.fad.fa-signal-alt-1:after,
.fa-duotone.fa-signal-alt-1:after {
  content: "\10f691";
}

.fad.fa-signal-fair:after,
.fa-duotone.fa-signal-fair:after {
  content: "\10f68d";
}

.fad.fa-signal-2:after,
.fa-duotone.fa-signal-2:after {
  content: "\10f68d";
}

.fad.fa-signal-good:after,
.fa-duotone.fa-signal-good:after {
  content: "\10f68e";
}

.fad.fa-signal-3:after,
.fa-duotone.fa-signal-3:after {
  content: "\10f68e";
}

.fad.fa-signal-slash:after,
.fa-duotone.fa-signal-slash:after {
  content: "\10f695";
}

.fad.fa-signal-stream:after,
.fa-duotone.fa-signal-stream:after {
  content: "\10f8dd";
}

.fad.fa-signal-stream-slash:after,
.fa-duotone.fa-signal-stream-slash:after {
  content: "\10e250";
}

.fad.fa-signal-strong:after,
.fa-duotone.fa-signal-strong:after {
  content: "\10f68f";
}

.fad.fa-signal-4:after,
.fa-duotone.fa-signal-4:after {
  content: "\10f68f";
}

.fad.fa-signal-weak:after,
.fa-duotone.fa-signal-weak:after {
  content: "\10f68c";
}

.fad.fa-signal-1:after,
.fa-duotone.fa-signal-1:after {
  content: "\10f68c";
}

.fad.fa-signature:after,
.fa-duotone.fa-signature:after {
  content: "\10f5b7";
}

.fad.fa-signs-post:after,
.fa-duotone.fa-signs-post:after {
  content: "\10f277";
}

.fad.fa-map-signs:after,
.fa-duotone.fa-map-signs:after {
  content: "\10f277";
}

.fad.fa-sim-card:after,
.fa-duotone.fa-sim-card:after {
  content: "\10f7c4";
}

.fad.fa-sim-cards:after,
.fa-duotone.fa-sim-cards:after {
  content: "\10e251";
}

.fad.fa-sink:after,
.fa-duotone.fa-sink:after {
  content: "\10e06d";
}

.fad.fa-siren:after,
.fa-duotone.fa-siren:after {
  content: "\10e02d";
}

.fad.fa-siren-on:after,
.fa-duotone.fa-siren-on:after {
  content: "\10e02e";
}

.fad.fa-sitemap:after,
.fa-duotone.fa-sitemap:after {
  content: "\10f0e8";
}

.fad.fa-skeleton:after,
.fa-duotone.fa-skeleton:after {
  content: "\10f620";
}

.fad.fa-skull:after,
.fa-duotone.fa-skull:after {
  content: "\10f54c";
}

.fad.fa-skull-cow:after,
.fa-duotone.fa-skull-cow:after {
  content: "\10f8de";
}

.fad.fa-skull-crossbones:after,
.fa-duotone.fa-skull-crossbones:after {
  content: "\10f714";
}

.fad.fa-slash:after,
.fa-duotone.fa-slash:after {
  content: "\10f715";
}

.fad.fa-slash-back:after,
.fa-duotone.fa-slash-back:after {
  content: "\10e327";
}

.fad.fa-slash-forward:after,
.fa-duotone.fa-slash-forward:after {
  content: "\10e328";
}

.fad.fa-sleigh:after,
.fa-duotone.fa-sleigh:after {
  content: "\10f7cc";
}

.fad.fa-slider:after,
.fa-duotone.fa-slider:after {
  content: "\10e252";
}

.fad.fa-sliders:after,
.fa-duotone.fa-sliders:after {
  content: "\10f1de";
}

.fad.fa-sliders-h:after,
.fa-duotone.fa-sliders-h:after {
  content: "\10f1de";
}

.fad.fa-sliders-simple:after,
.fa-duotone.fa-sliders-simple:after {
  content: "\10e253";
}

.fad.fa-sliders-up:after,
.fa-duotone.fa-sliders-up:after {
  content: "\10f3f1";
}

.fad.fa-sliders-v:after,
.fa-duotone.fa-sliders-v:after {
  content: "\10f3f1";
}

.fad.fa-smog:after,
.fa-duotone.fa-smog:after {
  content: "\10f75f";
}

.fad.fa-smoke:after,
.fa-duotone.fa-smoke:after {
  content: "\10f760";
}

.fad.fa-smoking:after,
.fa-duotone.fa-smoking:after {
  content: "\10f48d";
}

.fad.fa-snake:after,
.fa-duotone.fa-snake:after {
  content: "\10f716";
}

.fad.fa-snooze:after,
.fa-duotone.fa-snooze:after {
  content: "\10f880";
}

.fad.fa-zzz:after,
.fa-duotone.fa-zzz:after {
  content: "\10f880";
}

.fad.fa-snow-blowing:after,
.fa-duotone.fa-snow-blowing:after {
  content: "\10f761";
}

.fad.fa-snowflake:after,
.fa-duotone.fa-snowflake:after {
  content: "\10f2dc";
}

.fad.fa-snowflakes:after,
.fa-duotone.fa-snowflakes:after {
  content: "\10f7cf";
}

.fad.fa-snowman:after,
.fa-duotone.fa-snowman:after {
  content: "\10f7d0";
}

.fad.fa-snowman-head:after,
.fa-duotone.fa-snowman-head:after {
  content: "\10f79b";
}

.fad.fa-frosty-head:after,
.fa-duotone.fa-frosty-head:after {
  content: "\10f79b";
}

.fad.fa-snowplow:after,
.fa-duotone.fa-snowplow:after {
  content: "\10f7d2";
}

.fad.fa-soap:after,
.fa-duotone.fa-soap:after {
  content: "\10e06e";
}

.fad.fa-socks:after,
.fa-duotone.fa-socks:after {
  content: "\10f696";
}

.fad.fa-solar-panel:after,
.fa-duotone.fa-solar-panel:after {
  content: "\10f5ba";
}

.fad.fa-solar-system:after,
.fa-duotone.fa-solar-system:after {
  content: "\10e02f";
}

.fad.fa-sort:after,
.fa-duotone.fa-sort:after {
  content: "\10f0dc";
}

.fad.fa-unsorted:after,
.fa-duotone.fa-unsorted:after {
  content: "\10f0dc";
}

.fad.fa-sort-down:after,
.fa-duotone.fa-sort-down:after {
  content: "\10f0dd";
}

.fad.fa-sort-desc:after,
.fa-duotone.fa-sort-desc:after {
  content: "\10f0dd";
}

.fad.fa-sort-up:after,
.fa-duotone.fa-sort-up:after {
  content: "\10f0de";
}

.fad.fa-sort-asc:after,
.fa-duotone.fa-sort-asc:after {
  content: "\10f0de";
}

.fad.fa-spa:after,
.fa-duotone.fa-spa:after {
  content: "\10f5bb";
}

.fad.fa-space-station-moon:after,
.fa-duotone.fa-space-station-moon:after {
  content: "\10e033";
}

.fad.fa-space-station-moon-construction:after,
.fa-duotone.fa-space-station-moon-construction:after {
  content: "\10e034";
}

.fad.fa-space-station-moon-alt:after,
.fa-duotone.fa-space-station-moon-alt:after {
  content: "\10e034";
}

.fad.fa-spade:after,
.fa-duotone.fa-spade:after {
  content: "\10f2f4";
}

.fad.fa-spaghetti-monster-flying:after,
.fa-duotone.fa-spaghetti-monster-flying:after {
  content: "\10f67b";
}

.fad.fa-pastafarianism:after,
.fa-duotone.fa-pastafarianism:after {
  content: "\10f67b";
}

.fad.fa-sparkles:after,
.fa-duotone.fa-sparkles:after {
  content: "\10f890";
}

.fad.fa-speaker:after,
.fa-duotone.fa-speaker:after {
  content: "\10f8df";
}

.fad.fa-speakers:after,
.fa-duotone.fa-speakers:after {
  content: "\10f8e0";
}

.fad.fa-spell-check:after,
.fa-duotone.fa-spell-check:after {
  content: "\10f891";
}

.fad.fa-spider:after,
.fa-duotone.fa-spider:after {
  content: "\10f717";
}

.fad.fa-spider-black-widow:after,
.fa-duotone.fa-spider-black-widow:after {
  content: "\10f718";
}

.fad.fa-spider-web:after,
.fa-duotone.fa-spider-web:after {
  content: "\10f719";
}

.fad.fa-spinner:after,
.fa-duotone.fa-spinner:after {
  content: "\10f110";
}

.fad.fa-spinner-third:after,
.fa-duotone.fa-spinner-third:after {
  content: "\10f3f4";
}

.fad.fa-split:after,
.fa-duotone.fa-split:after {
  content: "\10e254";
}

.fad.fa-splotch:after,
.fa-duotone.fa-splotch:after {
  content: "\10f5bc";
}

.fad.fa-spoon:after,
.fa-duotone.fa-spoon:after {
  content: "\10f2e5";
}

.fad.fa-utensil-spoon:after,
.fa-duotone.fa-utensil-spoon:after {
  content: "\10f2e5";
}

.fad.fa-spray-can:after,
.fa-duotone.fa-spray-can:after {
  content: "\10f5bd";
}

.fad.fa-spray-can-sparkles:after,
.fa-duotone.fa-spray-can-sparkles:after {
  content: "\10f5d0";
}

.fad.fa-air-freshener:after,
.fa-duotone.fa-air-freshener:after {
  content: "\10f5d0";
}

.fad.fa-sprinkler:after,
.fa-duotone.fa-sprinkler:after {
  content: "\10e035";
}

.fad.fa-square:after,
.fa-duotone.fa-square:after {
  content: "\10f0c8";
}

.fad.fa-square-0:after,
.fa-duotone.fa-square-0:after {
  content: "\10e255";
}

.fad.fa-square-1:after,
.fa-duotone.fa-square-1:after {
  content: "\10e256";
}

.fad.fa-square-2:after,
.fa-duotone.fa-square-2:after {
  content: "\10e257";
}

.fad.fa-square-3:after,
.fa-duotone.fa-square-3:after {
  content: "\10e258";
}

.fad.fa-square-4:after,
.fa-duotone.fa-square-4:after {
  content: "\10e259";
}

.fad.fa-square-5:after,
.fa-duotone.fa-square-5:after {
  content: "\10e25a";
}

.fad.fa-square-6:after,
.fa-duotone.fa-square-6:after {
  content: "\10e25b";
}

.fad.fa-square-7:after,
.fa-duotone.fa-square-7:after {
  content: "\10e25c";
}

.fad.fa-square-8:after,
.fa-duotone.fa-square-8:after {
  content: "\10e25d";
}

.fad.fa-square-9:after,
.fa-duotone.fa-square-9:after {
  content: "\10e25e";
}

.fad.fa-square-a:after,
.fa-duotone.fa-square-a:after {
  content: "\10e25f";
}

.fad.fa-square-ampersand:after,
.fa-duotone.fa-square-ampersand:after {
  content: "\10e260";
}

.fad.fa-square-arrow-down:after,
.fa-duotone.fa-square-arrow-down:after {
  content: "\10f339";
}

.fad.fa-arrow-square-down:after,
.fa-duotone.fa-arrow-square-down:after {
  content: "\10f339";
}

.fad.fa-square-arrow-down-left:after,
.fa-duotone.fa-square-arrow-down-left:after {
  content: "\10e261";
}

.fad.fa-square-arrow-down-right:after,
.fa-duotone.fa-square-arrow-down-right:after {
  content: "\10e262";
}

.fad.fa-square-arrow-left:after,
.fa-duotone.fa-square-arrow-left:after {
  content: "\10f33a";
}

.fad.fa-arrow-square-left:after,
.fa-duotone.fa-arrow-square-left:after {
  content: "\10f33a";
}

.fad.fa-square-arrow-right:after,
.fa-duotone.fa-square-arrow-right:after {
  content: "\10f33b";
}

.fad.fa-arrow-square-right:after,
.fa-duotone.fa-arrow-square-right:after {
  content: "\10f33b";
}

.fad.fa-square-arrow-up:after,
.fa-duotone.fa-square-arrow-up:after {
  content: "\10f33c";
}

.fad.fa-arrow-square-up:after,
.fa-duotone.fa-arrow-square-up:after {
  content: "\10f33c";
}

.fad.fa-square-arrow-up-left:after,
.fa-duotone.fa-square-arrow-up-left:after {
  content: "\10e263";
}

.fad.fa-square-arrow-up-right:after,
.fa-duotone.fa-square-arrow-up-right:after {
  content: "\10f14c";
}

.fad.fa-external-link-square:after,
.fa-duotone.fa-external-link-square:after {
  content: "\10f14c";
}

.fad.fa-square-b:after,
.fa-duotone.fa-square-b:after {
  content: "\10e264";
}

.fad.fa-square-bolt:after,
.fa-duotone.fa-square-bolt:after {
  content: "\10e265";
}

.fad.fa-square-c:after,
.fa-duotone.fa-square-c:after {
  content: "\10e266";
}

.fad.fa-square-caret-down:after,
.fa-duotone.fa-square-caret-down:after {
  content: "\10f150";
}

.fad.fa-caret-square-down:after,
.fa-duotone.fa-caret-square-down:after {
  content: "\10f150";
}

.fad.fa-square-caret-left:after,
.fa-duotone.fa-square-caret-left:after {
  content: "\10f191";
}

.fad.fa-caret-square-left:after,
.fa-duotone.fa-caret-square-left:after {
  content: "\10f191";
}

.fad.fa-square-caret-right:after,
.fa-duotone.fa-square-caret-right:after {
  content: "\10f152";
}

.fad.fa-caret-square-right:after,
.fa-duotone.fa-caret-square-right:after {
  content: "\10f152";
}

.fad.fa-square-caret-up:after,
.fa-duotone.fa-square-caret-up:after {
  content: "\10f151";
}

.fad.fa-caret-square-up:after,
.fa-duotone.fa-caret-square-up:after {
  content: "\10f151";
}

.fad.fa-square-check:after,
.fa-duotone.fa-square-check:after {
  content: "\10f14a";
}

.fad.fa-check-square:after,
.fa-duotone.fa-check-square:after {
  content: "\10f14a";
}

.fad.fa-square-chevron-down:after,
.fa-duotone.fa-square-chevron-down:after {
  content: "\10f329";
}

.fad.fa-chevron-square-down:after,
.fa-duotone.fa-chevron-square-down:after {
  content: "\10f329";
}

.fad.fa-square-chevron-left:after,
.fa-duotone.fa-square-chevron-left:after {
  content: "\10f32a";
}

.fad.fa-chevron-square-left:after,
.fa-duotone.fa-chevron-square-left:after {
  content: "\10f32a";
}

.fad.fa-square-chevron-right:after,
.fa-duotone.fa-square-chevron-right:after {
  content: "\10f32b";
}

.fad.fa-chevron-square-right:after,
.fa-duotone.fa-chevron-square-right:after {
  content: "\10f32b";
}

.fad.fa-square-chevron-up:after,
.fa-duotone.fa-square-chevron-up:after {
  content: "\10f32c";
}

.fad.fa-chevron-square-up:after,
.fa-duotone.fa-chevron-square-up:after {
  content: "\10f32c";
}

.fad.fa-square-code:after,
.fa-duotone.fa-square-code:after {
  content: "\10e267";
}

.fad.fa-square-d:after,
.fa-duotone.fa-square-d:after {
  content: "\10e268";
}

.fad.fa-square-dashed:after,
.fa-duotone.fa-square-dashed:after {
  content: "\10e269";
}

.fad.fa-square-divide:after,
.fa-duotone.fa-square-divide:after {
  content: "\10e26a";
}

.fad.fa-square-dollar:after,
.fa-duotone.fa-square-dollar:after {
  content: "\10f2e9";
}

.fad.fa-dollar-square:after,
.fa-duotone.fa-dollar-square:after {
  content: "\10f2e9";
}

.fad.fa-usd-square:after,
.fa-duotone.fa-usd-square:after {
  content: "\10f2e9";
}

.fad.fa-square-down:after,
.fa-duotone.fa-square-down:after {
  content: "\10f350";
}

.fad.fa-arrow-alt-square-down:after,
.fa-duotone.fa-arrow-alt-square-down:after {
  content: "\10f350";
}

.fad.fa-square-down-left:after,
.fa-duotone.fa-square-down-left:after {
  content: "\10e26b";
}

.fad.fa-square-down-right:after,
.fa-duotone.fa-square-down-right:after {
  content: "\10e26c";
}

.fad.fa-square-e:after,
.fa-duotone.fa-square-e:after {
  content: "\10e26d";
}

.fad.fa-square-ellipsis:after,
.fa-duotone.fa-square-ellipsis:after {
  content: "\10e26e";
}

.fad.fa-square-ellipsis-vertical:after,
.fa-duotone.fa-square-ellipsis-vertical:after {
  content: "\10e26f";
}

.fad.fa-square-envelope:after,
.fa-duotone.fa-square-envelope:after {
  content: "\10f199";
}

.fad.fa-envelope-square:after,
.fa-duotone.fa-envelope-square:after {
  content: "\10f199";
}

.fad.fa-square-exclamation:after,
.fa-duotone.fa-square-exclamation:after {
  content: "\10f321";
}

.fad.fa-exclamation-square:after,
.fa-duotone.fa-exclamation-square:after {
  content: "\10f321";
}

.fad.fa-square-f:after,
.fa-duotone.fa-square-f:after {
  content: "\10e270";
}

.fad.fa-square-fragile:after,
.fa-duotone.fa-square-fragile:after {
  content: "\10f49b";
}

.fad.fa-box-fragile:after,
.fa-duotone.fa-box-fragile:after {
  content: "\10f49b";
}

.fad.fa-square-wine-glass-crack:after,
.fa-duotone.fa-square-wine-glass-crack:after {
  content: "\10f49b";
}

.fad.fa-square-full:after,
.fa-duotone.fa-square-full:after {
  content: "\10f45c";
}

.fad.fa-square-g:after,
.fa-duotone.fa-square-g:after {
  content: "\10e271";
}

.fad.fa-square-h:after,
.fa-duotone.fa-square-h:after {
  content: "\10f0fd";
}

.fad.fa-h-square:after,
.fa-duotone.fa-h-square:after {
  content: "\10f0fd";
}

.fad.fa-square-heart:after,
.fa-duotone.fa-square-heart:after {
  content: "\10f4c8";
}

.fad.fa-heart-square:after,
.fa-duotone.fa-heart-square:after {
  content: "\10f4c8";
}

.fad.fa-square-i:after,
.fa-duotone.fa-square-i:after {
  content: "\10e272";
}

.fad.fa-square-info:after,
.fa-duotone.fa-square-info:after {
  content: "\10f30f";
}

.fad.fa-info-square:after,
.fa-duotone.fa-info-square:after {
  content: "\10f30f";
}

.fad.fa-square-j:after,
.fa-duotone.fa-square-j:after {
  content: "\10e273";
}

.fad.fa-square-k:after,
.fa-duotone.fa-square-k:after {
  content: "\10e274";
}

.fad.fa-square-l:after,
.fa-duotone.fa-square-l:after {
  content: "\10e275";
}

.fad.fa-square-left:after,
.fa-duotone.fa-square-left:after {
  content: "\10f351";
}

.fad.fa-arrow-alt-square-left:after,
.fa-duotone.fa-arrow-alt-square-left:after {
  content: "\10f351";
}

.fad.fa-square-m:after,
.fa-duotone.fa-square-m:after {
  content: "\10e276";
}

.fad.fa-square-minus:after,
.fa-duotone.fa-square-minus:after {
  content: "\10f146";
}

.fad.fa-minus-square:after,
.fa-duotone.fa-minus-square:after {
  content: "\10f146";
}

.fad.fa-square-n:after,
.fa-duotone.fa-square-n:after {
  content: "\10e277";
}

.fad.fa-square-o:after,
.fa-duotone.fa-square-o:after {
  content: "\10e278";
}

.fad.fa-square-p:after,
.fa-duotone.fa-square-p:after {
  content: "\10e279";
}

.fad.fa-square-parking:after,
.fa-duotone.fa-square-parking:after {
  content: "\10f540";
}

.fad.fa-parking:after,
.fa-duotone.fa-parking:after {
  content: "\10f540";
}

.fad.fa-square-parking-slash:after,
.fa-duotone.fa-square-parking-slash:after {
  content: "\10f617";
}

.fad.fa-parking-slash:after,
.fa-duotone.fa-parking-slash:after {
  content: "\10f617";
}

.fad.fa-square-pen:after,
.fa-duotone.fa-square-pen:after {
  content: "\10f14b";
}

.fad.fa-pen-square:after,
.fa-duotone.fa-pen-square:after {
  content: "\10f14b";
}

.fad.fa-pencil-square:after,
.fa-duotone.fa-pencil-square:after {
  content: "\10f14b";
}

.fad.fa-square-phone:after,
.fa-duotone.fa-square-phone:after {
  content: "\10f098";
}

.fad.fa-phone-square:after,
.fa-duotone.fa-phone-square:after {
  content: "\10f098";
}

.fad.fa-square-phone-flip:after,
.fa-duotone.fa-square-phone-flip:after {
  content: "\10f87b";
}

.fad.fa-phone-square-alt:after,
.fa-duotone.fa-phone-square-alt:after {
  content: "\10f87b";
}

.fad.fa-square-phone-hangup:after,
.fa-duotone.fa-square-phone-hangup:after {
  content: "\10e27a";
}

.fad.fa-phone-square-down:after,
.fa-duotone.fa-phone-square-down:after {
  content: "\10e27a";
}

.fad.fa-square-plus:after,
.fa-duotone.fa-square-plus:after {
  content: "\10f0fe";
}

.fad.fa-plus-square:after,
.fa-duotone.fa-plus-square:after {
  content: "\10f0fe";
}

.fad.fa-square-poll-horizontal:after,
.fa-duotone.fa-square-poll-horizontal:after {
  content: "\10f682";
}

.fad.fa-poll-h:after,
.fa-duotone.fa-poll-h:after {
  content: "\10f682";
}

.fad.fa-square-poll-vertical:after,
.fa-duotone.fa-square-poll-vertical:after {
  content: "\10f681";
}

.fad.fa-poll:after,
.fa-duotone.fa-poll:after {
  content: "\10f681";
}

.fad.fa-square-q:after,
.fa-duotone.fa-square-q:after {
  content: "\10e27b";
}

.fad.fa-square-question:after,
.fa-duotone.fa-square-question:after {
  content: "\10f2fd";
}

.fad.fa-question-square:after,
.fa-duotone.fa-question-square:after {
  content: "\10f2fd";
}

.fad.fa-square-quote:after,
.fa-duotone.fa-square-quote:after {
  content: "\10e329";
}

.fad.fa-square-r:after,
.fa-duotone.fa-square-r:after {
  content: "\10e27c";
}

.fad.fa-square-right:after,
.fa-duotone.fa-square-right:after {
  content: "\10f352";
}

.fad.fa-arrow-alt-square-right:after,
.fa-duotone.fa-arrow-alt-square-right:after {
  content: "\10f352";
}

.fad.fa-square-root:after,
.fa-duotone.fa-square-root:after {
  content: "\10f697";
}

.fad.fa-square-root-variable:after,
.fa-duotone.fa-square-root-variable:after {
  content: "\10f698";
}

.fad.fa-square-root-alt:after,
.fa-duotone.fa-square-root-alt:after {
  content: "\10f698";
}

.fad.fa-square-rss:after,
.fa-duotone.fa-square-rss:after {
  content: "\10f143";
}

.fad.fa-rss-square:after,
.fa-duotone.fa-rss-square:after {
  content: "\10f143";
}

.fad.fa-square-s:after,
.fa-duotone.fa-square-s:after {
  content: "\10e27d";
}

.fad.fa-square-share-nodes:after,
.fa-duotone.fa-square-share-nodes:after {
  content: "\10f1e1";
}

.fad.fa-share-alt-square:after,
.fa-duotone.fa-share-alt-square:after {
  content: "\10f1e1";
}

.fad.fa-square-sliders:after,
.fa-duotone.fa-square-sliders:after {
  content: "\10f3f0";
}

.fad.fa-sliders-h-square:after,
.fa-duotone.fa-sliders-h-square:after {
  content: "\10f3f0";
}

.fad.fa-square-sliders-vertical:after,
.fa-duotone.fa-square-sliders-vertical:after {
  content: "\10f3f2";
}

.fad.fa-sliders-v-square:after,
.fa-duotone.fa-sliders-v-square:after {
  content: "\10f3f2";
}

.fad.fa-square-small:after,
.fa-duotone.fa-square-small:after {
  content: "\10e27e";
}

.fad.fa-square-star:after,
.fa-duotone.fa-square-star:after {
  content: "\10e27f";
}

.fad.fa-square-t:after,
.fa-duotone.fa-square-t:after {
  content: "\10e280";
}

.fad.fa-square-terminal:after,
.fa-duotone.fa-square-terminal:after {
  content: "\10e32a";
}

.fad.fa-square-this-way-up:after,
.fa-duotone.fa-square-this-way-up:after {
  content: "\10f49f";
}

.fad.fa-box-up:after,
.fa-duotone.fa-box-up:after {
  content: "\10f49f";
}

.fad.fa-square-u:after,
.fa-duotone.fa-square-u:after {
  content: "\10e281";
}

.fad.fa-square-up:after,
.fa-duotone.fa-square-up:after {
  content: "\10f353";
}

.fad.fa-arrow-alt-square-up:after,
.fa-duotone.fa-arrow-alt-square-up:after {
  content: "\10f353";
}

.fad.fa-square-up-left:after,
.fa-duotone.fa-square-up-left:after {
  content: "\10e282";
}

.fad.fa-square-up-right:after,
.fa-duotone.fa-square-up-right:after {
  content: "\10f360";
}

.fad.fa-external-link-square-alt:after,
.fa-duotone.fa-external-link-square-alt:after {
  content: "\10f360";
}

.fad.fa-square-user:after,
.fa-duotone.fa-square-user:after {
  content: "\10e283";
}

.fad.fa-square-v:after,
.fa-duotone.fa-square-v:after {
  content: "\10e284";
}

.fad.fa-square-w:after,
.fa-duotone.fa-square-w:after {
  content: "\10e285";
}

.fad.fa-square-x:after,
.fa-duotone.fa-square-x:after {
  content: "\10e286";
}

.fad.fa-square-xmark:after,
.fa-duotone.fa-square-xmark:after {
  content: "\10f2d3";
}

.fad.fa-times-square:after,
.fa-duotone.fa-times-square:after {
  content: "\10f2d3";
}

.fad.fa-xmark-square:after,
.fa-duotone.fa-xmark-square:after {
  content: "\10f2d3";
}

.fad.fa-square-y:after,
.fa-duotone.fa-square-y:after {
  content: "\10e287";
}

.fad.fa-square-z:after,
.fa-duotone.fa-square-z:after {
  content: "\10e288";
}

.fad.fa-squirrel:after,
.fa-duotone.fa-squirrel:after {
  content: "\10f71a";
}

.fad.fa-staff:after,
.fa-duotone.fa-staff:after {
  content: "\10f71b";
}

.fad.fa-stairs:after,
.fa-duotone.fa-stairs:after {
  content: "\10e289";
}

.fad.fa-stamp:after,
.fa-duotone.fa-stamp:after {
  content: "\10f5bf";
}

.fad.fa-standard-definition:after,
.fa-duotone.fa-standard-definition:after {
  content: "\10e28a";
}

.fad.fa-rectangle-sd:after,
.fa-duotone.fa-rectangle-sd:after {
  content: "\10e28a";
}

.fad.fa-star:after,
.fa-duotone.fa-star:after {
  content: "\10f005";
}

.fad.fa-star-and-crescent:after,
.fa-duotone.fa-star-and-crescent:after {
  content: "\10f699";
}

.fad.fa-star-christmas:after,
.fa-duotone.fa-star-christmas:after {
  content: "\10f7d4";
}

.fad.fa-star-exclamation:after,
.fa-duotone.fa-star-exclamation:after {
  content: "\10f2f3";
}

.fad.fa-star-half:after,
.fa-duotone.fa-star-half:after {
  content: "\10f089";
}

.fad.fa-star-half-stroke:after,
.fa-duotone.fa-star-half-stroke:after {
  content: "\10f5c0";
}

.fad.fa-star-half-alt:after,
.fa-duotone.fa-star-half-alt:after {
  content: "\10f5c0";
}

.fad.fa-star-of-david:after,
.fa-duotone.fa-star-of-david:after {
  content: "\10f69a";
}

.fad.fa-star-of-life:after,
.fa-duotone.fa-star-of-life:after {
  content: "\10f621";
}

.fad.fa-star-sharp:after,
.fa-duotone.fa-star-sharp:after {
  content: "\10e28b";
}

.fad.fa-star-sharp-half:after,
.fa-duotone.fa-star-sharp-half:after {
  content: "\10e28c";
}

.fad.fa-star-sharp-half-stroke:after,
.fa-duotone.fa-star-sharp-half-stroke:after {
  content: "\10e28d";
}

.fad.fa-star-sharp-half-alt:after,
.fa-duotone.fa-star-sharp-half-alt:after {
  content: "\10e28d";
}

.fad.fa-star-shooting:after,
.fa-duotone.fa-star-shooting:after {
  content: "\10e036";
}

.fad.fa-starfighter:after,
.fa-duotone.fa-starfighter:after {
  content: "\10e037";
}

.fad.fa-starfighter-twin-ion-engine:after,
.fa-duotone.fa-starfighter-twin-ion-engine:after {
  content: "\10e038";
}

.fad.fa-starfighter-alt:after,
.fa-duotone.fa-starfighter-alt:after {
  content: "\10e038";
}

.fad.fa-starfighter-twin-ion-engine-advanced:after,
.fa-duotone.fa-starfighter-twin-ion-engine-advanced:after {
  content: "\10e28e";
}

.fad.fa-starfighter-alt-advanced:after,
.fa-duotone.fa-starfighter-alt-advanced:after {
  content: "\10e28e";
}

.fad.fa-stars:after,
.fa-duotone.fa-stars:after {
  content: "\10f762";
}

.fad.fa-starship:after,
.fa-duotone.fa-starship:after {
  content: "\10e039";
}

.fad.fa-starship-freighter:after,
.fa-duotone.fa-starship-freighter:after {
  content: "\10e03a";
}

.fad.fa-steak:after,
.fa-duotone.fa-steak:after {
  content: "\10f824";
}

.fad.fa-steering-wheel:after,
.fa-duotone.fa-steering-wheel:after {
  content: "\10f622";
}

.fad.fa-sterling-sign:after,
.fa-duotone.fa-sterling-sign:after {
  content: "\10f154";
}

.fad.fa-gbp:after,
.fa-duotone.fa-gbp:after {
  content: "\10f154";
}

.fad.fa-pound-sign:after,
.fa-duotone.fa-pound-sign:after {
  content: "\10f154";
}

.fad.fa-stethoscope:after,
.fa-duotone.fa-stethoscope:after {
  content: "\10f0f1";
}

.fad.fa-stocking:after,
.fa-duotone.fa-stocking:after {
  content: "\10f7d5";
}

.fad.fa-stomach:after,
.fa-duotone.fa-stomach:after {
  content: "\10f623";
}

.fad.fa-stop:after,
.fa-duotone.fa-stop:after {
  content: "\10f04d";
}

.fad.fa-stopwatch:after,
.fa-duotone.fa-stopwatch:after {
  content: "\10f2f2";
}

.fad.fa-stopwatch-20:after,
.fa-duotone.fa-stopwatch-20:after {
  content: "\10e06f";
}

.fad.fa-store:after,
.fa-duotone.fa-store:after {
  content: "\10f54e";
}

.fad.fa-store-slash:after,
.fa-duotone.fa-store-slash:after {
  content: "\10e071";
}

.fad.fa-strawberry:after,
.fa-duotone.fa-strawberry:after {
  content: "\10e32b";
}

.fad.fa-street-view:after,
.fa-duotone.fa-street-view:after {
  content: "\10f21d";
}

.fad.fa-stretcher:after,
.fa-duotone.fa-stretcher:after {
  content: "\10f825";
}

.fad.fa-strikethrough:after,
.fa-duotone.fa-strikethrough:after {
  content: "\10f0cc";
}

.fad.fa-stroopwafel:after,
.fa-duotone.fa-stroopwafel:after {
  content: "\10f551";
}

.fad.fa-subscript:after,
.fa-duotone.fa-subscript:after {
  content: "\10f12c";
}

.fad.fa-suitcase:after,
.fa-duotone.fa-suitcase:after {
  content: "\10f0f2";
}

.fad.fa-suitcase-medical:after,
.fa-duotone.fa-suitcase-medical:after {
  content: "\10f0fa";
}

.fad.fa-medkit:after,
.fa-duotone.fa-medkit:after {
  content: "\10f0fa";
}

.fad.fa-suitcase-rolling:after,
.fa-duotone.fa-suitcase-rolling:after {
  content: "\10f5c1";
}

.fad.fa-sun:after,
.fa-duotone.fa-sun:after {
  content: "\10f185";
}

.fad.fa-sun-bright:after,
.fa-duotone.fa-sun-bright:after {
  content: "\10e28f";
}

.fad.fa-sun-alt:after,
.fa-duotone.fa-sun-alt:after {
  content: "\10e28f";
}

.fad.fa-sun-cloud:after,
.fa-duotone.fa-sun-cloud:after {
  content: "\10f763";
}

.fad.fa-sun-dust:after,
.fa-duotone.fa-sun-dust:after {
  content: "\10f764";
}

.fad.fa-sun-haze:after,
.fa-duotone.fa-sun-haze:after {
  content: "\10f765";
}

.fad.fa-sunglasses:after,
.fa-duotone.fa-sunglasses:after {
  content: "\10f892";
}

.fad.fa-sunrise:after,
.fa-duotone.fa-sunrise:after {
  content: "\10f766";
}

.fad.fa-sunset:after,
.fa-duotone.fa-sunset:after {
  content: "\10f767";
}

.fad.fa-superscript:after,
.fa-duotone.fa-superscript:after {
  content: "\10f12b";
}

.fad.fa-swatchbook:after,
.fa-duotone.fa-swatchbook:after {
  content: "\10f5c3";
}

.fad.fa-sword:after,
.fa-duotone.fa-sword:after {
  content: "\10f71c";
}

.fad.fa-sword-laser:after,
.fa-duotone.fa-sword-laser:after {
  content: "\10e03b";
}

.fad.fa-sword-laser-alt:after,
.fa-duotone.fa-sword-laser-alt:after {
  content: "\10e03c";
}

.fad.fa-swords:after,
.fa-duotone.fa-swords:after {
  content: "\10f71d";
}

.fad.fa-swords-laser:after,
.fa-duotone.fa-swords-laser:after {
  content: "\10e03d";
}

.fad.fa-symbols:after,
.fa-duotone.fa-symbols:after {
  content: "\10f86e";
}

.fad.fa-icons-alt:after,
.fa-duotone.fa-icons-alt:after {
  content: "\10f86e";
}

.fad.fa-synagogue:after,
.fa-duotone.fa-synagogue:after {
  content: "\10f69b";
}

.fad.fa-syringe:after,
.fa-duotone.fa-syringe:after {
  content: "\10f48e";
}

.fad.fa-t:after,
.fa-duotone.fa-t:after {
  content: "\10e32c";
}

.fad.fa-table:after,
.fa-duotone.fa-table:after {
  content: "\10f0ce";
}

.fad.fa-table-cells:after,
.fa-duotone.fa-table-cells:after {
  content: "\10f00a";
}

.fad.fa-th:after,
.fa-duotone.fa-th:after {
  content: "\10f00a";
}

.fad.fa-table-cells-large:after,
.fa-duotone.fa-table-cells-large:after {
  content: "\10f009";
}

.fad.fa-th-large:after,
.fa-duotone.fa-th-large:after {
  content: "\10f009";
}

.fad.fa-table-columns:after,
.fa-duotone.fa-table-columns:after {
  content: "\10f0db";
}

.fad.fa-columns:after,
.fa-duotone.fa-columns:after {
  content: "\10f0db";
}

.fad.fa-table-layout:after,
.fa-duotone.fa-table-layout:after {
  content: "\10e290";
}

.fad.fa-table-list:after,
.fa-duotone.fa-table-list:after {
  content: "\10f00b";
}

.fad.fa-th-list:after,
.fa-duotone.fa-th-list:after {
  content: "\10f00b";
}

.fad.fa-table-picnic:after,
.fa-duotone.fa-table-picnic:after {
  content: "\10e32d";
}

.fad.fa-table-pivot:after,
.fa-duotone.fa-table-pivot:after {
  content: "\10e291";
}

.fad.fa-table-rows:after,
.fa-duotone.fa-table-rows:after {
  content: "\10e292";
}

.fad.fa-rows:after,
.fa-duotone.fa-rows:after {
  content: "\10e292";
}

.fad.fa-table-tennis-paddle-ball:after,
.fa-duotone.fa-table-tennis-paddle-ball:after {
  content: "\10f45d";
}

.fad.fa-ping-pong-paddle-ball:after,
.fa-duotone.fa-ping-pong-paddle-ball:after {
  content: "\10f45d";
}

.fad.fa-table-tennis:after,
.fa-duotone.fa-table-tennis:after {
  content: "\10f45d";
}

.fad.fa-table-tree:after,
.fa-duotone.fa-table-tree:after {
  content: "\10e293";
}

.fad.fa-tablet:after,
.fa-duotone.fa-tablet:after {
  content: "\10f3fb";
}

.fad.fa-tablet-android:after,
.fa-duotone.fa-tablet-android:after {
  content: "\10f3fb";
}

.fad.fa-tablet-button:after,
.fa-duotone.fa-tablet-button:after {
  content: "\10f10a";
}

.fad.fa-tablet-rugged:after,
.fa-duotone.fa-tablet-rugged:after {
  content: "\10f48f";
}

.fad.fa-tablet-screen:after,
.fa-duotone.fa-tablet-screen:after {
  content: "\10f3fc";
}

.fad.fa-tablet-android-alt:after,
.fa-duotone.fa-tablet-android-alt:after {
  content: "\10f3fc";
}

.fad.fa-tablet-screen-button:after,
.fa-duotone.fa-tablet-screen-button:after {
  content: "\10f3fa";
}

.fad.fa-tablet-alt:after,
.fa-duotone.fa-tablet-alt:after {
  content: "\10f3fa";
}

.fad.fa-tablets:after,
.fa-duotone.fa-tablets:after {
  content: "\10f490";
}

.fad.fa-tachograph-digital:after,
.fa-duotone.fa-tachograph-digital:after {
  content: "\10f566";
}

.fad.fa-digital-tachograph:after,
.fa-duotone.fa-digital-tachograph:after {
  content: "\10f566";
}

.fad.fa-taco:after,
.fa-duotone.fa-taco:after {
  content: "\10f826";
}

.fad.fa-tag:after,
.fa-duotone.fa-tag:after {
  content: "\10f02b";
}

.fad.fa-tags:after,
.fa-duotone.fa-tags:after {
  content: "\10f02c";
}

.fad.fa-tally:after,
.fa-duotone.fa-tally:after {
  content: "\10f69c";
}

.fad.fa-tally-5:after,
.fa-duotone.fa-tally-5:after {
  content: "\10f69c";
}

.fad.fa-tally-1:after,
.fa-duotone.fa-tally-1:after {
  content: "\10e294";
}

.fad.fa-tally-2:after,
.fa-duotone.fa-tally-2:after {
  content: "\10e295";
}

.fad.fa-tally-3:after,
.fa-duotone.fa-tally-3:after {
  content: "\10e296";
}

.fad.fa-tally-4:after,
.fa-duotone.fa-tally-4:after {
  content: "\10e297";
}

.fad.fa-tape:after,
.fa-duotone.fa-tape:after {
  content: "\10f4db";
}

.fad.fa-taxi:after,
.fa-duotone.fa-taxi:after {
  content: "\10f1ba";
}

.fad.fa-cab:after,
.fa-duotone.fa-cab:after {
  content: "\10f1ba";
}

.fad.fa-taxi-bus:after,
.fa-duotone.fa-taxi-bus:after {
  content: "\10e298";
}

.fad.fa-teeth:after,
.fa-duotone.fa-teeth:after {
  content: "\10f62e";
}

.fad.fa-teeth-open:after,
.fa-duotone.fa-teeth-open:after {
  content: "\10f62f";
}

.fad.fa-telescope:after,
.fa-duotone.fa-telescope:after {
  content: "\10e03e";
}

.fad.fa-temperature-arrow-down:after,
.fa-duotone.fa-temperature-arrow-down:after {
  content: "\10e03f";
}

.fad.fa-temperature-down:after,
.fa-duotone.fa-temperature-down:after {
  content: "\10e03f";
}

.fad.fa-temperature-arrow-up:after,
.fa-duotone.fa-temperature-arrow-up:after {
  content: "\10e040";
}

.fad.fa-temperature-up:after,
.fa-duotone.fa-temperature-up:after {
  content: "\10e040";
}

.fad.fa-temperature-empty:after,
.fa-duotone.fa-temperature-empty:after {
  content: "\10f2cb";
}

.fad.fa-temperature-0:after,
.fa-duotone.fa-temperature-0:after {
  content: "\10f2cb";
}

.fad.fa-thermometer-0:after,
.fa-duotone.fa-thermometer-0:after {
  content: "\10f2cb";
}

.fad.fa-thermometer-empty:after,
.fa-duotone.fa-thermometer-empty:after {
  content: "\10f2cb";
}

.fad.fa-temperature-full:after,
.fa-duotone.fa-temperature-full:after {
  content: "\10f2c7";
}

.fad.fa-temperature-4:after,
.fa-duotone.fa-temperature-4:after {
  content: "\10f2c7";
}

.fad.fa-thermometer-4:after,
.fa-duotone.fa-thermometer-4:after {
  content: "\10f2c7";
}

.fad.fa-thermometer-full:after,
.fa-duotone.fa-thermometer-full:after {
  content: "\10f2c7";
}

.fad.fa-temperature-half:after,
.fa-duotone.fa-temperature-half:after {
  content: "\10f2c9";
}

.fad.fa-temperature-2:after,
.fa-duotone.fa-temperature-2:after {
  content: "\10f2c9";
}

.fad.fa-thermometer-2:after,
.fa-duotone.fa-thermometer-2:after {
  content: "\10f2c9";
}

.fad.fa-thermometer-half:after,
.fa-duotone.fa-thermometer-half:after {
  content: "\10f2c9";
}

.fad.fa-temperature-high:after,
.fa-duotone.fa-temperature-high:after {
  content: "\10f769";
}

.fad.fa-temperature-list:after,
.fa-duotone.fa-temperature-list:after {
  content: "\10e299";
}

.fad.fa-temperature-low:after,
.fa-duotone.fa-temperature-low:after {
  content: "\10f76b";
}

.fad.fa-temperature-quarter:after,
.fa-duotone.fa-temperature-quarter:after {
  content: "\10f2ca";
}

.fad.fa-temperature-1:after,
.fa-duotone.fa-temperature-1:after {
  content: "\10f2ca";
}

.fad.fa-thermometer-1:after,
.fa-duotone.fa-thermometer-1:after {
  content: "\10f2ca";
}

.fad.fa-thermometer-quarter:after,
.fa-duotone.fa-thermometer-quarter:after {
  content: "\10f2ca";
}

.fad.fa-temperature-snow:after,
.fa-duotone.fa-temperature-snow:after {
  content: "\10f768";
}

.fad.fa-temperature-frigid:after,
.fa-duotone.fa-temperature-frigid:after {
  content: "\10f768";
}

.fad.fa-temperature-sun:after,
.fa-duotone.fa-temperature-sun:after {
  content: "\10f76a";
}

.fad.fa-temperature-hot:after,
.fa-duotone.fa-temperature-hot:after {
  content: "\10f76a";
}

.fad.fa-temperature-three-quarters:after,
.fa-duotone.fa-temperature-three-quarters:after {
  content: "\10f2c8";
}

.fad.fa-temperature-3:after,
.fa-duotone.fa-temperature-3:after {
  content: "\10f2c8";
}

.fad.fa-thermometer-3:after,
.fa-duotone.fa-thermometer-3:after {
  content: "\10f2c8";
}

.fad.fa-thermometer-three-quarters:after,
.fa-duotone.fa-thermometer-three-quarters:after {
  content: "\10f2c8";
}

.fad.fa-tenge-sign:after,
.fa-duotone.fa-tenge-sign:after {
  content: "\10f7d7";
}

.fad.fa-tenge:after,
.fa-duotone.fa-tenge:after {
  content: "\10f7d7";
}

.fad.fa-tennis-ball:after,
.fa-duotone.fa-tennis-ball:after {
  content: "\10f45e";
}

.fad.fa-terminal:after,
.fa-duotone.fa-terminal:after {
  content: "\10f120";
}

.fad.fa-text:after,
.fa-duotone.fa-text:after {
  content: "\10f893";
}

.fad.fa-text-height:after,
.fa-duotone.fa-text-height:after {
  content: "\10f034";
}

.fad.fa-text-size:after,
.fa-duotone.fa-text-size:after {
  content: "\10f894";
}

.fad.fa-text-slash:after,
.fa-duotone.fa-text-slash:after {
  content: "\10f87d";
}

.fad.fa-remove-format:after,
.fa-duotone.fa-remove-format:after {
  content: "\10f87d";
}

.fad.fa-text-width:after,
.fa-duotone.fa-text-width:after {
  content: "\10f035";
}

.fad.fa-thermometer:after,
.fa-duotone.fa-thermometer:after {
  content: "\10f491";
}

.fad.fa-theta:after,
.fa-duotone.fa-theta:after {
  content: "\10f69e";
}

.fad.fa-thought-bubble:after,
.fa-duotone.fa-thought-bubble:after {
  content: "\10e32e";
}

.fad.fa-thumbs-down:after,
.fa-duotone.fa-thumbs-down:after {
  content: "\10f165";
}

.fad.fa-thumbs-up:after,
.fa-duotone.fa-thumbs-up:after {
  content: "\10f164";
}

.fad.fa-thumbtack:after,
.fa-duotone.fa-thumbtack:after {
  content: "\10f08d";
}

.fad.fa-thumb-tack:after,
.fa-duotone.fa-thumb-tack:after {
  content: "\10f08d";
}

.fad.fa-tick:after,
.fa-duotone.fa-tick:after {
  content: "\10e32f";
}

.fad.fa-ticket:after,
.fa-duotone.fa-ticket:after {
  content: "\10f145";
}

.fad.fa-ticket-airline:after,
.fa-duotone.fa-ticket-airline:after {
  content: "\10e29a";
}

.fad.fa-ticket-simple:after,
.fa-duotone.fa-ticket-simple:after {
  content: "\10f3ff";
}

.fad.fa-ticket-alt:after,
.fa-duotone.fa-ticket-alt:after {
  content: "\10f3ff";
}

.fad.fa-tickets-airline:after,
.fa-duotone.fa-tickets-airline:after {
  content: "\10e29b";
}

.fad.fa-tilde:after,
.fa-duotone.fa-tilde:after {
  content: "\10f69f";
}

.fad.fa-timeline:after,
.fa-duotone.fa-timeline:after {
  content: "\10e29c";
}

.fad.fa-timeline-arrow:after,
.fa-duotone.fa-timeline-arrow:after {
  content: "\10e29d";
}

.fad.fa-timer:after,
.fa-duotone.fa-timer:after {
  content: "\10e29e";
}

.fad.fa-tire:after,
.fa-duotone.fa-tire:after {
  content: "\10f631";
}

.fad.fa-tire-flat:after,
.fa-duotone.fa-tire-flat:after {
  content: "\10f632";
}

.fad.fa-tire-pressure-warning:after,
.fa-duotone.fa-tire-pressure-warning:after {
  content: "\10f633";
}

.fad.fa-tire-rugged:after,
.fa-duotone.fa-tire-rugged:after {
  content: "\10f634";
}

.fad.fa-toggle-off:after,
.fa-duotone.fa-toggle-off:after {
  content: "\10f204";
}

.fad.fa-toggle-on:after,
.fa-duotone.fa-toggle-on:after {
  content: "\10f205";
}

.fad.fa-toilet:after,
.fa-duotone.fa-toilet:after {
  content: "\10f7d8";
}

.fad.fa-toilet-paper:after,
.fa-duotone.fa-toilet-paper:after {
  content: "\10f71e";
}

.fad.fa-toilet-paper-blank:after,
.fa-duotone.fa-toilet-paper-blank:after {
  content: "\10f71f";
}

.fad.fa-toilet-paper-alt:after,
.fa-duotone.fa-toilet-paper-alt:after {
  content: "\10f71f";
}

.fad.fa-toilet-paper-blank-under:after,
.fa-duotone.fa-toilet-paper-blank-under:after {
  content: "\10e29f";
}

.fad.fa-toilet-paper-reverse-alt:after,
.fa-duotone.fa-toilet-paper-reverse-alt:after {
  content: "\10e29f";
}

.fad.fa-toilet-paper-slash:after,
.fa-duotone.fa-toilet-paper-slash:after {
  content: "\10e072";
}

.fad.fa-toilet-paper-under:after,
.fa-duotone.fa-toilet-paper-under:after {
  content: "\10e2a0";
}

.fad.fa-toilet-paper-reverse:after,
.fa-duotone.fa-toilet-paper-reverse:after {
  content: "\10e2a0";
}

.fad.fa-toilet-paper-under-slash:after,
.fa-duotone.fa-toilet-paper-under-slash:after {
  content: "\10e2a1";
}

.fad.fa-toilet-paper-reverse-slash:after,
.fa-duotone.fa-toilet-paper-reverse-slash:after {
  content: "\10e2a1";
}

.fad.fa-tomato:after,
.fa-duotone.fa-tomato:after {
  content: "\10e330";
}

.fad.fa-tombstone:after,
.fa-duotone.fa-tombstone:after {
  content: "\10f720";
}

.fad.fa-tombstone-blank:after,
.fa-duotone.fa-tombstone-blank:after {
  content: "\10f721";
}

.fad.fa-tombstone-alt:after,
.fa-duotone.fa-tombstone-alt:after {
  content: "\10f721";
}

.fad.fa-toolbox:after,
.fa-duotone.fa-toolbox:after {
  content: "\10f552";
}

.fad.fa-tooth:after,
.fa-duotone.fa-tooth:after {
  content: "\10f5c9";
}

.fad.fa-toothbrush:after,
.fa-duotone.fa-toothbrush:after {
  content: "\10f635";
}

.fad.fa-torii-gate:after,
.fa-duotone.fa-torii-gate:after {
  content: "\10f6a1";
}

.fad.fa-tornado:after,
.fa-duotone.fa-tornado:after {
  content: "\10f76f";
}

.fad.fa-tower-broadcast:after,
.fa-duotone.fa-tower-broadcast:after {
  content: "\10f519";
}

.fad.fa-broadcast-tower:after,
.fa-duotone.fa-broadcast-tower:after {
  content: "\10f519";
}

.fad.fa-tower-control:after,
.fa-duotone.fa-tower-control:after {
  content: "\10e2a2";
}

.fad.fa-tractor:after,
.fa-duotone.fa-tractor:after {
  content: "\10f722";
}

.fad.fa-trademark:after,
.fa-duotone.fa-trademark:after {
  content: "\10f25c";
}

.fad.fa-traffic-cone:after,
.fa-duotone.fa-traffic-cone:after {
  content: "\10f636";
}

.fad.fa-traffic-light:after,
.fa-duotone.fa-traffic-light:after {
  content: "\10f637";
}

.fad.fa-traffic-light-go:after,
.fa-duotone.fa-traffic-light-go:after {
  content: "\10f638";
}

.fad.fa-traffic-light-slow:after,
.fa-duotone.fa-traffic-light-slow:after {
  content: "\10f639";
}

.fad.fa-traffic-light-stop:after,
.fa-duotone.fa-traffic-light-stop:after {
  content: "\10f63a";
}

.fad.fa-trailer:after,
.fa-duotone.fa-trailer:after {
  content: "\10e041";
}

.fad.fa-train:after,
.fa-duotone.fa-train:after {
  content: "\10f238";
}

.fad.fa-train-subway:after,
.fa-duotone.fa-train-subway:after {
  content: "\10f239";
}

.fad.fa-subway:after,
.fa-duotone.fa-subway:after {
  content: "\10f239";
}

.fad.fa-train-subway-tunnel:after,
.fa-duotone.fa-train-subway-tunnel:after {
  content: "\10e2a3";
}

.fad.fa-subway-tunnel:after,
.fa-duotone.fa-subway-tunnel:after {
  content: "\10e2a3";
}

.fad.fa-train-tram:after,
.fa-duotone.fa-train-tram:after {
  content: "\10f7da";
}

.fad.fa-tram:after,
.fa-duotone.fa-tram:after {
  content: "\10f7da";
}

.fad.fa-transformer-bolt:after,
.fa-duotone.fa-transformer-bolt:after {
  content: "\10e2a4";
}

.fad.fa-transgender:after,
.fa-duotone.fa-transgender:after {
  content: "\10f224";
}

.fad.fa-transgender-alt:after,
.fa-duotone.fa-transgender-alt:after {
  content: "\10f225";
}

.fad.fa-transporter:after,
.fa-duotone.fa-transporter:after {
  content: "\10e042";
}

.fad.fa-transporter-1:after,
.fa-duotone.fa-transporter-1:after {
  content: "\10e043";
}

.fad.fa-transporter-2:after,
.fa-duotone.fa-transporter-2:after {
  content: "\10e044";
}

.fad.fa-transporter-3:after,
.fa-duotone.fa-transporter-3:after {
  content: "\10e045";
}

.fad.fa-transporter-4:after,
.fa-duotone.fa-transporter-4:after {
  content: "\10e2a5";
}

.fad.fa-transporter-5:after,
.fa-duotone.fa-transporter-5:after {
  content: "\10e2a6";
}

.fad.fa-transporter-6:after,
.fa-duotone.fa-transporter-6:after {
  content: "\10e2a7";
}

.fad.fa-transporter-7:after,
.fa-duotone.fa-transporter-7:after {
  content: "\10e2a8";
}

.fad.fa-transporter-empty:after,
.fa-duotone.fa-transporter-empty:after {
  content: "\10e046";
}

.fad.fa-trash:after,
.fa-duotone.fa-trash:after {
  content: "\10f1f8";
}

.fad.fa-trash-arrow-up:after,
.fa-duotone.fa-trash-arrow-up:after {
  content: "\10f829";
}

.fad.fa-trash-restore:after,
.fa-duotone.fa-trash-restore:after {
  content: "\10f829";
}

.fad.fa-trash-can:after,
.fa-duotone.fa-trash-can:after {
  content: "\10f2ed";
}

.fad.fa-trash-alt:after,
.fa-duotone.fa-trash-alt:after {
  content: "\10f2ed";
}

.fad.fa-trash-can-arrow-up:after,
.fa-duotone.fa-trash-can-arrow-up:after {
  content: "\10f82a";
}

.fad.fa-trash-restore-alt:after,
.fa-duotone.fa-trash-restore-alt:after {
  content: "\10f82a";
}

.fad.fa-trash-can-check:after,
.fa-duotone.fa-trash-can-check:after {
  content: "\10e2a9";
}

.fad.fa-trash-can-clock:after,
.fa-duotone.fa-trash-can-clock:after {
  content: "\10e2aa";
}

.fad.fa-trash-can-list:after,
.fa-duotone.fa-trash-can-list:after {
  content: "\10e2ab";
}

.fad.fa-trash-can-plus:after,
.fa-duotone.fa-trash-can-plus:after {
  content: "\10e2ac";
}

.fad.fa-trash-can-slash:after,
.fa-duotone.fa-trash-can-slash:after {
  content: "\10e2ad";
}

.fad.fa-trash-alt-slash:after,
.fa-duotone.fa-trash-alt-slash:after {
  content: "\10e2ad";
}

.fad.fa-trash-can-undo:after,
.fa-duotone.fa-trash-can-undo:after {
  content: "\10f896";
}

.fad.fa-trash-can-arrow-turn-left:after,
.fa-duotone.fa-trash-can-arrow-turn-left:after {
  content: "\10f896";
}

.fad.fa-trash-undo-alt:after,
.fa-duotone.fa-trash-undo-alt:after {
  content: "\10f896";
}

.fad.fa-trash-can-xmark:after,
.fa-duotone.fa-trash-can-xmark:after {
  content: "\10e2ae";
}

.fad.fa-trash-check:after,
.fa-duotone.fa-trash-check:after {
  content: "\10e2af";
}

.fad.fa-trash-clock:after,
.fa-duotone.fa-trash-clock:after {
  content: "\10e2b0";
}

.fad.fa-trash-list:after,
.fa-duotone.fa-trash-list:after {
  content: "\10e2b1";
}

.fad.fa-trash-plus:after,
.fa-duotone.fa-trash-plus:after {
  content: "\10e2b2";
}

.fad.fa-trash-slash:after,
.fa-duotone.fa-trash-slash:after {
  content: "\10e2b3";
}

.fad.fa-trash-undo:after,
.fa-duotone.fa-trash-undo:after {
  content: "\10f895";
}

.fad.fa-trash-arrow-turn-left:after,
.fa-duotone.fa-trash-arrow-turn-left:after {
  content: "\10f895";
}

.fad.fa-trash-xmark:after,
.fa-duotone.fa-trash-xmark:after {
  content: "\10e2b4";
}

.fad.fa-treasure-chest:after,
.fa-duotone.fa-treasure-chest:after {
  content: "\10f723";
}

.fad.fa-tree:after,
.fa-duotone.fa-tree:after {
  content: "\10f1bb";
}

.fad.fa-tree-christmas:after,
.fa-duotone.fa-tree-christmas:after {
  content: "\10f7db";
}

.fad.fa-tree-deciduous:after,
.fa-duotone.fa-tree-deciduous:after {
  content: "\10f400";
}

.fad.fa-tree-alt:after,
.fa-duotone.fa-tree-alt:after {
  content: "\10f400";
}

.fad.fa-tree-decorated:after,
.fa-duotone.fa-tree-decorated:after {
  content: "\10f7dc";
}

.fad.fa-tree-large:after,
.fa-duotone.fa-tree-large:after {
  content: "\10f7dd";
}

.fad.fa-tree-palm:after,
.fa-duotone.fa-tree-palm:after {
  content: "\10f82b";
}

.fad.fa-trees:after,
.fa-duotone.fa-trees:after {
  content: "\10f724";
}

.fad.fa-triangle:after,
.fa-duotone.fa-triangle:after {
  content: "\10f2ec";
}

.fad.fa-triangle-exclamation:after,
.fa-duotone.fa-triangle-exclamation:after {
  content: "\10f071";
}

.fad.fa-exclamation-triangle:after,
.fa-duotone.fa-exclamation-triangle:after {
  content: "\10f071";
}

.fad.fa-warning:after,
.fa-duotone.fa-warning:after {
  content: "\10f071";
}

.fad.fa-triangle-instrument:after,
.fa-duotone.fa-triangle-instrument:after {
  content: "\10f8e2";
}

.fad.fa-triangle-music:after,
.fa-duotone.fa-triangle-music:after {
  content: "\10f8e2";
}

.fad.fa-triangle-person-digging:after,
.fa-duotone.fa-triangle-person-digging:after {
  content: "\10f85d";
}

.fad.fa-construction:after,
.fa-duotone.fa-construction:after {
  content: "\10f85d";
}

.fad.fa-trophy:after,
.fa-duotone.fa-trophy:after {
  content: "\10f091";
}

.fad.fa-trophy-star:after,
.fa-duotone.fa-trophy-star:after {
  content: "\10f2eb";
}

.fad.fa-trophy-alt:after,
.fa-duotone.fa-trophy-alt:after {
  content: "\10f2eb";
}

.fad.fa-truck:after,
.fa-duotone.fa-truck:after {
  content: "\10f0d1";
}

.fad.fa-truck-clock:after,
.fa-duotone.fa-truck-clock:after {
  content: "\10f48c";
}

.fad.fa-shipping-timed:after,
.fa-duotone.fa-shipping-timed:after {
  content: "\10f48c";
}

.fad.fa-truck-container:after,
.fa-duotone.fa-truck-container:after {
  content: "\10f4dc";
}

.fad.fa-truck-container-empty:after,
.fa-duotone.fa-truck-container-empty:after {
  content: "\10e2b5";
}

.fad.fa-truck-fast:after,
.fa-duotone.fa-truck-fast:after {
  content: "\10f48b";
}

.fad.fa-shipping-fast:after,
.fa-duotone.fa-shipping-fast:after {
  content: "\10f48b";
}

.fad.fa-truck-flatbed:after,
.fa-duotone.fa-truck-flatbed:after {
  content: "\10e2b6";
}

.fad.fa-truck-front:after,
.fa-duotone.fa-truck-front:after {
  content: "\10e2b7";
}

.fad.fa-truck-medical:after,
.fa-duotone.fa-truck-medical:after {
  content: "\10f0f9";
}

.fad.fa-ambulance:after,
.fa-duotone.fa-ambulance:after {
  content: "\10f0f9";
}

.fad.fa-truck-monster:after,
.fa-duotone.fa-truck-monster:after {
  content: "\10f63b";
}

.fad.fa-truck-moving:after,
.fa-duotone.fa-truck-moving:after {
  content: "\10f4df";
}

.fad.fa-truck-pickup:after,
.fa-duotone.fa-truck-pickup:after {
  content: "\10f63c";
}

.fad.fa-truck-plow:after,
.fa-duotone.fa-truck-plow:after {
  content: "\10f7de";
}

.fad.fa-truck-ramp:after,
.fa-duotone.fa-truck-ramp:after {
  content: "\10f4e0";
}

.fad.fa-truck-ramp-box:after,
.fa-duotone.fa-truck-ramp-box:after {
  content: "\10f4de";
}

.fad.fa-truck-loading:after,
.fa-duotone.fa-truck-loading:after {
  content: "\10f4de";
}

.fad.fa-truck-ramp-couch:after,
.fa-duotone.fa-truck-ramp-couch:after {
  content: "\10f4dd";
}

.fad.fa-truck-couch:after,
.fa-duotone.fa-truck-couch:after {
  content: "\10f4dd";
}

.fad.fa-truck-tow:after,
.fa-duotone.fa-truck-tow:after {
  content: "\10e2b8";
}

.fad.fa-trumpet:after,
.fa-duotone.fa-trumpet:after {
  content: "\10f8e3";
}

.fad.fa-tshirt:after,
.fa-duotone.fa-tshirt:after {
  content: "\10f553";
}

.fad.fa-tty:after,
.fa-duotone.fa-tty:after {
  content: "\10f1e4";
}

.fad.fa-teletype:after,
.fa-duotone.fa-teletype:after {
  content: "\10f1e4";
}

.fad.fa-tty-answer:after,
.fa-duotone.fa-tty-answer:after {
  content: "\10e2b9";
}

.fad.fa-teletype-answer:after,
.fa-duotone.fa-teletype-answer:after {
  content: "\10e2b9";
}

.fad.fa-tugrik-sign:after,
.fa-duotone.fa-tugrik-sign:after {
  content: "\10e2ba";
}

.fad.fa-turkey:after,
.fa-duotone.fa-turkey:after {
  content: "\10f725";
}

.fad.fa-turkish-lira-sign:after,
.fa-duotone.fa-turkish-lira-sign:after {
  content: "\10e2bb";
}

.fad.fa-try:after,
.fa-duotone.fa-try:after {
  content: "\10e2bb";
}

.fad.fa-turkish-lira:after,
.fa-duotone.fa-turkish-lira:after {
  content: "\10e2bb";
}

.fad.fa-turn-down:after,
.fa-duotone.fa-turn-down:after {
  content: "\10f3be";
}

.fad.fa-level-down-alt:after,
.fa-duotone.fa-level-down-alt:after {
  content: "\10f3be";
}

.fad.fa-turn-down-left:after,
.fa-duotone.fa-turn-down-left:after {
  content: "\10e331";
}

.fad.fa-turn-up:after,
.fa-duotone.fa-turn-up:after {
  content: "\10f3bf";
}

.fad.fa-level-up-alt:after,
.fa-duotone.fa-level-up-alt:after {
  content: "\10f3bf";
}

.fad.fa-turntable:after,
.fa-duotone.fa-turntable:after {
  content: "\10f8e4";
}

.fad.fa-turtle:after,
.fa-duotone.fa-turtle:after {
  content: "\10f726";
}

.fad.fa-tv:after,
.fa-duotone.fa-tv:after {
  content: "\10f26c";
}

.fad.fa-television:after,
.fa-duotone.fa-television:after {
  content: "\10f26c";
}

.fad.fa-tv-alt:after,
.fa-duotone.fa-tv-alt:after {
  content: "\10f26c";
}

.fad.fa-tv-music:after,
.fa-duotone.fa-tv-music:after {
  content: "\10f8e6";
}

.fad.fa-tv-retro:after,
.fa-duotone.fa-tv-retro:after {
  content: "\10f401";
}

.fad.fa-typewriter:after,
.fa-duotone.fa-typewriter:after {
  content: "\10f8e7";
}

.fad.fa-u:after,
.fa-duotone.fa-u:after {
  content: "\10e332";
}

.fad.fa-ufo:after,
.fa-duotone.fa-ufo:after {
  content: "\10e047";
}

.fad.fa-ufo-beam:after,
.fa-duotone.fa-ufo-beam:after {
  content: "\10e048";
}

.fad.fa-umbrella:after,
.fa-duotone.fa-umbrella:after {
  content: "\10f0e9";
}

.fad.fa-umbrella-beach:after,
.fa-duotone.fa-umbrella-beach:after {
  content: "\10f5ca";
}

.fad.fa-umbrella-simple:after,
.fa-duotone.fa-umbrella-simple:after {
  content: "\10e2bc";
}

.fad.fa-umbrella-alt:after,
.fa-duotone.fa-umbrella-alt:after {
  content: "\10e2bc";
}

.fad.fa-underline:after,
.fa-duotone.fa-underline:after {
  content: "\10f0cd";
}

.fad.fa-unicorn:after,
.fa-duotone.fa-unicorn:after {
  content: "\10f727";
}

.fad.fa-union:after,
.fa-duotone.fa-union:after {
  content: "\10f6a2";
}

.fad.fa-universal-access:after,
.fa-duotone.fa-universal-access:after {
  content: "\10f29a";
}

.fad.fa-unlock:after,
.fa-duotone.fa-unlock:after {
  content: "\10f09c";
}

.fad.fa-unlock-keyhole:after,
.fa-duotone.fa-unlock-keyhole:after {
  content: "\10f13e";
}

.fad.fa-unlock-alt:after,
.fa-duotone.fa-unlock-alt:after {
  content: "\10f13e";
}

.fad.fa-up:after,
.fa-duotone.fa-up:after {
  content: "\10f357";
}

.fad.fa-arrow-alt-up:after,
.fa-duotone.fa-arrow-alt-up:after {
  content: "\10f357";
}

.fad.fa-up-down:after,
.fa-duotone.fa-up-down:after {
  content: "\10f338";
}

.fad.fa-arrows-alt-v:after,
.fa-duotone.fa-arrows-alt-v:after {
  content: "\10f338";
}

.fad.fa-up-down-left-right:after,
.fa-duotone.fa-up-down-left-right:after {
  content: "\10f0b2";
}

.fad.fa-arrows-alt:after,
.fa-duotone.fa-arrows-alt:after {
  content: "\10f0b2";
}

.fad.fa-up-from-line:after,
.fa-duotone.fa-up-from-line:after {
  content: "\10f346";
}

.fad.fa-arrow-alt-from-bottom:after,
.fa-duotone.fa-arrow-alt-from-bottom:after {
  content: "\10f346";
}

.fad.fa-up-left:after,
.fa-duotone.fa-up-left:after {
  content: "\10e2bd";
}

.fad.fa-up-long:after,
.fa-duotone.fa-up-long:after {
  content: "\10f30c";
}

.fad.fa-long-arrow-alt-up:after,
.fa-duotone.fa-long-arrow-alt-up:after {
  content: "\10f30c";
}

.fad.fa-up-right:after,
.fa-duotone.fa-up-right:after {
  content: "\10e2be";
}

.fad.fa-up-right-and-down-left-from-center:after,
.fa-duotone.fa-up-right-and-down-left-from-center:after {
  content: "\10f424";
}

.fad.fa-expand-alt:after,
.fa-duotone.fa-expand-alt:after {
  content: "\10f424";
}

.fad.fa-up-right-from-square:after,
.fa-duotone.fa-up-right-from-square:after {
  content: "\10f35d";
}

.fad.fa-external-link-alt:after,
.fa-duotone.fa-external-link-alt:after {
  content: "\10f35d";
}

.fad.fa-up-to-line:after,
.fa-duotone.fa-up-to-line:after {
  content: "\10f34d";
}

.fad.fa-arrow-alt-to-top:after,
.fa-duotone.fa-arrow-alt-to-top:after {
  content: "\10f34d";
}

.fad.fa-upload:after,
.fa-duotone.fa-upload:after {
  content: "\10f093";
}

.fad.fa-usb-drive:after,
.fa-duotone.fa-usb-drive:after {
  content: "\10f8e9";
}

.fad.fa-user:after,
.fa-duotone.fa-user:after {
  content: "\10f007";
}

.fad.fa-user-alien:after,
.fa-duotone.fa-user-alien:after {
  content: "\10e04a";
}

.fad.fa-user-astronaut:after,
.fa-duotone.fa-user-astronaut:after {
  content: "\10f4fb";
}

.fad.fa-user-bounty-hunter:after,
.fa-duotone.fa-user-bounty-hunter:after {
  content: "\10e2bf";
}

.fad.fa-user-check:after,
.fa-duotone.fa-user-check:after {
  content: "\10f4fc";
}

.fad.fa-user-clock:after,
.fa-duotone.fa-user-clock:after {
  content: "\10f4fd";
}

.fad.fa-user-cowboy:after,
.fa-duotone.fa-user-cowboy:after {
  content: "\10f8ea";
}

.fad.fa-user-crown:after,
.fa-duotone.fa-user-crown:after {
  content: "\10f6a4";
}

.fad.fa-user-doctor:after,
.fa-duotone.fa-user-doctor:after {
  content: "\10f0f0";
}

.fad.fa-user-md:after,
.fa-duotone.fa-user-md:after {
  content: "\10f0f0";
}

.fad.fa-user-doctor-message:after,
.fa-duotone.fa-user-doctor-message:after {
  content: "\10f82e";
}

.fad.fa-user-md-chat:after,
.fa-duotone.fa-user-md-chat:after {
  content: "\10f82e";
}

.fad.fa-user-gear:after,
.fa-duotone.fa-user-gear:after {
  content: "\10f4fe";
}

.fad.fa-user-cog:after,
.fa-duotone.fa-user-cog:after {
  content: "\10f4fe";
}

.fad.fa-user-graduate:after,
.fa-duotone.fa-user-graduate:after {
  content: "\10f501";
}

.fad.fa-user-group:after,
.fa-duotone.fa-user-group:after {
  content: "\10f500";
}

.fad.fa-user-friends:after,
.fa-duotone.fa-user-friends:after {
  content: "\10f500";
}

.fad.fa-user-group-crown:after,
.fa-duotone.fa-user-group-crown:after {
  content: "\10f6a5";
}

.fad.fa-users-crown:after,
.fa-duotone.fa-users-crown:after {
  content: "\10f6a5";
}

.fad.fa-user-headset:after,
.fa-duotone.fa-user-headset:after {
  content: "\10f82d";
}

.fad.fa-user-helmet-safety:after,
.fa-duotone.fa-user-helmet-safety:after {
  content: "\10f82c";
}

.fad.fa-user-construction:after,
.fa-duotone.fa-user-construction:after {
  content: "\10f82c";
}

.fad.fa-user-hard-hat:after,
.fa-duotone.fa-user-hard-hat:after {
  content: "\10f82c";
}

.fad.fa-user-injured:after,
.fa-duotone.fa-user-injured:after {
  content: "\10f728";
}

.fad.fa-user-large:after,
.fa-duotone.fa-user-large:after {
  content: "\10f406";
}

.fad.fa-user-alt:after,
.fa-duotone.fa-user-alt:after {
  content: "\10f406";
}

.fad.fa-user-large-slash:after,
.fa-duotone.fa-user-large-slash:after {
  content: "\10f4fa";
}

.fad.fa-user-alt-slash:after,
.fa-duotone.fa-user-alt-slash:after {
  content: "\10f4fa";
}

.fad.fa-user-lock:after,
.fa-duotone.fa-user-lock:after {
  content: "\10f502";
}

.fad.fa-user-minus:after,
.fa-duotone.fa-user-minus:after {
  content: "\10f503";
}

.fad.fa-user-music:after,
.fa-duotone.fa-user-music:after {
  content: "\10f8eb";
}

.fad.fa-user-ninja:after,
.fa-duotone.fa-user-ninja:after {
  content: "\10f504";
}

.fad.fa-user-nurse:after,
.fa-duotone.fa-user-nurse:after {
  content: "\10f82f";
}

.fad.fa-user-pen:after,
.fa-duotone.fa-user-pen:after {
  content: "\10f4ff";
}

.fad.fa-user-edit:after,
.fa-duotone.fa-user-edit:after {
  content: "\10f4ff";
}

.fad.fa-user-pilot:after,
.fa-duotone.fa-user-pilot:after {
  content: "\10e2c0";
}

.fad.fa-user-pilot-tie:after,
.fa-duotone.fa-user-pilot-tie:after {
  content: "\10e2c1";
}

.fad.fa-user-plus:after,
.fa-duotone.fa-user-plus:after {
  content: "\10f234";
}

.fad.fa-user-police:after,
.fa-duotone.fa-user-police:after {
  content: "\10e333";
}

.fad.fa-user-police-tie:after,
.fa-duotone.fa-user-police-tie:after {
  content: "\10e334";
}

.fad.fa-user-robot:after,
.fa-duotone.fa-user-robot:after {
  content: "\10e04b";
}

.fad.fa-user-secret:after,
.fa-duotone.fa-user-secret:after {
  content: "\10f21b";
}

.fad.fa-user-shakespeare:after,
.fa-duotone.fa-user-shakespeare:after {
  content: "\10e2c2";
}

.fad.fa-user-shield:after,
.fa-duotone.fa-user-shield:after {
  content: "\10f505";
}

.fad.fa-user-slash:after,
.fa-duotone.fa-user-slash:after {
  content: "\10f506";
}

.fad.fa-user-tag:after,
.fa-duotone.fa-user-tag:after {
  content: "\10f507";
}

.fad.fa-user-tie:after,
.fa-duotone.fa-user-tie:after {
  content: "\10f508";
}

.fad.fa-user-unlock:after,
.fa-duotone.fa-user-unlock:after {
  content: "\10e058";
}

.fad.fa-user-visor:after,
.fa-duotone.fa-user-visor:after {
  content: "\10e04c";
}

.fad.fa-user-xmark:after,
.fa-duotone.fa-user-xmark:after {
  content: "\10f235";
}

.fad.fa-user-times:after,
.fa-duotone.fa-user-times:after {
  content: "\10f235";
}

.fad.fa-users:after,
.fa-duotone.fa-users:after {
  content: "\10f0c0";
}

.fad.fa-group:after,
.fa-duotone.fa-group:after {
  content: "\10f0c0";
}

.fad.fa-users-gear:after,
.fa-duotone.fa-users-gear:after {
  content: "\10f509";
}

.fad.fa-users-cog:after,
.fa-duotone.fa-users-cog:after {
  content: "\10f509";
}

.fad.fa-users-medical:after,
.fa-duotone.fa-users-medical:after {
  content: "\10f830";
}

.fad.fa-users-slash:after,
.fa-duotone.fa-users-slash:after {
  content: "\10e073";
}

.fad.fa-utensils:after,
.fa-duotone.fa-utensils:after {
  content: "\10f2e7";
}

.fad.fa-cutlery:after,
.fa-duotone.fa-cutlery:after {
  content: "\10f2e7";
}

.fad.fa-utility-pole:after,
.fa-duotone.fa-utility-pole:after {
  content: "\10e2c3";
}

.fad.fa-utility-pole-double:after,
.fa-duotone.fa-utility-pole-double:after {
  content: "\10e2c4";
}

.fad.fa-v:after,
.fa-duotone.fa-v:after {
  content: "\10e335";
}

.fad.fa-vacuum:after,
.fa-duotone.fa-vacuum:after {
  content: "\10e04d";
}

.fad.fa-vacuum-robot:after,
.fa-duotone.fa-vacuum-robot:after {
  content: "\10e04e";
}

.fad.fa-value-absolute:after,
.fa-duotone.fa-value-absolute:after {
  content: "\10f6a6";
}

.fad.fa-van-shuttle:after,
.fa-duotone.fa-van-shuttle:after {
  content: "\10f5b6";
}

.fad.fa-shuttle-van:after,
.fa-duotone.fa-shuttle-van:after {
  content: "\10f5b6";
}

.fad.fa-vault:after,
.fa-duotone.fa-vault:after {
  content: "\10e2c5";
}

.fad.fa-vector-circle:after,
.fa-duotone.fa-vector-circle:after {
  content: "\10e2c6";
}

.fad.fa-vector-polygon:after,
.fa-duotone.fa-vector-polygon:after {
  content: "\10e2c7";
}

.fad.fa-vector-square:after,
.fa-duotone.fa-vector-square:after {
  content: "\10f5cb";
}

.fad.fa-venus:after,
.fa-duotone.fa-venus:after {
  content: "\10f221";
}

.fad.fa-venus-double:after,
.fa-duotone.fa-venus-double:after {
  content: "\10f226";
}

.fad.fa-venus-mars:after,
.fa-duotone.fa-venus-mars:after {
  content: "\10f228";
}

.fad.fa-vest:after,
.fa-duotone.fa-vest:after {
  content: "\10e085";
}

.fad.fa-vest-patches:after,
.fa-duotone.fa-vest-patches:after {
  content: "\10e086";
}

.fad.fa-vial:after,
.fa-duotone.fa-vial:after {
  content: "\10f492";
}

.fad.fa-vials:after,
.fa-duotone.fa-vials:after {
  content: "\10f493";
}

.fad.fa-video:after,
.fa-duotone.fa-video:after {
  content: "\10f03d";
}

.fad.fa-video-camera:after,
.fa-duotone.fa-video-camera:after {
  content: "\10f03d";
}

.fad.fa-video-arrow-down-left:after,
.fa-duotone.fa-video-arrow-down-left:after {
  content: "\10e2c8";
}

.fad.fa-video-arrow-up-right:after,
.fa-duotone.fa-video-arrow-up-right:after {
  content: "\10e2c9";
}

.fad.fa-video-plus:after,
.fa-duotone.fa-video-plus:after {
  content: "\10f4e1";
}

.fad.fa-video-slash:after,
.fa-duotone.fa-video-slash:after {
  content: "\10f4e2";
}

.fad.fa-vihara:after,
.fa-duotone.fa-vihara:after {
  content: "\10f6a7";
}

.fad.fa-violin:after,
.fa-duotone.fa-violin:after {
  content: "\10f8ed";
}

.fad.fa-virus:after,
.fa-duotone.fa-virus:after {
  content: "\10e074";
}

.fad.fa-virus-slash:after,
.fa-duotone.fa-virus-slash:after {
  content: "\10e075";
}

.fad.fa-viruses:after,
.fa-duotone.fa-viruses:after {
  content: "\10e076";
}

.fad.fa-voicemail:after,
.fa-duotone.fa-voicemail:after {
  content: "\10f897";
}

.fad.fa-volcano:after,
.fa-duotone.fa-volcano:after {
  content: "\10f770";
}

.fad.fa-volleyball-ball:after,
.fa-duotone.fa-volleyball-ball:after {
  content: "\10f45f";
}

.fad.fa-volume:after,
.fa-duotone.fa-volume:after {
  content: "\10f6a8";
}

.fad.fa-volume-medium:after,
.fa-duotone.fa-volume-medium:after {
  content: "\10f6a8";
}

.fad.fa-volume-high:after,
.fa-duotone.fa-volume-high:after {
  content: "\10f028";
}

.fad.fa-volume-up:after,
.fa-duotone.fa-volume-up:after {
  content: "\10f028";
}

.fad.fa-volume-low:after,
.fa-duotone.fa-volume-low:after {
  content: "\10f027";
}

.fad.fa-volume-down:after,
.fa-duotone.fa-volume-down:after {
  content: "\10f027";
}

.fad.fa-volume-off:after,
.fa-duotone.fa-volume-off:after {
  content: "\10f026";
}

.fad.fa-volume-slash:after,
.fa-duotone.fa-volume-slash:after {
  content: "\10f2e2";
}

.fad.fa-volume-xmark:after,
.fa-duotone.fa-volume-xmark:after {
  content: "\10f6a9";
}

.fad.fa-volume-mute:after,
.fa-duotone.fa-volume-mute:after {
  content: "\10f6a9";
}

.fad.fa-volume-times:after,
.fa-duotone.fa-volume-times:after {
  content: "\10f6a9";
}

.fad.fa-vr-cardboard:after,
.fa-duotone.fa-vr-cardboard:after {
  content: "\10f729";
}

.fad.fa-w:after,
.fa-duotone.fa-w:after {
  content: "\10e336";
}

.fad.fa-wagon-covered:after,
.fa-duotone.fa-wagon-covered:after {
  content: "\10f8ee";
}

.fad.fa-walker:after,
.fa-duotone.fa-walker:after {
  content: "\10f831";
}

.fad.fa-walkie-talkie:after,
.fa-duotone.fa-walkie-talkie:after {
  content: "\10f8ef";
}

.fad.fa-wallet:after,
.fa-duotone.fa-wallet:after {
  content: "\10f555";
}

.fad.fa-wand:after,
.fa-duotone.fa-wand:after {
  content: "\10f72a";
}

.fad.fa-wand-magic:after,
.fa-duotone.fa-wand-magic:after {
  content: "\10f0d0";
}

.fad.fa-magic:after,
.fa-duotone.fa-magic:after {
  content: "\10f0d0";
}

.fad.fa-wand-magic-sparkles:after,
.fa-duotone.fa-wand-magic-sparkles:after {
  content: "\10e2ca";
}

.fad.fa-magic-wand-sparkles:after,
.fa-duotone.fa-magic-wand-sparkles:after {
  content: "\10e2ca";
}

.fad.fa-wand-sparkles:after,
.fa-duotone.fa-wand-sparkles:after {
  content: "\10f72b";
}

.fad.fa-warehouse:after,
.fa-duotone.fa-warehouse:after {
  content: "\10f494";
}

.fad.fa-warehouse-full:after,
.fa-duotone.fa-warehouse-full:after {
  content: "\10f495";
}

.fad.fa-warehouse-alt:after,
.fa-duotone.fa-warehouse-alt:after {
  content: "\10f495";
}

.fad.fa-washing-machine:after,
.fa-duotone.fa-washing-machine:after {
  content: "\10f898";
}

.fad.fa-washer:after,
.fa-duotone.fa-washer:after {
  content: "\10f898";
}

.fad.fa-watch:after,
.fa-duotone.fa-watch:after {
  content: "\10f2e1";
}

.fad.fa-watch-apple:after,
.fa-duotone.fa-watch-apple:after {
  content: "\10e2cb";
}

.fad.fa-watch-calculator:after,
.fa-duotone.fa-watch-calculator:after {
  content: "\10f8f0";
}

.fad.fa-watch-fitness:after,
.fa-duotone.fa-watch-fitness:after {
  content: "\10f63e";
}

.fad.fa-watch-smart:after,
.fa-duotone.fa-watch-smart:after {
  content: "\10e2cc";
}

.fad.fa-water:after,
.fa-duotone.fa-water:after {
  content: "\10f773";
}

.fad.fa-water-arrow-down:after,
.fa-duotone.fa-water-arrow-down:after {
  content: "\10f774";
}

.fad.fa-water-lower:after,
.fa-duotone.fa-water-lower:after {
  content: "\10f774";
}

.fad.fa-water-arrow-up:after,
.fa-duotone.fa-water-arrow-up:after {
  content: "\10f775";
}

.fad.fa-water-rise:after,
.fa-duotone.fa-water-rise:after {
  content: "\10f775";
}

.fad.fa-water-ladder:after,
.fa-duotone.fa-water-ladder:after {
  content: "\10f5c5";
}

.fad.fa-ladder-water:after,
.fa-duotone.fa-ladder-water:after {
  content: "\10f5c5";
}

.fad.fa-swimming-pool:after,
.fa-duotone.fa-swimming-pool:after {
  content: "\10f5c5";
}

.fad.fa-watermelon-slice:after,
.fa-duotone.fa-watermelon-slice:after {
  content: "\10e337";
}

.fad.fa-wave-pulse:after,
.fa-duotone.fa-wave-pulse:after {
  content: "\10f5f8";
}

.fad.fa-heart-rate:after,
.fa-duotone.fa-heart-rate:after {
  content: "\10f5f8";
}

.fad.fa-wave-sine:after,
.fa-duotone.fa-wave-sine:after {
  content: "\10f899";
}

.fad.fa-wave-square:after,
.fa-duotone.fa-wave-square:after {
  content: "\10f83e";
}

.fad.fa-wave-triangle:after,
.fa-duotone.fa-wave-triangle:after {
  content: "\10f89a";
}

.fad.fa-waveform:after,
.fa-duotone.fa-waveform:after {
  content: "\10f8f1";
}

.fad.fa-waveform-lines:after,
.fa-duotone.fa-waveform-lines:after {
  content: "\10f8f2";
}

.fad.fa-weight-hanging:after,
.fa-duotone.fa-weight-hanging:after {
  content: "\10f5cd";
}

.fad.fa-weight-scale:after,
.fa-duotone.fa-weight-scale:after {
  content: "\10f496";
}

.fad.fa-weight:after,
.fa-duotone.fa-weight:after {
  content: "\10f496";
}

.fad.fa-whale:after,
.fa-duotone.fa-whale:after {
  content: "\10f72c";
}

.fad.fa-wheat:after,
.fa-duotone.fa-wheat:after {
  content: "\10f72d";
}

.fad.fa-wheat-awn:after,
.fa-duotone.fa-wheat-awn:after {
  content: "\10e2cd";
}

.fad.fa-wheat-alt:after,
.fa-duotone.fa-wheat-alt:after {
  content: "\10e2cd";
}

.fad.fa-wheat-awn-slash:after,
.fa-duotone.fa-wheat-awn-slash:after {
  content: "\10e338";
}

.fad.fa-wheat-slash:after,
.fa-duotone.fa-wheat-slash:after {
  content: "\10e339";
}

.fad.fa-wheelchair:after,
.fa-duotone.fa-wheelchair:after {
  content: "\10f193";
}

.fad.fa-wheelchair-move:after,
.fa-duotone.fa-wheelchair-move:after {
  content: "\10e2ce";
}

.fad.fa-wheelchair-alt:after,
.fa-duotone.fa-wheelchair-alt:after {
  content: "\10e2ce";
}

.fad.fa-whiskey-glass:after,
.fa-duotone.fa-whiskey-glass:after {
  content: "\10f7a0";
}

.fad.fa-glass-whiskey:after,
.fa-duotone.fa-glass-whiskey:after {
  content: "\10f7a0";
}

.fad.fa-whiskey-glass-ice:after,
.fa-duotone.fa-whiskey-glass-ice:after {
  content: "\10f7a1";
}

.fad.fa-glass-whiskey-rocks:after,
.fa-duotone.fa-glass-whiskey-rocks:after {
  content: "\10f7a1";
}

.fad.fa-whistle:after,
.fa-duotone.fa-whistle:after {
  content: "\10f460";
}

.fad.fa-wifi:after,
.fa-duotone.fa-wifi:after {
  content: "\10f1eb";
}

.fad.fa-wifi-3:after,
.fa-duotone.fa-wifi-3:after {
  content: "\10f1eb";
}

.fad.fa-wifi-strong:after,
.fa-duotone.fa-wifi-strong:after {
  content: "\10f1eb";
}

.fad.fa-wifi-exclamation:after,
.fa-duotone.fa-wifi-exclamation:after {
  content: "\10e2cf";
}

.fad.fa-wifi-fair:after,
.fa-duotone.fa-wifi-fair:after {
  content: "\10f6ab";
}

.fad.fa-wifi-2:after,
.fa-duotone.fa-wifi-2:after {
  content: "\10f6ab";
}

.fad.fa-wifi-slash:after,
.fa-duotone.fa-wifi-slash:after {
  content: "\10f6ac";
}

.fad.fa-wifi-weak:after,
.fa-duotone.fa-wifi-weak:after {
  content: "\10f6aa";
}

.fad.fa-wifi-1:after,
.fa-duotone.fa-wifi-1:after {
  content: "\10f6aa";
}

.fad.fa-wind:after,
.fa-duotone.fa-wind:after {
  content: "\10f72e";
}

.fad.fa-wind-turbine:after,
.fa-duotone.fa-wind-turbine:after {
  content: "\10f89b";
}

.fad.fa-wind-warning:after,
.fa-duotone.fa-wind-warning:after {
  content: "\10f776";
}

.fad.fa-wind-circle-exclamation:after,
.fa-duotone.fa-wind-circle-exclamation:after {
  content: "\10f776";
}

.fad.fa-window:after,
.fa-duotone.fa-window:after {
  content: "\10f40e";
}

.fad.fa-window-flip:after,
.fa-duotone.fa-window-flip:after {
  content: "\10f40f";
}

.fad.fa-window-alt:after,
.fa-duotone.fa-window-alt:after {
  content: "\10f40f";
}

.fad.fa-window-frame:after,
.fa-duotone.fa-window-frame:after {
  content: "\10e04f";
}

.fad.fa-window-frame-open:after,
.fa-duotone.fa-window-frame-open:after {
  content: "\10e050";
}

.fad.fa-window-maximize:after,
.fa-duotone.fa-window-maximize:after {
  content: "\10f2d0";
}

.fad.fa-window-minimize:after,
.fa-duotone.fa-window-minimize:after {
  content: "\10f2d1";
}

.fad.fa-window-restore:after,
.fa-duotone.fa-window-restore:after {
  content: "\10f2d2";
}

.fad.fa-windsock:after,
.fa-duotone.fa-windsock:after {
  content: "\10f777";
}

.fad.fa-wine-bottle:after,
.fa-duotone.fa-wine-bottle:after {
  content: "\10f72f";
}

.fad.fa-wine-glass:after,
.fa-duotone.fa-wine-glass:after {
  content: "\10f4e3";
}

.fad.fa-wine-glass-crack:after,
.fa-duotone.fa-wine-glass-crack:after {
  content: "\10f4bb";
}

.fad.fa-fragile:after,
.fa-duotone.fa-fragile:after {
  content: "\10f4bb";
}

.fad.fa-wine-glass-empty:after,
.fa-duotone.fa-wine-glass-empty:after {
  content: "\10f5ce";
}

.fad.fa-wine-glass-alt:after,
.fa-duotone.fa-wine-glass-alt:after {
  content: "\10f5ce";
}

.fad.fa-won-sign:after,
.fa-duotone.fa-won-sign:after {
  content: "\10f159";
}

.fad.fa-krw:after,
.fa-duotone.fa-krw:after {
  content: "\10f159";
}

.fad.fa-won:after,
.fa-duotone.fa-won:after {
  content: "\10f159";
}

.fad.fa-wreath:after,
.fa-duotone.fa-wreath:after {
  content: "\10f7e2";
}

.fad.fa-wrench:after,
.fa-duotone.fa-wrench:after {
  content: "\10f0ad";
}

.fad.fa-wrench-simple:after,
.fa-duotone.fa-wrench-simple:after {
  content: "\10e2d1";
}

.fad.fa-x:after,
.fa-duotone.fa-x:after {
  content: "\10e33a";
}

.fad.fa-x-ray:after,
.fa-duotone.fa-x-ray:after {
  content: "\10f497";
}

.fad.fa-xmark:after,
.fa-duotone.fa-xmark:after {
  content: "\10f00d";
}

.fad.fa-close:after,
.fa-duotone.fa-close:after {
  content: "\10f00d";
}

.fad.fa-multiply:after,
.fa-duotone.fa-multiply:after {
  content: "\10f00d";
}

.fad.fa-remove:after,
.fa-duotone.fa-remove:after {
  content: "\10f00d";
}

.fad.fa-times:after,
.fa-duotone.fa-times:after {
  content: "\10f00d";
}

.fad.fa-xmark-to-slot:after,
.fa-duotone.fa-xmark-to-slot:after {
  content: "\10f771";
}

.fad.fa-times-to-slot:after,
.fa-duotone.fa-times-to-slot:after {
  content: "\10f771";
}

.fad.fa-vote-nay:after,
.fa-duotone.fa-vote-nay:after {
  content: "\10f771";
}

.fad.fa-y:after,
.fa-duotone.fa-y:after {
  content: "\10e33b";
}

.fad.fa-yen-sign:after,
.fa-duotone.fa-yen-sign:after {
  content: "\10f157";
}

.fad.fa-cny:after,
.fa-duotone.fa-cny:after {
  content: "\10f157";
}

.fad.fa-jpy:after,
.fa-duotone.fa-jpy:after {
  content: "\10f157";
}

.fad.fa-rmb:after,
.fa-duotone.fa-rmb:after {
  content: "\10f157";
}

.fad.fa-yen:after,
.fa-duotone.fa-yen:after {
  content: "\10f157";
}

.fad.fa-yin-yang:after,
.fa-duotone.fa-yin-yang:after {
  content: "\10f6ad";
}

.fad.fa-z:after,
.fa-duotone.fa-z:after {
  content: "\10e33c";
}

@font-face {
  font-family: 'Font Awesome 6 Pro';
  font-style: normal;
  font-weight: 300;
  font-display: block;
  src: url("../fonts/fa-light-300.woff2") format("woff2"), url("../fonts/fa-light-300.woff") format("woff"), url("../fonts/fa-light-300.ttf") format("truetype");
}

.fal,
.fa-light {
  font-family: 'Font Awesome 6 Pro';
  font-weight: 300;
}

@font-face {
  font-family: 'Font Awesome 6 Pro';
  font-style: normal;
  font-weight: 400;
  font-display: block;
  src: url("../fonts/fa-regular-400.woff2") format("woff2"), url("../fonts/fa-regular-400.woff") format("woff"), url("../fonts/fa-regular-400.ttf") format("truetype");
}

.far,
.fa-regular {
  font-family: 'Font Awesome 6 Pro';
  font-weight: 400;
}

@font-face {
  font-family: 'Font Awesome 6 Pro';
  font-style: normal;
  font-weight: 900;
  font-display: block;
  src: url("../fonts/fa-solid-900.woff2") format("woff2"), url("../fonts/fa-solid-900.woff") format("woff"), url("../fonts/fa-solid-900.ttf") format("truetype");
}

.fas,
.fa-solid {
  font-family: 'Font Awesome 6 Pro';
  font-weight: 900;
}

@font-face {
  font-family: 'Font Awesome 6 Pro';
  font-style: normal;
  font-weight: 100;
  font-display: block;
  src: url("../fonts/fa-thin-100.woff2") format("woff2"), url("../fonts/fa-thin-100.woff") format("woff"), url("../fonts/fa-thin-100.ttf") format("truetype");
}

.fat,
.fa-thin {
  font-family: 'Font Awesome 6 Pro';
  font-weight: 100;
}